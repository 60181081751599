import React from "react";
import ReactSwitch from "react-switch";

const Togglebutton = (props) => {
  const {
    checked = true,
    onChange = () => {},
    onColor = "#9098d1",
    onHandleColor = "#303b91",
    handleDiameter = 18,
    uncheckedIcon = false,
    checkedIcon = false,
    boxShadow = "0px 1px 5px rgba(0, 0, 0, 0.6)",
    activeBoxShadow = "0px 0px 1px 10px rgba(0, 0, 0, 0.2)",
    height = 12,
    width = 30,
    className = "react-switch",
    id = "material-switch",
  } = props;
  return (
    <ReactSwitch
      checked={checked}
      onChange={onChange}
      onColor={onColor}
      onHandleColor={onHandleColor}
      handleDiameter={handleDiameter}
      uncheckedIcon={uncheckedIcon}
      checkedIcon={checkedIcon}
      boxShadow={boxShadow}
      activeBoxShadow={activeBoxShadow}
      height={height}
      width={width}
      className={className}
      id={id}
    />
  );
};

export default Togglebutton;
