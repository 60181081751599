import React, { useState, useEffect } from "react";
import { Form, Button } from "antd";
// import { useHttp } from "../../../components/Hook/Use-http";
import useHttp from "../../../components/Hook/Use-http";
import BrsrFormHeading from "../BrsrFormHeading";
import Questions from "../../../components/Report/Questions";
import { replaceFormIdandClientId, replaceId } from "../../Utility/function";
import CONSTANT from "../../Utility/constnt";
import Submit from "../submitButton";
import { StarFilled } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { Spinner } from "reactstrap";


const NGRBCPrinciples = () => {
  const { id } = useParams();
  const userRole = localStorage.getItem("userRole");
  const API_CALL = useHttp();
  const [loading, setLoading] = useState(true);

  const [questions, setQuestions] = useState([]);
  const getTooltipText = {
    225:"Mention all certifications (local, national and international) which firm has and map each of them to specific principles",
    226:"The question seeks to know if the firm has set any goals on it's own to improve it's performance against the NGRBC's",
    227:"To evaluate the entity’s Performance against its set Commitments.",
    228:"It gives insight into entity contribution towards sustainable development including the key events, achievements, & failures associated with the business.",
    229:"It ensures accountability & increases the awareness & understanding of climate-related risks & opportunities within the entity which results in better risk management & more informed strategic planning.",
    230:"Provide details about the composition of the Sustainability Committee",

  }

  useEffect(() => {
    try {
      const parser = (data = []) => {
        return data.map((curr) => {
          const dataSchema = JSON.parse(curr.Question.answerSchema);
          let answerString = curr.Question?.Answers[0]?.value;
          return {
            id: curr.Question.id,
            question: curr.Question.description,
            options: dataSchema.options,
            answerSchema:dataSchema,
            type: dataSchema.type,
            answer: answerString,
            valueType: curr.Question?.Answers[0]?.valueType,
          };
        });
      };

      let apiData;
      let sectionId = 15; // need to call this api and call from api not statically
      if (userRole == "client") {
        API_CALL.sendRequest(
          replaceId(CONSTANT.API.GLOBALFORMS.get, sectionId),
          (res) => {
            let globalQuestions = parser(res?.data?.GlobalSectionQuestions);
            setQuestions(globalQuestions);
            setLoading(false);
          }
        );
      } else if (userRole == "admin") {
        API_CALL.sendRequest(
          replaceFormIdandClientId(
            CONSTANT.API.GLOBALFORMS.get2,
            sectionId,
            id
          ),
          (res) => {
            let globalQuestions = parser(res?.data?.GlobalSectionQuestions);
            setQuestions(globalQuestions);
            setLoading(false);
          }
        );
      } else if (userRole == "spoc") {
        API_CALL.sendRequest(
          replaceFormIdandClientId(
            CONSTANT.API.GLOBALFORMS.get,
            sectionId,
            id
          ),
          (res) => {
            let globalQuestions = parser(res?.data[0]?.GlobalSection?.GlobalSectionQuestions);
            setQuestions(globalQuestions);
            setLoading(false);
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleSubmit = (data = {}) => {
    try {
      const formatObject = (obj) => JSON.stringify(obj).replace(/"/g, '\\"');
      const transformData = (data) => ({
        answers: Object.entries(data).map(([key, value]) => {
          const question = questions.find((q) => q.id === Number(key));
          let formattedValue;
          if (
            (question && question.type === "fixedtable") ||
            (question.type === "dynamictable" && question.transformedAnswer)
          ) {
            formattedValue = JSON.stringify(
              question?.transformedAnswer
            )?.replace(/"/g, '"');
          } else {
            formattedValue =
              typeof value === "object"
                ? formatObject([{ id: value.key }])
                : value;
          }
          return {
            qid: Number(key),
            value: formattedValue,
          };
        }),
      });

      if (userRole === "client") {
        API_CALL.sendRequest(
          CONSTANT.API.GLOBALFORMS.create,
          null,
          transformData(data),
          "Your data added successfully"
        );
      } else if (userRole === "admin") {
        API_CALL.sendRequest(
          replaceId(CONSTANT.API.GLOBALFORMS.create2, id),
          null,
          transformData(data),
          "Your data added successfully"
        );
      }  else if (userRole === "spoc") {
        const clientId = localStorage.getItem("client");
        API_CALL.sendRequest(
          replaceId(CONSTANT.API.GLOBALFORMS.create2, clientId),
          null,
          transformData(data),
          "Your data added successfully"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      style={{ marginTop: 100 }}
    >
      <BrsrFormHeading
        title="NGRBCPrinciples"
        explanation="This section is aimed at helping businesses demonstrate the structures, policies  & processes put in place towards adopting the NGRBC Principles  & Core Elements."
      />
      <p style={{ fontStyle: "italic", marginBottom: "16px" }}>
        Note: Answers marked with ✨ are recommended by our AI. You can edit
        them if needed.
      </p>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <Spinner className="m-1" color="primary" />
        </div>
      ) : (
      <Form layout="vertical" onFinish={handleSubmit}>
        {questions.map((ques, index) => (
          <Questions key={ques.id} ques={ques} index={index} />
        ))}
        <Button
          type="primary"
          htmlType="submit"
          style={{
            position: "fixed",
            bottom: "30px",
            backgroundColor: "#060DBF",
            color: "#62C941",
            right: "74px",
            zIndex: 1000,
            // width: "125px",
            height: "40px",
            cursor: "pointer",
          }}
        >
          Save changes
        </Button>
      </Form>
      )}
    </div>
  );
};

export default NGRBCPrinciples;
