import { put, takeLatest } from "redux-saga/effects";
import { DELETE_DRIVER_START, GET_DRIVER_START } from "./actionTypes";
import Apicall from "../../components/Hook/Api-call";
import CONSTANT from "../../pages/Utility/constnt";
import {
  deleteDriverError,
  deleteDriverSucess,
  getDriverError,
  getDriverSuccess,
} from "./actions";
import { replaceEndPointId } from "../../pages/Utility/function";

function* getDriver() {
  try {
    const res = yield Apicall?.get(CONSTANT.API.DRIVER.get.endpoint);
    yield put(getDriverSuccess(res));
  } catch (error) {
    console.log(error);
    yield put(getDriverError(error?.message));

    // yield put({ type: GET_CUSTOMER_FAILURE, error });
  }
}

function* deleteDriverSaga(user) {
  try {
    const response = yield Apicall?.remove(
      replaceEndPointId(CONSTANT?.API?.DRIVER?.delete?.endpoint, user?.payload)
    );

    if (response?.status == "success") {
      if (user?.successCallback) {
        user?.successCallback();
      }
    }
    yield put(deleteDriverSucess(user));
  } catch (error) {
    console.log(error?.message, "responce");
    yield put(deleteDriverError(error?.message));
  }
}

// function* getDriver() {
//   try {
//     const res = yield Apicall.get(CONSTANT.API.DRIVER.getDriver.endpoint);
//     // const res = "aaaaaaaabb";

//     yield put({ type: GET_DRIVER_SUCCESS, data: res });
//     // debugger;
//   } catch (error) {
//     console.log(error);
//     // yield put({ type: "GET_MY_PROFILE_ERROR", error });
//   }
// }

function* GetDriverDetailSaga() {
  yield takeLatest(GET_DRIVER_START, getDriver);
  yield takeLatest(DELETE_DRIVER_START, deleteDriverSaga);
}

export default GetDriverDetailSaga;
