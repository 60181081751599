import React from "react";
import PageTitle from "../../../components/pageTitle";
import PaginatedTable from "../../../components/paginatedTable/PaginatedTable";

const ChangeLogs = () => {

    const exportHeader = [
        { label: 'Action On', key: 'actionType' },
        { label: 'Reference Id', key: 'reference' },
        { label: 'Action', key: 'action' },
        { label: 'Performed By', key: 'performedBy' },
        { label: 'Email', key: 'email' },
        { label: 'Date', key: 'date' },
        { label: 'Time', key: 'time' },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <PageTitle pageHeading={`Change Logs`} />
                {/* for Audit logs */}
                <PaginatedTable title={"Change Logs"} exportHeader={exportHeader} />
            </div>
        </React.Fragment>
    );
};

export default ChangeLogs;
