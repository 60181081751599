import React, { useEffect, useState } from "react";
import CustomModalMain from "../../components/Custome/CustomModalMain";
import {
  Nav,
  NavItem,
  NavLink,
  Alert,
  Card,
  CardBody,
  Col,
  Row,
  TabPane,
  TabContent,
} from "reactstrap";
import { Empty } from "antd";
import useHttp from "../../components/Hook/Use-http";
import { useDispatch, useSelector } from "react-redux";
import CONSTANT, { MyData } from "../Utility/constnt";
import { getUsersDetailSuccess } from "../../store/actions";
import { useLocation, useParams } from "react-router";
import { tab, TAB_DATA } from "../Utility/constnt";
import classNames from "classnames";
import { replaceId } from "../Utility/function";
import UserApprovalCard from "./Notifications/userApproval";

const ClientProfile = (props) => {
  const [flag, setFlag] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const getClientData = useSelector((state) => state.customerDetailData);
  const userRole = localStorage.getItem("userRole");
  const location = useLocation();
  const currentpath = location.pathname;
  const API_CALL = useHttp();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [activeTab, setactiveTab] = useState(tab?.tabActiveId);

  useEffect(() => {
    if (location.state?.activeTab) {
      setactiveTab(location.state.activeTab);
    }
  }, [location.state]);

  const toggleCustomJustified = (tab) => {
    if (activeTab !== tab.tabId) {
      setactiveTab(tab);
    }
  };
  const [updatedList, setUpdatedList] = useState(false);
  //   const clientdata

  const myData = useSelector((state) => state.userGetMeData);

  useEffect(() => {
    if (localStorage.getItem("authUser") && userRole != "admin") {
      API_CALL.sendRequest(CONSTANT.API.ADMIN.getMe, getMeDataHandler);
    }
  }, [flag]);


  useEffect(() => {
    if (userRole && currentpath.includes("/client")) {
      id &&
        getMeFindDataHandler(
          getClientData.users.data?.rows?.find((el) => el?.id == id)
        );
    }
  }, [getClientData.users.data]);

  const getMeDataHandler = (res) => {
    setUserDetail(res.data);
    dispatch(getUsersDetailSuccess(res?.data));

    MyData.data = res.data;
  };
  const getMeFindDataHandler = (res) => {
    setUserDetail(res);
    dispatch(getUsersDetailSuccess(res));

    MyData.data = res;
  };

  const onSubmitForm = (payload) => {
    (async () => {
      API_CALL.sendRequest(
        CONSTANT.API.ADMIN.adminUpdate,
        updateMeHandler,
        payload,
        "Your data was updated successfully"
      );
    })();
  };

  const onSubmitCompanyForm = (payload) => {
    (async () => {
      API_CALL.sendRequest(
        replaceId(CONSTANT.API.CLIENT.update, id),
        null,
        payload,
        "Your data was updated successfully"
      );
    })();
  };

  const updateMeHandler = () => {
    setFlag(!flag);
  };
  
  const [newUserData, setNewUserData] = useState(null);
  useEffect(() => {
    if (userRole === "admin" || userRole === "client") {
      API_CALL.sendRequest(
        replaceId(CONSTANT.API.USERAPPROVAL.getAllUnverifiedClientAdmin, id),
        (res) => {
          setNewUserData(res?.unverifiedUserList);
          localStorage.setItem("unverifiedUsersCount", res?.unverifiedUserList.length);
        }
      );
    }
  }, [id, updatedList]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">PROFILE</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Welcome {myData?.users?.name}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </Row>

        <Row>
          <Col lg="12">
            {props.error && props.error ? (
              <Alert color="danger">{props.error}</Alert>
            ) : null}
            {props.success && props.success ? (
              <Alert color="success">{props.success}</Alert>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <div className="page-content mt-0 pt-0 scrollSticky">
              <Nav tabs className="nav-tabs-custom nav-justified">
                {TAB_DATA.CLIENT_PROFILE_TAB.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="mt-2"
                      style={{ width: "300px" }}
                    >
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classNames({
                            active: activeTab === data.tabId,
                            activeTabDesign: activeTab === data.tabId,
                            TabDesign: true,
                          })}
                          onClick={() => {
                            tab.tabActiveId = data?.tabId;
                            toggleCustomJustified(data.tabId);
                          }}
                        >
                          <span className="d-sm-block">{data.name}</span>
                        </NavLink>
                      </NavItem>
                    </div>
                  );
                })}
              </Nav>
              <TabContent activeTab={activeTab} className="mt-3 p-0 TabBody">
                <TabPane tabId={1} className="py-3">
                  <Card>
                    <CardBody>
                      <CustomModalMain
                        data={CONSTANT.FORM_FIELDS[`COMPANY_DETAILS`]}
                        isEdit={true}
                        defaultData={userDetail}
                        labelSize={"25%"}
                        submitBtnPosition={"center"}
                        fieldSize={"70%"}
                        // submitBtnName="Calculate Shipment Emission"
                        onSubmit={(data) => onSubmitCompanyForm(data)}
                        isCloseButtonVisible={false}
                        minHeight={"300px"}
                        onChange={(data) => {}}
                        formData={true}
                      />
                    </CardBody>
                  </Card>
                </TabPane>
                <TabPane tabId={2} className="py-3">
                  <Card>
                    <CardBody>
                      <CustomModalMain
                        data={CONSTANT.FORM_FIELDS[`client_PROFILE`]}
                        isEdit={true}
                        defaultData={userDetail}
                        labelSize={"25%"}
                        submitBtnPosition={"center"}
                        fieldSize={"70%"}
                        // submitBtnName="Calculate Shipment Emission"
                        onSubmit={(data) => onSubmitForm(data)}
                        isCloseButtonVisible={false}
                        minHeight={"300px"}
                        onChange={(data) => {}}
                        formData={true}
                      />
                    </CardBody>
                  </Card>
                </TabPane>
                <TabPane tabId={3} className="py-3">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "20px",
                    }}
                  >
                    {newUserData && (<UserApprovalCard userData = {newUserData} clientId={id} setUpdatedList={setUpdatedList} />)}
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default ClientProfile;
