import React, { useEffect, useState } from "react";

import { getRecordId, replaceFormIdandClientId, replaceIdClientId, selectedCategoryBulkView } from "../Utility/function";

import { useSelector } from "react-redux";

import useHttp from "../../components/Hook/Use-http";
import { Col, Modal, Row } from "reactstrap";
import PageTitle from "../../components/pageTitle";

import { useDispatch } from "react-redux";
import BulkUploadHeaderCardGen from "../../components/emissionEntry/bulkUploadView/bulkUploadHeaderCardGen";
import CONSTANT from "../Utility/constnt";
import { useParams } from "react-router-dom";

const initHeader = [
  { label: "No", field: "no", sort: "asc" },
  { label: "Status", field: "status", sort: "asc" },
  {
    label: <p style={{ margin: "0px", width: "100px" }}>Record Status</p>,
    field: "isDuplicate",
    sort: "asc",
  },
];
// let dynamicOption = {};

let responseStatus = null;
let succesCount = 0;
let failedCount = 0;
// let Other = {
//   site: [],
// };

const BulkOnboardView = () => {
    const userRole = localStorage.getItem("userRole");
    const API_CALL = useHttp();
  let jobId = window.location.pathname.split("/")[3];

  const API = useHttp();

  const [jobSingleData, setJobSingleData] = useState([]);
  const [jobViewData, setJobViewData] = useState([]);
  const [viewReasonData, setViewReasonData] = useState(null);
  const [dynamicOption, setDynamicOption] = useState({});
  const [alldynamicOption, setAllDynamicOption] = useState({});

  const [editData, setEditData] = useState({});

  const getJobBulkData = useSelector((state) => state.jobBulkDetailData);

  const [flag, setFlag] = useState(null);

  const {id} = useParams();

  const onCloseModal = () => {
    setViewReasonData(null);
  };

  const onViewReason = (tripData) => {
    setViewReasonData(tripData);
  };

  const onFailed = () => {
    responseStatus = "fail";
  };
  const onSuccess = () => {
    responseStatus = "success";
  };
  const onAll = () => {
    responseStatus = null;
  };

     useEffect(()=>{
        if (userRole === "admin"){
            API_CALL.sendRequest(
                replaceFormIdandClientId(CONSTANT.API.CONNECTIONS, jobId, id),
                (res) => {
                    setJobSingleData(res?.data?.find((item)=> item?.id == jobId))
                }   
            )
        }
     },[]);






  useEffect(() => {
    succesCount = jobViewData?.filter((it) => it?.status == "success")?.length;
    failedCount = jobViewData?.filter((it) => it?.status == "fail")?.length;
  }, [jobViewData]);

  const extraValuePass = {
    jobId: jobId,
    jobSingleData: jobSingleData,
    API: API,
    initHeader: initHeader,
    setDynamicOption: setDynamicOption,
    setAllDynamicOption: setAllDynamicOption,
    setJobViewData: setJobViewData,
    onViewReason: onViewReason,
    responceStatus: responseStatus,
    flag: flag,
    dynamicOption: dynamicOption,
    setEditData: setEditData,
  };

  return (
    <>
      {/* <BulkUploadViewGen /> */}
      <div className="page-content">
        <PageTitle
          pageHeading={`JOB / ${getRecordId?.job(jobId)}`}
          withBackButton={true}
        />

        {!API?.isLoading ? (
          <BulkUploadHeaderCardGen
            extraValue={{
              jobViewData: jobViewData,
              jobSingleData: jobSingleData,
              responceStatus: responseStatus,
              setFlag: setFlag,
              failedCount: failedCount,
              succesCount: succesCount,
              onFailed: onFailed,
              onSuccess: onSuccess,
              onAll: onAll,
            }}
          />
        ) : (
          <></>
        )}
        {selectedCategoryBulkView(jobSingleData, extraValuePass)}
      </div>
      {viewReasonData != null && (
        <Modal isOpen={true} style={{ width: "550px" }}>
          <div className="modal-body">
            <Row
              style={{
                borderBottom: "1px solid #bac1c7",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <Col>
                <h3
                  style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Reason
                </h3>
              </Col>
            </Row>

            {viewReasonData}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={onCloseModal}
              className="btn btn-primary waves-effect"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </Modal>
      )}
      ;
    </>
  );
};

export default BulkOnboardView;
