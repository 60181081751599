import axios from "axios";
import moment from "moment";
import { Col, Row, Spinner } from "reactstrap";
import "./function.css";
import CONSTANT, { UNIT_KEY } from "./constnt";
import { Fragment, useState } from "react";
import Services from "./API/service";
import { MDBBadge } from "mdbreact";
import { notification } from "antd";
import Select, { components } from "react-select";
import DownStream from "../../components/emissionEntry/singleEntryFormCategory/downStream";
import Electricity from "../../components/emissionEntry/singleEntryFormCategory/electricity";
import StationaryCombustion from "../../components/emissionEntry/singleEntryFormCategory/stationaryCombustion";
import PurchasedGases from "../../components/emissionEntry/singleEntryFormCategory/purchasedGases";
import WasteManagment from "../../components/emissionEntry/singleEntryFormCategory/wasteManagment";
import DownStreamView from "../../components/emissionEntry/bulkUploadView/bulkUploadViewCategory/downStreamView";
import ElectricityView from "../../components/emissionEntry/bulkUploadView/bulkUploadViewCategory/electricityView";
import PurchasedGasesView from "../../components/emissionEntry/bulkUploadView/bulkUploadViewCategory/purchasedGasesView";
import StationaryCombustionView from "../../components/emissionEntry/bulkUploadView/bulkUploadViewCategory/stationaryCombustionView";
import WasteManagmentView from "../../components/emissionEntry/bulkUploadView/bulkUploadViewCategory/wasteManagmentView";
import BulkOnboardPartnerView from "../../components/emissionEntry/bulkUploadView/bulkUploadViewCategory/bulkOnboardPartnerView";
import EmployeeCommute from "../../components/emissionEntry/singleEntryFormCategory/employeeCommute";
import EmployeeCommuteView from "../../components/emissionEntry/bulkUploadView/bulkUploadViewCategory/employeeCommuteView";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Transportation from "../../components/emissionEntry/singleEntryFormCategory/transportationNew";
import BusinessTravel from "../../components/emissionEntry/singleEntryFormCategory/businessTravel";
import TransportationView from "../../components/emissionEntry/bulkUploadView/bulkUploadViewCategory/transportationView";
import BusinessTravelView from "../../components/emissionEntry/bulkUploadView/bulkUploadViewCategory/businessTravelView";
import SweetAlert from "react-bootstrap-sweetalert";
import PurchasedGoodAndService from "../../components/emissionEntry/singleEntryFormCategory/purchasedGoodAndService";
import PurchasedGoodAndServiceView from "../../components/emissionEntry/bulkUploadView/bulkUploadViewCategory/purchasedGoodAndServiceView";
import CapitalGood from "../../components/emissionEntry/singleEntryFormCategory/capitalGood";
import CapitalGoodView from "../../components/emissionEntry/bulkUploadView/bulkUploadViewCategory/capitalGoodView";
import { Key } from "igniteui-react-core";
import ElectricitySingleView from "../../components/emissionEntry/singleEntryFormCategory/singleEntryViewCategory/ElectricitySingleView";
import BusinessTravelSingleView from "../../components/emissionEntry/singleEntryFormCategory/singleEntryViewCategory/BusinessTravelSingleView";
import CapitalGoodSingleView from "../../components/emissionEntry/singleEntryFormCategory/singleEntryViewCategory/CapitalGoodSingleView";
import EmployeeCommuteSingleView from "../../components/emissionEntry/singleEntryFormCategory/singleEntryViewCategory/EmployeeCommuteSingleView";
import StationaryCombustionSingleView from "../../components/emissionEntry/singleEntryFormCategory/singleEntryViewCategory/StationaryCombustionSingleView";
import PurchasedGasesSingleView from "../../components/emissionEntry/singleEntryFormCategory/singleEntryViewCategory/PurchasedGasesSingleView";
import PurchasedGoodAndServiceSingleView from "../../components/emissionEntry/singleEntryFormCategory/singleEntryViewCategory/PurchasedGoodAndServiceSingleView";
import TransportationSingleView from "../../components/emissionEntry/singleEntryFormCategory/singleEntryViewCategory/TransportationSingleView";
import DownStreamSingleView from "../../components/emissionEntry/singleEntryFormCategory/singleEntryViewCategory/DownStreamSingleView";
import WasteManagmentSingleView from "../../components/emissionEntry/singleEntryFormCategory/singleEntryViewCategory/WasteManagmentSingleView";
export const getDateAndTimeColumn = (date) =>
  moment.utc(date).format("DD-MM-YYYY") + " " + moment.utc(date).format("LT") ||
  "";

export const getLocalDateAndTimeColumn = (date) =>
  moment(date).local().format("DD-MM-YYYY") +
  " " +
  moment(date).local().format("LT") || "";

export const getDate = (date, formate) =>
  moment.utc(date).format(formate || "DD-MM-YYYY");

export const getDateAndTimeColumnFor = (date) =>
  date
    ? moment.utc(date).format("DD-MM-YYYY") +
    " " +
    moment.utc(date).format("LT")
    : "";

export const getUpperCaseText = (data) => data;

export const getCurrentIST = (extraTime) => {
  // Get current UTC time
  const currentUtcTime = moment.utc();
  // Define IST offset (UTC+5:30)
  const istOffset = 5.5;
  // Calculate IST time
  let istTime = currentUtcTime.utcOffset(istOffset);

  if (extraTime) istTime = istTime.add(extraTime, "hours");

  // Return formatted IST time
  return istTime.format("YYYY-MM-DD HH:mm");
};

export const isNonEmpty = (value) => value && value.trim() !== "";

export const getVehicleNumberText = (data, width) => {
  return data?.length === 9
    ? `${data?.substring(0, 4)}-${data?.substring(4, 5)}-${data?.substring(5)}`
    : `${data?.substring(0, 4)}-${data?.substring(4, 6)}-${data?.substring(6)}`;
  // return `${data?.substring(0, 4)} - ${data?.substring(
  //   4,
  //   5
  // )} - ${data?.substring(5)}`;
  // return `${data?.substring(0, 4)} - ${data?.substring(
  //   4,
  //   6
  // )} - ${data?.substring(6)}`;
};
export const Loader = () => (
  <Row
    className="mb-3 mx-3 VehicleCardWrapper justify-content-center "
    style={{ padding: "100px 0px", gap: "10px" }}
  >
    <Spinner
      style={{ width: "12px", height: "12px", padding: "3px" }}
      type="grow"
    />
    <p className="m-0 text-center">Loading</p>
  </Row>
);

export const log = (res, defaultText) => {
  if (process.env.REACT_APP_CONSOLE_LOG == 0) {
    return console.log(res, defaultText);
  }
};

export const getTonneFromKg = (weight) => +(+weight / 1000)?.toFixed(0);

export const getMobileNumber = (data) => {
  data = `${data}`;
  if (data.length >= 10) return `${data.substring(0, 5)}${data.substring(5)}`;

  return data;
};

export const getLatLngFromGoogleMapsLink = (link) => {
  const regex = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
  const match = link.match(regex);

  if (match && match.length === 3) {
    const latitude = parseFloat(match[1]);
    const longitude = parseFloat(match[2]);
    return { latitude, longitude };
  }

  const url = new URL(link);
  const query = new URLSearchParams(url.search);
  const queryLatitude = parseFloat(query.get("q").split(",")[0]);
  const queryLongitude = parseFloat(query.get("q").split(",")[1]);

  if (!isNaN(queryLatitude) && !isNaN(queryLongitude)) {
    return { latitude: queryLatitude, longitude: queryLongitude };
  }

  return null; // Return null if the link format doesn't match
};

export const getUnitValue = (value, Unit) => {
  const preUnit = Unit?.preUnit || "";
  const postUnit = Unit?.postUnit || "";

  return value ? `${preUnit} ${value} ${postUnit}` : "Not Found";
};

export const debounce = (func, delay) => {
  let timeoutId;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(function () {
      func.apply(context, args);
    }, delay);
  };
};
export const stringifyObject = (obj) => {
  for (const key in obj) {
    if (obj[key] !== null && typeof obj[key] === "object") {
      stringifyObject(obj[key]); // Recursively handle nested objects
    } else {
      obj[key] = String(obj[key]);
    }
  }
};

export const getFormFiled = (
  name = "",
  label = "",
  placeholder = null,
  type = "text",
  required = true,
  disabled = false,
  isShow = () => {
    return true;
  },
  helpText = null,
  width = 12,
  extraValue = null,
) => {
  if (placeholder == null) {
    placeholder = label;
  }
  return {
    id: name,
    name,
    label,
    placeholder,
    type,
    required,
    disabled,
    isShow,
    helpText,
    width,
    extraValue,
  };
};

export const getOption = (label, value = null) => {
  if (value != null) {
    return {
      label,
      value,
    };
  } else {
    return {
      label,
      value: label,
    };
  }
};

export const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Yes
    </div>
  );
};

export const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  );
};

export const isIdExists = (array, id) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i].id === id) {
      return true; // ID exists in the array
    }
  }
  return false; // ID does not exist in the array
};
function sensitiveSort(arr) {
  return arr.sort((a, b) => {
    const labelA = a.label;
    const labelB = b.label;
    for (let i = 0; i < Math.min(labelA.length, labelB.length); i++) {
      const charCodeA = labelA.charCodeAt(i);
      const charCodeB = labelB.charCodeAt(i);
      if (charCodeA !== charCodeB) {
        return charCodeA - charCodeB;
      }
    }
    return labelA.length - labelB.length;
  });
}
export const optionArray = (array, label, value) =>
  array?.map((data) => {
    return { label: data[label], value: data[value ? value : label] };
  });
export const optionArrayWithSort = (
  array,
  label = "name",
  value = "id",
  extra = null
) => {
  const optionArr = array?.map((data) => {
    let optObj = {
      label: data[label],
      value: data[value ? value : label],
    };
    if (optObj?.label == optObj?.value) {
      optObj = {
        ...optObj,
        id: data["id"],
      };
    }
    if (extra != null)
      optObj = {
        ...optObj,
        extra: data[extra ? extra : value],
        id: data["id"],
      };
    return { ...optObj };
  });

  return optionArr.sort((a, b) => {
    const nameA = a.label.toUpperCase();
    const nameB = b.label.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }

    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });
};

export const convertLabelFromAPI = (string) => {
  // Split the string into an array of words
  const words = string.split("_");

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );

  // Join the capitalized words with a space or any other format specified
  const convertedString = capitalizedWords.join(" ");

  // Return the converted string
  return convertedString;
};

export const DetailsCard = ({
  data,
  isCardVisible = false,
  isCardLoading = false,
  details = [],
  viewModelImage,
  idBase64 = true,
  errorMessage = null,
}) => {
  if (isCardLoading) return <Loader />;
  if (errorMessage)
    return (
      <Row className="mb-3 mx-3 VehicleCardWrapper">
        <Col md="12">
          <p className="error">{errorMessage || "Something Went Wrong"}</p>
        </Col>
      </Row>
    );
  if (isCardVisible)
    return (
      <Row className="mb-3 mx-3 VehicleCardWrapper">
        {viewModelImage ? (
          <Col md="12" className="mb-3 d-flex justify-content-center">
            <img
              src={
                idBase64
                  ? `data:image/png;base64,${viewModelImage}`
                  : viewModelImage
              }
            />
          </Col>
        ) : (
          <></>
        )}
        {details?.map((item, index) => {
          if (Array.isArray(item)) {
            let keyName, headName;
            let keyName1, headName1;
            if (item?.[0]?.includes(":")) {
              let [firstName, secondName] = item?.[0]?.split(":");
              keyName = firstName;
              headName = secondName;
            } else {
              keyName = item?.[0];
              headName = item?.[0];
            }
            if (item?.[1]?.includes(":")) {
              let [firstName, secondName] = item?.[1]?.split(":");
              keyName1 = firstName;
              headName1 = secondName;
            } else {
              keyName1 = item?.[1];
              headName1 = item?.[1];
            }
            return (
              <Fragment key={`showItem${index}`}>
                {keyName !== null && data?.[keyName] !== null && data?.[keyName] !== undefined && data?.[keyName] !== "" && (
                  <Col md="6">
                    <p>
                      <span>{convertLabelFromAPI(headName || "")} :</span>{" "}
                      {getUnitValue(data?.[keyName], UNIT_KEY?.[keyName])}
                    </p>{" "}
                  </Col>
                )}
                {keyName1 !== null && data?.[keyName1] !== null && data?.[keyName1] !== undefined && data?.[keyName1] !== "" && (
                  <Col md="6">
                    <p>
                      <span>{convertLabelFromAPI(headName1 || "")} :</span>{" "}
                      {getUnitValue(data?.[keyName1], UNIT_KEY?.[keyName1])}
                    </p>{" "}
                  </Col>
                )}
              </Fragment>
            );
          }
          let keyName, headName;
          if (item?.includes(":")) {
            let [firstName, secondName] = item?.split(":");
            keyName = firstName;
            headName = secondName;
          } else {
            keyName = item;
            headName = item;
          }
          return (
            <Col md="12" key={`showItem${index}`}>
              {keyName !== null && data?.[keyName] !== null && data?.[keyName] !== undefined && data?.[keyName] !== "" && (
                <p>
                  <span>{convertLabelFromAPI(headName || "")} :</span>{" "}
                  {getUnitValue(data?.[keyName], UNIT_KEY?.[keyName])}
                </p>
              )}
            </Col>
          );
        })}
      </Row>
    );

  return <></>;
};
export const selectedObjGenerator = (label, value) => {
  return {
    label: label,
    value: value,
  };
};

export const getRow = (title, value) => {
  return (
    <Row key={title} className="lineSetRow">
      <Col sm={9} style={{ lineHeight: "25px" }}>
        {title}
      </Col>
      <Col sm={3} style={{ lineHeight: "25px" }}>
        {value}
      </Col>
    </Row>
  );
};

export const packegingAssetForClient = (res) => {
  const dataHeader = [
    {
      label: "Package Name",
      field: "packageName",
      sort: "asc",
    },
  ];

  res?.packageData?.map((data) => {
    dataHeader?.push({
      label: data?.plant?.unitName,
      field: data?.plantId,
      sort: "asc",
    });
  });
  dataHeader.push({
    label: "Total Assigned",
    field: "totalAssign",
    sort: "asc",
  });

  dataHeader.push({
    label: "Total Accounted",
    field: "totalAccounted",
    sort: "asc",
  });
  return dataHeader?.reduce(
    (prev, item) => {
      if (!prev.key.includes(item.field)) {
        prev.value.push(item);
        prev.key.push(item?.field);
      }

      return prev;
    },
    { key: [], value: [] }
  ).value;
};

export const packegingAssetForSite = (routes, SiteID) => {
  const dataHeader = [
    {
      label: "Package Name",
      field: "packageName",
      sort: "asc",
    },
  ];

  routes
    ?.filter((item) => {
      return (
        item?.routeType == "Forward" &&
        (item?.plantId?.toString() == SiteID?.toString() ||
          item?.destinationPlantId?.toString() == SiteID?.toString())
      );
    })
    ?.map((data) => {
      dataHeader?.push({
        label: data?.routeName,
        field: data?.id,
        sort: "asc",
      });
    });
  dataHeader.push({
    label: (
      <p style={{ margin: "0px", width: "90px", textAlign: "center" }}>
        Total Assigned
      </p>
    ),
    field: "totalAssign",
    sort: "asc",
  });
  dataHeader.push({
    label: (
      <p style={{ margin: "0px", width: "100px", textAlign: "center" }}>
        Total Accounted
      </p>
    ),
    field: "totalAccounted",
    sort: "disabled",
  });
  // dataHeader.push({
  //   label: "Total UnAccounted",
  //   field: "totalUnaccounted",
  //   sort: "asc",
  // });
  dataHeader.push({
    label: "View",
    field: "view",
    sort: "disabled",
  });
  return dataHeader?.reduce(
    (prev, item) => {
      if (!prev.key.includes(item.field)) {
        prev.value.push(item);
        prev.key.push(item?.field);
      }

      return prev;
    },
    { key: [], value: [] }
  ).value;
};

export const calculatePackageCount = (res, packageId) => {
  let result = 0;
  let SiteWiseData = {};

  res?.packageAssignedData
    .filter(
      (item) =>
        item?.packagingTypeId == packageId &&
        item["route.routeType"] == "Forward"
    )
    ?.map((outgoing) => {
      if (SiteWiseData[outgoing["route.plantId"]]) {
        SiteWiseData[outgoing["route.plantId"]] += +outgoing?.totalCount;
      } else {
        SiteWiseData = {
          ...SiteWiseData,
          [outgoing["route.plantId"]]: +outgoing?.totalCount,
        };
      }
    });

  let totalAssign =
    Object.keys(SiteWiseData).reduce((total, key) => {
      return total + +SiteWiseData[key];
    }, 0) + result;

  res?.tripsPackageData
    .filter((item) => item?.packagingTypeId == packageId)
    ?.map((outgoing) => {
      if (SiteWiseData[outgoing["trip.plant.id"]]) {
        SiteWiseData[outgoing["trip.plant.id"]] -=
          +outgoing?.outGoingTripsPackageCount;
      } else {
        SiteWiseData = {
          ...SiteWiseData,
          [outgoing["trip.plant.id"]]: -outgoing?.outGoingTripsPackageCount,
        };
      }

      if (outgoing["trip.status"] == "2")
        if (SiteWiseData[outgoing["trip.route.routeName"]]) {
          SiteWiseData[outgoing["trip.route.routeName"]] +=
            +outgoing?.outGoingTripsPackageCount;
        } else {
          SiteWiseData = {
            ...SiteWiseData,
            [outgoing["trip.route.routeName"]]:
              +outgoing?.outGoingTripsPackageCount,
          };
        }

      if (outgoing["trip.status"] == "3")
        if (SiteWiseData[outgoing["trip.destinationPlant.id"]]) {
          SiteWiseData[outgoing["trip.destinationPlant.id"]] +=
            +outgoing?.outGoingTripsPackageCount;
        } else {
          SiteWiseData = {
            ...SiteWiseData,
            [outgoing["trip.destinationPlant.id"]]:
              +outgoing?.outGoingTripsPackageCount,
          };
        }
    });

  Object.keys(SiteWiseData).map((item) => {
    SiteWiseData[item] < 0 && delete SiteWiseData[item];
  });

  let totalAS = Object.keys(SiteWiseData).reduce((subTotal, key) => {
    return subTotal + +SiteWiseData[key];
  }, 0);

  return (
    <MDBBadge
      className={
        totalAssign != totalAS
          ? "bg-soft-danger text-danger p-1"
          : "bg-soft-success text-success p-1"
      }
      style={{
        borderRadius: "4px",
        cursor: "pointer",
        width: "80px",
        fontSize: "15px",
      }}
    >
      {totalAS}
    </MDBBadge>
  );
};

export const getItem = (label, key, icon, children) => {
  return {
    key,
    icon,
    children,
    label,
  };
};


export const menuGen = (path) => {
  return [
    ...CONSTANT?.MENU?.[path][0]?.submenu?.map((el) =>
      getItem(
        el.lable,
        `${el.to}`,
        el?.icon,
        el.submenu &&
        el.submenu.map((elp) =>
          getItem(
            elp.lable,
            `${elp.to}`,
            elp?.icon,
            elp.submenu &&
            elp.submenu.map((elpd) =>
              getItem(elpd?.lable, `${elpd.to}`, elpd.icon,
                elpd.submenu &&
                elpd.submenu.map((elpd2) =>
                  getItem(elpd2?.lable, `${elpd2.to}`, elpd2.icon)
                )
              )
            )
          )
        )
      )
    ),
  ];
};

export const provideWidthArea = (count, device) => {
  if (count >= 7) {
    return { lg: 6, md: 6, xl: 3, xxl: 3 };
  } else if (count >= 5) {
    return { lg: 4, md: 6, xl: 4, xxl: 4 };
  } else if (count >= 3) {
    return { lg: 6, md: 6, xl: 6, xxl: 6 };
  } else if (count >= 2) {
    return { lg: 12, md: 12, xl: 12, xxl: 12 };
  } else {
    return { lg: 24, md: 24, xl: 24, xxl: 24 };
  }
};

export const findKeyOnLocalStorage = (value) => {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    const storedValue = localStorage.getItem(key);

    if (localStorage.getItem(key)?.toString() == value?.toString()) {
      return key;
    }
  }
  return null; // Return null if the value is not found
};

export const replaceId = (api = "", id = "") => {
  const URL = { ...api };
  URL.endpoint = URL.endpoint.replace(":id", id);
  return URL;
};
export const replaceIdClientId = (api="", clientId="") => {
  const URL = { ...api };
  // URL.endpoint = URL.endpoint.replace(":id", id);
  URL.endpoint = URL.endpoint.replace(":client_id", clientId);
  return URL;
}
export const replaceFormIdandClientId = (api = "", formId = "", clientId = "") => {
  const URL = { ...api };
  URL.endpoint = URL.endpoint.replace(":id", formId);
  URL.endpoint = URL.endpoint.replace(":client_id", clientId);
  return URL;
};

export const replaceEndPointId = (endpoint = "", id = "") => {
  // const URL = { ...api };
  const end = endpoint?.replace(":id", id);

  return end;
};

export const getPackagingAsset = (res, packageName) => {
  let plantPackageCountPackageCountAtPlant = {};

  res?.totalPackageAssignToSite
    .filter((item) => item["route.routeType"] == "Forward")
    ?.map((incoming) => {
      if (incoming["plant.unitName"] in plantPackageCountPackageCountAtPlant) {
        plantPackageCountPackageCountAtPlant[incoming["plant.unitName"]] +=
          +incoming?.totalCount;
      } else {
        plantPackageCountPackageCountAtPlant = {
          ...plantPackageCountPackageCountAtPlant,
          [incoming["plant.unitName"]]: +incoming?.totalCount,
        };
      }
    });

  res?.tripsPackageData?.map((outgoing) => {
    if (
      outgoing["trip.plant.unitName"] in plantPackageCountPackageCountAtPlant
    ) {
      plantPackageCountPackageCountAtPlant[outgoing["trip.plant.unitName"]] -=
        +outgoing?.outGoingTripsPackageCount;
    } else
      plantPackageCountPackageCountAtPlant = {
        ...plantPackageCountPackageCountAtPlant,
        [outgoing["trip.plant.unitName"]]: -outgoing?.outGoingTripsPackageCount,
      };

    if (outgoing["trip.status"] == "3") {
      if (
        outgoing["trip.destinationPlant.unitName"] in
        plantPackageCountPackageCountAtPlant
      ) {
        plantPackageCountPackageCountAtPlant[
          outgoing["trip.destinationPlant.unitName"]
        ] += +outgoing?.outGoingTripsPackageCount;
      } else {
        plantPackageCountPackageCountAtPlant = {
          ...plantPackageCountPackageCountAtPlant,
          [outgoing["trip.destinationPlant.unitName"]]:
            +outgoing?.outGoingTripsPackageCount,
        };
      }
    }
  });

  Object.keys(plantPackageCountPackageCountAtPlant).map((item) => {
    plantPackageCountPackageCountAtPlant[item] < 0 &&
      delete plantPackageCountPackageCountAtPlant[item];
  });

  return {
    title: `${packageName} Location`,
    plantPackageCountPackageCountAtPlant: plantPackageCountPackageCountAtPlant,
    route: [
      ...res?.tripsPackageData
        ?.filter((item) => item["trip.status"] == "2")
        ?.map((outgoingIntransit) => {
          return {
            title: outgoingIntransit["trip.route.routeName"],
            value: outgoingIntransit?.outGoingTripsPackageCount,
          };
        }),
    ],
  };
};

export const calculatePackageCountForClient = (res, packageId) => {
  let result = 0;
  let SiteWiseData = {};

  res?.outGoingTripsPackageData
    .filter((item) => item?.packagingTypeId == packageId)
    ?.map((outgoing) => {
      if (SiteWiseData[outgoing["trip.route.plantId"]]) {
        SiteWiseData[outgoing["trip.route.plantId"]] -=
          +outgoing?.outGoingTripsPackageCount;
      } else {
        SiteWiseData = {
          ...SiteWiseData,
          [outgoing["trip.route.plantId"]]:
            -outgoing?.outGoingTripsPackageCount,
        };
      }

      if (outgoing["trip.status"] == "2")
        if (SiteWiseData[outgoing["trip.route.routeName"]]) {
          SiteWiseData[outgoing["trip.route.routeName"]] +=
            +outgoing?.outGoingTripsPackageCount;
        } else {
          SiteWiseData = {
            ...SiteWiseData,
            [outgoing["trip.route.routeName"]]:
              +outgoing?.outGoingTripsPackageCount,
          };
        }
      if (outgoing["trip.status"] == "3") {
        if (SiteWiseData[outgoing["trip.route.destinationPlantId"]]) {
          SiteWiseData[outgoing["trip.route.destinationPlantId"]] +=
            +outgoing?.outGoingTripsPackageCount;
        } else {
          SiteWiseData = {
            ...SiteWiseData,
            [outgoing["trip.route.destinationPlantId"]]:
              +outgoing?.outGoingTripsPackageCount,
          };
        }
      }
    });

  res?.packageData
    .filter(
      (item) =>
        item?.packagingTypeId == packageId &&
        item?.route?.routeType == "Forward"
    )
    ?.map((outgoing) => {
      if (SiteWiseData[outgoing["route.plantId"]]) {
        SiteWiseData[outgoing["route.plantId"]] += +outgoing?.totalCount;
      } else {
        SiteWiseData = {
          ...SiteWiseData,
          [outgoing["route.plantId"]]: +outgoing?.totalCount,
        };
      }
    });

  let total =
    Object.keys(SiteWiseData).reduce((total, key) => {
      return total + +SiteWiseData[key];
    }, 0) + result;

  Object.keys(SiteWiseData).map((item) => {
    SiteWiseData[item] < 0 && delete SiteWiseData[item];
  });

  let totalAS = Object.keys(SiteWiseData).reduce((subTotal, key) => {
    return subTotal + +SiteWiseData[key];
  }, 0);

  return (
    <MDBBadge
      className={
        total != totalAS
          ? "bg-soft-danger text-danger p-1"
          : "bg-soft-success text-success p-1"
      }
      style={{
        borderRadius: "4px",
        cursor: "pointer",
        width: "80px",
        fontSize: "15px",
      }}
    >
      {totalAS}
    </MDBBadge>
  );
};

export const isValidVehicleNumber = (vehicleNumber) => {
  // Regular expression pattern for Indian vehicle numbers
  const pattern = /^[A-Z]{2}[-]?[0-9]{2}[-]?[A-Z]{1,2}[-]?[0-9]{1,4}$/;

  // Remove hyphens from the vehicle number
  let normalizedNumber = vehicleNumber?.replace(/-/g, "");

  // Check if the vehicle number matches the pattern
  const isMatch = pattern.test(normalizedNumber?.trim());

  normalizedNumber = normalizedNumber?.trim();
  // Return true if the vehicle number is valid, false otherwise
  // return isMatch;
  return {
    isMatch,
    normalizedNumber,
  };
};

export const subTotalGen = (
  subTotal = 0,
  basicFreightAmount = 0,
  FuelSurcharges = 0,
  docetCharges = 0,
  FOV = 0,
  loadingUnloadingCharges = 0,
  collectionCharges = 0,
  delieveryCharges = 0,
  codDodCharges = 0,
  toPay = 0,
  otherCharges = 0
) => {
  subTotal =
    +basicFreightAmount +
    +FuelSurcharges +
    +docetCharges +
    +FOV +
    +loadingUnloadingCharges +
    +collectionCharges +
    +delieveryCharges +
    +codDodCharges +
    +toPay +
    +otherCharges;

  return +(+subTotal).toFixed(0);
};

export const totalGen = (subTotal = 0, sgt = 0, cgt = 0, igst = 0) => {
  const subTotalTemp = +subTotal;
  subTotal = +subTotal;
  subTotal += (subTotalTemp * +igst) / 100;
  subTotal += (subTotalTemp * +sgt) / 100;
  subTotal += (subTotalTemp * +cgt) / 100;
  return +subTotal.toFixed(0);
};

export const isNotEmpty = (value) => {
  if (value === null || value === undefined) {
    return false;
  }

  if (Array?.isArray(value) && value?.length === 0) {
    return false;
  }

  if (typeof value === "string" && value?.trim() === "") {
    return false;
  }

  return true;
};

export const verifyMobileNumber = (number) => {
  // Remove any non-digit characters from the input
  const cleanedNumber = number.replace(/\D/g, "");

  // Check if the cleaned number is exactly 10 digits long
  if (cleanedNumber.length !== 10) {
    return false;
  }

  // Check if the number starts with a valid digit (2-9)
  const firstDigit = cleanedNumber.charAt(0);
  if (firstDigit < "2" || firstDigit > "9") {
    return false;
  }

  // All checks passed, the number is valid
  return true;
};

export const getExtraConstPayload = (data, mappingData) => {
  const results = [];
  if (data && Object.keys(data).length !== 0) {
    Object.keys(data)?.map((key) => {
      results.push({
        id: mappingData ? mappingData[key] || key : key,
        value: data[key] || "",
      });
    });
  }
  return results;
};

export const DeleteSweetAlert = ({ onDelete = () => { }, setDeleteData }) => {
  return (
    <SweetAlert
      title="Are you sure?"
      warning
      showCancel
      confirmBtnBsStyle="success"
      cancelBtnBsStyle="danger"
      onConfirm={() => {
        onDelete();
      }}
      onCancel={() => {
        setDeleteData(null);
      }}
    >
      You won't be able to revert this!
    </SweetAlert>
  );
};

const idGenrator = (id, idName, padding) => {
  return `${idName}${id.toString().padStart(padding, "0")}`;
};

export const getRecordId = {
  job: (id) => idGenrator(id, "JOB", 5),
  record: (id) => idGenrator(id, "REC", 5),
  duplicateEntry: (id) => idGenrator(id, "DUP", 5),
  clientRecordId: (id) => idGenrator(id, "C", 5),
  shipmentRecordId: (id) => idGenrator(id, "CO2", 13),
  vehicleRecordId: (id) => idGenrator(id, "V", 7),
  packageRecordId: (id) => idGenrator(id, "P", 7),
  routeRecordId: (id) => idGenrator(id, "R", 8),
  driverRecordId: (id) => idGenrator(id, "D", 7),
  transpoterRecordId: (id) => idGenrator(id, "T", 7),
  plantRecordId: (id) => idGenrator(id, "S", 7),
  categoryRecordId: (id) => idGenrator(id, "CA", 7),
  attributeRecordId: (id) => idGenrator(id, "A", 7),
  roleAndAccessRecordId: (id) => idGenrator(id, "TEAM", 4),
  pcfProductId: (id) => idGenrator(id, "P", 5),
  brsrSpocId: (id) => idGenrator(id, "SP", 5),
  brsrFormId: (id) => idGenrator(id, "F", 5),
  connectionSentId: (id)=>idGenrator(id,"C",5),
  connectionRecievedId:(id)=>idGenrator(id,"R",5),
  brsrAllotedSpocId: (id) => idGenrator(id, "S", 5),
  userApprovalId: (id) => idGenrator(id,"U", 5),
};

export const notifications = {
  success: (message, duration = "3") =>
    notification.success({
      message: "Success",
      description: message,
      duration: duration,
    }),
  error: (message, duration = "3") =>
    notification.error({
      message: "Error",
      description: message,
      duration: duration,
    }),
  warning: (message, duration = "3") =>
    notification.warning({
      message: "Warning",
      description: message,
      duration: duration,
    }),
};

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

export const removeKeys = (obj, keysToRemove) => {
  const newObj = { ...obj };
  keysToRemove.forEach((key) => delete newObj[key]);

  return newObj;
};

export const selectedCategoryForm = (extraValue, category, extraValuePass) => {
  const findCategory = extraValue?.subCategory?.find(
    (el) => el?.id == category
  );

  switch (findCategory?.categoryFormModalName) {
    case "DownstreamTransportationModal":
      return <DownStream {...extraValuePass} />;
    case "ElectricityModal":
      return <Electricity {...extraValuePass} />;
    case "StationaryCombustionModal":
      return <StationaryCombustion {...extraValuePass} />;
    case "PurchasedGasesModal":
      return <PurchasedGases {...extraValuePass} />;
    case "WasteManagementModal":
      return <WasteManagment {...extraValuePass} />;
    case "EmployeeCommuteModel":
      return <EmployeeCommute {...extraValuePass} />;
    case "Transportation_Modal":
      return <Transportation {...extraValuePass} />;
    case "businessTravel_Modal":
      return <BusinessTravel {...extraValuePass} />;
    case "PurchasedGoodAndService_Modal":
      return <PurchasedGoodAndService {...extraValuePass} />;
    case "CapitalGood_Modal":
      return <CapitalGood {...extraValuePass} />;
    default:
      // Handle the default case if necessary
      return null;
  }
};
export const replaceValue = (value, OldValue, replaceValue) => {
  return value?.replaceAll(OldValue, replaceValue);
};

export const formatNumber = (number, maxTrailingZeros = 5) => {
  const numberStr = number.toString();
  // "56481484848".tostr;
  return number?.toPrecision(2)?.toLocaleString();
};
export const formatToFixedNumber = (number, maxTrailingZeros = 5) => {
  const numberStr = number.toString();
  const toFixeNumb = +number?.toFixed(0);
  // "56481484848".tostr;
  return toFixeNumb?.toLocaleString("en-IN");
};
// export const formatNumberWithPrecision = (number) => {
//   if (number > 1) {
//     return number?.toFixed(0);
//   } else {
//     if (number < 0.01) {
//       return number?.toPrecision(2);
//     } else {
//       return number?.toFixed(2);
//     }
//   }
// }
export const formateToFixed = (number) => {
  if (number > 1) {
    return number?.toFixed(0);
  } else {
    if (number < 0.01) {
      return number?.toFixed(0);
    } else {
      return number?.toFixed(2);
    }
  }
};

export const selectedCategoryBulkView = (jobSingleData, extraValuePass) => {
  switch (jobSingleData?.subCategory?.categoryFormModalName) {
    case "DownstreamTransportationModal":
      return <DownStreamView {...extraValuePass} />;
    case "ElectricityModal":
      return <ElectricityView {...extraValuePass} />;
    case "StationaryCombustionModal":
      return <StationaryCombustionView {...extraValuePass} />;
    case "PurchasedGasesModal":
      return <PurchasedGasesView {...extraValuePass} />;
    case "WasteManagementModal":
      return <WasteManagmentView {...extraValuePass} />;
    case "EmployeeCommuteModel":
      return <EmployeeCommuteView {...extraValuePass} />;
    case "Transportation_Modal":
      return <TransportationView {...extraValuePass} />;
    case "businessTravel_Modal":
      return <BusinessTravelView {...extraValuePass} />;
    case "PurchasedGoodAndService_Modal":
      return <PurchasedGoodAndServiceView {...extraValuePass} />;
    case "CapitalGood_Modal":
      return <CapitalGoodView {...extraValuePass} />;
    case "BulkOnboard_Modal":
      return <BulkOnboardPartnerView {...extraValuePass} />;
    default:
      // Handle the default case if necessary
      return null;
  }
};

export const selectedCategorySingleView = (category, extraValuePass) => {
  extraValuePass.category = category;
  switch (category?.label) {
    case "Downstream Transportation":
    case "Upstream Transportation":
      return <DownStreamSingleView {...extraValuePass} />;
    case "Electricity":
    case "Hotel Stays":
    case "Water Treatment and Discharge":
    case "Water Usage":
      return <ElectricitySingleView {...extraValuePass} />;
    case "Stationary Combustion":
    case "Mobile Combustion":
      return <StationaryCombustionSingleView {...extraValuePass} />;
    case "Purchased Gases":
      return <PurchasedGasesSingleView {...extraValuePass} />;
    case "Waste Management":
      return <WasteManagmentSingleView {...extraValuePass} />;
    case "Employee Commute":
      return <EmployeeCommuteSingleView {...extraValuePass} />;
    case "Transportation":
      return <TransportationSingleView {...extraValuePass} />;
    case "Business Travel":
      return <BusinessTravelSingleView {...extraValuePass} />;
    case "Purchased Goods and Services":
      return <PurchasedGoodAndServiceSingleView {...extraValuePass} />;
    case "Capital Goods":
      return <CapitalGoodSingleView {...extraValuePass} />;
    default:
      // Handle the default case if necessary
      return null;
  }
};

export const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type="checkbox" checked={isSelected} />
      {children}
    </components.Option>
  );
};

export const base64ToFile = (base64String, fileName) => {
  if (!base64String || !fileName) {
    return null;
  }
  const byteString = atob(base64String);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([ab], { type: "application/octet-stream" });
  return new File([blob], fileName);
};

export function parseAddress(address) {
  const floor_number = address?.floor_number ? address.floor_number + "," : "";
  const door_number = address?.door_number ? address.door_number + "," : "";
  const building_name = address?.building_name ? address.building_name + "," : "";
  const street_name = address?.street_name ? address.street_name + "," : ""
  const location = address?.location ? address.location + "," : "";
  const addressComponents = {
    AddressLine1: `${floor_number} ${door_number} ${building_name}`,
    AddressLine2: `${street_name} ${location}`,
    City: address?.city || address?.location || address?.dst,
    State: address?.state_name,
    Pincode: address?.pincode,
    AddressString: `${floor_number} ${door_number} ${building_name} ${street_name} ${address?.city || address?.location || address?.dst}, ${address?.state_name}, ${address?.pincode}`
  };
  return addressComponents;
}

export const updateDriverConcent = async () => {
  try {
    const res = await Services.get("/driver");
    const Drivers = res?.data?.data || [];
    for (let i = 0; i < Drivers.length; i++) {
      try {
        if (Drivers[i]?.consent == null) {
          const consentRes = await Services.get(
            `/location/consentApi/${Drivers[i]?.mobile}`
          );
          const payload = {
            consent: consentRes?.data?.data?.Consent?.status,
          };

          const updateDriver = await Services.patch(
            `/driver/${Drivers[i]?.id}`,
            payload
          );
        }
      } catch (error) {
        console.log("Error in", Drivers[i]?.mobile);
      }
    }
  } catch (error) { }
};

export function delayOneSecond() {
  return new Promise((resolve) => {
    setTimeout(resolve, 1000); // 1000 milliseconds (1 second)
  });
}

export const updateVehicleConcent = async () => {
  try {
    const res = await Services.get("/vehicle");
    const Vehicle = res?.data?.data || [];
    for (let i = 10; i < Vehicle.length; i++) {
      try {
        if (Vehicle[i]?.gross_vehicle_weight == null) {
          await delayOneSecond();
          const vehicleData = await Services.post(`/vehicle/checkVehicle`, {
            vehicleNumber: Vehicle[i]?.registrationNumber
              .replace(" ", "")
              .trim(),
          });

          const payload = {
            ...vehicleData?.data?.data,
            registrationNumber: vehicleData?.data?.data?.registration_number,
            manufacture: vehicleData?.data?.data?.manufacturer,
            fuelType: vehicleData?.data?.data?.fuel_type,
            unladden_weight: getTonneFromKg(
              vehicleData?.data?.data?.unladden_weight
            ),
            gross_vehicle_weight: getTonneFromKg(
              vehicleData?.data?.data?.gross_vehicle_weight
            ),
            capacity: getTonneFromKg(
              +vehicleData?.data?.data?.gross_vehicle_weight -
              +vehicleData?.data?.data?.unladden_weight
            ),

            allocate: true,
          };

          const updateDriver = await Services.patch(
            `/vehicle/${Vehicle[i]?.id}`,
            payload
          );
        }
      } catch (error) {
        console.log("Error in", Vehicle[i]?.mobile);
      }
    }
  } catch (error) {
    console.log(error, "");
  }
};

//Not USe
export const removeNonReusablePackaging = async () => {
  try {
    const res = await Services.get("/packagingType?type=Non-Reusable");

    const nonReusablePackagingData =
      res?.data?.data?.map((item) => item?.id) || [];

    const packagingTracingDataRes = await Services.get("/packageTracking");

    const packagingTracingData = packagingTracingDataRes?.data?.data || [];

    for (let i = 10; i < packagingTracingData.length; i++) {
      try {
        if (
          nonReusablePackagingData.includes(
            packagingTracingData[i].packagingTypeId
          )
        ) {
          await delayOneSecond();

          const updateDriver = await Services.delete(
            `/packageTracking/${packagingTracingData[i]?.id}`
          );
        }
      } catch (error) {
        console.log(i, "index Error");
      }
    }
  } catch (error) {
    console.log(error, "");
  }
};

export const updateTrip = async () => {
  const res = await Services.get(
    CONSTANT.API.SHIPMENT.get.endpoint + `&status=2`
  );

  for (let trip of res?.data?.data) {
    let bool1 = moment(trip?.targetedDateAndTime).isBefore("2023-09-10");
    if (bool1) {
      const payload = new FormData();
      payload.append("status", 3);

      const res1 = await Services.patch(`/trip/${trip?.id}`, payload);
    }
  }
};

export const calculation = {
  carbonEmission: (value) => {
    return (+value / 1000)?.toFixed(3);
  },
};

export const tableHeaderToExportHeader = {
  header: (tableHeader) => {
    let headerGen = [];
    tableHeader?.map((it) => {
      if (it?.field != "action") {
        if (it?.field == "status") {
          // headerGen["statusHeader"] = it?.label;
          let arr = [];
          arr.push("statusHeader");
          arr.push(it?.label);
          headerGen?.push(arr);
        } else if (it?.field == "isDuplicate") {
          let arr = [];
          arr.push("isDuplicateHeader");
          arr.push(it?.label);
          headerGen?.push(arr);
        } else {
          let arr = [];
          arr.push(it?.field);
          arr.push(it?.label);
          headerGen?.push(arr);
          // headerGen[it?.field] = it?.label;
        }
      }
    });

    return headerGen;
  },
};

export const createEndPoint = {
  get: (endpoint) => {
    return { endpoint, type: "GET" };
  },
  post: (endpoint) => {
    return { endpoint, type: "POST" };
  },
  patch: (endpoint) => {
    return { endpoint, type: "PATCH" };
  },
  delete: (endpoint) => {
    return { endpoint, type: "DELETE" };
  },
};

export const updatePlant = async () => {
  const res = await Services.get("/plant");
  const plantData = res?.data?.data;

  for (let plant of plantData) {
    let payload = {};
    try {
      if (plant?.pradr == null) {
        payload.pradr = `${plant?.addressLine1}, ${plant?.addressLine2}, ${plant?.city}, ${plant?.state}, ${plant?.pincode}`;
      }
    } catch (e) {
      console.log(e);
    }
  }
};
