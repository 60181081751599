import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
  NavLink,
  Button,
} from "reactstrap";
import classnames from "classnames";
import CONSTANT, {
  StatusButton,
  TABLE_HEADER,
  TAB_DATA,
  getTableData,
} from "../../Utility/constnt";
import moment from "moment";
import useHttp from "../../../components/Hook/Use-http";

import Table from "../../../components/Custome/table";
import {
  calculatePackageCountForClient,
  getDateAndTimeColumn,
  getExtraConstPayload,
  getMobileNumber,
  getUpperCaseText,
  getVehicleNumberText,
  packegingAssetForClient,
  replaceId,
} from "../../Utility/function";
import {
  Fitsol_Team,
  customersiteHeaderData,
  tripHeaderData,
} from "../../Utility/exportValue";
import PackagingLocationDetail from "../../../components/packagingLocationDetail";
import PageTitle from "../../../components/pageTitle";
import CustomPage from "../../../components/Custome/CustomPage";
import {
  clientAccessMask,
  siteDataMask,
  tripMask,
} from "../../Utility/spread-function";
import CustomModalMain from "../../../components/Custome/CustomModalMain";
import { useSelector } from "react-redux";
import {
  deleteRoleAndAccessStart,
  getRoleAndAccessStart,
} from "../../../store/actions";
import GoogleMapModal from "../../../components/googleMapModal";
import { useParams } from "react-router-dom";

let tripCreateHeader = [];

const ClientInfo = () => {
  // let clientId = window.location.pathname.split("/")[2];
  const { clientId } = useParams();
  const [activeTab, setactiveTab] = useState(1);
  const [tripData, setTripData] = useState([]);
  const [plantData, setPlantData] = useState([]);
  const [pacakegingData, setPackagingData] = useState([]);
  const [tableHeader, setTableHeader] = useState([]);
  const [viewAction, setViewAction] = useState(null);
  const [clientData, setClientData] = useState([]);
  const [actionData, setActionData] = useState({});

  const [showModel_1, setShowModel_1] = useState(false);

  const API_CALL = useHttp();
  const getRolAndAccessData = useSelector(
    (state) => state.roleAndAccessDetailData
  );
  const getSiteData = useSelector((state) => state.plantDetailData);
  const getClientData = useSelector((state) => state.customerDetailData);
  const getShipmentData = useSelector((state) => state.shipmentDetailData);
  // const getVehicleData = useSelector((state) => state.vehicleDetailData);
  // const getDriverData = useSelector((state) => state.driverDetailData);
  // const getTransporterData = useSelector(
  //   (state) => state.transporterDetailData
  // );
  const getPackagingData = useSelector((state) => state.packagingDetailData);

  const toggleCustomJustified = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };
  useEffect(() => {
    API_CALL.getAll(
      [
        // `/trip/?clientId=${clientId}`,
        // `/plant/?clientId=${clientId}`,
        replaceId(
          CONSTANT.API.PACKAGINGASSIGNMENT.getSinglepackageDataClient,
          clientId
        ).endpoint,
        // `${
        //   localStorage.getItem("userRole") === "admin"
        //     ? `${
        //         replaceId(CONSTANT.API.CLIENT.getSingleClient, clientId)
        //           .endpoint
        //       }`
        //     : `/client/getMe`
        // }`,
      ],
      (res) => {
        packagingDataHandler(res[0]?.data);
        // tripDataHandler(res[0]?.data);
        // plantDataHandler(res[1]?.data);
        // clientDetail(res[3]?.data);
      }
    );
  }, []);

  useEffect(() => {
    setTimeout(() => {
      tripDataHandler(getShipmentData?.users?.data?.rows);
      plantDataHandler(getSiteData?.users?.data?.rows);
      clientDetail(getClientData?.users?.data?.rows);
    }, 100);
  }, [getShipmentData, getSiteData, getClientData]);

  const clientDetail = (res) => {
    setClientData(res?.find((it) => it?.id == clientId));
  };

  const tripDataHandler = (res) => {
    const clientTrip = res?.filter((itm) => itm?.clientId == clientId);

    setTripData(tripMask(clientTrip));
    // setTripData(
    //   res?.data
    //     ?.filter((itm) => itm?.clientId == clientId)
    //     ?.map((tripData, index) => {
    //       const startDate = moment(
    //         tripData?.targetedDateAndTime,
    //         "DD-MM-YYYY hh:mm A"
    //       );
    //       const completedDate = moment(tripData?.completedDateAndTime);

    //       const isStartDateAfterCompletedDate =
    //         startDate.isAfter(completedDate);
    //       return {
    //         ...tripData,
    //         no: `CO${tripData?.id + 20000000006000}`,
    //         clientName: getUpperCaseText(tripData?.client?.name),
    //         transporterName: getUpperCaseText(
    //           tripData?.transporter?.transporterName
    //         ),
    //         utilisation: parseInt(tripData?.utilisation),
    //         driverName: getUpperCaseText(tripData?.driver?.name),
    //         driverPhoneNumber: getMobileNumber(tripData?.driver?.mobile),
    //         vehicleNumber: getVehicleNumberText(
    //           tripData?.vehicle?.registrationNumber
    //         ),
    //         plantName: getUpperCaseText(tripData?.plant?.unitName),
    //         startDateAndTime: getDateAndTimeColumn(tripData?.startDateAndTime),
    //         targetedDateAndTime: getDateAndTimeColumn(
    //           tripData?.targetedDateAndTime
    //         ),
    //         targetedDate: moment
    //           .utc(tripData?.targetedDateAndTime, "DD-MM-YYYY hh:mm A")
    //           .format("DD-MM-YYYY"),
    //         startDate: moment
    //           .utc(tripData?.startDateAndTime, "DD-MM-YYYY hh:mm A")
    //           .format("DD-MM-YYYY"),
    //         startTime: moment(
    //           tripData?.startDateAndTime,
    //           "DD-MM-YYYY hh:mm A"
    //         ).format("hh:mm A"),
    //         targetedTime: moment(
    //           tripData?.targetedDateAndTime,
    //           "DD-MM-YYYY hh:mm A"
    //         ).format("hh:mm A"),
    //         startDateAfterCompletedDate: isStartDateAfterCompletedDate
    //           ? "On Time"
    //           : "Delayed",
    //         statusdata:
    //           tripData?.status === 1
    //             ? "In Transit"
    //             : tripData?.status === 2
    //             ? "In Transit"
    //             : "Completed",
    //         statusData: (
    //           <>
    //             <StatusButton value={tripData?.status} />
    //           </>
    //         ),
    //       };
    //     })
    // );
  };

  const onSubmitForm = (payload, setFormData) => {
    API_CALL.sendRequest(
      replaceId(CONSTANT.API.ROLEANDACCESS.update, actionData?.id),
      () => {
        setShowModel_1(false);
        setActionData(null);
        setFormData({});
      },
      payload,
      "Password updated"
    );
  };

  const packagingDataHandler = (res) => {
    res = res?.data;

    const possiblePackageDetails = [
      ...res?.packageData?.map((item) => {
        return {
          packagingTypeId: item?.packagingTypeId,
          name: item["packagingType.DropdownName"],
        };
      }),
      ...res?.outGoingTripsPackageData?.map((item) => {
        return {
          packagingTypeId: item?.packagingTypeId,
          name: item["packagingType.DropdownName"],
        };
      }),
    ];

    let packageTypeId = possiblePackageDetails.reduce(
      (prev, curr) => {
        if (!prev?.label?.includes(curr.packagingTypeId)) {
          prev?.label?.push(curr.packagingTypeId);
          prev?.Arr?.push(curr);
        }

        return prev;
      },
      { label: [], Arr: [] }
    ).Arr;

    let initValue = {};
    res?.packageData?.map((data) => {
      initValue = { ...initValue, [data?.plantId]: 0 };
    });

    setPackagingData(
      packageTypeId?.map((packageId) => {
        let rowData = {};
        const rowEntry = res?.packageData?.filter(
          (item) =>
            item?.packagingTypeId === packageId?.packagingTypeId &&
            item["route.routeType"] == "Forward"
        );
        rowEntry?.map((item) => {
          rowData = {
            ...rowData,

            [item?.plantId]: item?.totalCount,
          };
        });
        let plantPackageCountPackageCountAtPlant = {};
        res?.packageData
          ?.filter(
            (item) =>
              item?.packagingTypeId == packageId?.packagingTypeId &&
              item["route.routeType"] == "Forward"
          )
          ?.map((incoming) => {
            if (
              incoming["plant.unitName"] in plantPackageCountPackageCountAtPlant
            ) {
              plantPackageCountPackageCountAtPlant[
                incoming["plant.unitName"]
              ] += +incoming?.totalCount;
            } else {
              plantPackageCountPackageCountAtPlant = {
                ...plantPackageCountPackageCountAtPlant,
                [incoming["plant.unitName"]]: +incoming?.totalCount,
              };
            }
          });

        res?.outGoingTripsPackageData
          ?.filter(
            (item) => item?.packagingTypeId == packageId?.packagingTypeId
          )
          ?.map((outgoing) => {
            if (
              outgoing["trip.route.plant.unitName"] in
              plantPackageCountPackageCountAtPlant
            ) {
              plantPackageCountPackageCountAtPlant[
                outgoing["trip.route.plant.unitName"]
              ] -= +outgoing?.outGoingTripsPackageCount;
            } else
              plantPackageCountPackageCountAtPlant = {
                ...plantPackageCountPackageCountAtPlant,
                [outgoing["trip.route.plant.unitName"]]:
                  -outgoing?.outGoingTripsPackageCount,
              };

            if (outgoing["trip.status"] == "2")
              if (outgoing[outgoing["trip.route.routeName"]]) {
                outgoing[outgoing["trip.route.routeName"]] +=
                  +outgoing?.outGoingTripsPackageCount;
              } else {
                outgoing = {
                  ...outgoing,
                  [outgoing["trip.route.routeName"]]:
                    +outgoing?.outGoingTripsPackageCount,
                };
              }

            if (outgoing["trip.status"] == "3") {
              const constDestinationUnitName =
                outgoing["trip.route.destinationPlant.unitName"];
              if (
                constDestinationUnitName in plantPackageCountPackageCountAtPlant
              ) {
                plantPackageCountPackageCountAtPlant[
                  constDestinationUnitName
                ] += +outgoing?.outGoingTripsPackageCount;
              } else {
                plantPackageCountPackageCountAtPlant = {
                  ...plantPackageCountPackageCountAtPlant,
                  [constDestinationUnitName]:
                    +outgoing?.outGoingTripsPackageCount,
                };
              }
            }
          });

        Object.keys(plantPackageCountPackageCountAtPlant).map((item) => {
          plantPackageCountPackageCountAtPlant[item] < 0 &&
            delete plantPackageCountPackageCountAtPlant[item];
        });

        rowData = {
          ...initValue,
          ...rowData,
          packageName: packageId?.name,
          totalAssign: rowEntry?.reduce((total, item) => {
            total += +item?.totalCount;
            return +total;
          }, 0),
          totalAccounted: (
            <Button
              id="view"
              title="View"
              style={{
                minWidth: "70px",
                background: "transparent",
                border: "0",
                padding: "0",
                margin: "0",
              }}
              onClick={() =>
                setViewAction({
                  title: packageId?.name,
                  plantPackageCountPackageCountAtPlant:
                    plantPackageCountPackageCountAtPlant,
                  route: [
                    ...res?.outGoingTripsPackageData
                      ?.filter(
                        (item) =>
                          item["trip.status"] == "2" &&
                          item?.packagingTypeId == packageId?.packagingTypeId
                      )
                      ?.map((outgoingIntransit) => {
                        return {
                          title: outgoingIntransit["trip.route.routeName"],
                          value: outgoingIntransit?.outGoingTripsPackageCount,
                        };
                      }),
                  ],
                })
              }
            >
              {calculatePackageCountForClient(res, packageId?.packagingTypeId)}
            </Button>
          ),
        };

        return rowData;
      })
    );

    setTableHeader(packegingAssetForClient(res));
  };

  const plantDataHandler = (res) => {
    let prepareData = {
      data: {
        rows: [],
      },
    };
    prepareData.data.rows = res?.filter((itm) => itm?.clientId == clientId);

    setPlantData(siteDataMask(prepareData));
    // setPlantData(
    //   res?.data
    //     ?.filter((itm) => itm?.clientId == clientId)
    //     ?.map((plantData, index) => {
    //       return {
    //         ...plantData,
    //         no: `S${plantData?.id.toString().padStart(7, "0")}`,
    //         clientName: getUpperCaseText(plantData?.client.name),
    //         unitName: getUpperCaseText(plantData?.unitName),
    //       };
    //     })
    // );
  };

  useEffect(() => {
    if (localStorage.getItem("userRole") === "admin") {
      tripCreateHeader = [
        tripHeaderData?.filter(([key]) => {
          return ![
            "transporterName",
            "driverName",
            "driverPhoneNumber",
          ].includes(key);
        }),
      ];
    }
  }, []);

  const onCloseModal = () => {
    setViewAction(null);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <PageTitle
          pageHeading={`Customer / ${clientData?.name || ""}`}
          backNavPath={`/customers`}
          withBackButton
        />

        <Row>
          <Col sm={12} className="">
            {/* <Card>
              <CardBody> */}
            <div className="page-content mt-0 pt-0 scrollSticky ">
              <Nav tabs className="nav-tabs-custom nav-justified">
                {TAB_DATA.CLIENT_TAB.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="mt-2"
                      style={{ width: "100px" }}
                    >
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab === data.tabId,
                            activeTabDesign: activeTab === data.tabId,
                            TabDesign: true,
                          })}
                          onClick={() => {
                            toggleCustomJustified(data.tabId);
                          }}
                        >
                          <span className="d-none d-sm-block">{data.name}</span>
                        </NavLink>
                      </NavItem>
                    </div>
                  );
                })}
              </Nav>
              <TabContent activeTab={activeTab} className="mt-3 p-0 TabBody">
                <TabPane tabId={1} className="py-3  px-0">
                  <div className="custom-Table">
                    <Table
                      title="Shipment list"
                      data={getTableData(
                        TABLE_HEADER?.shipment?.filter((el) => {
                          let Hide = [];
                          Hide = [
                            "pod",
                            "mapView",
                            "billDownload",
                            "packageEmission",
                            "totalGen",
                            "invoiceNumber",
                            "packageType",
                            "action",
                          ];

                          return !Hide.includes(el);
                        }),
                        tripData
                      )}
                      exportHeader={
                        localStorage.getItem("userRole") !== "admin"
                          ? tripCreateHeader
                          : tripHeaderData
                      }
                      // exportValue={tripValueData(tripData)}
                      excelName="Shipment_Report.csv"
                      // ExportHeaders={vehicalHeaderData}
                      // ExportValues={vehicalValueData(tripData)}
                    />
                  </div>
                </TabPane>
                <TabPane tabId={2} className="py-3 px-0">
                  <div className="custom-Table">
                    <Table
                      title="Site List"
                      data={getTableData(
                        TABLE_HEADER?.site?.filter((el) => {
                          let Hide = [];
                          Hide = ["view", "access", "link", "action"];

                          return !Hide.includes(el);
                        }),
                        plantData
                      )}
                      exportHeader={customersiteHeaderData}
                      excelName="Site_Report.csv"
                    />
                  </div>
                </TabPane>
                <TabPane tabId={3} className="py-3 px-0">
                  <div className="custom-Table">
                    <Table
                      title="Packaging asset list"
                      data={{
                        columns: tableHeader,
                        rows: pacakegingData,
                      }}
                      // exportHeader={customersiteHeaderData}
                      // excelName="Site_Report.csv"
                    />
                  </div>
                </TabPane>

                <TabPane tabId={4} className="py-3 px-0">
                  <div
                  // className="page-content mt-0 pt-0 scrollSticky"
                  // style={{
                  //   paddingTop: "0",
                  //   paddingBottom: "0",
                  // }}
                  >
                    <CustomPage
                      noHeader={false}
                      PAGE={{
                        title: "Roles and access",
                        dataFunction: (res, Actions) => {
                          return clientAccessMask(
                            res,
                            clientId,
                            setShowModel_1,
                            setActionData,
                            Actions
                          );
                        },
                      }}
                      API={CONSTANT.API.ROLEANDACCESS}
                      REDUX={{
                        getDataRedux: getRolAndAccessData?.users,
                        getApiDataRedux: getRoleAndAccessStart(),
                        deleteApiDataRedux: deleteRoleAndAccessStart,
                      }}
                      FORM={{
                        formField: CONSTANT.FORM_FIELDS?.TEAM,

                        addFormTitle: "Create access",
                        editFormTitle: "Edit access",
                        addNewRecordCtaText: "Create access",
                        removeFieldOnEdit: ["password"],
                        extraConstPayload: getExtraConstPayload({
                          role: "client",
                          clientId: clientId,
                          POCName: clientData?.name,
                        }),
                      }}
                      TABLE={{
                        title: "Roles and access list",
                        header: TABLE_HEADER?.team,
                        export: {
                          fileName: clientData?.name + "_Access.csv",
                          header: Fitsol_Team,
                        },
                      }}
                      // API
                      // CreateAPI={CONSTANT.API.ROLEANDACCESS.addTeam}
                      // UpdateAPI={CONSTANT.API.ROLEANDACCESS.updateTeam}
                      // DeleteAPI={CONSTANT.API.ROLEANDACCESS.deleteTeam}
                      // GetAPI={CONSTANT.API.ROLEANDACCESS.getTeam}
                      //redux
                      // getDataRedux={getRolAndAccessData?.users}
                      // getApiDataRedux={getRoleAndAccessStart}
                      // deleteApiDataRedux={deleteRoleAndAccessStart}
                      // Model
                      // ModalName="TEAM" //Form Model
                      // tableColumn="team" //Vehicle Model
                      //Form Customization
                      // addName="Add Access "
                      // addFormTitle="Add Access"
                      // editFormTitle="Edit Access"
                      formData={true}
                      // removeFieldOnEdit={["password"]}
                      // extraConstPayload={getExtraConstPayload({
                      //   role: "client",
                      //   clientId: clientId,
                      //   POCName: clientData?.name,
                      // })}
                      // Table Customization
                      // titleHeader="Roles And Access List"
                      // datafunction={(res) => {
                      //   return clientAccessMask(
                      //     res,
                      //     clientId,
                      //     setShowModel_1,
                      //     setActionData
                      //   );
                      // }}
                      // ExportHeaders={Fitsol_Team}
                      // ExportValues={driverValueData(driverDataExcel)}
                      // excelName={clientData?.name + "_Access.csv"}
                      // viewModelData={false}
                    />
                  </div>
                </TabPane>
              </TabContent>
            </div>
            {/* </CardBody>
            </Card> */}
          </Col>
        </Row>
      </div>
      <CustomModalMain
        modalType="formModal"
        show={showModel_1}
        close={() => setShowModel_1((prev) => !prev)}
        modalTitle="Reset password"
        onSubmit={(data, setFormData) => onSubmitForm(data, setFormData)}
        data={CONSTANT.FORM_FIELDS.RESETPASSWORD}
        formData={false}
      />
      {viewAction != null && (
        <PackagingLocationDetail viewData={viewAction} onClose={onCloseModal} />
      )}
    </React.Fragment>
  );
};

export default ClientInfo;
