export const vehicleMapping = {
  registration_number: "registrationNumber",
  capacity: "capacity",
  manufacturer: "manufacture",
  fuel_type: "fuelType",
};

export const transporterMapping = {
  transporter_name: "transporterName",
  date_of_registration:"rgdt",
  business_address:"pradr",
  business_type:"ctb",
  is_field_visit_conducted:"isFieldVisitConducted",
  gst_status:"sts",
  is_aadhar_verified:"adhrVFlag"
};

export const driverMapping = {};

export const siteMapping = {
  date_of_registration:"rgdt",
  business_address:"pradr",
  business_type:"ctb",
  is_field_visit_conducted:"isFieldVisitConducted",
  gst_status:"sts",
  is_aadhar_verified:"adhrVFlag",
  pin_code: "pincode",
  state: "state",
};

export const brsrFormMapping = {
  id: "clientId", 
  value: "id",
  deadline:"deadline"
}
