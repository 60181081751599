import React from "react";
import { MDBDataTable } from "mdbreact";
import { CSVLink } from "react-csv";
import "./datatables.scss";
import { Button } from "antd";

const TableView = ({
  title,
  data = {},
  paging = true,
  searching = true,
  exportHeader = {},
  excelName = "Report.csv",
}) => {
  const isDataEmpty = data?.rows?.length <= 0;
  let CSVData = [];

  if (
    Array.isArray(exportHeader) &&
    exportHeader?.every((el) => Array.isArray(el))
  ) {
    if (!isDataEmpty) {
      CSVData[0] = exportHeader?.map((el) => {
        return el?.[1];
      });

      data?.rows?.map((item, index) => {
        CSVData[index + 1] = exportHeader
          ?.map((el) => el?.[0])
          ?.map((val) => {
            if (item != null && val in item) return item?.[val];
            return "";
          });
        return 0;
      });
    }
  } else if (Object?.keys(exportHeader)?.length > 0) {
    if (!isDataEmpty) {
      CSVData[0] = Object?.values(exportHeader);

      data?.rows?.map((item, index) => {
        CSVData[index + 1] = Object?.keys(exportHeader)?.map((val) => {
          if (item != null && val in item) return item?.[val];
          return "";
        });
        return 0;
      });
    }
  }
  return (
    <React.Fragment>
      <p
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: "16px",
        }}
      >
        {title}
        {!isDataEmpty && Object?.keys(exportHeader)?.length > 0 && (
          <CSVLink data={CSVData} filename={excelName}>
            <Button className="outline-btn waves-effect waves-light">
              Export
            </Button>
          </CSVLink>
        )}
      </p>

      <MDBDataTable
       style={{
        verticalAlign:"middle"
       }}
        className="fixed-header"
        autoWidth={false}
        responsive
        barReverse
        noRecordsFoundLabel="No record found"
        bordered
        noBottomColumns
        data={data}
        exportToCSV={true}
        striped
        disableRetreatAfterSorting
        paginationLabel={[
          <i className="bx bx-chevrons-left"></i>,
          <i className="bx bx-chevrons-right"></i>,
        ]}
        paging={paging}
        searching={searching}
      />
    </React.Fragment>
  );
};

TableView.defaultProps = {
  title: "",
  data: {
    columns: [],
    rows: [],
  },
};

export default TableView;
