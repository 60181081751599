import React, { useEffect, useState } from "react";
import PageTitle from "../../../components/pageTitle";
import { Button } from "antd";
import useHttp from "../../../components/Hook/Use-http";
import Loader from "../../../components/Loader";
import CONSTANT, {
  StatusButton,
  TABLE_HEADER,
  getTableData,
} from "../../Utility/constnt";
import Table from "../../../components/Custome/table";
import PcfServices from "../../Utility/API/pcfService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  DeleteButton,
  EditButton,
  StatusButtonDisplay,
} from "../../Utility/spread-function";
import {
  DeleteSweetAlert,
  getRecordId,
  replaceId,
} from "../../Utility/function";
import moment from "moment";
import ProductChartModal from "../../../components/pcfComponent/productChartModal";
import { MDBBadge } from "mdbreact";

const PcfProductPage = () => {
  const API_CALL = useHttp();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const currentPath = location.pathname;
  const [itemData, setItemData] = useState([]);
  const [deleteData, setDeleteData] = useState(null);
  const [chartDetailModal, setChartDetailModal] = useState(false);

  useEffect(() => {
    API_CALL.getAll(
      [
        replaceId(CONSTANT.API.PCFPRODUCT.getProductFilterByClient, id)
          .endpoint,
      ],
      (res) => {
        setItemData(
          res?.[0]?.data?.data?.rows?.map((el) => {
            return {
              ...el,
              no: getRecordId.pcfProductId(el?.id),
              productName: el?.name,
              // tags: "No tags Added",
              statusData: (
                <>
                  <StatusButtonDisplay
                    className={
                      el?.isCompleted
                        ? "bg-soft-success text-success p-1"
                        : "bg-soft-primary text-primary p-1"
                    }
                    textName={el?.isCompleted ? "Completed" : "In Draft"}
                    width="100px"
                  />
                </>
              ),
              // `${el?.isCompleted ? "Completed" : "in Draft"}`,
              dataModify: moment(el?.updatedAt).format("DD-MM-YYYY"),
              productCategory: el?.productCategory?.name,
              productWeight: el?.weight,
              carbonFootprint: el?.totalCarbonEmission,
              view: (
                <>
                  <Button
                    className="btn btn-primary waves-effect waves-light white-color"
                    color="primary"
                    id="view"
                    title="View"
                    onClick={() => onView(el)}
                    disabled={!el?.isCompleted}
                  >
                    <i className="fa fa-eye white-color"></i>
                  </Button>
                </>
              ),
              action: (
                <>
                  <EditButton
                    onClick={() => {
                      navigate(
                        `${currentPath}/pcftab?tab=product&productid=${el?.id}`
                      );
                    }}
                  />

                  <DeleteButton
                    onClick={() => {
                      setDeleteData({ ...el });
                    }}
                  />
                </>
              ),
            };
          })
        );
      },
      PcfServices
    );
  }, []);

  const onView = (el) => {
    setChartDetailModal(el);
  };

  const onAddNewProduct = () => {
    const payload = { clientId: +id };
    API_CALL.sendRequest(
      CONSTANT.API.PCFPRODUCT.create,
      (res) => {
        navigate(
          `${currentPath}/pcftab?tab=product&productid=${res?.data?.id}`
        );
      },
      payload,
      null,
      null,
      null,
      PcfServices
    );
  };
  const onDelete = () => {
    API_CALL.sendRequest(
      replaceId(CONSTANT.API.PCFPRODUCT.delete, deleteData?.id),
      () => {
        setItemData((previous) =>
          previous.filter((item) => item?.id !== deleteData?.id)
        );
        setDeleteData(null);
      },
      null,
      "Deleted successfully",
      null,
      null,
      PcfServices
    );
  };

  return (
    <>
      <div className="page-content">
        <PageTitle pageHeading={`PCF Product`} />

        <Button
          type="primary"
          className="btn btn-primary waves-effect waves-light "
          style={{
            zIndex: "50",
          }}
          onClick={() => {
            onAddNewProduct();
          }}
        >
          {"Add new product"}
        </Button>
        {API_CALL?.isLoading ? (
          <Loader />
        ) : (
          TABLE_HEADER?.product && (
            <div
              className=" mt-4 pt-0 scrollSticky custom-Table"
              style={{
                paddingTop: "0",
                paddingBottom: "0",
              }}
            >
              <Table
                title={"Product list"}
                isLoading={API_CALL?.isLoading}
                data={getTableData(TABLE_HEADER?.product, itemData)}
                //   exportHeader={TABLE?.export?.header}
                //   excelName={TABLE?.export?.fileName || []}
              />
            </div>
          )
        )}
      </div>
      {deleteData != null ? (
        <DeleteSweetAlert onDelete={onDelete} setDeleteData={setDeleteData} />
      ) : null}
      <ProductChartModal
        title={"Result"}
        open={chartDetailModal}
        onCancel={setChartDetailModal}
      />
    </>
  );
};

export default PcfProductPage;
