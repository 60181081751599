export const JOBBULK_DETAIL = "JOBBULK_DETAIL";
export const SET_JOBBULK_DETAIL = "SET_JOBBULK_DETAIL";

export const DELETE_JOBBULK_START = "DELETE_JOBBULK_START";
export const DELETE_JOBBULK_SUCCESS = "DELETE_JOBBULK_SUCCESS";
export const DELETE_JOBBULK_FAILURE = "DELETE_JOBBULK_FAILURE";

export const GET_JOBBULK_START = "GET_JOBBULK_START";
export const GET_JOBBULK_SUCCESS = "GET_JOBBULK_SUCCESS";
export const GET_JOBBULK_FAILURE = "GET_JOBBULK_FAILURE";
