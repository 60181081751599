import React, { useState, useEffect } from "react";
import { Form, Button } from "antd";
// import { useHttp } from "../../../components/Hook/Use-http";
import useHttp from "../../../components/Hook/Use-http";
import BrsrFormHeading from "../BrsrFormHeading";
import Questions from "../../../components/Report/Questions";
import { replaceFormIdandClientId, replaceId } from "../../Utility/function";
import CONSTANT from "../../Utility/constnt";
import Submit from "../submitButton";
import { StarFilled } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import { useForm } from "antd/es/form/Form";

const EmployeesWellBeing = () => {
  const { id } = useParams();
  const userRole = localStorage.getItem("userRole");
  const API_CALL = useHttp();
  const [questions, setQuestions] = useState([]);
  const getTooltipText = {
    147: "Gives insights into whether or not the employees' freedom of association & collective bargaining is respected by the entity. Data from both the previous & the current year improves comparability & transparency.",
  };
  const [loading, setLoading] = useState(true);
  const [medicalBenefits, setMedicalBenefits] = useState(null);
  const [retirementBenefits, setRetirementBenefits] = useState(null);
  const [qData, setQData] = useState(null);
  const [form] = useForm();

  useEffect(() => {
    try {
      const parser = (data = []) => {
        setQData(data);
        return data
          .map((curr) => {
            const dataSchema = JSON.parse(curr.Question.answerSchema);
            let answerString = curr.Question?.Answers[0]?.value;
            if (
              answerString &&
              (curr.Question.id === 107 || curr.Question.id === 120)
            ) {
              let parsedAnswer = JSON.parse(answerString);
              const yesStakeholders = parsedAnswer
                .filter((item) =>
                  Object.values(item).some((value) => value === "Yes")
                )
                .map((item) => {
                  const key = Object.keys(item).find((key) =>
                    key.endsWith(":benefits")
                  );
                  return item[key];
                });
              if (curr.Question.id === 107) {
                setMedicalBenefits(yesStakeholders);
              } else if (curr.Question.id === 120) {
                setRetirementBenefits(yesStakeholders);
              }
            }
            return {
              id: curr.Question.id,
              question: curr.Question.description,
              options: dataSchema.options,
              answerSchema: dataSchema,
              type: dataSchema.type,
              answer: answerString,
              valueType: curr.Question?.Answers[0]?.valueType,
              tooltip: getTooltipText?.[curr.Question.id],
            };
          })
          .filter((dataSchema) => dataSchema.answerType !== "autopopulated");
      };

      let apiData;
      let sectionId = 8; // need to call this api and call from api not statically
      if (userRole == "client") {
        API_CALL.sendRequest(
          replaceId(CONSTANT.API.GLOBALFORMS.get, sectionId),
          (res) => {
            let globalQuestions = parser(res?.data?.GlobalSectionQuestions);
            setQuestions(globalQuestions);
            setLoading(false);
          }
        );
      } else if (userRole == "admin") {
        API_CALL.sendRequest(
          replaceFormIdandClientId(
            CONSTANT.API.GLOBALFORMS.get2,
            sectionId,
            id
          ),
          (res) => {
            let globalQuestions = parser(res?.data?.GlobalSectionQuestions);
            setQuestions(globalQuestions);
            setLoading(false);
          }
        );
      } else if (userRole == "spoc") {
        API_CALL.sendRequest(
          replaceFormIdandClientId(CONSTANT.API.GLOBALFORMS.get, sectionId, id),
          (res) => {
            let globalQuestions = parser(
              res?.data[0]?.GlobalSection?.GlobalSectionQuestions
            );
            setQuestions(globalQuestions);
            setLoading(false);
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    try {
      if (medicalBenefits && medicalBenefits.length > 0 && qData.length > 0) {
          const updatedQuestions = qData.map((curr) => {
          let dataSchema = JSON.parse(curr.Question.answerSchema);

          if (curr.Question.id >= 109 && curr.Question.id <= 116) {
            dataSchema.rows_headers = medicalBenefits?.map((item) => [
              { name: item, id: item.toLowerCase().replace(/ /g, "_") },
            ]);
            dataSchema.key_map.rows = medicalBenefits?.map((item) =>
              item.toLowerCase().replace(/ /g, "_")
            );
          } else if (curr.Question.id >= 121 && curr.Question.id <= 126) {
            dataSchema.rows_headers = retirementBenefits?.map((item) => [
              { name: item, id: item.toLowerCase().replace(/ /g, "_") },
            ]);
            dataSchema.key_map.rows = retirementBenefits?.map((item) =>
              item.toLowerCase().replace(/ /g, "_")
            );
          }

          return {
            id: curr.Question.id,
            question: curr.Question.description,
            options: dataSchema.options,
            answerSchema: dataSchema,
            type: dataSchema.type,
            answer: curr.Question?.Answers[0]?.value,
            valueType: curr.Question?.Answers[0]?.valueType,
            tooltip: getTooltipText?.[curr.Question.id],
          };
        });
        setQuestions(updatedQuestions);
      }
    } catch (error) {
      console.log(error);
    }
  }, [medicalBenefits, retirementBenefits]);

  const handleSubmit = (data = {}) => {
    try {
      const formatObject = (obj) => JSON.stringify(obj).replace(/"/g, '\\"');
        const transformData = (data) => (
        {
        answers: Object.entries(data).map(([key, value]) => {
          const question = questions.find((q) => q.id === Number(key));
          let formattedValue;
          if (
            (question && question.type === "fixedtable") ||
            (question.type === "dynamictable" && question.transformedAnswer) ||
            (question.type === "dynamictables" && question.transformedAnswer)
          ) {
            formattedValue = JSON.stringify(
              question?.transformedAnswer
            )?.replace(/"/g, '"');
          } else {
            formattedValue =
              typeof value === "object"
                ? formatObject([{ id: value.key }])
                : value;
          }
          return {
            qid: Number(key),
            value: formattedValue,
          };
        }),
      });

      if (userRole === "client") {
        API_CALL.sendRequest(
          CONSTANT.API.GLOBALFORMS.create,
          null,
          transformData(data),
          "Your data added successfully"
        );
      } else if (userRole === "admin") {
        API_CALL.sendRequest(
          replaceId(CONSTANT.API.GLOBALFORMS.create2, id),
          null,
          transformData(data),
          "Your data added successfully"
        );
      }  else if (userRole === "spoc") {
        const clientId = localStorage.getItem("client");
        API_CALL.sendRequest(
          replaceId(CONSTANT.API.GLOBALFORMS.create2, clientId),
          null,
          transformData(data),
          "Your data added successfully"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ marginTop: 100 }}>
      <BrsrFormHeading title="Employees wellbeing" />
      <p style={{ fontStyle: "italic", marginBottom: "16px" }}>
        Note: Answers marked with ✨ are recommended by our AI. You can edit
        them if needed.
      </p>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <Spinner className="m-1" color="primary" />
        </div>
      ) : (
        <Form layout="vertical" onFinish={handleSubmit}>
          {questions.map((ques, index) => (
            <Questions
              key={ques.id}
              ques={ques}
              index={index}
              setMedicalBenefits={setMedicalBenefits}
              medicalBenefits={medicalBenefits}
              form={form}
            />
          ))}
          <Button
            type="primary"
            htmlType="submit"
            style={{
              position: "fixed",
              bottom: "30px",
              backgroundColor: "#060DBF",
              color: "#62C941",
              right: "74px",
              zIndex: 1000,
              // width: "125px",
              height: "40px",
              cursor: "pointer",
            }}
          >
            Save changes
          </Button>
        </Form>
      )}
    </div>
  );
};

export default EmployeesWellBeing;
