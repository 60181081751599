import React, { useEffect, useState } from 'react';
import { Input, Button, Col, Row, Form, Typography } from 'antd';
import useHttp from '../../../components/Hook/Use-http';
import CONSTANT from '../../Utility/constnt';
import { replaceId } from '../../Utility/function';
import { useParams } from 'react-router-dom';
import BrsrFormHeading from '../BrsrFormHeading';
import Submit from '../submitButton';

const { TextArea } = Input;
const { Text } = Typography;

const CSRDetails = () => {
    const API = useHttp();
    const { formId } = useParams();
    const [data, setData] = useState({ A_VI: {} });

    const handleInputChange = (e) => {
        const qnos = e.target.getAttribute('qnos');
        const name = e.target.name;
        const value = e.target.value;

        setData(prevData => ({
            ...prevData,
            A_VI: {
                ...prevData.A_VI,
                [qnos]: {
                    ...prevData.A_VI[qnos],
                    [name]: value
                }
            }
        }));
    };

    useEffect(() => {
        try {
            API.sendRequest(replaceId(CONSTANT.API.BRSRFORMS.get2, formId), (res) => {
                setData(res?.data || { A_VI: {} });
            });
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        console.log(data);
    }, [data]);

    const getInputValue = (qnos, key) => {
        return data?.A_VI?.[qnos]?.[key] || '';
    };

    const handleSubmit = () => {
        try {
            API.sendRequest(replaceId(CONSTANT.API.BRSRFORMS.create, formId), (res) => {
                console.log(res);
            }, { "data": data }, "Data saved successfully");
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div style={{ marginTop: 100 }}>
            <BrsrFormHeading
                title="CSR Details"
                subtitle="Section A: General Disclosures"
            />
            <Text>24.</Text>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        rules={[{ required: true, message: 'Please enter Yes/No' }]}
                    >
                        <div style={{ marginBottom: 3 }}>i. Whether CSR is applicable as per section 135 of Companies Act, 2013: (Yes/No)</div>
                        <Input
                            name="WhetherCSRIsApplicableAsPerSection135OfCompaniesAct2013|DCYMain"
                            qnos="24_i"
                            onChange={handleInputChange}
                            value={getInputValue('24_i', 'WhetherCSRIsApplicableAsPerSection135OfCompaniesAct2013|DCYMain')}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        rules={[{ required: true, message: 'Please enter integer value' }]}
                    >
                        <div style={{ marginBottom: 3 }}>ii. Turnover (in Rs.)</div>
                        <Input
                            name="Turnover|DCYMain"
                            qnos="24_ii"
                            onChange={handleInputChange}
                            value={getInputValue('24_ii', 'Turnover|DCYMain')}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        rules={[{ required: true, message: 'Please enter integer value' }]}
                    >
                        <div style={{ marginBottom: 3 }}>iii. Net worth (in Rs.)</div>
                        <Input
                            name="NetWorth|DCYMain"
                            qnos="24_iii"
                            onChange={handleInputChange}
                            value={getInputValue('24_iii', 'NetWorth|DCYMain')}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Submit handleSubmit={handleSubmit} loading={API.isLoading}></Submit>
        </div>

    );
};

export default CSRDetails;
