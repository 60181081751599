import React, { createRef, useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect, useDispatch, useSelector } from "react-redux";
import useHttp from "../../../components/Hook/Use-http";
import {
  allClientData,
  allDriverData,
  allPackageData,
  allPlantData,
  allTransporterData,
  allVehicleData,
  checkIsDataExist,
} from "../../Utility/helperData";
import jsPDF from "jspdf";
import { tripMask } from "../../Utility/spread-function";
import { replaceId, subTotalGen, totalGen } from "../../Utility/function";
import { tripHeaderData } from "../../Utility/exportValue";
import { Button } from "reactstrap";
import PageTitle from "../../../components/pageTitle";
import DateFilterCard from "../../../components/shipmentPage/dateFilterCard";
import Table from "../../../components/Custome/table";
import CustomModal from "../../../components/Custome/CustomModal";
import PackageModal from "../../../components/shipmentPage/packageModal";
import BillForm from "../../BillForm";
import CONSTANT, {
  TABLE_HEADER,
  getTableData,
  packageSubtype,
  siteRoute,
  viewRole,
} from "../../Utility/constnt";
import { getShipmentStart } from "../../../store/actions";
import Loader from "../../../components/Loader";
import { ROUTES } from "../../Utility/Route";
import { useParams } from "react-router-dom";
import { DatePicker } from "antd";

const { RangePicker } = DatePicker;

let tripCreateHeader = [];
const SiteViewerShipment = () => {
  const [showModel, setShowModel] = useState(false);
  const [showModel_1, setShowModel_1] = useState(false);
  const [tripData, setTripData] = useState([]);
  const [actionData, setActionData] = useState({});
  const [confirm_both, setConfirm_both] = useState(false);
  const [flag, setFlag] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [plantData, setPlantData] = useState([]);
  const [vehiclesData, setVehiclesData] = useState([]);
  const [vehiclesDataRes, setVehiclesDataRes] = useState([]);
  const [driverData, setDriverData] = useState([]);
  const [packageTypeData, setPackageTypeData] = useState([]);

  const [transporterData, setTransporterData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [downloadData, setDownloadData] = useState({});
  const [ptypeData, setPtypeData] = useState("Reusable");

  const [packageOpen, setPackageOpen] = useState(false);
  const [packageData, setPackageData] = useState({});
  const [filterParams, setFilterParams] = useState("");
  const myData = useSelector((state) => state.userGetMeData);
  const [loading, setLoading] = useState(true);

  const reportTemplateRef = useRef(null);
  const API_CALL = useHttp();
  const SEARCH_API_CALL = useHttp();
  const dispatch = useDispatch();
  const getSiteData = useSelector((state) => state.plantDetailData);
  const getClientData = useSelector((state) => state.customerDetailData);
  const getShipmentData = useSelector((state) => state.shipmentDetailData);
  const getVehicleData = useSelector((state) => state.vehicleDetailData);
  const getDriverData = useSelector((state) => state.driverDetailData);
  const getTransporterData = useSelector(
    (state) => state.transporterDetailData
  );
  const { id } = useParams();
  const getPackagingData = useSelector((state) => state.packagingDetailData);

  const packageType = [
    {
      label: `Reusable`,
      value: "Reusable",
    },
    {
      label: `Non-reusable`,
      value: "Non-Reusable",
    },
  ];

  // useEffect(() => {
  //   updateVehicleConcent();
  // }, []);

  //not admin
  useEffect(() => {
    if (localStorage.getItem("userRole") !== "admin") {
      // const tripApi = { ...CONSTANT.API.SHIPMENT.getSingleFilterTripClient };
      // tripApi.endpoint = tripApi.endpoint.replace(
      //   ":id",
      //   `${myData?.users?.id}`
      // );
      setTimeout(() => {
        if (myData?.users?.id) {
          const tripData = getShipmentData?.users?.data?.rows?.filter(
            (it) => it?.clientId == myData?.users?.id
          );
          tripDataHandler(tripData);
        }
      }, 100);

      // myData?.users?.id && API_CALL.sendRequest(tripApi, tripDataHandler);
      // API_CALL.sendRequest(CONSTANT.API.getAllPackage, packageDataHandler);
    }
  }, [flag, myData?.users, getShipmentData]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      checkIsDataExist(allPackageData)
        ? setPackageTypeData(allPackageData)
        : packageDataHandler(getPackagingData?.users, allPackageData);
      // API_CALL.sendRequest(
      //     CONSTANT.API.PACKAGEING.getPackage,
      //     packageDataHandler
      //   );
    }, 100);
  }, [getPackagingData]);

  useEffect(() => {
    const apiObject = { ...CONSTANT.API.SHIPMENT.get };

    if (filterParams !== "") {
      apiObject.endpoint += filterParams;
      SEARCH_API_CALL.sendRequest(apiObject, (res) => {
        tripDataHandler(res?.data?.rows);
      });
    } else {
      setTimeout(() => {
        tripDataHandler(getShipmentData?.users?.data?.rows);
      }, 100);
    }
  }, [flag, filterParams, getShipmentData?.users?.data]);

  const driverDataHandler = (res, allDriverData) => {
    setDriverData(res?.data?.rows);
    allDriverData = res?.data?.rows;
  };
  const packageDataHandler = (res, allPackageData) => {
    setPackageTypeData(res?.data?.rows);
    allPackageData = res?.data?.rows;
  };

  const transporterDataHandler = (res, allTransporterData) => {
    setTransporterData(res?.data?.rows);
    allTransporterData = res?.data?.rows;
  };

  const vehiclesDataHandler = (res, allVehicleData) => {
    setVehiclesData(res?.data?.rows);
    setVehiclesDataRes(res?.data?.rows);
    allVehicleData = res?.data?.rows;
  };

  const plantDataHandler = (res, allPlantData) => {
    setPlantData(res?.data?.rows);
    allPlantData = res?.data?.rows;
  };

  const clientDataHandler = (res, allClientData) => {
    setClientData(res?.data?.rows);
    allClientData = res?.data?.rows;
  };

  // const goToMapPage = (trip) => {
  //   // window.location.replace(`/tracking/${trip?.id}`);
  //   return <Link to={`/tracking/${trip?.id}`}></Link>;
  // };

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      orientation: "1",
      format: [2480, 3508],
      unit: "px",
    });

    // Adding the fonts.
    doc.setFont("Inter-Regular", "normal");

    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        await doc.save("document");
      },
    });
  };
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef([]);

  const tripDataHandler = (res) => {
    hiddenFileInput.current = res?.map?.(
      // (tripDataB, i) => hiddenFileInput?.current[i] ?? createRef()
      (tripDataB, i) => hiddenFileInput?.current?.[i] ?? createRef()
    );

    (async () => {
      // const packageRes = await Services.get(
      //   CONSTANT.API.PACKAGEING.getPackage.endpoint
      // );
      let PackagingEmission = {};
      packageTypeData?.users?.data?.map((packageData) => {
        PackagingEmission = {
          ...PackagingEmission,
          [packageData?.id]: packageData?.tCO2PerShipment,
        };
      });

      setTripData(
        tripMask(
          res,
          hiddenFileInput,
          PackagingEmission,
          handleChange,
          handleGeneratePdf,
          setDownloadData,
          onPackage,
          onEditTrip,
          setShowModel_1,
          setShowModel,
          openConfirmationDeleteModal,
          `${siteRoute}/${id}/${ROUTES.ADMIN_TRACKING}/`
        )
      );
      setLoading(false);
    })();
  };

  const openConfirmationDeleteModal = (tripData) => {
    setConfirm_both(true);
    setActionData(tripData);
  };

  const onEditTrip = (tripData) => {
    setActionData(tripData);

    setIsEdit(true);
  };

  const onPackage = (tripData) => {
    setPackageData(tripData);

    setPackageOpen(true);
  };

  const onDeleteTrip = () => {
    API_CALL.sendRequest(
      replaceId(CONSTANT.API.SHIPMENT.delete, actionData?.id),
      () => {
        setFlag((previous) => !previous);
        dispatch(getShipmentStart());
      },
      null,
      "Deleted successfully"
    );
  };

  const handleChange = (e, tripDatas) => {
    const payload = new FormData();

    // tripData.POD = e.target?.files[0];
    payload.append("POD", e.target.files[0]);

    (async () => {
      const fileUploaded = e.target.files[0];
      if (e.target?.files[0]) {
        API_CALL.sendRequest(
          replaceId(CONSTANT.API.SHIPMENT.update, tripDatas?.id),
          () => {
            setFlag((previous) => !previous);
            dispatch(getShipmentStart());
          },
          payload,
          "Shipment updated successfully"
        );
      }

      if (e.target?.files[0] === null) {
      }
    })();
  };

  const onCloseModal = () => {
    setPackageOpen(false);
    setPackageData(null);
  };

  const openNewTab = (st) => {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = st;
    a.target = "_blank";
    a.referrerPolicy = "no-referrer";
    a.click();
  };

  useEffect(() => {
    if (localStorage.getItem("userRole") != "admin") {
      tripCreateHeader = [
        tripHeaderData?.filter(([key]) => {
          return ![
            "transporterName",
            "driverName",
            "driverPhoneNumber",
          ].includes(key);
        }),
      ];
    }
    // tripHeaderData["transporterName"] = "Transporter name";
    // tripHeaderData["driverName"] = "Driver Name";
    // tripHeaderData["driverPhoneNumber"] = "Driver Contact number";

    // tripHeaderData["vehicleNumber"] = "Vehicle Number";
    // tripHeaderData["startDateAfterCompletedDate"] = "Completion status";
  }, []);

  const handlerFormSubmission = (e) => {
    e.preventDefault();
    setFilterParams(
      `&startDate=${e.target.startDate.value}&endDate=${e.target.endDate.value}`
    );
  };

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <PageTitle pageHeading={`Shipments`} />
        <div className="d-flex justify-content-between mt-3 mb-3">
          <div></div>
          <RangePicker
            onChange={(e) => {
              if (e == null) {
                setFilterParams("");
              } else {
                setFilterParams(
                  `&startDate=${e?.[0]?.format(
                    "YYYY-MM-DD"
                  )}&endDate=${e?.[1]?.format("YYYY-MM-DD")}`
                );
              }
            }}
          />
        </div>
      </div>
      {API_CALL?.isLoading || loading ? (
        <Loader />
      ) : (
        <div className="page-content mt-0 pt-0 scrollSticky custom-Table">
          <Table
            title="Shipment list"
            data={getTableData(
              TABLE_HEADER?.shipment?.filter((it) => {
                return (
                  (localStorage.getItem("userRole") == "admin" && !viewRole) ||
                  !(
                    it == "action" ||
                    it == "transporterName" ||
                    it == "pod" ||
                    it == "driverPhoneNumber" ||
                    it == "driverName"
                  )
                );
              }),
              tripData
            )}
            exportHeader={
              localStorage.getItem("userRole") !== "admin"
                ? tripCreateHeader
                : tripHeaderData
            }
            // exportValue={tripValueData(tripData)}
            excelName="Shipment_Report.csv"
          />
        </div>
      )}
      {packageOpen && (
        <PackageModal
          extraValue={{
            packageData: packageData,
            packageTypeData: packageTypeData,
            onCloseModal: onCloseModal,
          }}
        />
      )}

      {/* {confirm_both ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            onDeleteTrip();
            setConfirm_both(false);
          }}
          onCancel={() => {
            setConfirm_both(false);
          }}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null} */}
      <div className="d-none">
        <div className="" ref={reportTemplateRef}>
          <BillForm trip={downloadData} packageList={packageTypeData} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default SiteViewerShipment;
