import React, { useEffect } from "react";
import { Container, Row, Col, CardBody, Card, Button } from "reactstrap";

//Import Images
import errorImage from "../../assets/images/error-img.png";

const Datanotfound = () => {
  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody>
                  <div className="text-center p-3">
                    <div className="img">
                      <img src={errorImage} className="img-fluid" alt="" />
                    </div>

                    {/* <h1 className="error-page mt-5">
                      <span>404!</span>
                    </h1> */}
                    <h4 className="mb-4 mt-5">
                      {" "}
                      {localStorage.getItem("userRole") === "siteViewer"
                        ? "Sorry, This Account is no longer active!"
                        : " Sorry, This Account is no longer Active!"}
                    </h4>
                    <p className="mb-4 w-75 mx-auto">
                      {localStorage.getItem("userRole") === "siteViewer"
                        ? "If you believe this is a mistake, Please reach out to your organisation's admin or send a mail to "
                        : "If you believe this is a mistake, Please reach out to your organisation's admin or send a mail to "}
                      <a href="mailto:cx@fitsolscs.com">cx@fitsolscs.com</a>.
                    </p>
                    {/* <h4 className="mb-4 mt-5">Contact Us</h4>
                    <p>Email Id : admin@fitsol.com</p>
                    <p>Contact no. : 1234567890</p> */}
                    <Button
                      color="primary"
                      onClick={() => window.location.replace("logout")}
                    >
                      Home
                    </Button>

                    {/* <a
                      className="btn btn-primary mb-4 waves-effect waves-light"
                      href="/"
                    >
                      <i className="mdi mdi-home"></i> Back to Dashboard
                    </a> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Datanotfound;
