import React, { useEffect, useState } from "react";
import CONSTANT from "../../../pages/Utility/constnt";
import CustomModalMain from "../../Custome/CustomModalMain";
import { replaceId } from "../../../pages/Utility/function";

import {
  emissionEntryCategoryWiseDataLoad,
  emissionForFormDataCallLoad,
} from "../../../pages/Utility/spread-function";
import { useLocation, useParams } from "react-router";

const WasteManagment = (props) => {
  const {
    setFinancialYear,
    setInputDataForEmission,
    findEmissionData,
    subCategoryAttribute,
    parentExtraValue,
    category,
    setCategory,
    setCarbonEmission,
    setSubCategoryAttribute,
    API_CALL,
  } = props;

  const [dynamicOption, setDynamicOption] = useState({});
  const [alldynamicOption, setAllDynamicOption] = useState({});
  const [wasteTypeData, setwasteTypeData] = useState(null);
  const [wasteTreatment, setWasteTreatment] = useState([]);
  const [newFormData, setNewFormData] = useState({});

  const [formData, setFormData] = useState([]);
  // const [defaultData, setDefaultData] = useState({});
  const [cleanData, setCleanData] = useState(true);
  const { id } = useParams();
  const userRole = localStorage.getItem("userRole");
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    const api = {
      ...replaceId(
        CONSTANT.API.SUBCATEGORYATTRIBUTE.getSingleFilterSubCategoryAttribute,
        category
      ),
    };

    setCleanData(true);
    // setDefaultData({ category: category });
    api.endpoint += "&definationType=userDefined";
    category != null &&
      API_CALL.getAll(
        [
          api?.endpoint,
          CONSTANT?.API?.ATTRIBUTEOPTION.get?.endpoint,
          CONSTANT?.API.SITE.get?.endpoint,
          CONSTANT?.API.JOB.getWasteUsed?.endpoint,
          //   CONSTANT?.API.JOB.getGasUsed?.endpoint,
        ],
        (res) => {
          // setSubCategoryAttribute(res?.data);
          let categoryOption = {};
          const subCategoryAttributeRes = res[0]?.data;
          const subCategoryAttributeOptionRes = res[1]?.data?.data;
          const sites =
            userRole == "admin" && currentPath.includes("/client") && id
              ? res[2]?.data?.data?.rows?.filter((el) => el?.clientId == id)
              : res[2]?.data?.data?.rows;
          const Other = {
            site: sites,
            wasteType: res[3]?.data?.data?.rows?.map((el) => {
              categoryOption = {
                ...categoryOption,
                [el.wasteType]: el?.availableTreatments,
              };

              return {
                ...el,
              };
            }),
          };

          setwasteTypeData(res[3]?.data?.data?.rows);

          const rearrangedData = subCategoryAttributeRes?.data?.rows?.sort(
            (a, b) => +a.index - +b.index
          );
          const dddd = emissionEntryCategoryWiseDataLoad(
            subCategoryAttributeOptionRes,
            Other,
            rearrangedData,
            setDynamicOption,
            null
          );

          setAllDynamicOption((prev) => ({
            ...prev,
            ...categoryOption,
          }));
          setSubCategoryAttribute([
            ...CONSTANT.FORM_FIELDS.ADDSINGLEEMISSIONENTRY,
            ...dddd?.arr,
          ]);
          setCleanData(false);
        }
      );
  }, [category]);

  useEffect(() => {
    emissionForFormDataCallLoad(
      subCategoryAttribute,
      setDynamicOption,
      setSubCategoryAttribute,
      setFinancialYear,
      formData,
      alldynamicOption
    );
  }, [formData]);

  useEffect(() => {
    const arr = [];
    const keyOnly = subCategoryAttribute?.filter(
      (el) => el?.extraValue?.depenededFieldId
    );
    keyOnly?.map((el) => arr?.push(el?.extraValue?.depenededFieldId));

    if (newFormData?.[arr?.[0]]) {
      delete formData?.data?.[keyOnly?.[0]?.id]; //87
      delete formData?.data?.[keyOnly?.[1]?.id]; //89
      delete formData?.selectedValue?.[keyOnly?.[0]?.id]; //89
      delete formData?.selectedValue?.[keyOnly?.[1]?.id]; //87

      formData?.operations?.inputData(formData?.data);
      formData?.operations?.selectedValue(formData?.selectedValue);

      // setSubCategoryModal((pr) => [...pr]);
    } else if (newFormData?.[arr?.[1]]) {
      delete formData?.data?.[keyOnly?.[1]?.id]; //89
      delete formData?.selectedValue?.[keyOnly?.[1]?.id]; //89

      const findUnit1 = wasteTypeData?.find(
        (el) =>
          el?.wasteType == formData?.data?.[arr?.[0]] ||
          el?.wasteType == formData?.defaultData?.[arr?.[0]]
      );
      const wasteUnit1 = findUnit1?.availableTreatments?.filter(
        (ell) => ell?.value == formData?.data?.[arr?.[1]]
      );

      formData?.operations?.inputData({
        ...formData?.data,
        [keyOnly?.[1]?.id]: findUnit1?.[formData?.data?.[arr?.[1]]],
      });
      formData?.operations?.selectedValue(formData?.selectedValue);

      setNewFormData({});
    }

    const fff = { ...formData?.defaultData, ...formData?.data };

    if (fff) {
      const matchingKey = Object.keys(fff).find(
        (key) => fff[key] === "Third Party" || fff[key] === "Site Owned"
      );

      if (Object?.keys(fff)?.length > 0 || newFormData?.[matchingKey]) {
        if (newFormData?.[matchingKey]) {
          delete formData?.data?.[keyOnly?.[1]?.id]; //89
          delete formData?.selectedValue?.[keyOnly?.[1]?.id]; //89

          const findUnit1 = wasteTypeData?.find(
            (el) => el?.wasteType == fff?.[arr?.[0]]
          );

          formData?.operations?.inputData({
            ...formData?.data,
            [keyOnly?.[1]?.id]: findUnit1?.[fff?.[arr?.[1]]],
          });

          setNewFormData({});

          if (fff?.[matchingKey] == "Third Party") {
            setSubCategoryAttribute((pr) => {
              pr?.map((el) => {
                if (el?.id == keyOnly?.[1]?.id) {
                  el["disabled"] = false;
                }
              });

              return [...pr];
            });
          } else {
            setSubCategoryAttribute((pr) => {
              pr?.map((el) => {
                if (el?.id == keyOnly?.[1]?.id) {
                  el["disabled"] = true;
                }
              });

              return [...pr];
            });
          }
        }
      }
    }
  }, [formData]);

  const onSubmit = (value) => {
    const keyOnly = subCategoryAttribute?.find(
      (el) => el?.extraValue?.keyOrEquetion?.[0]
    );

    const findUnit = wasteTypeData
      ?.find(
        (el) =>
          el?.wasteType ==
          formData?.data?.[keyOnly?.extraValue?.keyOrEquetion?.[0]]
      )
      ?.availableTreatments?.filter((ell) => ell?.value == wasteTreatment);

    const e = { ...value };

    if (findUnit?.length == 0) {
      delete e?.[keyOnly?.id];
    }

    let payload = {};
    payload.createFlag = false;
    payload.subCategoryId = category;
    if (localStorage.getItem("userRole") == "admin" && id) {
      payload.clientId = id;
    }
    payload.data = e;

    Object.keys(payload.data)?.map((el) => {
      payload.data[`id${el}`] = payload.data[el];
    });
    if (findUnit?.length == 0) {
      delete payload?.data?.[keyOnly?.id];
      delete payload?.data?.[`id${keyOnly?.id}`];
    }
    setInputDataForEmission({ ...payload, createFlag: true });
    findEmissionData(payload);
  };

  return (
    <CustomModalMain
      data={subCategoryAttribute}
      // defaultData={defaultData}
      labelSize={"25%"}
      submitBtnPosition={"center"}
      fieldSize={"70%"}
      submitBtnName="Calculate Emissions"
      isSubmitButtonVisible={category != null && !API_CALL?.isLoading}
      option={{
        ...dynamicOption,
      }}
      isEdit={true}
      isCloase={cleanData}
      close={() => {
        if (cleanData) {
          setCarbonEmission(null);
          setSubCategoryAttribute([
            ...CONSTANT.FORM_FIELDS.ADDSINGLEEMISSIONENTRY,
          ]);
        }
      }}
      onSubmit={onSubmit}
      isCloseButtonVisible={false}
      minHeight={"90vh"}
      scrollCard="none"
      onSingleChange={(data) => {
        setNewFormData(data);
      }}
      onChange={(data, selectedValue, operations) => {
        const keyOnly = subCategoryAttribute?.find(
          (el) => el?.extraValue?.keyOrEquetion?.[0]
        );
        setWasteTreatment(data?.[keyOnly?.id]);

        setFormData({ data, selectedValue, operations });
      }}
    />
  );
};

export default WasteManagment;
