// import { ROUTE_DETAIL } from "./actionTypes";

// // export const getMyProfile = () => ({
// //   type: GET_MY_PROFILE,
// // });

// export const getRouteDetailSuccess = (users) => {
//   return {
//     type: ROUTE_DETAIL,
//   };
//   // return {
//   //   type: GET_DRIVER_DETAIL_SUCCESS,
//   //   payload: users,
//   // };
// };

import {
  GET_ROUTE_START,
  GET_ROUTE_SUCCESS,
  GET_ROUTE_FAILURE,
  DELETE_ROUTE_START,
  DELETE_ROUTE_SUCCESS,
  DELETE_ROUTE_FAILURE,
} from "./actionTypes";

//GET
export const getRouteStart = (api = null) => {
  return {
    type: GET_ROUTE_START,
    payload: api,
  };
};
export const getRouteSuccess = (users) => {
  return {
    type: GET_ROUTE_SUCCESS,
    payload: users,
  };
};
export const getRouteError = (error) => {
  return {
    type: GET_ROUTE_FAILURE,
    payload: error,
  };
};

//DELETE
export const deleteRouteStart = (id, successCallback) => ({
  type: DELETE_ROUTE_START,
  payload: id,
  successCallback,
});

export const deleteRouteSucess = (id) => ({
  type: DELETE_ROUTE_SUCCESS,
  payload: id,
});

export const deleteRouteError = (error) => ({
  type: DELETE_ROUTE_FAILURE,
  payload: error,
});
