import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import defaultImage from "../../../assets/images/UserImage.jpg";
import { BellOutlined } from "@ant-design/icons";
import { Badge } from "antd";
import { Col } from "antd";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
// users
import user4 from "../../../assets/images/users/avatar-4.jpg";
import { roles } from "../../../pages/Utility/constnt";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }
  return ComponentWithRouterProp;
}

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const myData = useSelector((state) => state.userGetMeData);
  const navigate = useNavigate();
  const [username, setusername] = useState("Admin");
  const { id } = useParams();

  const location = useLocation();
  const userRole = localStorage.getItem("userRole");
  const currentPath = location.pathname;
  let profilePath = `/${userRole}/${id ? `${id}/` : ""}profile`;
  if (currentPath.includes(userRole) && currentPath.includes("admin")) {
    profilePath = `/${userRole}/profile`;
  } else if (
    !currentPath.includes(userRole) &&
    currentPath.includes("client")
  ) {
    profilePath = `/client/${id ? `${id}/` : ""}profile`;
  } else if (
    !currentPath.includes(userRole) &&
    currentPath.includes("siteViewer")
  ) {
    profilePath = `/siteViewer/${id ? `${id}/` : ""}profile`;
  }
  const [unverifiedUsersCount, setUnverifiedUsersCount] = useState(JSON.parse(localStorage.getItem("unverifiedUsersCount")) || 0);
  useEffect(() => {
    const updateUnverifiedUsersCount = () => {
      const count = localStorage.getItem("unverifiedUsersCount");
      if (count) {
        setUnverifiedUsersCount(parseInt(count, 10));
      }
    };
    window.addEventListener(
      "storage",
      updateUnverifiedUsersCount
    );
    return () => {
      window.removeEventListener(
        "storage",
        updateUnverifiedUsersCount
      );
    };
  }, []);




  useEffect(() => {
    // if (localStorage.getItem("authUser")) {
    //   if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
    //     const obj = JSON.parse(localStorage.getItem("authUser"));
    //     setusername(obj.displayName);
    //   } else if (
    //     process.env.REACT_APP_DEFAULTAUTH === "fake" ||
    //     process.env.REACT_APP_DEFAULTAUTH === "jwt"
    //   ) {
    //     const obj = JSON.parse(localStorage.getItem("authUser"));
    //     setusername(obj.username);
    //   }
    // }
  }, [props.success]);

  return (
    <React.Fragment>
      {currentPath.includes("client") && (
        <div style={{ paddingTop: "20px", paddingRight: "10px" }}>
          <Link to={{ pathname: profilePath, state: { activeTab: 3 } }}>
            {unverifiedUsersCount > 0 && (
              <Col sm={2} md={2}>
                <Badge count={unverifiedUsersCount}>
                  <BellOutlined style={{ fontSize: "24px", color: "white" }} />
                </Badge>
              </Col>
            )}
          </Link>
        </div>
      )}
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={
              myData?.users?.profilePic
                ? myData?.users?.profilePic
                : defaultImage
            }
            alt="Header Avatar"
          />{" "}
          <span className="d-none d-xl-inline-block ms-1">
            {myData?.users?.name}
          </span>{" "}
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>{" "}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {localStorage.getItem("userRole") != "tripCreator" ? (
            <>
              <DropdownItem
                tag="div"
                // href={`${profilePath}`}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(profilePath);
                }}
              >
                {" "}
                <i className="bx bx-user font-size-16 align-middle me-1"></i>{" "}
                {props.t("View profile")}{" "}
              </DropdownItem>
              <div className="dropdown-divider" />
            </>
          ) : null}

          <Link to="/logout" className="dropdown-item text-danger">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>{" "}
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
