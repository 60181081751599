import { Col, Row } from "antd";
import React from "react";

const ResultTabFooterPrGen = (props) => {
  const { calculateData, series, bottom = "24%" } = props;
  return (
    <>
      <Row
        style={{
          position: "absolute",
          width: "95%",
          bottom: `${bottom}`,
        }}
      >
        <Col xs={0} sm={1} lg={5} xl={5}></Col>
        <Col xs={0} sm={23} lg={19} xl={19}>
          <div className="grid-temp w-full">
            {series
              ? series[0]?.data?.map((el, index) => {
                  return (
                    <div className="grid-row-5-custom" key={`he-${index}`}>
                      {/* <p className="pcf-total-carbon-label text-uppercase">
                        {el?.x}
                      </p> */}

                      <p
                        className="font-poppis m-0"
                        style={{ fontWeight: "500", fontSize: "18px" }}
                      >
                        {el?.percentage}%
                        {/* <span>
                          kgCO<sub>2</sub>e
                        </span> */}
                      </p>
                    </div>
                  );
                })
              : null}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ResultTabFooterPrGen;
