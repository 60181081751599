import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Row, Col, Card, Alert, CardBody, Button } from "reactstrap";
// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";
import CONSTANT, { MyData } from "../Utility/constnt";
import CustomForm from "../../components/Custome/CustomForm";
import useHttp from "../../components/Hook/Use-http";
import defaultImage from "../../assets/images/UserImage.jpg";
import { getUsersDetailSuccess } from "../../store/getMeData/actions";
import CustomModalMain from "../../components/Custome/CustomModalMain";
import { withRouter } from "../Utility/function";
const UserProfile = (props) => {
  const [flag, setFlag] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const API_CALL = useHttp();
  const dispatch = useDispatch();

  const myData = useSelector((state) => state.userGetMeData);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      API_CALL.sendRequest(CONSTANT.API.ADMIN.getMe, getMeDataHandler);
    }
  }, [flag]);

  const getMeDataHandler = (res) => {
    setUserDetail(res.data);
    dispatch(getUsersDetailSuccess(res?.data));

    MyData.data = res.data;
  };

  const onSubmitForm = (payload) => {
    (async () => {
      API_CALL.sendRequest(
        CONSTANT.API.ADMIN.adminUpdate,
        updateMeHandler,
        payload,
        "Your data was updated successfully"
      );
    })();
  };

  const updateMeHandler = () => {
    setFlag(!flag);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">PROFILE</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Welcome {myData?.users?.name}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </Row>

        <Row>
          <Col lg="12">
            {props.error && props.error ? (
              <Alert color="danger">{props.error}</Alert>
            ) : null}
            {props.success && props.success ? (
              <Alert color="success">{props.success}</Alert>
            ) : null}

            {/* <Card>
              <CardBody>
                <div className="d-flex">
                  <div className="ms-3">
                    <img
                      src={
                        userDetail?.profilePic
                          ? userDetail?.profilePic
                          : defaultImage
                      }
                      alt=""
                      className="avatar-md rounded-circle img-thumbnail"
                    />
                  </div>
                  <div className="flex-1 align-self-center ms-3">
                    <div className="text-muted">
                      <h5>{userDetail?.name}</h5>
                      <p className="mb-1">{userDetail?.email}</p>
                      <p className="mb-0">{userDetail?.name}</p>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card> */}
          </Col>
        </Row>
        <Card>
          <CardBody>
            <CustomModalMain
              data={
                CONSTANT.FORM_FIELDS[
                  `${localStorage.getItem("userRole")}_PROFILE`
                ]
              }
              isEdit = {true}
              defaultData={userDetail}
              labelSize={"25%"}
              submitBtnPosition={"center"}
              fieldSize={"70%"}
              // submitBtnName="Calculate Shipment Emission"
              onSubmit={(data) => onSubmitForm(data)}
              isCloseButtonVisible={false}
              minHeight={"300px"}
              onChange={(data) => {}}
              formData={true}
            />
            {/* <CustomForm
              data={
                CONSTANT.FORM_FIELDS[
                  `${localStorage.getItem("userRole")}_PROFILE`
                ]
              }
              onSubmit={(data) => onSubmitForm(data)}
              defaultData={userDetail}
              formData={true}
              // isEdit={isEdit}
            /> */}
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, { editProfile, resetProfileFlag })(UserProfile)
);
