import React from "react";
import { Card, CardBody, CardTitle, Modal } from "reactstrap";
import TrackIdSvg from "../../pages/Tracking/SVG/TrackIdSvg";
import moment from "moment";
import CalanderIconSvg from "../../pages/Tracking/SVG/CalanderIconSvg";
import LocationIconSvg from "../../pages/Tracking/SVG/LocationIconSvg";
import { getVehicleNumberText } from "../../pages/Utility/function";
import defaultImage from "../../assets/images/UserImage.jpg";

const ShipmentModal = (props) => {
  const { extraValue } = props;
  return (
    <>
      <Modal
        isOpen={extraValue?.isModalShow}
        toggle={() => {
          extraValue?.setIsModalShow((previous) => !previous);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Shipment Details
          </h5>
          <button
            type="button"
            onClick={() => {
              extraValue?.setIsModalShow(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Card style={{ overflowWrap: "anywhere" }}>
          <CardBody>
            <div className="modal-body">
              <CardTitle className="font-size-12">
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "10px" }}
                >
                  <TrackIdSvg />
                  <div className="trackId ">
                    <p className="m-0 ms-0 fs-5">{`CO${
                      20000000006000 + extraValue?.selectTripData?.id
                    }`}</p>

                    <p
                      className="m-0 ms-0 fs-5"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "100%",
                      }}
                    >
                      {`( ${extraValue?.selectTripData?.client?.name} )`}
                    </p>
                  </div>
                </div>
              </CardTitle>

              <div className="mt-3 d-flex">
                <strong className="ftext-truncate font-size-18 text-dark bold">
                  {"Vehicle"}
                </strong>{" "}
                &nbsp;
              </div>

              <div
                className="d-flex flex-column"
                style={{
                  letterSpacing: "1px",
                }}
              >
                <div className="d-flex">
                  <p className="m-0">
                    {getVehicleNumberText(
                      extraValue?.selectTripData?.vehicle?.registrationNumber,
                      "unset"
                    )}
                  </p>
                  <p className="text-truncate m-0">
                    &nbsp;({extraValue?.selectTripData?.vehicle?.fuelType})
                  </p>
                </div>
                <div className="font-size-15">
                  {extraValue?.selectTripData?.time}
                </div>
              </div>

              <hr></hr>

              {localStorage.getItem("userRole") == "admin" ||
              localStorage.getItem("userRole") == "tripCreator" ? (
                <>
                  <div className="d-flex align-items-start">
                    <div className="me-3 align-self-center">
                      <img
                        src={
                          extraValue?.selectTripData?.driver?.profilePic?.includes(
                            "https"
                          )
                            ? extraValue?.selectTripData?.driver?.profilePic
                            : defaultImage
                        }
                        alt=""
                        className="avatar-sm rounded-circle"
                      />
                    </div>
                    <div className="flex-1 overflow-hidden">
                      <h5 className="font-size-16 mb-1 text-dark">
                        {extraValue?.selectTripData?.driver?.name}
                      </h5>
                      <p className="text-truncate mb-0">
                        {" "}
                        {extraValue?.selectTripData?.driver?.mobile}{" "}
                        {extraValue?.selectTripData?.driver?.consent ===
                        "ALLOWED" ? (
                          <div className="card_Header_arrow d-flex align-items-center">
                            <p className="m-0 text-success fw-bolder">
                              Consent Is Allowed
                            </p>
                          </div>
                        ) : (
                          <div className="card_Header_arrow_false d-flex align-items-center">
                            <p className="m-0 text-danger fw-bolder">
                              Consent Is Pending
                            </p>
                          </div>
                        )}
                      </p>
                    </div>
                  </div>
                  <hr></hr>
                </>
              ) : null}

              <div>
                <div className="d-flex row">
                  <div
                    className="d-flex align-items-center bg-soft-primary modalCalender col-6"
                    style={{ border: "1px solid blue" }}
                  >
                    <CalanderIconSvg />
                    <div className="ms-2">
                      <p className="m-0 font-size-10">
                        {moment(extraValue?.selectTripData?.createdAt)
                          .local()
                          .format("DD-MM-YYYY")}
                      </p>
                      <p className="m-0">
                        {" "}
                        {moment(extraValue?.selectTripData?.createdAt)
                          .local()
                          .format("LT")}
                      </p>
                    </div>
                  </div>
                  <div className="modalCalendertwo d-flex align-items-center col-6">
                    <div>
                      <LocationIconSvg />
                    </div>
                    <div>
                      <p className="text m-0 ms-2 font-size-10">
                        Source Location
                      </p>
                      <p className="text m-0 ms-2 font-size-10">
                        {extraValue?.selectTripData?.plant?.unitName}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row-">
                  <div className="col-5 d-flex justify-content-center">
                    <p className="calenderLine m-0"></p>
                  </div>
                </div>
                <div className="d-flex row">
                  <div
                    className="d-flex align-items-center bg-soft-danger modalCalender col-6"
                    style={{ border: "1px solid red" }}
                  >
                    <CalanderIconSvg />
                    <div className="ms-2">
                      <p className="m-0 font-size-10">
                        {moment
                          .utc(extraValue?.selectTripData?.targetedDateAndTime)
                          .format("DD-MM-YYYY")}
                      </p>
                      <p className="m-0">
                        {moment
                          .utc(extraValue?.selectTripData?.targetedDateAndTime)
                          .format("LT")}
                      </p>
                    </div>
                  </div>
                  <div className="modalCalendertwo d-flex align-items-center col-6">
                    <div>
                      <LocationIconSvg />
                    </div>
                    <div>
                      <p className="text m-0 ms-2 font-size-10">
                        Destination Location
                      </p>
                      <p className="text m-0 ms-2 font-size-10">
                        {extraValue?.selectTripData?.destinationPlant?.unitName}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Modal>
    </>
  );
};

export default ShipmentModal;
