import React,{useState, useEffect} from 'react'
import CustomPage from '../../../../components/Custome/CustomPage'
import CONSTANT, {connectionStatusList, TABLE_HEADER} from '../../../Utility/constnt'
import { useParams } from 'react-router-dom';
import useHttp from '../../../../components/Hook/Use-http';
import { createEndPoint, replaceClientId, replaceId, replaceIdClientId } from '../../../Utility/function';
import {Col,Form, Row, Select} from 'antd';
import { connectionReceivedDataMask } from '../../../Utility/spread-function';
import { connectionRecievedHeaderData } from '../../../Utility/exportValue';
import { clientId } from '../../../Utility/models/form-model';
const ConnectionRecieved = () => {
    const { id } = useParams();
    const role = localStorage.getItem("userRole");
    const [flag, setFlag] = useState(false);
  return (
    <div className="w-full">
        <CustomPage
        PAGE={{
          title: "Connections recieved",
          dataFunction : (res, actions) => {
            return connectionReceivedDataMask(res, actions, setFlag);
          },
          pageRefresh: {flag}
        }}
        API={{
          ...CONSTANT.API.MANAGE_CONNECTIONS_INCOMING,
          get: createEndPoint.get(
            role === "admin"
            // ?status=${connectionStatus}&
              ? `${role}/connections?type=incoming&clientId=${id}`
              : `${role}/connections?type=incoming`
          ),
        }}
        FORM={{
          formField: CONSTANT.FORM_FIELDS?.CONNECTION_UPDATE,
          addFormTitle: "Add update",
          editFormTitle: "Update Connection status",
          extraConstPayload: [
            {
              id: "clientId",
              value: id,
            },
          ],
          option: {
            status: connectionStatusList?.map(d => {
                return {label:d, value:d}
            }),
          },
        }}
        TABLE={{
          title: "Forms list",
          header: TABLE_HEADER?.connectionReceived,
          export: {
            fileName: "connectionsRecievedList.csv",
            header: connectionRecievedHeaderData,
          },
        }}
        connectionRecieved = {true}
      />
    
  </div>
  )
}

export default ConnectionRecieved