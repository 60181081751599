import React, { useEffect } from "react";
import {
  customerWiseHeaderData,
  transporterWiseHeaderData,
} from "../../pages/Utility/exportValue";
import { Col, Row } from "reactstrap";
import Table from "../../components/Custome/table";
import CONSTANT from "../../pages/Utility/constnt";
import {
  headerForClientSiteGen,
  headerForScopeGen,
  headerFortSiteGen,
} from "../../pages/Utility/spread-function";
import { useParams } from "react-router-dom";

const TableGen = (props) => {
  const {
    transporterList = null,
    myData,
    showEmission = true,
    tripList,
    tableHeading,
    categoryDataLabel,
    siteWiseList,
    scope3List,
    scope2List,
    scope1List,
    title,
  } = props;
  const { id } = useParams();

  let exportFormate, exportForScope;
  if (scope1List == true || scope2List == true || scope3List == true) {
    exportForScope = headerForScopeGen(categoryDataLabel, id) || [];
  } else {
    exportFormate = headerFortSiteGen(categoryDataLabel, id, scope2List) || [];
  }

  // const exportFormate =

  let data = [];
  if (scope1List) {
    data = {
      columns: tableHeading,
      rows: tripList,
    };
  } else if (scope2List) {
    data = {
      columns: tableHeading,
      rows: tripList,
    };
  } else if (scope3List) {
    data = {
      columns: tableHeading,
      rows: tripList,
    };
  }

  const updatedData = {
    ...data,
    rows: data?.rows
      ?.map((row) => {
        const newRow = { ...row };
        data?.columns?.forEach((column) => {
          const { field } = column;
          if (field && newRow[field] !== undefined && !isNaN(newRow[field])) {
            newRow[field] = parseFloat(newRow[field]).toFixed(0);
          } else if (newRow[field] === undefined || newRow[field] === "") {
            newRow[field] = "0";
          }
        });
        return newRow;
      })
      .filter((row) => {
        return row?.Scope_3 !== "0.000";
      })
      .filter((row) => {
        return row?.Scope_2 !== "0.000";
      })
      .filter((row) => {
        return row?.Scope_1 !== "0.000";
      }),
  };
  return (
    <>
      {" "}
      <Row>
        {transporterList != null ? (
          <Col lg={6} className="dashboard-tb">
            <Table
              title={<>Transporter-wise emission</>}
              data={{
                columns: CONSTANT.DATA_TABLE_COLUME.Transporter_emmited,
                rows: transporterList,
              }}
              paging={false}
              searching={false}
              exportHeader={transporterWiseHeaderData}
            />
          </Col>
        ) : null}
        {/* </Row>
      <Row> */}

        {siteWiseList != null ? (
          <Col lg={transporterList != null ? 6 : 12} className="dashboard-tb">
            <Table
              title={
                myData?.users?.role === "admin" ? (
                  showEmission ? (
                    <>Customer-wise emission</>
                  ) : (
                    "Customer Carbon Efficiency"
                  )
                ) : showEmission ? (
                  <>Site-wise emission</>
                ) : (
                  "Customer Carbon Efficiency"
                )
              }
              data={{
                columns:
                  myData?.users?.role === "admin"
                    ? CONSTANT.DATA_TABLE_COLUME.Transporter_emmited
                    : CONSTANT.DATA_TABLE_COLUME.Site_emmited,
                rows: tripList,
              }}
              paging={false}
              searching={false}
              exportHeader={exportFormate}
            />
          </Col>
        ) : null}

        {scope3List != null ? (
          <Col lg={transporterList != null ? 6 : 12} className="dashboard-tb">
            <Table
              title={title}
              data={updatedData}
              paging={false}
              searching={false}
              exportHeader={exportForScope}
              responsive={true}
              // exportHeader={exportFormate}
            />
          </Col>
        ) : null}
        {scope2List != null ? (
          <Col lg={transporterList != null ? 6 : 12} className="dashboard-tb">
            <Table
              title={title}
              data={updatedData}
              paging={false}
              searching={false}
              exportHeader={exportForScope}
              responsive={true}
            />
          </Col>
        ) : null}
        {scope1List != null ? (
          <Col lg={transporterList != null ? 6 : 12} className="dashboard-tb">
            <Table
              title={title}
              data={updatedData}
              paging={false}
              searching={false}
              exportHeader={exportForScope}
              responsive={true}
            />
          </Col>
        ) : null}
      </Row>
    </>
  );
};

export default TableGen;
