import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import PropTypes from "prop-types";
import {
  GoogleApiWrapper,
  InfoWindow,
  Map,
  Marker,
  Polyline,
} from "google-maps-react";
// import Location_Blue from "../../assets/images/Location_Blue.svg";
import Location_Blue from "../assets/images/Location_Blue.svg";

import { connect } from "react-redux";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import notify from "../pages/Utility/coustemFunction";
import { notifications } from "../pages/Utility/function";

const LoadingContainer = () => <div></div>;

const GoogleMapModal = (props) => {
  const {
    isOpen,
    inputData,
    setmapViewOpen,
    autoSerach,
    onSubmit,
    viewOnly = false,
  } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [clickLatLong, setClickLatLong] = useState(false);
  const [loader, setLoader] = useState(false);
  const toggleModal = () => {
    setModalOpen(false);
    setmapViewOpen(false);
  };

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);
  useEffect(() => {
    if (viewOnly == true) {
      geoLocation(inputData?.latitude, inputData?.logtitude);
    } else {
      inputData?.latitude &&
        setTimeout(() => {
          setClickLatLong({
            markerPosition: {
              lat: inputData?.latitude,
              lng: inputData?.logtitude,
            },
            mapCenter: {
              lat: inputData?.latitude,
              lng: inputData?.logtitude,
            },
            locationName: inputData?.locationName,
            locationData: inputData?.locationData,
          });
        }, 100);
    }
  }, [inputData?.latitude]);

  const onSubmitData = () => {
    if (clickLatLong?.mapCenter && clickLatLong?.locationName) {
      onSubmit({
        lat: clickLatLong?.mapCenter?.lat,
        lng: clickLatLong?.mapCenter?.lng,
        locationName: clickLatLong?.locationName,
        locationData: clickLatLong?.locationData,
      });
      toggleModal();
    } else {
      // notify.error("Please Select Location");
      notifications.error("Please Select Location");
    }
  };

  const geoLocation = (lat, lng) => {
    const geocoder = new props.google.maps.Geocoder();

    const latLng = new props.google.maps.LatLng(lat, lng);
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK" && results[0]) {
        // let locationName = results[0].formatted_address?.toString();
        let arr = [];
        results[0]?.address_components?.map((it, i) => {
          if (i != 0) {
            arr.push(it?.long_name);
          }
        });
        const formatted_string = arr?.join(", ");
        setClickLatLong({
          markerPosition: { lat, lng },
          mapCenter: { lat, lng },
          locationName: formatted_string,
          locationData: results[0]?.place_id,
        });
      } else {
        console.error(
          "Reverse geocoding was not successful for the following reason: " +
            status
        );
      }
    });
  };

  const onMapClick = (markerProps, marker, clickEvent) => {
    // Get the clicked position
    const lat = clickEvent.latLng.lat();
    const lng = clickEvent.latLng.lng();
    // Update the marker position and map center
    geoLocation(lat, lng);
    // setClickLatLong({
    //   markerPosition: { lat, lng },
    //   mapCenter: { lat, lng },
    // });
  };

  const onChangeGoogleAddress = (e) => {
    geocodeByPlaceId(e.value.place_id)
      .then((results) => {
        setClickLatLong({
          markerPosition: {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          },
          mapCenter: {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          },
          locationName: results[0]?.formatted_address,
          locationData: e.value.place_id,
        });
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      {/* <Col sm={6} md={4} xl={4}> */}
      <div>
        <Modal
          isOpen={modalOpen}
          toggle={toggleModal}
          style={{ maxWidth: "800px" }}
        >
          {/* <ModalHeader toggle={toggleModal}>Modal Title</ModalHeader> */}
          <ModalBody>
            {/* Your modal content goes here */}
            <div style={{ width: "100%" }}>
              {autoSerach && (
                <GooglePlacesAutocomplete
                  apiKey={process.env.REACT_APP_MAP_KEY}
                  apiOptions={{
                    types: ["(cities)"],
                    componentRestrictions: { country: "IN" },
                  }}
                  // defaultValue={clickLatLong?.locationName}
                  // value={clickLatLong?.locationName}

                  selectProps={{
                    value:
                      clickLatLong?.locationData && clickLatLong?.locationName
                        ? {
                            label: clickLatLong?.locationName,
                            value: {
                              place_id: clickLatLong?.locationData,
                            },
                          }
                        : null,
                    //   defaultInputValue: inputData?.locationName,
                    onChange: (e) => onChangeGoogleAddress(e),
                  }}
                />
              )}

              <div className="mt-3" style={{ height: "50vh" }}>
                {/* {clickLatLong != false && ( */}
                <Map
                  google={props.google}
                  style={{
                    height: ` ${"50vh"}`,
                    maxWidth: "96vh",
                    borderRadius: "10px",
                  }}
                  containerStyle={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                  }}
                  clickableIcons={true}
                  zoom={17}
                  center={clickLatLong?.mapCenter}
                  fullscreenControl={false}
                  centerAroundCurrentLocation={true}
                  onClick={onMapClick}
                  className="fit-map-wrap"
                >
                  {/* clickLatLong */}
                  {clickLatLong?.markerPosition != null && (
                    // <>

                    <Marker
                      title="Location"
                      position={clickLatLong?.markerPosition}
                      draggable={true}
                      icon={{
                        url: Location_Blue,
                        fillColor: "#000000",
                      }}
                      onDragend={onMapClick}
                    ></Marker>
                  )}
                </Map>
                {/* )} */}
              </div>
              {clickLatLong?.locationName != null && (
                <h1
                  style={{
                    position: "absolute",
                    marginTop: "-50px",
                    backgroundColor: "#ffffffd6",
                    borderRadius: "8px",
                    padding: "10px 15px",
                    fontSize: "15px",
                    marginLeft: "10px",
                    display: "flex",
                  }}
                >
                  <i className="bx bxs-map" style={{ fontSize: "18px" }}></i>
                  {clickLatLong?.locationName}
                </h1>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                toggleModal();
              }}
            >
              Cancel
            </Button>
            {!viewOnly && (
              <Button color="primary" onClick={onSubmitData}>
                Confirm
              </Button>
            )}
          </ModalFooter>
        </Modal>
      </div>
      {/* </Col> */}
    </>
  );
};
GoogleMapModal.propTypes = {
  google: PropTypes.object,
};

// export default GoogleMapModal;
export default connect(null)(
  GoogleApiWrapper({
    apiKey: process.env.REACT_APP_MAP_KEY,
    LoadingContainer: LoadingContainer,
    v: "3",
  })(GoogleMapModal)
);
