import { useEffect, useState } from "react";
import CustomPage from "../../../Custome/CustomPage";
import useHttp from "../../../Hook/Use-http";
import CONSTANT, {
  getTableColumConfig,
} from "../../../../pages/Utility/constnt";
import { useLocation, useParams } from "react-router-dom";
import {
  emissionEntryCategoryWiseDataLoad,
  emissionForEditFormDataCallLoad,
  jobViewDataMask,
} from "../../../../pages/Utility/spread-function";
import {
  createEndPoint,
  replaceId,
  tableHeaderToExportHeader,
} from "../../../../pages/Utility/function";

const EmployeeCommuteSingleView = ({
  category,
  initHeader,
  flag,
  ...props
}) => {
  const API = useHttp();
  const { id } = useParams();
  const location = useLocation();
  const [header, setHeader] = useState([]);
  const [editData, setEditData] = useState({});
  const [formData, setFormData] = useState([]);
  const [dynamicOption, setDynamicOption] = useState({});
  const [alldynamicOption, setAllDynamicOption] = useState({});
  const [subCategoryModal, setSubCategoryModal] = useState([]);

  const currentPath = location.pathname;
  const userRole = localStorage.getItem("userRole");

  useEffect(() => {
    category &&
      API?.getAll(
        [
          CONSTANT?.API.SUBCATEGORYATTRIBUTE.get.endpoint,
          CONSTANT?.API?.ATTRIBUTEOPTION.get?.endpoint,
          CONSTANT?.API.SITE.get?.endpoint,
          CONSTANT?.API.JOB.getEmployeeLabelCommute?.endpoint,
        ],
        (res) => {
          let categoryOption = {};
          // extract Sub-Category Attribute Options
          const subCategoryAttributeOptions = res[1]?.data?.data;
          const sites =
            userRole == "admin" && currentPath.includes("/client") && id
              ? res[2]?.data?.data?.rows?.filter((el) => el?.clientId == id)
              : res[2]?.data?.data?.rows;
          const Other = {
            site: sites,
            employeeMode: res[3]?.data?.data?.rows,
          };

          // Filter out Sub-Category Attributes According to given category
          const rearrangedData = res[0]?.data?.data?.rows
            ?.filter((it) => it?.subCategoryId == category?.value)
            ?.sort((a, b) => +a.index - +b.index);

          // contains default fields to show in header of table
          let arrHeader = [...initHeader];

          const transformedData = emissionEntryCategoryWiseDataLoad(
            subCategoryAttributeOptions,
            Other,
            rearrangedData,
            setDynamicOption,
            arrHeader
          );

          setSubCategoryModal(transformedData?.arr);
          setAllDynamicOption((prev) => ({
            ...prev,
            ...categoryOption,
          }));
          // add action buttons to header
          setHeader([
            ...transformedData?.arrHeader,
            getTableColumConfig("action"),
          ]);
        }
      );
  }, [category]);

  useEffect(() => {
    emissionForEditFormDataCallLoad(
      subCategoryModal,
      setDynamicOption,
      setSubCategoryModal,
      formData,
      alldynamicOption,
      editData
    );
  }, [formData, editData]);

  const prepareUpdatePayload = (editPayload, payload) => {
    const objPayload = {
      data: {
        ...editPayload,
        ...payload,
      },
    };
    delete objPayload?.data?.id;

    Object?.keys(objPayload?.data)?.map((itm) => {
      objPayload.data[`id${itm}`] = payload[itm] || editPayload[itm];
    });

    return {
      ...objPayload,
    };
  };

  return (
    <CustomPage
      PAGE={{
        title: `Record List`,
        dataFunction: (res, Actions) => {
          return jobViewDataMask(res, Actions);
        },
        pageRefresh: { flag },
      }}
      noHeader={false}
      API={{
        get: createEndPoint.get(
          userRole === "admin"
            ? `${userRole}/uploadShipmentData/getFormData/${category?.value}?clientId=${id}`
            : `${userRole}/uploadShipmentData/getFormData/${category?.value}`
        ),
        update: CONSTANT?.API.CATEGORYFORM.update,
        delete: CONSTANT?.API.CATEGORYFORM.delete,
      }}
      TABLE={{
        title: "Record List",
        header,
        export: {
          fileName: `${category?.label}_Records.csv`,
          header: tableHeaderToExportHeader?.header(header),
        },
      }}
      FORM={{
        formField: subCategoryModal,
        editFormTitle: "Edit Record",
        option: { ...dynamicOption },
        editDataPayload: (editData) => {
          setEditData(editData);
          setSubCategoryModal((pr) => {
            pr?.map((pl) => {
              if (pl?.type == "GoogleAutoComplete") {
                pl.placeholder = editData?.[pl?.id];
              }
            });
            return [...pr];
          });
        },
        prepareUpdatePayload: prepareUpdatePayload,
        onChange: (data, selectedValue, operations) => {
          setFormData({ data, selectedValue, operations });
        },
        selectedValue: false,
      }}
    />
  );
};

export default EmployeeCommuteSingleView;
