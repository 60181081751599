import React, { useEffect, useState } from "react";
import CONSTANT from "../../../pages/Utility/constnt";
import CustomModalMain from "../../Custome/CustomModalMain";
import { replaceId } from "../../../pages/Utility/function";

import {
  emissionEntryCategoryWiseDataLoad,
  emissionForFormDataCallLoad,
} from "../../../pages/Utility/spread-function";
import { useParams } from "react-router";

const DownStream = (props) => {
  const {
    setFinancialYear,
    setInputDataForEmission,
    findEmissionData,
    subCategoryAttribute,
    extraValue,
    category,
    setCategory,
    setCarbonEmission,
    setSubCategoryAttribute,
    API_CALL,
  } = props;

  const [dynamicOption, setDynamicOption] = useState({});
  const [alldynamicOption, setAllDynamicOption] = useState({});

  const [formData, setFormData] = useState([]);
  const [defaultData, setDefaultData] = useState({});
  const [cleanData, setCleanData] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const api = {
      ...replaceId(
        CONSTANT.API.SUBCATEGORYATTRIBUTE.getSingleFilterSubCategoryAttribute,
        category
      ),
    };
    // if (Object?.keys(formData)?.length > 1) {
    //   setCarbonEmission(null);
    //   setFormData(null);
    //   setSubCategoryAttribute([...CONSTANT.FORM_FIELDS.ADDSINGLEEMISSIONENTRY]);
    // }
    // cleanerFunction();

    setCleanData(true);
    setDefaultData({ category: category });
    api.endpoint += "&definationType=userDefined";
    category != null &&
      API_CALL.getAll(
        [
          api?.endpoint,
          //   CONSTANT?.API?.ATTRIBUTEOPTION.get?.endpoint,
        ],
        (res) => {
          // setSubCategoryAttribute(res?.data);
          let categoryOption = {};
          const subCategoryAttributeRes = res[0]?.data;
          const subCategoryAttributeOptionRes = res[1]?.data?.data;
          const Other = {};

          // setFuelTypeData(res[3]?.data?.data?.rows);

          const rearrangedData = subCategoryAttributeRes?.data?.rows?.sort(
            (a, b) => +a.index - +b.index
          );
          const dddd = emissionEntryCategoryWiseDataLoad(
            subCategoryAttributeOptionRes,
            Other,
            rearrangedData,
            setDynamicOption,
            null,
            formData
          );

          setAllDynamicOption((prev) => ({
            ...prev,
            ...categoryOption,
          }));
          setSubCategoryAttribute([
            ...CONSTANT.FORM_FIELDS.ADDSINGLEEMISSIONENTRY,
            ...dddd?.arr,
          ]);
          setCleanData(false);
        }
      );
  }, [category]);

  useEffect(() => {
    emissionForFormDataCallLoad(
      subCategoryAttribute,
      setDynamicOption,
      setSubCategoryAttribute,
      setFinancialYear,
      formData,
      alldynamicOption
    );
  }, [formData]);

  const onSubmit = (value) => {
    const e = { ...value };
    let payload = {};
    payload.createFlag = false;
    payload.subCategoryId = category;
    // delete e?.category;
    payload.data = e;
    if (localStorage.getItem("userRole") == "admin" && id) {
      payload.clientId = id;
    }

    Object.keys(payload.data)?.map((el) => {
      payload.data[`id${el}`] = payload.data[el];
    });

    setInputDataForEmission({ ...payload, createFlag: true });
    findEmissionData(payload);
  };

  return (
    <CustomModalMain
      data={subCategoryAttribute}
      defaultData={defaultData}
      labelSize={"25%"}
      submitBtnPosition={"center"}
      fieldSize={"70%"}
      submitBtnName="Calculate Emissions"
      isSubmitButtonVisible={category != null && !API_CALL?.isLoading}
      option={{
        ...dynamicOption,
      }}
      disabledValue={
        extraValue?.subCategory?.find((el) => el?.id == category)?.category
          ?.name
      }
      // cleanData={cleanData}
      // isAutoClose={cleanData}
      isCloase={cleanData}
      close={() => {
        // setCategory(null);

        if (cleanData) {
          setCarbonEmission(null);
          setSubCategoryAttribute([
            ...CONSTANT.FORM_FIELDS.ADDSINGLEEMISSIONENTRY,
          ]);
        }
      }}
      onSubmit={onSubmit}
      isCloseButtonVisible={false}
      minHeight={"90vh"}
      scrollCard="none"
      onChange={(data) => {
        // if (data?.category) {
        setFormData(data);
        //   setCategory(data?.category);
        //
        // API_CALL.sendRequest(
        //   replaceId(
        //     CONSTANT.API.subCategoryAttribute,
        //     data?.category
        //   ),
        //   (res) => {
        //     setCategory(res?.data[0]);
        //   }
        // );
        // }
      }}
    />
  );
};

export default DownStream;
