import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useHttp from "../../../components/Hook/Use-http";
import { getExtraConstPayload, replaceId } from "../../Utility/function";
import { SiteAccessMask } from "../../Utility/spread-function";
import {
  deleteRoleAndAccessStart,
  getRoleAndAccessStart,
} from "../../../store/actions";
import CONSTANT, { TABLE_HEADER } from "../../Utility/constnt";
import CustomModalMain from "../../../components/Custome/CustomModalMain";
import { Fitsol_Team } from "../../Utility/exportValue";
import CustomPage from "../../../components/Custome/CustomPage";
import { useParams } from "react-router";

const ClientSiteAccess = () => {
  const API_CALL2 = useHttp();
  // let SiteID = window.location.pathname.split("/")[3];
  const { SiteID, id } = useParams();
  const getRolAndAccessData = useSelector(
    (state) => state.roleAndAccessDetailData
  );
  const getSiteData = useSelector((state) => state.plantDetailData);
  const role = localStorage.getItem("userRole");
  const [siteData, setSiteData] = useState([]);
  const [actionData, setActionData] = useState({});

  const [showModel_1, setShowModel_1] = useState(false);
  useEffect(() => {
    // const apiData = replaceId(CONSTANT.API.SITE.getSinglePlant, SiteID);
    // API_CALL2.sendRequest(apiData, siteDataHandler);
    if (!getSiteData?.users?.data && !SiteID) return;
    siteDataHandler(
      getSiteData?.users?.data?.rows?.find((it) => +it?.id === +SiteID)
    );
  }, [getSiteData?.users?.data, SiteID]);
  const siteDataHandler = (res) => {
    setSiteData(res);
  };

  const onSubmitForm = (payload, setFormData) => {
    API_CALL2.sendRequest(
      replaceId(CONSTANT.API.ROLEANDACCESS.update, actionData?.id),
      () => {
        setShowModel_1(false);
        setActionData(null);
        setFormData({});
      },
      payload,
      "Password Updated"
    );
  };
  return (
    <>
      <div className="page-content">
        <CustomPage
          PAGE={{
            title: `Sites / ${siteData?.unitName || ""}`,
            withBackButton: true,
            dataFunction: (res, Actions) => {
              return SiteAccessMask(
                res,
                SiteID,

                setShowModel_1,
                setActionData,
                Actions
              );
            },
          }}
          REDUX={{
            getDataRedux: getRolAndAccessData?.users,
            getApiDataRedux:
              role === "admin"
                ? getRoleAndAccessStart(
                    replaceId(
                      CONSTANT.API.ROLEANDACCESS.getClientRoleAndAccess,
                      id
                    )?.endpoint
                  )
                : getRoleAndAccessStart(
                    CONSTANT.API.ROLEANDACCESS.get.endpoint
                  ),
            deleteApiDataRedux: deleteRoleAndAccessStart,
          }}
          API={CONSTANT.API.ROLEANDACCESS}
          FORM={{
            formField: CONSTANT.FORM_FIELDS?.TEAM,

            addFormTitle: "Create access",
            editFormTitle: "Edit access",
            addNewRecordCtaText: "Create access",
            removeFieldOnEdit: ["password"],
            extraConstPayload: getExtraConstPayload({
              role: "siteViewer",
              plantId: SiteID,
            }),
          }}
          TABLE={{
            title: "Roles and access list",
            header: TABLE_HEADER?.team,
            export: {
              fileName: siteData?.unitName + "_Access.csv",
              header: Fitsol_Team,
            },
          }}
          formData={true}
          viewModelData={false}
        />
      </div>
      <CustomModalMain
        modalType="formModal"
        show={showModel_1}
        close={() => setShowModel_1((prev) => !prev)}
        modalTitle="Reset password"
        onSubmit={(data, setFormData) => onSubmitForm(data, setFormData)}
        data={CONSTANT.FORM_FIELDS.RESETPASSWORD}
        formData={false}
      />
    </>
  );
};

export default ClientSiteAccess;
