import React, { useEffect, useState } from "react";
import useHttp from "../../../components/Hook/Use-http";
import BulkUpload from "../../EmissionCalculation/bulkUpload";
import classNames from "classnames";

import SingleUpload from "../../EmissionCalculation/singleUpload";
import CONSTANT, { TAB_DATA, tab } from "../../Utility/constnt";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import PageTitle from "../../../components/pageTitle";
import ClientBulkUpload from "./clientbulkUpload";
import ClientDuplicateData from "./duplicateData";
import ClientMetricData from "../ClientProfileData";

const ClientAddEntry = () => {
  // let clientId = window.location.pathname.split("/")[2];

  const [activeTab, setactiveTab] = useState(tab?.tabActiveId);

  const [subCategory, setSubCategory] = useState([]);

  const API_CALL = useHttp();
  const toggleCustomJustified = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  useEffect(() => {
    API_CALL.sendRequest(CONSTANT.API.SUBCATEGORY.get, (res) => {
      // added a check for removing extra category (Stationary Combustion - Biogenic) and sorting the list alphabetically
      setSubCategory(
        res?.data?.rows
          ?.filter((el) => el?.isVisible == true && el?.name !== "Stationary Combustion - Biogenic")
          ?.sort((a, b) => a?.name < b?.name ? -1 : a?.name > b?.name ? 1 : 0)
      )
    });
  }, []);

  return (
    <React.Fragment>
      <div className="page-content ">
        <PageTitle pageHeading={`Emissions`} />

        <Row>
          <Col sm={12} className="">
            <div className="page-content mt-0 pt-0 scrollSticky">
              <Nav tabs className="nav-tabs-custom nav-justified">
                {TAB_DATA.EMISSION_ADD_TAB.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="mt-2"
                      style={{ width: "150px" }}
                    >
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classNames({
                            active: activeTab === data.tabId,
                            activeTabDesign: activeTab === data.tabId,
                            TabDesign: true,
                          })}
                          onClick={() => {
                            tab.tabActiveId = data?.tabId;
                            toggleCustomJustified(data.tabId);
                          }}
                        >
                          <span className="d-sm-block">{data.name}</span>
                        </NavLink>
                      </NavItem>
                    </div>
                  );
                })}
              </Nav>
              <TabContent activeTab={activeTab} className="mt-3 p-0 TabBody">
                <TabPane tabId={1} className="py-3">
                  <SingleUpload
                    extraValue={{
                      subCategory: subCategory,
                      activeTab: activeTab,
                    }}
                  />
                </TabPane>
                <TabPane tabId={2} className="py-3">
                  <ClientBulkUpload subCategory={subCategory} />
                </TabPane>
                {/* <TabPane tabId={3} className="py-3 px-0">
                  <ClientDuplicateData />
                </TabPane> */}
                <TabPane tabId={3} className="py-3">
                  <ClientMetricData/>
                </TabPane>
              </TabContent>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default ClientAddEntry;
