import React, { useEffect, useState } from "react";
import FileUploadGen from "../../../components/emissionEntry/fileUploadGen";

import { Card, CardBody } from "reactstrap";
import Loader from "../../../components/Loader";
import useHttp from "../../../components/Hook/Use-http";
import { useSelector } from "react-redux";
import { bulkUploadJobLoad } from "../../Utility/spread-function";
import { TABLE_HEADER, getTableData } from "../../Utility/constnt";
import { job_Report_Data } from "../../Utility/exportValue";
import TableView from "../../../components/Custome/tableView";

const ClientBulkUpload = (props) => {
  const { subCategory } = props;
  const API_CALL = useHttp();

  const [jobData, setJobData] = useState([]);
  const [flag, setFlag] = useState(false);
  const getJobBulkData = useSelector((state) => state.jobBulkDetailData);

  useEffect(() => {
    setTimeout(() => {
      // debugger;

      setJobData(bulkUploadJobLoad(getJobBulkData));
    }, 100);
  }, [getJobBulkData, flag]);

  return (
    <>
      <FileUploadGen
        extraValue={{
          subCategory: subCategory,
          setFlag: setFlag,
        }}
      />

      {API_CALL?.isLoading ? (
        <Loader />
      ) : (
        <Card style={{ borderRadius: "7px" }}>
          <CardBody>
            <TableView
              title="Job List"
              data={getTableData(TABLE_HEADER?.jobTable, jobData)}
              exportHeader={job_Report_Data}
              excelName="Job_Report.csv"
            />
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default ClientBulkUpload;
