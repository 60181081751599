import React, { useEffect, useState } from "react";

import { getRecordId, replaceFormIdandClientId, replaceIdClientId, selectedCategoryBulkView } from "../../../Utility/function";

import { useSelector } from "react-redux";
import useHttp from "../../../../components/Hook/Use-http";
import { Col, Modal, Row } from "reactstrap";
import PageTitle from "../../../../components/pageTitle";
import { useDispatch } from "react-redux";
import BulkUploadHeaderCardGen from "../../../../components/emissionEntry/bulkUploadView/bulkUploadHeaderCardGen";
import CONSTANT from "../../../Utility/constnt";
import { useParams } from "react-router-dom";
import { clientId } from "../../../Utility/models/form-model";
import TableView from "../../../../components/Custome/tableView";
import { getTableData } from "../../../Utility/constnt";
import {Card, CardBody} from "reactstrap";
import Loader from "../../../../components/Loader";
import { bulkUploadSingleJobLoad } from "../../../Utility/spread-function";
import { TABLE_HEADER } from "../../../Utility/constnt";

const initHeader = [
  { label: "No", field: "no", sort: "asc" },
  { label: "Status", field: "status", sort: "asc" },
  {
    label: <p style={{ margin: "0px", width: "100px" }}>Record Status</p>,
    field: "isDuplicate",
    sort: "asc",
  },
];

let responseStatus = null;
let succesCount = 0;
let failedCount = 0;

const BulkOnboardView = () => {
    const userRole = localStorage.getItem("userRole");
    const API_CALL = useHttp();
  let jobId = window.location.pathname.split("/")[5];

  const API = useHttp();

  const [jobSingleData, setJobSingleData] = useState([]);
  const [jobViewData, setJobViewData] = useState([]);
  const [viewReasonData, setViewReasonData] = useState(null);
  const [dynamicOption, setDynamicOption] = useState({});
  const [alldynamicOption, setAllDynamicOption] = useState({});
  const [jobData, setJobData] = useState([]);

  const [editData, setEditData] = useState({});

  const getJobBulkData = useSelector((state) => state.jobBulkDetailData);

  const [flag, setFlag] = useState(null);

  const {id} = useParams();
  const [loading, setLoading] = useState(true);

  const onCloseModal = () => {
    setViewReasonData(null);
  };

  const onViewReason = (tripData) => {
    setViewReasonData(tripData);
  };

  const onFailed = () => {
    responseStatus = "fail";
  };
  const onSuccess = () => {
    responseStatus = "success";
  };
  const onAll = () => {
    responseStatus = null;
  };


    useEffect(()=>{
        const fetchJobData = async () => {
            if (userRole === "admin" || userRole === "client"){
                API_CALL.sendRequest(
                    replaceFormIdandClientId(CONSTANT.API.CONNECTIONS.getSingleJob, jobId, id),
                    (res) => {
                        let formattedData = bulkUploadSingleJobLoad(res?.data);
                        setJobData(formattedData);
                        setLoading(false);
                    },
                    null,
                    null,
                    (err)=> {
                      console.log("err", err);
                    }
                )
            }
        }
        fetchJobData();
      },[]);


  useEffect(() => {
    succesCount = jobViewData?.filter((it) => it?.status == "success")?.length;
    failedCount = jobViewData?.filter((it) => it?.status == "fail")?.length;
  }, [jobViewData]);

  const extraValuePass = {
    jobId: jobId,
    jobSingleData: jobSingleData,
    API: API,
    initHeader: initHeader,
    setDynamicOption: setDynamicOption,
    setAllDynamicOption: setAllDynamicOption,
    setJobViewData: setJobViewData,
    onViewReason: onViewReason,
    responceStatus: responseStatus,
    flag: flag,
    dynamicOption: dynamicOption,
    setEditData: setEditData,
  };

  return (
    <>
      {/* <BulkUploadViewGen /> */}
      <div className="page-content">
        <PageTitle
          pageHeading={`JOB / ${getRecordId?.job(jobId)}`}
          withBackButton={true}
        />
      </div>
      {loading ? (<Loader/>):(
        <Card style={{ borderRadius: "7px" }}>
          <CardBody>
            <TableView 
            title="Job entries list" 
            data={getTableData(TABLE_HEADER?.jobEntriesTable, jobData)}
            // exportheaders to be added 
            excelName="Invited_Partners.csv" />
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default BulkOnboardView;
