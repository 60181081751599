import React from "react";
import moment from "moment";

import logoMain from "../BillForm/logo-light.3f82f4ae.png";
import { getRecordId } from "../Utility/function";

const BillTableGen = (props) => {
  const { trip, packageList } = props;
  return (
    <table
      border={0}
      cellPadding={0}
      cellSpacing={0}
      id="sheet0"
      className="sheet0 gridlines"
      style={{
        padding: "35px",
        width: "2390px",
        height: "1600px",
        marginBottom: "45px",
      }}
    >
      <colgroup>
        <col className="col0" />
        <col className="col1" />
        <col className="col2" />
        <col className="col3" />
        <col className="col4" />
        <col className="col5" />
        <col className="col6" />
        <col className="col7" />
        <col className="col8" />
        {/* <col className="col9" /> */}
        {/* <col className="col10" /> */}
      </colgroup>
      <tbody>
        {/* <tr className="row0">
        
        <td className="column1">&nbsp;</td>
        <td className="column2">&nbsp;</td>
        <td className="column3">&nbsp;</td>
        <td className="column4">&nbsp;</td>
        <td className="column5">&nbsp;</td>
        <td className="column6">&nbsp;</td>
        <td className="column7">&nbsp;</td>
        <td className="column8">&nbsp;</td>
        <td className="column9">&nbsp;</td>
        
      </tr> */}
        <tr className="row1">
          <td className="column1 style77 null style85" colSpan={4} rowSpan={2}>
            {/* <div style={{ position: "relative" }}> */}
            <img
              style={{
                zIndex: 1,

                width: "100%",
              }}
              src={logoMain}
              border="0"
            />
            {/* </div> */}
          </td>
          <td className="column5 style89 s style90" colSpan={2}>
            DOCKET NO
          </td>
          <td className="column7 style91 s style92" colSpan={3} rowSpan={2}>
            FITSOL SUPPLY CHAIN SOLUTIONS PRIVATE LIMITED
            <br />
            <span
              style={{
                fontWeight: "bold",
                color: "#000000",
                // fontFamily: '"Arial Black"',
                fontSize: "20pt",
              }}
            >
              S-204, 2ND FLOOR, SECT-5 PLOT NO.7, ARJUN MLU PLAZA DWARKA DELHI,
              SOUTH WEST DELHI - 110075 IN.
              {/* F-16A, FIRST FLOOR, MALHAN FALCON PLAZA, PLOT NO.4, POCKET-7,
            SECTOR-12, DWARKA, NEW&nbsp;DELHI&nbsp;-110078. */}
            </span>
          </td>
        </tr>
        <tr className="row2">
          <td className="column5 style94 s style97" colSpan={2} rowSpan={1}>
            <br />
            <p
              style={{
                fontSize: "25pt",
                textAlign: "center",
                color: "black",
              }}
            >
              {getRecordId?.shipmentRecordId(6000 + trip?.id)}
              {/* {`CO2${(6000 + trip.id).toString().padStart(13, "0")}`} */}
            </p>
            <br />
          </td>
        </tr>
        {/* <tr className="row3"></tr> */}
        <tr className="row4">
          <td className="column1 style10 s">BRANCH CODE</td>
          <td className="column2 style10 s">FROM</td>
          <td className="column3 style75 s style76" colSpan={2}>
            TO
          </td>
          <td className="column5 style88 s style88" colSpan={2}>
            Dated : {moment(new Date()).format("LL")}
          </td>
          <td className="column7 style72 s style74" colSpan={3}>
            Vehicle No : {trip?.vehicle?.registrationNumber}
          </td>
        </tr>
        <tr className="row5">
          <td className="column1 style2 null" />
          <td className="column2 style2 null">{trip?.plant?.city}</td>
          <td className="column3 style48 null style48" colSpan={2}>
            {trip?.destinationPlant?.city}
          </td>
          <td className="column5 style86 s style87" colSpan={2}>
            Owner Risk
          </td>
          <td className="column7 style51 s style52" colSpan={3}>
            MODE
          </td>
        </tr>
        <tr className="row6">
          <td
            className="column1 style49 s style49"
            style={{ textAlign: "center" }}
            colSpan={3}
          >
            CONSIGNOR
          </td>
          <td
            className="column4 style49 s style50"
            style={{
              textAlign: "center",
              // borderRight: "0px solid #000000 !important",
            }}
            colSpan={3}
          >
            CONSIGNEE
          </td>
          <td className="column7 style8 s">ROAD</td>
          <td className="column8 style7 s">TRAIN</td>
          <td className="column9 style7 s">AIR</td>
        </tr>
        <tr className="row7">
          <td
            className="column1 style57 null style57"
            style={{ fontSize: "1.5rem" }}
            colSpan={3}
            rowSpan={4}
          >
            {trip?.plant?.unitName}, {trip?.plant?.pradr}
          </td>
          <td
            className="column4 style57 null style58"
            style={{ fontSize: "1.5rem" }}
            colSpan={3}
            rowSpan={4}
          >
            {trip?.destinationPlant?.unitName}, {trip?.plant?.pradr}
          </td>
          <td
            className="column7 style68 s style69"
            style={{ paddingLeft: "10px" }}
            colSpan={2}
          >
            FREIGHT DETAILS
          </td>
          <td className="column9 style14 s">AMOUNT</td>
        </tr>
        <tr className="row8">
          <td className="column7 style98 s style103" colSpan={2} rowSpan={15}>
            FREIGHT CHARGES
          </td>
          <td className="column9 style104 s style106" rowSpan={15}>
            TO BE BILLED
          </td>
        </tr>
        <tr className="row9"></tr>
        <tr className="row10"></tr>
        <tr className="row11">
          <td
            className="column1 style22 s style27"
            style={{ paddingLeft: "10px" }}
            colSpan={3}
            rowSpan={2}
          >
            GST NO : {trip?.plant?.GST}
          </td>
          <td
            className="column4 style53 s style56"
            style={{ paddingLeft: "10px" }}
            colSpan={3}
            rowSpan={2}
          >
            GST NO
          </td>
        </tr>
        <tr className="row12"></tr>
        <tr className="row13">
          <td
            className="column1 style22 s style26"
            style={{ paddingLeft: "10px" }}
            colSpan={3}
            rowSpan={2}
          >
            TEL NO. : {trip?.client?.mobile}
          </td>
          <td
            className="column4 style23 s style56"
            style={{ paddingLeft: "10px" }}
            colSpan={3}
            rowSpan={2}
          >
            TEL NO.
          </td>
        </tr>
        <tr className="row14"></tr>
        <tr className="row15">
          <td className="column1 style11 s">NOS OF PACKAGE</td>
          <td className="column2 style11 s">ACTUAL WGT</td>
          <td className="column3 style11 s">CHARGE WGT</td>
          <td className="column4 style71 s style71" colSpan={2}>
            VOLUMETRIC SIZE
          </td>
          <td
            className="column6 style71 s style71"
            style={{ textAlign: "center" }}
          >
            CONTENT - DESCRICTION
          </td>
        </tr>

        <tr className="row16">
          <td
            className="column1 style70 null style70"
            rowSpan={4}
            style={{ whiteSpace: "nowrap", width: "15%" }}
          >
            {trip?.package?.map((ele) => {
              return (
                <p style={{ marginLeft: "10px", textAlign: "left" }}>
                  {
                    packageList?.find((item) => item?.id == ele?.packageId)
                      ?.DropdownName
                  }
                  <span style={{ fontWeight: 700, marginInline: "10px" }}>
                    :
                  </span>
                  {ele?.count}
                </p>
              );
            })}
          </td>
          <td className="column2 style70 null style70" rowSpan={4}>
            {" "}
            {trip?.weight}
            {" Tonne"}
          </td>
          <td className="column3 style70 null style70" rowSpan={4}></td>
          <td className="column4 style70 null style70" colSpan={2} rowSpan={4}>
            {trip?.volumetricWeight}
          </td>
          <td className="column6 style53 s style56" rowSpan={4}></td>
        </tr>
        <tr className="row17"></tr>
        <tr className="row18"></tr>
        <tr className="row19"></tr>
        <tr className="row20">
          <td className="column1 style6 s" style={{ paddingLeft: "10px" }}>
            INVOICE NO
          </td>
          <td className="column2 style37 null style38" colSpan={2}>
            {trip?.invoiceNumber}
          </td>
          <td className="column4 style28 s style36" colSpan={3} rowSpan={4}>
            CUSTOME
            <br />
            GOODS RECEIVED IN GOOD CONDITION
            <br />
            <br />
            <br />
            NAME STAMP SIGNATURE
          </td>
        </tr>
        <tr className="row21">
          <td className="column1 style6 s" style={{ paddingLeft: "10px" }}>
            INVOICE VALUE
          </td>
          <td className="column2 style37 null style38" colSpan={2}>
            {" "}
            {trip?.invoiceValue}
          </td>
        </tr>
        <tr className="row22">
          <td className="column1 style6 s" style={{ paddingLeft: "10px" }}>
            E-WAYBILL NO
          </td>
          <td className="column2 style37 null style38" colSpan={2}>
            {trip?.eWayBillNo}
          </td>
        </tr>
        <tr className="row23">
          <td className="column1 style6 s" style={{ paddingLeft: "10px" }}>
            INSURANCE NO.
          </td>
          <td className="column2 style37 null style38" colSpan={2}>
            {trip?.insuranceNumber}
          </td>
          <td className="column7 style39 s style47" colSpan={3} rowSpan={4}>
            Reach us At
            <br />
            www.fitsolscs.com
            <br />
            Phone +91 7428225501 <br />
            Email: billing@fitsolscs.com
            <br />
          </td>
        </tr>
        <tr className="row24">
          <td className="column1 style16 s style21" colSpan={2} rowSpan={3}>
            Recd by <br />
            FSCSPL
          </td>
          <td className="column3 style59 null style67" colSpan={4} rowSpan={3}>
            {" "}
            <p
              style={{
                fontSize: "30pt",
                color: "#00B0F0",
                // fontFamily: "Arial Black",
                fontWeight: "bold",
              }}
            >
              Our Solutions Fits -ALL
            </p>
            <br />
            <p style={{ fontSize: "20pt", color: "#022060" }}>
              Integrated Logistics & Packaging Solutions
            </p>
          </td>
        </tr>
        <tr className="row25"></tr>
        <tr className="row26"></tr>
      </tbody>
    </table>
  );
};

export default BillTableGen;
