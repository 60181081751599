import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CONSTANT, { viewRole } from "../../Utility/constnt";
import CustomPage from "../../../components/Custome/CustomPage";
import PackagingLocationDetail from "../../../components/packagingLocationDetail";
import Table from "../../../components/Custome/table";
import useHttp from "../../../components/Hook/Use-http";
import {
  getExtraConstPayload,
  packegingAssetForSite,
  replaceId,
} from "../../Utility/function";
import { sitePackaginInfoDataLoad } from "../../Utility/spread-function";
import { useParams } from "react-router-dom";

let initValue = {};

const SiteViewerPackaging = () => {
  const [idData, setIdData] = useState([]);
  const API_CALL2 = useHttp();
  const myData = useSelector((state) => state.userGetMeData);

  // let SiteID = window.location.pathname.split("/")[2];
  let { id } = useParams();
  // if (localStorage.getItem("userRole") === "siteViewer") {
  //   SiteID = myData?.users?.plantId;
  // }

  const [pacakgeData, setPackageData] = useState([]);

  const [routeData, setRouteData] = useState([]);
  const [siteData, setSiteData] = useState([]);

  const [pacakegingData, setPackagingData] = useState([]);

  const [tableHeader, setTableHeader] = useState([]);
  const [viewAction, setViewAction] = useState(null);
  const [exportHeaders, setExportHeaders] = useState(false);

  const [refresh, setRefresh] = useState(false);

  const getPackagingData = useSelector((state) => state.packagingDetailData);
  const getSiteData = useSelector((state) => state.plantDetailData);
  const getRouteData = useSelector((state) => state.routeDetailData);

  useEffect(() => {
    if (id != undefined) {
      API_CALL2.getAll(
        [
          replaceId(CONSTANT.API.PACKAGEING.getSinglepackageDataSite, id)
            .endpoint,
        ],
        (res) => {
          packagingDataHandler(res[0]?.data);
        }
      );
    }
  }, [refresh, id]);

  useEffect(() => {
    setTimeout(() => {
      setSiteData(getSiteData?.users?.data?.rows?.find((it) => it?.id == id));
      setRouteData(getRouteData?.users?.data?.rows);
      setPackageData(getPackagingData?.users?.data?.rows);
      setTableHeader(
        packegingAssetForSite(getRouteData?.users?.data?.rows, id)
      );
      getRouteData?.users?.data?.rows?.map((item) => {
        initValue = { ...initValue, [item?.id]: 0 };
      });
    }, 100);
  }, [refresh, id, getSiteData, getRouteData, getPackagingData]);

  useEffect(() => {
    if (id != undefined) {
      setSiteData(getSiteData?.users?.data?.rows?.find((it) => it?.id == id));
      setRouteData(getRouteData?.users?.data?.rows);
      setPackageData(getPackagingData?.users?.data?.rows);
      setTableHeader(
        packegingAssetForSite(getRouteData?.users?.data?.rows, id)
      );
      getRouteData?.users?.data?.rows?.map((item) => {
        initValue = { ...initValue, [item?.id]: 0 };
      });
    }
  }, [getSiteData, getRouteData, getPackagingData]);

  useEffect(() => {
    setExportHeaders(() => {
      let headers = {};
      tableHeader
        ?.filter((item) => item?.field !== "view")
        ?.map((item) => {
          headers = { ...headers, [item?.field]: item?.label };
        });

      return headers;
    });
  }, [tableHeader]);

  const packagingDataHandler = (res) => {
    sitePackaginInfoDataLoad(res, setPackagingData, setViewAction, initValue);
  };

  const onCloseModal = () => {
    setViewAction(null);
  };

  return (
    <>
      <div className="page-content">
        <CustomPage
          API={
            {
              // create:
              //   localStorage.getItem("userRole") === "admin" && !viewRole
              //     ? CONSTANT.API.PACKAGINGASSIGNMENT.create
              //     : null,
            }
          }
          PAGE={{
            title: `Sites  ${
              siteData?.unitName ? `${`/ ${siteData?.unitName}`}` : ""
            }`,
            withBackButton: true,
          }}
          FORM={{
            formField: CONSTANT.FORM_FIELDS?.PACKAGEASSIGNMENT,
            addFormTitle: "Add packaging assets",

            addNewRecordCtaText: "Add packaging assets", //===========

            extraConstPayload: getExtraConstPayload(idData),

            option: {
              packagingTypeId: pacakgeData
                ? Object?.values(
                    pacakgeData
                      ?.filter((item) => item?.type == "Reusable")
                      ?.reduce((acc, data) => {
                        const { DropdownName, id } = data;
                        if (!acc[DropdownName]) {
                          acc[DropdownName] = {
                            label: DropdownName,
                            value: id,
                          };
                        }
                        return acc;
                      }, {})
                  )
                : {},
              routeId: routeData
                ? routeData
                    ?.filter((item) => {
                      return (
                        item?.routeType == "Forward" &&
                        (item?.plantId?.toString() == id?.toString() ||
                          item?.destinationPlantId?.toString() ===
                            id?.toString())
                      );
                    })
                    ?.map((res, data) => {
                      return { label: res?.routeName, value: res?.id };
                    }, {})
                : {},
            },
            onChange: (e) => {
              if (e?.routeId) {
                setIdData(() => {
                  const chooseRoute = routeData?.find(
                    (item) => item.id == e?.routeId
                  );
                  return {
                    clientId: chooseRoute?.plant?.client?.id,
                    plantId: chooseRoute?.plant?.id,
                  };
                });
              }
            },
          }}
          minHeight="400px"
          setRefresh={setRefresh}
        />

        <Table
          title="Packaging asset list"
          data={{
            columns: tableHeader,
            rows: pacakegingData,
          }}
          // exportHeader={exportHeaders}
          // exportValue={ExportValues}
          // excelName={excelName}
        />
      </div>

      <PackagingLocationDetail
        siteId={id}
        packageId={viewAction?.packageId}
        packageName={viewAction?.packageName}
        onClose={onCloseModal}
      />
    </>
  );
};

export default SiteViewerPackaging;
