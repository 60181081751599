import {
  ADD_CUSTOMER_FAILURE,
  ADD_CUSTOMER_START,
  ADD_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAILURE,
  DELETE_CUSTOMER_START,
  DELETE_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAILURE,
  GET_CUSTOMER_START,
  GET_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER_START,
  UPDATE_CUSTOMER_SUCCESS,
} from "./actionTypes";

const initialState = {
  users: [],
  loading: false,
  error: null,
};

const customerDetailData = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CUSTOMER_START:
    case GET_CUSTOMER_START:
    case UPDATE_CUSTOMER_START:
    case DELETE_CUSTOMER_START:
      return { ...state, loading: true };
    case GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      };
    // return action.data;
    case ADD_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: {
          data: [action?.payload, ...state?.users?.data],
        },
        // items: [...data?.data, action?.data],
      };

    case DELETE_CUSTOMER_SUCCESS:
      const newData = state?.users?.data?.rows?.filter(
        (itm) => itm?.id != action?.payload?.payload
      );
      return {
        ...state,
        loading: false,
        users: {
          data: {
            count: newData?.length,
            rows: newData,
          },
        },
        // items: [...data?.data, action?.data],
      };
    case UPDATE_CUSTOMER_SUCCESS:
      const updatedItem = action.payload;

      const updatedData = state.users.data.map((item) =>
        +item.id == +updatedItem.id ? action?.payload?.payload : item
      );

      // const updateData = state?.users?.data?.find(
      //   (itm) => itm?.id == action?.payload?.id
      // );
      return {
        ...state,
        loading: false,
        users: {
          data: updatedData,
        },
        // items: [...data?.data, action?.data],
      };
    case GET_CUSTOMER_FAILURE:
    case ADD_CUSTOMER_FAILURE:
    case UPDATE_CUSTOMER_FAILURE:
    case DELETE_CUSTOMER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };

    default:
      return state;
  }
};
export default customerDetailData;
