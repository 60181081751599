// import { SET_DRIVER_DETAIL } from "./actionTypes";

// const driverDetailData = (data = [], action) => {
//   switch (action.type) {
//     case SET_DRIVER_DETAIL:
//       console.warn("SET_DRIVER_DETAIL condition ", action);
//       return action.data;
//     default:
//       return data;
//   }
// };
// export default driverDetailData;

import {
  DELETE_DRIVER_FAILURE,
  DELETE_DRIVER_START,
  DELETE_DRIVER_SUCCESS,
  GET_DRIVER_FAILURE,
  GET_DRIVER_START,
  GET_DRIVER_SUCCESS,
} from "./actionTypes";

const initialState = {
  users: [],
  loading: false,
  error: null,
};

const driverDetailData = (state = initialState, action) => {
  switch (action.type) {
    case GET_DRIVER_START:
    case DELETE_DRIVER_START:
      return { ...state, loading: true };
    case GET_DRIVER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      };
    // return action.data;

    case DELETE_DRIVER_SUCCESS:
      const newData = state?.users?.data?.rows?.filter(
        (itm) => +itm?.id !== +action?.payload?.payload
      );
      return {
        ...state,
        loading: false,
        users: {
          data: {
            count: newData?.length,
            rows: newData,
          },
        },
        // items: [...data?.data, action?.data],
      };

    case GET_DRIVER_FAILURE:
    case DELETE_DRIVER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };

    default:
      return state;
  }
};
export default driverDetailData;
