import React, { useState } from "react";
import { Row, Form, DatePicker, Col, Input, Select, Modal } from "antd";
import Electricity from "../../../components/emissionEntry/singleEntryFormCategory/electricity";
import { fuelType } from "../../Utility/option";
import PurchasedGases from "../../../components/emissionEntry/singleEntryFormCategory/purchasedGases";
const { Option } = Select;

const CommonRow = ({ selectedCategory }) => {

  const electricityOptions = [
    {
      label: "Renewable",
      value: "renewable",
    },
    {
      label: "Grid",
      value: "grid",
    },
  ];
  const vehicleUnit = [
    {
      label: "km",
      value: "km",
    },
    {
      label: "passenger-km",
      value: "passenger-km",
    },
  ];
  const vehiclesList = [
    "Scooter <110CC",
    "Scooter <150CC",
    "Small Car <800CC_LPG",
    "Motorcycle <100CC",
    "Motorcycle <125CC",
    "Motorcycle <135CC",
    "Motorcycle <200CC",
    "Motorcycle <300CC",
    "Motorcycle <500CC",
    "Three Wheeler_Petrol",
    "Three Wheeler_Diesel",
    "Three Wheeler_CNG",
    "Small Car <800CC _Gasoline",
    "Small Car <800CC_CNG",
    "Hatchback <1600CC_Diesel",
    "Sedan <1400CC_Diesel",
    "Sedan <1600CC_Diesel",
    "Sedan <2000CC_Diesel",
    "Premium Sedan <2000CC_Diesel",
    "Premium Sedan <2500CC_Diesel",
    "Premium Sedan <3000CC_Diesel",
    "MUV <1400CC_Diesel",
    "MUV <2500CC_Diesel",
    "Hatchback Car <1000CC_Gasoline",
    "Hatchback Car <1400CC_Gasoline",
    "Compact SUV <1600CC_Gasoline",
    "Premium Hatchback Car <1600CC_Gasoline",
    "Gypsy_1298CC_Gasoline",
    "Sedan <1400CC_Gasoline",
    "Sedan <1600CC_Gasoline",
    "Sedan <2500CC_Gasoline",
    "Sedan <2000CC_Gasoline",
    "SUV <2000CC_Gasoline",
    "MUV <3000CC_Gasoline",
    "Premium SUV <2000CC_Gasoline",
    "Premium SUV <3000CC_Gasoline",
    "Premium SUV >3000CC_Gasoline",
    "Premium Sedan <3000CC_Gasoline",
    "Premium Sedan <2000CC_Gasoline",
    "Premium Sedan >3000CC_Gasoline",
    "Hybrid <2000CC_Gasoline",
    "Hatchback <1000CC_Diesel",
    "Bus",
    "Aircraft",
    "Maxi Van_Diesel",
    "SUV <2000CC_Diesel",
    "SUV <2500CC_Diesel",
    "SUV <3000CC_Diesel",
    "Premium SUV <2000CC_Diesel",
    "Premium SUV <3000CC_Diesel",
    "Indian_NonSuburban_Railways",
    "Indian_Suburban_Railways",
    "Indian_Metro",
    "Hatchback <1400CC_Diesel",
    "Premium SUV >3000CC_Diesel",
    "Premium SUV <2500CC_Diesel",
  ];
  const [customValue, setCustomValue] = useState("");
  const [isCustom, setIsCustom] = useState(false);
  const vehicleOptions = [...new Set(vehiclesList)].map((vehicle) => ({
    label: vehicle,
    value: vehicle.replace(/\s+/g, "_"),
  }));
  const countryList = [
    "UK",
    "UK (London)",
    "Australia",
    "Belgium",
    "Brazil",
    "Canada",
    "Chile",
    "China",
    "Colombia",
    "Costa Rica",
    "Egypt",
    "France",
    "Germany",
    "Hong Kong, China",
    "India",
    "Indonesia",
  ];
  const countryOptions = [...new Set(countryList)].map((country) => ({
    label: country,
    value: country.replace(/\s+/g, "_"),
  }));
  const waterDischargeUnits = [
    {
      label: "cubic metres",
      value: "cubic metres",
    },
    {
      label: "million litres",
      value: "million litres",
    },
  ];
  const transportationModeOptions = [
    {
      label: "Indian rail",
      value: "Indian Rail",
    },
    {
      label: "Waterborne craft",
      value: "Waterborne Craft",
    },
    {
      label: "Aircraft",
      value: "Aircraft",
    },
  ];
  const fuelType = [
    {
      label: "Diesel",
      value: "diesel",
    },
    {
      label: "CNG",
      value: "CNG",
    },
    {
      label: "Petrol",
      value: "pertrol",
    },
    {
      label: "Petroleum coke (solid)",
      value: "Petroleum coke (solid)",
    },
    {
      label: "Vegetable oil",
      value: "Vegetable oil",
    },
    {
      label: "Rendered animal fat",
      value: "Rendered animal fat",
    },
    {
      label: "Ethanol (100%)",
      value: "Ethanol (100%)",
    },
    {
      label: "Biodiesel (100%)",
      value: "Biodiesel (100%)",
    },
  ];
  const wasteType = [
    {
      label: "Leaves",
      value: "Leaves",
    },
    {
      label: "Branches",
      value: "Branches",
    },
    {
      label: "Food waste",
      value: "Food waste",
    },
  ];
  const purchasedGases = [
    {
      label: "CO2",
      value: "CO2",
    },
    {
      label: "CH4",
      value: "CH4",
    },
    {
      label: "N2O",
      value: "N2O",
    },
    {
      label: "HFC-23",
      value: "HFC-23",
    },
    {
      label: "HFC-32",
      value: "HFC-32",
    },
  ]
  const [selectedValue, setSelectedValue] = useState(null);
  const handleChange = (value) => {
    if (value === "custom") {
      setIsCustom(true);
      setSelectedValue("");
    } else {
      setIsCustom(false);
      setSelectedValue(value);
    }
  };
  const handleCustomInputChange = (e) => {
    setCustomValue(e.target.value);
    setSelectedValue(e.target.value);
  };

  return (
    <div>
      {selectedCategory === "electricity" && (
        <Row>
          <Col span={24}>
            <Form.Item
              label="Please choose electricity type"
              name="electricityType"
              rules={[
                {
                  required: true,
                  message: "Electricity type",
                },
              ]}
            >
              <Select
                placeholder="Please select electricity type"
                options={electricityOptions}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      {selectedCategory === "transportationMode" && (
        <Row>
          <Form.Item
            label="Please chose mode of transport"
            name="transportMode"
          >
            <Select
              placeholder="Please select transport mode"
              options={transportationModeOptions}
            />
          </Form.Item>
        </Row>
      )}
      {(selectedCategory === "stationaryCombustion" || selectedCategory === "mobileCombustion") && (
        <Col>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Fuel Type"
                name="fuelType"
                rules={[
                  { required: true, message: "Please select a fuel type!" },
                ]}
              >
                <Select placeholder="Choose fuel type" options={fuelType} />
              </Form.Item>
            </Col>
          </Row>


          <Row>
            <Col span={24}>
              <Form.Item
                label="CO2 Emission Factor (KG/UNIT)"
                name="CO2FactorKgPerUNIT"
                rules={[
                  { required: true, message: "Please enter CO2 emission factor!" },
                  { type: 'number', transform: value => parseFloat(value), message: "Please enter a valid number" },
                ]}
              >
                <Input type="number" step="0.01" placeholder="Enter CO2 emission factor" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label="CH4 Emission Factor (G/UNIT)"
                name="CH4FactorGPerUNIT"
                rules={[
                  { required: true, message: "Please enter CH4 emission factor!" },
                  { type: 'number', transform: value => parseFloat(value), message: "Please enter a valid number" },
                ]}
              >
                <Input type="number" step="0.01" placeholder="Enter CH4 emission factor" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label="NO2 Emission Factor (G/UNIT)"
                name="NO2FactorGPerUNIT"
                rules={[
                  { required: true, message: "Please enter NO2 emission factor!" },
                  { type: 'number', transform: value => parseFloat(value), message: "Please enter a valid number" },
                ]}
              >
                <Input type="number" step="0.01" placeholder="Enter NO2 emission factor" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      )}
      {selectedCategory === "purchasedGases" && (
        <Row>
          <Col span={24}>
            <Form.Item
              label="Please enter gas name"
              name="gas"
              rules={[
                {
                  required: true,
                  message: "Please enter gas name",
                },
              ]}
            >
              <Select
                // options={wasteType} 
                options={[
                  ...purchasedGases,
                  { label: "Add custom", value: "custom" },
                ]}
                value={isCustom ? "custom" : selectedValue}
                onChange={handleChange}
                placeholder="choose purchased gas"
              />
            </Form.Item>
            {isCustom && (
              <Form.Item
                label="Enter name of gas"
                name="customGas"
              >
                <Input
                  value={customValue}
                  onChange={handleCustomInputChange}
                  placeholder="Enter name of gas"
                />
              </Form.Item>
            )}
          </Col>
          <Col span={24}>
            <Form.Item
              label="Please enter GWP value of gas"
              name="gwp"
              rules={[
                {
                  required: true,
                  message: "Please enter gwp value of entered gas",
                },
              ]}
            >
              <Input placeholder="Please enter GWP value of entered gas" />
            </Form.Item>
          </Col>
        </Row>
      )}
      {selectedCategory === "wasteManagement" && (
        <div>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Please enter name of waste type"
                name="wasteType"
                rules={[
                  {
                    required: true,
                    message: "Please enter name of waste type",
                  },
                ]}
              >
                <Select
                  options={[
                    ...wasteType,
                    { label: "Add custom", value: "custom" },
                  ]}
                  value={isCustom ? "custom" : selectedValue}
                  onChange={handleChange}
                  placeholder="choose waste type"
                />
              </Form.Item>
              {isCustom && (
                <Form.Item
                  label="Enter waste type"
                  name="customWasteType"
                >
                  <Input
                    value={customValue}
                    onChange={handleCustomInputChange}
                    placeholder="Enter waste type"
                  />
                </Form.Item>
              )}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Recycled" name="recycled">
                <Input placeholder="Please enter factor of recycled" />
              </Form.Item>
            </Col>
            <Col >
              <Form.Item label="Combusted" name="combusted">
                <Input placeholder="Please enter factor of combusted" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Landfilled" name="landFilled">
                <Input placeholder="Please enter factor of landfilled" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Composted" name="composted">
                <Input placeholder="Please enter factor of composted" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={10}>
              <Form.Item
                label="Anaerobically digested dry"
                name="anaerobicallyDigestedDry"
              >
                <Input placeholder="Please enter factor of anaerobically digested dry" />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label="Anaerobically digested wet"
                name="anaerobicallyDigestedWet"
              >
                <Input placeholder="Please enter factor of anaerobically digested wet" />
              </Form.Item>
            </Col>
          </Row>
        </div>
      )}
      {selectedCategory === "employeeCommute" && (
        <Row>
          <Col span={24}>
            <Form.Item
              label="Please choose vehicle type"
              name="vehicleType"
              rules={[
                {
                  required: true,
                  message: "Vechilce type",
                },
              ]}
            >
              <Select
                placeholder="Please select vehicle type"
                options={[
                  ...vehicleOptions,
                  { label: "Add custom", value: "custom" },
                ]}
                value={isCustom ? "custom" : selectedValue}
                onChange={handleChange}
              />
            </Form.Item>
            {isCustom && (
              <Form.Item
                label="Enter custom vehicle type"
                name="customVehicleType"
              >
                <Input
                  value={customValue}
                  onChange={handleCustomInputChange}
                  placeholder="Enter custom vehicle type"
                />
              </Form.Item>
            )}
          </Col>
          <Col span={24}>
            <Form.Item
              label="Please choose unit"
              name="unit"
              rules={[
                {
                  required: true,
                  message: "Vechilce unit",
                },
              ]}
            >
              <Select
                placeholder="Please select vehicle unit"
                options={vehicleUnit}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      {selectedCategory === "hotelStays" && (
        <Row>
          <Col span={24}>
            <Form.Item
              label="Please choose country"
              name="countryName"
              rules={[
                {
                  required: true,
                  message: "country",
                },
              ]}
            >
              <Select
                placeholder="Please select country"
                // options={countryOptions}
                options={[
                  ...countryOptions,
                  { label: "Add custom", value: "custom" },
                ]}
                value={isCustom ? "custom" : selectedValue}
                onChange={handleChange}
              // onChange={handleVehicleType}
              />
            </Form.Item>
            {isCustom && (
              <Form.Item
                label="Enter country name"
                name="customCountry"
              >
                <Input
                  value={customValue}
                  onChange={handleCustomInputChange}
                  placeholder="Enter country name"
                />
              </Form.Item>
            )}
          </Col>
        </Row>
      )}
      <Row>
        <Col span={11} style={{ marginRight: "10px" }}>
          <Form.Item
            label="Start date"
            name="startDate"
            rules={[
              {
                required: true,
                message: "Please select start date!",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            label="End date"
            name="endDate"
            rules={[
              {
                required: true,
                message: "Please select end date!",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
        </Col>
      </Row>
      {(selectedCategory === "waterTreatment" ||
        selectedCategory === "waterUsage") && (
          <Row>
            <Col span={24}>
              <Form.Item
                label="Consumption unit"
                name="VolumeUnit"
                rules={[
                  {
                    required: true,
                    message: "Please select end date!",
                  },
                ]}
              >
                <Select
                  options={waterDischargeUnits}
                  placeholder="chose consumption unit"
                />
              </Form.Item>
            </Col>
          </Row>
        )}
      {(selectedCategory === "waterTreatment" ||
        selectedCategory === "waterUsage" ||
        selectedCategory === "transportationMode" ||
        selectedCategory === "electricity" ||
        selectedCategory === "employeeCommute" ||
        selectedCategory === "hotelStays") && (
          <Row>
            <Col span={24}>
              <Form.Item
                label="Emission factor value"
                name="emissionFactorValue"
                rules={[
                  {
                    required: true,
                    message: "Please select end date!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        )}
    </div>
  );
};

export default CommonRow;
