import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Card,
  CardBody,
  Badge,
  CardFooter,
  Row,
  Col,
  Button,
} from "reactstrap";
//Import Images
import Select from "react-select";
import ReactSwitch from "react-switch";

import {
  answerDataType,
  autoDataType,
  userDataType,
} from "../../Utility/option";
import SweetAlert from "react-bootstrap-sweetalert";
import { debounce } from "../../Utility/function";
import notify from "../../Utility/coustemFunction";
import CONSTANT from "../../Utility/constnt";
import Togglebutton from "../../../components/Custome/togglebutton";

const CardTaskBox = (props) => {
  const [showLabel, setShowLabel] = useState(props?.isRequired || false);
  const [showSweetAlert, setShowSweetAlert] = useState(false);
  const [optionChangeValue, setOptionChangeValue] = useState(null);

  const dataType = [userDataType, autoDataType];

  const findValue = (arr, props) => {
    return arr?.find((e) => e?.value === props);
  };

  const onChangeToggle = (selected) => {
    props.updateAttribute(props?.id, {
      isRequired: selected,
    });

    setShowLabel((previous) => !previous);
  };

  return (
    <React.Fragment>
      <Card className="task-box">
        <CardBody className="borad-width">
          <div>
            <Select
              value={props?.option?.find(
                (e) => e?.value === props?.attributeId
              )}
              // defaultValue={props.option[2]}
              options={props?.option}
              maxMenuHeight={200}
              // selected={"16"}
              classNamePrefix="select2-selection"
              onChange={(selected) => {
                //update

                props.updateAttribute(props?.id, {
                  attributeId: selected?.value,
                });
              }}
            />

            {findValue(answerDataType, props?.definationType)?.value ==
              "userDefined" && (
              <input
                autoComplete="no"
                name={"placeHolder"}
                defaultValue={props?.placeHolder}
                // value={""}
                placeholder={"Enter Placeholder"}
                type={"text"}
                className="form-control"
                required={true}
                onChange={debounce((e) => {
                  props.updateAttribute(props?.id, {
                    placeHolder: e?.target?.value,
                  });
                }, 500)}
                style={{ marginTop: "10px" }}
              />
            )}
            <Row style={{ marginTop: "10px" }}>
              <Col>
                <Select
                  value={findValue(answerDataType, props?.definationType)}
                  // defaultValue={props.option[2]}
                  options={answerDataType}
                  maxMenuHeight={200}
                  // selected={"16"}
                  classNamePrefix="select2-selection"
                  onChange={(selected) => {
                    //update
                    props.updateAttribute(props?.id, {
                      definationType: selected?.value,
                    });
                  }}
                />
              </Col>

              <Col>
                <Select
                  value={
                    findValue(answerDataType, props?.definationType)?.value ==
                    "AutoCalculated"
                      ? findValue(autoDataType, props?.dataType)
                      : findValue(userDataType, props?.dataType)
                  }
                  // defaultValue={props.option[2]}

                  options={
                    findValue(answerDataType, props?.definationType)?.value ==
                    "AutoCalculated"
                      ? autoDataType
                      : userDataType
                  }
                  maxMenuHeight={200}
                  // selected={"16"}
                  classNamePrefix="select2-selection"
                  onChange={(selected) => {
                    //update

                    props.updateAttribute(props?.id, {
                      dataType: selected?.value,
                    });
                  }}
                />
              </Col>
            </Row>

            {(findValue(answerDataType, props?.definationType)?.value ==
              "AutoCalculated" ||
              findValue(userDataType, props?.dataType)?.value ==
                "dynamicSelect") && (
              <input
                autoComplete="no"
                name={"placeHolder"}
                defaultValue={props?.equation}
                // value={""}
                placeholder={"Enter Key or Equations"}
                type={"text"}
                className="form-control"
                required={true}
                onChange={debounce((e) => {
                  props.updateAttribute(props?.id, {
                    equation: e?.target?.value,
                  });
                }, 500)}
                style={{ marginTop: "10px" }}
              />
            )}

            {/* {(findValue(userDataType, props?.dataType)?.value ==
              "dynamicSelect" ||
              findValue(userDataType, props?.dataType)?.value ==
                "getShowDefaultSelect") && (
              <input
                autoComplete="no"
                name={"placeHolder"}
                defaultValue={props?.extra}
                // value={""}
                placeholder={"Enter Extra Value"}
                type={"text"}
                className="form-control"
                required={true}
                onChange={debounce((e) => {
                  props.updateAttribute(props?.id, {
                    extra: e?.target?.value,
                  });
                }, 500)}
                style={{ marginTop: "10px" }}
              />
            )} */}

            <input
              autoComplete="no"
              name={"width"}
              defaultValue={props?.width}
              // value={""}
              placeholder={"Enter Width"}
              type={"text"}
              className="form-control"
              required={true}
              onChange={debounce((e) => {
                props.updateAttribute(props?.id, {
                  width: e?.target?.value,
                });
              }, 500)}
              style={{ marginTop: "10px" }}
            />

            {(findValue(answerDataType, props?.definationType)?.value ==
              "userDefined" ||
              findValue(answerDataType, props?.definationType)?.value ==
                "userDefinedDynamic") &&
              (findValue(userDataType, props?.dataType)?.value ==
                "SingleSelect" ||
                findValue(userDataType, props?.dataType)?.value ==
                  "getShowDefaultSelect") && (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.addOption(
                      {
                        value: e.target.optionInPut.value,
                        subCategoryAttributeId: props?.id,
                        attributeId: props?.attributeId,
                      },
                      CONSTANT.API.ATTRIBUTEOPTION.create
                    );

                    e.target.optionInPut.value = "";
                  }}
                >
                  <Row>
                    <Col className="col-9">
                      <input
                        id="optionInPut"
                        autoComplete="no"
                        name={"option"}
                        defaultValue={""}
                        placeholder={"Enter Option"}
                        type={"text"}
                        className="form-control"
                        required={true}
                        style={{ marginTop: "10px" }}
                      />
                    </Col>

                    <Col className="col-2" style={{ marginTop: "10px" }}>
                      <button
                        type="submit"
                        className="btn btn-primary waves-effect waves-light"
                        // onClick={() => {
                        //   optionChangeValue !== null
                        //     ? props.addData(
                        //         {
                        //           value: optionChangeValue,
                        //           subCategoryAttributeId: props?.id,
                        //           attributeId: props?.attributeId,
                        //         },
                        //         CONSTANT.API.addOptionForAttribute
                        //       )
                        //     : notify?.error("Please fil-up Option Field!");
                        // }}
                      >
                        Add
                      </button>
                    </Col>

                    <div
                      style={{
                        borderRadius: "9px",
                        marginTop: "15px",
                      }}
                    >
                      <p style={{ margin: "0px", color: "#b1b1b1" }}>
                        Option Count :{" "}
                        {
                          props?.optionForSelect?.filter(
                            (gg) =>
                              gg?.attribute?.name == props?.attribute?.name
                          )?.length
                        }
                      </p>
                      {props?.optionForSelect
                        ?.filter(
                          (gg) => gg?.attribute?.name == props?.attribute?.name
                        )
                        ?.map((data, index) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "10px",
                                justifyContent: "space-between",
                                backgroundColor: "#ededed",
                                padding: "8px",
                              }}
                            >
                              <p style={{ margin: 0 }}>{data?.value}</p>
                              <i
                                className="bx bxs-trash-alt"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  props?.deleteOption(
                                    data?.id,
                                    CONSTANT.API.ATTRIBUTEOPTION.delete
                                  );
                                }}
                              ></i>
                            </div>
                          );
                        })}
                    </div>
                  </Row>
                </form>
              )}

            {findValue(answerDataType, props?.definationType)?.value ==
              "userDefined" && (
              <form
                onSubmit={(e) => {
                  e.preventDefault();

                  const spreadData = props?.extra?.visibiltyRule || [];

                  props?.updateAttribute(props?.id, {
                    // extra: {
                    //   ...props.extra,
                    //   visibiltyRule: {
                    //     ...props.extra.visibiltyRule,
                    //     [e.target.keys.value]: e?.target?.values?.value,
                    //   },
                    // },

                    extra: {
                      ...props?.extra,
                      visibiltyRule: [
                        ...spreadData,
                        {
                          [e.target.keys.value]: e?.target?.values?.value,
                        },
                      ],
                    },
                    // extra: {

                    //     props.extra.visibiltyRule : {
                    //       ...props.extra.visibiltyRule
                    //       [e.target.keys.value]: e?.target?.values?.value,
                    //     }
                    // },
                  });
                  // props.addOption(
                  //   {
                  //     value: e.target.optionInPut.value,
                  //     subCategoryAttributeId: props?.id,
                  //     attributeId: props?.attributeId,
                  //   },
                  //   CONSTANT.API.ATTRIBUTEOPTION.create
                  // );

                  e.target.keys.value = "";
                  e.target.values.value = "";
                }}
              >
                <Row>
                  <Col className="col-5">
                    <input
                      id="keys"
                      autoComplete="no"
                      name={"keys"}
                      defaultValue={""}
                      placeholder={"Enter key"}
                      type={"text"}
                      className="form-control"
                      required={true}
                      style={{ marginTop: "10px" }}
                    />
                  </Col>
                  <Col className="col-4">
                    <input
                      id="values"
                      autoComplete="no"
                      name={"values"}
                      defaultValue={""}
                      placeholder={"Enter value"}
                      type={"text"}
                      className="form-control"
                      required={true}
                      style={{ marginTop: "10px" }}
                    />
                  </Col>

                  <Col className="col-2" style={{ marginTop: "10px" }}>
                    <button
                      type="submit"
                      className="btn btn-primary waves-effect waves-light"
                      // onClick={() => {
                      //   optionChangeValue !== null
                      //     ? props.addData(
                      //         {
                      //           value: optionChangeValue,
                      //           subCategoryAttributeId: props?.id,
                      //           attributeId: props?.attributeId,
                      //         },
                      //         CONSTANT.API.addOptionForAttribute
                      //       )
                      //     : notify?.error("Please fil-up Option Field!");
                      // }}
                    >
                      Add
                    </button>
                  </Col>

                  {props?.extra?.visibiltyRule?.length ? (
                    <div
                      style={{
                        borderRadius: "9px",
                        marginTop: "15px",
                      }}
                    >
                      <p style={{ margin: "0px", color: "#b1b1b1" }}>
                        Visibility Count : {props?.extra?.visibiltyRule?.length}
                      </p>
                      {props?.extra?.visibiltyRule?.map((it) => {
                        const values = Object?.values(it);
                        return (
                          <div
                            style={{
                              marginTop: "10px",
                              backgroundColor: "#ededed",
                              padding: "8px",
                            }}
                          >
                            {Object?.keys(it)?.map((gg, index) => {
                              return (
                                <Row>
                                  <Col
                                    span={24}
                                    className="d-flex align-items-center justify-content-between"
                                  >
                                    <p style={{ margin: 0 }}>{gg}</p>
                                    <p style={{ margin: 0 }}>
                                      {values?.[index]?.toString()}
                                    </p>
                                  </Col>
                                </Row>
                              );
                            })}
                          </div>
                        );
                        // return (

                        //   <div
                        //     style={{
                        //       display: "flex",
                        //       alignItems: "center",
                        //       marginTop: "10px",
                        //       justifyContent: "space-between",
                        //       backgroundColor: "#ededed",
                        //       padding: "8px",
                        //     }}
                        //   >
                        //     <p style={{ margin: 0 }}>{Object?.keys(it)}</p>
                        //     <p style={{ margin: 0 }}>{Object?.values(it)}</p>
                        //   </div>
                        // );
                      })}
                    </div>
                  ) : (
                    <></>
                  )}
                </Row>
              </form>
            )}
          </div>
        </CardBody>
        <CardFooter>
          <div className="d-flex justify-content-between">
            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <span>Id : {props?.id}</span>
              {findValue(answerDataType, props?.definationType)?.value ==
                "userDefined" && (
                <>
                  <span
                    style={{
                      borderLeft: "2px solid #cac9c9",
                      paddingLeft: "8px",
                    }}
                  >
                    Required
                  </span>
                  <Togglebutton checked={showLabel} onChange={onChangeToggle} />
                  {/* <ReactSwitch
                    checked={showLabel}
                    onChange={(selected) => {
                      props.updateAttribute(props?.id, {
                        isRequired: selected,
                      });

                      setShowLabel((previous) => !previous);
                    }}
                    onColor="#9098d1"
                    onHandleColor="#303b91"
                    handleDiameter={18}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={12}
                    width={30}
                    className="react-switch"
                    id="material-switch"
                  /> */}
                </>
              )}
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <i
                className="bx bxs-trash-alt"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowSweetAlert(true);
                }}
              ></i>
            </div>
          </div>
        </CardFooter>
      </Card>

      {showSweetAlert && (
        <SweetAlert
          title="Are you sure For Delete Attribute?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            props?.ondeleteForm(props?.id);
          }}
          onCancel={() => {
            setShowSweetAlert(false);
          }}
        >
          Do you really want to delete Attribute ?
        </SweetAlert>
      )}
    </React.Fragment>
  );
};

CardTaskBox.propTypes = {
  data: PropTypes.object,
};

export default CardTaskBox;
