import React, { useEffect, useState } from "react";
import CONSTANT, { TABLE_HEADER } from "../Utility/constnt";
import CustomPage from "../../components/Custome/CustomPage";
import { routeHeaderData } from "../Utility/exportValue";
import { useSelector } from "react-redux";
import { deleteRouteStart, getRouteStart } from "../../store/actions";
import { routeDataMask } from "../Utility/spread-function";
import { optionArray } from "../Utility/function";

const RoutePage = () => {
  const myData = useSelector((state) => state.userGetMeData);
  const getRouteData = useSelector((state) => state.routeDetailData);
  const getClientData = useSelector((state) => state.customerDetailData);
  const getSiteData = useSelector((state) => state.plantDetailData);

  const [siteData, setSiteData] = useState([]);
  const [routeData, setRouteData] = useState([]);

  const [selectedSourceSiteData, setSelectedSourceSiteData] = useState({});
  const userRole = localStorage.getItem("userRole");
  const [filterSiteData, setFilterSite] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    (async () => {
      if (userRole == "admin") {
        siteDataHandler();
      }
    })();
  }, [routeData, getClientData, getSiteData]);

  const siteDataHandler = () => {
    // setSiteData(res[0]?.data?.data);
    // setFilterSite(res[0]?.data?.data);
    // setCustomerData(res[1]?.data?.data);
    setSiteData(getSiteData?.users?.data?.rows);
    setFilterSite(getSiteData?.users?.data?.rows);
    setCustomerData(getClientData?.users?.data?.rows);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <CustomPage
          PAGE={{
            title: "Route",
            dataFunction: (res, Operation) => {
              setRouteData(res);
              return routeDataMask(res, Operation, myData?.users?.clientId);
            },
          }}
          API={{
            create: userRole == "admin" ? CONSTANT.API.ROUTE.create : null,
            update: userRole == "admin" ? CONSTANT.API.ROUTE.update : null,
            delete: CONSTANT.API.ROUTE.delete,
            get: CONSTANT.API.ROUTE.get,
          }}
          REDUX={{
            getDataRedux: getRouteData?.users,
            getApiDataRedux: getRouteStart(),
            getMeDetail: myData?.users?.role == "client" ? myData?.users : null,
            deleteApiDataRedux: deleteRouteStart,
          }}
          FORM={{
            formField: CONSTANT.FORM_FIELDS?.ROUTE,
            addFormTitle: "Add route",
            editFormTitle: "Edit route",
            addNewRecordCtaText: "Add route",

            onChange: (data, selectedValue, operations, defaultValue) => {
              setFormData({ data, selectedValue, operations, defaultValue });
              if (!data?.clientId && defaultValue?.clientId) {
                setFilterSite(
                  siteData?.filter(
                    (el) => el?.clientId === defaultValue?.clientId
                  )
                );
              }
              if (!data?.plantId && defaultValue?.plantId) {
                setSelectedSourceSiteData(
                  siteData?.filter((el) => el?.id === defaultValue?.plantId)[0]
                );
              }
            },
            onSingleChange: (singleData) => {
              if (singleData?.clientId) {
                setFilterSite(
                  siteData?.filter((el) => el?.clientId == singleData?.clientId)
                );
                formData?.operations?.selectedValue((pr) => {
                  delete pr?.plantId;
                  delete pr?.destinationPlantId;
                  return { ...pr };
                });
                formData?.operations?.inputData((pr) => {
                  delete pr?.plantId;
                  delete pr?.destinationPlantId;
                  return { ...pr };
                });
              }
              if (singleData?.plantId) {
                setSelectedSourceSiteData(
                  siteData?.filter((el) => el?.id === singleData?.plantId)[0]
                );
                formData?.operations?.selectedValue((pr) => {
                  delete pr?.destinationPlantId;
                  return { ...pr };
                });
                formData?.operations?.inputData((pr) => {
                  delete pr?.destinationPlantId;
                  return { ...pr };
                });
              }
            },
            removeFieldOnEdit: ["routeType"],
            option: {
              plantId: optionArray(filterSiteData, "unitName", "id"),
              destinationPlantId: optionArray(
                filterSiteData?.filter(
                  (item) => selectedSourceSiteData?.id !== item?.id
                ),
                "unitName",
                "id"
              ),
              clientId: optionArray(customerData, "name", "id"),
            },
          }}
          TABLE={{
            title: "Route list",
            header: TABLE_HEADER?.route?.filter((it) => {
              return userRole == "admin" || it != "action";
            }),
            export: {
              fileName: "Route_Report.csv",
              header: routeHeaderData,
            },
          }}
          editDefaultorInput={true}
          extrakeyForedit={"true"}
          //redux
          // getData={getRouteData?.data || []}
          // GetSingle={`${
          //   myData?.users?.role === "client"
          //     ? `?clientId=${myData?.users?.clientId}`
          //     : ""
          // }`}
        />
      </div>
    </React.Fragment>
  );
};

export default RoutePage;
