import {
  GET_ADMIN_DASHBOARD_START,
  GET_ADMIN_DASHBOARD_SUCCESS,
  GET_ADMIN_DASHBOARD_FAILURE,
  DELETE_ADMIN_DASHBOARD_START,
  DELETE_ADMIN_DASHBOARD_SUCCESS,
  DELETE_ADMIN_DASHBOARD_FAILURE,
} from "./actionTypes";

// export const getMyProfile = () => ({
//   type: GET_MY_PROFILE,
// });

// export const getAdminDashboardDetailSuccess = (users) => {
//   return {
//     type: ADMIN_DASHBOARD_DETAIL,
//   };
//   // return {
//   //   type: GET_ADMIN_DASHBOARD_DETAIL_SUCCESS,
//   //   payload: users,
//   // };
// };

//GET
export const getAdminDashboardStart = () => {
  return {
    type: GET_ADMIN_DASHBOARD_START,
  };
};
export const getAdminDashboardSuccess = (users) => {
  return {
    type: GET_ADMIN_DASHBOARD_SUCCESS,
    payload: users,
  };
};
export const getAdminDashboardError = (error) => {
  return {
    type: GET_ADMIN_DASHBOARD_FAILURE,
    payload: error,
  };
};

//DELETE
export const deleteAdminDashboardStart = (id, successCallback) => ({
  type: DELETE_ADMIN_DASHBOARD_START,
  payload: id,
  successCallback,
});

export const deleteAdminDashboardSucess = (id) => ({
  type: DELETE_ADMIN_DASHBOARD_SUCCESS,
  payload: id,
});

export const deleteAdminDashboardError = (error) => ({
  type: DELETE_ADMIN_DASHBOARD_FAILURE,
  payload: error,
});
