import React from "react";
import { ApproveButton, RejectButton } from "../../../Utility/spread-function";
import CONSTANT from "../../../Utility/constnt";
import useHttp from "../../../../components/Hook/Use-http";
import { useParams } from "react-router-dom";

const ApproveAction = ({
  Operation,
  clientData,
  setUpdatedList
}) => {
  const API_CALL = useHttp();
  const userRole = localStorage.getItem("userRole");
  const { id } = useParams();
  const handleApprove = async (userId) => {
    try {
      if (userRole === "admin" || userRole === "client") {
        // const id = clientId;
        await API_CALL.sendRequest(
          CONSTANT.API.USERAPPROVAL.verifyApproval,
          null,
          {
            clientId: id,
            requestedUserId: userId,
            status: "ACCEPTED",
          },
          "User approval updated successfully!"
        );
        setUpdatedList((prevState) => !prevState);
      }
    } catch (error) {
      console.error("getting error while approving the new user", error);
    }
  };
  const handleDeny = async (userId) => {
    try {
      if (userRole === "admin" || userRole === "client") {
        await API_CALL.sendRequest(
          CONSTANT.API.USERAPPROVAL.verifyApproval,
          null,
          {
            clientId: id,
            requestedUserId: userId,
            status: "REJECTED",
          },
          "User approval updated successfully!"
        );
        setUpdatedList((prevState) => !prevState);
      }
    } catch (error) {
      console.error("getting error while approving the new user", error);
    }
  };
  return (
    <div className=" d-flex justify-content-center">
      {Operation?.editRecord && (
        <ApproveButton
          onClick={() => {
            handleApprove(clientData.id);
          }}
        />
      )}
      {Operation?.setDeleteData && (
        <RejectButton
          onClick={() => handleDeny(clientData.id)}
        />
      )}
    </div>
  );
};

export default ApproveAction;
