import { MDBBadge } from "mdbreact";
import { useState } from "react";

import { Tooltip } from "reactstrap";
import {
  ActualWeight,
  ConsignorEmail,
  ConsignorName,
  DimensionInmm,
  GST,
  POCName,
  VehicleNumber,
  actualAddress,
  brsrSpocDepartment,
  brsrSpocEmail,
  brsrSpocId,
  brsrSpocPhone,
  calendarYear,
  categoryAttributeDataType,
  categoryAttributeForm,
  categoryAttributeIsrequiared,
  categorySelect,
  ciiMemberShipNo,
  cinNumber,
  clientForRoleId,
  clientId,
  clientName,
  clientSKU,
  color,
  companyName,
  corporateAddress1,
  corporateAddress2,
  corporateDistrict,
  corporatePincode,
  corporateState,
  countForPackage,
  csvName,
  customerMobile,
  customerName,
  customerPassword,
  deadLine,
  definationType,
  department,
  description,
  destinationPlantId,
  distance,
  dob,
  drivingLicense,
  email,
  expectedCompletionTimeInHR,
  financialYear,
  formModalName,
  freightWeightInTon,
  getAllSPOCS,
  gstIn,
  gstNumber,
  insuranceNumber,
  location,
  material,
  mobile,
  name,
  notificationType,
  packageId,
  packageSubType,
  packageType,
  plantForRoleId,
  plantId,
  postmanLink,
  profilePic,
  registeredAddress1,
  registeredAddress2,
  registeredDistrict,
  registeredPincode,
  registeredState,
  roleWise,
  routeDestinationPlantId,
  routeName,
  routePlantId,
  sampleFile,
  scope,
  scopeForCategory,
  sectionId,
  spocsList,
  status,
  totalRevenue,
  transporterId,
  turnover,
  unitName,
  vehicleCapacity,
  vehicleCategory,
  yearOfIncorporation,
  yearOfTurnover,
} from "./models/form-model";
import {
  BiCategory,
  BiCog,
  BiGroup,
  BiMap,
  BiLeaf,
  BiPaste,
  BiSolidFactory,
  BiSolidInbox,
  BiSolidReport,
  BiSolidTruck,
  BiSolidUserDetail,
  BiSolidUserRectangle,
  BiNetworkChart,
  BiLinkExternal,
  BiLink,
} from "react-icons/bi";
import { FaRoute, FaUser } from "react-icons/fa";
import { RiAddCircleFill, RiProductHuntLine } from "react-icons/ri";
import { MdOutlineAccountTree } from "react-icons/md";

import { TbBrandCarbon, TbFileDescription } from "react-icons/tb";

import { createEndPoint } from "./function";
import { TABLE_CONFIG } from "./table-config";
import { Calendar } from "antd";
import { MAX_VERTICAL_CONTENT_RADIUS } from "antd/es/style/placementArrow";

export const ToolTipButton = ({ id, msg }) => {
  const [ttop, setttop] = useState(false);
  return (
    <>
      <Tooltip
        placement="top"
        isOpen={ttop}
        target={id}
        toggle={() => {
          setttop(!ttop);
        }}
      >
        {msg}
      </Tooltip>
      <i
        id={id}
        className="bx bx-info-circle fs-3"
        style={{ cursor: "pointer" }}
      ></i>
    </>
  );
};

export const loginRoot = "/";
export const appRoot = `/${localStorage.getItem("userRole")}`;
export const adminRoute = "/admin";
export const clientRoute = "/client";
export const siteRoute = "/siteViewer";
export const tripRoute = "/tripCreator";
export const spocRoute = "/spoc";

export const tab = {
  tabActiveId: 1,
};

export const MonthName = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const constTrackingOption = {
  simBased: "Sim based",
  ashokleyland: "Ashok Leyland",
};

export const TAB_DATA = {
  CLIENT_TAB: [
    {
      name: "Shipment",
      tabId: 1,
    },
    {
      name: "Site",
      tabId: 2,
    },
    {
      name: "Packaging",
      tabId: 3,
    },
    {
      name: "Access",
      tabId: 4,
    },
  ],
  SITE_TAB: [
    {
      name: "Site",
      tabId: 1,
    },
    {
      name: "Route",
      tabId: 2,
    },
  ],
  EMISSION_ADD_TAB: [
    {
      name: "Single entry",
      tabId: 1,
    },
    {
      name: "Bulk entry",
      tabId: 2,
    },
    {
      name: "Intensity metrics",
      tabId: 3,
    },
    // {
    //   name: "Duplicate",
    //   tabId: 3,
    // },
  ],
  VEHICLES_TAB: [
    {
      name: "Shipment",
      tabId: 1,
    },
    {
      name: "Vehicle",
      tabId: 2,
    },
  ],
  DRIVER_TAB: [
    {
      name: "Shipment",
      tabId: 1,
    },
  ],
  PARTNER_ONBOARD_TAB:[
    {
      name:"Onboard partner",
      tabId:1, 
    },
    {
      name:"Bulk onboard partners",
      tabId:2,
    },
  ],
  MANAGE_CONNECTIONS_TAB:[
    {
      name:"Connections sent",
      tabId:1,
    },
    {
      name:"Connections recieved",
      tabId:2,
    },
  ],
  CLIENT_PROFILE_TAB:[
    {
      name:"Company details",
      tabId:1,
    },
    {
      name:"Profile details",
      tabId:2,
    },
    {
      name:"Approval requests",
      tabId:3,
    },
  ]
};

export const MyData = {
  data: {},
};

export const StatusCategory = {
  1: "Pending",
  2: "On Going",
  3: "Completed",
};

const STATUS_COLOR = {
  1: "bg-soft-danger text-danger p-1",
  2: "bg-soft-primary text-primary p-1",
  3: "bg-soft-success text-success p-1",
};

export const packageSubtype = {
  "Non-Reusable": [
    {
      label: `Corrugated`,
      value: "Corrugated",
    },
    {
      label: `Wooden`,
      value: "Wooden",
    },
    {
      label: `Polythene`,
      value: "Polythene",
    },
  ],
  Reusable: [
    {
      label: `PLS`,
      value: "PLS",
    },
    {
      label: `Metal`,
      value: "Metal",
    },
    {
      label: `FLC`,
      value: "FLC",
    },
    {
      label: `Combipak`,
      value: "Combipak",
    },
    {
      label: `Folderpak`,
      value: "Folderpak",
    },
    {
      label: `Bin`,
      value: "Bin",
    },
    {
      label: `Trolley`,
      value: "Trolley",
    },
  ],
};

export const StatusButton = ({ value, onClick = {}, disabled = false }) => {
  return (
    <MDBBadge
      className={STATUS_COLOR[value]}
      style={{
        borderRadius: "4px",
        cursor: "pointer",
        width: "90px",
        fontSize: "14px",
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {StatusCategory[value]}
    </MDBBadge>
  );
};

export const roles = localStorage.getItem("userRole");

export const viewRole =
  (localStorage.getItem("client") && localStorage.getItem("client")) ||
  (localStorage.getItem("siteViewer") && localStorage.getItem("siteViewer"));

export const getLatestEightFinancialYears = (numYears) => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 0; i < numYears; i++) {
    const startYear = currentYear - i - 1; // Financial year starts from previous year
    const endYear = (startYear + 1).toString().slice(-2);
    years.push({
      label: `${startYear}-${endYear}`,
      value: `${startYear}-${endYear}`,
    });
  }
  return years;
};

export const getLatestEightCalendarYears = (numYears) => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 0; i < numYears; i++) {
    const startYear = currentYear - i - 1; // Financial year starts from previous year
    const endYear = startYear.toString().slice(-2);
    years.push({ label: `${startYear}`, value: `${startYear}-${endYear}` });
  }
  return years;
};

const CONSTANT = {
  API: {
    PUBLIC:{
      getGstDetails:createEndPoint.post(`/open/getGSTDetails`),
    },
    ADMIN: {
      getMe: createEndPoint.get(`/auth/getMe`),
      roleGetMe: createEndPoint.get("/userManagement/getMe"),
      getOTP: createEndPoint.post(`/auth/get-otp`),
      verifyOTP: createEndPoint.post(`/auth/verify-otp`),
      adminLogin: createEndPoint.post(`/auth/login`),
      adminUpdate: createEndPoint.patch(`/auth/updateMe`),
      clientToken: createEndPoint.get(`/auth/getClientToken?clientId=:id`),
      signup: createEndPoint.post(`/auth/signup`),
      register:createEndPoint.post(`/auth/register`), 
      getSupplierList: createEndPoint.get(`${roles}/supplierTypeToCategory`),
    },
    CLIENT: {
      get: createEndPoint.get(`${roles}/client`),
      create: createEndPoint.post(`${roles}/client`),
      update: createEndPoint.patch(`${roles}/client/:id`),
      delete: createEndPoint.delete(`${roles}/client/:id`),
      getMeClient: createEndPoint.get(`${roles}/client/getMe`),
      getSingleClient: createEndPoint.get(`${roles}/client/:id`),
      getSupplierList: createEndPoint.get(`${roles}/supplierTypeToCategory`),
    },
    SPOC: {
      getMe: createEndPoint.get(`spoc/getMe`),
    },
    DRIVER: {
      get: createEndPoint.get(`${roles}/driver`),
      create: createEndPoint.post(`${roles}/driver`),
      update: createEndPoint.patch(`${roles}/driver/:id`),
      delete: createEndPoint.delete(`${roles}/driver/:id`),
      addDriverVerifyByLicense: createEndPoint.post(
        `${roles}/driver/checkDriver`
      ),
    },
    ANALYSIS: {
      get: createEndPoint.get(`${roles}/brsrForm/analysis`),
      getAdmin: createEndPoint.get(`${roles}/brsrForm/analysis/:id`),
    },
    TRANSPORTER: {
      get: createEndPoint.get(`${roles}/transporter`),
      create: createEndPoint.post(`${roles}/transporter`),
      update: createEndPoint.patch(`${roles}/transporter/:id`),
      delete: createEndPoint.delete(`${roles}/transporter/:id`),
      getSingleTransporter: createEndPoint.get(`${roles}/transporter/:id`),
      addTransporterVerifyByGst: createEndPoint.post(
        `${roles}/gst/getGSTDetails`
      ),
    },
    VEHICLE: {
      get: createEndPoint.get(`${roles}/vehicle`),
      create: createEndPoint.post(`${roles}/vehicle`),
      update: createEndPoint.patch(`${roles}/vehicle/:id`),
      delete: createEndPoint.delete(`${roles}/vehicle/:id`),
      addVehicleVerifyByNumber: createEndPoint.post(
        `${roles}/vehicle/checkVehicle`
      ),
      getDriverByVehicle: createEndPoint.get(
        `${roles}/vehicle/getDriverByVehicle/:id`
      ),
    },
    LOCATION: {
      getLocationConsentApi: createEndPoint.get(
        `${roles}/location/consentApi/:id`
      ),
      getLocationByTripId: createEndPoint.get(`${roles}/location/:id`),
    },
    SHIPMENT: {
      get: createEndPoint.get(`${roles}/trip?limit=500`),
      getwithoutLimit: createEndPoint.get(`${roles}/trip`),
      create: createEndPoint.post(`${roles}/trip`),
      getSingleTrip: createEndPoint.get(`${roles}/trip/:id`),
      getSingleFilterTripClient: createEndPoint.get(
        `${roles}/trip?clientId=:id`
      ),
      getPlantTrip: createEndPoint.get(`${roles}/trip?plantId=:id`),
      getRouteTrip: createEndPoint.get(`${roles}/trip/?status=2&routeId=:id`),
      update: createEndPoint.patch(`${roles}/trip/:id`),
      delete: createEndPoint.delete(`${roles}/trip/:id`),
    },
    IntensityMetrics: {
      get: createEndPoint.get(`${roles}/intensityMetric`),
      post: createEndPoint.post(`${roles}/intensityMetric`),
    },
    SITE: {
      get: createEndPoint.get(`${roles}/plant`),
      create: createEndPoint.post(`${roles}/plant`),
      update: createEndPoint.patch(`${roles}/plant/:id`),
      delete: createEndPoint.delete(`${roles}/plant/:id`),
      getSinglePlant: createEndPoint.get(`${roles}/plant/:id`),
      getClientPlant: createEndPoint.get(`${roles}/plant?clientId=:id`),
      addPlantVerifyByGst: createEndPoint.post(`${roles}/gst/getGSTDetails`),
    },

    ROUTE: {
      get: createEndPoint.get(`${roles}/shipmentRoute`),
      create: createEndPoint.post(`${roles}/shipmentRoute`),
      update: createEndPoint.patch(`${roles}/shipmentRoute/:id`),
      delete: createEndPoint.delete(`${roles}/shipmentRoute/:id`),
      getClientRoute: createEndPoint.get(`${roles}/shipmentRoute?clientId=:id`),
      getPlantRoute: createEndPoint.get(`${roles}/shipmentRoute?plantId=:id`),
    },
    PACKAGEING: {
      get: createEndPoint.get(`${roles}/packagingType`),
      create: createEndPoint.post(`${roles}/packagingType`),
      update: createEndPoint.patch(`${roles}/packagingType/:id`),
      delete: createEndPoint.patch(`${roles}/packagingType/:id`),
      // getPackageAssignment: createEndPoint.get(`/packageAssignment`),
      // getSinglepackageDataClient: createEndPoint.get(
      //   `/admin/getPackageDataByClient/:id`
      // ),

      getSinglepackageDataSite: createEndPoint.get(
        `${roles}/plant/getPackagesTypeInfoByPlant/:id`
      ),
      // addPackageAssignment: createEndPoint.post(`/packageAssignment`),
      // deletePackageAssignment: createEndPoint.delete(`/packageAssignment/:id`),
    },
    PACKAGINGASSIGNMENT: {
      get: createEndPoint.get(`${roles}/packageAssignment`),
      create: createEndPoint.post(`${roles}/packageAssignment`),
      update: createEndPoint.patch(`${roles}/packageAssignment/:id`),
      delete: createEndPoint.patch(`${roles}/packageAssignment/:id`),
      getSinglepackageDataClient: createEndPoint.get(
        `${roles}/packageAssignment/getPackageDataByClient/:id`
      ),
    },
    SETTING: {
      get: createEndPoint.get(`${roles}/setting/distance`),
      create: createEndPoint.post(`${roles}/setting`),

      update: createEndPoint.patch(`${roles}/setting/distance`),
      delete: createEndPoint.delete(`${roles}/setting/distance`),
    },
    ROLEANDACCESS: {
      get: createEndPoint.get(`${roles}/userManagement/getAll`),
      getSingle:createEndPoint.get(`${roles}/userManagement/:id`),
      create: createEndPoint.post(`${roles}/userManagement/signup`),
      update: createEndPoint.patch(`${roles}/userManagement/:id`),
      delete: createEndPoint.delete(`${roles}/userManagement/:id`),
      getClientRoleAndAccess: createEndPoint.get(
        `${roles}/userManagement/getAll?clientId=:id`
      ),
    },
    DASHBOARD: {
      getAnalysis: createEndPoint.get(`${roles}/analysis`),
      getClientAnalysis: createEndPoint.get(`${roles}/clientAnalytics`),
      getScope2Data: createEndPoint.get(`${roles}/analysis/subCategory/:id`),
    },
    CHANGELOGS: {
      get: createEndPoint.get(`client/client/getAuditLogs`),
    },
    CATEGORY: {
      get: createEndPoint.get(`${roles}/category`),
      create: createEndPoint.get(`${roles}/category`),
      update: createEndPoint.patch(`${roles}/category/:id`),
      delete: createEndPoint.delete(`${roles}/category/:id`),
      getSingleCategory: createEndPoint.get(`${roles}/category/:id`),
    },
    ATTRIBUTE: {
      get: createEndPoint.get(`${roles}/attributes`),
      create: createEndPoint.post(`${roles}/attributes`),
      update: createEndPoint.patch(`${roles}/attributes/:id`),
      delete: createEndPoint.delete(`${roles}/attributes/:id`),
    },
    SUBCATEGORY: {
      get: createEndPoint.get(`${roles}/subCategory`),
      create: createEndPoint.post(`${roles}/subCategory`),
      update: createEndPoint.patch(`${roles}/subCategory/:id`),
      delete: createEndPoint.delete(`${roles}/subCategory/:id`),
    },
    SUBCATEGORYATTRIBUTE: {
      get: createEndPoint.get(`${roles}/subCategoryAttribute`),
      create: createEndPoint.post(`${roles}/subCategoryAttribute`),
      update: createEndPoint.patch(`${roles}/subCategoryAttribute/:id`),
      delete: createEndPoint.delete(`${roles}/subCategoryAttribute/:id`),
      getSingleFilterSubCategoryAttribute: createEndPoint.get(
        `${roles}/subCategoryAttribute?subCategoryId=:id"`
      ),
    },
    JOB: {
      get: createEndPoint.get(`${roles}/job`),
      create: createEndPoint.post(`${roles}/job`),
      update: createEndPoint.patch(`${roles}/job/:id`),
      delete: createEndPoint.delete(`${roles}/job/:id`),
      getClientJob: createEndPoint.get(`${roles}/job?clientId=:id`),
      getduplicateEntry: createEndPoint.get(`${roles}/job/duplicateData/:id`),

      getduplicateRecordAnalytic: createEndPoint.get(
        `${roles}/job/numberOfDuplicateDataByClient`
      ),
      getduplicateClientRecordAnalytic: createEndPoint.get(
        `${roles}/job/numberOfDuplicateDataByClient/:id`
      ),
      getFuelType: createEndPoint.get(`${roles}/emissionFactor/fuelData`),
      getGasUsed: createEndPoint.get(`${roles}/emissionFactor/GWPData`),
      getWasteUsed: createEndPoint.get(
        `${roles}/emissionFactor/wasteManagement`
      ),
      getEmployeeCommute: createEndPoint.get(
        `${roles}/emissionFactor/employeeCommute`
      ),
      getEmployeeLabelCommute: createEndPoint.get(
        `${roles}/emissionFactor/employeeCommute/getAllWithDistinctName`
      ),
      getPurchasedGoodServiceCategory: createEndPoint.get(
        `${roles}/purchasedGoodsAndServices/categories`
      ),
      getPurchasedGoodServiceSubCategory: createEndPoint.get(
        `${roles}/purchasedGoodsAndServices/subCategories/:id`
      ),
      getPurchasedGoodServiceRawMaterialType: createEndPoint.get(
        `${roles}/purchasedGoodsAndServices/rawMaterialTypes/:id`
      ),
      getCapitalGoodCategory: createEndPoint.get(
        `${roles}/capitalGoods/categories`
      ),
      getCapitalGoodSubCategory: createEndPoint.get(
        `${roles}/capitalGoods/subCategories/:id`
      ),
      getAirports: createEndPoint.get(`${roles}/airports`),
    },
    //baki che
    JOBVIEW: {
      get: createEndPoint.get(`${roles}/job/jobInformation/:id`),
      update: createEndPoint.patch(`${roles}/job/updateFailedJobData/:id`),
      // delete: createEndPoint.delete(`${roles}/form/:id`),

      // get: createEndPoint.get(`${roles}/form/:id`),
      // update: createEndPoint.patch(`${roles}/form/:id`),
      delete: createEndPoint.delete(`${roles}/form/:id`),

      deleteAllDuplicate: createEndPoint.post(`${roles}/form/bulkDelete`),
    },
    ATTRIBUTEOPTION: {
      get: createEndPoint.get(`${roles}/attributeOptions`),
      create: createEndPoint.post(`${roles}/attributeOptions`),
      update: createEndPoint.patch(`${roles}/attributeOptions/:id`),
      delete: createEndPoint.delete(`${roles}/attributeOptions/:id`),
    },
    BRSRFORMS: {
      get: createEndPoint.get(`${roles}/brsrFormFieldValues`),
      get2: createEndPoint.get(`${roles}/brsrFormFieldValues/:id`),
      create: createEndPoint.post(`${roles}/brsrFormFieldValues/:id`),
    },
    BRSRSCORE: {
      get: createEndPoint.get(`${roles}/brsrScore`),
    },
    PROPROCESSING: {
      get: createEndPoint.get(`${roles}/proProcessing`),
      create: createEndPoint.post(`${roles}/proProcessing`),
      update: createEndPoint.patch(`${roles}/proProcessing/:id`),
      delete: createEndPoint.delete(`${roles}/proProcessing/:id`),
      getFilterPreProcessing: createEndPoint.get(
        `${roles}/proProcessing?subCategoryId=:id`
      ),
    },
    CATEGORYFORM: {
      getCarbonEmissionAnalysis: createEndPoint.get(
        `${roles}/uploadShipmentData/carbonEmissionAnalysis`
      ),
      getObjectKey: createEndPoint.get(`${roles}/uploadShipmentData/objectKey`),
      adduploadShipmentData: createEndPoint.post(
        `${roles}/uploadShipmentData/processShipmentData`
      ),
      getFormData: createEndPoint.get(
        `${roles}/uploadShipmentData/getFormData/:id`
      ),
      update: createEndPoint.patch(`${roles}/uploadShipmentData/:id`),
      delete: createEndPoint.delete(`${roles}/uploadShipmentData/:id`),
    },

    PCFPRODUCT: {
      get: createEndPoint.get(`product`),
      getSingle: createEndPoint.get(`product/:id`),
      create: createEndPoint.post(`product`),
      update: createEndPoint.patch(`product/:id`),
      delete: createEndPoint.delete(`product/:id`),
      getProductFilterByClient: createEndPoint.get(`product?clientId=:id`),
    },

    PCFPRODUCTCATEGORY: {
      get: createEndPoint.get(`productCategory`),
      create: createEndPoint.post(`productCategory`),
      update: createEndPoint.patch(`productCategory/:id`),
      delete: createEndPoint.delete(`productCategory/:id`),
      getForProduct: createEndPoint.get(`productCategory/product`),
      getForMaterial: createEndPoint.get(`productCategory/material`),
      getForPackaging: createEndPoint.get(`productCategory/packaging`),
      getForAssembly: createEndPoint.get(`productCategory/assembly`),
      getForPurchsedGoodService: createEndPoint.get(
        `productCategory?typeId=:id`
      ),
    },
    PCFPRODUCTSUBCATEGORY: {
      get: createEndPoint.get(`productSubCategory`),
      create: createEndPoint.post(`productSubCategory`),
      update: createEndPoint.patch(`productSubCategory/:id`),
      delete: createEndPoint.delete(`productSubCategory/:id`),
      getSubCategoryFilterByCategory: createEndPoint.get(
        `productSubCategory?productCategoryId=:id`
      ),
    },
    PCFPRODUCTQUALITY: {
      get: createEndPoint.get(`productQuality`),
      create: createEndPoint.post(`productQuality`),
      update: createEndPoint.patch(`productQuality/:id`),
      delete: createEndPoint.delete(`productQuality/:id`),
      getQualityFilterBySubCategory: createEndPoint.get(
        `productQuality?productSubCategoryId=:id`
      ),
    },
    PCFRPRODUCTPROCESSCATEGORY: {
      get: createEndPoint.get(`processCategory`),
      create: createEndPoint.post(`processCategory`),
      update: createEndPoint.patch(`processCategory/:id`),
      delete: createEndPoint.delete(`processCategory/:id`),
      getProceessFilterByProductSubCategory: createEndPoint.get(
        `processCategory?productSubCategoryId=:id`
      ),
    },
    PCFRPRODUCTPROCESSSUBCATEGORY: {
      get: createEndPoint.get(`processSubCategory`),
      create: createEndPoint.post(`processSubCategory`),
      update: createEndPoint.patch(`processSubCategory/:id`),
      delete: createEndPoint.delete(`processSubCategory/:id`),
      getProcessSubCategoryFilterByProcessCategory: createEndPoint.get(
        `processSubCategory?processCategoryId=:id`
      ),
    },
    PCFPRODUCTEMISSIONSOURCE: {
      get: createEndPoint.get(`emissionSource`),
      create: createEndPoint.post(`emissionSource`),
      update: createEndPoint.patch(`emissionSource/:id`),
      delete: createEndPoint.delete(`emissionSource/:id`),
      getEnergySource: createEndPoint.get(`emissionSource/energySource`),
      getTranportMode: createEndPoint.get(`emissionSource/transportation`),
    },
    PCFPRODUCTMATERIAL: {
      get: createEndPoint.get(`productMaterial`),
      getSingle: createEndPoint.get(`productMaterial/:id`),
      create: createEndPoint.post(`productMaterial`),
      update: createEndPoint.patch(`productMaterial/:id`),
      delete: createEndPoint.delete(`productMaterial/:id`),
      getProductWise: createEndPoint.get(`productMaterial?productId=:id`),
      createBulkUpload: createEndPoint.post(`productMaterial/bulkCreate`),
    },
    PCFPRODUCTPACKAGING: {
      get: createEndPoint.get(`productPackaging`),
      getSingle: createEndPoint.get(`productPackaging/:id`),
      getProductWise: createEndPoint.get(`productPackaging?productId=:id`),
      create: createEndPoint.post(`productPackaging`),
      update: createEndPoint.patch(`productPackaging/:id`),
      delete: createEndPoint.delete(`productPackaging/:id`),
      createBulkUpload: createEndPoint.post(`productPackaging/bulkCreate`),
    },
    PCFPRODUCTASSEMBLY: {
      get: createEndPoint.get(`productAssembly`),
      getSingle: createEndPoint.get(`productAssembly/:id`),
      getProductWise: createEndPoint.get(`productAssembly?productId=:id`),
      create: createEndPoint.post(`productAssembly`),
      update: createEndPoint.patch(`productAssembly/:id`),
      delete: createEndPoint.delete(`productAssembly/:id`),
    },
    PCFPRODUCTPROCESS: {
      get: createEndPoint.get(`productProcessing`),
      getSingle: createEndPoint.get(`productProcessing/:id`),
      getProductWise: createEndPoint.get(`productProcessing?productId=:id`),
      create: createEndPoint.post(`productProcessing`),
      update: createEndPoint.patch(`productProcessing/:id`),
      delete: createEndPoint.delete(`productProcessing/:id`),
    },
    PCFRESULTCALCULATION: {
      getProductWise: createEndPoint.get(`/calculate/:id`),
    },
    BRSR_SPOC: {
      get: createEndPoint.get(`${roles}/spoc?clientId=:id`),
      getSingle: createEndPoint.get(`${roles}/spoc/:id`),
      create: createEndPoint.post(`${roles}/spoc`),
      update: createEndPoint.patch(`${roles}/spoc/:id`),
      delete: createEndPoint.delete(`${roles}/spoc/:id`),
    },
    BRSR_FORMS: {
      get: createEndPoint.get(`${roles}/brsrForm`),
      getSingle: createEndPoint.get(`${roles}/brsrForm/:id`),
      // create: createEndPoint.post(`${roles}/brsrForm`),
      create: createEndPoint.post(`${roles}/brsrForm/bulkCreate`),
      update: createEndPoint.patch(`${roles}/brsrForm/:id`),
      bulkUpdateETA: createEndPoint.patch(`${roles}/brsrForm/bulkUpdateETA`),
    },
    BRSR_SPOC_ALLOT: {
      create: createEndPoint.post(`${roles}/brsrForm/assignSPOC/:id`),
      get: createEndPoint.get(`${roles}/spoc`),
      getSingle: createEndPoint.get(`${roles}/brsrForm/:id`),
    },

    GLOBALFORMS: {
      get: createEndPoint.get(
        `${roles}/globalForm/global-sections/:id/questions?includeAnswers=true`
      ),
      get2: createEndPoint.get(
        `${roles}/globalForm/global-sections/:id/questions?includeAnswers=true&clientId=:client_id`
      ),
      create: createEndPoint.post(`${roles}/globalForm/answers`),
      create2: createEndPoint.post(`${roles}/globalForm/answers?clientId=:id`),
    },
    GLOBAL_SECTIONS: {
      getSingle: createEndPoint.get(`${roles}/globalForm/global-sections/:id`),
      get: createEndPoint.get(`${roles}/globalForm/global-sections`),
      // create: createEndPoint.post(`${roles}/brsrForm/bulkCreate`),
      // update: createEndPoint.patch(`${roles}/brsrForm/:id`),
      // bulkUpdateETA: createEndPoint.patch(`${roles}/brsrForm/bulkUpdateETA`),
    },
    GLOBAL_SPOCS: {
      get: createEndPoint.get(`${roles}/globalFormSPOC/getSPOCs/:id`),
      create: createEndPoint.post(`${roles}/globalFormSPOC/assignSPOC/:id`),
    },
    ADD_EMISSION_FACTORS: {
      create: createEndPoint.post(`${roles}/addEmissionFactor`),
    },
    GLOBAL_REPORT: {
      get: createEndPoint.get(
        "https://reporting.fitsolscs.com/api/brsr/generate?clientId=:id"
      ),
      get2: createEndPoint.get(
        "https://reporting.fitsolscs.com/api/brsr/generate"
      ),
    },
    MANAGE_CONNECTIONS_INCOMING: {
      get: createEndPoint.get(
        `${roles}/connections?status=PENDING&type=incoming&clientId=:id`
      ),
      update: createEndPoint.patch(`${roles}/connections/:id`),
      delete: createEndPoint.delete(`${roles}/connections/:id?clientId=:connectionToId`),
    },
    MANAGE_CONNECTIONS_OUTGOING: {
      get: createEndPoint.get(
        `${roles}/connections?status=PENDING&type=incoming&clientId=:id`
      ),
      update: createEndPoint.patch(`${roles}/connections/:id`),
      delete: createEndPoint.delete(`${roles}/connections/:id?clientId=:connectionFromId`),
    },
    CONNECTIONS: {
      post: createEndPoint.post(`${roles}/connections`),
      bulkCreate:createEndPoint.post(`${roles}/connections/bulkCreate`),
      get:createEndPoint.get(`${roles}/connectionJobs`),
      getClientJob:createEndPoint.get(`${roles}/connectionJobs?clientId=:id`),
      getSingleJob:createEndPoint.get(`${roles}/connectionJobs/:id?clientId=:client_id`),
      getSingleClientJob: createEndPoint.get(`${roles}/connectionJobs/:id`),
    },
    USERAPPROVAL:{
      getAllUnverifiedClientAdmin: createEndPoint.get(`${roles}/connections/getUnverifiedClientList?clientId=:id`),
      verifyApproval: createEndPoint.patch(`${roles}/connections/user/verifyNewClient`),
    },
  },
  MENU_ITEM: [
    {
      id: 1,
      submenu: [
        {
          id: 1,
          className: "waves-effect",
          to: "/shipmentForm",
          lable: "Trip form",
          icon: "bx bxs-report",
        },
      ],
    },
  ],
  MENU: {
    admin: [
      {
        id: 1,
        title: "",
        submenu: [
          {
            id: 1,
            className: "waves-effect",
            to: `${adminRoute}`,
            lable: "Dashboard",
            icon: <BiSolidReport />,
          },
          {
            id: 2,
            className: " has-arrow waves-effect",
            // to: `${adminRoute}/shipments`,
            to: `shipment-Menu`,
            lable: "Shipments",
            icon: <BiPaste />,
            submenu: [
              {
                id: 4,
                className: "waves-effect",
                // to: `${adminRoute}/tracking`,
                to: `Track-shipment-Menu`,
                lable: "Track shipments",
                icon: <BiMap />,
                submenu: [
                  {
                    id: 56,
                    className: "waves-effect",
                    to: `${adminRoute}/tracking`,
                    lable: "View transit",
                    icon: <BiMap />,
                  },
                  {
                    id: 55,
                    className: "waves-effect",
                    to: `${adminRoute}/shipments`,
                    lable: "Shipment history",
                    icon: <BiPaste />,
                  },
                ],
              },
              {
                id: 5,
                className: "has-arrow waves-effect",
                lable: "Customers",
                // to: `${adminRoute}/customers`,
                to: `customer-menu`,
                icon: <BiSolidUserDetail />,
                submenu: [
                  {
                    id: 6,
                    to: `${adminRoute}/customers`,
                    lable: "Customer details",
                    icon: <BiSolidUserDetail />,
                  },
                  {
                    id: 7,
                    to: `${adminRoute}/sites`,
                    lable: "Site details",
                    icon: <BiSolidFactory />,
                  },
                  {
                    id: 8,
                    to: `${adminRoute}/routeInfo`,
                    lable: "Route details",
                    icon: <FaRoute />,
                  },
                ],
              },
              {
                id: 9,
                to: `${adminRoute}/packaging`,
                lable: "Packaging",
                icon: <BiSolidInbox />,
              },
              {
                id: 10,
                // to: `${adminRoute}/transporter`,
                to: "transporter-menu",
                className: "has-arrow waves-effect",
                lable: "Transporter",
                icon: <BiSolidUserRectangle />,
                submenu: [
                  {
                    id: 13,
                    to: `${adminRoute}/transporter`,
                    lable: "Transporter details",
                    icon: <BiSolidUserRectangle />,
                  },
                  {
                    id: 11,
                    className: "waves-effect",
                    to: `${adminRoute}/drivers`,
                    lable: "Driver details",
                    icon: <FaUser />,
                  },
                  {
                    id: 12,
                    className: "waves-effect",
                    to: `${adminRoute}/vehicles`,
                    lable: "Vehicle details",
                    icon: <BiSolidTruck />,
                  },
                ],
              },
            ],
          },
          {
            id: 14,
            className: "waves-effect",
            to: `${adminRoute}/roleaccess`,
            lable: "Roles and access",
            icon: <BiGroup />,
          },
          // {
          //   id: 14,
          //   className: "waves-effect",
          //   to: `${adminRoute}/category`,
          //   lable: "Category",
          //   icon: <BiCategory />,
          // },
          {
            id: 15,
            className: "waves-effect",
            to: `${adminRoute}/settings`,
            lable: "Settings",
            icon: <BiCog />,
          },
        ],
      },
      // {
      //   id: 1,
      //   title: "Analytics",
      //   submenu: [
      //     {
      //       id: 1,
      //       className: "waves-effect",
      //       to: "/",
      //       lable: "Dashboard",
      //       icon: "bx bxs-report",
      //     },
      //     {
      //       id: 2,
      //       className: "waves-effect",
      //       to: "/tracking",
      //       lable: "Track Shipments",
      //       icon: "bx bxs-map",
      //     },
      //   ],
      // },
      // {
      //   id: 2,
      //   title: "Operation",
      //   submenu: [
      //     {
      //       id: 1,
      //       className: "has-arrow waves-effect",
      //       lable: "Customers",
      //       to: "/customers",
      //       icon: "bx bxs-user-detail",
      //       submenu: [
      //         {
      //           id: 1,
      //           to: "/customers",
      //           lable: "Customers",
      //         },
      //         {
      //           id: 2,
      //           to: "/sites",
      //           lable: "Sites",
      //         },
      //         {
      //           id: 3,
      //           to: "/routeInfo",
      //           lable: "Routes",
      //         },
      //       ],
      //     },
      //     {
      //       id: 4,
      //       to: "/packaging",
      //       lable: "Packaging",
      //       icon: "bx bxs-inbox",
      //     },
      //     {
      //       id: 2,
      //       to: "/transporter",

      //       lable: "Transporter",
      //       icon: "bx bxs-user-rectangle",
      //     },
      //     {
      //       id: 2,
      //       className: "waves-effect",
      //       to: "/drivers",
      //       lable: "Drivers",
      //       icon: "fas fa-user",
      //     },
      //     {
      //       id: 2,
      //       className: "waves-effect",
      //       to: "/vehicles",
      //       lable: "Vehicles",
      //       icon: "bx bxs-truck",
      //     },
      //     {
      //       id: 3,
      //       className: "waves-effect",
      //       to: "/shipments",
      //       lable: "Shipments",
      //       icon: "bx bxs-paste",
      //     },

      //     {
      //       id: 5,
      //       className: "waves-effect",
      //       to: "/settings",
      //       lable: "Settings",
      //       icon: "bx bxs-cog",
      //     },

      //     {
      //       id: 5,
      //       className: "waves-effect",
      //       to: "/roleaccess",
      //       lable: "Roles And Access",
      //       icon: "bx bxs-group",
      //     },

      // {
      //   id: 6,
      //   className: "has-arrow waves-effect",
      //   lable: "Emission",
      //   to: "/emission",
      //   icon: "bx bxs-user-detail",
      //   submenu: [
      //     {
      //       id: 1,
      //       to: "/category",
      //       lable: "Category",
      //     },
      //     // {
      //     //   id: 2,
      //     //   to: "/sub-category",
      //     //   lable: "Sub-Category",
      //     // },
      //     {
      //       id: 3,
      //       to: "/attribute",
      //       lable: "Attribute",
      //     },
      //     {
      //       id: 4,
      //       to: "/category-form",
      //       lable: "Category Form",
      //     },
      //   ],
      // },
      // ],
      // },
    ],
    client: [
      {
        id: 156,
        title: "",
        submenu: [
          {
            id: 1,
            className: "waves-effect",
            to: `${clientRoute}/:id`,
            lable: "Dashboard",
            icon: <BiSolidReport />,
          },
          {
            id: 2,
            className: "has-arrow waves-effect",
            lable: "Shipments",
            to: `shipment-Menu`,
            icon: <BiPaste />,
            submenu: [
              {
                id: 3,
                className: "waves-effect",
                to: `Track-shipment-Menu`,
                lable: "Track shipments",
                icon: <BiMap />,
                submenu: [
                  {
                    id: 4,
                    to: `${clientRoute}/:id/tracking`,
                    lable: "View transit",
                    icon: <BiMap />,
                  },
                  {
                    id: 5,
                    to: `${clientRoute}/:id/shipments`,
                    lable: "Shipment history",
                    icon: <BiPaste />,
                  },
                ],
              },
              {
                id: 6,
                className: "has-arrow waves-effect",
                lable: "Customers",
                // to: `${adminRoute}/customers`,
                to: `customer-menu`,
                icon: <BiSolidUserDetail />,
                submenu: [
                  {
                    id: 7,
                    className: "waves-effect",
                    to: `${clientRoute}/:id/sites`,
                    lable: "Site details",
                    icon: <BiSolidFactory />,
                  },
                  {
                    id: 8,
                    to: `${clientRoute}/:id/routeInfo`,
                    lable: "Route details",
                    icon: <FaRoute />,
                  },
                ],
              },
              {
                id: 9,
                to: `${clientRoute}/:id/packaging`,
                lable: "Packaging",
                icon: <BiSolidInbox />,
              },
            ],
          },

          {
            id: 10,
            className: "waves-effect",
            to: `${clientRoute}/:id/roleaccess`,
            lable: "Roles and access",
            icon: <BiGroup />,
          },
          {
            id: 23,
            className: "has-arrow waves-effect",
            lable: "Carbon Accounting",
            icon: <MdOutlineAccountTree />,
            submenu: [
              {
                id: 11,
                className: "has-arrow waves-effect",
                lable: "Emissions",
                to: `${clientRoute}/:id/emissions`,
                icon: <TbBrandCarbon />,
                submenu: [
                  // {
                  //   id: 1,
                  //   to: `${clientRoute}/:id/emission-dashboard``,
                  //   lable: "Dashboard",
                  // },
                  {
                    id: 12,
                    to: `${clientRoute}/:id/add-entry`,
                    lable: "Add new entry",
                    icon: <RiAddCircleFill />,
                  },
                  {
                    id: 14,
                    className: "waves-effect",
                    to: `Scope-wise Report`,
                    lable: "Scope-wise report",
                    icon: <BiPaste />,
                    submenu: [
                      {
                        id: 15,
                        to: `${clientRoute}/:id/scope`,
                        lable: "Total emissions",
                        icon: <BiPaste />,
                      },
                      {
                        id: 16,
                        to: `${clientRoute}/:id/scope1`,
                        lable: "Scope 1 report",
                        icon: <BiPaste />,
                      },
                      {
                        id: 17,
                        to: `${clientRoute}/:id/scope2`,
                        lable: "Scope 2 report",
                        icon: <BiPaste />,
                      },
                      {
                        id: 18,
                        to: `${clientRoute}/:id/scope3`,
                        lable: "Scope 3 report",
                        icon: <BiPaste />,
                      },
                      {
                        id: 19,
                        to: `${clientRoute}/:id/biogenic`,
                        lable: "Biogenic emissions report",
                        icon: <BiPaste />,
                      },
                    ],
                  },
                  {
                    id: 13,
                    to: `${clientRoute}/:id/pcfproduct`,
                    lable: "Product carbon footprint",
                    icon: <RiProductHuntLine />,
                  },
                  // {
                  //   id: 3,
                  //   to: `/generate-report`,
                  //   lable: "Generate Reports",
                  // },
                  // {
                  //   id: 4,
                  //   to: `/download-report`,
                  //   lable: "Download data",
                  // },
                ],
              },
              {
                id: 19,
                className: "has-arrow waves-effect",
                lable: "ESG reporting",
                to: `${clientRoute}/:id/brsr`,
                icon: <BiLeaf />,
                submenu: [
                  {
                    id: 20,
                    to: `${clientRoute}/:id/brsr`,
                    lable: "BRSR",
                    icon: <BiPaste />,
                  },
                ],
              },
              {
                id: 20,
                className: "waves-effect",
                lable: "Change Logs",
                to: `${clientRoute}/:id/changeLogs`,
                icon: <TbFileDescription />,
              },
              {
                id: 21,
                className: "has-arrow waves-effect",
                lable: "Networking",
                to: `network`,
                icon: <BiNetworkChart />,
                submenu: [
                  {
                    id: 22,
                    to: `${clientRoute}/:id/network/partneronboard`,
                    lable: "Onboard partner",
                    icon: <BiLinkExternal />,
                  },
                  {
                    id: 22,
                    to: `${clientRoute}/:id/network/manageconnection`,
                    lable: "Manage connections",
                    icon: <BiNetworkChart />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    siteViewer: [
      {
        id: 1,
        title: "",
        submenu: [
          {
            id: 2,
            className: "waves-effect",
            to: `${siteRoute}/:id`,
            lable: "Dashboard",
            icon: <BiSolidReport />,
          },

          {
            id: 3,
            className: "has-arrow waves-effect",
            lable: "Shipments",
            to: `${siteRoute}/:id/tracking`,
            icon: <BiMap />,
            submenu: [
              {
                id: 4,
                className: "waves-effect",
                // to: `${adminRoute}/tracking`,
                lable: "Track shipments",
                to: `Track-shipment-Menu`,
                icon: <BiMap />,
                submenu: [
                  {
                    id: 5,
                    to: `${siteRoute}/:id/tracking`,
                    lable: "View transit",
                    icon: <BiMap />,
                  },
                  {
                    id: 6,
                    to: `${siteRoute}/:id/shipments`,
                    lable: "Shipment history",
                    icon: <BiPaste />,
                  },
                ],
              },
              {
                id: 7,
                to: `site-menu`,
                lable: "Site details",
                icon: <BiSolidFactory />,
                submenu: [
                  {
                    id: 8,
                    to: `${siteRoute}/:id/routeInfo`,
                    lable: "Route details",
                    icon: <FaRoute />,
                  },
                ],
              },
              {
                id: 9,
                to: `${siteRoute}/:id/site-packaging`,
                lable: "Packaging",
                icon: <BiSolidInbox />,
              },
            ],
          },
          {
            id: 23,
            className: "has-arrow waves-effect",
            lable: "Carbon Accounting",
            icon: <MdOutlineAccountTree />,
            submenu: [
              {
                id: 19,
                className: "waves-effect",
                lable: "Change Logs",
                to: `${siteRoute}/:id/changeLogs`,
                icon: <TbFileDescription />,
              },
            ],
          },
        ],
      },
    ],
    tripCreator: [
      {
        id: 1,
        submenu: [
          {
            id: 1,
            className: "waves-effect",
            to: `${tripRoute}`,
            lable: "Trip form",
            icon: <BiSolidReport />,
          },
          {
            id: 2,
            className: "has-arrow waves-effect",
            lable: "Shipments",
            to: `shipment-Menu`,
            icon: <BiPaste />,
            submenu: [
              {
                id: 3,
                className: "waves-effect",
                to: `Track-shipment-Menu`,
                lable: "Track shipments",
                icon: <BiMap />,
                submenu: [
                  {
                    id: 4,
                    to: `${tripRoute}/tracking`,
                    lable: "View transit",
                    icon: <BiMap />,
                  },
                  {
                    id: 5,
                    to: `${tripRoute}/shipments`,
                    lable: "Shipment history",
                    icon: <BiPaste />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    spoc: [
      {
        id: 1,
        submenu: [
          {
            id: 2,
            className: "has-arrow waves-effect",
            lable: "ESG reporting",
            to: `${spocRoute}/:id/brsr`,
            icon: <BiLeaf />,
            submenu: [
              {
                id: 3,
                to: `${spocRoute}/:id/brsr`,
                lable: "BRSR",
                icon: <BiPaste />,
              },
            ],
          },
        ],
      },
    ],
  },
  client_MENU: [
    {
      id: 1,
      title: "",
      submenu: [
        {
          id: 1,
          className: "waves-effect",
          to: "/",
          lable: "Dashboard",
          icon: "bx bxs-report",
        },

        {
          id: 2,
          className: "has-arrow waves-effect",
          lable: "Shipments",
          to: "/tracking",
          icon: "bx bxs-map",
          submenu: [
            {
              id: 1,
              to: "/tracking",
              lable: "View transit",
            },
            {
              id: 1,
              to: "/shipments",
              lable: "View all",
            },
          ],
        },
        {
          id: 4,
          to: "/packaging",
          lable: "Packaging",
          icon: "bx bxs-inbox",
        },
        {
          id: 3,
          className: "waves-effect",
          to: "/sites",
          lable: "Sites",
          icon: "bx bxs-factory",
        },
        {
          id: 3,
          to: "/routeInfo",
          lable: "Routes",
          icon: "bx bxs-paste",
        },
        {
          id: 5,
          className: "waves-effect",
          to: "/roleaccess",
          lable: "Roles and access",
          icon: "bx bxs-group",
        },
        {
          id: 6,
          className: "has-arrow waves-effect",
          lable: "Emissions",
          to: "/emissions",
          icon: "bx bxs-map",
          submenu: [
            {
              id: 1,
              to: "/emission-dashboard",
              lable: "Dashboard",
            },
            {
              id: 2,
              to: "/add-entry",
              lable: "Add new entry",
            },
            // {
            //   id: 3,
            //   to: "/generate-report",
            //   lable: "Generate Reports",
            // },
            // {
            //   id: 4,
            //   to: "/download-report",
            //   lable: "Download data",
            // },
          ],
        },
        {
          id: 7,
          className: "has-arrow waves-effect",
          lable: "Networking",
          to: "/networking",
          icon: "bx bxs-map",
          submenu: [
            {
              id: 1,
              to: "/onboardPartner",
              lable: "Onboard partner",
            },
          ],
        },
      ],
    },
  ],

  siteViewer_MENU: [
    {
      id: 1,
      title: "",
      submenu: [
        {
          id: 1,
          className: "waves-effect",
          to: "/",
          lable: "Dashboard",
          icon: "bx bxs-report",
        },

        {
          id: 2,
          className: "has-arrow waves-effect",
          lable: "Shipments",
          to: "/tracking",
          icon: "bx bxs-map",
          submenu: [
            {
              id: 1,
              to: "/tracking",
              lable: "In transit",
            },
            {
              id: 1,
              to: "/shipments",
              lable: "Delivered",
            },
          ],
        },
        {
          id: 4,
          to: "/site-packaging",
          lable: "Packaging",
          icon: "bx bxs-inbox",
        },

        {
          id: 3,
          to: "/routeInfo",
          lable: "Routes",
          icon: "bx bxs-paste",
        },
      ],
    },
  ],
  DATA_TABLE_COLUME: {
    Transporter_emmited: [
      {
        label: "Name",
        field: "name",
        sort: "asc",
      },
      {
        label: "Shipments",
        field: "shipment",
        sort: "asc",
      },
      {
        label: (
          <>
            Carbon emission <br />
            (tCO<sub>2</sub>e)
          </>
        ),
        field: "per",
        sort: "asc",
      },
    ],

    Site_emmited: [
      {
        label: "Site name",
        field: "name",
        sort: "asc",
      },
      {
        label: "Shipments",
        field: "shipment",
        sort: "asc",
      },
      {
        label: (
          <>
            Shipment <br />
            (tCO<sub>2</sub>e)
          </>
        ),
        // label: "Shipment CarbonEmission",
        field: "shipmentCarbonEmission",
        sort: "asc",
      },
      {
        label: (
          <>
            Scope 1 <br />
            (tCO<sub>2</sub>e)
          </>
        ),
        // label: "Scope 1",
        field: "Scope_1",
        sort: "asc",
      },
      {
        label: (
          <>
            Scope 2 <br />
            (tCO<sub>2</sub>e)
          </>
        ),
        // label: "Scope 2",
        field: "Scope_2",
        sort: "asc",
      },
      {
        label: (
          <>
            Scope 3 <br />
            (tCO<sub>2</sub>e)
          </>
        ),
        // label: "Scope 3",
        field: "Scope_3",
        sort: "asc",
      },
      {
        label: (
          <>
            Total <br />
            (tCO<sub>2</sub>e)
          </>
        ),
        field: "per",
        sort: "asc",
      },
      // {
      //   label: (
      //     <>
      //       Carbon Efficiency
      //       <br />
      //       (tCO<sub>2</sub>e/Freight Tonne-km)
      //     </>
      //   ),
      //   field: "eff",
      //   sort: "asc",
      // },
    ],
    Scope3_emmited: [
      {
        label: "Site name",
        field: "name",
        sort: "asc",
      },
      // {
      //   label: "Upstream Transportation",
      //   field: "Upstream_transportation",
      //   sort: "asc",
      // },
      // {
      //   label: "Downstream Transportation",
      //   field: "Downstream_transportation",
      //   sort: "asc",
      // },
      {
        label: "Waste management",
        field: "Waste_Management",
        sort: "asc",
      },
      {
        label: "Buisness travel",
        field: "Business_Travel",
        sort: "asc",
      },
      {
        label: "Employee commute",
        field: "Employee_Commute",
        sort: "asc",
      },
      {
        label: "Transportation",
        field: "Transportation",
        sort: "asc",
      },
      {
        label: "Purchased goods & services",
        field: "Purchased_Goods_and_Services",
        sort: "asc",
      },
      {
        label: "Capital goods",
        field: "Capital_Goods",
        sort: "asc",
      },
      {
        label: (
          <>
            Scope 3 <br />
            (tCO<sub>2</sub>e)
          </>
        ),
        // label: "Scope 3",
        field: "Scope_3",
        sort: "asc",
      },
    ],
    Scope2_emmited: [
      {
        label: "Site name",
        field: "siteName",
        sort: "asc",
      },
      {
        label: "Electricity (in MWh)",
        field: "totalElectricity",
        sort: "asc",
      },
      {
        label: "Renewable energy (in MWh)",
        field: "renewableSource",
        sort: "asc",
      },
      {
        label: "Non-renewable energy (in MWh)",
        field: "gridSource",
        sort: "asc",
      },
      {
        label: (
          <>
            Scope 2 <br />
            (tCO<sub>2</sub>e)
          </>
        ),
        // label: "Scope 3",
        field: "totalCarbonEmission",
        sort: "asc",
      },
    ],
    Scope1_emmited: [
      {
        label: "Site name",
        field: "name",
        sort: "asc",
      },
      {
        label: "Purchased gases",
        field: "Purchased_Gases",
        sort: "asc",
      },
      {
        label: "Waste management",
        field: "Waste_Management",
        sort: "asc",
      },
      {
        label: "Stationary combustion",
        field: "Stationary_Combustion",
        sort: "asc",
      },
      {
        label: "Upstream transportation",
        field: "Upstream_transportation",
        sort: "asc",
      },
      {
        label: "Downstream transportation",
        field: "Downstream_transportation",
        sort: "asc",
      },
      {
        label: (
          <>
            Scope 1 <br />
            (tCO<sub>2</sub>e)
          </>
        ),
        // label: "Scope 3",
        field: "Scope_1",
        sort: "asc",
      },
    ],
    admin: [
      {
        label: "No",
        field: "no",
        sort: "asc",
      },
      {
        label: "Email ID",
        field: "email",
        sort: "asc",
      },
      {
        label: (
          <p style={{ margin: "0px", width: "100px", textAlign: "center" }}>
            Action
          </p>
        ),
        field: "action",
        sort: "disabled",
      },
    ],
    team: [
      {
        label: "Team id",
        field: "no",
        sort: "asc",
      },

      {
        label: <p style={{ margin: "0px", width: "150px" }}>Name</p>,
        field: "name",
        sort: "asc",
      },
      {
        label: <p style={{ margin: "0px", width: "150px" }}>Email id</p>,
        field: "email",
        sort: "asc",
      },
      {
        label: <p style={{ margin: "0px", width: "150px" }}>Mobile number</p>,
        field: "mobile",
        sort: "asc",
      },
      {
        label: <p style={{ margin: "0px", width: "150px" }}>Role</p>,
        field: "roleColomn",
        sort: "asc",
      },
      // {
      //   label: <p style={{ margin: "0px", width: "150px" }}>Site</p>,
      //   field: "siteslt",
      //   sort: "asc",
      // },
      {
        label: <p style={{ margin: "0px", width: "150px" }}>Customer</p>,
        field: "client",
        sort: "asc",
      },
      // {
      //   label: (
      //     <p style={{ margin: "0px", width: "70px", textAlign: "center" }}>
      //       Reset Password
      //     </p>
      //   ),
      //   field: "reset",
      //   sort: "disabled",
      // },
      {
        label: (
          <p style={{ margin: "0px", width: "100px", textAlign: "center" }}>
            Action
          </p>
        ),
        field: "action",
        sort: "disabled",
      },
    ],
    admin_Package_Assignment: [
      // {
      //   label: "Id",
      //   field: "no",
      //   sort: "asc",
      // },
      {
        label: "Package sub type",
        field: "pacakeSubType",
        sort: "asc",
      },
      {
        // Transaction type ( Assign | incoming | outgoing )
        label: "Transaction type",
        field: "type",
        sort: "asc",
      },
      {
        label: "Count ",
        field: "count",
        sort: "asc",
      },
      {
        label: "Date of transaction",
        field: "createdAt",
        sort: "asc",
      },
    ],
    admin_Attribute: [
      {
        label: "Attribute id",
        field: "no",
        sort: "asc",
      },
      {
        label: "Name",
        field: "name",
        sort: "disabled",
      },

      {
        label: (
          <p style={{ margin: "0px", width: "90px", textAlign: "center" }}>
            Action
          </p>
        ),
        field: "action",
        sort: "disabled",
      },
    ],
    admin_Category: [
      {
        label: "Category id",
        field: "no",
        sort: "asc",
      },
      {
        label: "Name",
        field: "name",
        sort: "disabled",
      },
      {
        label: "Scope",
        field: "scope",
        sort: "disabled",
      },
      {
        label: (
          <p style={{ margin: "0px", width: "90px", textAlign: "center" }}>
            Action
          </p>
        ),
        field: "action",
        sort: "disabled",
      },
    ],
    jobTable: [
      {
        label: "Job id",
        field: "no",
        sort: "asc",
      },

      {
        label: <p style={{ margin: "0px", width: "90px" }}>Uploaded file</p>,
        field: "uploadFile",
        sort: "asc",
      },
      {
        label: (
          <p style={{ margin: "0px", width: "120px" }}>Uploaded records</p>
        ),
        field: "uploadedRecord",
        sort: "asc",
      },
      {
        label: <p style={{ margin: "0px", width: "110px" }}>Success records</p>,
        field: "successRecord",
        sort: "asc",
      },
      {
        label: <p style={{ margin: "0px", width: "100px" }}>Failed records</p>,
        field: "failedRecord",
        sort: "asc",
      },
      {
        label: <p style={{ margin: "0px", width: "90px" }}>Response file</p>,
        field: "responseFile",
        sort: "asc",
      },
      {
        label: <p style={{ margin: "0px", width: "70px" }}>View file</p>,
        field: "viewFile",
        sort: "asc",
      },
    ],
  },

  FORM_FIELDS: {
    PLANT: [unitName, location, GST],
    DRIVER: [mobile, dob, drivingLicense],
    TEAM: [name, email, mobile, customerPassword],
    ROLETEAM: [roleWise, name, email, mobile, customerPassword],
    PROLETEAM: [
      roleWise,
      plantForRoleId,
      clientForRoleId,
      name,
      email,
      mobile,
      // customerPassword,
    ],
    CROLETEAM: [
      plantId,
      name,
      email,
      mobile,
      // customerPassword
    ],

    SROLETEAM: [roleWise, clientId, name, email, mobile, customerPassword],
    RESETPASSWORD: [customerPassword],

    CATEGORY: [
      scopeForCategory,
      name,
      color,
      sampleFile,
      csvName,
      formModalName,
      // description,
      // postmanLink,
    ],
    CATEGORYSETTING: [
      scopeForCategory,
      name,
      color,
      sampleFile,
      csvName,
      // formModalName,
      // description,
      // postmanLink,
    ],
    ATTRIBUTE: [name],

    ROUTE: [
      clientId,
      routePlantId,
      routeDestinationPlantId,
      {
        name: "routeType",
        label: "Route type",
        placeholder: "Enter Route type",
        type: "SingleSelect",
        required: true,
        options: [
          { label: "Forward", value: "Forward" },
          { label: "Reverse", value: "Reverse" },
        ],
        isShow: () => true,
      },
      {
        name: "vehicleType",
        label: "Vehicle type",
        placeholder: "Vehicle type",
        type: "SingleSelect",
        required: true,
        options: [
          { label: "32FT MXL", value: "32FT MXL" },
          { label: "32FT SAL", value: "32FT SAL" },
          { label: "22FT OPEN", value: "22FT OPEN" },
          { label: "20FT SAL", value: "20FT SAL" },
          { label: "20FT SAL/32FT SAL", value: "20FT SAL/32FT SAL" },
        ],
        isShow: () => true,
      },

      vehicleCapacity,
      {
        name: "ownerShipType",
        label: "Ownership type",
        placeholder: "Ownership type",
        type: "SingleSelect",
        required: true,
        options: [
          { label: "Customer-Owned", value: "Customer-Owned" },
          { label: "Fitsol", value: "Fitsol" },
        ],
        isShow: () => true,
      },
      expectedCompletionTimeInHR,
      freightWeightInTon,
    ],
    PACKAGE: [
      packageType,
      packageSubType,
      DimensionInmm,
      material,
      ActualWeight,
    ],
    PACKAGEASSIGNMENT: [
      routeName,
      packageId,
      countForPackage,
      // {
      //   id: "count",
      //   name: "count",
      //   label: "Count",
      //   placeholder: "Enter Count",
      //   type: "number",
      //   required: true,
      // },
    ],
    CUSTOMER: [
      clientName,
      insuranceNumber,
      // , tripEndDistance
    ],

    CLIENTFORFORM: [
      ConsignorName,
      POCName,
      ConsignorEmail,
      customerMobile,
      insuranceNumber,
    ],
    SITE: [clientId, actualAddress, unitName, gstNumber],
    CSITE: [actualAddress, unitName, gstNumber],
    admin_PROFILE: [
      name,
      {
        ...email,
        disabled: true,
        isShow: () => true,
      },
      profilePic,
    ],
    client_PROFILE: [
      { ...name, disabled: true },
      {
        ...email,
        disabled: true,
        isShow: () => true,
      },
      {
        ...notificationType,
        required: false,
        options: [
          { label: "Activity (Trip End)", value: "activity" },
          { label: "Daily", value: "daily" },
          { label: "Weekly", value: "weekly" },
          { label: "Monthly", value: "monthly" },
        ],
        isShow: () => true,
      },
    ],
    COMPANY_DETAILS:[
      companyName,
      insuranceNumber,
      gstIn,
      cinNumber,
      yearOfIncorporation,
      ciiMemberShipNo,
      turnover,
      yearOfTurnover,
      registeredAddress1,
      registeredAddress2,
      registeredDistrict,
      registeredState,
      registeredPincode,
      corporateAddress1,
      corporateAddress2,
      corporateDistrict,
      corporateState,
      corporatePincode,
    ],
    clientIntensityMetricsFY: [
      {
        ...financialYear,
        required: true,
        options: getLatestEightFinancialYears(15),
      },
      totalRevenue,
      clientSKU,
    ],
    clientIntensityMetricsCalendarYear: [
      {
        ...calendarYear,
        required: true,
        options: getLatestEightCalendarYears(15),
      },
      totalRevenue,
      clientSKU,
    ],
    siteViewer_PROFILE: [
      { ...unitName, disabled: true },
      {
        ...email,
        disabled: true,
        isShow: () => true,
      },
      {
        ...notificationType,
        required: false,
        options: [
          { label: "Activity (Trip End)", value: "activity" },
          { label: "Daily", value: "daily" },
          { label: "Weekly", value: "weekly" },
          { label: "Monthly", value: "monthly" },
        ],
        isShow: () => true,
      },
      // POCName,
      // mobile,
      // {
      //   ...email,
      //   disabled: true,
      //   isShow: () => true,
      // },
      // insuranceNumber,
      // profilePic,
    ],
    tripCreator_PROFILE: [
      { ...name, disabled: true },
      // POCName,
      mobile,
      {
        ...email,
        disabled: false,
        isShow: () => true,
      },
      // insuranceNumber,
      profilePic,
    ],
    spoc_PROFILE: [
      { ...name, disabled: true },
      { ...mobile, disabled: true },
      { ...brsrSpocEmail, disabled: true },
      // { ...brsrSpocDepartment, disabled: true },
    ],
    SETTINGS: [distance],
    ADDSINGLEEMISSIONENTRY: [
      // categorySelect,
      // scope,
      // shipmentDate,
      // transportationSource,
      // transportationDestination,
      // vehicleNumber,
      // partNumber,
      // partCount,
      // freightWeight,
      // shipmentId,
      // shipperName,
    ],

    ADDCATEGORYFORM: [
      categoryAttributeForm,
      definationType,
      categoryAttributeDataType,
      categoryAttributeIsrequiared,
    ],

    TRANSPORTER: [gstNumber],
    VEHICLES: [transporterId, vehicleCategory, VehicleNumber],
    VEHICLESShipmentForm: [vehicleCategory, VehicleNumber],
    TRIP: [
      {
        name: "clientId",
        id: "clientId",
        label: "Consignor",
        placeholder: "Select consignor",
        type: "SingleSelect",
        isShow: () => true,
      },
      {
        name: "consignorLocation",
        id: "consignorLocation",
        label: " Consignor site",
        placeholder: "Select or create consignor location ",
        type: "SingleSelect",
        required: true,
        isShow: () => true,
      },
      {
        name: "Consignee",
        id: "Consignee",
        label: "Route",
        placeholder: "Select or create route ",
        type: "SingleSelect",
        isShow: () => true,
      },
      {
        name: "startDateAndTime",
        id: "startDateAndTime",
        label: "PickUp date & time",
        placeholder: "PickUp date & time",
        type: "text",
        disabled: true,
        required: true,
        isShow: () => true,
      },
      {
        name: "transporterId",
        id: "transporterId",
        label: "Transporter",
        placeholder: "transporter id",
        type: "SingleSelectWithCreate",

        required: true,
        isShow: () => true,
      },
      {
        name: "vehicleId",
        id: "vehicleId",
        label: "Vehicle number",
        placeholder: "Vehicle number",
        type: "SingleSelect",
        isShow: () => true,
      },
      {
        name: "driverId",
        id: "driverId",
        label: "Driver name",
        placeholder: "Driver name",
        type: "SingleSelect",
        isShow: () => true,
      },
      {
        name: "reusable",
        id: "reusable",
        label: "Select reusable",
        placeholder: "Select reusable",
        type: "multipleSelectWithCheckBox",
        isShow: () => true,
      },
      {
        name: "nonReusable",
        id: "nonReusable",
        label: "Select non-reusable",
        placeholder: "Select non-reusable",
        type: "multipleSelectWithCheckBox",
        isShow: () => true,
      },

      {
        name: "invoiceNumber",
        id: "invoiceNumber",
        label: "Invoice number",
        placeholder: "Invoice number",
        type: "text",
        isShow: () => true,
        disabled: false,
      },
      {
        name: "InvoiceValue",
        id: "InvoiceValue",
        label: "Invoice value",
        placeholder: "Invoice value",
        type: "text",
        disabled: false,
        isShow: () => true,
      },
      {
        name: "eWayBillNumber",
        id: "eWayBillNumber",
        label: "E-way bill number",
        placeholder: "E-way bill number",
        type: "text",
        disabled: false,
        isShow: () => true,
      },

      // {
      //   name: "startDateAndTime",
      //   label: (
      //     <p style={{ width: "105px", margin: "0px" }}>Start Date & Time</p>
      //   ),
      //   placeholder: "Start Date",
      //   type: "selectDate&Time",
      //   required: false,
      //   isShow: () => true,
      // },
      // {
      //   name: "targetedDateAndTime",
      //   label: "Expected ETA",
      //   placeholder: "Expected ETA",
      //   type: "selectDate&Time",
      //   required: false,
      //   isShow: () => true,
      // },
      // {
      //   name: "actualeta",
      //   label: "Actual ETA",
      //   placeholder: "Actual ETA",
      //   type: "selectDate&Time",
      //   required: false,
      // },
      // {
      //   name: "weight",
      //   label: "Weight (in Tonnes)",
      //   placeholder: "Weight (in Tonnes)",
      //   type: "text",
      //   required: false,
      //   isShow: () => true,
      // },
      // {
      //   name: "status",
      //   label: "Status",
      //   placeholder: "Status",
      //   type: "SingleSelect",
      //   required: false,
      //   options: [
      //     { label: "Pending", value: "1" },
      //     { label: "On Going", value: "2" },
      //   ],
      // },
      // {
      //   name: "type",
      //   label: "Tracking Type",
      //   placeholder: "Tracking Type",
      //   type: "SingleSelect",
      //   required: true,
      //   options: Object.keys(constTrackingOption).map((item) => {
      //     return { label: constTrackingOption[item], value: item };
      //   }),
      //   isShow: () => true,
      // },
      // {
      //   name: "distanceOfTrip",
      //   label: "Distance Of Shipment",
      //   placeholder: "Distance Of Shipment",
      //   type: "text",
      //   required: false,
      //   isShow: () => true,
      // },

      // {
      //   name: "reusable",
      //   label: "Select Reusable",
      //   placeholder: "Select Reusable",
      //   type: "multipleSelectWithCheckBox",
      //   isShow: () => true,
      // },
      // {
      //   name: "nonReusable",
      //   label: "Select Non-Reusable",
      //   placeholder: "Select Non-Reusable",
      //   type: "multipleSelectWithCheckBox",
      //   isShow: () => true,
      // },

      // {
      //   name: "fleetCharges",
      //   label: "Fleet Charges",
      //   placeholder: "Fleet Charges",
      //   type: "number",
      // },
      // {
      //   name: "gstCharges",
      //   label: "GST Charges",
      //   placeholder: "GST Charges",
      //   type: "number",
      // },
      // {
      //   name: "invoiceNumber",
      //   label: "Invoice Number",
      //   placeholder: "Invoice Number",
      //   type: "text",
      //   isShow: () => true,
      // },
      // {
      //   name: "ptype",
      //   label: "Package Type",
      //   placeholder: "Package Type",
      //   type: "SingleSelect",
      //   isShow: () => true,
      // },
      // {
      //   name: "subtype",
      //   label: "Package Sub Type",
      //   placeholder: "Package Sub Type",
      //   type: "SingleSelect",
      //   isShow: () => true,
      //   // options: packageSubtype[packageType],
      // },
      // {
      //   name: "volumetricWeight",
      //   label: "Volumetric Weight",
      //   placeholder: "Volumetric Weight",
      //   type: "number",
      //   required: false,
      // },
      // {
      //   name: "basicFreightAmount",
      //   label: "BasicFreight Amount",
      //   placeholder: "BasicFreight Amount",
      //   type: "number",
      //   required: false,
      // },
      // {
      //   name: "FuelSurcharges",
      //   label: "Fuel Charges",
      //   placeholder: "Fuel Charges",
      //   type: "number",
      //   required: false,
      // },
      // {
      //   name: "docetCharges",
      //   label: "Docket Charges",
      //   placeholder: "Docket Charges",
      //   type: "number",
      //   required: false,
      // },
      // {
      //   name: "FOV",
      //   label: "FOV",
      //   placeholder: "FOV",
      //   type: "number",
      //   required: false,
      // },

      // {
      //   name: "loadingUnloadingCharges",
      //   label: "Loading/Unloading Charges",
      //   placeholder: "Loading/Unloading Charges",
      //   type: "number",
      //   required: false,
      // },
      // {
      //   name: "collectionCharges",
      //   label: "Collection Charges",
      //   placeholder: "Collection Charges",
      //   type: "number",
      //   required: false,
      // },
      // {
      //   name: "delieveryCharges",
      //   label: "Delivery Charges",
      //   placeholder: "Delivery Charges",
      //   type: "number",
      //   required: false,
      // },
      // {
      //   name: "codDodCharges",
      //   label: "Cod Charges",
      //   placeholder: "Cod Charges",
      //   type: "number",
      //   required: false,
      // },
      // {
      //   name: "toPay",
      //   label: "ToPay",
      //   placeholder: "ToPay",
      //   type: "number",
      //   required: false,
      // },
      // {
      //   name: "otherCharges",
      //   label: "Other Charges",
      //   placeholder: "Other Charges",
      //   type: "number",
      //   required: false,
      // },
      // {
      //   name: "subTotal",
      //   label: "SubTotal",
      //   placeholder: "SubTotal",
      //   type: "number",
      //   required: false,
      // },
      // {
      //   name: "IGST",
      //   label: "IGST",
      //   placeholder: "IGST",
      //   type: "number",
      //   required: false,
      // },
      // {
      //   name: "CGST",
      //   label: "CGST",
      //   placeholder: "CGST",
      //   type: "number",
      //   required: false,
      // },
      // {
      //   name: "SGST",
      //   label: "SGST",
      //   placeholder: "SGST",
      //   type: "number",
      //   required: false,
      // },
      // {
      //   name: "total",
      //   label: "Total",
      //   placeholder: "Total",
      //   type: "number",
      //   required: false,
      // },
    ],
    TRIP_STATUS: [
      {
        name: "status",
        label: "Status",
        placeholder: "Status",
        type: "SingleSelect",
        required: false,
        options: [
          { label: "Pending", value: "1" },
          { label: "On Going", value: "2" },
          { label: "Completed", value: "3" },
        ],
        isShow: () => true,
      },
      // {
      //   name: "completedDateAndTime",
      //   label: "Completed Date & Time",
      //   placeholder: "Completed Date & Time",
      //   type: "selectDate&Time",
      //   required: false,
      // },
      // {
      //   name: "fuelUserd",
      //   label: "Fuel Used",
      //   placeholder: "Fuel Used",
      //   type: "text",
      //   required: false,
      // },
    ],
    END_TRIP: [
      {
        name: "fuelUserd",
        label: "Fuel used",
        placeholder: "Fuel used",
        type: "text",
        required: false,
        isShow: () => true,
      },
    ],
    BRSR_SPOC: [brsrSpocId, brsrSpocEmail, brsrSpocPhone, brsrSpocDepartment],
    BRSR_FORMS: [deadLine],
    CONNECTION_UPDATE: [status],
    // sectionId, deadLine
    BRSR_SPOC_FORM: [department, getAllSPOCS],
  },
};

// if (
//   Object.entries(localStorage)?.find((el) => el?.[0] == "client")
//   // localStorage.getItem("ViewRole")
// ) {
//   CONSTANT.MENU["client"] = CONSTANT.MENU.client?.map((el) => {
//     if (el?.to) {
//       el["to"] = `/clientView/${viewRole}${el?.to}`;
//     }

//     if (el?.submenu) {
//       el?.submenu?.map((ell) => {
//         if (ell?.to) {
//           ell["to"] = `/clientView/${viewRole}${ell?.to}`;
//         }
//         if (ell?.submenu) {
//           ell?.submenu?.map((elll) => {
//             if (elll?.to) {
//               elll["to"] = `/clientView/${viewRole}${elll?.to}`;
//             }
//           });
//         }
//       });
//     }
//     return el;
//   });
// }

// if (Object.entries(localStorage)?.find((el) => el?.[0] == "siteViewer")) {
//   CONSTANT.MENU["siteViewer"] = CONSTANT.MENU.siteViewer?.map((el) => {
//     if (el?.to) {
//       el["to"] = `/siteView/${viewRole}${el?.to}`;
//     }

//     if (el?.submenu) {
//       el?.submenu?.map((ell) => {
//         if (ell?.to) {
//           ell["to"] = `/siteView/${viewRole}${ell?.to}`;
//         }
//         if (ell?.submenu) {
//           ell?.submenu?.map((elll) => {
//             if (elll?.to) {
//               elll["to"] = `/siteView/${viewRole}${elll?.to}`;
//             }
//           });
//         }
//       });
//     }
//     return el;
//   });
// }

export const TABLE_HEADER = {
  product: [
    "no",
    "productName",
    // "tags",
    "statusData",
    "dataModify",
    "productCategory",
    "productWeight",
    "carbonFootprint",
    "view",
    "action",
  ],
  customer: [
    "no",
    "clientName",
    "insuranceNumber",
    "tripEndDistance",
    "viewRole",
    "view",
    "action",
  ],
  site: [
    "no",
    "unitName",
    "clientName",
    "pradr",
    "GST",
    "viewRole",
    "view",
    "access",
    "link",
    "action",
  ],
  route: [
    "no",
    "clientName",
    "unitName",
    "displayName",
    "routeType",
    "vehicleType",
    "vehicleCapacity",
    "ownerShipType",
    "expectedtime",
    "freightWeight",
    "action",
  ],
  packageType: [
    "no",
    "type",
    "SubType",
    "DimensionInmm",
    "DropdownName",
    "VolumeCBM",
    "VolumetricWeightInKG",
    "ActualWeight",
    "material",
    "co2EmissionPerKG",
    "lifeTimetCO2e",
    "lifeTimeTrips",
    "tCO2PerShipment",
    "action",
  ],
  transporter: ["no", "TransporterName", "gstNumber", "view", "action"],
  driver: ["no", "driverName", "dl_no", "mobile", "consent", "action"],
  vehicles: [
    "no",
    "RegistrationNumbers",
    "manufacture",
    "fuelType",
    "transporterName",
    "co2PerKm",
    "mileage",
    "action",
  ],
  shipment: [
    "no",
    "pod",
    "mapView",
    "billDownload",
    "statusData",
    "clientName",
    "transporterName",
    "plantName",
    "startDateAndTime",
    "sourceLocation",
    "destinationLocation",
    "driverName",
    "driverPhoneNumber",
    "vehicleNumber",
    "targetedDateAndTime",
    "actualeta",
    "carbonEmission",
    "packageEmission",
    "totalGen",
    "utilisation",
    "invoiceNumber",
    "packageType",
    "action",
  ],
  team: [
    "no",
    "name",
    "email",
    "mobile",
    "roleColomn",
    "client",
    // "reset",
    "action",
  ],
  jobTable: [
    "no",
    "uploadFile",
    "category",
    "uploadedRecord",
    "successRecord",
    "failedRecord",
    "createdDate",
    // "responseFile",
    "view",
  ],
  connectionsJobTable:[
    "no",
    "uploadFile",
    "uploadedRecord",
    "successRecord",
    "failedRecord",
    "createdDate",
    "view",
  ],
  jobEntriesTable: [
    "no",
    "status",
    "error"
  ],
  admin_Category: [
    "CategoryId",
    "name",
    "color",
    "sampleFile",
    "categoryFormModalName",
    "csvName",
    "isVisible",
    // "description",
    // "postmanLink",
    "scope",
    "action",
  ],
  admin_Category_setting: [
    "CategoryId",
    "name",
    "color",
    "sampleFile",
    // "categoryFormModalName",
    "csvName",
    "isVisible",
    // "description",
    // "postmanLink",
    "scope",
    "action",
  ],
  admin_Attribute: ["attributeId", "name", "action"],
  brsrSpoc: ["no", "name", "email", "phone", "department", "action"],
  brsrForm: [
    "sectionName",
    "totalFields",
    "filledFields",
    "score",
    "maxScore",
    "deadline",
    "progressBar",
    "spocs",
    "editForm",
    "updateBRSRForm",
  ],
  spocForm: ["no", "SPOCname", "SPOCemail", "SPOCphone", "SPOCdepartment"],
  spocBrsrForm: [
    "sectionName",
    "totalFields",
    "filledFields",
    "deadline",
    "editForm",
  ],
  globalSections: [
    "sectionName",
    "totalQuestion",
    "answeredQuestion",
    "score",
    "maxScore",
    "deadline",
    "progressBar",
    "spocs",
    "editForm",
    // "updateBRSRForm",
  ],
  connectionSent: [
    "no",
    "connectionTo",
    "status",
    "gstIn",
    "pocName",
    "pocMobile",
    "pocMail",
    "categoryServiceProvider",
    "action",
  ],
  connectionReceived: [
    "no",
    "connectionFrom",
    "status",
    "pocName",
    "pocMobile",
    "pocMail",
    "categoryServiceProvider",
    "updateStatus",
    "deleteConnection",
  ],
  newUserData:[
    "no",
    "name",
    "email",
    "mobile",
    "action",
  ],

  spocGlobalSections: [
    "sectionName",
    "totalQuestion",
    "answeredQuestion",
    "deadline",
    "progressBar",
    "editForm",
  ],
};
export const PCFTabsList = [
  {
    key: "1",
    label: "1. Product",
    children:
      "Start by filling in the general data about the product and give the product a unique name.",
  },
  {
    key: "2",
    label: "2. Material Acquisition And Processing",
    children:
      "Specify each individual part consisting of a unique material and all relevant processes. The total combined weight of the individual parts must match the total product weight entered at the start of this calculation.",
  },
  {
    key: "3",
    label: "3. Packaging",
    children:
      "If the product has packaging, specify each unique packaging material. The packaging weight is added to the product weight.",
  },
  {
    key: "4",
    label: "4. Product Assembly And Distribution",
    children:
      "The total energy consumed is generated from previous inserted data for parts and packaging (based on weight of material, product category and energy source selected) but can be changed manually.",
  },
  {
    key: "5",
    label: "5. Result",
    children:
      "The calculations are completed based on the input you have give. To have correct result make sure you have given all necessary data.",
  },
];

export const nameConsts = {
  ELECTRICITY_TYPE: "Electricity Type",
  ELECTRICITY_USAGE: "Enter Electricity Usage ( in MWh )",
  CARBON_EMISSION_TCO2: "Carbon Emission (tCO2e)",
  SITE_ID: "Site Id",
};

export const scopeWiseEmissionEquivalent = (totalEmission) => {
  if (!totalEmission) {
    return [];
  }
  const emission = parseInt(totalEmission.replace(/,/g, ""), 10);
  if (isNaN(emission)) {
    return [];
  }
  const deforestedTrees = Math.round(emission * 40).toLocaleString("en-IN");
  const petrolBurnt = Math.round(emission * 6525).toLocaleString("en-IN");
  const coalBurnt = Math.round(emission * 0.515).toLocaleString("en-IN");

  return [
    {
      heading: "Equivalent to",
      count: deforestedTrees,
      units: "trees deforested",
    },
    {
      heading: "Equivalent to",
      count: petrolBurnt,
      units: "km travelled by petrol car",
    },
    {
      heading: "Equivalent to",
      count: coalBurnt,
      units: "tonnes coal burnt",
    },
  ];
};
export const pcfProductDetailTableFields = [
  {
    id: "name",
    name: "name",
    label: "Product name",
    placeholder: "Enter product name",
    type: "text",
    width: "10%",
    required: true,
    xl: 5,
  },
  {
    id: "weight",
    name: "weight",
    label: "Total product weight",
    placeholder: "Enter total product weight",
    type: "number",
    width: "10%",
    required: true,
    xl: 5,
  },
  {
    id: "productCategoryId",
    name: "productCategoryId",
    key: "productCategoryId",
    label: "Product category",
    placeholder: "Enter product category",
    type: "select",
    // option: [
    // ],
    width: "10%",
    xl: 5,
  },
  {
    id: "productSubCategoryId",
    name: "productSubCategoryId",
    label: "Product type",
    placeholder: "Enter product type",
    type: "select",
    width: "10%",
    xl: 4,
  },
  {
    id: "productCode",
    key: "productCode",
    label: "Product code (EAN/UPC)",
    placeholder: "Enter product code",
    type: "text",
    width: "10%",
    xl: 5,
  },
];
export const pcfMaterialPartDetailTableFields = [
  {
    id: "partName",
    name: "partName",
    label: "Part name",
    placeholder: "Enter part name",
    type: "text",
    width: "10%",
    // xl: 3,
  },
  {
    id: "materialCategory",
    name: "materialCategory",
    label: "Material category",
    placeholder: "Enter material category",
    type: "select",
    // dynamic
    option: [
      { label: "appp", value: "app" },
      { label: "fdgfg", value: "fdgfg" },
      { label: "sdsdsd", value: "sdsdsd" },
    ],
    width: "10%",
    // xl: 3,
  },
  {
    id: "subCategory",
    name: "subCategory",
    label: "Sub category",
    placeholder: "Enter sub category",
    type: "select",
    // dynamic
    option: [
      { label: "appp", value: "app" },
      { label: "fdgfg", value: "fdgfg" },
      { label: "sdsdsd", value: "sdsdsd" },
    ],
    width: "10%",
    // xl: 3,
  },
  {
    id: "quality",
    name: "quality",
    label: "Quality",
    placeholder: "Enter quality",
    type: "select",
    // dynamic
    option: [
      { label: "appp", value: "app" },
      { label: "fdgfg", value: "fdgfg" },
      { label: "sdsdsd", value: "sdsdsd" },
    ],
    width: "10%",
    // xl: 3,
  },
  {
    id: "weight",
    name: "weight",
    label: "Weight",
    placeholder: "Enter weight",
    type: "text",
    width: "10%",
    // xl: 3,
    suffix: "grams",
  },
  {
    id: "supplierName",
    name: "supplierName",
    label: "Supplier name",
    placeholder: "Enter supplier name",
    type: "text",
    width: "10%",
    // xl: 3,
  },
  {
    id: "supplierLocation",
    name: "supplierLocation",
    label: "Supplier location",
    placeholder: "Enter supplier location",
    type: "select",
    // gmaps select option dynamic
    option: [
      { label: "appp", value: "app" },
      { label: "fdgfg", value: "fdgfg" },
      { label: "sdsdsd", value: "sdsdsd" },
    ],
    width: "10%",
    // xl: 3,
  },
  {
    id: "transportMode",
    name: "transportMode",
    label: "Transport mode",
    placeholder: "Enter transport mode",
    type: "select",
    // static
    option: [
      { label: "appp", value: "app" },
      { label: "fdgfg", value: "fdgfg" },
      { label: "sdsdsd", value: "sdsdsd" },
    ],
    width: "10%",
    // xl: 3,
  },
];

export const pcfProcessDetailTableFields = [
  {
    id: "processes",
    name: "processes",
    label: "Processes",
    placeholder: "Enter processes",
    type: "text",
    fieldShow: true,
    width: "10%",
    xl: 3,
  },
  {
    id: "processCategory",
    name: "processCategory",
    label: "Process category",
    placeholder: "Enter process category",
    type: "select",
    // /dynamic values
    option: [
      { label: "appp", value: "app" },
      { label: "fdgfg", value: "fdgfg" },
      { label: "sdsdsd", value: "sdsdsd" },
    ],
    width: "10%",
    xl: 3,
  },
  {
    id: "process",
    name: "process",
    label: "Process",
    placeholder: "Enter process",
    type: "select",
    // /dynamic values
    option: [
      { label: "appp", value: "app" },
      { label: "fdgfg", value: "fdgfg" },
      { label: "sdsdsd", value: "sdsdsd" },
    ],
    width: "10%",
    xl: 3,
  },
  {
    id: "energySource",
    name: "energySource",
    label: "Energy Source",
    placeholder: "Enter energy source",
    type: "select",
    // /dynamic values
    option: [
      { label: "appp", value: "app" },
      { label: "fdgfg", value: "fdgfg" },
      { label: "sdsdsd", value: "sdsdsd" },
    ],
    width: "10%",
    xl: 3,
  },
  {
    id: "energyIntensity",
    name: "energyIntensity",
    label: "Energy intensity",
    placeholder: "Enter energy intensity",
    type: "text",
    width: "10%",
    xl: 4,
    suffix: "kWh/kg",
  },
  {
    id: "processLocation",
    name: "processLocation",
    label: "Process location",
    placeholder: "Enter process location",
    type: "text",
    width: "10%",
    xl: 4,
  },
  {
    id: "transportMode",
    name: "transportMode",
    label: "Transport mode",
    placeholder: "Enter transport mode",
    type: "select",
    // static
    option: [
      { label: "appp", value: "app" },
      { label: "fdgfg", value: "fdgfg" },
      { label: "sdsdsd", value: "sdsdsd" },
    ],
    width: "10%",
    xl: 4,
  },
];

export const toCamelCase = (str) => {
  // Split the string by spaces
  const words = str.split(" ");

  // Map over the words, converting each word except the first to capitalize the first letter
  const camelCasedWords = words.map((word, index) => {
    if (index === 0) {
      // For the first word, keep it in lowercase
      return word.toLowerCase();
    } else {
      // For subsequent words, capitalize the first letter and keep the rest in lowercase
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  });

  // Join the words back together into a single string
  return camelCasedWords.join("");
};

export const camelCaseToNormalCase = (camelCaseStr) => {
  const isAlphabetic = /^[a-zA-Z]+$/.test(camelCaseStr);
  let normalCaseStr;
  if (isAlphabetic) {
    normalCaseStr = camelCaseStr?.replace(/([A-Z])/g, " $1").toLowerCase();
    normalCaseStr =
      normalCaseStr?.charAt(0).toUpperCase() + normalCaseStr?.slice(1);
  } else {
    normalCaseStr = camelCaseStr;
  }
  return normalCaseStr;
};

export const pcfPackagingDetailTableFields = [
  {
    id: "packagingName",
    name: "packagingName",
    label: "Packaging name",
    placeholder: "Enter packaging name",
    type: "text",
    width: "10%",
    xl: 3,
  },
  {
    id: "materialCategory",
    name: "materialCategory",
    label: "Material category",
    placeholder: "Enter material category",
    type: "select",
    // dynamic
    option: [
      { label: "appp", value: "app" },
      { label: "fdgfg", value: "fdgfg" },
      { label: "sdsdsd", value: "sdsdsd" },
    ],
    width: "10%",
    xl: 3,
  },
  {
    id: "subCategory",
    name: "subCategory",
    label: "Sub category",
    placeholder: "Enter sub category",
    type: "select",
    // dynamic
    option: [
      { label: "appp", value: "app" },
      { label: "fdgfg", value: "fdgfg" },
      { label: "sdsdsd", value: "sdsdsd" },
    ],
    width: "10%",
    xl: 3,
  },
  {
    id: "quality",
    name: "quality",
    label: "Quality",
    placeholder: "Enter quality",
    type: "select",
    // dynamic
    option: [
      { label: "appp", value: "app" },
      { label: "fdgfg", value: "fdgfg" },
      { label: "sdsdsd", value: "sdsdsd" },
    ],
    width: "10%",
    xl: 3,
  },
  {
    id: "weight",
    name: "weight",
    label: "Weight",
    placeholder: "Enter weight",
    type: "text",
    width: "10%",
    xl: 3,
  },
  {
    id: "supplierName",
    name: "supplierName",
    label: "Supplier name",
    placeholder: "Enter supplier name",
    type: "text",
    width: "10%",
    xl: 3,
  },
  {
    id: "supplierLocation",
    name: "supplierLocation",
    label: "Supplier location",
    placeholder: "Enter supplier location",
    type: "select",
    // gmaps select option dynamic
    option: [
      { label: "appp", value: "app" },
      { label: "fdgfg", value: "fdgfg" },
      { label: "sdsdsd", value: "sdsdsd" },
    ],
    width: "10%",
    xl: 3,
  },
  {
    id: "transportMode",
    name: "transportMode",
    label: "Transport mode",
    placeholder: "Enter transport mode",
    type: "select",
    // static
    option: [
      { label: "appp", value: "app" },
      { label: "fdgfg", value: "fdgfg" },
      { label: "sdsdsd", value: "sdsdsd" },
    ],
    width: "10%",
    xl: 3,
  },
];

export const pcfProductAssemblyTableFields = [
  {
    id: "manufacturingFacilityLocation",
    name: "manufacturingFacilityLocation",
    label: "Manufacturing facility location",
    placeholder: "Enter manufacturing facility location",
    type: "select",
    // gmaps select option dynamic
    option: [
      { label: "appp", value: "app" },
      { label: "fdgfg", value: "fdgfg" },
      { label: "sdsdsd", value: "sdsdsd" },
    ],
    width: "10%",
    xl: 6,
  },
  {
    id: "energySource",
    name: "energySource",
    label: "Energy source",
    placeholder: "Enter energy source",
    type: "select",
    // /dynamic values
    option: [
      { label: "appp", value: "app" },
      { label: "fdgfg", value: "fdgfg" },
      { label: "sdsdsd", value: "sdsdsd" },
    ],
    width: "10%",
    xl: 4,
  },
  {
    id: "totalEnergyConsumed",
    name: "totalEnergyConsumed",
    label: "Total energy consumed",
    placeholder: "Enter total energy consumed",
    type: "text",
    width: "10%",
    xl: 4,
  },
  {
    id: "brandDistributionCenterLocation",
    name: "brandDistributionCenterLocation",
    label: "Brand distribution center location",
    placeholder: "Enter brand distribution center location",
    type: "select",
    // gmaps select option dynamic
    option: [
      { label: "appp", value: "app" },
      { label: "fdgfg", value: "fdgfg" },
      { label: "sdsdsd", value: "sdsdsd" },
    ],
    width: "10%",
    xl: 6,
  },
  {
    id: "modeofTransport",
    name: "modeofTransport",
    label: "Mode of transport",
    placeholder: "Enter mode of transport",
    type: "select",
    // static
    option: [
      { label: "appp", value: "app" },
      { label: "fdgfg", value: "fdgfg" },
      { label: "sdsdsd", value: "sdsdsd" },
    ],
    width: "10%",
    xl: 4,
  },
];

export const colorInner = ["#C3FF93", "#A0DEFF", "#7ABA78"];
export const colorCode = [
  [
    "#C3FF93",
    "#B0FF8D",
    "#9CFF88",
    "#89FF82",
    "#76FF7C",
    "#63FF77",
    "#4FFF71",
    "#3CFF6B",
    "#29FF66",
    "#16FF60",
    "#03FF5A",
  ],
  [
    "#C3FFFF",
    "#A3F7FF",
    "#93EFFF",
    "#83E7FF",
    "#73DFFF",
    "#63D7FF",
    "#53CFFF",
    "#43C7FF",
    "#33BFFF",
    "#23B7FF",
    "#13AFFF",
  ],
  [
    "#00F5FF",
    "#00FFFF",
    "#00F1EB",
    "#00E7D7",
    "#00DBBF",
    "#00D1AB",
    "#00C793",
    "#00BD7F",
    "#00B36B",
    "#00A957",
    "#009F43",
  ],
];

export const BRSRTabsList = [
  {
    key: "1",
    label: "Dashboard",
    // children: "Score and Statistics for your BRSR Report",
  },
  {
    key: "2",
    label: "Forms",
    // children: "placeholder",
  },
  {
    key: "3",
    label: "Users",
    // children: "Create and Assign SPOCs to your Reports",
  },
];
export const UNIT = {
  weight: "Tonne",
};

// retutn {preUnit,postUnit}
export const UNIT_KEY = {
  unladden_weight: { postUnit: UNIT.weight },
  capacity: { postUnit: UNIT.weight },
  gross_vehicle_weight: { postUnit: UNIT.weight },
};

export const getTableColumConfig = (key) => {
  if (key in TABLE_CONFIG) {
    return TABLE_CONFIG[key];
  } else {
    return {
      label: key?.label,
      field: key?.field,
      sort: key?.sort,
    };
  }
};

export const truncateAndAddCommas = (value) => {
  return Number(Number(value).toFixed(0)).toLocaleString();
};

export const sortCommaSeparatedString = (str) => {
  let numbersArray = str.split(",");
  let intArray = numbersArray.map(Number);
  intArray.sort((a, b) => a - b);
  let sortedString = intArray.join(",");
  return sortedString;
};
export const getTableData = (TABLE_HEADER, data) => {
  return {
    columns: TABLE_HEADER?.map((dataKey) => getTableColumConfig(dataKey)),
    rows: data || [],
  };
  // }
  // return {
  //   columns: [
  //     {
  //       label: "",
  //       field: "",
  //       sort: "disabled",
  //     },
  //   ],
  //   rows: [],
  // };
};

export const doughnutBRSROuterColors = [
  "#C3FF93",
  "#B0FF8D",
  "#9CFF88",
  "#89FF82",
  "#76FF7C",
  "#63FF77",
  "#4FFF71",
  "#3CFF6B",
  "#29FF66",
  "#16FF60",
  "#03FF5A",
  "#C3FFFF",
  "#A3F7FF",
  "#93EFFF",
  "#83E7FF",
  "#73DFFF",
  "#63D7FF",
  "#53CFFF",
  "#43C7FF",
  "#33BFFF",
  "#23B7FF",
  "#13AFFF",
  "#00f5ff",
  "#00ffff",
  "#00f1eb",
  "#00e7d7",
  "#00dbbf",
  "#00d1ab",
  "#00c793",
  "#00bd7f",
  "#00b36b",
  "#00a957",
  "#009f43",
];

export const doughnutBRSROuterLabels = [
  "Section A",
  "Section B",
  "Section C (Principle 1)",
  "Section C (Principle 2)",
  "Section C (Principle 3)",
  "Section C (Principle 4)",
  "Section C (Principle 5)",
  "Section C (Principle 6)",
  "Section C (Principle 7)",
  "Section C (Principle 8)",
  "Section C (Principle 9)",
  "Section A",
  "Section B",
  "Section C (Principle 1)",
  "Section C (Principle 2)",
  "Section C (Principle 3)",
  "Section C (Principle 4)",
  "Section C (Principle 5)",
  "Section C (Principle 6)",
  "Section C (Principle 7)",
  "Section C (Principle 8)",
  "Section C (Principle 9)",
  "Section A",
  "Section B",
  "Section C (Principle 1)",
  "Section C (Principle 2)",
  "Section C (Principle 3)",
  "Section C (Principle 4)",
  "Section C (Principle 5)",
  "Section C (Principle 6)",
  "Section C (Principle 7)",
  "Section C (Principle 8)",
  "Section C (Principle 9)",
];

export const BRSRSectionsKeys = [
  "A_I",
  "A_II",
  "A_III",
  "A_IV",
  "A_V",
  "A_VI",
  "A_VII",
  "B",
  "C_P1_E",
  "C_P1_L",
  "C_P2_E",
  "C_P2_L",
  "C_P3_E",
  "C_P3_L",
  "C_P4_E",
  "C_P4_L",
  "C_P5_E",
  "C_P5_L",
  "C_P6_E",
  "C_P6_L",
  "C_P7_E",
  "C_P7_L",
  "C_P8_E",
  "C_P8_L",
  "C_P9_E",
  "C_P9_L",
];

export const departmentList = [
  "Human Resources (HR)",
  "Finance/Accounting",
  "Sales",
  "Marketing",
  "Operations/Production",
  "Information Technology (IT)",
  "Customer Service/Support",
  "Research and Development (R&D)",
  "Legal",
  "Supply Chain/Logistics",
  "Quality Assurance/Control",
  "Administration",
  "Public Relations (PR)",
  "Training and Development",
  "Health and Safety",
];

export const connectionStatusList = ["ACCEPTED", "REJECTED"];
export const BIOGENIC_EMISSIONS_LINK =
  "https://ghgprotocol.org/sites/default/files/2023-02/Stationary_Combustion_Guidance_final_1_0.pdf";

export default CONSTANT;
