import React, { useState, useEffect } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import Location_Blue from "../../../../assets/images/Location_Blue.svg";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import useHttp from "../../../../components/Hook/Use-http";
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
import { replaceId } from "../../../../pages/Utility/function";
import CONSTANT from "../../../../pages/Utility/constnt";
const LoadingContainer = () => <div>Loading...</div>;
const MapComponent = (props) => {
  const [activeMarker, setActiveMarker] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const { tripList } = props;
  const API = useHttp();
  const [allLocations, setAllLocations] = useState([]);
  useEffect(() => {
    const fetchLocations = async () => {
      const tempLocations = [];
      const apiRequests = tripList.map(async (trip) => {
        const siteId = trip?.id;
            try {
              API.getAll(
                [replaceId(CONSTANT.API.SITE.getSinglePlant, siteId).endpoint],
                (res) => {
                  let lat = res[0]?.data?.data[0]?.latitude;
                  let lng = res[0]?.data?.data[0]?.logtitude;
                  let siteName = res[0]?.data?.data[0]?.unitName;
                  let scope_3 = trip?.Scope_3;
                  tempLocations.push({ lat, lng, siteName, scope_3 });
                  if (tempLocations.length === tripList.length) {
                    setAllLocations(tempLocations);
                  }
                }
              );
            } catch (error) {
              console.error("Error fetching location data:", error);
            }
      });
      await Promise.all(apiRequests);
    };
    fetchLocations();
  }, [tripList]);

  if (allLocations.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardSubtitle className="mb-3">
                Scope 3 Site-Wise Locations
              </CardSubtitle>
              <div
                id="gmaps-markers"
                className="gmaps"
                style={{ position: "relative" }}
              >
                <Map
                  google={props.google}
                  style={{ width: "100%", height: "100%" }}
                  zoom={1}
                >
                  {allLocations.map((location, index) => (

                    <Marker
                      key={index}
                      title={`Site ${location.siteName} Scope 3 Emission: ${location.scope_3}`}
                      position={{ lat: location.lat, lng: location.lng }}
                      icon={{
                        url: Location_Blue,
                        fillColor: "#EB00FF",
                      }}
                      onClick={(props, marker, e) => {
                        setActiveMarker(marker);
                        setSelectedPlace(location.siteName);
                        setShowingInfoWindow(true);
                      }}
                    />
                  ))}
                  {activeMarker && showingInfoWindow && (
                    <InfoWindow
                      marker={activeMarker}
                      visible={showingInfoWindow}
                      onClose={() => setShowingInfoWindow(false)}
                    >
                      <div>
                        <h1>{selectedPlace}</h1>
                      </div>
                    </InfoWindow>
                  )}
                </Map>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

MapComponent.propTypes = {
  google: PropTypes.object,
};

export default connect(null)(
  GoogleApiWrapper({
    apiKey: process.env.REACT_APP_MAP_KEY,
    LoadingContainer: LoadingContainer,
    v: "3",
  })(MapComponent)
);
