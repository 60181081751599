import React, { useEffect, useState } from "react";
import useHttp from "../../../components/Hook/Use-http";
import CONSTANT from "../../Utility/constnt";
import Select from "react-select";

import { emissionDashboardDataLoad } from "../../Utility/spread-function";
import PageTitle from "../../../components/pageTitle";

import { Card, CardBody, Col, Label, Row } from "reactstrap";
import { colorCode, colorInner } from "../../Utility/option";
import EmissionGen from "../../../components/dashboard/emissionGen";

const ClientEmissionDashboard = () => {
  const [chartView, setChartView] = useState(<></>);
  const [categoryDataLabel, setCategoryDataLabel] = useState({});

  const [financialYear, setFinancialYear] = useState("2023-24");
  const [AvailableFinancialYear, setAvailableFinancialYear] = useState([]);

  const API = useHttp();

  useEffect(() => {
    let analyticsAPIendpoint =
      CONSTANT.API.CATEGORYFORM.getCarbonEmissionAnalysis.endpoint;
    if (financialYear != null) {
      analyticsAPIendpoint += "?value=" + financialYear;
    }
    API.getAll(
      [
        analyticsAPIendpoint,
        CONSTANT.API.CATEGORY.get.endpoint,
        CONSTANT.API.SUBCATEGORY.get.endpoint,
      ],
      (res) => {
        const rees = emissionDashboardDataLoad(
          res,
          {},
          [],
          setChartView,
          setAvailableFinancialYear
        );

        setCategoryDataLabel(rees?.categoryData);
      }
    );
  }, [financialYear]);

  return (
    <React.Fragment>
      <div className="page-content">
        <PageTitle pageHeading={`DASHBOARD`} />

        <Row>
          {/* <Col sm={4} className="d-none">
            <Card
              style={{
                width: "100%",
              }}
            >
              <CardBody
                style={{ backgroundColor: "#2f4395", color: "white" }}
              ></CardBody>
            </Card>
          </Col> */}
          <Col sm={12} className="p-0">
            <Row>
              <Col xs="5">
                <Col>
                  <Label style={{ display: "flex", alignItems: "center" }}>
                    Financial year
                  </Label>

                  <Select
                    className="mb-3"
                    options={AvailableFinancialYear}
                    required={true}
                    maxMenuHeight={200}
                    onChange={(selected) => {
                      setFinancialYear(selected?.value);
                    }}
                  />
                  <EmissionGen
                    extraValue={{
                      financialYear: financialYear,
                      categoryDataLabel: categoryDataLabel,
                    }}
                  />
                </Col>
              </Col>

              <Col xs="7">{chartView}</Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default ClientEmissionDashboard;
