import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Col,
  Label,
  Modal,
  Row,
  Input,
  UncontrolledTooltip,
  Button,
} from "reactstrap";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { connect } from "react-redux";
import { GoogleApiWrapper } from "google-maps-react";

import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import moment from "moment/moment";
import CreatableSelect from "react-select/creatable";
import {
  InputOption,
  debounce,
  getLatLngFromGoogleMapsLink,
} from "../../pages/Utility/function";
import FormForModal from "./FormForModal";
import ReactSwitch from "react-switch";
import GoogleMapModal from "../googleMapModal";
import notify from "../../pages/Utility/coustemFunction";
import { Card, Form, Button as AntDBtn, InputNumber } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import AntdForm from "./antdForm";
import MultiSelectField from "../customselect";
import TestCustomSelect from "../testCustomSelect";
// import "./../style/custom.css";

import "../../assets/css/Custom.css";
import Services from "../../pages/Utility/API/service";

const requiredFieldStyle = {
  color: "red",
  marginLeft: "3px",
};

const iButtonStyle = {
  fontSize: "15px",
  marginLeft: "2px",
};

const LoadingContainer = () => <div></div>;
const CustomModalMain = (props) => {
  const {
    isAutoClose = true,
    isCloase = false,

    changedetect = {},
    modalType,
    show,
    close = () => null,
    modalTitle,
    extraValues = null,
    data,
    onSubmit,
    defaultData = null,
    setDefaultData = () => null,
    formData,
    isEdit,
    onChange = () => {},
    onVerifyHandler = () => {},
    // onChangeFunction = {},
    option = {},
    isSubmitButtonVisible = true,
    viewModel = <></>,
    minHeight = "auto",
    cleanData = false,
    submitBtnName = "Submit",
    fieldSize = "100%",
    labelSize,
    submitBtnPosition = true,
    isCloseButtonVisible = true,
    disabledValue = null,
    scrollCard = "scroll",
    changeInputPayload = null,
    extraConstPayload = null,
    accessTeam = null,
    editAllPayloadSubmit = false,
    extrakeyForedit = null,
    editDefaultorInput = false,
    onSingleChange = () => {},

    // overflowY = "scroll",
  } = props;


  const [inputData, setInputData] = useState({});

  const [defaultValue, setDefaultValue] = useState({});

  const [selectedValue, setSelectedValue] = useState({});
  const [isChange, setIsChange] = useState(false);

  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [error, setError] = useState("");

  const [selectedplant, setSelectedplant] = useState(true);
  const [locationChange, setLocationChange] = useState(false);
  const [selectedSwitchValue, setSelectedSwitchValue] = useState(false);
  const [mapViewOpen, setmapViewOpen] = useState(false);
  const animatedComponents = makeAnimated();
  const [closedSKUIndex, setClosedSKUIndex] = useState([]); 

  const [form] = Form.useForm();
  const operations = {
    inputData: setInputData,
    selectedValue: setSelectedValue,
    setDefaultValue: setDefaultValue,
  };

  useEffect(() => {
    // debounce()
    onChange(inputData, selectedValue, operations, defaultValue);
  }, [inputData]);

  useEffect(() => {
    if (editAllPayloadSubmit == false) return;

    const ddd = defaultValue;
    setInputData((prev) => {
      return {
        ...prev,
        ...ddd,
      };
    });
  }, [editAllPayloadSubmit]);

  useEffect(() => {
    setDefaultValue(defaultData);
  }, [defaultData]);

  useEffect(() => {
    setInputData({});
    onSingleChange({});
    // setDefaultValue({});
    // setSelectedValue({});
    // setRemoveInputValue({});
  }, [cleanData]);

  useEffect(() => {
    if (!isCloase) return;

    close();
    setInputData({ ...defaultValue });
    setIsChange(false);
    setSelectedValue({});
    onSingleChange({});
  }, [isCloase]);

  const onChangeInput = (e) => {
    setInputData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
    onSingleChange({ [e.target.name]: e.target.value });
  };

  const onChangeSwitchInput = (e, id) => {
    setInputData((prev) => {
      return {
        ...prev,
        [id]: e[id],
      };
    });
    onSingleChange({ [id]: e[id] });
    // }
  };

  useEffect(() => {
    if (
      changeInputPayload != null &&
      Object?.keys?.(changeInputPayload)?.length > 0
    ) {
      setInputData({ ...changeInputPayload });
      onSingleChange({ ...changeInputPayload });
    }
  }, [changeInputPayload]);

  useEffect(() => {
    if (extraConstPayload != null) {
      setInputData((prev) => {
        return {
          ...prev,

          ...extraConstPayload,
        };
      });
      onSingleChange({ ...extraConstPayload });
    }
  }, []);

  useEffect(() => {
    if (!isEdit) return;
    const newArr = { ...defaultData, ...inputData };
    if (defaultValue != null && Object?.keys(newArr)) {
      data
        ?.filter?.(
          (el) =>
            el?.type == "SingleSelect" || el?.type == "SingleSelectWithCreate"
        )
        ?.map((ell) => {
          const OldValue = isEdit ? newArr?.[ell?.name] : null;

          setSelectedValue((pr) => {
            pr[ell?.name] =
              option[ell?.name]?.filter(
                (e) =>
                  e.value == OldValue?.toString() ||
                  e.label == OldValue?.toString()
              )[0] ||
              ell?.options?.filter((e) => e.value == OldValue?.toString())[0];
            return {
              ...pr,
            };
          });
        });
      // if (newArr["role"]) {

      //   Object?.keys(inputData)?.length <= 0 &&
      //     setInputData((pr) => {
      //       return { ...pr, role: newArr["role"] };
      //     });
      // }
    }
  }, [defaultValue]);

  const onGoogleInputChange = async (e) => {
    const splitArry = e.target.value.split("/@");

    if (splitArry.length > 1) {
      const splitArry2 = splitArry[1].split(",");
      if (splitArry2.length > 1) {
        if (splitArry2[0] && splitArry2[1]) {
          setLocationChange(false);

          setInputData({
            ...inputData,
            [e.target.name]: e.target.value,
            latitude: splitArry2[0],
            logtitude: splitArry2[1],
          });
          // }
        } else {
          setLocationChange(true);

          setInputData({ ...inputData, [e.target.name]: e.target.value });
        }
      } else {
        setLocationChange(true);

        setInputData({ ...inputData, [e.target.name]: e.target.value });
      }
    } else {
      setLocationChange(true);

      setInputData({ ...inputData, [e.target.name]: e.target.value });
    }
  };

  const onSelectValue = (selected, key, field) => {
    field?.isShow();

    setInputData({ ...inputData, [key]: selected.value });
    setSelectedValue({ ...selectedValue, [key]: selected });
    onSingleChange({ [key]: selected.value }, selected);

    if (key === "plantId" && extraValues) {
      const f = extraValues.filter((data) => data.id === selected.value);

      setSelectedplant(f);
    }

    setIsChange(true);
  };

  const onChangeFileValue = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.files[0] });
    onSingleChange({ [e.target.name]: e.target.files[0] });
  };

  const onChangeMultiInput = (ele) => {
    let SKUs = ele?.["Add SKU"];
    setInputData({...inputData, 
      SKUs});
  }

  const datinationFind = (request, directionsService) => {
    directionsService.getDistanceMatrix(request, (response, status) => {
      if (status === "OK") {
        const DistanceOfTrip = response.rows[0].elements[0].distance.text || 0;

        setInputData((previous) => {
          return {
            ...previous,
            distanceOfTrip: +parseInt(DistanceOfTrip, 0).toFixed(0),
          };
        });
      }
    });
  };

  const onChangeGoogleAddress = (e, fieldName) => {
    if (fieldName === "sourceLocation") {
      setInputData({
        ...inputData,
        [fieldName]: e?.label,
        // sourceLocation: e?.label,
        sourceId: e?.value?.place_id,
      });
      // }
    } else if (fieldName === "location") {
      setInputData({
        ...inputData,
        [fieldName]: e?.label,
        // location: e?.label,
        locationId: e?.value?.place_id,
      });
      // }
    } else if (fieldName === "URL") {
      setInputData({
        ...inputData,
        destinationLocation: e?.label,
      });
      // }

      geocodeByPlaceId(e?.value?.place_id)
        .then((results) => {
          const destinationLat = results[0].geometry.location.lat();
          const destinationLong = results[0].geometry.location.lng();

          if (destinationLat && destinationLong) {
            const directionsService =
              new props.google.maps.DistanceMatrixService();

            if (selectedplant != null) {
              const pointA = new props.google.maps.LatLng(
                selectedplant[0]?.latitude,
                selectedplant[0]?.logtitude
              ); // Example coordinates
              const pointB = new props.google.maps.LatLng(
                destinationLat,
                destinationLong
              ); // Example coordinates
              const request = {
                origins: [pointA],
                destinations: [pointB],
                travelMode: "DRIVING",
              };

              datinationFind(request, directionsService);
            }
          }

          setInputData({
            ...inputData,
            latitude: destinationLat,
            logtitude: destinationLong,
            URL: e?.label?.toUpperCase(),
            placeId: e?.value?.place_id,
          });
          // }
        })
        .catch((error) => console.error(error));

      Object.keys(inputData).map((key) => {
        payload.append(key, inputData[key]);
      });
    } else {
      if (fieldName === "destinationLocation") {
        setInputData({
          ...inputData,
          [fieldName]: e?.label,
          // destinationLocation: e?.label,
          destinationId: e?.value?.place_id,
        });
      } else {
        setInputData({
          ...inputData,
          [`${fieldName}`]: e?.label,
        });
      }

      // }
    }
  };
  let payload = new FormData();
  const onSubmitForm = (e) => {
    e.preventDefault();
    let formValue = {};
    changeInputPayload != null
      ? (formValue = inputData)
      : (formValue = inputData);

    if (formData) {
      Object.keys(formValue).map((key) => {
        payload.append(key, formValue[key]);
      });

      // if (accessTeam == "site") {
      //   if (inputData?.latitude && inputData?.logtitude) {
      //     onSubmit(payload);
      //   } else {
      //     notify.error("Please Select Location");
      //   }
      // } else {

      onSubmit(payload, setInputData);
      // }
    } else {
      // if (accessTeam == "site") {
      //   if (inputData?.latitude && inputData?.logtitude) {
      //     onSubmit(changeInputPayload != null ? inputData : inputData);
      //   } else {
      //     notify.error("Please Select Location");
      //   }
      // } else {

      onSubmit(
        changeInputPayload != null ? inputData : inputData,
        setInputData
      );
      // }
    }

    close && isAutoClose && close();
  };
  // useEffect(() => {
  //   const result = close();

  //   if (result != null) {
  //     if (!isAutoClose) {
  //       close();
  //     }
  //   }
  // }, [close]);
  const handlePincodeChange = (e) => {
    const newPincode = e.target.value;

    if (newPincode.length === 6) {
      setInputData({ ...inputData, [e.target.name]: e.target.value });
      // }

      fetchPincodeDetails(newPincode);
    } else {
      setCity("");
      setState("");
      setError("");
    }
  };

  useEffect(() => {
    if (defaultValue?.pincode) fetchPincodeDetails(defaultValue?.pincode);

    if (defaultValue?.pincode) onChange(defaultValue, operations);
  }, [defaultValue]);

  const fetchPincodeDetails = async (pincode) => {
    try {
      //   API_CALL.sendRequest(CONSTANT.API.getAllPlant, plantDataHandler);

      fetch(`https://api.postalpincode.in/pincode/${pincode}`)
        .then((res) => res.json())
        .then((json) => {
          if (json[0]?.PostOffice) {
            setCity(json[0]?.PostOffice[0]?.District);
            setState(json[0]?.PostOffice[0]?.State);

            setInputData((inputData) => ({
              ...inputData,
              city: json[0]?.PostOffice[0]?.District,
              state: json[0]?.PostOffice[0]?.State,
            }));
            // }

            setError("");
          } else {
            setError("Wrong Pincode");
          }
        });
    } catch (error) {
      setCity("");
      setState("");
      setError("Invalid PIN code");
    }
  };

  //   const isEditOn = () => {
  //     isEdit ? setEditOn(true) : setEditOn(false);
  //   };

  const onCloseModal = () => {
    close();
    setInputData({});
    setIsChange(false);
    setSelectedValue({});
    onSingleChange({});
    setDefaultValue({});
    // setRemoveInputValue({});
  };

  const onChangeNumber = (key, value, country, e, formattedValue) => {
    setInputData({
      ...inputData,
      [key]: value.replace(country.dialCode, ""),
      countryCode: country.dialCode,
    });
    onSingleChange({
      [key]: value.replace(country.dialCode, ""),
      countryCode: country.dialCode,
    });
    // }
  };

  const formField = (fieldName, index) => {
    if (
      (fieldName.type === "text" || fieldName.type === "number") &&
      fieldName.disabled == false
    ) {
      return (
        <Col
          xs={12}
          md={12}
          sm={12}
          lg={fieldName?.width || 12}
          key={index}
          className="mb-4"
        >
          <Label
            htmlFor="validationCustom01"
            style={{ display: "flex", alignItems: "center" }}
          >
            {fieldName?.label}{" "}
            {fieldName?.required && <sub style={requiredFieldStyle}>*</sub>}
            {fieldName?.helpText && fieldName?.helpText != null && (
              <>
                {" "}
                {/* <UncontrolledTooltip placement="top" target={"icon" + index}>
                  {fieldName?.helpText}
                </UncontrolledTooltip> */}
                <i
                  title={fieldName?.helpText}
                  className="bx bx-info-circle"
                  id={"icon" + index}
                  style={iButtonStyle}
                ></i>
              </>
            )}
          </Label>
          {/* {fieldName.name === "total" || fieldName.name === "subTotal" ? (
            <input
              autoComplete="no"
              name={fieldName?.name}
              defaultValue={
                defaultValue &&
                defaultValue !== "null" &&
                defaultValue !== "undefined" &&
                defaultValue?.[fieldName?.name]
              }
              placeholder={fieldName?.placeholder}
              type={fieldName?.type ? fieldName?.type : "text"}
              className="form-control"
              required={fieldName?.required}
              id={"validationCustom" + index}
              onChange={onChangeInput}
              disabled
            />
          ) : ( */}

          <input
            autoComplete="no"
            name={fieldName?.name}
            defaultValue={
              defaultValue &&
              defaultValue !== "null" &&
              defaultValue !== "undefined"
                ? defaultValue?.[fieldName?.name] || ""
                : null
            }
            value={
              disabledValue !== null
                ? disabledValue || ""
                : inputData?.[fieldName?.name] != undefined ||
                  inputData?.[fieldName?.name] != null
                ? inputData?.[fieldName?.name] || ""
                : inputData?.[fieldName?.name] === ""
                ? inputData?.[fieldName?.name] || ""
                : defaultValue?.[fieldName?.name] != undefined ||
                  defaultValue?.[fieldName?.name] != null
                ? defaultValue?.[fieldName?.name] || ""
                : ""
            }
            placeholder={fieldName?.placeholder}
            type={fieldName?.type ? fieldName?.type : "text"}
            className="form-control"
            required={fieldName?.required}
            step="any"
            id={"validationCustom" + index}
            onChange={onChangeInput}
          />
          {/* )} */}
        </Col>
      );
    } else if (
      (fieldName.type === "text" || fieldName.type === "number") &&
      fieldName.disabled
    ) {
      return (
        <Col
          xs={12}
          md={12}
          sm={12}
          lg={fieldName?.width || 12}
          key={index}
          className="mb-4"
        >
          <Label
            htmlFor="validationCustom01"
            style={{ display: "flex", alignItems: "center" }}
          >
            {fieldName?.label}{" "}
            {fieldName?.required && <sub style={requiredFieldStyle}>*</sub>}
            {fieldName?.helpText && fieldName?.helpText != null && (
              <>
                {" "}
                {/* <UncontrolledTooltip placement="top" target={"icon" + index}>
                  {fieldName?.helpText}
                </UncontrolledTooltip> */}
                <i
                  title={fieldName?.helpText}
                  className="bx bx-info-circle"
                  id={"icon" + index}
                  style={iButtonStyle}
                ></i>
              </>
            )}
          </Label>
          {
            <input
              autoComplete="no"
              name={fieldName?.name}
              defaultValue={
                defaultValue &&
                defaultValue !== "null" &&
                defaultValue !== "undefined" &&
                defaultValue?.[fieldName?.name]
              }
              value={
                disabledValue !== null
                  ? disabledValue || ""
                  : inputData?.[fieldName?.name] != undefined ||
                    inputData?.[fieldName?.name] != null
                  ? inputData?.[fieldName?.name] || ""
                  : inputData?.[fieldName?.name] === ""
                  ? inputData?.[fieldName?.name] || ""
                  : defaultValue?.[fieldName?.name] != undefined ||
                    defaultValue?.[fieldName?.name] != null
                  ? defaultValue?.[fieldName?.name] || ""
                  : ""
              }
              // value={disabledValue !== null ? disabledValue : null}
              placeholder={fieldName?.placeholder}
              type={fieldName?.type ? fieldName?.type : "text"}
              className="form-control"
              required={fieldName?.required}
              id={"validationCustom" + index}
              onChange={onChangeInput}
              disabled
            />
          }
        </Col>
      );
    } else if (fieldName.type === "color") {
      return (
        <Col
          xs={12}
          md={12}
          sm={12}
          lg={fieldName?.width || 12}
          key={index}
          className="mb-4"
        >
          <Label
            htmlFor="validationCustom01"
            style={{ display: "flex", alignItems: "center" }}
          >
            {fieldName?.label}{" "}
            {fieldName?.required && <sub style={requiredFieldStyle}>*</sub>}
            {fieldName?.helpText && fieldName?.helpText != null && (
              <>
                {" "}
                {/* <UncontrolledTooltip placement="top" target={"icon" + index}>
                  {fieldName?.helpText}
                </UncontrolledTooltip> */}
                <i
                  title={fieldName?.helpText}
                  className="bx bx-info-circle"
                  id={"icon" + index}
                  style={iButtonStyle}
                ></i>
              </>
            )}
          </Label>
          {
            <input
              autoComplete="no"
              className="form-control form-control-color mw-100"
              name={fieldName?.name}
              defaultValue={
                defaultValue &&
                defaultValue !== "null" &&
                defaultValue !== "undefined" &&
                defaultValue?.[fieldName?.name]
              }
              value={disabledValue !== null ? disabledValue : null}
              type={fieldName?.type ? fieldName?.type : "text"}
              required={fieldName?.required}
              id={"validationCustom" + index}
              onChange={onChangeInput}
            />
          }
        </Col>
      );
    } else if (fieldName.type === "textWithVerifyButton") {
      return (
        <Col
          xs={12}
          md={12}
          sm={12}
          lg={fieldName?.width || 12}
          key={index}
          className="mb-4"
        >
          <Label
            htmlFor="validationCustom01"
            style={{ display: "flex", alignItems: "center" }}
          >
            {fieldName?.label}{" "}
            {fieldName?.required && <sub style={requiredFieldStyle}>*</sub>}
            {fieldName?.helpText && fieldName?.helpText != null && (
              <>
                {" "}
                {/* <UncontrolledTooltip placement="top" target={"icon" + index}>
                  {fieldName?.helpText}
                </UncontrolledTooltip> */}
                <i
                  title={fieldName?.helpText}
                  className="bx bx-info-circle"
                  id={"icon" + index}
                  style={iButtonStyle}
                ></i>
              </>
            )}
          </Label>
          <div style={{ display: "flex", gap: "10px" }}>
            <input
              autoComplete="no"
              name={fieldName?.name}
              defaultValue={
                defaultValue &&
                defaultValue !== "null" &&
                defaultValue !== "undefined" &&
                defaultValue?.[fieldName?.name]
              }
              placeholder={fieldName?.placeholder}
              type={fieldName?.type ? fieldName?.type : "text"}
              className="form-control"
              required={fieldName?.required}
              id={"validationCustom" + index}
              onChange={onChangeInput}
            />
            <button
              type="button"
              onClick={() => {
                onVerifyHandler({
                  [fieldName?.name]: inputData[fieldName?.name],
                });
              }}
              className="btn btn-primary waves-effect"
              style={{ width: "80px" }}
            >
              Verify
            </button>
          </div>
        </Col>
      );
    } else if (fieldName.type === "google-location-link") {
      //   const OldValue = isEdit
      //     ? (value = fieldName.name === "city" ? city : state)
      //     : null;

      return (
        <Col
          xs={12}
          md={12}
          sm={12}
          lg={fieldName?.width || 12}
          key={index}
          className="mb-4"
        >
          <Label
            htmlFor="validationCustom01"
            style={{ display: "flex", alignItems: "center" }}
          >
            {fieldName?.label}{" "}
            {fieldName?.required && <sub style={requiredFieldStyle}>*</sub>}
            {fieldName?.helpText && fieldName?.helpText != null && (
              <>
                {" "}
                {/* <UncontrolledTooltip placement="top" target={"icon" + index}>
                  {fieldName?.helpText}
                </UncontrolledTooltip> */}
                <i
                  title={fieldName?.helpText}
                  className="bx bx-info-circle"
                  id={"icon" + index}
                  style={iButtonStyle}
                ></i>
              </>
            )}
          </Label>

          <input
            autoComplete="no"
            name={fieldName?.name}
            defaultValue={
              defaultValue &&
              defaultValue !== "null" &&
              defaultValue !== "undefined" &&
              defaultValue?.[fieldName?.name]
            }
            placeholder={fieldName?.placeholder}
            type={fieldName?.type ? fieldName?.type : "text"}
            className="form-control"
            required={fieldName?.required}
            id={"validationCustom" + index}
            onChange={onGoogleInputChange}
          />
          {locationChange && (
            <p style={{ color: "red", fontSize: "11px" }}>
              Please Enter Valid Link
            </p>
          )}
        </Col>
      );
    } else if (fieldName.type === "google-map-view") {
      return (
        <Col
          xs={12}
          md={12}
          sm={12}
          lg={fieldName?.width || 12}
          key={index}
          className="mb-4"
        >
          <Label
            htmlFor="validationCustom01"
            style={{ display: "flex", alignItems: "center" }}
          >
            {fieldName?.label}{" "}
            {fieldName?.required && <sub style={requiredFieldStyle}>*</sub>}
            {fieldName?.helpText && fieldName?.helpText != null && (
              <>
                {" "}
                {/* <UncontrolledTooltip placement="top" target={"icon" + index}>
                  {fieldName?.helpText}
                </UncontrolledTooltip> */}
                <i
                  title={fieldName?.helpText}
                  className="bx bx-info-circle"
                  id={"icon" + index}
                  style={iButtonStyle}
                ></i>
              </>
            )}
          </Label>
          <div style={{ display: "flex", gap: "10px" }}>
            {/* <input
              autoComplete="no"
              // name={fieldName?.name}
              value={
                inputData?.locationAddress ? inputData?.locationAddress : null
              }
              placeholder={"Selected Address"}
              type={"text"}
              className="form-control"
              required={true}
              id={"validationCustom" + index}
              onChange={onChangeInput}
              style={{ width: "62%" }}
              disabled
            /> */}

            <div style={{ width: "62%" }}>
              <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_MAP_KEY}
                apiOptions={{
                  types: ["(cities)"],
                  componentRestrictions: { country: "IN" },
                }}
                selectProps={{
                  value:
                    inputData?.locationName && inputData?.locationData
                      ? {
                          label: inputData?.locationName,
                          value: {
                            place_id: inputData?.locationData,
                          },
                        }
                      : null,
                  placeholder: (
                    <div
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {fieldName.placeholder}
                    </div>
                  ),
                  onChange: (e) => {
                    geocodeByPlaceId(e.value.place_id)
                      .then((results) => {
                        setInputData((pr) => {
                          return {
                            ...pr,
                            latitude: results[0].geometry.location.lat(),
                            logtitude: results[0].geometry.location.lng(),
                            locationName: results[0]?.formatted_address,
                            locationData: e.value.place_id,
                          };
                        });
                      })
                      .catch((error) => console.error(error));
                  },
                }}
              />
            </div>

            <Button
              color="primary"
              className="btn btn-primary waves-effect waves-light p-2 d-flex align-items-center "
              onClick={() => {
                setmapViewOpen(true);
              }}
              // style={{ display: "flex", alignItems: "center" }}
              id="view"
            >
              <i className="bx bxs-map"></i>Pick Location
            </Button>
          </div>
          {mapViewOpen && (
            <GoogleMapModal
              isOpen={mapViewOpen}
              setInputData={setInputData}
              inputData={inputData}
              setmapViewOpen={setmapViewOpen}
              autoSerach={mapViewOpen}
              onSubmit={(data) => {
                setInputData((prev) => {
                  return {
                    ...prev,
                    latitude: data?.lat,
                    logtitude: data?.lng,
                    locationName: data?.locationName,
                    locationData: data?.locationData,
                  };
                });
              }}
            />
          )}
        </Col>
      );
    } else if (fieldName.type === "pincode") {
      return (
        <Col
          xs={12}
          md={12}
          sm={12}
          lg={fieldName?.width || 12}
          key={index}
          className="mb-4"
        >
          <Label
            htmlFor="validationCustom01"
            style={{ display: "flex", alignItems: "center" }}
          >
            {fieldName?.label}{" "}
            {fieldName?.required && <sub style={requiredFieldStyle}>*</sub>}
            {fieldName?.helpText && fieldName?.helpText != null && (
              <>
                {" "}
                {/* <UncontrolledTooltip placement="top" target={"icon" + index}>
                  {fieldName?.helpText}
                </UncontrolledTooltip> */}
                <i
                  title={fieldName?.helpText}
                  className="bx bx-info-circle"
                  id={"icon" + index}
                  style={iButtonStyle}
                ></i>
              </>
            )}
          </Label>
          {fieldName.name === "city" || fieldName.name === "state" ? (
            <input
              autoComplete="no"
              name={fieldName?.name}
              defaultValue={
                defaultValue &&
                defaultValue !== "null" &&
                defaultValue !== "undefined" &&
                defaultValue?.[fieldName?.name]
              }
              //   OldValue
              // {isEdit ?   value=
              //   fieldName.name === "city" ? city : state
              // :  value={}}
              value={fieldName.name === "city" ? city : state}
              placeholder={fieldName?.placeholder}
              type={fieldName?.type ? fieldName?.type : "text"}
              className="form-control"
              required={fieldName?.required}
              id={"validationCustom" + index}
            />
          ) : (
            <>
              <input
                autoComplete="no"
                name={fieldName?.name}
                defaultValue={
                  defaultValue &&
                  defaultValue !== "null" &&
                  defaultValue !== "undefined" &&
                  defaultValue?.[fieldName?.name]
                }
                // value={pincode}
                placeholder={fieldName?.placeholder}
                type={fieldName?.type ? fieldName?.type : "text"}
                className="form-control"
                required={fieldName?.required}
                id={"validationCustom" + index}
                onChange={handlePincodeChange}
              />
              {error && <p style={{ color: "red" }}>{error}</p>}
            </>
          )}
        </Col>
      );
    } else if (fieldName.type === "SingleSelect") {
      const OldValue = isEdit ? defaultValue?.[fieldName?.name] : null;
      return (
        <Col
          xs={12}
          md={12}
          sm={12}
          lg={fieldName?.width || 12}
          key={index}
          className="mb-4"
        >
          <Label style={{ display: "flex", alignItems: "center" }}>
            {fieldName?.label}{" "}
            {fieldName?.required && <sub style={requiredFieldStyle}>*</sub>}
            {fieldName?.helpText && fieldName?.helpText != null && (
              <>
                {" "}
                {/* <UncontrolledTooltip placement="top" target={"icon" + index}>
                  {fieldName?.helpText}
                </UncontrolledTooltip> */}
                <i
                  title={fieldName?.helpText}
                  className="bx bx-info-circle"
                  id={"icon" + index}
                  style={iButtonStyle}
                ></i>
              </>
            )}
          </Label>
          <Select
            value={
              isEdit
                ? !isChange
                  ? option[fieldName?.name]?.filter(
                      (e) =>
                        e.value == OldValue?.toString() ||
                        e.label == OldValue?.toString()
                    )[0] ||
                    fieldName?.options?.filter(
                      (e) =>
                        e.value === OldValue?.toString() ||
                        e.label == OldValue?.toString()
                    )[0]
                  : selectedValue[fieldName?.name] || null
                : selectedValue[fieldName?.name] || null
            }
            options={
              fieldName?.options
                ? fieldName?.options
                : option[fieldName?.name]
                ? option[fieldName?.name]
                : []
            }
            closeMenuOnSelect={
              fieldName?.extraValue?.isSelectMultiple &&
              fieldName?.extraValue?.isSelectMultiple != undefined
                ? false
                : true
            }
            isMulti={
              fieldName?.extraValue?.isSelectMultiple &&
              fieldName?.extraValue?.isSelectMultiple != undefined
                ? true
                : false
            }
            components={
              fieldName?.extraValue?.isSelectMultiple != undefined
                ? animatedComponents
                : {}
            }
            required={true}
            maxMenuHeight={200}
            selected={"clientName" == fieldName?.name}
            classNamePrefix="select2-selection"
            onChange={(selected) => {
              onSelectValue(selected, fieldName?.name, fieldName);
              // if (onChangeFunction[fieldName?.name]) {
              //   onChangeFunction[fieldName?.name](selected);
              // }
              changedetect[fieldName?.name] &&
                changedetect[fieldName?.name](selected?.label);
            }}
          />
        </Col>
      );
    } else if (fieldName.type === "ports-autocomplete") {
      const OldValue = isEdit ? defaultValue?.[fieldName?.name] : null;
      const loadOptions = (inputValue, callback) => {
        Services.get(`admin/airports/${inputValue}`).then((res) => {
          const optionsList = res?.data?.data.split("\n").slice(0, -1);
          const options = [];
          optionsList.forEach((el) => {
            options.push({
              label: el.split("|")[0],
              value: el.split("|")[0],
            });
          });
          callback(options);
        });
      };
      return (
        <Col
          xs={12}
          md={12}
          sm={12}
          lg={fieldName?.width || 12}
          key={index}
          className="mb-4"
        >
          <Label style={{ display: "flex", alignItems: "center" }}>
            {fieldName?.label}{" "}
            {fieldName?.required && <sub style={requiredFieldStyle}>*</sub>}
            {fieldName?.helpText && fieldName?.helpText != null && (
              <>
                {" "}
                {/* <UncontrolledTooltip placement="top" target={"icon" + index}>
                  {fieldName?.helpText}
                </UncontrolledTooltip> */}
                <i
                  title={fieldName?.helpText}
                  className="bx bx-info-circle"
                  id={"icon" + index}
                  style={iButtonStyle}
                ></i>
              </>
            )}
          </Label>
          <AsyncSelect
            loadOptions={loadOptions}
            cacheOptions
            onChange={(selected) => {
              onSelectValue(selected, fieldName?.name, fieldName);
              // if (onChangeFunction[fieldName?.name]) {
              //   onChangeFunction[fieldName?.name](selected);
              // }
              changedetect[fieldName?.name] &&
                changedetect[fieldName?.name](selected?.label);
            }}
          />
        </Col>
      );
    } else if (fieldName.type === "SingleSelectWithCreate") {
      const OldValue = isEdit ? defaultValue?.[fieldName?.name] : null;

      return (
        <Col
          xs={12}
          md={12}
          sm={12}
          lg={fieldName?.width || 12}
          key={index}
          className="mb-4"
        >
          <Label style={{ display: "flex", alignItems: "center" }}>
            {fieldName?.label}{" "}
            {fieldName?.required && <sub style={requiredFieldStyle}>*</sub>}
            {fieldName?.helpText && fieldName?.helpText != null && (
              <>
                {" "}
                {/* <UncontrolledTooltip placement="top" target={"icon" + index}>
                  {fieldName?.helpText}
                </UncontrolledTooltip> */}
                <i
                  title={fieldName?.helpText}
                  className="bx bx-info-circle"
                  id={"icon" + index}
                  style={iButtonStyle}
                ></i>
              </>
            )}
          </Label>
          <CreatableSelect
            value={
              isEdit
                ? !isChange
                  ? option[fieldName?.name]?.filter(
                      (el) => el?.value == OldValue
                    )?.[0] ||
                    fieldName?.options?.filter((e) => e?.value === OldValue)
                  : selectedValue[fieldName?.name] || null
                : selectedValue[fieldName?.name]
            }
            options={
              fieldName?.options
                ? fieldName?.options
                : option[fieldName?.name]
                ? option[fieldName?.name]
                : []
            }
            isValidNewOption={(inputValue) => {
              if (fieldName?.hasOwnProperty("create")) {
                if (fieldName?.create == false) {
                  return null;
                } else {
                  return inputValue;
                }
              } else {
                return inputValue;
              }
            }}
            selected={"clientName" == fieldName?.name}
            classNamePrefix="select2-selection"
            onChange={(selected) => {
              onSelectValue(selected, fieldName?.name, fieldName);
            }}
          />
        </Col>
      );
    } else if (fieldName.type === "selectTime") {
      return (
        <Col
          xs={12}
          md={12}
          sm={12}
          lg={fieldName?.width || 12}
          key={index}
          className="mb-4"
        >
          <Label style={{ display: "flex", alignItems: "center" }}>
            {fieldName?.label}{" "}
            {fieldName?.required && <sub style={requiredFieldStyle}>*</sub>}
            {fieldName?.helpText && fieldName?.helpText != null && (
              <>
                {" "}
                {/* <UncontrolledTooltip placement="top" target={"icon" + index}>
                  {fieldName?.helpText}
                </UncontrolledTooltip> */}
                <i
                  title={fieldName?.helpText}
                  className="bx bx-info-circle"
                  id={"icon" + index}
                  style={iButtonStyle}
                ></i>
              </>
            )}
          </Label>
          <input
            name={fieldName?.name}
            className="form-control"
            type="time"
            defaultValue="13:45:00"
            id="example-time-input"
            onChange={onChangeInput}
            required={fieldName?.required}
          />
        </Col>
      );
    } else if (fieldName.type === "switch") {
      return (
        <Col
          xs={12}
          md={12}
          sm={12}
          lg={fieldName?.width || 12}
          key={index}
          className="mb-4"
        >
          <Label style={{ display: "flex", alignItems: "center" }}>
            {fieldName?.label}{" "}
            {fieldName?.required && <sub style={requiredFieldStyle}>*</sub>}
            {fieldName?.helpText && fieldName?.helpText != null && (
              <>
                {" "}
                {/* <UncontrolledTooltip placement="top" target={"icon" + index}>
                  {fieldName?.helpText}
                </UncontrolledTooltip> */}
                <i
                  title={fieldName?.helpText}
                  className="bx bx-info-circle"
                  id={"icon" + index}
                  style={iButtonStyle}
                ></i>
              </>
            )}
          </Label>
          <br />
          <ReactSwitch
            checked={selectedSwitchValue}
            onChange={(selected) => {
              const updatedField = { [fieldName.id]: selected };

              setSelectedSwitchValue(selected);
              onChangeSwitchInput(updatedField, fieldName.id);
              // setShowLabel((previous) => !previous);
            }}
            defaultChecked={false}
            onColor="#9098d1"
            onHandleColor="#303b91"
            handleDiameter={18}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={12}
            width={30}
            className="react-switch"
            id="material-switch"
          />
        </Col>
      );
    } else if (fieldName.type === "GoogleAutoComplete") {
      return (
        <Col
          xs={12}
          md={12}
          sm={12}
          lg={fieldName?.width || 12}
          key={index}
          className="mb-4"
        >
          <Label style={{ display: "flex", alignItems: "center" }}>
            {fieldName?.label}{" "}
            {fieldName?.required && <sub style={requiredFieldStyle}>*</sub>}
            {fieldName?.helpText && fieldName?.helpText != null && (
              <>
                {" "}
                {/* <UncontrolledTooltip placement="top" target={"icon" + index}>
                  {fieldName?.helpText}
                </UncontrolledTooltip> */}
                <i
                  title={fieldName?.helpText}
                  className="bx bx-info-circle"
                  id={"icon" + index}
                  style={iButtonStyle}
                ></i>
              </>
            )}
          </Label>
          <GooglePlacesAutocomplete
            apiKey={process.env.REACT_APP_MAP_KEY}
            apiOptions={{
              types: ["(cities)"],
              componentRestrictions: { country: "IN" },
            }}
            selectProps={{
              placeholder: (
                <div
                  className="google-placeholder-custom"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {fieldName.placeholder}
                </div>
              ),
              onChange: (e) => onChangeGoogleAddress(e, fieldName?.name),
            }}
          />
        </Col>
      );
    } else if (fieldName.type === "mobileNumber") {
      return (
        <Col
          xs={12}
          md={12}
          sm={12}
          lg={fieldName?.width || 12}
          key={index}
          className="mb-4"
        >
          <Label style={{ display: "flex", alignItems: "center" }}>
            {fieldName?.label}{" "}
            {fieldName?.required && <sub style={requiredFieldStyle}>*</sub>}
            {fieldName?.helpText && fieldName?.helpText != null && (
              <>
                <i
                  title={fieldName?.helpText}
                  className="bx bx-info-circle"
                  id={"icon" + index}
                  style={iButtonStyle}
                ></i>{" "}
                {/* <UncontrolledTooltip placement="top" target={"icon" + index}>
                  {fieldName?.helpText}
                </UncontrolledTooltip> */}
              </>
            )}
          </Label>
          {/* <br /> */}
          <PhoneInput
            inputProps={{
              name: "mobile",
              required: fieldName?.required,
            }}
            country="in"
            value={
              defaultValue &&
              defaultValue !== "null" &&
              defaultValue !== "undefined" &&
              `91${defaultValue?.[fieldName?.name]}`
            }
            onChange={(value, country, e, formattedValue) =>
              onChangeNumber(fieldName?.name, value, country, e, formattedValue)
            }
            inputStyle={{
              width: "100%",
            }}
          />
        </Col>
      );
    } else if (fieldName?.type == "multipleSelectWithCheckBox") {
      return (
        <Col
          xs={12}
          md={12}
          sm={12}
          lg={fieldName?.width || 12}
          key={index}
          className="mb-4"
        >
          <Label style={{ display: "flex", alignItems: "center" }}>
            {fieldName?.label}{" "}
            {fieldName?.required && <sub style={requiredFieldStyle}>*</sub>}
            {fieldName?.helpText && fieldName?.helpText != null && (
              <>
                {" "}
                {/* <UncontrolledTooltip placement="top" target={"icon" + index}>
                  {fieldName?.helpText}
                </UncontrolledTooltip> */}
                <i
                  title={fieldName?.helpText}
                  className="bx bx-info-circle"
                  id={"icon" + index}
                  style={iButtonStyle}
                ></i>
              </>
            )}
          </Label>
          <TestCustomSelect
            options={
              fieldName?.options
                ? fieldName?.options
                : option[fieldName?.name]
                ? option[fieldName?.name]
                : []
            }
            optionTitle={fieldName?.label}
            selectedOptions={selectedValue?.[fieldName?.name]}
            setSelectedOptions={setSelectedValue}
            setTripForm={setInputData}
            fieldName={fieldName}
          />
          {/* <Input
          type={fieldName.type}
          className="form-control"
          name={fieldName?.name}
          onChange={onChangeFileValue}
          required={fieldName?.required}
        /> */}
        </Col>
      );
      //   <div className="mb-4">
      //   <Label htmlFor="validationCustom01">
      //     Select Non-Reusable
      //     <sub style={{ color: "red" }}>*</sub>
      //   </Label>

      // </div>
    } else if (fieldName.type === "date") {
      let defaultDate =
        defaultValue &&
        defaultValue != null &&
        defaultValue != undefined &&
        fieldName?.name in defaultValue &&
        (moment(defaultValue?.[fieldName?.name], "DD-MM-YYYY", true)?.isValid()
          ? moment(defaultValue?.[fieldName?.name], "DD-MM-YYYY")?.format(
              "YYYY-MM-DD"
            )
          : `${defaultValue?.[fieldName?.name]}`);

      return (
        <Col
          xs={12}
          md={12}
          sm={12}
          lg={fieldName?.width || 12}
          key={index}
          className="mb-4"
        >
          <Label style={{ display: "flex", alignItems: "center" }}>
            {fieldName?.label}{" "}
            {fieldName?.required && <sub style={requiredFieldStyle}>*</sub>}
            {fieldName?.helpText && fieldName?.helpText != null && (
              <>
                {" "}
                {/* <UncontrolledTooltip placement="top" target={"icon" + index}>
                  {fieldName?.helpText}
                </UncontrolledTooltip> */}
                <i
                  title={fieldName?.helpText}
                  className="bx bx-info-circle"
                  id={"icon" + index}
                  style={iButtonStyle}
                ></i>
              </>
            )}
          </Label>
          <input
            name={fieldName?.name}
            className="form-control"
            type="date"
            defaultValue={defaultDate}
            id="example-time-input"
            onChange={onChangeInput}
            required={fieldName?.required}
          />
        </Col>
      );
    } else if (fieldName.type === "selectDate&Time") {
      return (
        <Col
          xs={12}
          md={12}
          sm={12}
          lg={fieldName?.width || 12}
          key={index}
          className="mb-4"
        >
          <Label style={{ display: "flex", alignItems: "center" }}>
            {fieldName?.label}{" "}
            {fieldName?.required && <sub style={requiredFieldStyle}>*</sub>}
            {fieldName?.helpText && fieldName?.helpText != null && (
              <>
                {" "}
                {/* <UncontrolledTooltip placement="top" target={"icon" + index}>
                  {fieldName?.helpText}
                </UncontrolledTooltip> */}
                <i
                  title={fieldName?.helpText}
                  className="bx bx-info-circle"
                  id={"icon" + index}
                  style={iButtonStyle}
                ></i>
              </>
            )}
          </Label>
          <input
            name={fieldName?.name}
            className="form-control"
            type="datetime-local"
            defaultValue={moment(defaultValue?.[fieldName?.name]).format(
              "YYYY-MM-DDTHH:MM"
            )}
            id="example-datetime-local-input"
            onChange={onChangeInput}
            required={fieldName?.required}
          />
        </Col>
      );
    } else if (fieldName.type === "file") {
      return (
        <Col
          xs={12}
          md={12}
          sm={12}
          lg={fieldName?.width || 12}
          key={index}
          className="mb-4"
        >
          <Label style={{ display: "flex", alignItems: "center" }}>
            {fieldName?.label}{" "}
            {fieldName?.required && <sub style={requiredFieldStyle}>*</sub>}
            {fieldName?.helpText && fieldName?.helpText != null && (
              <>
                {" "}
                {/* <UncontrolledTooltip placement="top" target={"icon" + index}>
                  {fieldName?.helpText}
                </UncontrolledTooltip> */}
                <i
                  title={fieldName?.helpText}
                  className="bx bx-info-circle"
                  id={"icon" + index}
                  style={iButtonStyle}
                ></i>
              </>
            )}
          </Label>
          <Input
            type={fieldName.type}
            className="form-control"
            name={fieldName?.name}
            onChange={onChangeFileValue}
            required={fieldName?.required}
          />
        </Col>
      );
    } else if (fieldName.type === "multifield") {
      return (
        <Col
          xs={12}
          md={12}
          sm={12}
          lg={fieldName?.width || 12}
          key={index}
          className="mb-4"
        >
          <Label style={{ display: "flex", alignItems: "center" }}>
            {fieldName?.label}{" "}
            {fieldName?.required && <sub style={requiredFieldStyle}>*</sub>}
            {fieldName?.helpText && fieldName?.helpText != null && (
              <>
                {" "}
                {/* <UncontrolledTooltip placement="top" target={"icon" + index}>
                  {fieldName?.helpText}
                </UncontrolledTooltip> */}
                <i
                  title={fieldName?.helpText}
                  className="bx bx-info-circle"
                  id={"icon" + index}
                  style={iButtonStyle}
                ></i>
              </>
            )}
          </Label>
          <Form
            labelCol={{
              span: 6,
            }}
            wrapperCol={{
              span: 25,
            }}
            form={form}
            name=""
            style={
              {
                // maxWidth: 600,
              }
            }
            autoComplete="off"
            initialValues={fieldName?.initialValue}
            onChange={(daa) => {
              console.log(form?.getFieldsValue());
            }}
            onValuesChange={(dd) => {
              console.log(form?.getFieldsValue(), "jhjhjh", dd);
            }}
            onFieldsChange={(dd) => {
              console.log(form?.getFieldsValue(), "sdsds", dd);
            }}
          >
            <Form.List
              name={fieldName?.name}
              id={fieldName?.id}
              required={fieldName?.required}
            >
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    rowGap: 10,
                    flexDirection: "column",
                  }}
                >
                  {fields.map((field, index) => (
                    <Card
                      size="small"
                      title={`${fieldName?.name || "Item"} ${index + 1}`}
                      key={field.key}
                      extra={
                        <CloseOutlined
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      }
                    >
                      {/* <Form.Item label="Mode" name={[field.name, "name"]}>
                        <Input />
                      </Form.Item> */}

                      {fieldName?.fields &&
                        fieldName?.fields?.map((dataField, dataFieldindex) => (
                          <>
                            <Form.Item
                              // label={dataField?.label}
                              style={{ margin: "0" }}
                              name={[fieldName.name, `${dataField?.name}`]}
                            >
                              {formField({
                                ...dataField,
                                name: [field.name, `${dataField.name}`],
                                id: [field.id, dataField.id],
                                key: field.key + dataFieldindex,
                                mainName: fieldName?.name,
                              })}
                            </Form.Item>
                          </>
                        ))}
                    </Card>
                  ))}

                  <AntDBtn type="dashed" onClick={() => add()} block>
                    + Add more Root
                  </AntDBtn>
                </div>
              )}
            </Form.List>
          </Form>
        </Col>
      );
    }else if (fieldName.type === "multiInput") {
      return (
        <Col
          xs={12}
          md={12}
          sm={12}
          lg={fieldName?.width || 12}
          key={index}
          className="mb-4"
        >
          <Label style={{ display: "flex", alignItems: "center" }}>
            {fieldName?.label}{" "}
            {fieldName?.required && <sub style={requiredFieldStyle}>*</sub>}
            {fieldName?.helpText && fieldName?.helpText != null && (
              <>
                {" "}
                <i
                  title={fieldName?.helpText}
                  className="bx bx-info-circle"
                  id={"icon" + index}
                  style={iButtonStyle}
                ></i>
              </>
            )}
          </Label>
          <Form
            labelCol={{
              span: 6,
            }}
            wrapperCol={{
              span: 25,
            }}
            form={form}
            name=""
            autoComplete="off"
            initialValues={fieldName?.initialValue}
            // onChange={(daa) => {
            //   console.log("1",form?.getFieldsValue());
            // }}
            // onChange={()=>{
            //   onChangeMultiInput(form?.getFieldsValue())
            // }}
            // onValuesChange={(dd) => {
            //   console.log("2", dd);
            // }}
            onFieldsChange={(dd) => {
              // console.log("3",form?.getFieldsValue(), "sdsds", dd);
              onChangeMultiInput(form?.getFieldsValue())
            }}
          >
            <Form.List
              name={fieldName?.name}
              id={fieldName?.id}
              required={fieldName?.required}
            >
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    rowGap: 10,
                    flexDirection: "column",
                  }}
                >
                  
                  {fields.map((field, index) => (
                    <Card
                      size="small"
                      title={`${fieldName?.name || "Item"} ${index + 1}`}
                      key={field.key}
                      extra={
                        <CloseOutlined
                          onClick={() => {
                    
                            // let data = form?.getFieldsValue();
                            // let arr = data?.["Add SKU"];
                            // arr.filter((_, index) => index !== field.name);
                            // console.log("arr", arr);
                            // console.log("formvalues", form?.getFieldsValue(), field.name);
                            remove(field.name);
                          }}
                        />
                      }
                    >
                      <Form.Item label="Name" name={[field.name, "name"]}
                      rules={[
                        { required: true, message: "Please enter name of sku" },
                      ]}
                      >
                        <Input 
                        placeholder="Enter name of sku"
                        defaultValue={
                          defaultValue &&
                          defaultValue !== "null" &&
                          defaultValue !== "undefined"
                            ? defaultValue?.[field?.name] || ""
                            : null
                        }
                        />
                      </Form.Item>
                      <Form.Item label="Type" name={[field.name, "type"]}
                      rules={[
                        { required: true, message: "Please enter type of sku" },
                      ]}
                      >
                        <Input placeholder="Enter type of sku"
                        />
                      </Form.Item>
                      <Form.Item label="Sub-type" name={[field.name, "subtype"]}
                      rules={[
                        { required: true, message: "Please enter sub-type of sku" },
                      ]}
                      >
                        <Input 
                        placeholder="Enter sub-type of sku"
                        />
                      </Form.Item>
                      <Form.Item label="Total production" name={[field.name, "quantity"]}
                      rules={[
                        { required: true, message: "Please enter quantity of sku" },
                      ]}
                      >
                        <Input 
                        placeholder="Enter total production"
                        />
                      </Form.Item>
                      <Form.Item label="Total revenue generated" name={[field.name, "revenue"]}>
                        <Input placeholder="Enter total revenue generated"
                        />
                      </Form.Item>

                      {fieldName?.fields &&
                        fieldName?.fields?.map((dataField, dataFieldindex) => (
                          <>
                            <Form.Item
                              // label={dataField?.label}
                              style={{ margin: "0" }}
                              name={[fieldName.name, `${dataField?.name}`]}
                            >
                              {formField({
                                ...dataField,
                                name: [field.name, `${dataField.name}`],
                                id: [field.id, dataField.id],
                                key: field.key + dataFieldindex,
                                mainName: fieldName?.name,
                              })}
                            </Form.Item>
                          </>
                        ))}
                    </Card>
                  ))}

                  <AntDBtn type="dashed" onClick={() => add()} block>
                    + Add more SKUs
                  </AntDBtn>
                </div>
              )}
            </Form.List>
          </Form>
        </Col>
      );
    }
     else {
      return null;
    }
  };
  return (
    <>
      {modalType == "formModal" ? (
        <Col sm={6} md={4} xl={4} className="jdbhjfdf">
          <Modal isOpen={show} className="dfdfd" style={{ zIndex: 800 }}>
            <div className="modal-body">
              <h5>{modalTitle}</h5>
            </div>
            <div>
              <form
                className="needs-validation"
                onSubmit={onSubmitForm}
                style={{
                  minHeight: minHeight,
                  maxHeight: "550px",
                  overflowX: `${scrollCard == "none" ? "none" : "hidden"}`,
                  overflowY: `${scrollCard}`,
                }}
              >
                <Row>
                  <Col md="12">
                    <div className="mb-3 px-3">
                      {data?.map((fieldName, index) => {
                        if (
                          fieldName?.isShow(
                            editDefaultorInput
                              ? extrakeyForedit != null
                                ? Object.keys(inputData)?.length > 0
                                  ? inputData
                                  : defaultValue
                                : defaultValue
                              : inputData,
                            defaultValue
                            // editDefaultorInput
                            //   ? Object.keys(inputData)?.length > 0
                            //     ? extrakeyForedit == null
                            //       ? inputData
                            //       : defaultValue
                            //     : defaultValue
                            //   : inputData
                          )
                        ) {
                          return formField(fieldName, index);
                        }
                      })}
                    </div>
                  </Col>
                </Row>

                {viewModel}

                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={onCloseModal}
                    className="btn btn-primary waves-effect"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  {isSubmitButtonVisible && (
                    <button
                      type="submit"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      Submit
                    </button>
                  )}
                </div>
              </form>
            </div>
          </Modal>
          {/* </div> */}
        </Col>
      ) : modalType == "antdModal" ? (
        <>
          <Col sm={6} md={4} xl={4}>
            <Modal isOpen={show}>
              <div className="modal-body">
                <h5>{modalTitle}</h5>
              </div>
              <div>
                <AntdForm
                  formFields={data}
                  form={form}
                  formData={defaultValue}
                  dynamicOption={option}
                  changedFields={(key, value) => {
                    const ff = form?.getFieldsValue();

                    setInputData((prev) => ({ ...prev, [key]: value }));
                  }}
                  onSubmit={onSubmit}
                />
                <button
                  type="button"
                  onClick={onCloseModal}
                  className="btn btn-primary waves-effect"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </Modal>
          </Col>
        </>
      ) : (
        <div style={{ margin: "10px 0px 0px 0px" }}>
          {/* <div>
            <h5>{modalTitle}</h5>
          </div> */}
          <FormForModal
            onSubmitForm={onSubmitForm}
            data={data}
            viewModel={viewModel}
            isSubmitButtonVisible={isSubmitButtonVisible}
            minHeight={minHeight}
            onCloseModal={onCloseModal}
            formField={formField}
            inputData={inputData}
            isCloseButtonVisible={isCloseButtonVisible}
            submitBtnName={submitBtnName}
            fieldSize={fieldSize}
            labelSize={labelSize}
            submitBtnPosition={submitBtnPosition}
            modalType={modalType}
            scroll={scrollCard}
          />
        </div>
      )}
    </>
  );
};

// export default CustomModalMain;
export default connect(null)(
  GoogleApiWrapper({
    apiKey: process.env.REACT_APP_MAP_KEY,
    LoadingContainer: LoadingContainer,
    v: "3",
  })(CustomModalMain)
);
