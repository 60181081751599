// import { SET_ADMIN_DASHBOARD_DETAIL } from "./actionTypes";

// const adminDashboardDetailData = (data = [], action) => {
//   switch (action.type) {
//     case SET_ADMIN_DASHBOARD_DETAIL:
//       console.warn("SET_ADMIN_DASHBOARD_DETAIL condition ", action);
//       return action.data;
//     default:
//       return data;
//   }
// };

// export default adminDashboardDetailData;

import {
  DELETE_ADMIN_DASHBOARD_FAILURE,
  DELETE_ADMIN_DASHBOARD_START,
  DELETE_ADMIN_DASHBOARD_SUCCESS,
  GET_ADMIN_DASHBOARD_FAILURE,
  GET_ADMIN_DASHBOARD_START,
  GET_ADMIN_DASHBOARD_SUCCESS,
} from "./actionTypes";

const initialState = {
  users: [],
  loading: false,
  error: null,
};

const adminDashboardDetailData = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMIN_DASHBOARD_START:
    case DELETE_ADMIN_DASHBOARD_START:
      return { ...state, loading: true };
    case GET_ADMIN_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      };
    // return action.data;

    case DELETE_ADMIN_DASHBOARD_SUCCESS:
      const newData = state?.users?.data?.filter(
        (itm) => itm?.id != action?.payload?.payload
      );
      return {
        ...state,
        loading: false,
        users: {
          ...state?.users,
          length: newData?.length,
          data: newData,
        },
      };

    case GET_ADMIN_DASHBOARD_FAILURE:
    case DELETE_ADMIN_DASHBOARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };

    default:
      return state;
  }
};
export default adminDashboardDetailData;
