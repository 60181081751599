import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { GoogleApiWrapper, Map, Marker, Polyline } from "google-maps-react";
import { Card, CardBody, Col, ListGroup, Row } from "reactstrap";
import { connect } from "react-redux";
import useHttp from "../../components/Hook/Use-http";
import MapTruck from "../../assets/images/MapTruck.png";
import hold from "../../assets/images/break_mark.svg";
import Location_Blue from "../../assets/images/Location_Blue.svg";
import Location_Red from "../../assets/images/Location_Red.svg";
import moment from "moment/moment";
import ReactSwitch from "react-switch";
import {
  Offsymbol,
  OnSymbol,
  notifications,
  replaceId,
} from "../Utility/function";
import CONSTANT from "../Utility/constnt";
let breakPoint = [];
const LoadingContainer = () => <div></div>;

const TrackMap = (props) => {
  const API_CALL = useHttp();
  const [completeRoute, setCompleteRoute] = useState([]);
  const [tripStep, setTripStep] = useState(1);
  const [showLabel, setShowLabel] = useState(true);
  const [routesBounds, setRoutesBounds] = useState(); // Google API response path

  const startAndEndLocation = async (
    track,
    sourceLatitude,
    sourceLogtitude,
    destinationLatitude,
    destinationLongtitude
  ) => {
    // Prepare Path
    const trackPath = [];
    let type = 1;
    let PreviousLocation = {};
    breakPoint = [];

    trackPath.push({
      loc: { lat: sourceLatitude, lng: sourceLogtitude },
      time: moment(props?.selectTripData?.createdAt)
        .local()
        .format("ddd MMM DD, YYYY hh:mm a"),
      address: props?.selectTripData?.detailedAddress,
      id: props?.selectTripData?.id,
      type,
    });

    track.map((val) => {
      if (val?.latitude != null && val?.longtitude != null) {
        if (
          PreviousLocation.latitude == val?.latitude &&
          PreviousLocation.longtitude == val?.longtitude
        ) {
          breakPoint.push({
            loc: { lat: val?.latitude, lng: val?.longtitude },
            time: moment(val?.createdAt)
              .local()
              .format("ddd MMM DD, YYYY hh:mm a"),
            address: val?.detailedAddress,
            id: val?.id,
            type,
          });
        }

        trackPath.push({
          loc: { lat: val?.latitude, lng: val?.longtitude },
          time: moment(val?.createdAt)
            .local()
            .format("ddd MMM DD, YYYY hh:mm a"),
          address: val?.detailedAddress,
          id: val?.id,
          type,
        });

        PreviousLocation = {
          latitude: val?.latitude,
          longtitude: val?.longtitude,
        };
        type = 1;
      } else {
        type = 2;
      }
    });

    trackPath.push({
      loc: { lat: destinationLatitude, lng: destinationLongtitude },
      time: moment(props?.selectTripData?.createdAt)
        .local()
        .format("ddd MMM DD, YYYY hh:mm a"),
      address: props?.selectTripData?.detailedAddress,
      id: props?.selectTripData?.id,
      type,
    });

    // // get Source and destination location
    // var geocoder = new props.google.maps.Geocoder();
    // const mapData =
    //   StartId !== undefined &&
    //   EndId !== undefined &&
    //   (await Promise.all(
    //     [StartId, EndId].map(async (id) => {
    //       const location = await geocoder.geocode({ placeId: id });
    //       try {
    //         return {
    //           loc: {
    //             lat: location?.results[0]?.geometry?.location.lat(),
    //             lng: location?.results[0]?.geometry?.location.lng(),
    //           },
    //         };
    //       } catch (err) {
    //         console.log(err);
    //       }
    //     })
    //   ));

    // if (mapData !== false) {
    // trackPath.unshift(mapData[0]);
    // trackPath.push(mapData[1]);

    var directionsService = new props.google.maps.DirectionsService();
    var directionsRenderer = new props.google.maps.DirectionsRenderer();
    var request = {
      origin: trackPath[trackPath.length - 2].loc,
      destination: trackPath[trackPath.length - 1].loc,
      travelMode: "DRIVING",
    };
    directionsService.route(request, function (response, status) {
      if (status === "OK") {
        directionsRenderer.setDirections(response);
        setRoutesBounds(response.routes[0].overview_path);
      }
    });

    setCompleteRoute(trackPath);
    setTripStep(trackPath.length - 1);
    // }
  };

  useEffect(() => {
    if (props?.selectTripData?.id) {
      setTripStep(0);

      API_CALL.sendRequest(
        replaceId(
          CONSTANT.API.LOCATION.getLocationByTripId,
          props?.TripID || props?.selectTripData?.id
        ),
        MapLocationHandler
      );
    }
  }, [props?.selectTripData]);

  const MapLocationHandler = (res) => {
    // if (res?.data <= 0) {
    //   notify.error("No Tracking Data Received!");
    // }

    if (
      props?.selectTripData.destinationLatitude &&
      props?.selectTripData.destinationLongtitude
    ) {
      startAndEndLocation(
        res?.data,
        props?.selectTripData?.plant?.latitude,
        props?.selectTripData?.plant?.logtitude,
        props?.selectTripData?.destinationPlant?.latitude,
        props?.selectTripData?.destinationPlant?.logtitude
      );
    } else {
      if (!props?.selectTripData.sourceId) {
        notifications.error("Consignor location not assigned");
      } else if (!props?.selectTripData.destinationId) {
        notifications.error("Consignee location not assigned");
      }
    }
  };

  return (
    <div>
      <Row>
        <Col lg={12} style={{ paddingRight: "0" }}>
          <div
            id="gmaps-markers"
            className="gmapsCoustem"
            style={{
              position: "relative",
              // height: ` ${props.TripID ? "80vh" : "50vh"}`,
            }}
          >
            <Row className="mb-5">
              <Col
                xs={12}
                sm={12}
                md={8}
                style={{
                  height: ` ${props.TripID ? "80vh" : "50vh"}`,
                }}
              >
                <Map
                  google={props.google}
                  style={{
                    height: ` ${props.TripID ? "80vh" : "50vh"}`,
                    borderRadius: "10px",
                  }}
                  zoom={8}
                  center={
                    completeRoute[completeRoute.length - 2]?.loc || {
                      lat: 20.8507205,
                      lng: 73.7236507,
                    }
                  }
                  fullscreenControl={false}
                  className="fit-map-wrap"
                >
                  {/* Source Marker */}
                  {completeRoute.length > 0 && (
                    <Marker
                      position={completeRoute[0].loc}
                      icon={{
                        url: Location_Blue,
                        fillColor: "#EB00FF",
                        // scaledSize: new google.maps.Size(60, 60),
                      }}
                    />
                  )}

                  {/* Destination Marker */}
                  {completeRoute.length > 1 && (
                    <Marker
                      position={completeRoute[completeRoute.length - 1].loc}
                      icon={{
                        url: Location_Red,
                        fillColor: "#EB00FF",
                        // scaledSize: new google.maps.Size(60, 60),
                      }}
                    />
                  )}

                  {/* Break */}

                  {!showLabel &&
                    breakPoint.map((item, index) => {
                      return (
                        <Marker
                          key={`break${index}`}
                          shape="MarkerShapeCircle"
                          position={item?.loc}
                          icon={{
                            url: hold,
                            fillColor: "#EB00FF",
                            // scaledSize: new google.maps.Size(60, 60),
                          }}
                        />
                      );
                    })}
                  {tripStep > 0 && (
                    <Marker
                      title={"The marker`s title will appear as a tooltip."}
                      name={"SOMA"}
                      position={completeRoute.map((e) => e.loc)[tripStep - 1]}
                      icon={{
                        url: MapTruck,
                        fillColor: "#EB00FF",
                        // scaledSize: new google.maps.Size(60, 60),
                      }}
                    />
                  )}

                  {/* Truck */}
                  {tripStep > 0 && (
                    <Marker
                      title={"The marker`s title will appear as a tooltip."}
                      name={"SOMA"}
                      position={completeRoute.map((e) => e.loc)[tripStep - 1]}
                      icon={{
                        url: MapTruck,
                        fillColor: "#00000",
                        // scaledSize: new google.maps.Size(60, 60),
                      }}
                    />
                  )}

                  {completeRoute.map((route, index) => {
                    if (index + 2 < completeRoute.length) {
                      return (
                        <Polyline
                          key={"rout" + index}
                          path={[
                            completeRoute[index].loc,
                            completeRoute[index + 1].loc,
                          ]}
                          fillColor={
                            completeRoute[index + 1]?.type === 1
                              ? "#0000FF"
                              : "#000"
                          }
                          fillOpacity={1}
                          strokeColor={
                            completeRoute[index + 1]?.type === 1
                              ? "#0000FF"
                              : "#000"
                          }
                          strokeOpacity={0.8}
                          strokeWeight={4}
                        />
                      );
                    }
                  })}

                  <Polyline
                    path={routesBounds}
                    fillColor="red"
                    fillOpacity={1}
                    strokeColor="red"
                    strokeOpacity={0.8}
                    strokeWeight={4}
                  />
                </Map>
              </Col>
              <Col
                sm={12}
                xs={12}
                md={4}
                style={{
                  height: ` ${props.TripID ? "80vh" : "50vh"}`,
                }}
              >
                <div className="show-details-container ms-sm-3 mt-3 mt-md-0 h-100">
                  <div className="show-details-container-header w-100 d-flex justify-content-between  align-items-center flex-wrap">
                    <div className="flex-nowrap">
                      Carbon emission :{" "}
                      {+(props?.selectTripData?.carbonEmission / 100)?.toFixed(
                        3
                      ) || 0}{" "}
                      tCO
                      <sub>2</sub>e
                    </div>
                    {props?.TripID ? (
                      <i
                        onClick={() => {
                          // props?.setModalData(props?.selectTripData);
                          props?.setIsModalShow((previous) => !previous);
                        }}
                        style={{
                          cursor: "pointer",
                          fontSize: "25px",
                          zIndex: "10000000",

                          color: "white",
                          top: 10,
                        }}
                        className="bx bx-info-circle"
                      ></i>
                    ) : (
                      <></>
                    )}

                    <div className="flex-nowrap">
                      Show labels
                      <ReactSwitch
                        uncheckedIcon={<Offsymbol />}
                        checkedIcon={<OnSymbol />}
                        onColor="#273d91"
                        offColor="#ff0000"
                        className="m-0 ms-1"
                        onChange={() => {
                          setShowLabel((previous) => !previous);
                        }}
                        checked={showLabel}
                      />
                    </div>
                  </div>
                  {completeRoute.length > 2 ? (
                    <ol className="list-group list-group-numbered location-timestamp-list h-100">
                      {completeRoute?.map(
                        (timeStamp, index) =>
                          index !== 0 &&
                          index !== completeRoute.length - 1 && (
                            <li
                              className={`list-group-item d-flex justify-content-between align-items-start px-1 border-0 ${
                                index !== completeRoute.length - 2 &&
                                "border-bottom border-1 border-white"
                              }`}
                            >
                              <div className=" me-auto d-flex">
                                <i className="bx bxs-map me-1 mt-1" />
                                <div class="d-flex flex-column">
                                  <div className="fw-bold">
                                    {timeStamp?.address}
                                  </div>
                                  {moment(timeStamp?.time)
                                    .local()
                                    .format("hh:mm a, dddd MMM DD,YYYY")}
                                </div>
                              </div>
                              {/* <span className="badge bg-primary rounded-pill">14</span> */}
                            </li>
                          )
                      )}
                    </ol>
                  ) : props?.selectTripData?.driver?.consent === "ALLOWED" ? (
                    <>Tracking data not available</>
                  ) : (
                    <>Not in tracking</>
                  )}
                </div>
              </Col>
            </Row>

            {/* <div className="d-none d-sm-block">
              {completeRoute.map((e) => e.loc).length > 0 && (
                <Col className="MapRangeSlider">
                  <div className="p-3">
                    <Card>
                      <CardBody
                        className="py-0 pt-3 text-start"
                        style={{ width: "300px" }}
                      >
                        <p>{completeRoute[tripStep - 1]?.time}</p>
                        <p>{completeRoute[tripStep - 1]?.address}</p>

                        <Slider
                          value={tripStep}
                          min={1}
                          max={completeRoute.length - 1}
                          // labels={tripData.map((e) => e.time)}
                          orientation="horizontal"
                          onChange={(value) => {
                            setTripStep(value);
                          }}
                        />
                      </CardBody>
                    </Card>
                  </div>
                </Col>
              )}
            </div> */}
          </div>
        </Col>
      </Row>
    </div>
  );
};

TrackMap.propTypes = {
  google: PropTypes.object,
};

export default connect(null)(
  GoogleApiWrapper({
    apiKey: process.env.REACT_APP_MAP_KEY,
    LoadingContainer: LoadingContainer,
    v: "3",
  })(TrackMap)
);
