import React, { useEffect, useState } from "react";
import CustomPage from "../../components/Custome/CustomPage";
import {
  DetailsCard,
  getExtraConstPayload,
  isNotEmpty,
  notifications,
  optionArray,
  parseAddress,
  replaceId,
} from "../Utility/function";
import { TeamMasK } from "../Utility/spread-function";
import { Fitsol_Team } from "../Utility/exportValue";
import useHttp from "../../components/Hook/Use-http";
import { role } from "../Utility/option";

import CONSTANT, { TABLE_HEADER, viewRole } from "../Utility/constnt";
import CustomModalMain from "../../components/Custome/CustomModalMain";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteRoleAndAccessStart,
  getPlantStart,
  getRoleAndAccessStart,
} from "../../store/actions";
import { useParams } from "react-router-dom";
import { siteDetails } from "../Utility/models/view-details";

const keyCondition = "business_name";

const RoleAccess = () => {
  const API_CALL2 = useHttp();
  const myData = useSelector((state) => state.userGetMeData);
  const getRolAndAccessData = useSelector(
    (state) => state.roleAndAccessDetailData
  );
  const userRole = localStorage.getItem("userRole");
  const dispatch = useDispatch();
  // const { id } = useParams();
  const getSiteData = useSelector((state) => state.plantDetailData);
  const getClientData = useSelector((state) => state.customerDetailData);
  const [siteData, setSiteData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [changeData, setChangeData] = useState({});
  const [actionData, setActionData] = useState({});
  const [showModel_1, setShowModel_1] = useState(false);
  // const [formModel, setFormModel] = useState(-1);
  // const [viewModelData, setViewModelData] = useState(null);
  // const [errorMessage, setErrorMessage] = useState(null);
  // const [operationChange, setOperationChange] = useState(null);

  useEffect(() => {
    // const apiData = replaceId(CONSTANT.API.getAllPlant, SiteID);
    setTimeout(() => {
      getSiteData?.users?.data?.rows &&
        setSiteData(
          getSiteData?.users?.data?.rows
            ?.filter((el) => el?.client?.name)
            ?.map((data) => {
              return {
                label: `${data?.unitName} (${data?.client?.name})`,
                value: data?.id,
              };
            }) || []
        );

      getClientData?.users?.data?.rows &&
        setClientData(
          getClientData?.users?.data?.rows?.map((data) => {
            return {
              label: `${data?.name}`,
              value: data?.id,
            };
          }) || []
        );
    }, 100);
  }, [myData, getSiteData, getClientData]);

  const onSubmitForm = (payload, setInputData) => {
    API_CALL2.sendRequest(
      replaceId(CONSTANT.API.ROLEANDACCESS.update, actionData?.id),
      () => {
        setShowModel_1(false);
        setActionData(null);
        setInputData({});
        dispatch(getRoleAndAccessStart());
      },
      payload,
      "Password updated"
    );
  };

  const preParePayloadOnSubmit = (editPayload, payload) => {
    const totalData = {
      ...editPayload,
      ...payload,
    };
    delete totalData?.data?.id;

    if (totalData?.role === "siteViewer") {
      delete totalData?.clientId;
      delete totalData?.client;
      delete totalData?.plant;
    }
    if (totalData?.role === "client") {
      delete totalData?.plantId;
      delete totalData?.plant;
      delete totalData?.client;
    }
    if (totalData?.role === "tripCreator") {
      delete totalData?.plantId;
      delete totalData?.plant;
      delete totalData?.clientId;
      delete totalData?.client;
    }
    // console.log(totalData);
    return {
      ...totalData,
    };
  };

  // const verifyHandler = () => {
  //   console.log(viewModelData);

  //   if (
  //     isNotEmpty(viewModelData?.gst) &&
  //     isNotEmpty(viewModelData?.gst?.trim())
  //   ) {
  //     console.log(viewModelData);
  //     API_CALL2.sendRequest(
  //       CONSTANT.API.SITE.addPlantVerifyByGst,
  //       (res) => {
  //         if (res?.data?.Succeeded)
  //           setViewModelData((prev) => {
  //             const resData = res?.data?.Succeeded?.Gst_Details?.result;
  //             const address = parseAddress(resData?.pradr?.adr);
  //             return {
  //               ...prev,
  //               ...resData,
  //               business_name: resData?.tradeNam,
  //               addressLine1: address?.AddressLine1,
  //               addressLine2: address?.City,
  //               city: address?.City,
  //               state: address?.State,
  //               pin_code: address?.Pincode,
  //               pincode: address?.Pincode,
  //               registration_date: resData?.rgdt,
  //               business_type: resData?.ctb,
  //               business_address: resData?.pradr?.adr,
  //               pradr: resData?.pradr?.adr,
  //               gst_status: resData?.sts,
  //               is_aadhar_verified: resData?.adhrVFlag,
  //               is_field_visit_conducted: resData?.isFieldVisitConducted,
  //             };
  //           });
  //         else if (res?.message?.Failed) {
  //           setErrorMessage(res?.message?.Failed?.errorMessage);
  //         }
  //       },
  //       {
  //         GSTN: viewModelData?.gst?.trim(),
  //       }
  //     );
  //   } else {
  //     // setViewModelData((prev) => {

  //     //   return {
  //     //     clientId : id,
  //     //     latitude : prev?.latitude,
  //     //     locationData : prev?.locationData ,
  //     //     locationName : prev?.locationName,
  //     //     logtitude : prev?.logtitude,
  //     //     unitName : prev?.unitName,
  //     //   }
  //     // })
  //     notifications.error("Please Enter Valid GST Number");
  //   }
  // };
  // const getSiteModelSubmit = (payload) => {
  //   console.log(viewModelData, payload);

  //   API_CALL2.sendRequest(
  //     CONSTANT.API.SITE.create,
  //     (res) => {
  //       operationChange.selectedValue((prev) => {
  //         delete prev?.["plantId"];
  //         return {
  //           ...prev,
  //           ["plantId"]: {
  //             label: res?.data?.unitName,
  //             value: res?.data?.id,
  //           },
  //         };
  //       });
  //       operationChange.inputData((prev) => {
  //         delete prev?.["plantId"];
  //         return {
  //           ...prev,
  //           ["plantId"]: res?.data?.id,
  //         };
  //       });
  //       setFormModel(-1);
  //       dispatch(getPlantStart());
  //     },
  //     { ...viewModelData, ...payload }
  //   );
  // };

  return (
    <>
      <div className="page-content">
        <CustomPage
          PAGE={{
            title: "Roles and access",
            dataFunction: (res, Actions) => {
              return TeamMasK(res, Actions, setShowModel_1, setActionData);
            },
          }}
          REDUX={{
            getDataRedux: getRolAndAccessData?.users,
            getApiDataRedux: getRoleAndAccessStart(),
            deleteApiDataRedux: deleteRoleAndAccessStart,
          }}
          API={CONSTANT.API.ROLEANDACCESS}
          FORM={{
            formField:
              localStorage.getItem("userRole") == "client"
                ? CONSTANT.FORM_FIELDS?.CROLETEAM
                : CONSTANT.FORM_FIELDS?.PROLETEAM,
            addFormTitle: "Create access",
            editFormTitle: "Edit access",
            addNewRecordCtaText: "Create access",
            removeFieldOnEdit: ["password"],
            option: {
              plantId: siteData,
              clientId: clientData && clientData,
              role,
            },
            prepareUpdatePayload: preParePayloadOnSubmit,
            selectedValue: false,
            editAllPayloadSubmit: true,
            onSingleChange: (res, selectedValue) => {
              // console.log(res, selectedValue);
              // if (selectedValue?.__isNew__) {
              //   setFormModel({
              //     type: "site",
              //     extra: {
              //       ["unitName"]: selectedValue?.value,
              //       clientId: id,
              //     },
              //     formData: true,
              //   });
              // }
            },
            extraConstPayload:
              localStorage.getItem("userRole") == "client" &&
              getExtraConstPayload({ role: "siteViewer" }),

            onChange: (inputData, selectedValue, operations, defaultValue) => {
              // setOperationChange(operations);
            },
          }}
          TABLE={{
            title: "Roles and access list",
            header: TABLE_HEADER?.team,
            export: {
              fileName: "Fitsol_Team.csv",
              header: Fitsol_Team,
            },
          }}
          //redux
          formData={true}
          editValueInRole={true}
          // changeInputPayload={changeData}
          // setChangeData={setChangeData}
          accessTeam={"roleAccess"}
          editDefaultorInput={true}
          extrakeyForedit={"true"}
        />
      </div>
      {/* <CustomModalMain
        modalType="formModal"
        show={showModel_1}
        close={() => setShowModel_1((prev) => !prev)}
        modalTitle="Reset Password"
        onSubmit={(data, setInputData) => onSubmitForm(data, setInputData)}
        data={CONSTANT.FORM_FIELDS.RESETPASSWORD}
        formData={false}
      /> */}
      {/* {console.log(
        optionArray(clientData, "name", "id"),
        clientData,
        formModel,
        viewModelData
      )}
      {formModel !== -1 && (
        <CustomModalMain
          modalType="formModal"
          show={formModel !== -1}
          close={() => {
            setFormModel(-1);
            setViewModelData(null);
          }}
          // defaultData={formModel.extra}
          extraConstPayload={formModel.extra}
          // onChange={onChange}
          // minHeight={minHeight}
          option={{
            // engineType,
            clientId: clientData,
            // transporterId: optionArray(transporter, "transporterName", "id"),
          }}
          isAutoClose={false}
          onVerifyHandler={verifyHandler}
          isSubmitButtonVisible={
            viewModelData != null
              ? viewModelData?.hasOwnProperty(keyCondition)
              : false
          }
          viewModel={
            <DetailsCard
              viewModelImage={viewModelData?.image || null}
              errorMessage={errorMessage}
              data={viewModelData}
              isCardVisible={
                viewModelData != null
                  ? keyCondition in viewModelData
                    ? true
                    : false
                  : false
              }
              isCardLoading={API_CALL2?.isLoading}
              details={siteDetails}
            />
          }
          modalTitle={"Add Site"}
          onChange={(data, selectedValue, operations, defaultValue) => {
        
            // setOperationChange(operations);
            if (data?.clientId || data?.GST || data?.GST == "") {
           
              setViewModelData((prev) => {
                return {
                  ...prev,
                  gst: data?.GST,
                };
              });
            }
          }}
          onSubmit={getSiteModelSubmit}
          data={CONSTANT.FORM_FIELDS?.SITE}
          // formData={CONSTANT.FORM_FIELDS?.SITE}
        />
      )} */}
    </>
  );
};

export default RoleAccess;
