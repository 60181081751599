import CONSTANT from "./constnt";
import notify from "./coustemFunction";
import { getCurrentIST, notifications, replaceId } from "./function";
import {
  driverMapping,
  transporterMapping,
  vehicleMapping,
} from "./models/mapping-model";
import {
  driverCardDetails,
  transporterCardDetails,
  vehicleViewCardDetails,
} from "./models/view-details";

export const initValue = {
  type: "simBased",
  startDateAndTime: getCurrentIST(),
  targetedDateAndTime: getCurrentIST(),
  status: "2",
  mode: "road",
  otherCharges: 0,
  basicFreightAmount: 0,
  FuelSurcharges: 0,
  docetCharges: 0,
  FOV: 0,
  loadingUnloadingCharges: 0,
  collectionCharges: 0,
  delieveryCharges: 0,
  codDodCharges: 0,
  toPay: 0,
  IGST: 0,
  CGST: 0,
  SGST: 0,
  subTotal: 0,
  total: 0,
};

export const cardBackground = {
  "Loading...": "#ececec",
  ALLOWED: "#caf3d5",
  PENDING: "#f3caca",
};

export const textColor = {
  "Loading...": "#585858",
  ALLOWED: "#038e0c",
  PENDING: "#f53a3a",
};

export const getModelTitle = {
  consignor: "Add new Consignor",
  consignorLocation: "Add new consignor Location",
  transporter: "Add new Transporter",
  driver: "Add new Driver",
  vehicle: "Add new vehicle",
};

export const getVisible = {
  consignor: "Add new Consignor",
  consignorLocation: "Add new consignor Location",
  // transporter: "Add new Transporter",
  transporter: "gst_status",
  driver: "dob",
  vehicle: "registration_number",
};

export const getMappingDetails = {
  consignor: "Add new Consignor",
  consignorLocation: "Add new consignor Location",
  // transporter: "Add new Transporter",
  transporter: transporterMapping,
  driver: driverMapping,
  vehicle: vehicleMapping,
};

export const getVisibleDetails = {
  consignor: "Add new Consignor",
  consignorLocation: "Add new consignor Location",
  // transporter: "Add new Transporter",
  transporter: transporterCardDetails,
  driver: driverCardDetails,
  vehicle: vehicleViewCardDetails,
};

export const getFormDataType = {
  consignor: "Add new Consignor",
  consignorLocation: "Add new consignor Location",
  transporter: "Add new Transporter",
  driver: true,
  vehicle: false,
};

export const getModelFormat = {
  consignor: CONSTANT.FORM_FIELDS.CUSTOMER,
  consignorLocation: CONSTANT.FORM_FIELDS.PLANT,
  transporter: CONSTANT.FORM_FIELDS.TRANSPORTER,
  driver: CONSTANT.FORM_FIELDS.DRIVER,
  vehicle: CONSTANT.FORM_FIELDS.VEHICLESShipmentForm,
};

export const plantSubmitHandler = (
  res,
  setFormModel,
  setPlant,
  setTripForm
) => {
  setFormModel(-1);
  setPlant((previous) => {
    return [...previous, { ...res.data }];
  });
  // dispatch(getPlantStart());
  setTripForm((previous) => {
    return {
      ...previous,
      plantId: res.data.id,
      sourceId: res.data.locationId,
      sourceLocation: res.data.location.toUpperCase(),
    };
  });
};

export const clientSubmitHandler = (
  res,
  setFormModel,
  setClient,
  setTripForm
) => {
  setFormModel(-1);
  setClient((previous) => {
    return [...previous, { ...res.data }];
  });
  setTripForm((previous) => {
    return { ...previous, clientId: res.data.id };
  });
};

export const transporterSubmitHandler = (
  res,
  setFormModel,
  // setTransporter,
  setTripForm,
  setSelectedValue,
  setAllDataLoad
) => {
  setFormModel(-1);
  // setTransporter((previous) => {
  //   return [...previous, { ...res?.data }];
  // });
  setAllDataLoad((pr) => {
    pr?.transporter?.push({ ...res.data });
    //  = [...pr?.vehicle,{ ...res.data }]

    return { ...pr };
  });

  setSelectedValue((pr) => {
    delete pr?.["VehicleNumber"];
    delete pr?.["driver"];
    return {
      ...pr,
      ["transporterId"]: {
        label: res?.data?.transporterName,
        value: res?.data?.id,
      },
    };
  });
  setTripForm((previous) => {
    delete previous?.vehicleId;
    delete previous?.driverId;
    return {
      ...previous,
      transporterId: res?.data?.id,
    };
  });
};

export const mobileNumberResponseHandler = (res, id, API_CALL) => {
  if (res?.data?.Consent?.status)
    API_CALL.sendRequest(replaceId(CONSTANT.API.DRIVER.update, id), () => {}, {
      consent: res?.data?.Consent?.status,
    });
  if (res?.data?.Consent?.status === "ALLOWED") {
    notifications.success("Consent is ALLOWED");
  } else {
    notifications.error("Consent is PENDING");
  }
};

export const driverSubmitHandler = (
  res,
  setFormModel,
  // setDriver,
  setTripForm,
  setSelectedValue,
  setDriverName,
  setViewModelData,
  API_CALL,
  setAllDataLoad,
  setContinues
) => {
  // dispatch(getDriverStart());
  setFormModel(-1);
  setViewModelData(null);
  // setDriver((previous) => {
  //   return [...previous, { ...res.data }];
  // });
  // setDriverSelected({
  //   label: `${res?.data?.mobile} (${res?.data?.name})`,
  //   value: res?.data?.id,
  // });
  setAllDataLoad((pr) => {
    pr?.driver?.push({ ...res.data });
    //  = [...pr?.vehicle,{ ...res.data }]

    return { ...pr };
  });
  setSelectedValue((pr) => {
    return {
      ...pr,
      ["driver"]: {
        label: `${res?.data?.mobile} (${res?.data?.name})`,
        value: res?.data?.id,
      },
    };
  });
  setDriverName({
    name: res?.data?.name,
    mobile: res?.data?.mobile,
  });
  API_CALL.sendRequest(
    replaceId(CONSTANT.API.LOCATION.getLocationConsentApi, res?.data?.mobile),
    (e) => mobileNumberResponseHandler(e, res?.data?.id)
  );
  setTripForm((previous) => {
    return {
      ...previous,
      driverId: res?.data?.id,
      mobile: res?.data?.mobile,
    };
  });
  setContinues(true);
};

export const vehicleSubmitHandler = (
  res,
  setFormModel,
  setViewModelData,
  // setVehicle,
  setSelectedValue,
  setTripForm,
  setAllDataLoad
) => {
  // dispatch(getVehicleStart());
  setFormModel(-1);
  setViewModelData(null);
  // setVehicle((previous) => {
  //   return [...previous, { ...res.data }];
  // });

  setAllDataLoad((pr) => {
    pr?.vehicle?.push({ ...res.data });
    //  = [...pr?.vehicle,{ ...res.data }]

    return { ...pr };
  });
  // setVehicalSelected({
  //   label: res?.data?.registrationNumber,
  //   value: res?.data?.id,
  // });
  setSelectedValue((pr) => {
    delete pr?.["driver"];

    return {
      ...pr,
      ["VehicleNumber"]: {
        label: res?.data?.registrationNumber,
        value: res?.data?.id,
      },
    };
  });
  setTripForm((previous) => {
    delete previous?.driverId;

    return {
      ...previous,
      vehicleId: res.data.id,
      capacity: res.data?.capacity,
      co2PerKm: res.data?.co2PerKm,
    };
  });
};

{
  /* Source Location*/
}
{
  /* {tripForm?.plantId && (
                        <div className="mb-4">
                          <Label>
                            {" "}
                            Consignor Pickup Location{" "}
                            <sub style={{ color: "red" }}>*</sub>
                          </Label>
                          <br />
                          <GooglePlacesAutocomplete
                            apiKey={process.env.REACT_APP_MAP_KEY}
                            apiOptions={{
                              types: ["(cities)"],
                              componentRestrictions: { country: "IN" },
                            }}
                            selectProps={{
                              value: {
                                label: tripForm.sourceLocation,
                                value: { place_id: tripForm.sourceId },
                              },
                              onChange: (e) => {
                                geocodeByPlaceId(e.value.place_id)
                                  .then((results) => {
                                    setTripForm((previous) => {
                                      return {
                                        ...previous,
                                        sourceLatitude:
                                          results[0].geometry.location.lat(),
                                        sourceLogtitude:
                                          results[0].geometry.location.lng(),
                                      };
                                    });
                                  })
                                  .catch((error) => console.error(error));

                                setTripForm((previous) => {
                                  return {
                                    ...previous,
                                    sourceLocation: e.label,
                                    sourceId: e.value.place_id,
                                  };
                                });
                              },
                            }}
                          />
                        </div>
                      )} */
}
{
  /* Delivery Date & Time */
}
{
  /* {tripForm?.destinationId && (
                        <div className="mb-4">
                          <Label>
                            {" "}
                            Expected Delivery Date & Time{" "}
                            <sub style={{ color: "red" }}>*</sub>
                          </Label>
                          <input
                            className="form-control"
                            type="datetime-local"
                            value={moment(
                              `${tripForm.startDateAndTime}`,
                              "YYYY-MM-DDTHH:MM"
                            )
                              .add(ExpetedTime, "hours")
                              .format("YYYY-MM-DD HH:mm")}
                            defaultValue={moment(
                              `${todayDate}`,
                              "YYYY-MM-DDTHH:MM"
                            )
                              .add(ExpetedTime, "hours")
                              .format("YYYY-MM-DD HH:mm")}
                            id="Delivery"
                            onChange={(date) =>
                              setTripForm((previous) => {
                                return {
                                  ...previous,
                                  targetedDateAndTime: date.target.value,
                                };
                              })
                            }
                          />
                        </div>
                      )} */
}
{
  /*Tracking Type*/
}
{
  /* {tripForm?.vehicleId && (
                        <div className="mb-4">
                          <Label htmlFor="validationCustom01">
                            Tracking Type <sub style={{ color: "red" }}>*</sub>
                          </Label>

                          <Select
                            options={trakingMichanisum.map((data) => {
                              return {
                                label: data?.label,
                                value: data?.value,
                              };
                            })}
                            classNamePrefix="select2-selection"
                            onChange={(selected) => {
                              setTripForm((previous) => {
                                return {
                                  ...previous,
                                  type: selected.value,
                                };
                              });
                            }}
                          />
                        </div>
                      )} */
}
{
  /* Package type */
}
{
  /* {tripForm?.driverId && ( */
}
{
  /* <div className="mb-4">
                        <Label htmlFor="validationCustom01">
                          Package type <sub style={{ color: "red" }}>*</sub>
                        </Label>

                        <CreatableSelect
                          options={packageType}
                          classNamePrefix="select2-selection"
                          name={"packageType"}
                          placeholder={"Select or Package type "}
                          type="SingleSelect"
                          required
                          id={"packageType"}
                          onChange={(selected) => {
                            setTripForm((previous) => {
                              return {
                                ...previous,
                                packageType: selected.value,
                              };
                            });
                            packageDatastore();
                          }}
                        />
                      </div> */
}
{
  /* )}  */
}
{
  /* Actual Weight */
}

{
  /* {tripForm?.driverId && (
                        <div className="mb-4">
                          <Label htmlFor="validationCustom01">
                            Freight Weight in Tonne{" "}
                            <sub style={{ color: "red" }}>*</sub>
                          </Label>

                          <input
                            placeholder={"Actual Weight in Tonne"}
                            type={"number"}
                            className="form-control"
                            required
                            id={"weight"}
                            min={1}
                            onChange={(e) =>
                              setTripForm((previous) => {
                                return {
                                  ...previous,
                                  weight: +e.target.value,
                                };
                              })
                            }
                          />
                        </div>
                      )} */
}

{
  /* count */
}
{
  /* {tripForm?.packageSubType && (
                        <div className="mb-4">
                          <Label htmlFor="validationCustom01">
                            Count (1 - 1000){" "}
                            <sub style={{ color: "red" }}>*</sub>
                          </Label>

                          <input
                            placeholder={"count ( 1 - 1000 )"}
                            type={"number"}
                            className="form-control"
                            id={"count"}
                            max={1000}
                            min={0}
                            onChange={(e) =>
                              setTripForm((previous) => {
                                return {
                                  ...previous,
                                  count: +e.target.value,
                                };
                              })
                            }
                          />
                        </div>
                      )} */
}

{
  /*Volumetric Weight */
}
{
  /* {tripForm?.count && (
                        <div className="mb-4">
                          <Label htmlFor="validationCustom01">
                            Volumetric Weight in Tonne{" "}
                          </Label>

                          <input
                            placeholder={"Volumetric Weight in Tonne"}
                            type={"number"}
                            className="form-control"
                            id={"volumetricWeight"}
                            onChange={(e) =>
                              setTripForm((previous) => {
                                return {
                                  ...previous,
                                  volumetricWeight: +e.target.value,
                                };
                              })
                            }
                          />
                        </div>
                      )} */
}
{
  /* Insurance Number */
}
{
  /* {tripForm?.count && (
                        <div className="mb-4">
                          <Label htmlFor="validationCustom01">
                            Insurance Number
                          </Label>

                          <input
                            placeholder={"Insurance Number"}
                            type={"text"}
                            className="form-control"
                            id={"insuranceNumber"}
                            onChange={(e) =>
                              setTripForm((previous) => {
                                return {
                                  ...previous,
                                  insuranceNumber: e.target.value,
                                };
                              })
                            }
                          />
                        </div>
                      )} */
}

{
  /* Basic Freight Amount */
}
{
  /* {tripForm?.count && (
                        <div className="mb-4">
                          <Label htmlFor="validationCustom01">
                            Basic Freight Amount
                          </Label>

                          <input
                            placeholder={"Basic Freight Amount"}
                            type={"number"}
                            className="form-control"
                            id={"basicFreightAmount"}
                            defaultValue={}
                            onChange={(e) =>
                              setTripForm((previous) => {
                                return {
                                  ...previous,
                                  basicFreightAmount: +e.target.value,
                                  // subTotal:
                                  //   previous.subTotal -
                                  //   previous.basicFreightAmount +
                                  //   +e.target.value,
                                };
                              })
                            }
                          />
                        </div>
                      )} */
}

{
  /* Fuel Surcharges */
}
{
  /* {tripForm?.count && (
                        <div className="mb-4">
                          <Label htmlFor="validationCustom01">
                            Fuel Surcharges
                          </Label>

                          <input
                            placeholder={"Fuel Surcharges"}
                            type={"number"}
                            className="form-control"
                            id={"fuelSurcharges"}
                            onChange={(e) =>
                              setTripForm((previous) => {
                                return {
                                  ...previous,
                                  FuelSurcharges: +e.target.value,
                                  subTotal:
                                    previous.subTotal -
                                    previous.FuelSurcharges +
                                    +e.target.value,
                                };
                              })
                            }
                          />
                        </div>
                      )} */
}

{
  /* Docket Charges */
}
{
  /* {tripForm?.count && (
                        <div className="mb-4">
                          <Label htmlFor="validationCustom01">
                            Docket Charges
                          </Label>

                          <input
                            placeholder={"Docket Charges"}
                            type={"number"}
                            className="form-control"
                            id={"docketCharges"}
                            onChange={(e) =>
                              setTripForm((previous) => {
                                return {
                                  ...previous,
                                  docetCharges: +e.target.value,
                                  subTotal:
                                    previous.subTotal -
                                    previous.docetCharges +
                                    +e.target.value,
                                };
                              })
                            }
                          />
                        </div>
                      )} */
}

{
  /* FOV */
}
{
  /* {tripForm?.count && (
                        <div className="mb-4">
                          <Label htmlFor="validationCustom01">FOV</Label>

                          <input
                            placeholder={"FOV"}
                            type={"number"}
                            className="form-control"
                            id={"FOV"}
                            onChange={(e) =>
                              setTripForm((previous) => {
                                return {
                                  ...previous,
                                  FOV: +e.target.value,
                                  subTotal:
                                    previous.subTotal -
                                    previous.FOV +
                                    +e.target.value,
                                };
                              })
                            }
                          />
                        </div>
                      )} */
}

{
  /* Loading/Unloading Charges */
}
{
  /* {tripForm?.count && (
                        <div className="mb-4">
                          <Label htmlFor="validationCustom01">
                            Loading/Unloading Charges
                          </Label>

                          <input
                            placeholder={"Loading/Unloading Charges"}
                            type={"number"}
                            className="form-control"
                            id={"loadingUnloadingCharges"}
                            onChange={(e) =>
                              setTripForm((previous) => {
                                return {
                                  ...previous,
                                  loadingUnloadingCharges: +e.target.value,
                                  subTotal:
                                    previous.subTotal -
                                    previous.loadingUnloadingCharges +
                                    +e.target.value,
                                };
                              })
                            }
                          />
                        </div>
                      )} */
}

{
  /* Collection Charges */
}
{
  /* {tripForm?.count && (
                        <div className="mb-4">
                          <Label htmlFor="validationCustom01">
                            Collection Charges
                          </Label>

                          <input
                            placeholder={"Collection Charges"}
                            type={"number"}
                            className="form-control"
                            id={"collectionCharges"}
                            onChange={(e) =>
                              setTripForm((previous) => {
                                return {
                                  ...previous,
                                  collectionCharges: +e.target.value,
                                  subTotal:
                                    previous.subTotal -
                                    previous.collectionCharges +
                                    +e.target.value,
                                };
                              })
                            }
                          />
                        </div>
                      )} */
}

{
  /* Collection Charges */
}
{
  /* {tripForm?.count && (
                        <div className="mb-4">
                          <Label htmlFor="validationCustom01">
                            Delivery Charges
                          </Label>

                          <input
                            placeholder={"Delivery Charges"}
                            type={"number"}
                            className="form-control"
                            id={"deliveryCharges"}
                            onChange={(e) =>
                              setTripForm((previous) => {
                                return {
                                  ...previous,
                                  delieveryCharges: +e.target.value,
                                  subTotal:
                                    previous.subTotal -
                                    previous.delieveryCharges +
                                    +e.target.value,
                                };
                              })
                            }
                          />
                        </div>
                      )} */
}

{
  /* COD/DOD */
}
{
  /* {tripForm?.count && (
                        <div className="mb-4">
                          <Label htmlFor="validationCustom01">COD/DOD</Label>

                          <input
                            placeholder={"COD/DOD"}
                            type={"number"}
                            className="form-control"
                            id={"deliveryCharges"}
                            onChange={(e) =>
                              setTripForm((previous) => {
                                return {
                                  ...previous,
                                  codDodCharges: +e.target.value,
                                  subTotal:
                                    previous.subTotal -
                                    previous.codDodCharges +
                                    +e.target.value,
                                };
                              })
                            }
                          />
                        </div>
                      )} */
}

{
  /* To Pay */
}
{
  /* {tripForm?.count && (
                        <div className="mb-4">
                          <Label htmlFor="validationCustom01">To Pay</Label>

                          <input
                            placeholder={"To Pay"}
                            type={"number"}
                            className="form-control"
                            id={"toPay"}
                            onChange={(e) =>
                              setTripForm((previous) => {
                                return {
                                  ...previous,
                                  toPay: +e.target.value,
                                  subTotal:
                                    previous.subTotal -
                                    previous.toPay +
                                    +e.target.value,
                                };
                              })
                            }
                          />
                        </div>
                      )} */
}
{
  /* Other, If any */
}
{
  /* {tripForm?.count && (
                        <div className="mb-4">
                          <Label htmlFor="validationCustom01">
                            Other, If any
                          </Label>

                          <input
                            placeholder={"Other, If any"}
                            type={"number"}
                            className="form-control"
                            id={"otherIfAny"}
                            onChange={(e) =>
                              setTripForm((previous) => {
                                return {
                                  ...previous,
                                  otherCharges: +e.target.value,
                                  subTotal:
                                    previous.subTotal -
                                    previous.otherCharges +
                                    +e.target.value,
                                };
                              })
                            }
                          />
                        </div>
                      )} */
}

{
  /* Sub Total */
}
{
  /* {tripForm?.count && (
                        <div className="mb-4">
                          <Label htmlFor="validationCustom01">Sub Total</Label>

                          <input
                            placeholder={"Sub Total"}
                            type={"number"}
                            className="form-control"
                            id={"SubTotal"}
                            disabled
                            value={tripForm.subTotal}
                          />
                        </div>
                      )} */
}

{
  /* IGST */
}
{
  /* {tripForm?.count && (
                        <div className="mb-4">
                          <Label htmlFor="validationCustom01">IGST (%)</Label>

                          <input
                            placeholder={"IGST"}
                            type={"number"}
                            className="form-control"
                            id={"IGST"}
                            onChange={(e) =>
                              setTripForm((previous) => {
                                return {
                                  ...previous,
                                  IGST: +e.target.value,
                                };
                              })
                            }
                          />
                        </div>
                      )} */
}

{
  /* CGST */
}
{
  /* {tripForm?.count && (
                        <div className="mb-4">
                          <Label htmlFor="validationCustom01">CGST (%)</Label>

                          <input
                            placeholder={"CGST"}
                            type={"number"}
                            className="form-control"
                            id={"CGST"}
                            onChange={(e) =>
                              setTripForm((previous) => {
                                return {
                                  ...previous,
                                  CGST: +e.target.value,
                                };
                              })
                            }
                          />
                        </div>
                      )} */
}

{
  /* SGST */
}
{
  /* {tripForm?.count && (
                        <div className="mb-4">
                          <Label htmlFor="validationCustom01">SGST (%)</Label>

                          <input
                            placeholder={"SGST"}
                            type={"number"}
                            className="form-control"
                            id={"SGST"}
                            onChange={(e) =>
                              setTripForm((previous) => {
                                return {
                                  ...previous,
                                  SGST: +e.target.value,
                                };
                              })
                            }
                          />
                        </div>
                      )} */
}

{
  /* Total */
}
{
  /* {tripForm?.count && (
                        <div className="mb-4">
                          <Label htmlFor="validationCustom01">Total</Label>

                          <input
                            placeholder={"Total"}
                            type={"number"}
                            className="form-control"
                            id={"Total"}
                            disabled
                            value={
                              tripForm.subTotal +
                              +(
                                (tripForm.subTotal * tripForm.IGST) /
                                100
                              ).toFixed(2) +
                              +(
                                (tripForm.subTotal * tripForm.CGST) /
                                100
                              ).toFixed(2) +
                              +(
                                (tripForm.subTotal * tripForm.SGST) /
                                100
                              ).toFixed(2)
                            }
                          />
                        </div>
                      )} */
}
