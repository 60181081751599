import React, { useState, useEffect } from "react";
import { Form, Input, Select, Row, Col, Button, Modal, Typography } from "antd";
import CONSTANT from "../../../Utility/constnt";
import { CheckCircleOutlined, SmileOutlined } from "@ant-design/icons";
import useHttp from "../../../../components/Hook/Use-http";
import { useParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
const { Option } = Select;
const { Title, Text } = Typography;
const SingleOnboard = () => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const reportingYear = [
    { label: "2024-25", value: "2024-25" },
    { label: "2023-24", value: "2023-24" },
    { label: "2022-23", value: "2022-23" },
  ];
  const rawMaterialUnits = [
    { label: "Metric tonnes", value: "metricTonnes" },
    { label: "Short tonnes", value: "shortTonnes" },
    { label: "Kg", value: "Kg" },
    { label: "litres", value: "litre" },
  ];
  const currencyOptions = [
    { label: "INR", value: "inr" },
    { label: "USD", value: "usd" },
  ];
  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  const handleReset = () => {
    form.resetFields();
  }
  const role = localStorage.getItem("userRole");
  const { id } = useParams();
  const API_CALL = useHttp();
  const handleFinish = async (values) => {
    let formData = values;
    if (role === "admin") {
      formData = {
        ...formData,
        gstinData:gstDetails,
        clientId: id,
      };
      await API_CALL.sendRequest(
        CONSTANT.API.CONNECTIONS.post,
        (res) => {
            if (res) {
                setIsModalVisible(true);
                form.resetFields();
              }
        },
        formData,
        "Partner onboarded successfully"
      );
    } else if (role === "client") {
      const res = await API_CALL.sendRequest(
        CONSTANT.API.CONNECTIONS.post,
        (res)=>{
            if (res){
                setIsModalVisible(true);
                form.resetFields();
            }
        },
        formData,
        "Partner onboarded successfully"
      );
    }
  };
  const [partners, setPartners] = useState([]);
  const [supplierType, setSupplierType] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const userRole = localStorage.getItem("userRole");
  const [gstDetails, setgstDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [gstNumber, setgstNumber] = useState(null);

  const handleGstNumber = (value) => {
    setgstNumber(value);
  };

  const verifyGST = async () => {
    setLoading(true);
    try {
      await API_CALL.sendRequest(
        CONSTANT.API.TRANSPORTER.addTransporterVerifyByGst,
        (res) => {
          if (res?.data?.Succeeded) {
            setgstDetails(res?.data?.Succeeded?.Gst_Details?.result);
          }
        },
        { GSTN: gstNumber }
      );
    } catch (error) {
      console.error(`Error verifying GST: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (gstDetails) {
      form.setFieldsValue({ 
        gstin: gstDetails.gstin,
        name: gstDetails?.trade_name,
        registeredAddress1: gstDetails?.address?.door_number,
        registeredAddress2: gstDetails?.address?.city,
        registeredState: gstDetails?.address?.state_name,
        registeredPincode: gstDetails?.address?.pincode,
        state: gstDetails?.address?.state_name,
      });
    }
  }, [gstDetails]);


  useEffect(() => {
    const fecthAvailablePartners = async () => {
      try {
        const partners = { ...CONSTANT.API.CLIENT.get };
        API_CALL.getAll([partners.endpoint], (res) => {
          const partnersList = res[0]?.data?.data?.rows?.map((partner) => ({
            label: partner.name,
            value: partner.id,
          }));
          setPartners(partnersList);
        });
      } catch (error) {
        console.error("Error in fetching available partners", error);
      }
    };
    fecthAvailablePartners();
  }, []);
  useEffect(() => {
    const getAvailableSuppliers = async () => {
      try {
        let data;
        if (userRole === "admin") {
          data = { ...CONSTANT.API.ADMIN.getSupplierList };
        } else if (userRole === "client") {
          data = { ...CONSTANT.API.CLIENT.getSupplierList };
        }
        API_CALL.getAll([data.endpoint], (res) => {
          const supplierList = res[0]?.data?.data?.map((supplier) => ({
            label: supplier.categoryOfServiceProvider,
            value: supplier.id,
          }));
          setSupplierType(supplierList);
        });
      } catch (error) {
        console.error("Error in fetching Supplier types", error);
      }
    };
    getAvailableSuppliers();
  }, []);

  const handleSupplierType = (val) => {
    setSelectedSupplier(val);
  };

  const categories = [
    "Automotive Recycling Service Provider",
    "Raw Material Supplier",
    "Supplier type",
    "Inward Transporation Provider",
    "Primary transportation provider",
    "Outward transportation provider",
    "Secondary transportation provider",
    "Tertiary transportation provider",
    "Municipal Recycling Service Provider",
    "Commercial Recycling Service Provider",
    "E-Waste Recycling Service Provider",
    "Scrap Metal Recycling Service Provider",
    "Plastic Recycling Service Provider",
    "Paper and Cardboard Recycling Service Provider",
    "Organic Waste Recycling Service Provider",
    "Construction and Demolition (C&D) Recycling Service Provider",
    "Textile Recycling Service Provider",
    "Battery Recycling Service Provider",
    "Specialized Material Recycling Service Provider",
    "Hazardous Waste Recycling Service Provider",
    "Consumer Product Recycling Service Provider",
    "Cab provider",
    "Bus provider",
    "Real Estate Leasing Companies",
    "Vehicle Leasing Companies",
    "Equipment Leasing Companies",
    "Technology Leasing Firms",
    "Retail Property Management Firms",
    "Warehouse and Storage Leasing Companies",
    "Subsidiaries",
    "Portfolio companies",
  ];

  const categoryList = [...new Set(categories)].map((category) => ({
    label: category,
    value: category.replace(/\s+/g, "_"),
  }));

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "60%",
          backgroundColor: "#fff",
          padding: "40px",
          borderRadius: "8px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <Form form={form} layout="vertical" onFinish={handleFinish}>
          <h2 style={{ textAlign: "center" }}>Partner Onboarding Form</h2>
          {/* <Form.Item
            label="Select the partner to onboard"
            name="connectionToClientId"
          >
            <Select
              options={partners}
              placeholder="Select the partner to onboard"
            />
          </Form.Item> */}
          {/* <Form.Item label="Select Reporting Year" 
          name="reportingYear"
          >
            <Select
              options={reportingYear}
              placeholder="Select reporting year"
            />
          </Form.Item> */}

          {/* <Form.Item label="Enter Service Provider GSTN" 
          name="gstin"
          >
            <Input placeholder="Please enter GSTIN of service provider" />
          </Form.Item> */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Enter Service Provider GSTN"
                name="gstin"
                rules={[
                  {
                    required: true,
                    message: "Please enter service provider GSTN",
                  },
                ]}
              >
                <Input
                  placeholder="Enter GSTN"
                  onChange={(e) => handleGstNumber(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Button
                  key="verify"
                  type="primary"
                  loading={loading}
                  onClick={verifyGST}
                  style={{
                    marginTop: "25px",
                    backgroundColor: "#060DBF",
                    color: "#62C941",
                  }}
                >
                  verify
                </Button>
              </Form.Item>
            </Col>
          </Row>
          {gstDetails && (
            <>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Name of Supplier" name="name">
                    <Input placeholder="Enter supplier name" value="acsdfs"/>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Sector"
                    name="sector">
                    <Select placeholder="Select supplier's sector">
                      <Option value="agriculture">Agriculture</Option>
                      <Option value="automobile">Automobile</Option>
                      <Option value="banking">
                        Banking & Financial Services
                      </Option>
                      <Option value="construction">Construction</Option>
                      <Option value="education">Education</Option>
                      <Option value="healthcare">Healthcare</Option>
                      <Option value="it">Information Technology</Option>
                      <Option value="manufacturing">Manufacturing</Option>
                      <Option value="pharmaceuticals">Pharmaceuticals</Option>
                      <Option value="retail">Retail</Option>
                      <Option value="telecom">Telecommunications</Option>
                      <Option value="textile">Textile</Option>
                      <Option value="transportation">
                        Transportation & Logistics
                      </Option>
                      <Option value="energy">Energy & Utilities</Option>
                      <Option value="realEstate">Real Estate</Option>
                      <Option value="others">Others</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Address 1" name="registeredAddress1">
                    <Input placeholder="Enter  supplier's registered office address" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Address 2" name="registeredAddress2">
                    <Input placeholder="Enter supplier's registered office address" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="State" name="registeredState">
                    <Input placeholder="Enter state"/>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Pincode" name="registeredPincode">
                    <Input 
                    placeholder="Enter supplier's pincode" 
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          <Form.Item
            label="Select Category of Service Providers"
            name="categoryOfServiceProvider"
            rules={[{ 
                required: true, 
                message: "Please select category of service providers" }]}
          >
            <Select
              options={supplierType}
              value={selectedSupplier}
              onChange={handleSupplierType}
              placeholder="Select category of service providers"
            />
          </Form.Item>

          <Form.Item label="Contact Person Details">
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Name"
                  name="pocName"
                  rules={[{ required: true, message: "Please enter name!" }]}
                >
                  <Input placeholder="Enter point of contact name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Email"
                  name="pocEmail"
                  rules={[
                    {
                        required: true,
                        type: "email",
                        message: "Please input a valid email!",
                        pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        validator: (_, value) => {
                          const domain = value ? value.split("@")[1] : "";
                          if (
                            ["gmail.com", "yahoo.com", "hotmail.com"].includes(
                              domain
                            )
                          ) {
                            return Promise.reject(
                              new Error("Please use your corporate email address!")
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                  ]}
                >
                  <Input placeholder="Enter point of contact email" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Phone Number"
                  name="pocPhoneNumber"
                  rules={[
                    { required: true, message: "Please enter phone number!" },
                  ]}
                >
                    <PhoneInput
                  country={"in"}
                  enableSearch={true}
                  containerStyle={{ width: "100%" }}
                  inputStyle={{ width: "100%" }}
                  inputProps={{
                    name: "phone",
                    required: true,
                    autoFocus: true,
                  }}
                />
                  {/* <Input placeholder="Enter point of contact phone number" /> */}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Designation"
                  name="pocDesignation"
                  rules={[
                    { required: true, message: "Please enter designation!" },
                  ]}
                >
                  <Input placeholder="Enter point of contact designation" />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Value of Business Purchased"
                  name="valuePurchased"
                  rules={[
                    {
                      required: true,
                      message: "Please enter value of business purchased",
                    },
                  ]}
                >
                  <Input 
                  placeholder="Enter value of business purchased"
                  type="number"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Currency"
                  name="valuePurchasedCurrency"
                  rules={[
                    { required: true, message: "Please select currency!" },
                  ]}
                >
                  <Select
                    placeholder="Select currency"
                    options={currencyOptions}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item>
            {[2, 3, 4, 5, 6].includes(selectedSupplier) && (
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Enter tonne-km (tonnes * total kms)"
                    name="conditionalValue"
                    rules={[
                      {
                        required: true,
                        message: "Please enter value of tonne-km",
                      },
                    ]}
                  >
                    <Input placeholder="Please enter tonne-km" type="number"/>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Select unit" name="conditionalUnit"
                  
                  >
                    <Select
                      placeholder="Please select the unit"
                      value="tonne-km"
                      options={[{ label: "tonne-km", value: "tonne-km" }]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {selectedSupplier === 1 && (
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Enter amount/weight of goods procured"
                    name="conditionalValue"
                    rules={[
                      {
                        required: true,
                        message: "Please enter amount/weight of goods procured",
                      },
                    ]}
                  >
                    <Input placeholder="Please enter amount/weight of goods procured" type="number"/>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Select unit" name="conditionalUnit">
                    <Select
                      options={rawMaterialUnits}
                      placeholder="Please select the unit"
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {[7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].includes(
              selectedSupplier
            ) && (
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Enter weight/volume of waste recycled"
                    name="conditionalValue"
                    rules={[
                      {
                        required: true,
                        message: "Please enter weight/volume of waste recycled",
                      },
                    ]}
                  >
                    <Input placeholder="Please enter weight/volume of waste recycled"  type="number"/>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Select unit" name="conditionalUnit">
                    <Select
                      options={rawMaterialUnits}
                      placeholder="Please select the unit"
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {[22, 23].includes(selectedSupplier) && (
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Enter km travelled" name="conditionalValue"
                  rules={[
                    {
                      required: true,
                      message: "Please enter value of km travelled",
                    },
                  ]}
                  >
                    <Input placeholder="Please enter km travelled"  type="number"/>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Select unit" name="conditionalUnit">
                    <Select
                      // options={rawMaterialUnits}
                      placeholder="Please select the unit"
                      value="km"
                      options={[{ label: "km", value: "km" }]}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Enter number of passengers travelled"
                    name="passengersTravelled"
                    rules={[
                      {
                        required: true,
                        message: "Please enter value of passengers travelled",
                      },
                    ]}
                  >
                    <Input placeholder="Please enter number of passengers travelled" type="number"/>
                  </Form.Item>
                </Col>
              </Row>
            )}
            {[21, 24].includes(selectedSupplier) && (
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Enter area of leased"
                    name="conditionalValue"
                    rules={[
                      {
                        required: true,
                        message: "Please enter value of area leased",
                      },
                    ]}
                  >
                    <Input placeholder="Please enter area of leased" type="number"/>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Select unit" value="conditionalUnit">
                    <Select
                      // options={rawMaterialUnits}
                      placeholder="Please select the unit"
                      value="km"
                      options={[{ label: "km", value: "km" }]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {[29, 30].includes(selectedSupplier) && (
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Enter percentage holding"
                    name="conditionalValue"
                  >
                    <Input placeholder="Please enter holding percentage" type="number"/>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Select unit" name="conditionalUnit">
                    <Select
                      // options={rawMaterialUnits}
                      placeholder="Please select the unit"
                      value="percentage"
                      options={[{ label: "Percentage", value: "percentage" }]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Form.Item>
          <Row gutter={16} justify="center">
            <Col span={12}>
            <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              style={{
                backgroundColor: "#060DBF",
                color: "#62C941",
              }}
            >
              Onboard partner
            </Button>
          </Form.Item>
            </Col>
            <Col span={6}>
          <Form.Item>
            <Button
            style={{
                backgroundColor: "#060DBF",
                color: "#62C941",
              }}
              onClick={handleReset}
            >
                Reset form
            </Button>
          </Form.Item>
          </Col>
            </Row>
          
        </Form>
      </div>
      <Modal
        title={
          <Title level={3} style={{ textAlign: "center" }}>
            Thank You for onboarding your partner, your partner's POC will recieve a mail for further steps! 
          </Title>
        }
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={[
          <Button
            key="close"
            type="primary"
            onClick={handleModalClose}
            style={{
              backgroundColor: "#060DBF",
              color: "#62C941",
            }}
          >
            Close
          </Button>,
        ]}
        centered
        style={{ textAlign: "center" }}
      >
        <CheckCircleOutlined style={{ fontSize: "50px", color: "#52c41a" }} />
        <p style={{ fontSize: "16px", marginTop: "20px" }}>
          Request for onboarding partner has been submitted successfully!
        </p>
        {/* <SmileOutlined style={{ fontSize: '40px', color: '#52c41a', marginTop: '10px' }} /> */}
      </Modal>
    </div>
  );
};

export default SingleOnboard;
