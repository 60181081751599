import React, { useEffect, useRef, useState } from "react";
import { pcfProductDetailTableFields } from "../../pages/Utility/constnt";
import { Button, Card, Col, Row } from "antd";
import HeaderGen from "./headerGen";
import TableFormFields from "./tableFormFields";
import Loader from "../Loader";
import FooterGen from "./footerGen";

const SingleTabel = (props) => {
  const {
    tableField,
    tabIndex,
    productId,
    continueButton,
    previewButton = () => null,
    multipleTableList = null,
    dynamicOption = {},
    changedFields = () => {},
    onSingleChange = () => {},
    isPreviewButton = false,
  } = props;
  const [tableHeader, setTableHeader] = useState(true);
  // const [formMainData, setFormMainData] = useState([]);
  const containerRef = useRef();
  const [isLoading, setIsLoading] = useState(true);

  const checkLayout = () => {
    const totalGapWidth = 8;

    let totalWidth = 0;
    const rowZeroElement = document.getElementById("row_0");

    totalWidth = tableField?.reduce((sum, el, i) => {
      const tempDiv = document.getElementById("col-0-" + i);
      const tempWidth = tempDiv?.offsetWidth || 0;

      return sum + tempWidth;
    }, 0);

    setTableHeader(rowZeroElement?.offsetWidth >= totalWidth + totalGapWidth);
    setIsLoading(false);
  };

  // useEffect(() => {
  //   changedFields(formMainData);
  // }, [formMainData]);

  useEffect(() => {
    setTimeout(() => {
      checkLayout();
      window.addEventListener("resize", checkLayout);
      return () => {
        window.removeEventListener("resize", checkLayout);
      };
    }, 1000);
  }, [tableField]);

  return (
    <>
      {isLoading ? (
        <div xs={24} className=" w-full">
          <Loader />
        </div>
      ) : (
        <Card style={{ width: "100%" }}>
          <Row className="responsive-table">
            <Col md={24} className="table-Header text-uppercase">
              <Row
                className={`${!tableHeader && "d-none"}  p-3`}
                gutter={[8, 8]}
              >
                <HeaderGen tableFieldList={tableField} />
              </Row>
            </Col>
            <Col md={24} ref={containerRef}>
              <Row
                key={`row_0`}
                id={`row_0`}
                className="p-2 w-full "
                gutter={[8, 8]}
              >
                <TableFormFields
                  dynamicOption={dynamicOption}
                  formFields={tableField}
                  rowIndex="0"
                  tableHeader={tableHeader}
                  changedFields={(
                    index,
                    fieldData,
                    value,
                    multipleIndex,
                    latitude,
                    logtitude
                  ) => {
                    // setFormMainData(res, operations, defaultData);
                    changedFields(
                      index,
                      fieldData,
                      value,
                      multipleIndex,
                      latitude,
                      logtitude
                    );
                  }}
                  multipleTableList={multipleTableList}
                  onSingleChange={onSingleChange}
                  // setFormMainData={setFormMainData}
                />
              </Row>
            </Col>

            {/* footer for continue or previous */}
            <Col xs={24} className="table-border-top">
              <FooterGen
                isPreviewButton={isPreviewButton}
                previewButton={previewButton}
                continueButton={continueButton}
              />
              {/* <Row className="gap-4 p-3 d-flex justify-content-end">
              
                <Col className="">
                  <Button
                    // type="primary"
                    disabled={isPreviewButton}
                    onClick={() => {
                      previewButton();
                    }}
                  >
                    Previous
                  </Button>
                </Col>
                <Col className="">
                  <Button
                    type="primary"
                    onClick={() => {
                      continueButton();
                    }}
                  >
                    Save and Continue
                  </Button>
                </Col>
              </Row> */}
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
};

export default SingleTabel;
