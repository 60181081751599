import { Button, Col, Row, Tooltip, Typography } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import TableFormFields from "./tableFormFields";
import { IoMdClose } from "react-icons/io";
import HeaderGen from "./headerGen";
const { Paragraph } = Typography;

const Multipletable = (props) => {
  const {
    multipleTableList = [],
    rowIndex,
    upDownButton = false,
    ondeleteButton = false,
    onMultipleFormData,
    dynamicOption,
    addNewProcess,
    changedFields,
    multipleIndex,
    setDeleteData,
  } = props;
  const [tableAdd, setTableAdd] = useState([]);
  // const [formMainData, setFormMainData] = useState([]);

  const [open, setOpen] = useState(false);
  const [tableHeader, setTableHeader] = useState(true);
  const findMobileviewShowHeader = multipleTableList?.[0]?.data?.find(
    (el) => el?.fieldShow
  );

  // useEffect(() => {
  //   const tableObj = multipleTableList?.map((el) => {
  //     return {
  //       ...el,
  //       id: `${el?.id}_0`,
  //     };
  //   });
  //   setTableAdd([tableObj]);
  // }, []);

  const onDeleteRow = (tableRow) => {};

  const checkLayout = () => {
    const totalGapWidth = 8;

    let totalWidth = 0;
    const rowZeroElement = document.getElementById("row_0");

    totalWidth = multipleTableList[0]?.data?.reduce((sum, el, i) => {
      const tempDiv = document.getElementById("col-0-" + i);
      const tempWidth = tempDiv?.offsetWidth || 0;

      return sum + tempWidth;
    }, 0);

    setTableHeader(rowZeroElement?.offsetWidth >= totalWidth + totalGapWidth);
  };

  useEffect(() => {
    // Check initial layout
    setTimeout(() => {
      checkLayout();
      // Listen to window resize for dynamic changes
      window.addEventListener("resize", checkLayout);
      // Cleanup on component unmount
      return () => {
        window.removeEventListener("resize", checkLayout);
      };
    }, 1000);
  }, [multipleTableList]);

  const removeField = (index) => {
    const updatedFields = [...tableAdd];

    updatedFields.splice(index, 1);
    setTableAdd(updatedFields);
  };

  return (
    <>
      <Row style={{ width: "100%" }}>
        <Col xs={24} className="table-Headers text-uppercase">
          <Row
            className={` p-3 ${open ? "" : "table-border-bottom"} ${
              !tableHeader && "d-none"
            } `}
            style={{ position: "relative" }}
            gutter={[8, 8]}
          >
            <HeaderGen
              tableFieldList={multipleTableList?.[0]?.data}
              upDownButton={upDownButton}
              open={open}
              setOpen={setOpen}
              tableHeader={tableHeader}
            />
            <Col
              style={{
                position: "absolute",
                right: "-3px",
                alignSelf: "center",
              }}
            >
              <div className="child-table-field-show">
                <i
                  className={`${
                    open ? "bx bx-chevron-up" : "bx bx-chevron-down"
                  }`}
                  style={{ fontSize: "20px" }}
                  onClick={() => {
                    setOpen((pr) => !pr);
                  }}
                ></i>
              </div>
            </Col>
          </Row>
          <Row
            className={` p-3 ${open ? "" : "table-border-bottom"} ${
              tableHeader && "d-none"
            } `}
            gutter={[8, 8]}
          >
            <Col key={"label-1"} className="dsfdf">
              <Row align="middle" justify="start">
                <Paragraph
                  className="m-0"
                  style={{ fontSize: "13px", fontWeight: "600" }}
                >
                  {findMobileviewShowHeader?.label}
                  {/* {findMobileviewShowHeader?.required ? (
                    <sup className="">*</sup>
                  ) : null}{" "} */}
                  {/* {findMobileviewShowHeader?.helpText ? (
                    <Tooltip
                      className="table--label"
                      title={findMobileviewShowHeader?.helpText}
                    >
                      <i
                        className="bx bx-info-circle"
                        id={"icon"}
                        style={{ fontSize: "15px", marginLeft: "2px" }}
                      ></i>
                    </Tooltip>
                  ) : null} */}
                </Paragraph>
              </Row>
            </Col>
            <Col
              style={{
                position: "absolute",
                top: "20px",
                right: "0px",
                alignSelf: "center",
              }}
            >
              <div className="child-table-field-show ddddddd">
                <i
                  className={`${
                    open ? "bx bx-chevron-up" : "bx bx-chevron-down"
                  }`}
                  style={{ fontSize: "20px" }}
                  onClick={() => {
                    setOpen((pr) => !pr);
                  }}
                ></i>
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={24} className={`${open ? "d-none" : ""}`}>
          {multipleTableList?.map((tableRow, index) => (
            <>
              {" "}
              <Row
                className=" p-2"
                key={`row_${index}`}
                gutter={[8, 8]}
                // style={{}}
                style={{
                  borderTop: `${index != 0 ? "1px solid #dbdbdb" : ""}`,
                  position: "relative",
                }}
                id={`row_${index}`}
              >
                {/* <Col xs={24} className="d-flex"> */}
                <TableFormFields
                  formFields={tableRow?.data}
                  rowIndex={rowIndex}
                  multipleIndex={index}
                  tableHeader={tableHeader}
                  ondeleteButton={ondeleteButton}
                  onDeleteRow={onDeleteRow}
                  removeField={removeField}
                  multipleTableList={null}
                  changedFields={changedFields}
                  // setFormMainData={setFormMainData}
                  onMultipleFormData={onMultipleFormData}
                  dynamicOption={dynamicOption}
                />
                {/* </Col> */}

                {/* {tableHeader ? ( */}
                <Col
                  style={{
                    position: "absolute",
                    right: "0px",
                    top: "10px",
                    alignSelf: "center",
                  }}
                >
                  <div
                    className="child-table-field-show"
                    onClick={() => {
                      // onDeleteRow(tableRow);
                      setDeleteData({
                        processIndex: index,
                        partIndex: rowIndex,
                      });
                      // deleteProcess(rowIndex, index);
                    }}
                  >
                    <IoMdClose />
                  </div>
                </Col>
                {/* ) : null} */}
              </Row>
            </>
          ))}
        </Col>
        <Col md={24} className={`${open ? "d-none" : ""}`}>
          <Button
            className="btn hover-color btn-primary waves-effect waves-light"
            onClick={() => {
              addNewProcess(rowIndex);
            }}
          >
            Add Process
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Multipletable;
