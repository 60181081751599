import React, { useEffect, useState } from "react";
import CustomPage from "../../components/Custome/CustomPage";
import CONSTANT, { TABLE_HEADER } from "../Utility/constnt";
import { Link, useParams } from "react-router-dom";
import useHttp from "../../components/Hook/Use-http";
import { createEndPoint, optionArray, replaceId } from "../Utility/function";
import { useSelector } from "react-redux";
import {
  brsrSpocListDataMask,
  globalSpocsListDataMask,
} from "../Utility/spread-function";
import { BiArrowBack } from "react-icons/bi";
// import { spocList } from "../Utility/option";
// to remove this above code

const Spocslist = () => {
  const { id, formId } = useParams();
  const [spocList, setSpocList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const myData = useSelector((state) => state.userGetMeData);
  const role = localStorage.getItem("userRole");
  const [filter, setFilter] = useState("");
  const [spocData, setSpocData] = useState([]);
  const API_CALL = useHttp();
  useEffect(() => {
    // we can filter by departments TODO:
    let brsrendpoint = createEndPoint.get(
      role === "admin" ? `${role}/spoc?clientId=${id}` : `${role}/spoc`
    );
    API_CALL.getAll([brsrendpoint.endpoint], (res) => {
      let data = res[0]?.data?.data?.rows;
      let spocObj = {};
      const departmentList = new Set();
      data?.map((item) => {
        if (item?.clientId == id) {
          departmentList.add(item?.department);
          if (!spocObj[item?.department]) {
            spocObj[item?.department] = [];
          }
          spocObj[item?.department].push({
            label: item?.name,
            value: item?.id,
          });
        }
      });
      let depArr = [];
      for (const item of departmentList) {
        depArr.push({
          label: item,
          value: item,
        });
      }
      setDepartmentList(depArr);
      setSpocList(spocObj);
    });
  }, []);
  return (
    <div className="brsr-page">
      <Link to={-1}>
        <BiArrowBack size={20} />
        <span style={{ marginLeft: "5px" }}>Back to Forms Page</span>
      </Link>
      <CustomPage
        PAGE={{
          title: "BRSR Users List",
          dataFunction: globalSpocsListDataMask,
        }}
        API={{
          ...CONSTANT.API.GLOBAL_SPOCS,
          get: createEndPoint.get(
            role === "admin"
              ? `${role}/globalFormSPOC/getSPOCs/${formId}?clientId=${id}`
              : `${role}/globalFormSPOC/getSPOCs/${formId}`
          ),
          // get: replaceId(CONSTANT.API.GLOBAL_SPOCS.get, formId),
          create: replaceId(CONSTANT.API.GLOBAL_SPOCS.create, formId),

          // create: createEndPoint.post(
          //   role === "admin"
          //     ? `${role}/brsrForm/assignSPOC/${formId}`
          //     : `${role}/brsrForm/assignSPOC/${formId}`
          // ),
          // get: createEndPoint.get(
          //   role === "admin"
          //     ? `${role}/brsrForm/${formId}`
          //     : `${role}/brsrForm/${formId}`
          // ),
        }}
        FORM={{
          formField: CONSTANT.FORM_FIELDS?.BRSR_SPOC_FORM,
          addFormTitle: "Assign user",
          editFormTitle: "Edit form",
          addNewRecordCtaText: "Assign user",
          extraConstPayload: [{ id: "clientId", value: id }],
          // onChange : (data) => {
          //     console.log("Data1",data)
          // },
          onSingleChange: (data) => {
            if (data?.department) {
              let dept = data?.department;
              setSpocData(spocList?.[dept]);
            }
          },
          option: {
            department: departmentList,
            spocId: spocData,
            // spocList
          },

          // viewModel: {
          //   setVerifyData: setSpoc,
          //   viewModelData: spoc,
          //   viewModelLoading: API_CALL?.isLoading,
          //   errorMessage: errorMessage,
          //   onVerifyHandler: verifyHandler,
          // },
        }}
        TABLE={{
          title: `Assigned users`,
          header: TABLE_HEADER?.spocForm,
          //   export: {
          //     fileName: "forms_List.csv",
          //     header: brsrFormHeaderData,
          //   },
        }}
        // editClick={editClick}
        // setEditClick={setEditClick}
        // val={val}
        // setVal={setVal}
      />
    </div>
  );
};

export default Spocslist;

// todos: utility->options spcoclist
