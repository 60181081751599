import React, { useEffect, useState } from "react";
import useHttp from "../../../components/Hook/Use-http";
import { isEmpty, map } from "lodash";

import CONSTANT from "../../Utility/constnt";
import Board from "@asseinfo/react-kanban";

import Breadcrumb from "../../../components/Common/Breadcrumb";

import CardTaskBox from "../../old/Tasks/card-task-box";
import { notifications, replaceId } from "../../Utility/function";
import { Card, CardBody, Col, Row } from "reactstrap";
import CustomModalMain from "../../../components/Custome/CustomModalMain";
import Select from "react-select";
import {
  answerDataType,
  autoDataType,
  // dataType,
  userDataType,
} from "../../Utility/option";
// import notify from "../../Utility/coustemFunction";
import Loader from "../../../components/Loader";

const tasks = [
  {
    id: 1,
    title: "Attribute",
    cards: [
      {
        id: 1,
        name: "Card title 1",
        isRequired: true,
      },
      {
        id: 2,
        name: "Card title 2",
        isRequired: false,
      },
      {
        id: 3,
        name: "Card title 3",
        isRequired: true,
      },
    ],
  },
];
const CategoryForm = () => {
  const API = useHttp();
  const API_CALL2 = useHttp();

  const [attributeOption, setAttributeOption] = useState([]);
  const [filterAttributeOption, setFilterAttributeOption] = useState([]);
  const [attributeOptionForSelect, setAttributeOptionForSelect] = useState([]);

  const [preProcessingData, setPreProcessingData] = useState([]);
  const [preProcessingFunctionCall, setPreProcessingFunctionCall] = useState(
    []
  );
  const [preProcessChangeValue, setPreProcessChangeValue] = useState(null);

  const [dataTypeOption, setDataTypeOption] = useState([]);

  const [categoryScope, setCategoryScope] = useState([]);
  const [selectedCategoryScope, setSelectedCategoryScope] = useState(null);
  const [changeData, setChangeData] = useState({});

  const [controlledBoard, setBoard] = useState({
    columns: [],
  });
  const [flage, setFlage] = useState(false);

  const CardChangeEvent = (card, selectedCard, from, to) => {
    let min = from?.fromPosition;
    let max = to?.toPosition;

    if (from?.fromPosition > to?.toPosition) {
      max = from?.fromPosition;
      min = to?.toPosition;
    } else {
      min = from?.fromPosition;
      max = to?.toPosition;
    }
    const urls = [];
    for (let i = min; i <= max; i++) {
      urls.push({
        url: replaceId(
          CONSTANT.API.SUBCATEGORYATTRIBUTE.update,
          card.columns[0].cards[i].id
        ),
        payload: { index: i },
      });

      card.columns[0].cards[i].index = i;
    }

    API_CALL2.sendBulkRequest(urls, () => {
      setFlage((pr) => !pr);
    });
  };

  const updateAttribute = (id, data) => {
    if (
      data?.attributeId &&
      controlledBoard?.columns[0]?.cards?.find(
        (el) => el?.attributeId === data?.attributeId
      ) !== -1
    ) {
      notifications.error("This attribute is already available in the form");
    } else {
      API.sendRequest(
        replaceId(CONSTANT.API.SUBCATEGORYATTRIBUTE.update, id),
        () => {
          setFlage((pr) => !pr);
        },
        data,
        "Your data was updated successfully"
      );
    }
  };
  useEffect(() => {
    API.sendRequest(CONSTANT.API.CATEGORYFORM.getObjectKey, (res) => {
      setPreProcessingFunctionCall(res?.ObjectKey);
    });
  }, []);

  useEffect(() => {
    selectedCategoryScope != null &&
      API.sendRequest(
        replaceId(
          CONSTANT.API.SUBCATEGORYATTRIBUTE.getSingleFilterSubCategoryAttribute,
          selectedCategoryScope
        ),
        (res) => {
          const rearrangedData = res?.data?.rows?.sort(
            (a, b) => +a.index - +b.index
          );

          setBoard({
            columns: [
              {
                id: 1,
                title: (
                  <p style={{ textAlign: "center", margin: "0px" }}>
                    Attribute
                  </p>
                ),
                cards: [
                  ...rearrangedData?.map((data) => {
                    return {
                      ...data,
                      id: data?.id,
                      attributeId: data?.attributeId,
                      attributeName: data?.attribute?.name,
                      attributeIsRequired: data?.attribute?.isRequired,
                    };
                  }),
                ],
              },
            ],
          });
        }
      );

    API.sendRequest(CONSTANT.API.ATTRIBUTEOPTION.get, (res) => {
      setAttributeOptionForSelect(res?.data?.rows);
    });

    selectedCategoryScope != null &&
      API.sendRequest(
        replaceId(
          CONSTANT.API.PROPROCESSING.getFilterPreProcessing,
          selectedCategoryScope
        ),
        (res) => {
          setPreProcessingData(res?.data?.rows);
        }
      );
  }, [selectedCategoryScope, flage]);

  useEffect(() => {
    const attributeValuesSet = new Set(
      attributeOption?.map((option) => option?.value)
    );

    const filteredCards = new Set(
      controlledBoard?.columns[0]?.cards.map((card) => card?.attributeId)
    );

    const filterId = [...attributeValuesSet].filter(
      (item) => !filteredCards.has(item)
    );

    setFilterAttributeOption(
      attributeOption?.filter((dd) => filterId?.includes(dd?.value))
    );
  }, [attributeOption, controlledBoard]);

  const onSubmit = (data) => {
    data.subCategoryId = selectedCategoryScope;
    if (!data?.isRequired) {
      data.isRequired = false;
    }
    data.index = +controlledBoard?.columns[0]?.cards?.length;
    data["width"] = 12;
    data["extra"] = null;
    API.sendRequest(
      CONSTANT.API.SUBCATEGORYATTRIBUTE.create,
      () => {
        setFlage((pr) => !pr);
      },
      data,
      "Your data was added successfully"
    );
  };

  const ondeleteForm = (id) => {
    API.sendRequest(
      replaceId(CONSTANT.API.SUBCATEGORYATTRIBUTE.delete, id),
      () => {
        setFlage((pr) => !pr);
      },
      null,
      "Your data was deleted successfully"
    );
  };

  const addData = (payload, apiUrl) => {
    API.sendRequest(
      apiUrl,
      () => {
        setFlage((pr) => !pr);
      },
      payload,
      "Your data was added successfully"
    );
  };

  const deleteData = (id, apiUrl) => {
    API.sendRequest(
      replaceId(apiUrl, id),
      () => {
        setFlage((pr) => !pr);
      },
      null,
      "Your data was deleted successfully"
    );
  };

  const data = map(tasks, (task) => ({ ...task, cards: task.tasks }));

  const ControlledBoard = React.memo(() => {
    const handleCardMove = (_card, source, destination) => {
      CardChangeEvent(_card, source, destination);
    };

    return (
      <>
        <Board
          initialBoard={controlledBoard}
          onColumnNew
          onCardNew={console.log}
          onCardDragEnd={CardChangeEvent}
          disableColumnDrag
          renderCard={(e) => (
            <div
              style={{
                width: "400px",
                padding: "5px",
              }}
            >
              <CardTaskBox
                {...e}
                option={attributeOption}
                updateAttribute={updateAttribute}
                ondeleteForm={ondeleteForm}
                addOption={addData}
                deleteOption={deleteData}
                optionForSelect={attributeOptionForSelect}
              >
                {e}
              </CardTaskBox>
            </div>
          )}
        >
          {controlledBoard}
        </Board>
      </>
    );
  });

  useEffect(() => {
    API.sendRequest(CONSTANT.API.ATTRIBUTE.get, (res) => {
      setAttributeOption(
        res?.data?.rows?.map((data) => {
          return {
            label: `${data?.name}`,
            value: data?.id,
          };
        })
      );
    });

    API.sendRequest(CONSTANT.API.SUBCATEGORY.get, (res) => {
      setCategoryScope(
        res?.data?.rows?.map((data) => {
          return {
            label: `${data?.name}`,
            value: data?.id,
          };
        })
      );
    });
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        {/* Render Breadcrumbs */}
        <Breadcrumb title="Tasks" breadcrumbItem="Kanban board" />
        <Card className="task-box">
          <CardBody className="borad-width">
            <div>
              <Select
                options={categoryScope}
                maxMenuHeight={200}
                classNamePrefix="select2-selection"
                onChange={(selected) => {
                  //update
                  setSelectedCategoryScope(selected?.value);
                }}
              />
            </div>
          </CardBody>
        </Card>
        {selectedCategoryScope !== null && (
          <Row>
            <Col sm={5}>
              <Card>
                <CustomModalMain
                  data={CONSTANT.FORM_FIELDS.ADDCATEGORYFORM}
                  defaultData={{}}
                  labelSize={"25%"}
                  submitBtnPosition={"center"}
                  fieldSize={"70%"}
                  submitBtnName="Add attribute"
                  option={{
                    attributeId: filterAttributeOption,
                    definationType: answerDataType,
                    dataType: dataTypeOption,
                  }}
                  changeInputPayload={changeData}
                  onSubmit={onSubmit}
                  isCloseButtonVisible={false}
                  minHeight={"400px"}
                  onChange={(data) => {
                    if (Object.keys(data).length > 0) {
                      const totalData = { ...data };

                      if (
                        totalData?.definationType == "AutoCalculated" &&
                        userDataType?.find(
                          (el) => el?.value == totalData?.dataType
                        )
                      ) {
                        if (
                          userDataType?.find(
                            (el) => el?.value == totalData?.dataType
                          )
                        ) {
                          delete totalData?.dataType;
                          delete totalData?.isRequired;
                        }

                        setChangeData({ ...totalData });
                      }
                      if (
                        totalData?.definationType == "userDefined" &&
                        autoDataType?.find(
                          (el) => el?.value == totalData?.dataType
                        )
                      ) {
                        if (
                          autoDataType?.find(
                            (el) => el?.value == totalData?.dataType
                          )
                        ) {
                          delete totalData?.dataType;
                          // delete totalData?.isRequired;
                        }

                        setChangeData({ ...totalData });
                      }

                      if (data?.definationType) {
                        data?.definationType == "AutoCalculated"
                          ? setDataTypeOption(autoDataType)
                          : setDataTypeOption(userDataType);
                      }
                    }
                  }}
                  modalTitle={"Add attribute"}
                  scrollCard=""
                />
              </Card>

              <Card>
                {/* <CardHeader>Equation</CardHeader> */}
                <CardBody>
                  <h5>Pre-Processing Task</h5>

                  <Row>
                    <div
                      style={{
                        borderRadius: "9px",
                        marginTop: "15px",
                      }}
                    >
                      {preProcessingFunctionCall?.map((data, index) => {
                        return (
                          <div
                            key={data}
                            className="d-flex align-items-center justify-content-between "
                            style={{
                              marginTop: "10px",
                              backgroundColor: "#ededed",
                              padding: "8px",
                            }}
                          >
                            <p className="m-0">{data}</p>
                          </div>
                        );
                      })}
                    </div>
                  </Row>

                  <Row>
                    <Col className="col-9">
                      <input
                        autoComplete="no"
                        name={"equation"}
                        defaultValue={""}
                        placeholder={"Enter equation"}
                        type={"text"}
                        className="form-control"
                        required={true}
                        onChange={(value) => {
                          setPreProcessChangeValue(value?.target?.value);
                        }}
                        style={{ marginTop: "10px" }}
                      />
                    </Col>

                    <Col className="col-2" style={{ marginTop: "10px" }}>
                      <button
                        type="submit"
                        className="btn btn-primary waves-effect waves-light"
                        onClick={() => {
                          preProcessChangeValue !== null
                            ? addData(
                                {
                                  function: preProcessChangeValue,
                                  subCategoryId: selectedCategoryScope,
                                },
                                CONSTANT.API.PROPROCESSING.create
                              )
                            : notifications?.error(
                                "Please fil-up option field!"
                              );
                        }}
                      >
                        Add
                      </button>
                    </Col>
                    <div
                      style={{
                        borderRadius: "9px",
                        marginTop: "15px",
                      }}
                    >
                      {preProcessingData?.map((data, index) => {
                        return (
                          <div
                            key={data?.function}
                            className="d-flex align-items-center justify-content-between "
                            style={{
                              marginTop: "10px",
                              backgroundColor: "#ededed",
                              padding: "8px",
                            }}
                          >
                            <p className="m-0">
                              {index + 1}. {data?.function}
                            </p>
                            <i
                              className="bx bxs-trash-alt"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                deleteData(
                                  data?.id,
                                  CONSTANT.API.PROPROCESSING.delete
                                );
                              }}
                            ></i>
                          </div>
                        );
                      })}
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            {API_CALL2?.isLoading ? (
              <Col sm={5}>
                <Loader />
              </Col>
            ) : (
              <Col>{!isEmpty(data) && <ControlledBoard />}</Col>
            )}
          </Row>
        )}
        {/* )} */}
      </div>
    </React.Fragment>
  );
};

export default CategoryForm;
