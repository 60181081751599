import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { CardBody, Col, Label, Row, Spinner } from "reactstrap";
import CustomModalMain from "../../components/Custome/CustomModalMain";
import Loader from "../../components/Loader";
import Select from "react-select";
import { Button, Card, Form, Input, Typography } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import CONSTANT from "../Utility/constnt";
import {
  getFormFiled,
  notifications,
  replaceId,
  selectedCategoryForm,
  selectedCategorySingleView,
} from "../Utility/function";
import useHttp from "../../components/Hook/Use-http";
import defaultImage from "./OBJECTS.png";
import { unitForStatisanary } from "../Utility/option";
import { emissionEntryCategoryWiseDataLoad } from "../Utility/spread-function";
import SingleUploadRightSide from "../../components/emissionEntry/singleUploadRightSide";
import PagesComingsoon from "../Utility/pages-comingsoon";
import DownStream from "../../components/emissionEntry/singleEntryFormCategory/downStream";
import Electricity from "../../components/emissionEntry/singleEntryFormCategory/electricity";
import StationaryCombustion from "../../components/emissionEntry/singleEntryFormCategory/stationaryCombustion";
import PurchasedGases from "../../components/emissionEntry/singleEntryFormCategory/purchasedGases";
import Services from "../Utility/API/service";
import notify from "../Utility/coustemFunction";

const initHeader = [{ label: "No", field: "no", sort: "asc" }];

const SingleUpload = (props) => {
  const { extraValue } = props;
  const API_CALL = useHttp();
  const API_CALL2 = useHttp();
  const addButtonReff = useRef();
  let formField = [];
  const arr = [];

  const [flag, setFlag] = useState(false);
  const [category, setCategory] = useState(null);
  const [tableCategory, setTableCategory] = useState(null);
  const [carbonEmission, setCarbonEmission] = useState(null);
  const [financialYear, setFinancialYear] = useState("");
  const [emissionBreakdown, setEmissionBreakdown] = useState(null);
  const [gwpBreakdown, setGWPBreakdown] = useState(null);
  const [inputDataForEmission, setInputDataForEmission] = useState(null);
  const [subCategoryAttribute, setSubCategoryAttribute] = useState([
    ...CONSTANT.FORM_FIELDS.ADDSINGLEEMISSIONENTRY,
  ]);
  const [successPreviewdata, setSuccessPreviewdata] = useState(null);
  const [form] = Form.useForm();

  const resetForm = () => {
    setCategory(null);
    setCarbonEmission(null);
    setEmissionBreakdown(null);
    setGWPBreakdown(null);
    setSubCategoryAttribute([...CONSTANT.FORM_FIELDS.ADDSINGLEEMISSIONENTRY]);
  };

  const addEmissionData = (payload) => {
    if (Array.isArray(payload) && payload?.length > 0) {
      const newPayload = payload?.map((el) => {
        return {
          ...el,
          createFlag: true,
        };
      });
      (async () => {
        let axiosArray = [];

        newPayload?.map((el) => {
          const ff = {
            url: CONSTANT.API.CATEGORYFORM.adduploadShipmentData.endpoint,
            newPayload: el,
          };
          axiosArray?.push(ff);
        });

        try {
          const res = await axios
            .all(
              axiosArray.map((endpoint) =>
                Services.post(endpoint?.url, endpoint?.newPayload)
              )
            )
            .then((data) => data);

          let carbon = 0;
          let reffLink = [];

          res?.map((el) => {
            carbon += +el?.data?.result?.find(
              (ll) =>
                ll?.attribute?.id == process.env.REACT_APP_CARBON_EMISSION_KEY
            )?.value;

            reffLink?.push(el?.data?.dataSource);
          });

          setCarbonEmission(carbon);
          setSuccessPreviewdata({
            carbonEmission: carbon,
            subCategory: res[res?.length - 1]?.data?.result?.find(
              (el) => el?.subCategoryAttributesId == "categoryName"
            )?.value,
            refLink: reffLink,
          });
          let successMsg = "";
          if (newPayload.every((item) => item.createFlag == true)) {
            successMsg =
              "Your carbon emission data has been successfully stored.";
          } else {
            successMsg =
              "Congratulations! 🌿 Your request for carbon emission data has been successfully processed. Here are the results for your input.";
          }
          setFlag((pr) => !pr);
          addButtonReff.current.style.display = "none";
          notifications?.success(successMsg);
        } catch (error) {
          console.log(error);
          notifications?.error(error);
        }
      })();
    } else {
      API_CALL2.sendRequest(
        CONSTANT.API.CATEGORYFORM.adduploadShipmentData,
        (res) => {
          addButtonReff.current.style.display = "none";
          setCarbonEmission(
            res?.result?.find(
              (el) =>
                el?.attribute?.id == process.env.REACT_APP_CARBON_EMISSION_KEY
            )?.value
          );
          setEmissionBreakdown(
            res?.result?.find((el) => el?.attribute?.name === "breakdown")
              ?.value
          );
          setGWPBreakdown(
            res?.result?.find(
              (el) => el?.attribute?.name === "GWPbreakdown"
            )?.value
          );
          setFlag((pr) => !pr);
          setSuccessPreviewdata({
            carbonEmission: res?.result?.find(
              (el) =>
                el?.attribute?.id == process.env.REACT_APP_CARBON_EMISSION_KEY
            )?.value,
            subCategory: res?.result?.find(
              (el) => el?.subCategoryAttributesId == "categoryName"
            )?.value,
            refLink: res?.dataSource,
          });
        },
        payload,
        payload?.createFlag
          ? "Your carbon emission data has been successfully stored."
          : "Congratulations! 🌿 Your request for carbon emission data has been successfully processed. Here are the results for your input."
      );
    }
  };

  const findEmissionData = (payload) => {
    API_CALL.sendRequest(
      CONSTANT.API.CATEGORYFORM.adduploadShipmentData,
      (res) => {
        setCarbonEmission(
          res?.result?.find(
            (el) =>
              el?.attribute?.id == process.env.REACT_APP_CARBON_EMISSION_KEY
          )?.value
        );
        setEmissionBreakdown(
          res?.result?.find((el) => el?.attribute?.name === "breakdown")?.value
        );
        setGWPBreakdown(
          res?.result?.find(
            (el) => el?.attribute?.name === "GWPbreakdown"
          )?.value
        );
        setSuccessPreviewdata({
          carbonEmission: res?.result?.find(
            (el) =>
              el?.attribute?.id == process.env.REACT_APP_CARBON_EMISSION_KEY
          )?.value,
          subCategory: res?.result?.find(
            (el) => el?.subCategoryAttributesId == "categoryName"
          )?.value,
          refLink: res?.dataSource,
        });
      },
      payload,
      payload?.createFlag
        ? "Your carbon emission data has been successfully stored."
        : "Congratulations! 🌿 Your request for carbon emission data has been successfully processed. Here are the results for your input."
    );
  };

  const addBulkEmissionData = (payload) => {};

  const findBulkEmissionData = (payload) => {
    (async () => {
      let axiosArray = [];

      payload?.map((el) => {
        const ff = {
          url: CONSTANT.API.CATEGORYFORM.adduploadShipmentData.endpoint,
          payload: el,
        };
        axiosArray?.push(ff);
      });

      try {
        const res = await axios
          .all(
            axiosArray.map((endpoint) =>
              Services.post(endpoint?.url, endpoint?.payload)
            )
          )
          .then((data) => data);

        let carbon = 0;
        let reffLink = [];
        res?.map((el) => {
          carbon += +el?.data?.result?.find(
            (ll) =>
              ll?.attribute?.id == process.env.REACT_APP_CARBON_EMISSION_KEY
          )?.value;

          reffLink?.push(el?.data?.dataSource);
        });

        setCarbonEmission(carbon);
        setSuccessPreviewdata({
          carbonEmission: carbon,
          subCategory: res[res?.length - 1]?.data?.result?.find(
            (el) => el?.subCategoryAttributesId == "categoryName"
          )?.value,
          refLink: reffLink,
        });
        let successMsg = "";
        if (payload.every((item) => item.createFlag == true)) {
          successMsg =
            "Your carbon emission data has been successfully stored.";
        } else {
          successMsg =
            "Congratulations! 🌿 Your request for carbon emission data has been successfully processed. Here are the results for your input.";
        }

        notifications?.success(successMsg);
      } catch (error) {
        console.log(error, error?.response?.data, error?.message);
        notifications?.error(error?.response?.data?.message);
      }
    })();
  };

  useEffect(() => {
    setCarbonEmission(null);
    setEmissionBreakdown(null);
  }, [category]);

  const extraValuePass = {
    setFinancialYear: setFinancialYear,
    setInputDataForEmission: setInputDataForEmission,
    findEmissionData: findEmissionData,
    subCategoryAttribute: subCategoryAttribute,
    setSubCategoryAttribute: setSubCategoryAttribute,
    parentExtraValue: extraValue,
    category: category,
    setCategory: setCategory,
    setCarbonEmission: setCarbonEmission,
    API_CALL: API_CALL,
    setSuccessPreviewdata: setSuccessPreviewdata,
    findBulkEmissionData: findBulkEmissionData,
  };

  const extraValuePassSingle = {
    category: tableCategory,
    initHeader: initHeader,
    flag: flag,
  };

  return (
    // <PagesComingsoon />
    <>
      <Card>
        <CardBody>
          <Row>
            <Col sm={12} md={8} style={{ overflowY: "scroll" }}>
              <div
                className="mb-3 pr-3  "
                style={{
                  paddingRight: "15px",
                  paddingLeft: "15px",
                }}
              >
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  className="mb-4"
                  style={{
                    paddingRight: "1.5rem !important",
                    paddingLeft: "1rem !important",
                  }}
                >
                  <Label style={{ display: "flex", alignItems: "center" }}>
                    {"Category"}{" "}
                    <sub style={{ color: "red", marginLeft: "3px" }}>*</sub>
                  </Label>
                  <Select
                    value={
                      extraValue?.subCategory
                        ?.filter((el) => el?.id === category)
                        .map((el) => ({
                          label: el?.name,
                          value: el?.id,
                        }))?.[0]
                    }
                    options={extraValue?.subCategory?.map((data) => {
                      return {
                        label: data?.name,
                        value: data?.id,
                        categoryId: data?.categoryId,
                        categoryName: data?.category?.name,
                      };
                    })}
                    required={true}
                    maxMenuHeight={200}
                    classNamePrefix="select2-selection"
                    onChange={(selected) => {
                      setCategory(selected?.value);
                    }}
                  />
                </Col>
              </div>
              {selectedCategoryForm(extraValue, category, extraValuePass)}
            </Col>
            <Col sm={12} md={4}>
              {API_CALL?.isLoading ? (
                <div className="mt-5">
                  <Loader />
                </div>
              ) : (
                <>
                  <SingleUploadRightSide
                    extraValue={{
                      carbonEmission: carbonEmission,
                      parentExtraValue: extraValue,
                      financialYear: financialYear,
                      resetForm: resetForm,
                      API_CALL2: API_CALL2,
                      category: category,
                      carbonEmission: carbonEmission,
                      addEmissionData: addEmissionData,
                      inputDataForEmission: inputDataForEmission,
                      addButtonReff: addButtonReff,
                      successPreviewdata: successPreviewdata,
                      emissionBreakdown: emissionBreakdown,
                      gwpBreakdown: gwpBreakdown
                    }}
                  />
                </>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className="mt-4">
        <CardBody>
          <Col
            xs={12}
            md={12}
            sm={12}
            lg={12}
            className="mb-3"
            style={{
              paddingRight: "1.5rem !important",
              paddingLeft: "1rem !important",
            }}
          >
            <Label style={{ display: "flex", alignItems: "center" }}>
              {"Select Category to View Entries"}{" "}
              <sub style={{ color: "red", marginLeft: "3px" }}>*</sub>
            </Label>
            <Select
              value={
                extraValue?.subCategory
                  ?.filter((el) => el?.id === tableCategory)
                  .map((el) => ({
                    label: el?.name,
                    value: el?.id,
                  }))?.[0]
              }
              options={extraValue?.subCategory?.map((data) => {
                return {
                  label: data?.name,
                  value: data?.id,
                  categoryId: data?.categoryId,
                  categoryName: data?.category?.name,
                };
              })}
              required={true}
              maxMenuHeight={200}
              menuPlacement="top"
              classNamePrefix="select2-selection"
              onChange={(selected) => {
                setFlag((pr) => !pr);
                setTableCategory(selected);
              }}
            />
          </Col>
        </CardBody>
      </Card>
      {tableCategory &&
        selectedCategorySingleView(tableCategory, extraValuePassSingle)}
    </>
  );
};

export default SingleUpload;
