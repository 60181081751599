import React, { useEffect, useState } from "react";
import useHttp from "../../components/Hook/Use-http";
import { useDispatch, useSelector } from "react-redux";
import CONSTANT, { MyData } from "../Utility/constnt";
import { getUsersDetailSuccess } from "../../store/actions";
import { Alert, Card, CardBody, Col, Row } from "reactstrap";
import CustomModalMain from "../../components/Custome/CustomModalMain";
import { useLocation, useParams } from "react-router-dom";

const SpocProfile = (props) => {
  const [flag, setFlag] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const API_CALL = useHttp();
  const dispatch = useDispatch();
  const userRole = localStorage.getItem("userRole");
  const location = useLocation();
  const currentpath = location.pathname;

  const { id } = useParams();
  const getSiteData = useSelector((state) => state.plantDetailData);
  //   const clientdata

  const myData = useSelector((state) => state.userGetMeData);

  useEffect(() => {
    if (localStorage.getItem("authUser") && userRole != "admin") {
      API_CALL.sendRequest(CONSTANT.API.ADMIN.getMe, getMeDataHandler);
    }
  }, [flag]);

  useEffect(() => {
    if (userRole && currentpath.includes("/spoc")) {
      id &&
        getMeFindDataHandler(
          getSiteData.users.data?.rows?.find((el) => el?.id == id)
        );
    }
  }, [getSiteData.users.data]);

  const getMeDataHandler = (res) => {
    setUserDetail(res.data);
    dispatch(getUsersDetailSuccess(res?.data));

    MyData.data = res.data;
  };

  const getMeFindDataHandler = (res) => {
    setUserDetail(res);
    dispatch(getUsersDetailSuccess(res));

    MyData.data = res;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">PROFILE</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Welcome {myData?.users?.name}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </Row>

        <Row>
          <Col lg="12">
            {props.error && props.error ? (
              <Alert color="danger">{props.error}</Alert>
            ) : null}
            {props.success && props.success ? (
              <Alert color="success">{props.success}</Alert>
            ) : null}
          </Col>
        </Row>
        <Card>
          <CardBody>
            <CustomModalMain
              data={CONSTANT.FORM_FIELDS[`spoc_PROFILE`]}
              defaultData={userDetail}
              labelSize={"25%"}
              submitBtnPosition={"center"}
              fieldSize={"70%"}
              minHeight={"300px"}
              formData={true}
              isEdit = {false}
              isSubmitButtonVisible={true}
              isCloseButtonVisible={true}
            />
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default SpocProfile;
