import {
  AutoComplete,
  Button,
  Checkbox,
  Col,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Tooltip,
  Upload,
} from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { Label } from "../Custome/Antd-FormFields";

import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";

import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import { IoMdClose } from "react-icons/io";
import Multipletable from "./multipletable";
import { notifications, provideWidthArea } from "../../pages/Utility/function";

const TableFormFields = (props) => {
  const {
    formFields = [],
    dynamicOption = {},
    changedFields = () => {},
    rowIndex = null,
    multipleIndex = null,
    tableHeader = true,
    multipleTableList = [],
    ondeleteButton = false,
    formMainData = [],
    onMultipleFormData = () => {},
    addNewProcess,
    deleteProcess,
    setDeleteData,
    // setOpenTable,
  } = props;

  const [formData, setFormData] = useState({});
  const [defaultData, setDefaultData] = useState({});
  const [multipleFormData, setMultipleFormData] = useState({});

  const [mulTable, setMulTable] = useState(false);
  const operations = {
    inputData: formData,
    setFormData: setFormData,
    setDefaultData: setDefaultData,
  };

  useEffect(() => {
    // changedFields(formData, operations, defaultData);
    onMultipleFormData({ ...multipleFormData, operations });
  }, [formData]);
  useEffect(() => {}, [formMainData]);

  const handleChange = (index, fieldData, value, multipleIndex) => {
    if (fieldData?.type == "GoogleAutoComplete") {
      geocodeByPlaceId(value?.place_id)
        .then((results) => {
          const latitude = results?.[0].geometry.location.lat();
          const logtitude = results?.[0].geometry.location.lng();
          // const keyarr = Object?.keys(data?.latlog);
          // data.latlog[keyarr[0]] = latitude;
          // data.latlog[keyarr[1]] = logtitude;

          changedFields(
            index,
            fieldData,
            value?.description,
            multipleIndex,
            latitude,
            logtitude
          );
        })
        .catch((error) => console.error(error));
    } else {
      changedFields(index, fieldData, value, multipleIndex);
    }
    // onSingleChange({ [fieldData?.id]: value });
    // if (setFormMainData) {
    //   setFormMainData((pr) => {
    //     const updatedFields = pr;
    //     if (multipleIndex === null) {
    //       updatedFields[index] = {
    //         ...updatedFields[index],
    //         [fieldData?.id]: value,
    //       };
    //     } else {
    //       if (!updatedFields[index] || !updatedFields[index]?.multipleTable) {
    //         if (!updatedFields[index]) {
    //           updatedFields[index] = {
    //             ["multipleTable"]: [],
    //           };
    //         } else {
    //           updatedFields[index] = {
    //             ["multipleTable"]: [],
    //             ...updatedFields[index],
    //           };
    //         }
    //       }
    //       updatedFields[index]["multipleTable"][multipleIndex] = {
    //         ...updatedFields[index]["multipleTable"][multipleIndex],
    //         [fieldData?.id]: value,
    //       };
    //     }
    //     setMultipleFormData({
    //       updatedFields,
    //       index,
    //       multipleIndex,
    //       fieldData,
    //       value,
    //     });
    //     setFormData([...updatedFields]);
    //     return updatedFields;
    //   });
    // } else {
    //   setFormData((pr) => {
    //     return {
    //       ...pr,
    //       [fieldData?.id]: value,
    //     };
    //   });
    // }
  };
  const onChangeGoogleAddress = (e, data) => {};

  const getInputFormatFields = (data, index) => {
    switch (data.type) {
      case "select":
        return (
          <>
            <Select
              disabled={data?.disabled && formData[data?.name]}
              showSearch
              mode={
                data?.extraValue?.isSelectMultiple == "multiple"
                  ? "multiple"
                  : ""
              }
              className="select-custom-dropdown"
              loading={data?.isLoading}
              placeholder={data.placeholder ? data.placeholder : ""}
              filterOption={(inputValue, option) => {
                const value = option?.children?.props?.children[0]?.props
                  ?.children
                  ? `${option?.children?.props?.children[0]?.props?.children}`
                  : `${option?.children}`;
                return value
                  ?.toLowerCase()
                  ?.includes(inputValue?.toLowerCase());
              }}
              defaultValue={
                defaultData &&
                defaultData !== "null" &&
                defaultData !== "undefined"
                  ? defaultData?.[data?.name] || ""
                  : null
              }
              status={data?.value === "" || !!!data?.value ? data?.status : ""}
              value={data?.value || null}
              allowClear // Allow clearing the selected value
              onChange={
                // handleChange(index, data)
                (e) => {
                  handleChange(index, data, e, multipleIndex);
                }
              }
              style={{ width: "100%", height: "38px" }}
            >
              {data.option &&
                data.option.length > 0 &&
                data.option.map((item, id) => (
                  <Select.Option
                    key={`role_${id}`}
                    value={item.value}
                    style={{
                      width: "100%",
                      wordWrap: "break-word",
                      borderBottom: "1px solid #e0e0e0",
                    }}
                  >
                    {/* <span>{item.label ? item.label : item.value}</span> */}
                    {item?.helpText ? (
                      //   <Tooltip className="table--label" title={item?.helpText}>
                      //     <i
                      //       className="bx bx-info-circle"
                      //       id={"icon"}
                      //       style={{ fontSize: "15px", marginLeft: "2px" }}
                      //     ></i>
                      //   </Tooltip>
                      <Tooltip title={item?.helpText}>
                        <span>{item.label ? item.label : item.value}</span>
                      </Tooltip>
                    ) : (
                      <span>{item.label ? item.label : item.value}</span>
                    )}
                  </Select.Option>
                ))}

              {dynamicOption?.[data?.id]?.map((item, id) => (
                <Select.Option
                  key={`role_${id}`}
                  value={item.value}
                  style={{ fontSize: "16px", whiteSpace: "nowrap" }}
                >
                  {item.label ? item.label : item.value}
                </Select.Option>
              ))}
            </Select>
          </>
        );
      case "GoogleAutoComplete":
        return (
          <>
            <div
              style={{
                border:
                  (data?.value === "" || !!!data?.value) &&
                  data?.status == "error"
                    ? "1px #ff4d4f solid"
                    : "0px",
                borderRadius: "5px",
              }}
            >
              {" "}
              <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_MAP_KEY}
                apiOptions={{
                  types: ["(cities)"],
                  componentRestrictions: { country: "IN" },
                }}
                value={data?.value || null}
                selectProps={{
                  onChange: (e) => {
                    onChangeGoogleAddress(e, data);
                    handleChange(index, data, e?.value, multipleIndex);
                  },
                  placeholder: (
                    <div className="google-placeholder-custom">
                      {data?.value ? data?.value : data?.placeholder}
                    </div>
                  ),
                  styles: {
                    textInputContainer: {
                      backgroundColor: "red",
                      minHeight: "50px",
                    },

                    input: (provided) => {
                      return {
                        ...provided,
                        color: "#808193",
                        maxWidth: " calc(100% - 8px)",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      };
                    },
                    textInput: {
                      whiteSpace: "nowrap",
                      width: "92%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      color: "orange",
                    },
                    predefinedPlacesDescription: {
                      color: "#1faadb",
                    },
                  },
                }}
              />
              {/* {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <Input {...getInputProps()} />
                    <div>
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => (
                        <div {...getSuggestionItemProps(suggestion)}>
                          {suggestion.description}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </GooglePlacesAutocomplete> */}
            </div>
            {/* {data?.required &&
            data?.status == "error" &&
            (data?.value === "" || !!!data?.value) ? (
              <p className="m-0" style={{ color: "red" }}>
                required
              </p>
            ) : null} */}
          </>
        );
      case "dragupload":
        return (
          <Upload.Dragger
            name={data.name}
            id={data.id}
            disabled={data?.disabled && formData[data?.name]}
            beforeUpload={() => {
              return false;
            }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload.
            </p>
          </Upload.Dragger>
        );
      case "file":
        return (
          <Upload
            name="logo"
            disabled={data?.disabled && formData[data?.name]}
            listType="picture"
            accept={data?.acceptFormat}
            maxCount={1}
            beforeUpload={() => {
              return false;
            }}
          >
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        );
      case "number":
        return (
          <InputNumber
            disabled={data?.disabled && formData[data?.name]}
            placeholder={data.placeholder ? data.placeholder : ""}
            controls={false}
            style={{
              width: "100%",
              height: "38px",
            }}
            // min={0}
            onBlur={(e) => {
              if (e.target.value <= 0) {
                notifications.error(
                  `${data?.label} should not be negative or zero`
                );
                handleChange(index, data, "", multipleIndex);
              }
            }}
            suffix={data?.suffix ? data?.suffix : null}
            status={data?.value === "" || !!!data?.value ? data?.status : ""}
            value={data?.value}
            onChange={(e) => {
              handleChange(index, data, e, multipleIndex);

              // setFormData((pr) => {
              //   return {
              //     ...pr,
              //     [data?.id]: e,
              //   };
              // });
            }}
          />
        );
      case "mobile":
        return (
          <InputNumber
            disabled={data?.disabled && formData[data?.name]}
            placeholder={data.placeholder ? data.placeholder : ""}
            controls={false}
            style={{
              width: "100%",
              height: "38px",
            }}
            value={formData && formData[data.id]}
            pattern="[1-9]{1}[0-9]{9}"
            maxLength={10}
          />
        );
      case "checkbox":
        return (
          <Checkbox disabled={data?.disabled && formData[data?.name]}>
            {data.label}
          </Checkbox>
        );
      case "textarea":
        return (
          <Input.TextArea
            disabled={data?.disabled && formData[data?.name]}
            placeholder={data.placeholder ? data.placeholder : ""}
            initialvalues={
              formData && data.type !== "file" ? formData[data.name] : ""
            }
          />
        );

      case "switch":
        return (
          <Switch
            className="mt-2"
            disabled={
              data?.disabled &&
              (formData[data?.name] === false || formData[data?.name] === true)
            }
            defaultChecked={(formData && formData[data.name]) || false}
          />
        );

      default:
        return (
          <Input
            disabled={data?.disabled && formData[data?.name]}
            placeholder={data.placeholder ? data.placeholder : ""}
            style={{ width: "100%", height: "38px" }}
            // initialvalues={data?.value}
            value={data?.value}
            status={data?.value === "" || !!!data?.value ? data?.status : ""}
            // status={data?.required ? data?.validate(data?.value) : ""}
            suffix={data?.suffix ? data?.suffix : null}
            onChange={(e) => {
              handleChange(index, data, e?.target?.value, multipleIndex);
              // setFormData((pr) => {
              //   return {
              //     ...pr,
              //     [data?.id]: e?.target?.value,
              //   };
              // });
            }}
          />
        );
    }
  };

  const columnWidth = provideWidthArea(formFields?.length);

  return (
    <>
      {/* <Row gutter={[8, 8]} id={`row_${rowIndex}`} className="p-2"> */}

      {formFields
        ?.filter((el) => !Array.isArray(el))
        ?.map((el, i) => (
          <Fragment key={i + 1}>
            {" "}
            <Col
              id={"col-" + rowIndex + "-" + i}
              lg={el?.xl || columnWidth?.lg}
              md={columnWidth?.md}
              xl={el?.xl || columnWidth?.xl}
              xxl={el?.xl || columnWidth?.xxl}
              sm={12}
              xs={24}
              className={`${
                el?.fieldShow && !tableHeader ? "d-none" : ""
              } d-flex flex-column justify-content-between`}
            >
              {!el?.fieldShow ? (
                <>
                  <Col
                    className={`${tableHeader && "d-none"}  table-Label-show`}
                  >
                    <Label data={el} required={el?.required}>
                      {el?.label}
                    </Label>
                  </Col>
                  <Col
                    className=""
                    style={{
                      width: `${
                        ondeleteButton &&
                        tableHeader &&
                        formFields?.length == i + 1
                          ? "85%"
                          : "100%"
                      }`,
                    }}
                  >
                    {getInputFormatFields(el, i)}
                  </Col>

                  {/* {ondeleteButton && formFields?.length == i + 1 ? (
                    tableHeader ? (
                      <Col
                        style={{
                          position: "absolute",
                          right: "0px",
                          top: "3px",
                          alignSelf: "center",
                        }}
                      >
                        <div
                          className="child-table-field-show"
                          onClick={() => {
                            removeField(multipleIndex);
                          }}
                        >
                          <IoMdClose />
                        </div>
                      </Col>
                    ) : null
                  ) : null} */}
                </>
              ) : null}
            </Col>
          </Fragment>
        ))}
      {/* </Row> */}
      {multipleTableList != null && multipleTableList?.length != 0 && (
        <Multipletable
          multipleTableList={multipleTableList}
          rowIndex={rowIndex}
          upDownButton={true}
          ondeleteButton={true}
          onMultipleFormData={onMultipleFormData}
          dynamicOption={dynamicOption}
          addNewProcess={addNewProcess}
          deleteProcess={deleteProcess}
          changedFields={changedFields}
          multipleIndex={multipleIndex}
          setDeleteData={setDeleteData}
        />
      )}

      {multipleTableList != null && multipleTableList?.length == 0 ? (
        <Col className="d-flex align-items-end">
          <Row className="">
            {/* multiple table with multiple fields */}
            <Col className="d-flex align-self-end">
              <Button
                className="btn hover-color btn-primary waves-effect waves-light"
                onClick={() => {
                  addNewProcess(rowIndex);
                }}
              >
                Add Process
              </Button>
            </Col>
          </Row>
        </Col>
      ) : null}
    </>
  );
};

export default TableFormFields;
