import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button, Select, Modal } from "antd";
import CommonRow from "./CommonRow";
import useHttp from "../../../components/Hook/Use-http";
import CONSTANT from "../../Utility/constnt";

const AddEmisionFactor = () => {
  const API_CALL = useHttp();
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedScope, setSelectedScope] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [companies, setCompanies] = useState([]); // State for companies list
  const userRole = localStorage.getItem("userRole");

  const customGlobal = [
    { value: "custom", label: "Custom" },
    { value: "global", label: "Global" },
  ];

  const scopesList = [
    { value: "scope1", label: "Scope 1" },
    { value: "scope2", label: "Scope 2" },
    { value: "scope3", label: "Scope 3" },
  ];

  const scope1List = [
    { label: "Waste management", value: "wasteManagement" },
    { label: "Purchased Gases", value: "purchasedGases" },
    { label: "Stationary combustion", value: "stationaryCombustion" },
    { label: "Mobile combustion", value: "mobileCombustion" },
    { label: "Capital goods", value: "capitalGoods" },
    { label: "Purchased goods & services", value: "purchasedGoods" },
  ];

  const scope2List = [{ label: "Electricity", value: "electricity" }];
  const scope3List = [
    { label: "Transportation mode", value: "transportationMode" },
    { label: "Employee commute", value: "employeeCommute" },
    { label: "Water treatment and discharge", value: "waterTreatment" },
    { label: "Water usage", value: "waterUsage" },
    { label: "Hotel stays", value: "hotelStays" },
  ];

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const client = { ...CONSTANT.API.CLIENT.get }
        API_CALL.getAll(
          [client.endpoint],
          (res) => {
            const companiesList = res[0].data.data.rows.map(company => ({
              label: company.name,
              value: company.id,
            }));
            setCompanies(companiesList);
          }
        );
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setSelectedCategory("");
    setSelectedScope("");
  };

  const handleScope = (selectedScope) => {
    setSelectedScope(selectedScope);
    setSelectedCategory("");
  };

  const handleCategory = (selectedCategory) => {
    setSelectedCategory(selectedCategory);
  };

  const showModal = (values) => {
    setFormValues(values);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    setLoading(true);
    try {
      const formData = {
        ...formValues,
        startDate: formValues.startDate
          ? formValues.startDate.format("YYYY-MM-DD")
          : null,
        endDate: formValues.endDate
          ? formValues.endDate.format("YYYY-MM-DD")
          : null,
      };
      if (userRole === "admin") {
        await API_CALL.sendRequest(
          CONSTANT.API.ADD_EMISSION_FACTORS.create,
          null,
          formData,
          "Your data added successfully"
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setIsModalVisible(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleFinish = (values) => {
    showModal(values);
  };

  const renderFormValues = () => {
    const entries = Object.entries(formValues).map(([key, value]) => (
      <div key={key}>
        <strong>{key}:</strong> {value ? value.toString() : "N/A"}
      </div>
    ));
    return <div>{entries}</div>;
  };

  return (
    <div
      style={{ display: "flex", justifyContent: "center", marginTop: "100px" }}
    >
      <div>
        <p style={{ fontSize: 16, fontWeight: "bold" }}>Add emission factors</p>
        <Form
          name="basic_form"
          initialValues={{ remember: true }}
          onFinish={handleFinish}
          layout="vertical"
          className="mx-auto"
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Emission factor applicability"
                rules={[
                  {
                    required: true,
                    message: "Please select emission factor applicability",
                  },
                ]}
              >
                <Select
                  placeholder="Select option"
                  style={{ width: 500 }}
                  options={customGlobal}
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>
          </Row>

          {selectedOption === "custom" && (
            <Row>
              <Col>
                <Form.Item
                  label="Choose Companies"
                  name="clientId"
                  rules={[{ required: true, message: "Select companies" }]}
                >
                  <Select
                    placeholder="Select companies"
                    style={{ width: 500 }}
                    options={companies} // Use the companies list
                    mode="multiple" // Enable multi-select
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          {selectedOption && (
            <Row>
              <Col>
                <Form.Item
                  label="Choose Scope"
                  name="scope"
                  rules={[{ required: true, message: "Select scope" }]}
                >
                  <Select
                    placeholder="Select scope"
                    style={{ width: 500 }}
                    options={scopesList}
                    onChange={handleScope}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          {selectedScope && (
            <Row>
              <Col>
                <Form.Item name="category">
                  {selectedScope === "scope1" && (
                    <Select
                      placeholder="Select category"
                      style={{ width: 500 }}
                      options={scope1List}
                      onChange={handleCategory}
                    />
                  )}
                  {selectedScope === "scope2" && (
                    <Select
                      style={{ width: 500 }}
                      placeholder="Select category"
                      options={scope2List}
                      onChange={handleCategory}
                    />
                  )}
                  {selectedScope === "scope3" && (
                    <Select
                      style={{ width: 500 }}
                      placeholder="Select category"
                      options={scope3List}
                      onChange={handleCategory}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          )}

          {selectedCategory && (
            <Row>
              <Col>
                <Form.Item>
                  <CommonRow selectedCategory={selectedCategory} />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Form.Item style={{ marginBottom: 0 }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                width: "100%",
                backgroundColor: "#060DBF",
                color: "#62C941",
              }}
              loading={loading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>

        <Modal
          title="Confirm Submission"
          visible={isModalVisible}
          onOk={handleOk}
          okButtonProps={{
            style: {
              backgroundColor: "#060DBF",
              color: "#62C941",
            },
          }}
          onCancel={handleCancel}
          confirmLoading={loading}
        >
          <p>Are you sure you want to submit the following data?</p>
          {renderFormValues()}
        </Modal>
      </div>
    </div>
  );
};

export default AddEmisionFactor;
