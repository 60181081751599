import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from "react-simple-maps";
import { useState, useEffect } from "react";
import useHttp from "../../../../components/Hook/Use-http";
import { replaceId } from "../../../../pages/Utility/function";
import Location_Blue from "../../../../assets/images/Location_Blue.svg";
import CONSTANT from "../../../../pages/Utility/constnt";
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
import { Tooltip } from "antd";

import INDIA_TOPO_JSON from "./india.topo.json";
import { useParams } from "react-router";

const PROJECTION_CONFIG = {
  scale: 930,
  center: [78.9629, 22.5937],
};

const MapComponent2 = (props) => {
  const { tripList, selectedFY, scope } = props;
  const API = useHttp();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [siteData, setSiteData] = useState(null);
  const formattedSelectedFY = selectedFY.join(", ");
  useEffect(() => {
    try {
      const siteId = id;
      API.getAll(
        [replaceId(CONSTANT.API.SITE.getClientPlant, siteId).endpoint],
        (res) => {
          let data = res[0]?.data?.data?.rows;
          const filteredData = data.map((item) => ({
            siteId: item?.id,
            lat: item?.latitude,
            lng: item?.logtitude,
            name: item?.unitName,
          }));
          setFilteredData(filteredData);
        }
      );
    } catch (error) {
      console.error("Error fetching location data:", error);
    }
  }, [id]);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        let tempLocations;
        if (selectedFY.length == 0) {
          setSiteData([]);
        } else {
          tempLocations = tripList.map((trip) => {
            let scopee = trip?.Scope;
            const matchingData = filteredData.find(
              (data) => data?.siteId == trip?.id
            );
            if (matchingData) {
              return {
                coordinates: [matchingData?.lng, matchingData?.lat],
                name: matchingData?.name,
                markerOffset: 10,
                emission: scopee,
              };
            }
          });
          if (tempLocations.length === tripList.length) {
            setSiteData(tempLocations);
            setLoading(false);
          }
        }
      } catch (error) {
        console.error("Error fetching location data:", error);
      }
    };
    fetchLocations();
  }, [tripList, selectedFY]);

  // const markers = [
  //   { markerOffset: -5, name: "Vadodhara", coordinates: [77.209, 28.6139] },
  //   { markerOffset: -10, name: "Becharji", coordinates: [70.4376, 21.5306] },
  // ];

  return (
    <div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <Row>
          <Col
            className="bg-white m-2"
            style={{
              height: "728px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <Card>
              <CardBody>
                <CardSubtitle>
                  Scope 3 Site-Wise Locations
                </CardSubtitle> */}
            <ComposableMap
              projectionConfig={PROJECTION_CONFIG}
              projection="geoMercator"
              width={600}
              height={1000}
            >
              {/* <ZoomableGroup center={[78.9629, 22.5937]} zoom={1}> */}
              <Geographies geography={INDIA_TOPO_JSON}>
                {({ geographies }) =>
                  geographies.map((geo) => (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill="#EAEAEC"
                      stroke="#D6D6DA"
                      style={{
                        default: {
                          //   fill: "#D6D6DA",
                          outline: "none",
                        },
                        hover: {
                          //   fill: "#F53",
                          outline: "none",
                        },
                        pressed: {
                          //   fill: "#E42",
                          outline: "none",
                        },
                      }}
                    />
                  ))
                }
              </Geographies>
              {siteData
                .filter((item) => item)
                .map(({ name, coordinates, markerOffset, emission }, index) => (
                  <Tooltip
                    title={
                      <div>
                        <div>
                          <strong>Site name:</strong> {name}
                        </div>
                        <div>
                          <strong>Scope:</strong> {scope}
                        </div>
                        <div>
                          <strong>Duration: </strong>
                          {formattedSelectedFY}
                        </div>
                        <div>
                          <strong>Emissions:</strong> {emission} tCO2e
                        </div>
                      </div>
                    }
                  >
                    <Marker key={name} coordinates={coordinates}>
                      <circle r={4} fill="#F00" stroke="#fff" strokeWidth={2} />

                      {/* <text
                          textAnchor="middle"
                          y={markerOffset}
                          style={{
                            fontFamily: "system-ui",
                            fill: "#5D5A6D",
                            fontSize: "8px",
                          }}
                        >
                          {name}
                        </text> */}
                    </Marker>
                  </Tooltip>
                ))}
              {/* </ZoomableGroup> */}
            </ComposableMap>
            {/* </CardBody>
            </Card> */}
          </Col>
        </Row>
      )}
    </div>
  );
};

export default MapComponent2;
