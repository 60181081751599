import React, { useEffect, useState } from "react";
import useHttp from "../../../components/Hook/Use-http";
import CONSTANT, { TABLE_HEADER } from "../../Utility/constnt";
import { categoryDataMask } from "../../Utility/spread-function";
import CustomPage from "../../../components/Custome/CustomPage";
import { replaceId } from "../../Utility/function";

const Categorys = () => {
  const [subCategory, setSubCategory] = useState([]);
  const [flag, setFlag] = useState(false);

  const API = useHttp();

  useEffect(() => {
    API.sendRequest(CONSTANT.API.CATEGORY.get, (res) => {
      setSubCategory(res?.data?.rows);
    });
  }, []);

  const updateVisiblity = (selected, id) => {
    API.sendRequest(
      replaceId(CONSTANT.API.SUBCATEGORY.update, id),
      () => {
        setFlag((pr) => !pr);
      },
      { isVisible: selected },
      "Data updated successfully"
    );
  };

  return (
    <>
      {" "}
      <div className="page-content">
        <CustomPage
          // API
          PAGE={{
            title: "Category",
            pageRefresh: {
              flag: flag,
            },
            dataFunction: (res, Actions) =>
              categoryDataMask(res, Actions, updateVisiblity),
          }}
          API={CONSTANT.API.SUBCATEGORY}
          FORM={{
            formField: CONSTANT.FORM_FIELDS?.CATEGORY,
            addFormTitle: "Create category",
            editFormTitle: "Edit category",
            addNewRecordCtaText: "Create category",
            // optionalFieldOnEdit: ["tripEndDistance", "insuranceNumber"],
            // removeFieldOnEdit: ["sampleFile"],
            option: {
              categoryId: subCategory?.map((data) => {
                return {
                  label: data?.name,
                  value: data?.id,
                  // categoryId: data?.categoryId,
                  // categoryName: data?.category?.name,
                };
              }),
            },
          }}
          TABLE={{
            title: "Category list",
            header: TABLE_HEADER?.admin_Category,
            // export: {
            //   fileName: "Transporter_Report.csv",
            //   header: admin_Category,
            // },
          }}
          formData={true}
          // CreateAPI={CONSTANT.API.SUBCATEGORY.addSubCategoryForClient}
          // UpdateAPI={CONSTANT.API.SUBCATEGORY.updateSubCategoryForClient}
          // DeleteAPI={CONSTANT.API.SUBCATEGORY.deleteSubCategoryForClient}
          // GetAPI={CONSTANT.API.SUBCATEGORY.getsubCategoryForClient}
          // GetSingle={`${
          //   localStorage.getItem("userRole") == "client"
          //     ? `?clientId=${myData?.users?.clientId}`
          //     : ""
          // }`}
          // pageHeading="Category"
          // Model
          // ModalName={`CATEGORY`} //Form Model
          // tableColumn="admin_Category" //Vehicle Model
          //Form Customization
          // addName="Create Category"
          // addFormTitle="Create New Category"
          // editFormTitle="Edit Category"
          // formData={true}
          // removeFieldOnEdit={[]}
          // option={{
          //   categoryId: subCategory?.map((data) => {
          //     return {
          //       label: data?.name,
          //       value: data?.id,
          //       // categoryId: data?.categoryId,
          //       // categoryName: data?.category?.name,
          //     };
          //   }),
          // }}
          // onChange={(res) => {}}
          // extraConstPayload={

          // }
          // Table Customization
          // titleHeader="Category List"
          // datafunction={(res) => {
          //   return categoryDataMask(res);
          // }}
          // ExportHeaders={Fitsol_Team}
          // ExportValues={driverValueData(driverDataExcel)}
          // excelName="Fitsol_Team.csv"
          // viewModelData={false}
        />
      </div>
    </>
  );
};

export default Categorys;
