import React, { useState, useRef, useEffect } from "react";

const MultiSelectField = (props) => {
  const {
    options,
    optionTitle,
    selectedOptions,
    setSelectedOptions,
    setTripForm,
    type,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div
        className="custom-multi-select"
        style={{ width: "100%" }}
        ref={dropdownRef}
      >
        <div
          className={`select-header ${isOpen ? "open" : ""}`}
          onClick={() => setIsOpen(!isOpen)}
          style={{ width: "100%" }}
        >
          <span>{optionTitle}</span>
          <i className="arrow-icon"></i>
        </div>
        {isOpen && (
          <ul className="options-list" style={{ width: "100%", zIndex: "1" }}>
            {options?.map((option) => (
              <li
                key={option.id}
                className={`option`}
                style={{ display: "flex" }}
              >
                <input
                  type="checkbox"
                  style={{ marginLeft: "5px", marginRight: "5px" }}
                  checked={
                    selectedOptions.findIndex(
                      (item) => item?.id === option?.id
                    ) !== -1
                  }
                  onChange={(e) => {
                    setSelectedOptions((prev) => {
                      if (e.target.checked) {
                        return [...prev, { ...option }];
                      }

                      setTripForm((prevs) => {
                        return {
                          ...prevs,
                          package: prev.filter(
                            (item) => item?.id !== option?.id
                          ),
                        };
                      });

                      return prev.filter((item) => item?.id !== option?.id);
                    });
                  }}
                />

                <label style={{ marginBottom: "0" }}>{option.label}</label>
                <input
                  type="number"
                  disabled={
                    selectedOptions.findIndex(
                      (item) => item?.id === option?.id
                    ) == -1
                  }
                  placeholder="Count"
                  value={
                    selectedOptions.filter((item) => item?.id === option?.id)[0]
                      ?.cvalue || ""
                  }
                  style={{ position: "absolute", right: "3%" }}
                  onChange={(e) => {
                    setSelectedOptions((prev) => {
                      const newarr = [...prev];
                      const d = [];

                      const index = newarr.findIndex(
                        (item) => item?.id === option?.id
                      );
                      if (index !== -1) {
                        newarr[index] = {
                          ...newarr[index],
                          cvalue: e.target.value,
                        };
                      }

                      setTripForm((prevs) => {
                        newarr.map((data) => {
                          d.push({
                            packageId: `${data?.id}`,
                            count: `${data?.cvalue}`,
                            type: `${type}`,
                          });
                        });

                        return {
                          ...prevs,
                          package: d,
                        };
                      });
                      return newarr;
                    });
                  }}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default MultiSelectField;
