import React, { useEffect, useState } from "react";
import CustomPage from "../../../components/Custome/CustomPage";
import { getExtraConstPayload, replaceId } from "../../Utility/function";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteRoleAndAccessStart,
  getRoleAndAccessStart,
} from "../../../store/actions";
import { TeamMasK } from "../../Utility/spread-function";
import CustomModalMain from "../../../components/Custome/CustomModalMain";
import CONSTANT, { TABLE_HEADER } from "../../Utility/constnt";
import useHttp from "../../../components/Hook/Use-http";
import { Fitsol_Team } from "../../Utility/exportValue";
import { useParams } from "react-router";

const ClientRoleAndAccess = () => {
  const API_CALL2 = useHttp();
  const myData = useSelector((state) => state.userGetMeData);
  const getRolAndAccessData = useSelector(
    (state) => state.roleAndAccessDetailData
  );
  const { id } = useParams();
  const dispatch = useDispatch();
  const role = localStorage.getItem("userRole");

  const getSiteData = useSelector((state) => state.plantDetailData);
  const getClientData = useSelector((state) => state.customerDetailData);
  const [siteData, setSiteData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [changeData, setChangeData] = useState({});
  const [actionData, setActionData] = useState({});
  const [showModel_1, setShowModel_1] = useState(false);

  useEffect(() => {
    // const apiData = replaceId(CONSTANT.API.getAllPlant, SiteID);
    setTimeout(() => {
      setSiteData(
        getSiteData?.users?.data?.rows
          ?.filter((el) => el?.client?.name)
          ?.map((data) => {
            return {
              label: `${data?.unitName} (${data?.client?.name})`,
              value: data?.id,
            };
          }) || []
      );
    }, 100);
  }, [getSiteData]);

  const onSubmitForm = (payload, setFormData) => {
    API_CALL2.sendRequest(
      replaceId(CONSTANT.API.ROLEANDACCESS.update, actionData?.id),
      () => {
        setShowModel_1(false);
        setActionData(null);
        setFormData({});
        if (role == "admin" && id) {
          dispatch(
            getRoleAndAccessStart(
              replaceId(CONSTANT.API.ROLEANDACCESS.getClientRoleAndAccess, id)
                ?.endpoint
            )
          );
        } else {
          dispatch(
            getRoleAndAccessStart(
              replaceId(CONSTANT.API.ROLEANDACCESS.get, id)?.endpoint
            )
          );
        }

        // dispatch(getRoleAndAccessStart());
      },
      payload,
      "Password Updated"
    );
  };
  const preParePayloadOnSubmit = (editPayload, payload) => {
    const totalData = {
      ...editPayload,
      ...payload,
    };

    delete totalData?.data?.id;

    if (totalData?.role === "siteViewer") {
      delete totalData?.clientId;
      delete totalData?.client;
      delete totalData?.plant;
    }
    if (totalData?.role === "client") {
      delete totalData?.plantId;
      delete totalData?.plant;
      delete totalData?.client;
    }
    if (totalData?.role === "tripCreator") {
      delete totalData?.plantId;
      delete totalData?.plant;
      delete totalData?.clientId;
      delete totalData?.client;
    }
    return {
      ...totalData,
    };
  };

  return (
    <>
      <div className="page-content">
        <CustomPage
          PAGE={{
            title: "Roles and access",
            dataFunction: (res, Actions) => {
              return TeamMasK(res, Actions, setShowModel_1, setActionData);
            },
          }}
          REDUX={{
            getDataRedux: getRolAndAccessData?.users,
            getApiDataRedux:
              role == "admin" && id
                ? getRoleAndAccessStart(
                    replaceId(
                      CONSTANT.API.ROLEANDACCESS.getClientRoleAndAccess,
                      id
                    )?.endpoint
                  )
                : getRoleAndAccessStart(
                    replaceId(CONSTANT.API.ROLEANDACCESS.get, id)?.endpoint
                  ),
            deleteApiDataRedux: deleteRoleAndAccessStart,
          }}
          API={CONSTANT.API.ROLEANDACCESS}
          FORM={{
            formField: CONSTANT.FORM_FIELDS?.CROLETEAM,
            prepareUpdatePayload: preParePayloadOnSubmit,
            addFormTitle: "Create access",
            editFormTitle: "Edit access",
            addNewRecordCtaText: "Create access",
            removeFieldOnEdit: ["password"],
            option: {
              plantId: siteData,
            },
            selectedValue: false,
            extraConstPayload: getExtraConstPayload({ role: "siteViewer" }),
          }}
          TABLE={{
            title: "Roles and access list",
            header: TABLE_HEADER?.team,
            export: {
              fileName: "Fitsol_Team.csv",
              header: Fitsol_Team,
            },
          }}
          //redux
          formData={true}
          editValueInRole={true}
          changeInputPayload={changeData}
          setChangeData={setChangeData}
          accessTeam={"roleAccess"}
        />
      </div>
      <CustomModalMain
        modalType="formModal"
        show={showModel_1}
        close={() => setShowModel_1((prev) => !prev)}
        modalTitle="Reset Password"
        onSubmit={(data, setFormData) => onSubmitForm(data, setFormData)}
        data={CONSTANT.FORM_FIELDS.RESETPASSWORD}
        formData={false}
      />
    </>
  );
};

export default ClientRoleAndAccess;
