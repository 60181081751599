import React, { useEffect, useState } from "react";
import CONSTANT from "../../../pages/Utility/constnt";
import CustomModalMain from "../../Custome/CustomModalMain";
import {
  optionArrayWithSort,
  replaceId,
} from "../../../pages/Utility/function";

import {
  emissionEntryCategoryWiseDataLoad,
  emissionForFormDataCallLoad,
} from "../../../pages/Utility/spread-function";
import { useLocation, useParams } from "react-router";
import PcfServices from "../../../pages/Utility/API/pcfService";
import useHttp from "../../Hook/Use-http";

const PurchasedGoodAndService = (props) => {
  const {
    setFinancialYear,
    setInputDataForEmission,
    findEmissionData,
    subCategoryAttribute,
    parentExtraValue,
    category,
    setCategory,
    setCarbonEmission,
    setSubCategoryAttribute,
    API_CALL,
  } = props;
  const [dynamicOption, setDynamicOption] = useState({});
  const [alldynamicOption, setAllDynamicOption] = useState({});
  // const [purchasedCategoryData, setPurchasedCategoryData] = useState(null);
  // const [purchasedSubCategory, setPurchasedSubCategory] = useState([]);
  const [newFormData, setNewFormData] = useState({});
  const API = useHttp();
  const [formData, setFormData] = useState([]);
  // const [defaultData, setDefaultData] = useState({});
  const [cleanData, setCleanData] = useState(true);
  const { id } = useParams();
  const userRole = localStorage.getItem("userRole");
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    setCleanData(true);
    // setDefaultData({ category: category });
    // api.endpoint += "&definationType=userDefined";
    category != null &&
      API_CALL.getAll(
        [
          replaceId(
            CONSTANT.API.SUBCATEGORYATTRIBUTE
              .getSingleFilterSubCategoryAttribute,
            category
          )?.endpoint + "&definationType=userDefined",
          CONSTANT?.API?.ATTRIBUTEOPTION.get?.endpoint,
          CONSTANT?.API.SITE.get?.endpoint,
          CONSTANT?.API.JOB.getPurchasedGoodServiceCategory?.endpoint,
          //Category API EndPoint============================
          //   CONSTANT?.API.JOB.getWasteUsed?.endpoint,
          //   CONSTANT?.API.JOB.getGasUsed?.endpoint,
        ],
        (res) => {
          // setSubCategoryAttribute(res?.data);
          let categoryOption = {};
          const subCategoryAttributeRes = res[0]?.data;
          const subCategoryAttributeOptionRes = res[1]?.data?.data;
          const sites =
            userRole == "admin" && currentPath.includes("/client") && id
              ? res[2]?.data?.data?.rows?.filter((el) => el?.clientId == id)
              : res[2]?.data?.data?.rows;
          const Other = {
            site: sites,
            purchasedcategory: res[3]?.data?.data,
            //category add in all same as a site
          };
          //API CALL UNCOMMENT
          // setPurchasedCategoryData(res[3]?.data?.data?.rows);
          const rearrangedData = subCategoryAttributeRes?.data?.rows?.sort(
            (a, b) => +a.index - +b.index
          );
          const dddd = emissionEntryCategoryWiseDataLoad(
            subCategoryAttributeOptionRes,
            Other,
            rearrangedData,
            setDynamicOption,
            null
          );

          setAllDynamicOption((prev) => ({
            ...prev,
            ...categoryOption,
          }));
          setSubCategoryAttribute([...dddd?.arr]);
          setCleanData(false);
        }
      );
  }, [category]);

  useEffect(() => {
    emissionForFormDataCallLoad(
      subCategoryAttribute,
      setDynamicOption,
      setSubCategoryAttribute,
      setFinancialYear,
      formData,
      alldynamicOption
    );
  }, [formData]);

  useEffect(() => {
    if (!formData?.selectedValue && !formData?.data) return;
    const arr = [];
    const keyOnly = subCategoryAttribute?.filter(
      (el) => el?.extraValue?.depenededFieldId
    );
    keyOnly?.map((el) => arr?.push(el?.extraValue?.depenededFieldId));
    const keyFindId = Object?.keys(newFormData)?.[0];
    const optionlistCheck = Object.keys(formData?.selectedValue)?.find(
      (el) => el == keyFindId
    );
    const purchsedCategoryId = formData?.selectedValue[arr?.[0]]?.id;
    const purchsedSubCategoryId = formData?.selectedValue[arr?.[1]]?.id;
    if (
      newFormData?.[arr?.[0]] &&
      keyFindId == arr?.[0] &&
      optionlistCheck == arr?.[0] &&
      !!purchsedCategoryId
    ) {
      API.getAll(
        [
          replaceId(
            CONSTANT.API.JOB.getPurchasedGoodServiceSubCategory,
            purchsedCategoryId
          ).endpoint,
        ],
        (res) => {
          delete formData?.data?.[keyOnly?.[0]?.id]; //153 sub-category
          delete formData?.data?.[keyOnly?.[1]?.id]; //154 raw material type
          delete formData?.selectedValue?.[keyOnly?.[0]?.id]; //153 sub-category
          delete formData?.selectedValue?.[keyOnly?.[1]?.id]; //154 raw material type
          formData?.operations?.inputData(formData?.data);
          formData?.operations?.selectedValue(formData?.selectedValue);
          setDynamicOption((prev) => {
            //API SET SUBCATEGORY====================
            return {
              ...prev,
              [keyOnly?.[1]?.id]: [],
              [keyOnly?.[0]?.id]: optionArrayWithSort(
                res[0]?.data?.data,
                "name",
                "name"
              ), //subCategory list
            };
          });
        }
      );
    } else if (
      newFormData?.[arr?.[1]] &&
      keyFindId == arr?.[1] &&
      optionlistCheck == arr?.[1] &&
      !!purchsedSubCategoryId
    ) {
      API.getAll(
        [
          replaceId(
            CONSTANT.API.JOB.getPurchasedGoodServiceRawMaterialType,
            purchsedSubCategoryId
          ).endpoint,
        ],
        (res) => {
          delete formData?.data?.[keyOnly?.[1]?.id]; //154 raw material type
          delete formData?.selectedValue?.[keyOnly?.[1]?.id]; //154 raw material type

          formData?.operations?.inputData(formData?.data);
          formData?.operations?.selectedValue(formData?.selectedValue);
          setDynamicOption((prev) => {
            //API SET RAW MATERIAL TYPE
            return {
              ...prev,
              [keyOnly?.[1]?.id]: optionArrayWithSort(
                res[0]?.data?.data,
                "name",
                "name",
                "unit"
              ), //raw MAterial Type list
            };
          });
        }
      );
      setNewFormData({});
    }
  }, [formData]);

  const onSubmit = (value) => {
    const e = { ...value };
    let payload = {};
    payload.createFlag = false;
    payload.subCategoryId = category;
    if (localStorage.getItem("userRole") == "admin" && id) {
      payload.clientId = id;
    }
    payload.data = e;

    Object.keys(payload.data)?.map((el) => {
      payload.data[`id${el}`] = payload.data[el];
    });
    setInputDataForEmission({ ...payload, createFlag: true });
    findEmissionData(payload);
  };

  return (
    <CustomModalMain
      data={subCategoryAttribute}
      // defaultData={defaultData}
      labelSize={"25%"}
      submitBtnPosition={"center"}
      fieldSize={"70%"}
      submitBtnName="Calculate Emissions"
      isSubmitButtonVisible={category != null && !API_CALL?.isLoading}
      option={{
        ...dynamicOption,
      }}
      isEdit={true}
      isCloase={cleanData}
      close={() => {
        if (cleanData) {
          setCarbonEmission(null);
          setSubCategoryAttribute([
            // ...CONSTANT.FORM_FIELDS.ADDSINGLEEMISSIONENTRY,
          ]);
        }
      }}
      onSubmit={onSubmit}
      isCloseButtonVisible={false}
      minHeight={"90vh"}
      scrollCard="none"
      onSingleChange={(data) => {
        setNewFormData(data);
      }}
      onChange={(data, selectedValue, operations) => {
        const keyOnly = subCategoryAttribute?.find(
          (el) => el?.extraValue?.keyOrEquetion?.[0]
        );
        // setPurchasedSubCategory(data?.[keyOnly?.id]);

        setFormData({ data, selectedValue, operations });
      }}
    />
  );
};

export default PurchasedGoodAndService;
