import React, { useState, useEffect } from "react";
import { Form, Button, Input } from "antd";
import useHttp from "../../../components/Hook/Use-http";
import BrsrFormHeading from "../BrsrFormHeading";
import Questions from "../../../components/Report/Questions";
import { useParams } from "react-router-dom";
import { replaceFormIdandClientId, replaceId } from "../../Utility/function";
import CONSTANT from "../../Utility/constnt";
import {Spinner} from 'reactstrap';


const CSR = () => {
  const API_CALL = useHttp();
  const { id } = useParams();
  const userRole = localStorage.getItem("userRole");
  const [questions, setQuestions] = useState([]);
  const getTooltipText = {
    20: "Provide a link to your CSR policy and impact reports if any",
    24: "This disclosure shall be made, if thexentity has undertaken SIA in compliance with laws such as the Right to fair compensation & transparency in land acquisition, rehabilitation & resettlement act, 2013.",
    25: "The entity can disclose details of corrective actions taken to mitigate any negative impacts identified in the social impact assessments as disclosed in Q1 of P8",
    27: "The entity shall disclose requested information like (i) Name, (ii) place, (iii) no. of affected families, (iv) % of such families covered & (v) amounts paid for the ongoing R&R project.",
    28: "The entity shall disclose steps/process/measure/mechanism in place to receive & redress grievances/complaints of the nearby local community. The nature of complaints could be economical, social or environmental.",
    29: `Being able to measure & report on your organization's social impact can ultimately attract investors, retain top talent, boost reputation,  & boost business performance alongside several other potential benefits. As per the ‘Transformation of Aspirational Districts’ programme of the government, a list of districts has been identified for quick & effective transformation. For additional details, refer to the following link: <a href="https://www.niti.gov.in/aspirational-districts-programme" target="_blank" rel="noopener noreferrer">Click here</a>`,
    30: "For  each  CSR  Project  undertaken  by  the  entity, enter:   (a)   total   number   of   beneficiaries,   (b) percentage  of   such   beneficiaries  belonging   to vulnerable & marginalised group"
  };
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    try {
      const parser = (data = []) => {
        return data.map((curr) => {
          const dataSchema = JSON.parse(curr.Question.answerSchema);
          let answerString = curr.Question?.Answers[0]?.value;
          return {
            id: curr.Question.id,
            question: curr.Question.description,
            options: dataSchema.options,
            answerSchema: dataSchema,
            type: dataSchema.type,
            answer: answerString,
            valueType: curr.Question?.Answers[0]?.valueType,
            tooltip: getTooltipText?.[curr.Question.id],
          };
        });
      };


      let sectionId = 2; // need to call this api and call from api not statically
      if (userRole == "client") {
        API_CALL.sendRequest(
          replaceId(CONSTANT.API.GLOBALFORMS.get, sectionId),
          (res) => {
            let globalQuestions = parser(res?.data?.GlobalSectionQuestions);
            setQuestions(globalQuestions);
            setLoading(false);
          }
        );
      } else if (userRole == "admin") {
        API_CALL.sendRequest(
          replaceFormIdandClientId(
            CONSTANT.API.GLOBALFORMS.get2,
            sectionId,
            id
          ),
          (res) => {
            let globalQuestions = parser(res?.data?.GlobalSectionQuestions);
            setQuestions(globalQuestions);
            setLoading(false);
          }
        );
      } else if (userRole == "spoc") {
        API_CALL.sendRequest(
          replaceFormIdandClientId(
            CONSTANT.API.GLOBALFORMS.get,
            sectionId,
            id
          ),
          (res) => {
            let globalQuestions = parser(res?.data[0]?.GlobalSection?.GlobalSectionQuestions);
            setQuestions(globalQuestions);
            setLoading(false);
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, []);


  const handleSubmit = (data = {}) => {
    try {
      const formatObject = (obj) => JSON.stringify(obj).replace(/"/g, '\\"');
      const transformData = (data) => ({
        answers: Object.entries(data).map(([key, value]) => {
          const question = questions.find((q) => q.id === Number(key));
          let formattedValue;
          if (
            (question && question.type === "fixedtable") ||
            (question.type === "dynamictable" && question.transformedAnswer)
          ) {
            formattedValue = JSON.stringify(
              question?.transformedAnswer
            )?.replace(/"/g, '"');
          } else {
            formattedValue =
              typeof value === "object"
                ? formatObject([{ id: value.key }])
                : value;
          }
          return {
            qid: Number(key),
            value: formattedValue,
          };
        }),
      });


      if (userRole === "client") {
        API_CALL.sendRequest(
          CONSTANT.API.GLOBALFORMS.create,
          null,
          transformData(data),
          "Your data added successfully"
        );
      } else if (userRole === "admin") {
        API_CALL.sendRequest(
          replaceId(CONSTANT.API.GLOBALFORMS.create2, id),
          null,
          transformData(data),
          "Your data added successfully"
        );
      }  else if (userRole === "spoc") {
        const clientId = localStorage.getItem("client");
        API_CALL.sendRequest(
          replaceId(CONSTANT.API.GLOBALFORMS.create2, clientId),
          null,
          transformData(data),
          "Your data added successfully"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <div
      style={{ marginTop: 100 }}
      className="flex flex-col items-start p-2 w-full"
    >
      <BrsrFormHeading
        title="CSR Details"
        explanation="This form evaluates the effectiveness and responsiveness of a firm in addressing the needs of marginalized communities. It also examines the firm's commitment to fairness and equity in dealing with community issues."
      />
      <p style={{ fontStyle: "italic", marginBottom: "16px" }}>
        Note: Answers marked with ✨ are recommended by our AI. You can edit
        them if needed.
      </p>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <Spinner className="m-1" color="primary" />
        </div>
      ) : (
      <Form layout="vertical" onFinish={handleSubmit} form={form}>
        {questions.map((ques, index) =>
          ques.type == {}
            ? ("do the operation") : (
              <Questions key={ques.id} ques={ques} index={index} form={form} />
            )
        )}
        <Button
          type="primary"
          htmlType="submit"
          style={{
            position: "fixed",
            bottom: "30px",
            backgroundColor: "#060DBF",
            color: "#62C941",
            right: "74px",
            zIndex: 1000,
            // width: "125px",
            height: "40px",
            cursor: "pointer",
          }}
        >
          Save changes
        </Button>
      </Form>
      )}
    </div>
  );
};


export default CSR;
