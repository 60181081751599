import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Label,
  Tooltip,
  Card,
  CardBody,
  CardSubtitle,
} from "reactstrap";
import PageTitle from "../../../../components/pageTitle";
import CardGen from "../../../../components/dashboard/cardGen";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useHttp from "../../../../components/Hook/Use-http";
import CONSTANT, {
  getLatestEightFinancialYears,
  scope3SubCategories,
  scopeWiseEmissionEquivalent,
  truncateAndAddCommas,
} from "../../../Utility/constnt";
import { Carousel, Switch } from "antd";
import TotalEmission from "../../../../components/scopecomponents/totalEmission";
import {
  overallScopeWiseEmissionDashboardDataLoad,
  scopeWiseDashboardAnalysis,
  scopeWiseEmissionDashboardDataLoad,
  scopeWiseEmissionDashboardDataLoad2,
} from "../../../Utility/spread-function";
import { useSelector } from "react-redux";
import { cardAnalyticsData } from "../../../Utility/option";
import authStorage from "../../../Utility/API/authStroge";
import EmissionGen from "../../../../components/scopecomponents/emissionGen";
import {
  categoryAttributeDataType,
  categoryAttributeForm,
} from "../../../Utility/models/form-model";
import { ConsolidatedItemsPosition } from "igniteui-react-charts";
import TableGen from "../../../../components/dashboard/tableGen";
import MapComponent2 from "../Scope3Report/MapComponent2";
import { update } from "lodash";
import CheckBox from "../../../../components/scopecomponents/checkBox";
import CheckBox1 from "../../../../components/scopecomponents/checkBox1";
import useSessionStorage from "../../ToggleFunction/useSessionStorage.js";
let fiscalyear = "";
const today = new Date();
if (today.getMonth() + 1 <= 3) {
  fiscalyear = today.getFullYear() - 1;
} else {
  fiscalyear = today.getFullYear();
}

const CardData = {
  admin: cardAnalyticsData,
  client: cardAnalyticsData,
  siteViewer: cardAnalyticsData,
};
const ScopeOverallReport = () => {
  const [tripList, setTripList] = useState([]);
  const [scopeList, setScopeList] = useState([]);
  const myData = useSelector((state) => state.userGetMeData);
  const isLogin =
    authStorage?.getAuthToken() !== undefined &&
    authStorage?.getAuthToken() !== null;
  const userRole = localStorage.getItem("userRole")?.toLocaleLowerCase();
  const [financialYear, setFinancialYear] = useState(
    `${fiscalyear}-${(fiscalyear + 1).toString().substring(2)}`
  );
  const [prevFinancialYear, setPrevFinancialYear] = useState(
    `${fiscalyear}-${(fiscalyear + 1).toString().substring(2)}`
  );
  const [prevSelectedFY, setPrevSelectedFY] = useState([]);
  const [AvailableFinancialYear, setAvailableFinancialYear] = useState([]);
  const [selectedCalendarYear, setSelectedCalendarYear] = useState([]); // New line
  const [selectedFY, setSelectedFY] = useState([financialYear]);
  const API_CALL = useHttp();
  const location = useLocation();
  const { id } = useParams();
  const currentPath = location.pathname?.toLocaleLowerCase();
  const [categoryDataLabel, setCategoryDataLabel] = useState({});
  const [subCategoryCheckboxData, setSubCategoryCheckboxData] = useState([]);
  const [fyCheckboxData, setFYCheckboxData] = useState([]);
  const [calendarYearCheckboxData, setCalendarYearCheckboxData] = useState([]); // new added
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [scopeData, setScopeData] = useState({});
  const [categoryHead, setCategoryHead] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [categoryDataLoading, setCategoryDataLoading] = useState(true);
  const [totalEmission, setTotalEmission] = useState("0");
  const [chartView, setChartView] = useState(<></>);
  const [stackView, setStackView] = useState(<></>);
  const [lineView, setLineView] = useState(<></>);
  const [mapData, setMapData] = useState([]);
  const [tableHeading, setTableHeading] = useState([]);
  const [table2Heading, setTable2Heading] = useState([]);
  const [selectedScope, setSelectedScope] = useState("");
  const [intensityMetrics, setIntensityMetrics] = useState(false);

  const isFY =
    localStorage.getItem("selectedYear") == "financialYear" ? true : false;
  const [isFinancialYear, setIsFinancialYear] = useState(isFY);

  useEffect(() => {
    API_CALL.getAll([CONSTANT.API.CATEGORY.get.endpoint], (res) => {
      let result = res[0]?.data?.data?.rows;
      const filteredItems = result?.filter((item) => item?.name !== "Biogenic");
      setCategoryData(filteredItems);
    });
    // }
  }, []);

  useEffect(() => {
    if (!isLogin || !userRole) return;
    if (categoryData.length == 0) return;
    if (
      selectedFY.length > 0 &&
      JSON.stringify(selectedFY) == JSON.stringify(prevSelectedFY)
    ) {
      return;
    }
    let analyticsAPIendpoint = { ...CONSTANT.API.DASHBOARD.getAnalysis };
    if (selectedFY && selectedFY?.length > 0) {
      if (userRole == "admin" && currentPath?.includes(id)) {
        analyticsAPIendpoint.endpoint = selectedFY
          .filter((fy) => !(fy in scopeData))
          .map((fy) => {
            return `${analyticsAPIendpoint.endpoint}/${id}?value=${fy}`;
          });
      } else {
        analyticsAPIendpoint.endpoint = selectedFY
          .filter((fy) => !(fy in scopeData))
          .map((fy) => {
            return `${analyticsAPIendpoint.endpoint}?value=${fy}`;
          });
      }
    } else {
      if (userRole == "admin" && currentPath?.includes(id)) {
        analyticsAPIendpoint.endpoint = `${analyticsAPIendpoint.endpoint}/${id}`;
      }
    }
    const apiEndpoints = Array.isArray(analyticsAPIendpoint.endpoint)
      ? analyticsAPIendpoint.endpoint
      : [analyticsAPIendpoint.endpoint];
    API_CALL.getAll([...apiEndpoints], (res) => {
      const scopeDataObj = scopeData;
      for (const response of res) {
        const fy = response?.config?.url?.split("?value=")[1];
        if (fy) scopeDataObj[fy] = response?.data?.data;
      }
      setScopeData((prevData) => ({
        ...prevData,
        ...scopeData,
      }));
      if (
        res[0]?.data?.data?.getAllFinancialYearData &&
        AvailableFinancialYear.length == 0
      )
        setAvailableFinancialYear(res[0]?.data?.data?.getAllFinancialYearData);
    });
    setPrevSelectedFY(selectedFY);
  }, [categoryData, selectedFY]);

  useEffect(() => {
    const [scopeObj] = categoryData.filter((row) => {
      return row?.name === "Scope 3";
    });
    const scopeId = scopeObj?.id;
    setCategoryDataLoading(false);
    const rees = overallScopeWiseEmissionDashboardDataLoad(
      scopeData,
      categoryData,
      setStackView,
      setChartView,
      setLineView,
      selectedSubCategories,
      selectedFY,
      setTripList,
      setTableHeading,
      setScopeList,
      setTable2Heading,
      setMapData,
      setTotalEmission,
      setSelectedScope,
      subCategoryCheckboxData?.options
    );

    setPrevFinancialYear(financialYear);
  }, [scopeData, selectedSubCategories, selectedFY]);

  useEffect(() => {
    setSubCategoryCheckboxData({
      options: categoryData.map((el) => {
        return {
          value: el?.id,
          label: el?.name,
        };
      }),
      defaultData: categoryData.map((item) => {
        return item?.id;
      }),
    });
    setSelectedSubCategories(
      categoryData.map((item) => {
        return item?.id;
      })
    );

    // new line added ---
    setFYCheckboxData({
      options: AvailableFinancialYear.filter((year) =>
        String(year.value).match(/^\d{4}-\d{2}$/)
      ).map((fy) => ({
        value: fy.value,
        label: fy.value,
      })),
      defaultData: [financialYear],
    });
    // New: Set calendar year checkbox data
    setCalendarYearCheckboxData({
      options: AvailableFinancialYear.filter(
        (year) => !String(year.value).match(/^\d{4}-\d{2}$/)
      ).map((fy) => ({
        value: fy.value,
        label: fy.value,
      })),
      defaultData: [financialYear],
    });
    let calendarYears = [],
      financialYears = [];
    for (const item of AvailableFinancialYear) {
      if (item.value) {
        let flag = item.value;
        if (flag.includes("-")) {
          financialYears.push(flag);
        } else {
          calendarYears.push(flag);
        }
      }
    }
    if (isFinancialYear) {
      setSelectedFY(financialYears);
    } else {
      setSelectedFY(calendarYears);
    }
  }, [categoryData, AvailableFinancialYear, financialYear, isFinancialYear]);

  useEffect(() => {
    if (!isFinancialYear) {
      let calendarYears = [];
      for (const item of AvailableFinancialYear) {
        if (item.value) {
          let flag = item.value;
          if (!flag.includes("-")) {
            calendarYears.push(flag);
          }
        }
      }
      setSelectedFY(calendarYears);
    }
  }, [isFinancialYear]);

  const handleToggleChange = (checked) => {
    setIsFinancialYear(checked);
    if (checked) {
      localStorage.setItem("selectedYear", "financialYear");
    } else {
      localStorage.setItem("selectedYear", "calendarYear");
    }
  };

  return (
    <div className="page-content">
      <PageTitle pageHeading={`Total emissions`} />
      <Row>
        <Col md={3}>
          <TotalEmission
            boxData={{
              heading: "Total emissions",
              count: `${totalEmission} <small>tCO<sub>2</sub>e</small>`,
            }}
          />
        </Col>
        <Col md={3}>
          <Carousel autoplay dotPosition="bottom" effect="fade">
            {scopeWiseEmissionEquivalent(totalEmission).map((item, index) => (
              <div key={index}>
                <TotalEmission boxData={item} />
              </div>
            ))}
          </Carousel>
        </Col>

        <Col md={3}>
          <Card
            style={{
              height: "250px",
              overflow: "hidden",
              transform: "translateY(10px)",
            }}
          >
            <CardBody
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <CardSubtitle className="mb-1"></CardSubtitle> */}
              <Switch
                className="pt-0.4 "
                checkedChildren="Financial Year"
                unCheckedChildren="Calendar Year"
                checked={isFinancialYear}
                onChange={handleToggleChange}
              />

              {isFinancialYear ? (
                fyCheckboxData?.defaultData &&
                fyCheckboxData?.defaultData?.length > 0 ? (
                  <CheckBox1
                    heading={"Filter by financial year"}
                    names={fyCheckboxData}
                    onChange={(checkValues) => {
                      checkValues.sort();
                      setSelectedFY(checkValues);
                      setSelectedCalendarYear(checkValues);
                    }}
                    disabled={false}
                  />
                ) : (
                  "Loading...."
                )
              ) : calendarYearCheckboxData?.defaultData &&
                calendarYearCheckboxData?.defaultData?.length > 0 ? (
                <CheckBox1
                  heading={"Filter by calendar year"}
                  names={calendarYearCheckboxData}
                  onChange={(checkValues) => {
                    checkValues.sort();
                    setSelectedFY(checkValues);
                    setSelectedCalendarYear(checkValues);
                  }}
                  disabled={false}
                />
              ) : (
                "Loading...."
              )}
            </CardBody>
          </Card>
        </Col>
        <Col md={3}>
          {subCategoryCheckboxData?.defaultData &&
          subCategoryCheckboxData?.defaultData?.length > 0 ? (
            <CheckBox
              names={subCategoryCheckboxData}
              heading={"Filter by scope"}
              onChange={(checkValues) => {
                setSelectedSubCategories(checkValues);
              }}
              disabled={true}
            />
          ) : (
            "Loading......"
          )}
        </Col>
      </Row>
      <Row className="py-4">
        <Col>
          <span className="font-poppins font-semibold">
            Want to view Intensity metrics?{" "}
          </span>
          <Switch
            onChange={() => {
              setIntensityMetrics(!intensityMetrics);
            }}
          />
        </Col>
      </Row>
      <Row>
        {intensityMetrics && (
          <Col xs={12}>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-3">
                  <h5 style={{ fontSize: "20px", textAlign: "center" }}>
                    <b>Total emissions YoY comparison</b>
                  </h5>
                </CardSubtitle>
                {totalEmission == "0" ? "No Data Present" : lineView}
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
      <Row>
        {!intensityMetrics && (
          <Col xs={12}>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-3">
                  <h5 style={{ fontSize: "20px", textAlign: "center" }}>
                    <b>Total emissions YoY comparison</b>
                  </h5>
                </CardSubtitle>
                {totalEmission == "0" ? "No Data Present" : stackView}
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <Card>
            <CardBody>
              <CardSubtitle className="mb-3">
                <h5 style={{ fontSize: "20px", textAlign: "center" }}>
                  <b>Site-wise breakup </b>
                </h5>
              </CardSubtitle>
              <MapComponent2
                tripList={mapData}
                selectedFY={selectedFY}
                scope={selectedScope}
              />
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card>
            <CardBody
              style={{
                height: "820px",
              }}
            >
              {!categoryDataLoading && (
                <TableGen
                  title={
                    <span>
                      Site-wise total emissions in tCO<sub>2</sub>e
                    </span>
                  }
                  myData={myData}
                  tripList={tripList}
                  tableHeading={tableHeading}
                  categoryDataLabel={tableHeading}
                  scope3List={true}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row xs={12}>
        <Col xs={12} md={6}>
          <Card>
            <CardBody
              style={{
                height: "950px",
              }}
            >
              <CardSubtitle className="mb-3">
                <h5 style={{ fontSize: "20px", textAlign: "center" }}>
                  <b>Scope breakup</b>
                </h5>
              </CardSubtitle>
              {totalEmission == "0" ? "No Data Present" : chartView}
              {/* {chartView} */}
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card>
            <CardBody
              style={{
                height: "950px",
              }}
            >
              {!categoryDataLoading && (
                <TableGen
                  title={
                    <span>
                      Scope-wise total emissions in tCO<sub>2</sub>e
                    </span>
                  }
                  myData={myData}
                  tripList={scopeList}
                  tableHeading={table2Heading}
                  categoryDataLabel={table2Heading}
                  scope3List={true}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ScopeOverallReport;
