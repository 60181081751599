// apicall.js

import { notifications, replaceEndPointId } from "../../pages/Utility/function";
import notify from "../../pages/Utility/coustemFunction";
import Services from "../../pages/Utility/API/service";

const BASE_URL = process.env.REACT_APP_BASE_URL; // Replace with your API base URL

const authToken = localStorage.getItem("token");
// Helper function to handle common response logic
const handleResponse = async (response) => {
  if (!response.status === 200) {
    const errorData = await response.data;

    throw new Error(errorData.message);
  }
  return response.data;
};

// GET request function with query parameters and Bearer token
export const get = async (endpoint, queryParams = {}) => {
  const queryString = new URLSearchParams(queryParams).toString();
  const url = `${BASE_URL}${endpoint}`;

  try {
    const response = await Services.get(endpoint, {
      onDownloadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        // console.log(percentCompleted, "progresss", progressEvent);
        // if (progressHandler) {
        //   progressHandler(percentCompleted);
        // }
      },
    });

    return handleResponse(response);
  } catch (error) {
    console.log(error);
    notifications.error(error?.response?.data?.message);
    throw new Error(error.message);
  }
};

// POST request function with payload data and Bearer token
export const post = async (endpoint, data) => {
  const url = `${BASE_URL}${endpoint}`;
  // const requestOptions = {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${authToken}`,
  //   },
  //   body: JSON.stringify(data),
  // };

  try {
    const response = await Services.post(url, data, {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        // if (progressHandler) {
        //   progressHandler(percentCompleted);
        // }
      },
    });
    response?.data?.status == "success" &&
      notifications.success("Data added successfully");

    return handleResponse(response);
  } catch (error) {
    console.log(error.message);
    // console.log(error?.response?.data?.message);

    notifications.error(error?.response?.data?.message);
    throw new Error(error.message);
  }
};

// PUT request function with payload data and Bearer token
export const put = async (endpoint, data) => {
  const url = `${BASE_URL}${endpoint}`;
  // const requestOptions = {
  //   method: "PUT",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${authToken}`,
  //   },
  //   body: JSON.stringify(data),
  // };

  try {
    const response = await Services.put(url);
    response?.data?.status == "success" &&
      notifications.success("Data updated successfully");
    return handleResponse(response);
  } catch (error) {
    notifications.error(error?.response?.data?.message);
    throw new Error(error.message);
  }
};

// PATCH request function with payload data and Bearer token
export const patch = async (endpoint, data) => {
  const url = `${BASE_URL}${endpoint}`;
  // const requestOptions = {
  //   method: "PATCH",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${authToken}`,
  //   },
  //   body: JSON.stringify(data),
  // };

  try {
    const response = await Services.patch(url, data);
    response?.data?.status == "success" &&
      notifications.success("Data updated successfully");
    return handleResponse(response);
  } catch (error) {
    notifications.error(error?.response?.data?.message);
    throw new Error(error.message);
  }
};

// DELETE request function with Bearer token
export const remove = async (endpoint) => {
  const url = `${BASE_URL}/${endpoint}`;
  // const requestOptions = {
  //   method: "DELETE",
  //   headers: {
  //     Authorization: `Bearer ${authToken}`,
  //   },
  // };

  try {
    const response = await Services.delete(url);
    response?.data?.status == "success" &&
      notifications?.success("Data deleted successfully");
    return handleResponse(response);
  } catch (error) {
    console.log(error?.response?.data?.message);
    notifications?.error(error?.response?.data?.message);
    throw new Error(error?.message);
  }
};

const Apicall = {
  get,
  post,
  put,
  patch,
  remove,
};

export default Apicall;
