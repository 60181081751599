import React,{useState, useEffect} from 'react'
import CustomPage from '../../../../components/Custome/CustomPage'
import CONSTANT, {TABLE_HEADER} from '../../../Utility/constnt'
import { useParams } from 'react-router-dom';
import useHttp from '../../../../components/Hook/Use-http';
import { createEndPoint, replaceIdClientId } from '../../../Utility/function';
import {Col,Form, Row, Select} from 'antd';
import { connectionSentDataMask, globalSectionsDataMask } from '../../../Utility/spread-function';
import { connectionSentHeaderData } from '../../../Utility/exportValue';
import { useSelector } from 'react-redux';
const ConnectionSent = () => {
    const { id } = useParams();
    const role = localStorage.getItem("userRole");
  return (
    <div className="w-full">
        <CustomPage
        PAGE={{
          title: "Connections sent",
          dataFunction: connectionSentDataMask,
        }}
        API={{
          // need to change at client login level time url
          ...CONSTANT.API.MANAGE_CONNECTIONS_OUTGOING,
          get: createEndPoint.get(
            role === "admin"
              ? `${role}/connections?type=outgoing&clientId=${id}`
              : `${role}/connections?type=outgoing`
          ),
        }}
        TABLE={{
          title: "Forms list",
          header: TABLE_HEADER?.connectionSent,
          export: {
            fileName: "ConnectionsSent.csv",
            header: connectionSentHeaderData,
          },
        }}
        connectionSent = {true}
      />
    
  </div>
  )
}

export default ConnectionSent