export const packageType = [
  {
    label: `Reusable`,
    value: "Reusable",
  },
  {
    label: `Non-Reusable`,
    value: "Non-Reusable",
  },
];
export const brsrSectionType = [
  {
    label: "Section A-I",
    value: "A_I",
  },
  {
    label: "Section A-II",
    value: "A_II",
  },
  {
    label: "Section A-III",
    value: "A_III",
  },
  {
    label: "Section A-IV",
    value: "A_IV",
  },
  {
    label: "Section A-V",
    value: "A_V",
  },
  {
    label: "Section A-VI",
    value: "A_VI",
  },
  {
    label: "Section A - A-VII",
    value: "A_VII",
  },
  {
    label: "Section B",
    value: "B",
  },
  {
    label: "Section C principle 1 essential indicators",
    value: "C_P1_E",
  },
  {
    label: "Section C principle 1 leadership indicators",
    value: "C_P1_L",
  },
  {
    label: "Section C principle 2 essential indicators",
    value: "C_P2_E",
  },
  {
    label: "Section C principle 2 leadership indicators",
    value: "C_P2_L",
  },
  {
    label: "Section C principle 3 essential indicators",
    value: "C_P3_E",
  },
  {
    label: "Section C principle 3 leadership indicators",
    value: "C_P3_L",
  },
  {
    label: "Section C principle 4 essential indicators",
    value: "C_P4_E",
  },
  {
    label: "Section C principle 4 leadership indicators",
    value: "C_P4_L",
  },
  {
    label: "Section C principle 5 essential indicators",
    value: "C_P5_E",
  },
  {
    label: "Section C principle 5 leadership indicators",
    value: "C_P5_L",
  },
  {
    label: "Section C principle 6 essential indicators",
    value: "C_P6_E",
  },
  {
    label: "Section C principle 6 leadership indicators",
    value: "C_P6_L",
  },
  {
    label: "Section C principle 7 essential indicators",
    value: "C_P7_E",
  },
  {
    label: "Section C principle 7 leadership indicators",
    value: "C_P7_L",
  },
  {
    label: "Section C principle 8 essential indicators",
    value: "C_P8_E",
  },
  {
    label: "Section C principle 8 leadership indicators",
    value: "C_P8_L",
  },
  {
    label: "Section C principle 9 essential indicators",
    value: "C_P9_E",
  },
  {
    label: "Section C principle 9 leadership indicators",
    value: "C_P9_L",
  },
];
export const spocList = [
  // need to do an api call here:
  
  // {
  //   label: "spoc-1",
  //   value: "A_I",
  // },
  // {
  //   label: "spoc2",
  //   value: "A_II",
  // },
]
export const answerDataType = [
  { label: "Auto Calculated", value: "AutoCalculated" },
  { label: "User Defined", value: "userDefined" },
  { label: "User Defined Dynamic", value: "userDefinedDynamic" },
];

export const userDataType = [
  { label: "Text", value: "text" },
  { label: "Number", value: "number" },
  { label: "Date", value: "date" },
  { label: "Select", value: "SingleSelect" },
  { label: "Site-create-Select", value: "site-SingleSelect-create" },
  { label: "FuelType-Select", value: "fuelType-SingleSelect-Select" },
  { label: "GasUsed-Select", value: "gasUsed-SingleSelect-Select" },
  { label: "wasteType-Select", value: "wasteType-SingleSelect-Select" },
  { label: "employeeMode-Select", value: "employeeMode-SingleSelect-Select" },
  { label: "SelectWithCreate", value: "SingleSelectWithCreate" },
  {
    label: "purchased-category-Select",
    value: "purchasedcategory-SingleSelect-Select",
  },
  {
    label: "purchased-subcategory-Select",
    value: "purchasedsubcategory-SingleSelect-Select",
  },
  {
    label: "purchased-raw-material-Select",
    value: "purchasedrawmaterial-SingleSelect-Select",
  },
  { label: "good-category-Select", value: "goodCategory-SingleSelect-Select" },
  { label: "good-Type-Select", value: "goodType-SingleSelect-Select" },
  { label: "dynamicSelect", value: "dynamicSelect" },
  { label: "getShowDefaultSelect", value: "getShowDefaultSelect" },
  { label: "multifield", value: "multifield" },
  { label: "Ports-AutoComplete", value: "ports-autocomplete" },
  { label: "GoogleAutoComplete", value: "GoogleAutoComplete" },
];

export const autoDataType = [
  { label: "Api value", value: "apiValue" },
  { label: "Equation", value: "equation" },
];

export const role = [
  {
    label: `Site Admin`,
    value: "siteViewer",
  },
  {
    label: `Client Admin`,
    value: "client",
  },
  {
    label: `Shipment Creator`,
    value: "tripCreator",
  },
];

export const material = [
  { label: "Plastic", value: "Plastic", id: 3 },
  { label: "Metal", value: "Metal", id: 2.6 },
  { label: "Wood", value: "Wood", id: 1.7 },
  { label: "Corrugated", value: "Corrugated", id: 0.95 },
];

export const fuelType = [
  { label: "Petrol", value: "Petrol" },
  { label: "Diesel", value: "Diesel" },
  { label: "CNG", value: "CNG" },
  { label: "PLG", value: "PLG" },
];

export const dayName = [
  { label: "Monday", value: "Monday" },
  { label: "Tuesday", value: "Tuesday" },
  { label: "Wednesday", value: "Wednesday" },
  { label: "Thursday", value: "Thursday" },
  { label: "Friday", value: "Friday" },
  { label: "Saturday", value: "Saturday" },
  { label: "Sunday", value: "Sunday" },
];

export const getCo2 = {
  "20ft": 0.5,
  "32ft SA": 0.55,
  "32ft MA": 0.6,
  "For pickup": 0.3,
  "20ft CNG": 0.45,
};

export const engineType = Object?.keys(getCo2)?.map((ele) => {
  return { label: ele, value: ele };
});

export const analysisFilter = [
  { label: "1 Week", value: "lastWeekAnalysis" },
  { label: "1 Month", value: "lastMonthAnalysis" },
  { label: "3 Month", value: "last3MonthAnalysis" },
  { label: "6 Month", value: "last6MonthAnalysis" },
  { label: "1 Year", value: "currentFYAnalysis" },
  { label: "3 Year", value: "last3FYAnalysis" },
  { label: "5 Year", value: "last5FYAnalysis" },
  {
    label: "All",
    value: "lastAllMonthsTripAnalytics",
    isFixed: true,
  },
];

export const cardAnalyticsData = [
  {
    icon: "mdi mdi-leaf",
    name: (
      <>
        tCO<sub>2</sub>e emitted
      </>
    ),
    count: 80,
    value: "Loading...",
  },
  {
    icon: "bx bxs-check-square",
    name: "km completed",
    count: 120,
    value: "Loading...",
  },
  {
    icon: "mdi mdi-leaf",
    name: (
      <>
        tCO<sub>2</sub>e/freight tonne-km efficiency
      </>
    ),
    count: 80,
    value: "Loading...",
  },

  {
    icon: "bx bxs-truck",
    name: "Shipments in transit",
    count: 80,
    value: "Loading...",
  },
  {
    icon: "bx bxs-truck",
    name: "Shipments completed",
    count: 120,
    value: "Loading...",
  },
];

export const colorCode = [
  [
    "#FF0000",
    "#00FF00",
    "#0000FF",
    "#FFFF00",
    "#00FFFF",
    "#FF00FF",
    "#A52A2A",
    "#008080",
    "#FFA500",
    "#FFC0CB",
    "#800080",
    "#808000",
    "#E6E6FA",
    "#FFD700",
    "#C0C0C0",
    "#808080",
    "#FFFFFF",
    "#000000",
  ],
  [
    "#6A5ACD",
    "#2E8B57",
    "#B22222",
    "#FF69B4",
    "#2F4F4F",
    "#8A2BE2",
    "#DC143C",
    "#7B68EE",
    "#228B22",
    "#8B4513",
    "#556B2F",
    "#800000",
    "#DA70D6",
    "#FF6347",
    "#40E0D0",
    "#8B008B",
    "#7FFF00",
    "#B0C4DE",
    "#4169E1",
  ],
  [
    "#20B2AA",
    "#00CED1",
    "#D8BFD8",
    "#ADFF2F",
    "#FF4500",
    "#CD5C5C",
    "#8B0000",
    "#D2691E",
    "#4B0082",
    "#FFFFF0",
    "#FAEBD7",
    "#FFE4B5",
    "#F0E68C",
    "#F0FFF0",
    "#FFDAB9",
    "#FFA07A",
    "#20B2AA",
  ],
];

export const colorInner = ["#3CBDC9", "#9FB328", "#F96232"];

export const dynamicOption = {};

export const unitForStatisanary = [
  {
    label: "Kcal",
    value: "Kcal",
    active: [
      30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 29, 44, 45, 46, 47,
      48, 49, 50, 51, 52, 53, 54, 56, 43,
    ],
  },
  {
    label: "mmBtu",
    value: "mmBtu",
    active: [
      30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 29, 44, 45, 46, 47,
      48, 49, 50, 53, 54, 56, 43,
    ],
  },
  {
    label: "Litre",
    value: "Litre",
    active: [30, 31, 32, 33, 53, 54, 55, 56],
  },
  {
    label: "Cubic meter",
    value: "cubic meter",
    active: [29, 51, 52],
  },
  {
    label: "Metric ton",
    value: "Metric ton",
    active: [
      34, 35, 36, 37, 38, 39, 40, 41, 42, 44, 45, 46, 47, 48, 49, 50, 43,
    ],
  },

  {
    label: "kWh",
    value: "kWh",
    active: [29, 51, 52],
  },
  {
    label: "Kg",
    value: "Kg",
    active: [],
  },
];

// 55;
// gas == { mmBtu, Kcal, kg, kWh };
