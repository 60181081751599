import React, { useEffect, useState } from "react";
import CustomPage from "../../../Custome/CustomPage";
import {
  emissionEntryCategoryWiseDataLoad,
  emissionForEditFormDataCallLoad,
  jobViewDataMask,
} from "../../../../pages/Utility/spread-function";
import CONSTANT, {
  getTableColumConfig,
} from "../../../../pages/Utility/constnt";
import {
  getRecordId,
  replaceId,
  tableHeaderToExportHeader,
} from "../../../../pages/Utility/function";
import { useLocation, useParams } from "react-router-dom";

const WasteManagmentView = (props) => {
  const {
    jobId,
    jobSingleData,
    API,
    initHeader,

    setJobViewData,
    onViewReason,
    responceStatus,
    flag,
  } = props;
  const [subCategoryModal, setSubCategoryModal] = useState([]);
  const [subHeader, setHeader] = useState([]);
  const [dynamicOption, setDynamicOption] = useState({});
  const [editData, setEditData] = useState({});
  const [formData, setFormData] = useState([]);
  const [submitformData, setSubmitFormData] = useState({});

  const [newFormData, setNewFormData] = useState({});

  const [alldynamicOption, setAllDynamicOption] = useState({});
  const [wasteTypeData, setwasteTypeData] = useState(null);
  const [wasteTreatment, setWasteTreatment] = useState([]);

  const [defaultData, setDefaultData] = useState({});
  const { id } = useParams();
  const userRole = localStorage.getItem("userRole");
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    jobSingleData &&
      API?.getAll(
        [
          CONSTANT?.API.SUBCATEGORYATTRIBUTE.get.endpoint,
          CONSTANT?.API?.ATTRIBUTEOPTION.get?.endpoint,
          CONSTANT?.API.SITE.get?.endpoint,
          CONSTANT?.API.JOB.getWasteUsed?.endpoint,
        ],
        (res) => {
          // const subCategoryAttributeRes = res[0]?.data;
          let categoryOption = {};
          const subCategoryAttributeOptionRes = res[1]?.data?.data;
          const sites =
            userRole == "admin" && currentPath.includes("/client") && id
              ? res[2]?.data?.data?.rows?.filter((el) => el?.clientId == id)
              : res[2]?.data?.data?.rows;
          const Other = {
            site: sites,
            wasteType: res[3]?.data?.data?.rows?.map((el) => {
              categoryOption = {
                ...categoryOption,
                [el.wasteType]: el?.availableTreatments,
              };
              return {
                ...el,
              };
            }),
          };
          setwasteTypeData(res[3]?.data?.data?.rows);

          const rearrangedData = res[0]?.data?.data?.rows
            ?.filter((it) => it?.subCategoryId == jobSingleData?.subCategoryId)
            ?.sort((a, b) => +a.index - +b.index);

          let arrHeader = [...initHeader];

          const dddd = emissionEntryCategoryWiseDataLoad(
            subCategoryAttributeOptionRes,
            Other,
            rearrangedData,
            setDynamicOption,
            arrHeader
          );

          setSubCategoryModal(dddd?.arr);
          setAllDynamicOption((prev) => ({
            ...prev,
            ...categoryOption,
          }));
          setHeader((pr) => {
            return [...dddd?.arrHeader, getTableColumConfig("action")];
          });
        }
      );
  }, [jobSingleData]);

  useEffect(() => {
    emissionForEditFormDataCallLoad(
      subCategoryModal,
      setDynamicOption,
      setSubCategoryModal,
      formData,
      alldynamicOption,
      editData
    );
  }, [formData, editData]);

  useEffect(() => {
    const arr = [];
    const keyOnly = subCategoryModal?.filter(
      (el) => el?.extraValue?.depenededFieldId
    );
    keyOnly?.map((el) => arr?.push(el?.extraValue?.depenededFieldId));

    if (newFormData?.[arr?.[0]]) {
      delete formData?.data?.[keyOnly?.[0]?.id]; //87
      delete formData?.data?.[keyOnly?.[1]?.id]; //89
      delete formData?.selectedValue?.[keyOnly?.[0]?.id]; //89
      delete formData?.selectedValue?.[keyOnly?.[1]?.id]; //87

      formData?.operations?.inputData(formData?.data);
      formData?.operations?.selectedValue(formData?.selectedValue);
      formData?.operations?.setDefaultValue((pr) => {
        delete pr?.[keyOnly?.[1]?.id];

        delete pr?.[keyOnly?.[0]?.id];

        return { ...pr };
      });
      setNewFormData({});

      // setSubCategoryModal((pr) => [...pr]);
    } else if (newFormData?.[arr?.[1]]) {
      delete formData?.data?.[keyOnly?.[1]?.id]; //89
      delete formData?.selectedValue?.[keyOnly?.[1]?.id]; //89

      const findUnit1 = wasteTypeData?.find(
        (el) =>
          el?.wasteType == formData?.data?.[arr?.[0]] ||
          el?.wasteType == formData?.defaultData?.[arr?.[0]]
      );

      formData?.operations?.inputData({
        ...formData?.data,
        [keyOnly?.[1]?.id]: findUnit1?.[formData?.data?.[arr?.[1]]],
      });
      formData?.operations?.selectedValue(formData?.selectedValue);
      setNewFormData({});
    }
    const fff = { ...formData?.defaultData, ...formData?.data };
    if (fff) {
      const matchingKey = Object.keys(fff).find(
        (key) => fff[key] == "Third Party" || fff[key] == "Site Owned"
      );

      if (Object?.keys(fff)?.length > 0 || newFormData?.[matchingKey]) {
        if (newFormData?.[matchingKey]) {
          delete formData?.data?.[keyOnly?.[1]?.id]; //89
          delete formData?.selectedValue?.[keyOnly?.[1]?.id]; //89

          const findUnit1 = wasteTypeData?.find(
            (el) => el?.wasteType == fff?.[arr?.[0]]
          );

          formData?.operations?.inputData({
            ...formData?.data,
            [keyOnly?.[1]?.id]: findUnit1?.[fff?.[arr?.[1]]],
          });

          setNewFormData({});
        }

        if (fff?.[matchingKey] == "Third Party") {
          setSubCategoryModal((pr) => {
            pr?.map((el) => {
              if (el?.id == keyOnly?.[1]?.id) {
                el["disabled"] = false;
              }
            });

            return [...pr];
          });
        } else {
          setSubCategoryModal((pr) => {
            pr?.map((el) => {
              if (el?.id == keyOnly?.[1]?.id) {
                el["disabled"] = true;
              }
            });

            return [...pr];
          });
        }
      }
    }
  }, [formData, editData]);
  const prepareUpdatePayload = (editPayload, payload) => {
    let objPayload = {
      data: {
        ...formData?.defaultData,
        ...formData?.data,
      },
    };
    Object?.keys(objPayload?.data)?.map((itm) => {
      objPayload.data[`id${itm}`] = payload[itm] || editPayload[itm];
    });
    delete objPayload?.data?.id;
    return {
      ...objPayload,
    };
  };

  return (
    <>
      <CustomPage
        PAGE={{
          // withBackButton: true,
          title: `JOB / ${getRecordId?.job(jobId)}`,
          dataFunction: (res, Actions) => {
            setJobViewData(res?.result);
            return jobViewDataMask(res, Actions, onViewReason, responceStatus);
          },
          pageRefresh: { flag },
        }}
        noHeader={false}
        API={{
          get: replaceId(CONSTANT?.API.JOBVIEW.get, jobId),
          update: CONSTANT?.API.JOBVIEW.update,
          delete: CONSTANT?.API.JOBVIEW.delete,
        }}
        FORM={{
          formField: subCategoryModal,

          editFormTitle: "Edit Job",
          option: {
            ...dynamicOption,
          },
          editDataPayload: (editData) => {
            setEditData(editData);
          },

          prepareUpdatePayload: prepareUpdatePayload,
          onChange: (data, selectedValue, operations, defaultData) => {
            // setEditData(data);
            const keyOnly = subCategoryModal?.find(
              (el) => el?.extraValue?.keyOrEquetion?.[0]
            );
            setWasteTreatment(data?.[keyOnly?.id]);
            setFormData({ data, selectedValue, operations, defaultData });
          },
          onSingleChange: (data) => {
            setNewFormData(data);
          },
          selectedValue: false,
        }}
        TABLE={{
          title: "Record List",
          header: subHeader,
          export: {
            fileName: `${getRecordId?.job(jobId)}_Record.csv`,
            header: tableHeaderToExportHeader?.header(subHeader),
          },
        }}
      />
    </>
  );
};

export default WasteManagmentView;
