import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  Tooltip,
  Table,
  Button,
  DatePicker,
  Checkbox,
} from "antd";
import { InfoCircleOutlined, DeleteFilled } from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";
const { Option } = Select;
const { TextArea } = Input;

// render small fields

const renderField = (fieldType, text, rowIndex, col, handleInputChange, form = null) => {
  switch (fieldType?.type) {
    case "number":
      return (
        <Input
          type="number"
          value={text}
          onChange={(e) =>
            handleInputChange(rowIndex, col.dataIndex, e.target.value, form)
          }
        />
      );
    case "dropdown":
      return (
        <Select
          value={text}
          onChange={(value) =>
            handleInputChange(rowIndex, col.dataIndex, value, form)
          }
        >
          {fieldType.options.map((option) => (
            <Option key={option.id} value={option.id}>
              {option.label}
            </Option>
          ))}
        </Select>
      );
    case "date":
      return (
        <Input
          type="date"
          value={text}
          onChange={(e) =>
            handleInputChange(rowIndex, col.dataIndex, e.target.value, form)
          }
        />
      );
    case "url":
      return (
        <Input
          type="url"
          value={text}
          onChange={(e) =>
            handleInputChange(rowIndex, col.dataIndex, e.target.value, form)
          }
        />
      );
    case "fixedText":
      return <Input value={text} disabled />;

    case "fixed":
      return <Input value={rowIndex + 1} disabled />;
    default:
      return (
        <Input
          value={text}
          onChange={(e) =>
            handleInputChange(rowIndex, col.dataIndex, e.target.value, form)
          }
        />
      );
  }
};

const DropdownQuestion = ({ ques, index }) => {
  let answerString = ques.answer;
  const fixedValue = ques?.answerSchema?.fixedValue;

  if (answerString && answerString.startsWith("[")) {
    try {
      let cleanedAnswerString = answerString.replace(/\\/g, "");
      let parsedString = JSON.parse(cleanedAnswerString);
      answerString = parsedString?.[0]?.id;
    } catch (error) {
      console.error("Error parsing JSON:", error, "Value:", answerString);
    }
  } else if (answerString === undefined) {
    answerString = "";
  }

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Form.Item
          name={ques.id}
          label={
            <span style={{ fontSize: 16, fontWeight: "bold" }}>
              {`${index + 1}. ${ques.question}${
                ques.valueType && ques.valueType.toLowerCase() === "generated"
                  ? " ✨"
                  : ""
              }`}
              {ques.tooltip && (
                <Tooltip title={ques.tooltip}>
                  <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 16 }} />
                </Tooltip>
              )}
            </span>
          }
          initialValue={fixedValue || answerString}
        >
          <Select
            labelInValue
            style={{ width: 500 }}
            disabled={!!fixedValue}
            defaultValue={{ key: fixedValue || answerString }}
          >
            {ques.options.map((option) => (
              <Option key={option.id} value={option.id}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
};

const MultiSelectDropdownQuestion = ({ ques, index }) => {
  const [customOptions, setCustomOptions] = useState([]);
  let answerString = ques.answer;
  const fixedValue = ques?.answerSchema?.fixedValue;

  if (answerString && answerString.startsWith("[")) {
    try {
      let cleanedAnswerString = answerString.replace(/\\/g, "");
      let parsedString = JSON.parse(cleanedAnswerString);
      answerString = parsedString.map((item) => item.id);
    } catch (error) {
      console.error("Error parsing JSON:", error, "Value:", answerString);
      answerString = [];
    }
  } else if (answerString === undefined) {
    answerString = [];
  }

  const handleChange = (value) => {
    const lastValue = value[value.length - 1];
    if (
      !ques.options.some((option) => option.id === lastValue) &&
      !customOptions.some((option) => option === lastValue)
    ) {
      setCustomOptions([...customOptions, lastValue]);
    }
  };

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Form.Item
          name={ques.id}
          label={
            <span style={{ fontSize: 16, fontWeight: "bold" }}>
              {`${index + 1}. ${ques.question}${
                ques.valueType && ques.valueType.toLowerCase() === "generated"
                  ? " ✨"
                  : ""
              }`}
              {ques.tooltip && (
                <Tooltip title={ques.tooltip}>
                  <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 16 }} />
                </Tooltip>
              )}
            </span>
          }
          initialValue={fixedValue || answerString}
        >
          <Select
            mode="multiple"
            placeholder="Select or add options"
            style={{ width: 500 }}
            disabled={!!fixedValue}
            defaultValue={
              fixedValue ? [fixedValue] : answerString ? [answerString] : []
            }
            onChange={handleChange}
          >
            {ques.options.map((option) => (
              <Option key={option.id} value={option.id}>
                {option.label}
              </Option>
            ))}
            {customOptions.map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
};

const TextQuestion = ({ ques, index }) => (
  <Row gutter={16} key={ques.id}>
    <Col span={24}>
      <Form.Item
        name={ques.id}
        label={
          <span style={{ fontSize: 16, fontWeight: "bold" }}>
            {`${index + 1}. ${ques.question}${
              ques.valueType && ques.valueType.toLowerCase() === "generated"
                ? " ✨"
                : ""
            }`}
            {ques.tooltip && (
              <Tooltip title={ques.tooltip}>
                <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 16 }} />
              </Tooltip>
            )}
          </span>
        }
        initialValue={ques.answer}
      >
        <Input rows={2} type="text" />
      </Form.Item>
    </Col>
  </Row>
);

const AddressQuestion = ({ ques, index }) => (
  <div key={ques.id}>
    <Row gutter={[16, 8]}>
      <Col span={24}>
        <Form.Item
          label={
            <span style={{ fontSize: 16, fontWeight: "bold" }}>
              {`${index + 1}. ${ques.question}`}
            </span>
          }
          labelCol={{ span: 24 }} // Adjust this value if necessary
          wrapperCol={{ span: 24 }} // Adjust this value if necessary
        >
          <Form.Item
            name={[ques.id, "addressLine1"]}
            label="Address Line 1"
            rules={[{ required: true, message: "Address Line 1 is required" }]}
          >
            <Input />
          </Form.Item>
          <Row gutter={[16, 8]}>
            <Col span={12}>
              <Form.Item
                name={[ques.id, "city"]}
                label="City"
                rules={[{ required: true, message: "City is required" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={[ques.id, "district"]}
                label="District"
                rules={[{ required: true, message: "District is required" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col span={12}>
              <Form.Item
                name={[ques.id, "state"]}
                label="State"
                rules={[{ required: true, message: "State is required" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={[ques.id, "country"]}
                label="Country"
                rules={[{ required: true, message: "Country is required" }]}
              >
                <Select placeholder="Select a country">
                  {/* Add more country options as needed */}
                  <Option value="usa">USA</Option>
                  <Option value="india">India</Option>
                  <Option value="uk">UK</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col span={24}>
              <Form.Item
                name={[ques.id, "pincode"]}
                label="Pincode"
                rules={[{ required: true, message: "Pincode is required" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
      </Col>
    </Row>
  </div>
);

const LongTextQuestion = ({ ques, index }) => (
  <Row gutter={16} key={ques.id}>
    <Col span={24}>
      <Form.Item
        name={ques.id}
        label={
          <span style={{ fontSize: 16, fontWeight: "bold" }}>
            {`${index + 1}. ${ques.question}${
              ques.valueType && ques.valueType.toLowerCase() === "generated"
                ? " ✨"
                : ""
            }`}
            {ques.tooltip && (
              <Tooltip title={ques.tooltip}>
                <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 16 }} />
              </Tooltip>
            )}
          </span>
        }
        initialValue={ques.answer}
        // labelCol={{ span: 24 }}  // Adjust this value if necessary
        // wrapperCol={{ span: 24 }}  // Adjust this value if necessary
      >
        <TextArea rows={2} />
      </Form.Item>
    </Col>
  </Row>
);

const EmailQuestion = ({ ques, index }) => (
  <Row gutter={16} key={ques.id}>
    <Col span={24}>
      <Form.Item
        name={ques.id}
        label={
          <span style={{ fontSize: 16, fontWeight: "bold" }}>
            {`${index + 1}. ${ques.question}${
              ques.valueType && ques.valueType.toLowerCase() === "generated"
                ? " ✨"
                : ""
            }`}
            {ques.tooltip && (
              <Tooltip title={ques.tooltip}>
                <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 16 }} />
              </Tooltip>
            )}
          </span>
        }
        rules={[{ type: "email", message: "The input is not a valid email!" }]}
        initialValue={ques.answer}
        className="fw-bold"
      >
        <Input type="email" />
      </Form.Item>
    </Col>
  </Row>
);

const URLQuestion = ({ ques, index }) => (
  <Row gutter={16} key={ques.id}>
    <Col span={24}>
      <Form.Item
        name={ques.id}
        label={
          <span style={{ fontSize: 16, fontWeight: "bold" }}>
            {`${index + 1}. ${ques.question}${
              ques.valueType && ques.valueType.toLowerCase() === "generated"
                ? " ✨"
                : ""
            }`}
            {ques.tooltip && (
              <Tooltip title={ques.tooltip}>
                <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 16 }} />
              </Tooltip>
            )}
          </span>
        }
        initialValue={ques.answer}
        className="fw-bold"
      >
        <Input type="url" />
      </Form.Item>
    </Col>
  </Row>
);

const NumberQuestion = ({ ques, index }) => (
  <Row gutter={16} key={ques.id}>
    <Col span={24}>
      <Form.Item
        name={ques.id}
        label={
          <span style={{ fontSize: 16, fontWeight: "bold" }}>
            {`${index + 1}. ${ques.question}${
              ques.valueType && ques.valueType.toLowerCase() === "generated"
                ? " ✨"
                : ""
            }`}
            {ques.tooltip && (
              <Tooltip title={ques.tooltip}>
                <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 16 }} />
              </Tooltip>
            )}
          </span>
        }
        initialValue={ques.answer}
        className="fw-bold"
      >
        <Input type="number" style={{ width: "500px" }} />
      </Form.Item>
    </Col>
  </Row>
);

const YearQuestion = ({ ques, index }) => (
  <Row gutter={16} key={ques.id}>
    <Col span={24}>
      <Form.Item
        name={ques.id}
        label={
          <span style={{ fontSize: 16, fontWeight: "bold" }}>
            {`${index + 1}. ${ques.question}${
              ques.valueType && ques.valueType.toLowerCase() === "generated"
                ? " ✨"
                : ""
            }`}
            {ques.tooltip && (
              <Tooltip title={ques.tooltip}>
                <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 16 }} />
              </Tooltip>
            )}
          </span>
        }
        rules={[
          {
            message: "Please enter a valid year!",
            pattern: /^(19|20)\d{2}$/, // Validates years from 1900 to 2099
          },
        ]}
        initialValue={ques.answer}
        className="fw-bold"
      >
        <Input
          type="number"
          className="appearance-none"
          style={{
            WebkitAppearance: "none",
            MozAppearance: "textfield",
          }}
        />
      </Form.Item>
    </Col>
  </Row>
);

const DateQuestion = ({ ques, index }) => (
  <Row gutter={16} key={ques.id}>
    <Col span={24}>
      <Form.Item
        name={ques.id}
        label={
          <span style={{ fontSize: 16, fontWeight: "bold" }}>
            {`${index + 1}. ${ques.question}${
              ques.valueType && ques.valueType.toLowerCase() === "generated"
                ? " ✨"
                : ""
            }`}
            {ques.tooltip && (
              <Tooltip title={ques.tooltip}>
                <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 16 }} />
              </Tooltip>
            )}
          </span>
        }
        rules={[
          {
            message: "Please enter a valid date!",
            pattern: /^\d{4}-\d{2}-\d{2}$/, // Validates dates in the format YYYY-MM-DD
          },
        ]}
        initialValue={ques.answer}
        className="fw-bold"
      >
        <Input
          type="date"
          className="appearance-none"
          style={{
            WebkitAppearance: "none",
            MozAppearance: "textfield",
          }}
        />
      </Form.Item>
    </Col>
  </Row>
);

const TelephoneQuestion = ({ ques, index }) => (
  <Row gutter={16} key={ques.id}>
    <Col span={24}>
      <Form.Item
        label={
          <span style={{ fontSize: 16, fontWeight: "bold" }}>
            {`${index + 1}. ${ques.question}`}
          </span>
        }
        labelCol={{ span: 24 }} // Adjust this value if necessary
        wrapperCol={{ span: 24 }} // Adjust this value if necessary
      >
        <Form.Item
          name={ques.id}
          initialValue={ques.answer}
          rules={[{ required: true, message: "Phone number is required" }]}
        >
          <PhoneInput
            country={"in"}
            enableSearch={true}
            containerStyle={{ width: "100%" }}
            inputStyle={{ width: "100%" }}
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: true,
            }}
          />
        </Form.Item>
      </Form.Item>
    </Col>
  </Row>
);

const DynamicTables = ({
  ques,
  index,
  dataSource,
  setDataSource,
  columns,
  parentValue,
  form,
  setMedicalBenefits,
}) => {
  if (!ques.answerSchema) {
    return <div>No Data</div>;
  }

  const dependency = ques?.answerSchema?.dependency;
  const parentQuestion = dependency?.parentQuestion || null;
  const visibleOn = dependency?.visibleOn || null;
  // useEffect(() => {
  //   if (ques.id === 32) {
  //     const updatedStakeholder = dataSource
  //       .filter(item => Object.values(item).some(value => value === "Yes"))
  //       .map(item => {
  //         const key = Object.keys(item).find(key => key.endsWith(":stakeholder"));
  //         return item[key];
  //       });
  //     console.log("updatedstakeholder", updatedStakeholder);
  //     setStakeholder(updatedStakeholder);
  //   }
  // }, [dataSource, ques.id, setStakeholder]);

  const handleAddRow = () => {
    const newRow = {};
    const index = dataSource.length;
    columns.forEach((col) => {
      newRow[`${index + 1}:${col.dataIndex}`] =
        col.fieldType?.type === "fixed" ? index + 1 : "";
    });
    setDataSource([...dataSource, newRow]);
  };

  const handleDeleteRow = (rowIndex) => {
    const newData = dataSource.filter((item, idx) => idx !== rowIndex);
    setDataSource(newData);
    // ques.id === 32 ||
    if (ques.id === 107) {
      const updatedMedicalBenefits = newData
        .filter((item) => Object.values(item).includes("Yes"))
        .map((item) => {
          const key = Object.keys(item).find((key) =>
            key.endsWith(":benefits")
          );
          return item[key];
        });
      // stakeholder for qid = 32
      setMedicalBenefits(updatedMedicalBenefits);
    } else if (ques.id === 32){
      const updatedMedicalBenefits = newData
        .filter((item) => Object.values(item).includes("Yes"))
        .map((item) => {
          const key = Object.keys(item).find((key) =>
            key.endsWith(":stakeholder")
          );
          return item[key];
        });
      // stakeholder for qid = 32
      setMedicalBenefits(updatedMedicalBenefits);
    }
    // console.log("newdataafter", newData);

    // if (ques.id === 32) {
    //   const updatedStakeholder = newData
    //     .filter(item => Object.values(item).some(value => value === "Yes"))
    //     .map(item => {
    //       const key = Object.keys(item).find(key => key.endsWith(":stakeholder"));
    //       return item[key];
    //     });
    //   setStakeholder(updatedStakeholder);
    // }
  };

  const handleInputChange = (rowIndex, dataIndex, value, form) => {

    const newData = [...dataSource];
    newData[rowIndex][`${rowIndex + 1}:${dataIndex}`] = value;
    setDataSource(newData);
    // if (ques.id === 32) {
    //   const updatedStakeholder = newData
    //     .filter(item => Object.values(item).includes("Yes"))
    //     .map(item => {
    //       const key = Object.keys(item).find(key => key.endsWith(":stakeholder"));
    //       return item[key];
    //     });
    //   console.log("stakeholderlist", updatedStakeholder);
    //   setStakeholder(updatedStakeholder);
    // }

    if (ques.id === 107) {
      const updatedMedicalBenefits = newData
        .filter((item) => Object.values(item).includes("Yes"))
        .map((item) => {
          const key = Object.keys(item).find((key) =>
            key.endsWith(":benefits")
          );
          return item[key];
        });
      // console.log("medicalbeneiits", updatedMedicalBenefits);
      setMedicalBenefits(updatedMedicalBenefits);
      // form.setFieldValue(ques.id, value);
    } else if (ques.id === 32){
      const updatedMedicalBenefits = newData
        .filter((item) => Object.values(item).includes("Yes"))
        .map((item) => {
          const key = Object.keys(item).find((key) =>
            key.endsWith(":stakeholder")
          );
          return item[key];
        });
      // console.log("medicalbeneiits", updatedMedicalBenefits);
      setMedicalBenefits(updatedMedicalBenefits);
    }
  };

  const reformatDataSource = (dataSource) => {
    return dataSource.map((item) => {
      const formattedItem = {};
      for (const key in item) {
        const dataIndex = key.substring(key.indexOf(":") + 1);
        formattedItem[dataIndex] = item[key];
      }
      // console.log("dataSource", formattedItem);
      return formattedItem;
    });
  };

  const dynamicColumns = columns.map((col) => ({
    ...col,
    render: (text, record, rowIndex) => {
      if (
        !dependency ||
        parentValue === visibleOn ||
        parentValue?.value === visibleOn
      ) {
        return renderField(
          col.fieldType,
          text,
          rowIndex,
          col,
          handleInputChange,
          form
        );
      }
      return <Input value="NA" disabled />;
    },
  }));

  dynamicColumns.push({
    title: "Action",
    key: "action",
    render: (text, record, rowIndex) => (
      <Button
        type="link"
        onClick={() => handleDeleteRow(rowIndex)}
        icon={<DeleteFilled style={{ color: "red" }} />}
      />
    ),
  });

  return (
    <Row gutter={16} key={ques.id}>
      <Col span={24}>
        <Form.Item
          name={ques.id}
          label={
            <span style={{ fontSize: 16, fontWeight: "bold" }}>
              {`${index + 1}. ${ques.question}${
                ques.valueType && ques.valueType.toLowerCase() === "generated"
                  ? " ✨"
                  : ""
              }`}
              {ques.tooltip && (
                <Tooltip
                  title={
                    <span dangerouslySetInnerHTML={{ __html: ques.tooltip }} />
                  }
                  // title={ques.tooltip}
                >
                  <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 16 }} />
                </Tooltip>
              )}
            </span>
          }
        >
          <Table
            dataSource={reformatDataSource(dataSource)}
            columns={dynamicColumns}
            bordered
            pagination={false}
          />
          <Button
            onClick={handleAddRow}
            style={{
              marginTop: "20px",
              backgroundColor: "#060DBF",
              color: "#62C941",
              height: "40px",
              cursor: "pointer",
            }}
          >
            Add Row
          </Button>
        </Form.Item>
      </Col>
    </Row>
  );
};

const DynamicTable = ({
  ques,
  index,
  dataSource,
  setDataSource,
  columns,
  parentValue,
}) => {
  if (!ques.answerSchema) {
    return <div>No Data</div>;
  }

  const dependency = ques?.answerSchema?.dependency;
  const parentQuestion = dependency?.parentQuestion || null;
  const visibleOn = dependency?.visibleOn || null;

  const handleAddRow = () => {
    const newRow = {};
    const index = dataSource.length;
    columns.forEach((col) => {
      newRow[`${index + 1}:${col.dataIndex}`] =
        col.fieldType?.type === "fixed" ? index + 1 : "";
    });
    setDataSource([...dataSource, newRow]);
  };

  const handleDeleteRow = (rowIndex) => {
    const newData = dataSource.filter((item, idx) => idx !== rowIndex);
    setDataSource(newData);
  };

  const handleInputChange = (rowIndex, dataIndex, value) => {
    const newData = [...dataSource];
    newData[rowIndex][`${rowIndex + 1}:${dataIndex}`] = value;
    setDataSource(newData);
  };

  const reformatDataSource = (dataSource) => {
    return dataSource.map((item) => {
      const formattedItem = {};
      for (const key in item) {
        const dataIndex = key.substring(key.indexOf(":") + 1);
        formattedItem[dataIndex] = item[key];
      }
      return formattedItem;
    });
  };

  const dynamicColumns = columns.map((col) => ({
    ...col,
    render: (text, record, rowIndex) => {
      if (
        !dependency ||
        parentValue === visibleOn ||
        parentValue?.value === visibleOn
      ) {
        return renderField(
          col.fieldType,
          text,
          rowIndex,
          col,
          handleInputChange
        );
      }
      return <Input value="NA" disabled />;
    },
  }));

  dynamicColumns.push({
    title: "Action",
    key: "action",
    render: (text, record, rowIndex) => (
      <Button
        type="link"
        onClick={() => handleDeleteRow(rowIndex)}
        icon={<DeleteFilled style={{ color: "red" }} />}
      />
    ),
  });

  return (
    <Row gutter={16} key={ques.id}>
      <Col span={24}>
        <Form.Item
          name={ques.id}
          label={
            <span style={{ fontSize: 16, fontWeight: "bold" }}>
              {`${index + 1}. ${ques.question}${
                ques.valueType && ques.valueType.toLowerCase() === "generated"
                  ? " ✨"
                  : ""
              }`}
              {ques.tooltip && (
                <Tooltip
                  title={
                    <span dangerouslySetInnerHTML={{ __html: ques.tooltip }} />
                  }
                  // title={ques.tooltip}
                >
                  <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 16 }} />
                </Tooltip>
              )}
            </span>
          }
        >
          <Table
            dataSource={reformatDataSource(dataSource)}
            columns={dynamicColumns}
            bordered
            pagination={false}
          />
          <Button
            onClick={handleAddRow}
            style={{
              marginTop: "20px",
              backgroundColor: "#060DBF",
              color: "#62C941",
              height: "40px",
              cursor: "pointer",
            }}
          >
            Add Row
          </Button>
        </Form.Item>
      </Col>
    </Row>
  );
};

const TransFormToDynamic = ({ answer, answerSchema }) => {
  if (
    !answerSchema ||
    !answerSchema.type ||
    answerSchema.type != "dynamictable"
  )
    return {};
  const { column_headers } = answerSchema;
  const emptyObj = {};
  const columns = column_headers.map((val, index) => {
    emptyObj[`1:${val.id}`] = "";
    return {
      title: val.name,
      dataIndex: val.id,
      key: val.id,
      fieldType: val.fieldType,
    };
  });

  let data = [emptyObj];
  if (answer) {
    try {
      data = JSON.parse(answer);
    } catch (error) {
      console.error("Error parsing JSON for dynamic table data:", error);
    }
  }

  return { data, columns };
};
const TransFormToDynamics = ({ answer, answerSchema }) => {
  if (
    !answerSchema ||
    !answerSchema.type ||
    answerSchema.type != "dynamictables"
  )
    return {};
  const { column_headers, rows_headers, key_map } = answerSchema;
  const emptyObj = {};
  const columns = column_headers.map((val, index) => {
    emptyObj[`1:${val.id}`] = "";
    return {
      title: val.name,
      dataIndex: val.id,
      key: val.id,
      fieldType: val.fieldType,
    };
  });

  let data = [emptyObj];
  data = rows_headers?.map((rowHeader) => {
    const rowData = {};
    const rowId = rowHeader[0].id;
    rowData[`${rowId}:${columns[0].dataIndex}`] = rowHeader[0].name;
    // key_map.columns.forEach((colId) => {
    //   rowData[`${rowId}:${colId}`] = answer?.[rowId]?.[colId] || "";
    // });
    return rowData;
  });
  if (answer) {
    try {
      data = JSON.parse(answer);
    } catch (error) {
      console.error("Error parsing JSON for dynamic table data:", error);
    }
  }
  return { data1: data, columns1: columns };
};
const TransFormToFixed = ({ answer, answerSchema }) => {
  if (!answerSchema || !answerSchema.type || answerSchema.type !== "fixedtable")
    return {};

  const { column_headers, rows_headers, key_map } = answerSchema;

  // Define columns
  const columns = column_headers[0].map((header) => ({
    title: header.name,
    dataIndex: header.id,
    key: header.id,
    fieldType: header.fieldType,
  }));

  // Initialize the data source with row headers and answer values

  let dataFixed = rows_headers?.map((rowHeader) => {
    const rowData = {};
    // console.log("Rowheader", rowHeader);
    const rowId = rowHeader[0].id;
    // rowData[`${rowId}`] = rowHeader[0].name;
    rowData[`${rowId}:${columns[0].dataIndex}`] = rowHeader[0].name;
    // key_map.columns.forEach((colId) => {
    //   rowData[`${rowId}:${colId}`] = answer?.[rowId]?.[colId] || "";
    // });
    return rowData;
  });
  if (answer) {
    try {
      dataFixed = JSON.parse(answer);
    } catch (error) {
      console.error("Error parsing JSON for dynamic table data:", error);
    }
  }
  return { dataFixed, columnsFixed: columns };
};

const FixedTable = ({ ques, index, dataSource, setDataSource, columns }) => {
  if (!ques.answerSchema) {
    return <div>No answer schema defined</div>;
  }

  const handleInputChange = (rowIndex, dataIndex, value) => {
    const newData = [...dataSource];
    newData[rowIndex][dataIndex] = value;
    setDataSource(newData);
  };

  const dynamicColumns = columns.map((col) => ({
    ...col,
    render: (text, record, rowIndex) =>
      renderField(col.fieldType, text, rowIndex, col, handleInputChange),
  }));

  const reformatDataSource = (dataSource) => {
    return dataSource?.map((item) => {
      const formattedItem = {};
      for (const key in item) {
        const dataIndex = key.substring(key.indexOf(":") + 1);
        formattedItem[dataIndex] = item[key];
      }
      return formattedItem;
    });
  };

  return (
    <Row gutter={16} key={ques.id}>
      <Col span={24}>
        <Form.Item
          name={ques.id}
          label={
            <span style={{ fontSize: 16, fontWeight: "bold" }}>
              {`${index + 1}. ${ques.question}${
                ques.valueType && ques.valueType.toLowerCase() === "generated"
                  ? " ✨"
                  : ""
              }`}
            </span>
          }
        >
          <Table
            dataSource={reformatDataSource(dataSource)}
            columns={dynamicColumns}
            bordered
            pagination={false}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

const ContactInfoQuestion = ({ ques, index }) => (
  <div key={ques.id}>
    <Row gutter={[16, 8]}>
      <Col span={24}>
        <Form.Item
          name={[ques.id, "name"]}
          label={
            <span style={{ fontSize: 16, fontWeight: "bold" }}>
              {`${index + 1}. ${ques.question}${
                ques.valueType && ques.valueType.toLowerCase() === "generated"
                  ? " ✨"
                  : ""
              }`}
              {ques.tooltip && (
                <Tooltip
                  title={
                    <span dangerouslySetInnerHTML={{ __html: ques.tooltip }} />
                  }
                >
                  <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 16 }} />
                </Tooltip>
              )}
            </span>
          }
          initialValue={ques.answer?.name}
          // rules={[{ required: true, message: "Name is required" }]}
        >
          <Input placeholder="Enter name" />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={[16, 8]}>
      <Col span={12}>
        <Form.Item
          name={[ques.id, "telephone"]}
          label="Telephone"
          initialValue={ques.answer?.telephone}
          rules={[{ required: true, message: "Telephone number is required" }]}
        >
          <PhoneInput
            country={"us"}
            placeholder="Enter telephone number"
            inputStyle={{ width: "100%" }}
            containerStyle={{ width: "100%" }}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name={[ques.id, "email"]}
          label="Email"
          initialValue={ques.answer?.email}
          rules={[
            { required: true, message: "Email is required" },
            { type: "email", message: "Enter a valid email address" },
          ]}
        >
          <Input placeholder="Enter email" />
        </Form.Item>
      </Col>
    </Row>
  </div>
);

const Questions = ({
  ques,
  index,
  form,
  setMedicalBenefits,
  medicalBenefits,
}) => {
  const { data, columns } = TransFormToDynamic(ques);
  const { data1, columns1 } = TransFormToDynamics(ques);
  const { dataFixed, columnsFixed } = TransFormToFixed(ques);
  const [dataSourceFixed, setDataSourceFixed] = useState(dataFixed);
  const [dataSource, setDataSource] = useState(data);
  const [dataSource1, setDataSource1] = useState(data1);

  // watch parent value
  const dependency = ques?.answerSchema?.dependency;
  const parentQuestion = dependency?.parentQuestion || null;
  const parentValue = Form.useWatch(parentQuestion, form);

  useEffect(() => {
    ques.transformedAnswer = dataSource;
  }, [dataSource]);
  useEffect(() => {
    ques.transformedAnswer = dataSourceFixed;
  }, [dataSourceFixed]);
  useEffect(() => {
    ques.transformedAnswer = dataSource1;
  }, [dataSource1]);

  useEffect(() => {
    let dataSchema = ques.answerSchema;
    if ((ques.id >= 109 && ques.id <= 116) || (ques.id >= 33 && ques.id <= 37) ) {
      dataSchema.rows_headers = medicalBenefits?.map((item) => [
        { name: item, id: item?.toLowerCase().replace(/ /g, "_") },
      ]);
      dataSchema.key_map.rows = medicalBenefits?.map((item) =>
        item?.toLowerCase().replace(/ /g, "_")
      );
      const { dataFixed, columnsFixed } = TransFormToFixed(ques);
      setDataSourceFixed(dataFixed);
    }
    // else if (ques.id >= 121 && ques.id <= 126) {
    //   dataSchema.rows_headers = retirementBenefits?.map((item) => [
    //     { name: item, id: item.toLowerCase().replace(/ /g, "_") },
    //   ]);
    //   dataSchema.key_map.rows = retirementBenefits?.map((item) =>
    //     item.toLowerCase().replace(/ /g, "_")
    //   );
    // }

    //   return {
    //     id: ques.id,
    //     question: ques.description,
    //     options: dataSchema.options,
    //     answerSchema: dataSchema,
    //     type: dataSchema.type,
    //     answer: ques?.Answers[0]?.value,
    //     valueType: ques?.Answers[0]?.valueType,
    //     tooltip: getTooltipText?.[ques.id],
    //   };
    // });
  }, [medicalBenefits]);

  switch (ques.type) {
    case "dropdown":
      return <DropdownQuestion ques={ques} index={index} />;
    case "multiselect":
      return <MultiSelectDropdownQuestion ques={ques} index={index} />;
    case "text":
      return <TextQuestion ques={ques} index={index} />;
    case "alphanumeric":
    case "longtext":
      return <LongTextQuestion ques={ques} index={index} />;
    case "email":
      return <EmailQuestion ques={ques} index={index} />;
    case "url":
      return <URLQuestion ques={ques} index={index} />;
    case "number":
      return <NumberQuestion ques={ques} index={index} />;
    case "year":
      return <YearQuestion ques={ques} index={index} />;
    case "telephone":
      return <TelephoneQuestion ques={ques} index={index} />;
    case "date":
      return <DateQuestion ques={ques} index={index} />;
    case "address":
      return <AddressQuestion ques={ques} index={index} />;
    case "contactInfo":
      return <ContactInfoQuestion ques={ques} index={index} />;
    case "fixedtable":
      return (
        <FixedTable
          ques={ques}
          index={index}
          dataSource={dataSourceFixed}
          setDataSource={setDataSourceFixed}
          columns={columnsFixed}
        />
      );
    case "dynamictable":
      return (
        <DynamicTable
          ques={ques}
          index={index}
          dataSource={dataSource}
          setDataSource={setDataSource}
          columns={columns}
          parentValue={parentValue}
        />
      );
    case "dynamictables":
      return (
        <DynamicTables
          ques={ques}
          index={index}
          dataSource={dataSource1}
          setDataSource={setDataSource1}
          columns={columns1}
          parentValue={parentValue}
          form={form}
          setMedicalBenefits={setMedicalBenefits}
        />
      );
    default:
      return null;
  }
};

export default Questions;