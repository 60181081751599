import CustomPage from "../../../components/Custome/CustomPage";
import CONSTANT, { TABLE_HEADER, departmentList } from "../../Utility/constnt";
import { brsrSpocsHeaderData } from "../../Utility/exportValue";
import { brsrSpocDataMask } from "../../Utility/spread-function";
import { useParams } from "react-router-dom";
import { createEndPoint } from "../../Utility/function";

const SPOCsTab = () => {
  const { id } = useParams();
  const role = localStorage.getItem("userRole");

  return (
    <div className="w-full">
      <CustomPage
        PAGE={{
          title: "Users",
          dataFunction: (res, Actions) => brsrSpocDataMask(res, Actions),
        }}
        API={{
          ...CONSTANT.API.BRSR_SPOC,
          get: createEndPoint.get(
            role === "admin" ? `${role}/spoc?clientId=${id}` : `${role}/spoc`
          ),
        }}
        FORM={{
          formField: CONSTANT.FORM_FIELDS?.BRSR_SPOC,
          addFormTitle: "Add user",
          editFormTitle: "Edit user",
          addNewRecordCtaText: "Add user",
          extraConstPayload: [{ id: "clientId", value: id }],
          // viewModel: {
          //   setVerifyData: setSpoc,
          //   viewModelData: spoc,
          //   viewModelLoading: API_CALL?.isLoading,
          //   errorMessage: errorMessage,
          //   onVerifyHandler: verifyHandler,
          // },
          option:{
            department: departmentList?.map(d => {
              return { label: d, value: d }
            })
          }
        }}
        TABLE={{
          title: "Users list",
          header: TABLE_HEADER?.brsrSpoc,
          export: {
            fileName: "Spocs_List.csv",
            header: brsrSpocsHeaderData,
          },
        }}
      />
    </div>
  );
};

export default SPOCsTab;
