import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

let fiscalyear = "";
const today = new Date();
if (today.getMonth() + 1 <= 3) {
  fiscalyear = today.getFullYear() - 1;
} else {
  fiscalyear = today.getFullYear();
}

const CardGen = (props) => {
  const { CardData } = props;
  return (
    <>
      {CardData?.map((data, index) => {
        return (
          <Col key={index} sm={4}>
            <Card>
              <CardBody>
                <div className="d-flex align-items-center">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary rounded">
                      <i className={data.icon}></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2 ">
                      <p
                        style={{
                          fontWeight: "700",
                          color: "#000",
                          padding: "0",
                          margin: "0",
                        }}
                      >
                        {data.value}
                      </p>

                      <span style={{ fontSize: "13px" }}>{data?.name}</span>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        );
      })}
    </>
  );
};

export default CardGen;
