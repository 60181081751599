import React, { useEffect, useState } from "react";
import moment from "moment";
import { driverDataMask } from "../../Utility/spread-function";
import CustomPage from "../../../components/Custome/CustomPage";
import useHttp from "../../../components/Hook/Use-http";
import { driverCardDetails } from "../../Utility/models/view-details";
import CONSTANT, { TABLE_HEADER } from "../../Utility/constnt";
import defaultImage from "../../../assets/images/UserImage.jpg";
import notify from "../../Utility/coustemFunction";
import {
  base64ToFile,
  getExtraConstPayload,
  isNotEmpty,
  notifications,
} from "../../Utility/function";
import { driverHeaderData } from "../../Utility/exportValue";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDriverStart,
  getDriverStart,
} from "../../../store/driverFinal/actions";

const Driver = () => {
  const [driverData, setDriverData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const API_CALL = useHttp();

  const getDriverData = useSelector((state) => state.driverDetailData);

  const verifyHandler = (res) => {
    if (
      isNotEmpty(driverData?.dateOfBirthDate) &&
      isNotEmpty(driverData?.drivingLicenseNumber?.trim())
    ) {
      API_CALL.sendRequest(
        CONSTANT.API.DRIVER.addDriverVerifyByLicense,
        (res) => {
          if (res?.data?.Succeeded) {
            setErrorMessage(null);
            setDriverData((prev) => {
              const resData = res?.data?.Succeeded?.data?.result;
              return {
                ...prev,
                ...resData,
                gender: resData?.gender || "",
                blood_group: resData?.blood_group || "",
                temporary_address: resData?.temporary_address || "",
                profilePic: resData?.image
                  ? base64ToFile(resData?.image, "profilePic")
                  : defaultImage,
                dl_no: resData["dl_no."],
              };
            });
          } else if (res?.data?.Failed) {
            setErrorMessage(res?.data?.Failed?.statusMessage);
          }
        },
        {
          dlnumber: driverData?.drivingLicenseNumber?.trim(),
          dob: driverData?.dateOfBirthDate ,
        }
      );
    } else {
      notifications.error(
        "Please enter valid date of birth and driving license number"
      );
    }
  };

  return (
    <div className="page-content">
      <CustomPage
        PAGE={{
          title: "Driver",
          dataFunction: driverDataMask,
        }}
        API={CONSTANT.API.DRIVER}
        REDUX={{
          getDataRedux: getDriverData?.users,
          getApiDataRedux: getDriverStart(),
          deleteApiDataRedux: deleteDriverStart,
          getData: { getDriverData },
        }}
        FORM={{
          formField: CONSTANT.FORM_FIELDS?.DRIVER,
          addFormTitle: "Add driver",
          editFormTitle: "Edit driver",
          addNewRecordCtaText: "Add driver",
          removeFieldOnEdit: ["drivingLicense", "dateOfBirth"],
          isSubmitButtonVisible: driverData?.dob ? true : false,
          extraConstPayload: driverData?.dob
            ? getExtraConstPayload(driverData)
            : [],
          onChange: (data) => {
            if (data?.dateOfBirth) {
              setDriverData((prev) => {
                return {
                  ...prev,
                  dateOfBirthDate: moment
                    .utc(data?.dateOfBirth, "YYYY-MM-DD")
                    .format("DD-MM-YYYY"),
                };
              });
            }

            if (data?.drivingLicense) {
              setDriverData((prev) => {
                return {
                  ...prev,
                  drivingLicenseNumber: data?.drivingLicense,
                };
              });
            }
          },

          viewModel: {
            setVerifyData: setDriverData,
            viewModelData: driverData,
            viewModelImage: driverData?.image,
            cardVisibleOn: "dl_no",
            viewModelLoading: API_CALL?.isLoading,
            viewModelDetails: driverCardDetails,
            errorMessage: errorMessage,
            onVerifyHandler: verifyHandler,
          },
        }}
        TABLE={{
          title: "Driver list",
          header: TABLE_HEADER?.driver,
          export: {
            fileName: "Driver_Report.csv",
            header: driverHeaderData,
          },
        }}
        //redux
        // getData={getDriverData}                     //redux
        formData={true}
      />
    </div>
  );
};

export default Driver;
