import { Col, Row, Tabs } from "antd";
import { BRSRTabsList } from "../../Utility/constnt";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ScoreTab from "../../BRSR/tabs/ScoreTab";
import FormsTab from "../../BRSR/tabs/FormsTab";

const SpocBrsrPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location?.pathname;
  const searchQuery = location?.search;
  const [tabIndex, setTabIndex] = useState("2");
  const role = localStorage.getItem("userRole");

  // To switch tab based upon search params
  useEffect(() => {
    if (!searchQuery.includes("?tab")) return;
    let tab = searchQuery.split("=")[1];

    switch (tab) {
      // case "score":
      //   setTabIndex("1");
      //   break;
      case "forms":
        setTabIndex("2");
        break;
      default:
        setTabIndex("2");
    }
  }, [searchQuery]);

  const onChange = (key) => {
    switch (key) {
      // case "1":
      //   setTabIndex(key);
      //   navigate(`${currentPath}?tab=score`);
      //   break;

      case "2":
        setTabIndex(key);
        navigate(`${currentPath}?tab=forms`);
        break;

    }
  };

  const tabSelected = () => {
    switch (tabIndex) {
      // case "1":
      //   return <ScoreTab />;
      case "2":
        return <FormsTab />;

      default:
        return <FormsTab />;
    }
  };

  return (
    <div className="brsr-page">
      <Row>
        <Col xs={24}>
          <p className="brsr-heading-text">
            Business Responsibility & Sustainability Reporting (BRSR)
          </p>
        </Col>
      </Row>
      <Row className="w-full">
        <Tabs
          className="pcf-tabs w-full"
          activeKey={`${tabIndex}`}
          items={BRSRTabsList.filter((tab) => tab.key === "2")}
          onChange={onChange}
        />
      </Row>
      <Row>{tabSelected()}</Row>
    </div>
  );
};

export default SpocBrsrPage;
