import React, { useState, useEffect } from "react";
import PageTitle from "../../../components/pageTitle";
import { CardBody, Col, Label, Row, Spinner } from "reactstrap";
import { Button, Card, Form, Input, Typography, Select, Space } from "antd";
import { Switch } from "antd";
import CustomModalMain from "../../../components/Custome/CustomModalMain";
import CONSTANT from "../../Utility/constnt";
import { CloseOutlined } from "@ant-design/icons";
import useHttp from "../../../components/Hook/Use-http";
import { useParams } from "react-router-dom";
const ClientMetricData = () => {
  const [filledFYData, setFilledFYData] = useState({});
  const [inputData, setInputData] = useState(null);
  const { id } = useParams();
  const role = localStorage.getItem("userRole");
  const [calendarYear, setCalendarYear] = useState(false);

  const onSubmitForm = (payload) => {
    (async () => {
      API_CALL.sendRequest(
        CONSTANT.API.IntensityMetrics.post,
        null,
        payload,
        "Your data added successfully"
      );
    })();
  };
  const API_CALL = useHttp();

  useEffect(() => {
    let metricData = { ...CONSTANT.API.IntensityMetrics.get };
    // need to confirm with yasin to update the response
    metricData.endpoint = `${metricData.endpoint}/${id}/?value=2023-24`;
    API_CALL.getAll([metricData.endpoint], (res) => {
      let data = res[0]?.data?.data;
      setFilledFYData(data);
    });
  }, []);

  return (
    <Card>
      <CardBody>
        <Row>
          <Col>
          <span>Want to choose calendar year?   </span>
            <Switch
              onChange={() => {
                setCalendarYear(!calendarYear);
              }}
            />
          </Col>
        </Row>
        <CustomModalMain
          data={(calendarYear == true ? CONSTANT.FORM_FIELDS[`clientIntensityMetricsCalendarYear`] : CONSTANT.FORM_FIELDS[`clientIntensityMetricsFY`])}
          isEdit={true}
          defaultData={filledFYData?.[1]}
          //   defaultData={userDetail}
          labelSize={"25%"}
          submitBtnPosition={"center"}
          fieldSize={"70%"}
          submitBtnName="Submit"
          onSubmit={(data) => {
            onSubmitForm(inputData);
          }}
          isCloseButtonVisible={false}
          minHeight={"300px"}
          onChange={(data) => {
            setInputData(data);
            if (role == "admin") {
              setInputData((prev) => {
                return {
                  ...prev,
                  clientId: id,
                };
              });
            }
          }}
          formData={true}
        />
      </CardBody>
    </Card>
  );
};

export default ClientMetricData;
