// import PropTypes from "prop-types";
// import React, { useEffect } from "react";

import React, { useEffect, useState } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import ALL_ROUTES from "./pages/Utility/Route";
import CONSTANT from "./pages/Utility/constnt";
import authStorage from "./pages/Utility/API/authStroge";

const App = () => {
  const router = createBrowserRouter(ALL_ROUTES);

  return <RouterProvider router={router} />;
};

export default App;
