import { useEffect, useState } from "react";
import CustomPage from "../../../Custome/CustomPage";
import useHttp from "../../../Hook/Use-http";
import CONSTANT, {
  getTableColumConfig,
} from "../../../../pages/Utility/constnt";
import { useLocation, useParams } from "react-router-dom";
import {
  emissionEntryCategoryWiseDataLoad,
  emissionForEditFormDataCallLoad,
  jobViewDataMask,
} from "../../../../pages/Utility/spread-function";
import {
  createEndPoint,
  optionArrayWithSort,
  replaceId,
  tableHeaderToExportHeader,
} from "../../../../pages/Utility/function";

const CapitalGoodSingleView = ({ category, initHeader, flag, ...props }) => {
  const API = useHttp();
  const { id } = useParams();
  const location = useLocation();
  const [header, setHeader] = useState([]);
  const [editData, setEditData] = useState({});
  const [formData, setFormData] = useState([]);
  const [newFormData, setNewFormData] = useState({});
  const [dynamicOption, setDynamicOption] = useState({});
  const [alldynamicOption, setAllDynamicOption] = useState({});
  const [subCategoryModal, setSubCategoryModal] = useState([]);
  const [isFirstEditFlag, setIsFirstEditFlag] = useState(false);

  const currentPath = location.pathname;
  const userRole = localStorage.getItem("userRole");

  useEffect(() => {
    category &&
      API?.getAll(
        [
          CONSTANT?.API.SUBCATEGORYATTRIBUTE.get.endpoint,
          CONSTANT?.API?.ATTRIBUTEOPTION.get?.endpoint,
          CONSTANT?.API.SITE.get?.endpoint,
          CONSTANT?.API.JOB.getCapitalGoodCategory?.endpoint,
        ],
        (res) => {
          let categoryOption = {};
          // extract Sub-Category Attribute Options
          const subCategoryAttributeOptions = res[1]?.data?.data;
          const sites =
            userRole == "admin" && currentPath.includes("/client") && id
              ? res[2]?.data?.data?.rows?.filter((el) => el?.clientId == id)
              : res[2]?.data?.data?.rows;
          const Other = {
            site: sites,
            goodCategory: res[3]?.data?.data,
          };

          // Filter out Sub-Category Attributes According to given category
          const rearrangedData = res[0]?.data?.data?.rows
            ?.filter((it) => it?.subCategoryId == category?.value)
            ?.sort((a, b) => +a.index - +b.index);

          // contains default fields to show in header of table
          let arrHeader = [...initHeader];

          const transformedData = emissionEntryCategoryWiseDataLoad(
            subCategoryAttributeOptions,
            Other,
            rearrangedData,
            setDynamicOption,
            arrHeader
          );

          setSubCategoryModal(transformedData?.arr);
          setAllDynamicOption((prev) => ({
            ...prev,
            ...categoryOption,
          }));
          // add action buttons to header
          setHeader([
            ...transformedData?.arrHeader,
            getTableColumConfig("action"),
          ]);
        }
      );
  }, [category]);

  useEffect(() => {
    emissionForEditFormDataCallLoad(
      subCategoryModal,
      setDynamicOption,
      setSubCategoryModal,
      formData,
      alldynamicOption,
      editData
    );
  }, [formData, editData]);

  //For Edit option Set
  useEffect(() => {
    if (!isFirstEditFlag) return;
    if (Object.keys(editData)?.length == 0) return;
    if (!formData?.selectedValue && !formData?.data) return;

    const arr = [];
    const keyOnly = subCategoryModal?.filter(
      (el) => el?.extraValue?.depenededFieldId
    );
    keyOnly?.map((el) => arr?.push(el?.extraValue?.depenededFieldId));
    if (
      !!formData?.selectedValue?.[arr[0]] &&
      formData?.selectedValue?.[arr[0]]?.id
    ) {
      const purchsedCategoryId = formData?.selectedValue?.[arr[0]]?.id;
      if (
        (formData?.selectedValue?.[keyOnly[0]?.id] == undefined ||
          formData?.selectedValue?.[keyOnly[0]?.id] == null ||
          Object.keys(formData?.selectedValue?.[keyOnly[0]?.id])?.length ==
            0) &&
        (formData?.selectedValue?.[keyOnly[1]?.id] == undefined ||
          !!formData?.selectedValue?.[keyOnly[1]?.id] == null ||
          Object.keys(formData?.selectedValue?.[keyOnly[1]?.id])?.length == 0)
      ) {
        API.getAll(
          [
            replaceId(
              CONSTANT.API.JOB.getCapitalGoodSubCategory,
              purchsedCategoryId
            ).endpoint,
          ],
          (res) => {
            setDynamicOption((prev) => {
              //API SET SUBCATEGORY====================
              return {
                ...prev,
                [keyOnly?.[0]?.id]: optionArrayWithSort(
                  res[0]?.data?.data,
                  "name",
                  "name",
                  "unit"
                ), //subCategory list
              };
            });
            setSubCategoryModal((prev) => {
              const newValue = prev?.map((it) => {
                if (
                  it?.extraValue?.labelChangeDependedFieldId &&
                  formData?.selectedValue
                ) {
                  const labelDependendId = Object?.keys(
                    formData?.selectedValue
                  )?.find(
                    (el) => +el == +it?.extraValue?.labelChangeDependedFieldId
                  );
                  const quatityUnit = res[0]?.data?.data?.find(
                    (el) => el?.name == editData?.[labelDependendId]
                  )?.unit;

                  if (!!quatityUnit) {
                    it.label = (
                      <span>
                        Quantity {`${quatityUnit ? `(in ${quatityUnit})` : ""}`}
                      </span>
                    );
                  }
                }
                return it;
              });

              return newValue;
            });
            setIsFirstEditFlag(false);
          }
        );
      }
    }
  }, [editData, formData]);

  useEffect(() => {
    if (!formData?.selectedValue && !formData?.data) return;
    const arr = [];
    const keyOnly = subCategoryModal?.filter(
      (el) => el?.extraValue?.depenededFieldId
    );
    keyOnly?.map((el) => arr?.push(el?.extraValue?.depenededFieldId));
    const keyFindId = Object?.keys(newFormData)?.[0];
    const optionlistCheck = Object.keys(formData?.selectedValue)?.find(
      (el) => el == keyFindId
    );
    const purchsedCategoryId = formData?.selectedValue[arr?.[0]]?.id;
    if (
      newFormData?.[arr?.[0]] &&
      keyFindId == arr?.[0] &&
      optionlistCheck == arr?.[0] &&
      !!purchsedCategoryId
    ) {
      API.getAll(
        [
          replaceId(
            CONSTANT.API.JOB.getCapitalGoodSubCategory,
            purchsedCategoryId
          ).endpoint,
        ],
        (res) => {
          delete formData?.data?.[keyOnly?.[0]?.id]; //153 sub-category
          // delete formData?.data?.[keyOnly?.[1]?.id]; //154 raw material type
          delete formData?.selectedValue?.[keyOnly?.[0]?.id]; //153 sub-category
          // delete formData?.selectedValue?.[keyOnly?.[1]?.id]; //154 raw material type

          formData?.operations?.inputData(formData?.data);
          formData?.operations?.selectedValue(formData?.selectedValue);
          formData?.operations?.setDefaultValue((pr) => {
            delete pr?.[keyOnly?.[0]?.id];
            return { ...pr };
          });
          setDynamicOption((prev) => {
            //API SET SUBCATEGORY====================
            return {
              ...prev,
              [keyOnly?.[0]?.id]: optionArrayWithSort(
                res[0]?.data?.data,
                "name",
                "name",
                "unit"
              ), //raw MAterial Type list
            };
          });
          setNewFormData({});
        }
      );
    }
  }, [formData, editData]);

  const prepareUpdatePayload = (editPayload, payload) => {
    let objPayload = {
      data: {
        ...formData?.defaultData,
        ...formData?.data,
      },
    };

    Object?.keys(objPayload?.data)?.map((itm) => {
      objPayload.data[`id${itm}`] = payload[itm] || editPayload[itm];
    });
    delete objPayload?.data?.id;

    return {
      ...objPayload,
    };
  };

  return (
    <CustomPage
      PAGE={{
        title: `Record List`,
        dataFunction: (res, Actions) => {
          return jobViewDataMask(res, Actions);
        },
        pageRefresh: { flag },
      }}
      noHeader={false}
      API={{
        get: createEndPoint.get(
          userRole === "admin"
            ? `${userRole}/uploadShipmentData/getFormData/${category?.value}?clientId=${id}`
            : `${userRole}/uploadShipmentData/getFormData/${category?.value}`
        ),
        update: CONSTANT?.API.CATEGORYFORM.update,
        delete: CONSTANT?.API.CATEGORYFORM.delete,
      }}
      TABLE={{
        title: "Record List",
        header,
        export: {
          fileName: `${category?.label}_Records.csv`,
          header: tableHeaderToExportHeader?.header(header),
        },
      }}
      FORM={{
        formField: subCategoryModal,
        editFormTitle: "Edit Record",
        option: { ...dynamicOption },
        editDataPayload: (editData) => {
          setEditData(editData);
          setIsFirstEditFlag(true);
        },
        prepareUpdatePayload: prepareUpdatePayload,
        onChange: (data, selectedValue, operations, defaultData) => {
          setFormData({ data, selectedValue, operations, defaultData });
        },
        onSingleChange: (data) => {
          setNewFormData(data);
        },
        selectedValue: false,
      }}
    />
  );
};

export default CapitalGoodSingleView;
