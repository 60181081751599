import React, { useEffect, useState } from "react";
import CustomPage from "../../../Custome/CustomPage";
import {
  emissionEntryCategoryWiseDataLoad,
  emissionForEditFormDataCallLoad,
  jobViewDataMask,
} from "../../../../pages/Utility/spread-function";
import CONSTANT, {
  getTableColumConfig,
} from "../../../../pages/Utility/constnt";
import {
  getFormFiled,
  getRecordId,
  replaceId,
  tableHeaderToExportHeader,
} from "../../../../pages/Utility/function";
import { useLocation, useParams } from "react-router-dom";

const EmployeeCommuteView = (props) => {
  const {
    jobId,
    jobSingleData,
    API,
    initHeader,

    setJobViewData,
    onViewReason,
    responceStatus,
    flag,
  } = props;
  const [subCategoryModal, setSubCategoryModal] = useState([]);
  const [subHeader, setHeader] = useState([]);
  const [dynamicOption, setDynamicOption] = useState({});
  const [editData, setEditData] = useState({});
  const [formData, setFormData] = useState([]);
  const [alldynamicOption, setAllDynamicOption] = useState({});
  const [wasteTypeData, setwasteTypeData] = useState(null);
  const [wasteTreatment, setWasteTreatment] = useState([]);

  const [defaultData, setDefaultData] = useState({});
  const { id } = useParams();
  const userRole = localStorage.getItem("userRole");
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    jobSingleData &&
      API?.getAll(
        [
          CONSTANT?.API.SUBCATEGORYATTRIBUTE.get.endpoint,
          CONSTANT?.API?.ATTRIBUTEOPTION.get?.endpoint,
          CONSTANT?.API.SITE.get?.endpoint,
          CONSTANT?.API.JOB.getEmployeeLabelCommute?.endpoint,
        ],
        (res) => {
          // const subCategoryAttributeRes = res[0]?.data;
          let categoryOption = {};
          const subCategoryAttributeOptionRes = res[1]?.data?.data;
          const sites =
            userRole == "admin" && currentPath.includes("/client") && id
              ? res[2]?.data?.data?.rows?.filter((el) => el?.clientId == id)
              : res[2]?.data?.data?.rows;
          const Other = {
            site: sites,
            employeeMode: res[3]?.data?.data?.rows,
          };
          setwasteTypeData(res[3]?.data?.data?.rows);

          const rearrangedData = res[0]?.data?.data?.rows
            ?.filter((it) => it?.subCategoryId == jobSingleData?.subCategoryId)
            ?.sort((a, b) => +a.index - +b.index);

          let arrHeader = [...initHeader];

          const dddd = emissionEntryCategoryWiseDataLoad(
            subCategoryAttributeOptionRes,
            Other,
            rearrangedData,
            setDynamicOption,
            arrHeader
          );

          // dddd.arr?.map((ell) => {
          //   if (ell?.type == "SingleSelect") {
          //     ell.type = "select";
          //   }
          // });

          setSubCategoryModal([...dddd?.arr]);
          setAllDynamicOption((prev) => ({
            ...prev,
            ...categoryOption,
          }));
          setHeader((pr) => {
            return [...dddd?.arrHeader, getTableColumConfig("action")];
          });
        }
      );
  }, [jobSingleData]);

  useEffect(() => {
    emissionForEditFormDataCallLoad(
      subCategoryModal,
      setDynamicOption,
      setSubCategoryModal,
      formData,
      alldynamicOption,
      editData
    );
  }, [formData, editData]);

  // useEffect(() => {}, [formData, editData]);

  const prepareUpdatePayload = (editPayload, payload) => {
    let objPayload = {
      data: {
        ...editPayload,
        ...payload,
      },
    };

    Object?.keys(objPayload?.data)?.map((itm) => {
      objPayload.data[`id${itm}`] = payload[itm] || editPayload[itm];
    });
    return {
      ...objPayload,
    };
  };

  return (
    <>
      <CustomPage
        PAGE={{
          // withBackButton: true,
          title: `JOB / ${getRecordId?.job(jobId)}`,
          dataFunction: (res, Actions) => {
            setJobViewData(res?.result);

            return jobViewDataMask(res, Actions, onViewReason, responceStatus);
          },
          pageRefresh: { flag },
        }}
        // modalType="antdModal"
        noHeader={false}
        API={{
          get: replaceId(CONSTANT?.API.JOBVIEW.get, jobId),
          update: CONSTANT?.API.JOBVIEW.update,
          delete: CONSTANT?.API.JOBVIEW.delete,
        }}
        FORM={{
          formField: subCategoryModal,

          editFormTitle: "Edit Job",
          option: {
            ...dynamicOption,
          },
          editDataPayload: (editData) => {
            setEditData(editData);

            setSubCategoryModal((pr) => {
              pr?.map((pl) => {
                if (pl?.type == "GoogleAutoComplete") {
                  pl.placeholder = editData?.[pl?.id];
                }
              });
              return [...pr];
            });
          },

          prepareUpdatePayload: prepareUpdatePayload,

          onChange: (data, selectedValue, operations) => {
            // setEditData(data);
            const keyOnly = subCategoryModal?.find(
              (el) => el?.extraValue?.keyOrEquetion?.[0]
            );

            setWasteTreatment(data?.[keyOnly?.id]);
            setFormData({ data });
          },
          selectedValue: false,
        }}
        TABLE={{
          title: "Record List",
          header: subHeader,
          export: {
            fileName: `${getRecordId?.job(jobId)}_Record.csv`,
            header: tableHeaderToExportHeader?.header(subHeader),
          },
        }}
      />
    </>
  );
};

export default EmployeeCommuteView;
