import React, { useEffect } from "react";
import CONSTANT, { MyData } from "../../pages/Utility/constnt";
import { useDispatch } from "react-redux";
import useHttp from "../Hook/Use-http";
import { getUsersDetailSuccess } from "../../store/actions";
import { menuGen, notifications } from "../../pages/Utility/function";
import useCheckInternet from "../Hook/use-checkInternet";
import HorizontalLayout from "../HorizontalLayout";
import authStorage from "../../pages/Utility/API/authStroge";
import { useLocation, useNavigate } from "react-router-dom";

const SpocLayout = () => {
  useCheckInternet("/noInternet");
  const navigate = useNavigate();
  const API_CALL = useHttp();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPath = location.pathname;
  let userRole = localStorage.getItem("userRole");

  //Get My Data
  useEffect(() => {
    const isLogin =
      authStorage?.getAuthToken() !== undefined &&
      authStorage?.getAuthToken() !== null;
    if (!isLogin || !userRole) {
      notifications.error("Session Expired!");
      return navigate("/login");
    }
    (async () => {
      if (!window?.location?.href.includes("data-not-found")) {
        API_CALL.sendRequest(
          CONSTANT.API.SPOC.getMe,
          getMeSpocDataHandler,
          null,
          null,
          (err) => {}
        );
      }
    })();
  }, []);

  const getMeSpocDataHandler = (res) => {
    dispatch(getUsersDetailSuccess(res?.data[0]));
    MyData.data = res?.data;

    if (currentPath == "/spoc") {
      navigate(currentPath + `/${res?.data[0]?.id}`);
    }
    if (currentPath == "/spoc/") {
      navigate(currentPath + `${res?.data[0]?.id}`);
    }
    if (
      !currentPath?.includes(res?.data[0]?.id) &&
      currentPath != "/spoc" &&
      currentPath != "/spoc/"
    ) {
      navigate(
        currentPath?.replace(currentPath.split("/")[2], res?.data[0]?.id)
      );
    }

    if (res?.data[0] === null) {
      window.location.replace("data-not-found");
    }
  };

  return (
    <>
      <HorizontalLayout SideBarMenu={menuGen("spoc")} />
    </>
  );
};

export default SpocLayout;
