import React from "react";
import CONSTANT from "../../../Utility/constnt";
import { useParams } from "react-router-dom";
import useHttp from "../../../../components/Hook/Use-http";
import { replaceId } from "../../../Utility/function";
import { ApproveButton, RejectButton } from "../../../Utility/spread-function";
const ConnectionStatusUpdate = ({ Operation, userData, setFlag }) => {
  const { id } = useParams();
  const API_CALL = useHttp();
  let clientId = id;
  const userRole = localStorage.getItem("userRole");
  const handleApprove = async (id) => {
    try {
      if (userRole === "admin" || userRole === "client") {
        // const id = clientId;
        await API_CALL.sendRequest(
          replaceId(CONSTANT.API.MANAGE_CONNECTIONS_INCOMING.update, id),
          null,
          {
            clientId: clientId,
            status: "ACCEPTED",
          },
          "Accepted successfully!"
        );
        setFlag((prevState) => !prevState);
      }
    } catch (error) {
      console.error("getting error while approving the new user", error);
    }
  };
  const handleDeny = async (id) => {
    try {
      if (userRole === "admin" || userRole === "client") {
        await API_CALL.sendRequest(
          replaceId(CONSTANT.API.MANAGE_CONNECTIONS_INCOMING.update, id),
          null,
          {
            clientId: clientId,
            status: "REJECTED",
          },
          "Rejected successfully!"
        );
        setFlag((prevState) => !prevState);
      }
    } catch (error) {
      console.error("getting error while approving the new user", error);
    }
  };
  return (
    <div className=" d-flex justify-content-center">
      {Operation?.editRecord && (
        <ApproveButton
          onClick={() => {
            handleApprove(userData.id);
          }}
        />
      )}
      {Operation?.setDeleteData && (
        <RejectButton onClick={() => handleDeny(userData.id)} />
      )}
    </div>
  );
};

export default ConnectionStatusUpdate;
