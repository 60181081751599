import moment from "moment";
import { POCName } from "./models/form-model";

export const customerHeaderData = {
  no: "Customer id",
  name: "Name",
  POCName: "POC name",
  mobile: "Mobile No",
  insuranceNumber: "Insurance number",
  tripEndDistance: "Shipment End Distance (km)",
};

export const siteHeaderData = {
  no: "Site Id",
  clientName: "Customer Name",
  unitName: "Site Name",
  address: "Address",
  GST: "GST Number",
  adhrVFlag: "Aadhaar Verification Flag",
  aggreTurnOver: "Aggregate Turnover",
  cmpRt: "Composition Rate",
  ctb: "Central Tax Jurisdiction",
  ctj: "Central Tax Jurisdiction (Alternate Abbreviation)",
  cxdt: "Cancellation/Revocation/Denial of Taxpayer Registration",
  dty: "Dealer Type",
  einvoiceStatus: "E-Invoice Status",
  ekycVFlag: "eKYC Verification Flag",
  ekycVdt: "eKYC Verification Date",
  isFieldVisitConducted: "Is Field Visit Conducted",
  lgnm: "Legal Name",
  location: "Location",
  mbr: "Master Business Record",
  nba: "Non-Business Assessee",
  rtcrbs: "Non-Taxable Credit Balance Status",
  organization: "Organization (associated with the GST data, if any)",
  pradr: "Principal Address",
  rgdt: "Registration Date",
  stj: "State Tax Jurisdiction",
  sts: "State Tax Status",
  tradeNam: "Trade Name",
};

export const customersiteHeaderData = {
  no: "Site Id",
  clientName: "Customer Name",
  unitName: "Site Name",
  address: "Address",
  GST: "GST Number",
  adhrVFlag: "Aadhaar Verification Flag",
  aggreTurnOver: "Aggregate Turnover",
  cmpRt: "Composition Rate",
  ctb: "Central Tax Jurisdiction",
  ctj: "Central Tax Jurisdiction (Alternate Abbreviation)",
  cxdt: "Cancellation/Revocation/Denial of Taxpayer Registration",
  dty: "Dealer Type",
  einvoiceStatus: "E-Invoice Status",
  ekycVFlag: "eKYC Verification Flag",
  ekycVdt: "eKYC Verification Date",
  isFieldVisitConducted: "Is Field Visit Conducted",
  lgnm: "Legal Name",
  location: "Location",
  mbr: "Master Business Record",
  nba: "Non-Business Assessee",
  rtcrbs: "Non-Taxable Credit Balance Status",
  organization: "Organization (associated with the GST data, if any)",
  pradr: "Principal Address",
  rgdt: "Registration Date",
  stj: "State Tax Jurisdiction",
  sts: "State Tax Status",
  tradeNam: "Trade Name",
};

export const routeHeaderData = {
  no: "Route Id",
  clientName: "Customer Name",
  PlantName: "Plant Name",
  displayName: "Route display name",
  add: "Destination Address",
  routeType: "Route type",
  vehicleType: "Vehicle type",
  vehicleCapacity: "VehicleCapacity",
  ownerShipType: "OwnerShipType",
  expectedtime: "Expected completion (in Hour)",
  freightWeight: "Freight Weight (in ton)",
};

export const packageHeaderData = {
  no: "Packaging Id",
  type: "Type",
  SubType: "Sub-Type",
  DimensionInmm: "Dimension (LxBxH in mm)",
  DropdownName: "Display Name",
  VolumeCBM: "Volume (m3)",
  VolumetricWeightInKG: "Volumetric Weight(KG)",
  ActualWeight: "Actual Weight(KG)",
  material: "Material",
  co2EmissionPerKG: "tCO2e per kg",
  lifeTimetCO2e: "LifeTime tCO2e",
  lifeTimeTrips: "LifeTimeTrips",
  tCO2PerShipment: "tCO2e per shipment",
};

export const transporterHeaderData = {
  no: "Transporter Id",
  transporterName: "Name",
  gstNumber: "GST Number",
  organization: "Organization",
  adhrVFlag: "Aadhar Verification",
  adhrVdt: "Adhar Validation Date",
  ctj: "Centre Jurisdiction",
  ctb: "Constitution of Business",
  cxdt: "Date Of Cancellation",
  einvoiceStatus: "e-Invoice Status",
  nba: "Nature of Business Activity",
  sts: "GSTN status",
  stj: "State Jurisdiction",
  tradeNam: "Trade Name",
  aggreTurnOver: "Aggregate Turnover",
  cmpRt: "Composition Rate",
  dty: "Dealer Type",
  ekycVFlag: "eKYC Verification Flag",
  ekycVdt: "eKYC Verification Date",
  isFieldVisitConducted: "Is Field Visit Conducted",
  mbr: "Master Business Record",
  ntcrbs: "Non-Taxable Credit Balance Status",
  pradr: " Principal Address",
  rgdt: "Registration Date",
};

export const driverHeaderData = {
  no: "Driver Id",
  name: "Name",
  drivingLicense: "Driving license",
  mobile: "Mobile Number",
  consent: "Consent",
  badge_no: "Badge Number",
  badgeno_issue_date: "Badge Number Issue Date",
  blood_group: "Blood Group",
  cov: "Class of Vehicle",
  dl_no: "Driver's License Number",
  dob: "Date of Birth",
  gender: "Gender",
  issue_date: "Date of License Issue",
  nationality: "Nationality",
  organ_donar: "Organ Donor",
  // organization: "Organization ",
  permanent_address: "Permanent Address",
  temporary_address: "Temporary Address",
};

export const vehicalHeaderData = {
  no: "Vehicle Id",
  RegistrationNumbers: "Registration Number",
  manufacture: "Manufacturer",
  fuel_type: "Fuel Type",
  transporterName: "Transporter Name",
  co2PerKm: "tCO2e/km",
  mileage: "Mileage(L/km)",
  blacklist_status: "Blacklist Status",
  body_type: "Body Type",
  capacity: "Capacity",
  chassis_number: "Chassis Number",
  colour: "Colour",
  cubic_capacity: "Cubic Capacity",
  current_address: "Current Address",
  engineType: "Engine Type",
  engine_number: "Engine Number",
  father_name: "Father's Name",
  financer: "Financer",
  fitness_upto: "Fitness Expiry Date",
  gross_vehicle_weight: "Gross Vehicle Weight",
  insurance_name: "Insurance Name",
  insurance_policy_no: "Insurance Policy Number",
  insurance_validity: "Insurance Validity",
  m_y_manufacturing: "Manufacturing Year",
  manufacture: "Manufacturer",
  manufacturer_model: "Manufacturer Model",
  masked_name: "Masked Name",
  mileage: "Mileage",
  mv_tax_upto: "Motor Vehicle Tax Expiry Date",
  noc_details: "NOC Detail",
  noc_issue_date: "NOC Issue Date",
  noc_status: "NOC Status",
  noc_valid_upto: "NOC Validity",
  norms_type: "Norms Type",
  npermit_issued_by: "National Permit Issued By",
  npermit_no: "National Permit Number",
  npermit_upto: "National Permit Validity",
  number_of_cylinder: "Number of Cylinders",
  organization: "Organization",
  owner_mobile_no: "Owner's Mobile Number",
  owner_name: "Owner's Name",
  owner_serial_number: "Owner Serial Number",
  permanent_address: "Permanent Address",
  permit_issue_date: "Permit Issue Date",
  permit_no: "Permit Number",
  permit_type: "Permit Type",
  permit_validity_from: "Permit Validity From",
  permit_validity_upto: "Permit Validity Upto",
  puc_number: "Pollution Under Control (PUC) Number",
  puc_valid_upto: "PUC Validity",
  registered_place: "Registered Place",
  registrationNumber: "Registration Number",
  registration_date: "Registration Date",
  seating_capacity: "Seating Capacity",
  sleeper_capacity: "Sleeper Capacity",
  standing_capacity: "Standing Capacity",
  state: "State",
  status: "Status",
  status_message: "Status Message",
  status_verfy_date: "Status Verification Date",
  status_verification: "Status Verification",
  unladden_weight: "Unladen Weight",
  variant: "Variant",
  vehicle_category: "Vehicle Category",
  vehicle_class: "Vehicle Class",
  wheelbase: "Wheelbase",
};

// export const tripHeaderData = {
//   no: "Shipment Id",
//   clientName: "Customer name",
//   sourcename: "Source Site",
//   destinationName: "Destination Site",
//   weight: "Freight Weight(In Tonne)",
//   statusdata: "Status",
//   startDate: "Start Date",
//   startTime: "Start Time",
//   targetedDate: "Completion Date",
//   targetedTime: "Completion Time",
//   carbonEmission: "Carbon Emissions(tCO2e)",
//   utilisation: "Carbon Efficiency",
//   distanceOfTrip: "Distance of Shipment(km)",
//   type: "Tracking Type",
//   invoiceNumber: "Invoice Number",
//   packageEmission: "Packaging Emissions(tCO2e)",
//   totalGen: "Total Carbon Emissions(tCO2e)",
//   invoiceValue: "Invoice Value",
//   eWayBillNo: "E-way bill number",
//   mode: "Transportation Mode",
// };

export const tripHeaderData = [
  ["no", "Shipment Id"],
  ["clientName", "Customer name"],
  ["sourcename", "Source Site"],
  ["destinationName", "Destination Site"],
  ["weight", "Freight Weight(In Tonne)"],
  ["statusdata", "Status"],
  ["startDate", "Start Date"],
  ["startTime", "Start Time"],
  ["targetedDate", "Completion Date"],
  ["targetedTime", "Completion Time"],
  ["carbonEmission", "Carbon Emissions(tCO2e)"],
  ["utilisation", "Carbon Efficiency"],
  ["distanceOfTrip", "Distance of Shipment(km)"],
  ["type", "Tracking Type"],
  ["invoiceNumber", "Invoice Number"],
  ["packageEmission", "Packaging Emissions(tCO2e)"],
  ["totalGen", "Total Carbon Emissions(tCO2e)"],
  ["invoiceValue", "Invoice Value"],
  ["eWayBillNo", "E-way bill number"],
  ["mode", "Transportation Mode"],
  ["transporterName", "Transporter name"],
  ["driverName", "Driver Name"],
  ["driverPhoneNumber", "Driver Contact number"],
  ["vehicleNumber", "Vehicle Number"],
  ["startDateAfterCompletedDate", "Completion status"],
];

export const transporterWiseHeaderData = {
  name: "Name",
  shipment: "Shipments",
  per: "Carbon Emissions (tCO2e)",
  eff: "Carbon Efficiency (tCO2e/Freight Tonne-km)",
};

export const customerWiseHeaderData = {
  name: "Name",
  shipment: "Shipments",
  per: "Carbon Emissions (tCO2e)",
  eff: "Carbon Efficiency (tCO2e/Freight Tonne-km)",
};

export const PackagingAssetsHeaderData = {
  pacakeSubType: "Package Sub Type",
  type: "Transaction type",
  count: "Count",
  createdAt: "Date of transaction",
};

export const Fitsol_Team = {
  no: "Team ID",
  name: "Name",
  email: "Email",
  mobile: "Mobile",
};

export const job_Report_Data = {
  no: "Job ID",
  uploadedRecord: "Uploaded Record",
  category: "Category Name",
  successRecord: "Success Record",
  failedRecord: "Failed Record",
  createdDate: "Created Date",
  csvLink: "Uploaded File",
};

// export const packaging_Asset_Data = {
//   packageName: "Package Name",
//   totalAssign: "Total Assign Record",
//   totalAccounted: "Total Accounted",
// };

// export const packageAssignmentHeaderData = [
//     "p_id",
//     "Package Sub Type",
//     "Transaction type",
//     "Count",
//     "Date of transaction",
//   ];

//   export const packageAssignmentValueData = (res) => {
//     return res?.map((item) => {
//       return [item?.name, item?.shipment, item?.per, item?.eff];
//     });
//   };

export const brsrSpocsHeaderData = {
  no: "SPOC Id",
  name: "Name",
  email: "Email",
  phone: "Phone",
  department: "Department",
};

export const brsrFormHeaderData = {
  no: "Form Id",
  section: "Name",
  totalFields: "Total questions",
  filledFields: "Filled questions",
  spocs: "progress bar",
  deadline:"Dead line",
  fillForm: "Fill Form",
};

export const globalSectionHeaderData = {
  no: "Form Id",
  section: "Name",
  totalQuestion: "Total questions",
  answeredQuestion: "Filled questions",
  spocs: "progress bar",
  deadline:"Dead line",
  fillForm: "Fill Form",
};
export const connectionSentHeaderData = {
  no: "Form Id",
  connectionTo:"Onboarding partner",
  status:"Status",
  gstIn:"GSTIN",
  pocName:"POC name",
  pocMobile:"POC mobile",
  pocEmail:"POC email",
  categoryServiceProvider:"Category service provider",
}

export const connectionRecievedHeaderData = {
  no: "Form Id",
  connectionFrom:"Connection from",
  status:"Status",
  gstIn:"GSTIN",
  pocName:"POC name",
  pocMobile:"POC mobile",
  pocEmail:"POC email",

}