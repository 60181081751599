import { Col, Row } from "antd";
import React from "react";

const ResultTabHeaderGen = (props) => {
  const { calculateData, series } = props;

  const totalCarbon = +calculateData?.totalProductFootprint?.toFixed(0) || 0;
  const totalWeight = +calculateData?.productWeight?.toFixed(0) || 0;
  return (
    <>
      <Row className="justify-content-between">
        <Col>
          <Col className="d-flex flex-column ">
            <p className="pcf-total-carbon-label text-uppercase">
              Product carbon footprint
            </p>

            <p className="pcf-total-carbon-text font-dmserif d-flex align-items-center m-0">
              {totalCarbon?.toLocaleString("en-IN") || "00.00"}
              <span className="pcf-total-carbon-kg-text">
                kgCO<sub>2</sub>e
              </span>
            </p>
          </Col>
        </Col>
        <Col className="d-flex flex-column ">
          <p className="pcf-total-carbon-label text-uppercase">Weight</p>
          <p className="pcf-total-carbon-weight-text font-dmserif d-flex align-items-center">
            {totalWeight?.toLocaleString("en-IN") || "00"}{" "}
            <span
              className="pcf-total-carbon-kg-text"
              style={{ marginLeft: "5px" }}
            >
              {" grams"}
            </span>
          </p>
        </Col>
      </Row>

      <Row>
        <Col xs={0} sm={1} lg={5} xl={5}></Col>
        <Col xs={0} sm={23} lg={19} xl={19}>
          <div className="grid-temp w-full">
            {series
              ? series[0]?.data?.map((el, index) => {
                  return (
                    <div className="grid-row-5-custom" key={`he-${index}`}>
                      <p className="pcf-total-carbon-label text-uppercase">
                        {el?.x}
                      </p>

                      <p
                        className="font-poppis"
                        style={{ fontWeight: "500", fontSize: "18px" }}
                      >
                        {el?.footPrint?.toLocaleString("en-IN") || "00.00"}
                        <span>
                          kgCO<sub>2</sub>e
                        </span>
                      </p>
                    </div>
                  );
                })
              : null}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ResultTabHeaderGen;
