import React, { useEffect, useState } from "react";
import Table from "../../../components/Custome/table";
import { Button } from "reactstrap";

import PageTitle from "../../../components/pageTitle";
import PackagingLocationDetail from "../../../components/packagingLocationDetail";
import {
  calculatePackageCountForClient,
  packegingAssetForClient,
  replaceId,
} from "../../Utility/function";
import CONSTANT, { viewRole } from "../../Utility/constnt";
import useHttp from "../../../components/Hook/Use-http";
import { useSelector } from "react-redux";

const ClientPackaging = () => {
  const [pacakegingData, setPackagingData] = useState([]);
  const [tableHeader, setTableHeader] = useState([]);
  const [viewAction, setViewAction] = useState(null);
  const myData = useSelector((state) => state.userGetMeData);

  const API_CALL = useHttp();

  useEffect(() => {
    (async () => {
      if (localStorage.getItem("userRole") !== "admin") {
        // API_CALL.sendRequest(CONSTANT.API.ADMIN.roleGetMe, (res) => {
        myData?.users?.clientId &&
          API_CALL.sendRequest(
            replaceId(
              CONSTANT.API.PACKAGINGASSIGNMENT.getSinglepackageDataClient,
              myData?.users?.clientId
            ),
            packagingDataHandler
          );
      }
    })();
  }, [myData?.users]);

  useEffect(() => {
    (async () => {
      if (localStorage.getItem("userRole") == "admin" && viewRole) {
        // API_CALL.sendRequest(CONSTANT.API.ADMIN.roleGetMe, (res) => {
        myData?.users?.id &&
          API_CALL.sendRequest(
            replaceId(
              CONSTANT.API.PACKAGINGASSIGNMENT.getSinglepackageDataClient,
              viewRole
            ),
            packagingDataHandler
          );
      }
    })();
  }, [myData?.users]);

  const packagingDataHandler = (res) => {
    res = res?.data;

    const possiblePackageDetails = [
      ...res?.packageData?.map((item) => {
        return {
          packagingTypeId: item?.packagingTypeId,
          name: item?.packagingType?.dropDownName,
        };
      }),
      ...res?.outGoingTripsPackageData?.map((item) => {
        return {
          packagingTypeId: item?.packagingTypeId,
          name: item["packagingType.dropDownName"],
        };
      }),
    ];

    let packageTypeId = possiblePackageDetails.reduce(
      (prev, curr) => {
        if (!prev?.label?.includes(curr.packagingTypeId)) {
          prev?.label?.push(curr.packagingTypeId);
          prev?.Arr?.push(curr);
        }

        return prev;
      },
      { label: [], Arr: [] }
    ).Arr;

    let initValue = {};
    res?.packageData?.map((data) => {
      initValue = { ...initValue, [data?.plantId]: 0 };
    });

    setPackagingData(
      packageTypeId?.map((packageId) => {
        let rowData = {};
        const rowEntry = res?.packageData?.filter(
          (item) =>
            item?.packagingTypeId === packageId?.packagingTypeId &&
            item["route.routeType"] == "Forward"
        );
        rowEntry?.map((item) => {
          rowData = {
            ...rowData,

            [item?.plantId]: item?.totalCount,
          };
        });
        let plantPackageCountPackageCountAtPlant = {};
        res?.packageData
          ?.filter(
            (item) =>
              item?.packagingTypeId == packageId?.packagingTypeId &&
              item["route.routeType"] == "Forward"
          )
          ?.map((incoming) => {
            if (
              incoming["plant.unitName"] in plantPackageCountPackageCountAtPlant
            ) {
              plantPackageCountPackageCountAtPlant[
                incoming["plant.unitName"]
              ] += +incoming?.totalCount;
            } else {
              plantPackageCountPackageCountAtPlant = {
                ...plantPackageCountPackageCountAtPlant,
                [incoming["plant.unitName"]]: +incoming?.totalCount,
              };
            }
          });

        res?.outGoingTripsPackageData
          ?.filter(
            (item) => item?.packagingTypeId == packageId?.packagingTypeId
          )
          ?.map((outgoing) => {
            if (
              outgoing["trip.route.plant.unitName"] in
              plantPackageCountPackageCountAtPlant
            ) {
              plantPackageCountPackageCountAtPlant[
                outgoing["trip.route.plant.unitName"]
              ] -= +outgoing?.outGoingTripsPackageCount;
            } else
              plantPackageCountPackageCountAtPlant = {
                ...plantPackageCountPackageCountAtPlant,
                [outgoing["trip.route.plant.unitName"]]:
                  -outgoing?.outGoingTripsPackageCount,
              };

            if (outgoing["trip.status"] == "2")
              if (outgoing[outgoing["trip.route.routeName"]]) {
                outgoing[outgoing["trip.route.routeName"]] +=
                  +outgoing?.outGoingTripsPackageCount;
              } else {
                outgoing = {
                  ...outgoing,
                  [outgoing["trip.route.routeName"]]:
                    +outgoing?.outGoingTripsPackageCount,
                };
              }

            if (outgoing["trip.status"] == "3") {
              const constDestinationUnitName =
                outgoing["trip.route.destinationPlant.unitName"];
              if (
                constDestinationUnitName in plantPackageCountPackageCountAtPlant
              ) {
                plantPackageCountPackageCountAtPlant[
                  constDestinationUnitName
                ] += +outgoing?.outGoingTripsPackageCount;
              } else {
                plantPackageCountPackageCountAtPlant = {
                  ...plantPackageCountPackageCountAtPlant,
                  [constDestinationUnitName]:
                    +outgoing?.outGoingTripsPackageCount,
                };
              }
            }
          });

        Object.keys(plantPackageCountPackageCountAtPlant).map((item) => {
          plantPackageCountPackageCountAtPlant[item] < 0 &&
            delete plantPackageCountPackageCountAtPlant[item];
        });

        rowData = {
          ...initValue,
          ...rowData,
          packageName: packageId?.name,
          totalAssign: rowEntry?.reduce((total, item) => {
            total += +item?.totalCount;
            return +total;
          }, 0),
          totalAccounted: (
            <Button
              id="view"
              title="View"
              style={{
                minWidth: "70px",
                background: "transparent",
                border: "0",
                padding: "0",
                margin: "0",
              }}
              onClick={() =>
                setViewAction({
                  title: packageId?.name,
                  plantPackageCountPackageCountAtPlant:
                    plantPackageCountPackageCountAtPlant,
                  route: [
                    ...res?.outGoingTripsPackageData
                      ?.filter(
                        (item) =>
                          item["trip.status"] == "2" &&
                          item?.packagingTypeId == packageId?.packagingTypeId
                      )
                      ?.map((outgoingIntransit) => {
                        return {
                          title: outgoingIntransit["trip.route.routeName"],
                          value: outgoingIntransit?.outGoingTripsPackageCount,
                        };
                      }),
                  ],
                })
              }
            >
              {calculatePackageCountForClient(res, packageId?.packagingTypeId)}
            </Button>
          ),
        };

        return rowData;
      })
    );

    setTableHeader(packegingAssetForClient(res));
  };

  const onCloseModal = () => {
    setViewAction(null);
  };

  return (
    <>
      {" "}
      <div className="page-content">
        <PageTitle pageHeading={`Packaging asset `} />

        <Table
          title="Packaging asset list"
          data={{
            columns: tableHeader,
            rows: pacakegingData,
          }}
          // exportHeader={customersiteHeaderData}
          // excelName="Site_Report.csv"
        />
      </div>
      {viewAction != null && (
        <PackagingLocationDetail viewData={viewAction} onClose={onCloseModal} />
      )}
    </>
  );
};

export default ClientPackaging;
