import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import useHttp from '../../../../components/Hook/Use-http';
import CONSTANT from '../../../Utility/constnt';
import Helper from '../../Helper';
import { replaceId } from '../../../Utility/function';
import { useParams } from 'react-router-dom';
import Submit from '../../submitButton';
import BrsrFormHeading from '../../BrsrFormHeading';


const LeadershipIndicators = () => {
    const API = useHttp();
    const sectionName = "C_P7_L";
    const { formId } = useParams();
    const [finalData, setFinalData] = useState({ sectionName: {} });
    const [formTableData, setFormTableData] = useState([
        {
            qnos: "1",
            type: "table",
            title: "1. Details of public policy positions advocated by the entity:",
            keyMappings: {
                1: {
                    P1: "PublicPolicyAdvocated|D_PublicPolicyAxis",
                    P2: "MethodResortedForSuchAdvocacy|D_PublicPolicyAxis",
                    p3: "WhetherInformationAvailableInPublicDomain|D_PublicPolicyAxis",
                    p4: "FrequencyOfReviewByBoard|D_PublicPolicyAxis",
                    P5: "WebLinkPublicPolicyPositionAdvocated|D_PublicPolicyAxis",
                },
            },
            data: [
                {
                    title: "1",
                    key: "1",
                    P1: "",
                    P2: "",
                    P3: "",
                    P4: "",
                    P5: "",
                },
            ],
        },

    ]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                let apiData;
                API.sendRequest(replaceId(CONSTANT.API.BRSRFORMS.get2, formId), (res) => {
                    apiData = res?.data || { sectionName: {} };
                    const updatedFormTableData = formTableData.map((item) => {
                        const apiItemData = apiData[sectionName][item.qnos];
                        if (apiItemData) {
                            const updatedData = item?.data?.map((row) => {
                                const updatedRow = { ...row };
                                Object.keys(item.keyMappings[row.key] || {}).forEach((columnName) => {
                                    const brsrKey = item.keyMappings[row.key][columnName];
                                    updatedRow[columnName] = apiItemData[brsrKey] || '';
                                });
                                return updatedRow;
                            });
                            return { ...item, data: updatedData };
                        }
                        return item;
                    });
                    setFormTableData(updatedFormTableData);
                    setFinalData(apiData);
                });
            } catch (err) {
                console.log(err);
            }
        };

        fetchData();
    }, []);

    const getInputValue = (qnos, key) => {
        return finalData?.[sectionName]?.[qnos]?.[key] || '';
    };


    const handleSubmit = () => {
        try {
            API.sendRequest(replaceId(CONSTANT.API.BRSRFORMS.create, formId), (res) => {
                // console.log(res);
            }, { "data": finalData }, "Data saved successfully")
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div style={{ marginTop: 100 }}>
            <BrsrFormHeading
              title="Principle 7 - Leadership Indicators" 
              subtitle="Section C: Principle Wise Performance Disclosure"
            />
            {formTableData.map(item => (
                <Helper key={item.q_key} {...item} setFinalData={setFinalData} sectionName={sectionName} getInputValue={getInputValue} />
            ))}
            <Submit handleSubmit = {handleSubmit} loading={API.isLoading}></Submit>
        </div>
    );
};

export default LeadershipIndicators;
