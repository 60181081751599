// import { SET_PACKAGING_DETAIL } from "./actionTypes";

// const packagingDetailData = (data = [], action) => {
//   switch (action.type) {
//     case SET_PACKAGING_DETAIL:
//       console.warn("SET_PACKAGING_DETAIL condition ", action);
//       return action.data;
//     default:
//       return data;
//   }
// };

// export default packagingDetailData;

import {
  DELETE_PACKAGING_FAILURE,
  DELETE_PACKAGING_START,
  DELETE_PACKAGING_SUCCESS,
  GET_PACKAGING_FAILURE,
  GET_PACKAGING_START,
  GET_PACKAGING_SUCCESS,
} from "./actionTypes";

const initialState = {
  users: [],
  loading: false,
  error: null,
};

const packagingDetailData = (state = initialState, action) => {
  switch (action.type) {
    case GET_PACKAGING_START:
    case DELETE_PACKAGING_START:
      return { ...state, loading: true };
    case GET_PACKAGING_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      };
    // return action.data;

    case DELETE_PACKAGING_SUCCESS:
      const newData = state?.users?.data?.rows?.filter(
        (itm) => itm?.id != action?.payload?.payload
      );

      return {
        ...state,
        loading: false,
        users: {
          ...state?.users,

          data: {
            count: newData?.length,
            rows: newData,
          },
        },
      };

    case GET_PACKAGING_FAILURE:
    case DELETE_PACKAGING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };

    default:
      return state;
  }
};
export default packagingDetailData;
