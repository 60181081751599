import React from "react";
import { Row, Col } from "reactstrap";
import { Bar, Doughnut, Pie } from "react-chartjs-2";

const BarChart = (props) => {
  const dataset = [];
  for (const subCategory in props?.chartData) {
    dataset.push({
      data: props?.chartData[subCategory]?.data.map((el) => el.toFixed(2)),
      label: subCategory,
      backgroundColor: props?.chartData[subCategory]?.color,
    });
  }
  return (
    <Row>
      <Col className="bg-white m-2">
        <Bar
         style = {{
           borderRadius:20
        }}
          height={"100%"}
          redraw={true}
          options={{
            responsive: true,
            legend: {
              position: "left",
              labels: {
                usePointStyle: true,
                pointStyle: "circle",
              },
              onClick: function (e, legendItem, legend) {
                return;
              },
            },
            scales: {
              xAxes: [
                {
                  stacked: true,
                  scaleLabel: {
                    display: true,
                    labelString: "Financial year",
                  },
                },
              ],
              yAxes: [
                {
                  stacked: true,
                  scaleLabel: {
                    display: true,
                    labelString: "Carbon emissions (tCO2e)",
                  },
                },
              ],
            },
          }}
          data={{
            // labels: props?.labels,
            labels: props?.labels,
            datasets: dataset.map((data) => ({
              ...data,
              barPercentage: 0.3, // Adjust the width of individual bars
              categoryPercentage: 0.5, // Adjust the width of the bar groups
              borderWidth:{
                top:3,
              },
              borderColor: "#FFFFFF",
              pointLabels: {
                display: false, 
              },
              datalabels: {
                display: false,
              },
            })),
            // datasets: dataset
          }}
        />
      </Col>
    </Row>
  );
};

export default BarChart;
