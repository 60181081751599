import React from "react";
import { Col, Modal, Row } from "reactstrap";

const PackageModal = (props) => {
  const { extraValue } = props;
  return (
    <>
      <Modal isOpen={true} style={{ width: "550px" }}>
        <div className="modal-body">
          <Row
            style={{
              borderBottom: "1px solid #bac1c7",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Col>
              <h3
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                Package Type
              </h3>
            </Col>
            <Col>
              <h3
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                Package Name
              </h3>
            </Col>
            <Col>
              <h2
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                Count
              </h2>
            </Col>
          </Row>

          {/* </div> */}
          {extraValue?.packageData?.package?.map((ele, i) => (
            // <div
            //   key={i}
            //   style={{ display: "flex", justifyContent: "space-around" }}
            // >
            <Row>
              <Col>
                <h3 style={{ fontSize: "15px" }}>
                  {
                    extraValue?.packageTypeData?.find(
                      (item) => item?.id == ele?.packageId
                    )?.type
                  }
                </h3>
              </Col>
              <Col>
                <h3 style={{ fontSize: "15px" }}>
                  {
                    extraValue?.packageTypeData?.find(
                      (item) => item?.id == ele?.packageId
                    )?.DropdownName
                  }
                </h3>
              </Col>
              <Col>
                <h2 style={{ fontSize: "15px" }}>{ele?.count}</h2>
              </Col>
            </Row>
            // </div>
          ))}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={extraValue?.onCloseModal}
            className="btn btn-primary waves-effect"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
        {/* <div>jdgfbjdfb</div> */}
      </Modal>
    </>
  );
};

export default PackageModal;
