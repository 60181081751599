export const TABLE_CONFIG = {
  no: {
    label: <p style={{ margin: "0px", width: "70px" }}>Record id</p>,
    field: "no",
    sort: "asc",
  },
  productName: {
    label: <p style={{ margin: "0px", width: "70px" }}>Product name</p>,
    field: "productName",
    sort: "asc",
  },
  tags: {
    label: <p style={{ margin: "0px", width: "70px" }}>Tags</p>,
    field: "tags",
    sort: "asc",
  },
  dataModify: {
    label: <p style={{ margin: "0px", width: "70px" }}>Date modify</p>,
    field: "dataModify",
    sort: "asc",
  },
  productCategory: {
    label: <p style={{ margin: "0px", width: "70px" }}>Product category</p>,
    field: "productCategory",
    sort: "asc",
  },
  productWeight: {
    label: <p style={{ margin: "0px", width: "70px" }}>Weight</p>,
    field: "productWeight",
    sort: "asc",
  },
  carbonFootprint: {
    label: <p style={{ margin: "0px", width: "70px" }}>Carbon footprint</p>,
    field: "carbonFootprint",
    sort: "asc",
  },
  selector: {
    label: <p style={{ margin: "0px", width: "70px" }}>Delete</p>,
    field: "selector",
    sort: "disabled",
  },
  clientName: {
    label: <p style={{ margin: "0px", width: "100px" }}>Name</p>,
    field: "clientName",
    sort: "asc",
    color: "success",
  },
  insuranceNumber: {
    label: "Insurance number",
    field: "insuranceNumber",
    sort: "asc",
  },
  tripEndDistance: {
    label: (
      <p style={{ margin: "0px", width: "90px" }}>Shipment end distance (km)</p>
    ),
    field: "tripEndDistance",
    sort: "asc",
  },
  view: {
    label: "Detail",
    field: "view",
    sort: "disabled",
  },
  action: {
    label: (
      <p style={{ margin: "0px", width: "100px", textAlign: "center" }}>
        Action
      </p>
    ),
    field: "action",
    sort: "disabled",
  },
  unitName: {
    label: "Site name",
    field: "unitName",
    sort: "asc",
  },
  pradr: {
    label: "Location",
    field: "pradr",
    sort: "asc",
  },
  GST: {
    label: "GST number",
    field: "GST",
    sort: "asc",
  },
  access: {
    label: <p style={{ textAlign: "center", margin: "0" }}>Site access</p>,
    field: "access",
    sort: "disabled",
  },
  link: {
    label: (
      <p style={{ textAlign: "center", width: "35px", margin: "0" }}>Map</p>
    ),
    field: "link",
    sort: "disabled",
  },
  displayName: {
    label: <p style={{ width: "200px", margin: "0" }}>Route display name</p>,

    field: "displayName",
    sort: "asc",
  },
  routeType: {
    label: <p style={{ width: "80px", margin: "0" }}>Route type</p>,
    field: "routeType",
    sort: "asc",
  },
  vehicleType: {
    label: <p style={{ width: "80px", margin: "0" }}>Vehicle type</p>,
    field: "vehicleType",
    sort: "asc",
  },
  vehicleCapacity: {
    label: "Vehicle capacity",
    field: "vehicleCapacity",
    sort: "asc",
  },
  ownerShipType: {
    label: "Ownership type",
    field: "ownerShipType",
    sort: "asc",
  },
  expectedtime: {
    label: (
      <p style={{ width: "140px", margin: "0" }}>
        Expected completion (in hour)
      </p>
    ),
    field: "expectedtime",
    sort: "asc",
  },

  freightWeight: {
    label: (
      <p style={{ width: "100px", margin: "0" }}>Freight weight (in ton)</p>
    ),

    field: "freightWeight",
    sort: "asc",
  },

  type: {
    label: "Type",
    field: "type",
    sort: "asc",
  },
  SubType: {
    label: <p style={{ width: "60px", margin: "0" }}>Sub-type</p>,
    field: "SubType",
    sort: "asc",
  },
  DimensionInmm: {
    label: (
      <p style={{ width: "105px", margin: "0px" }}>Dimension (LxBxH in mm)</p>
    ),
    field: "DimensionInmm",
    sort: "asc",
  },
  DropdownName: {
    label: <p style={{ width: "100px", margin: "0" }}>Display name</p>,
    field: "DropdownName",
    sort: "asc",
  },
  VolumeCBM: {
    label: (
      <p style={{ width: "105px", margin: "0px" }}>
        Volume (m<sup>3</sup>)
      </p>
    ),
    field: "VolumeCBM",
    sort: "asc",
  },
  VolumetricWeightInKG: {
    label: "Volumetric weight(KG)",
    field: "VolumetricWeightInKG",
    sort: "asc",
  },
  ActualWeight: {
    label: "Actual weight(KG)",
    field: "ActualWeight",
    sort: "asc",
  },
  material: {
    label: "Material",
    field: "material",
    sort: "asc",
  },
  co2EmissionPerKG: {
    label: (
      <p style={{ width: "105px", margin: "0px" }}>
        tCO<sub>2</sub>e per kg
      </p>
    ),
    field: "co2EmissionPerKG",
    sort: "asc",
  },
  lifeTimetCO2e: {
    label: (
      <p style={{ width: "105px", margin: "0px" }}>
        Lifetime tCO<sub>2</sub>e
      </p>
    ),
    field: "lifeTimetCO2e",
    sort: "asc",
  },
  lifeTimeTrips: {
    label: "Lifetime trips",
    field: "lifeTimeTrips",
    sort: "asc",
  },
  // {
  //   label: "PlaceId",
  //   field: "placeId",
  //   sort: "asc",
  // },
  tCO2PerShipment: {
    label: (
      <p style={{ width: "105px", margin: "0px" }}>
        tCO<sub>2</sub>e per shipment
      </p>
    ),
    field: "tCO2PerShipment",
    sort: "asc",
  },
  TransporterName: {
    label: "Name",
    field: "TransporterName",
    sort: "asc",
  },
  gstNumber: {
    label: "GST number",
    field: "gstNumber",
    sort: "asc",
  },
  dl_no: {
    label: <p style={{ margin: "0px", width: "100px" }}>Driving license</p>,
    field: "dl_no",
    sort: "asc",
  },
  mobile: {
    label: <p style={{ margin: "0px", width: "100px" }}>Mobile number</p>,
    field: "mobile",
    sort: "asc",
  },
  consent: {
    label: "Consent",
    field: "consent",
    sort: "asc",
  },

  RegistrationNumbers: {
    label: "Registration number",
    field: "RegistrationNumbers",
    sort: "asc",
  },
  manufacture: {
    label: "Manufacturer",
    field: "manufacture",
    sort: "asc",
  },
  fuelType: {
    label: "Fuel type",
    field: "fuelType",
    sort: "asc",
  },
  transporterName: {
    label: "Transporter name",
    field: "transporterName",
    sort: "asc",
  },
  co2PerKm: {
    label: (
      <p style={{ width: "65px", margin: "0px" }}>
        tCO<sub>2</sub>e/km
      </p>
    ),
    field: "co2PerKm",
    sort: "asc",
  },
  mileage: {
    label: (
      <p style={{ margin: "0px" }}>
        Mileage
        <br />
        (l/km)
      </p>
    ),
    field: "mileage",
    sort: "asc",
  },

  pod: {
    label: "Upload PoD",
    field: "pod",
    sort: "disabled",
  },
  mapView: {
    label: "Map view",
    field: "mapView",
    sort: "disabled",
  },
  billDownload: {
    label: "Docket",
    field: "billDownload",
    sort: "disabled",
  },
  statusData: {
    label: "Status",
    field: "statusData",
    sort: "asc",
  },

  plantName: {
    label: "Origin",
    field: "plantName",
    sort: "asc",
  },
  startDateAndTime: {
    label: <p style={{ width: "105px", margin: "0px" }}>Start date & time</p>,
    field: "startDateAndTime",
    sort: "asc",
    width: 500,
  },
  sourceLocation: {
    label: <p style={{ width: "105px", margin: "0px" }}>Source</p>,
    field: "sourceLocation",
    sort: "asc",
  },
  destinationLocation: {
    label: <p style={{ width: "105px", margin: "0px" }}>Destination</p>,
    field: "destinationLocation",
    sort: "asc",
  },
  driverName: {
    label: <p style={{ width: "105px", margin: "0px" }}>Driver name</p>,
    field: "driverName",
    sort: "asc",
  },
  driverPhoneNumber: {
    label: <p style={{ width: "105px", margin: "0px" }}>Driver’s contact</p>,
    field: "driverPhoneNumber",
    sort: "asc",
  },
  vehicleNumber: {
    label: <p style={{ width: "105px", margin: "0px" }}>Vehicle number</p>,
    field: "vehicleNumber",
    sort: "asc",
  },
  targetedDateAndTime: {
    label: <p style={{ width: "105px", margin: "0px" }}>Expected ETA</p>,
    field: "targetedDateAndTime",
    sort: "asc",
  },
  actualeta: {
    label: <p style={{ width: "105px", margin: "0px" }}>Actual ETA</p>,
    field: "actualeta",
    sort: "asc",
  },

  carbonEmission: {
    label: (
      <p style={{ width: "85px", margin: "0px" }}>
        GHG emission (tCO{<sub>2</sub>}e)
      </p>
    ),
    field: "carbonEmission",
    sort: "asc",
  },
  packageEmission: {
    label: (
      <p style={{ width: "85px", margin: "0px" }}>
        Packaging emission (tCO{<sub>2</sub>}e)
      </p>
    ),
    field: "packageEmission",
    sort: "asc",
  },
  totalGen: {
    label: (
      <p style={{ width: "85px", margin: "0px" }}>
        Total carbon emission (tCO{<sub>2</sub>}e)
      </p>
    ),
    field: "totalGen",
    sort: "asc",
  },
  utilisation: {
    label: (
      <p style={{ width: "150px", margin: "0px" }}>
        Carbon efficiency (tCO<sub>2</sub>e/freight tonne-km)
      </p>
    ),
    field: "utilisation",
    sort: "asc",
  },
  // {
  //   label: (
  //     <p style={{ width: "85px", margin: "0px" }}>Fleet Charges (₹)</p>
  //   ),
  //   field: "fleetCharges",
  //   sort: "asc",
  // },
  // {
  //   label: <p style={{ width: "85px", margin: "0px" }}>GST Charges (₹)</p>,
  //   field: "gstCharges",
  //   sort: "asc",
  // },
  invoiceNumber: {
    label: "Invoice no",
    field: "invoiceNumber",
    sort: "asc",
  },
  packageType: {
    label: "Packages",
    field: "packageType",
    sort: "asc",
  },
  name: {
    label: <p style={{ margin: "0px", width: "150px" }}>Name</p>,
    field: "name",
    sort: "asc",
  },
  email: {
    label: <p style={{ margin: "0px", width: "150px" }}>Email id</p>,
    field: "email",
    sort: "asc",
  },
  roleColomn: {
    label: <p style={{ margin: "0px", width: "150px" }}>Role</p>,
    field: "roleColomn",
    sort: "asc",
  },
  client: {
    label: <p style={{ margin: "0px", width: "150px" }}>Customer</p>,
    field: "client",
    sort: "asc",
  },
  reset: {
    label: (
      <p style={{ margin: "0px", width: "70px", textAlign: "center" }}>
        Reset password
      </p>
    ),
    field: "reset",
    sort: "disabled",
  },
  //job
  uploadFile: {
    label: <p style={{ margin: "0px", width: "90px" }}>Uploaded file</p>,
    field: "uploadFile",
    sort: "disabled",
  },
  uploadedRecord: {
    label: <p style={{ margin: "0px", width: "120px" }}>Uploaded records</p>,
    field: "uploadedRecord",
    sort: "asc",
  },
  successRecord: {
    label: <p style={{ margin: "0px", width: "110px" }}>Success records</p>,
    field: "successRecord",
    sort: "asc",
  },
  failedRecord: {
    label: <p style={{ margin: "0px", width: "100px" }}>Failed records</p>,
    field: "failedRecord",
    sort: "asc",
  },
  responseFile: {
    label: <p style={{ margin: "0px", width: "90px" }}>Response file</p>,
    field: "responseFile",
    sort: "disabled",
  },

  CategoryId: {
    label: "Category id",
    field: "no",
    sort: "asc",
  },
  // CategoryId:{
  //   label: "Name",
  //   field: "name",
  //   sort: "disabled",
  // },
  scope: {
    label: "Scope",
    field: "scope",
    sort: "disabled",
  },
  color: {
    label: "Color",
    field: "color",
    sort: "disabled",
  },
  attributeId: {
    label: "Attribute id",
    field: "no",
    sort: "asc",
  },
  sampleFile: {
    label: "Sample file",
    field: "sampleFile",
    sort: "disabled",
  },
  csvName: {
    label: "Csv name",
    field: "csv",
    sort: "asc",
  },
  categoryFormModalName: {
    label: <p style={{ margin: "0px", width: "90px" }}>Form-modal name</p>,
    field: "categoryFormModalName",
    sort: "asc",
  },
  createdDate: {
    label: <p style={{ margin: "0px", width: "90px" }}>Created date</p>,
    field: "createdDate",
    sort: "disabled",
  },
  category: {
    label: <p style={{ margin: "0px", width: "100px" }}>Category name</p>,
    field: "category",
    sort: "asc",
  },
  viewRole: {
    label: "View role",
    field: "viewRole",
    sort: "disabled",
  },
  isVisible: {
    label: "Visible",
    field: "isVisible",
    sort: "disabled",
  },
  description: {
    label: "Description",
    field: "description",
    sort: "asc",
  },
  postmanLink: {
    label: "Postman link",
    field: "postmanLink",
    sort: "disabled",
  },
  phone: {
    label: <p style={{ margin: "0px", width: "150px" }}>Phone</p>,
    field: "phone",
    sort: "asc",
  },
  department: {
    label: <p style={{ margin: "0px", width: "150px" }}>Department</p>,
    field: "department",
    sort: "asc",
  },
  section:{
    label: <p style={{ margin: "0px", width: "100px" }}>Section id</p>,
    field: "section",
    sort: "asc",
  },
  sectionName:{
    label: <p style={{ margin: "0px", width: "250px" }}>Section name</p>,
    field: "sectionName",
    sort: "asc",
  },
  totalFields:{
    label: <p style={{ margin: "0px", width: "100px" }}>Total fields</p>,
    field: "totalFields",
    sort: "asc",
  },
  deadline:{
    label: <p style={{ margin: "0px", width: "100px" }}>ETA</p>,
    field: "deadline",
    sort: "asc",
  },
  filledFields:{
    label: <p style={{ margin: "0px", width: "150px" }}>Completed fields</p>,
    field: "filledFields",
    sort: "asc",
  },
  score:{
    label: <p style={{ margin: "0px", width: "80px" }}>Score</p>,
    field: "score",
    sort: "asc",
  },
  maxScore:{
    label: <p style={{ margin: "0px", width: "80px" }}>Max score</p>,
    field: "maxScore",
    sort: "asc",
  },
  progressBar:{
    label: <p style={{ margin: "0px", width: "80px" }}>Progress</p>,
    field: "progressBar",
    sort: "disabled",
  },
  editForm:{
    label: <p style={{ margin: "0px", width: "80px" }}>Edit form</p>,
    field: "editForm",
    sort: "disabled",
  },
  updateBRSRForm:{
    label: <p style={{ margin: "0px", width: "80px" }}>Set ETA</p>,
    field: "updateBRSRForm",
    sort: "disabled",
  },
  spocs:{
    label: <p style={{ margin: "0px", width: "130px" }}>Assigned users</p>,
    field: "spocs",
    sort: "disabled",
  },
  SPOCname:{
    label: <p style={{ margin: "0px", width: "150px" }}>Name</p>,
    field: "SPOCname",
    sort: "asc",
  },
  SPOCemail:{
    label: <p style={{ margin: "0px", width: "150px" }}>Email</p>,
    field: "SPOCemail",
    // sort: "asc",
  },
  SPOCphone:{
    label: <p style={{ margin: "0px", width: "150px" }}>Contact</p>,
    field: "SPOCphone",
    sort: "disabled",
  },
  SPOCdepartment:{
    label: <p style={{ margin: "0px", width: "150px" }}>Department</p>,
    field: "SPOCdepartment",
    sort: "asc",
  },
  totalQuestion:{
    label: <p style={{ margin: "0px", width: "100px" }}>Total Questions</p>,
    field: "totalQuestion",
    sort: "asc",
  },
  answeredQuestion:{
    label: <p style={{ margin: "0px", width: "150px" }}>Filled Questions</p>,
    field: "answeredQuestion",
    sort: "asc",
  },
  connectionFrom:{
    label:<p style={{ margin: "0px", width: "150px" }}>Customer name</p>,
    field:"connectionFrom",
    sort:"asc",
  },
  connectionTo:{
    label:<p style={{ margin: "0px", width: "150px" }}>Supplier name</p>,
    field:"connectionTo",
    sort:"asc",
  },
  status:{
    label:<p style={{ margin: "0px", width: "150px" }}>Status</p>,
    field:"status",
    sort:"asc",
  },
  gstIn:{
    label:<p style={{ margin: "0px", width: "150px" }}>GSTIN</p>,
    field:"gstIn",
    sort:"disabled",
  },
  pocName:{
    label:<p style={{ margin: "0px", width: "150px" }}>Contact person name</p>,
    field:"pocName",
    sort:"disabled",
  },
  pocMobile:{
    label:<p style={{ margin: "0px", width: "150px" }}>Contact person mobile</p>,
    field:"pocMobile",
    sort:"disabled",
  },
  categoryServiceProvider:{
    label:<p style={{ margin: "0px", width: "150px" }}>Category</p>,
    field:"categoryServiceProvider",
    sort:"asc",
  },
  error:{
    label: <p style={{ margin: "0px", width: "150px" }}>Status failed reason</p>,
    field: "errorMessage",
    sort:"asc",
  },
  name:{
    label: <p style={{ margin: "0px", width: "150px" }}>Client admin name</p>,
    field:"name",
    sort:"disabled",
  },
  deleteConnection:{
    label: <p style={{ margin: "0px", width: "150px" }}>Delete connection</p>,
    field:"deleteConnection",
    sort:"disabled",
  },
  updateStatus:{
    label: <p style={{ margin: "0px", width: "150px", textAlign: "center" }}>Update status</p>,
    field:"updateStatus",
    sort:"disabled",
  },
};
