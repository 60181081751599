import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { getMobileNumber, getUpperCaseText } from "../../Utility/function";
import CustomPage from "../../../components/Custome/CustomPage";
import { useSelector } from "react-redux";
import {
  addCustomerStart,
  deleteCustomerStart,
  getCustomerStart,
  updateCustomerStart,
} from "../../../store/actions";
import notify from "../../Utility/coustemFunction";
import CONSTANT, { TABLE_HEADER } from "../../Utility/constnt";
import { customerHeaderData } from "../../Utility/exportValue";
import { customerDataMask } from "../../Utility/spread-function";

const Customers = () => {
  const getClientData = useSelector((state) => state.customerDetailData);

  return (
    <div className="page-content">
      <CustomPage
        PAGE={{
          title: "Customer",
          dataFunction: customerDataMask,
        }}
        API={CONSTANT.API.CLIENT}
        REDUX={{
          getDataRedux: getClientData?.users,
          getApiDataRedux: getCustomerStart(),
          deleteApiDataRedux: deleteCustomerStart,
        }}
        FORM={{
          formField: CONSTANT.FORM_FIELDS?.CUSTOMER,
          addFormTitle: "Add customer",
          editFormTitle: "Edit customer",
          addNewRecordCtaText: "Add customer",
          optionalFieldOnEdit: ["tripEndDistance", "insuranceNumber"],
        }}
        TABLE={{
          title: "Customer list",
          header: TABLE_HEADER?.customer,
          export: {
            fileName: "Customer_Report.csv",
            header: customerHeaderData,
          },
        }}
        //redux
        // getData={getClientData}
      />
    </div>
  );
};

export default Customers;
