



import React, { useEffect, useState } from "react";
import { Input, Button, Form, Typography, Select } from "antd";
import useHttp from "../../../components/Hook/Use-http";
import CONSTANT from "../../Utility/constnt";
import { replaceFormIdandClientId, replaceId } from "../../Utility/function";
import { useParams } from "react-router-dom";
import BrsrFormHeading from "../BrsrFormHeading";
import Questions from "../../../components/Report/Questions";
import { DeleteFilled } from "@ant-design/icons";


const { Text } = Typography;
const { Option } = Select;


const ProductFile = () => {
  const API_CALL = useHttp();
  const { id } = useParams();
  const userRole = localStorage.getItem("userRole");
  const [questions, setQuestions] = useState([]);
  const [form] = Form.useForm();


  useEffect(() => {
    const parser = (data = []) => data.map((curr) => {
      const dataSchema = JSON.parse(curr.Question.answerSchema);
      let answerString = curr.Question?.Answers[0]?.value;
      return {
        id: curr.Question.id,
        question: curr.Question.description,
        options: dataSchema.options,
        answerSchema: dataSchema,
        type: dataSchema.type || "userDefined",
        answer: answerString,
        valueType: curr.Question?.Answers[0]?.valueType,
      };
    });


    const sectionId = 6;
    const fetchData = (url) => {
      API_CALL.sendRequest(url, (res) => {
        const globalQuestions = parser(res?.data?.GlobalSectionQuestions);
        setQuestions(globalQuestions);


        const initialValues = {};
        globalQuestions.forEach((q) => {
          if (q.answer) {
            const parsedAnswer = JSON.parse(q.answer);
            initialValues[q.id] = parsedAnswer;
          }
        });
        form.setFieldsValue(initialValues);
      });
    };


    if (userRole === "client") {
      fetchData(replaceId(CONSTANT.API.GLOBALFORMS.get, sectionId));
    } else if (userRole === "admin") {
      fetchData(replaceFormIdandClientId(CONSTANT.API.GLOBALFORMS.get2, sectionId, id));
    } else if (userRole == "spoc") {
      API_CALL.sendRequest(
        replaceFormIdandClientId(
          CONSTANT.API.GLOBALFORMS.get,
          sectionId,
          id
        ),
        (res) => {
          let globalQuestions = parser(res?.data[0]?.GlobalSection?.GlobalSectionQuestions);
          setQuestions(globalQuestions);
          // setLoading(false);
        }
      );
    }
  }, []);


  const handleSubmit = (data = {}) => {
    try {
      const transformData = (data) => ({
        answers: Object.entries(data).map(([key, value]) => {
          const question = questions.find((q) => q.id === Number(key));
          let formattedValue;


          if (question && (question.type === "fixedtable" || question.type === "dynamictable")) {
            formattedValue = JSON.stringify(question.transformedAnswer);
          } else {
            formattedValue = Array.isArray(value)
              ? JSON.stringify(value)
              : value;
          }


          return {
            qid: Number(key),
            value: formattedValue,
          };
        }),
      });


      // console.log(data);


      // API_CALL sendRequest code as before
    } catch (error) {
      console.log(error);
    }
  };


  const Question59 = ({ ques }) => {
    const [inputKeys, setInputKeys] = useState([1]);


    const handleAddInput = () => {
      setInputKeys([...inputKeys, inputKeys.length + 1]);
    };


    const handleDeleteInput = (key) => {
      setInputKeys(inputKeys.filter((k) => k !== key));
      form.setFieldsValue({ [`${ques.id}_${key}`]: undefined });
      form.setFieldsValue({
        [ques.id]: inputKeys
          .filter((k) => k !== key)
          .map((k) => form.getFieldValue(`${ques.id}_${k}`)),
      });
    };


    const handleChange = (key, e) => {
      const values = form.getFieldValue(ques.id) || [];
      values[key - 1] = e.target.value;
      form.setFieldsValue({ [ques.id]: values });
    };


    return (
      <Form.Item 
      label={
        <span style={{ fontWeight: 'bold', fontSize: 16 }}>
          {`4. ${ques.question}`}
        </span>
      }
      name={ques.id}
      >
        {inputKeys.map((key) => (
          <div key={key} style={{ display: "flex", marginBottom: 8 }}>
            <Form.Item
              name={`${ques.id}_${key}`}
              style={{ marginRight: 10, width: 400, display: "flex", alignItems: "center" }}
            >
              <Input
                style={{ width: 400 }}
                onChange={(e) => handleChange(key, e)}
              />
            </Form.Item>
            <DeleteFilled
              style={{ color: "red", cursor: "pointer", height: 30 }}
              onClick={() => handleDeleteInput(key)}
            />
          </div>
        ))}
        <Button
          style={{
            marginTop: "0px",
            backgroundColor: "#060DBF",
            color: "#62C941",
            height: "40px",
            cursor: "pointer",
          }}
          onClick={handleAddInput}
        >
          Add
        </Button>
      </Form.Item>
    );
  };


  const Question60 = ({ ques }) => {
    const values59 = Form.useWatch(59, form);
    const [selectValues, setSelectValues] = useState({});


    const handleSelectChange = (index, value) => {
      setSelectValues({
        ...selectValues,
        [index]: value,
      });
    };


    return (
      <Form.Item 
      label={
        <span style={{ fontWeight: 'bold', fontSize: 16 }}>
          {`5. ${ques.question}`}
        </span>
      }
      >
        {values59?.map((input, index) => (
          input && (
            <div key={index} style={{ display: "flex", marginBottom: 8, marginLeft: 20 }}>
              <Text style={{ marginRight: 8, marginTop: 1 }}>{input} : </Text>
              <Select
                value={selectValues[index] || ""}
                onChange={(value) => handleSelectChange(index, value)}
                style={{ width: 500 }}
              >
                <Option value="Risk">Risk</Option>
                <Option value="Opportunity">Opportunity</Option>
              </Select>
            </div>
          )
        ))}
      </Form.Item>
    );
  };


  const Question61 = ({ ques }) => {
    const values59 = Form.useWatch(59, form);


    return (
      <Form.Item 
      label={
        <span style={{ fontWeight: 'bold', fontSize: 16 }}>
          {`6. ${ques.question}`}
        </span>
      }
      >
        {values59?.map((input, index) => (
          input && (
            <div key={index} style={{ display: "flex", marginBottom: 8, marginLeft: 20 }}>
              <Text style={{ marginRight: 8, marginTop: 1 }}>{input} : </Text>
              <Form.Item name={`input_${ques.id}_${index}`}>
                <Input style={{ width: 500 }} />
              </Form.Item>
            </div>
          )
        ))}
      </Form.Item>
    );
  };


  const Question62 = ({ ques }) => {
    const values59 = Form.useWatch(59, form);


    return (
      <Form.Item 
      label={
        <span style={{ fontWeight: 'bold', fontSize: 16 }}>
          {`7. ${ques.question}`}
        </span>
      }
      >
        {values59?.map((input, index) => (
          input && (
            <div key={index} style={{ display: "flex", marginBottom: 8, marginLeft: 20 }}>
              <Text style={{ marginRight: 8, marginTop: 1 }}>{input} : </Text>
              <Form.Item name={`input_${ques.id}_${index}`}>
                <Input style={{ width: 500 }} />
              </Form.Item>
            </div>
          )
        ))}
      </Form.Item>
    );
  };


  const Question63 = ({ ques }) => {
    const values59 = Form.useWatch(59, form);


    return (
      <Form.Item 
      label={
        <span style={{ fontWeight: 'bold', fontSize: 16 }}>
          {`8. ${ques.question}`}
        </span>
      }
      >
        {values59?.map((input, index) => (
          input && (
            <div key={index} style={{ display: "flex", marginBottom: 8, marginLeft: 20 }}>
              <Text style={{ marginRight: 8, marginTop: 1 }}>{input} : </Text>
              <Form.Item name={`input_${ques.id}_${index}`}>
                <Input style={{ width: 500 }} />
              </Form.Item>
            </div>
          )
        ))}
      </Form.Item>
    );
  };


  return (
    <div style={{ marginTop: 100 }}>
      <BrsrFormHeading title="Products and services - General" />
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        {questions.map((ques, index) =>
          ques.id === 59 ? (
            <Question59 key={ques.id} ques={ques} />
          ) : ques.id === 60 ? (
            <Question60 key={ques.id} ques={ques} />
          ) : ques.id === 61 ? (
            <Question61 key={ques.id} ques={ques} />
          ) : ques.id === 62 ? (
            <Question62 key={ques.id} ques={ques} />
          ) : ques.id === 63 ? (
            <Question63 key={ques.id} ques={ques} />
          ) : (
            <Questions key={ques.id} ques={ques} index={index} />
          )
        )}
        <Button
          type="primary"
          htmlType="submit"
          style={{
            position: "fixed",
            bottom: "30px",
            backgroundColor: "#060DBF",
            color: "#62C941",
            right: "74px",
            zIndex: 1000,
            height: "40px",
            cursor: "pointer",
          }}
        >
          Save changes
        </Button>
      </Form>
    </div>
  );
};


export default ProductFile;



