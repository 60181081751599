import {
  ADD_CUSTOMER_START,
  ADD_CUSTOMER_FAILURE,
  ADD_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_START,
  GET_CUSTOMER_FAILURE,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_START,
  UPDATE_CUSTOMER_START,
  UPDATE_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAILURE,
  DELETE_CUSTOMER_SUCCESS,
} from "./actionTypes";

//GET
export const getCustomerStart = () => {
  return {
    type: GET_CUSTOMER_START,
  };
};
export const getCustomerSuccess = (users) => {
  return {
    type: GET_CUSTOMER_SUCCESS,
    payload: users,
  };
};
export const getCustomerError = (error) => {
  return {
    type: GET_CUSTOMER_FAILURE,
    payload: error,
  };
};

//ADD
export const addCustomerStart = (item, successCallback) => ({
  type: ADD_CUSTOMER_START,
  payload: item,
  successCallback,
});

export const addCustomerSucess = (user) => ({
  type: ADD_CUSTOMER_SUCCESS,
  payload: user,
});

export const addCustomerError = (error) => ({
  type: ADD_CUSTOMER_FAILURE,
  payload: error,
});

//UPDATE
export const updateCustomerStart = (item, id, successCallback) => ({
  type: UPDATE_CUSTOMER_START,
  payload: item,
  id,
  successCallback,
});

export const updateCustomerSucess = (user) => ({
  type: UPDATE_CUSTOMER_SUCCESS,
  payload: user,
});

export const updateCustomerError = (error) => ({
  type: UPDATE_CUSTOMER_FAILURE,
  payload: error,
});

//DELETE
export const deleteCustomerStart = (id, successCallback) => ({
  type: DELETE_CUSTOMER_START,
  payload: id,
  successCallback,
});

export const deleteCustomerSucess = (id) => ({
  type: DELETE_CUSTOMER_SUCCESS,
  payload: id,
});

export const deleteCustomerError = (error) => ({
  type: DELETE_CUSTOMER_FAILURE,
  payload: error,
});

// export const updateCustomer = (item) => ({
//   type: UPDATE_CUSTOMER_START,
//   payload: item,
// });

// export const deleteCustomer = (id) => ({
//   type: DELETE_CUSTOMER_START,
//   payload: id,
// });
