import React, { useEffect, useState } from "react";
import useHttp from "../../components/Hook/Use-http";
import {
  calculatePackageCount,
  getExtraConstPayload,
  packegingAssetForSite,
  replaceId,
} from "../Utility/function";
import CustomPage from "../../components/Custome/CustomPage";
import CONSTANT, { MyData } from "../Utility/constnt";
import { Button, Col, Modal, Row } from "reactstrap";
import Table from "../../components/Custome/table";
import PackagingLocationDetail from "../../components/packagingLocationDetail";
import { useSelector } from "react-redux";
import { sitePackaginInfoDataLoad } from "../Utility/spread-function";
import { useParams } from "react-router-dom";

let initValue = {};

const SitePackageInfo = () => {
  const [idData, setIdData] = useState([]);
  const API_CALL2 = useHttp();
  const myData = useSelector((state) => state.userGetMeData);

  // let SiteID = window.location.pathname.split("/")[2];
  let { SiteID } = useParams();

  if (localStorage.getItem("userRole") === "siteViewer") {
    SiteID = myData?.users?.plantId;
  }

  const [pacakgeData, setPackageData] = useState([]);

  const [routeData, setRouteData] = useState([]);
  const [siteData, setSiteData] = useState([]);

  const [pacakegingData, setPackagingData] = useState([]);

  const [tableHeader, setTableHeader] = useState([]);
  const [viewAction, setViewAction] = useState(null);
  const [exportHeaders, setExportHeaders] = useState(false);

  const [refresh, setRefresh] = useState(false);

  const getPackagingData = useSelector((state) => state.packagingDetailData);
  const getSiteData = useSelector((state) => state.plantDetailData);
  const getRouteData = useSelector((state) => state.routeDetailData);

  useEffect(() => {
    if (SiteID != undefined) {
      API_CALL2.getAll(
        [
          // replaceId(CONSTANT.API.SITE.getSinglePlant, SiteID).endpoint,
          replaceId(CONSTANT.API.PACKAGEING.getSinglepackageDataSite, SiteID)
            .endpoint,
          // CONSTANT?.API?.ROUTE?.get?.endpoint,
          // CONSTANT.API.PACKAGEING.get.endpoint,
        ],
        (res) => {
          // Site Data
          // setSiteData(res[0]?.data?.data[0]);

          // Package Usage Data
          // res[2]?.data?.data?.map((item) => {
          //   initValue = { ...initValue, [item?.id]: 0 };
          // });
          packagingDataHandler(res[0]?.data);

          // setTableHeader(packegingAssetForSite(res[2]?.data?.data, SiteID));

          // All Package Data
          // setPackageData(res[3]?.data?.data);

          // // All Route Data
          // setRouteData(res[2]?.data?.data);
        }
      );
    }
  }, [refresh, SiteID]);

  useEffect(() => {
    setTimeout(() => {
      setSiteData(
        getSiteData?.users?.data?.rows?.find((it) => it?.id == SiteID)
      );
      setRouteData(getRouteData?.users?.data?.rows);
      setPackageData(getPackagingData?.users?.data?.rows);
      setTableHeader(
        packegingAssetForSite(getRouteData?.users?.data?.rows, SiteID)
      );
      getRouteData?.users?.data?.rows?.map((item) => {
        initValue = { ...initValue, [item?.id]: 0 };
      });
    }, 100);
  }, [refresh, SiteID, getSiteData, getRouteData, getPackagingData]);

  useEffect(() => {
    if (SiteID != undefined) {
      setSiteData(
        getSiteData?.users?.data?.rows?.find((it) => it?.id == SiteID)
      );
      setRouteData(getRouteData?.users?.data?.rows);
      setPackageData(getPackagingData?.users?.data?.rows);
      setTableHeader(
        packegingAssetForSite(getRouteData?.users?.data?.rows, SiteID)
      );
      getRouteData?.users?.data?.rows?.map((item) => {
        initValue = { ...initValue, [item?.id]: 0 };
      });
    }
  }, [getSiteData, getRouteData, getPackagingData]);

  useEffect(() => {
    setExportHeaders(() => {
      let headers = {};
      tableHeader
        ?.filter((item) => item?.field !== "view")
        ?.map((item) => {
          headers = { ...headers, [item?.field]: item?.label };
        });

      return headers;
    });
  }, [tableHeader]);

  const packagingDataHandler = (res) => {
    sitePackaginInfoDataLoad(res, setPackagingData, setViewAction, initValue);
  };

  const onCloseModal = () => {
    setViewAction(null);
  };

  return (
    <>
      <div className="page-content">
        <CustomPage
          API={{
            create:
              localStorage.getItem("userRole") === "admin"
                ? CONSTANT.API.PACKAGINGASSIGNMENT.create
                : null,
          }}
          PAGE={{
            title: `Sites  ${
              siteData?.unitName ? `${`/ ${siteData?.unitName}`}` : ""
            }`,
            withBackButton: true,

            // datafunction: (res) => packageAssignment(res, SiteID),
          }}
          // CreateAPI={
          //   localStorage.getItem("userRole") === "admin"
          //     ? CONSTANT.API.PACKAGEING.addPackageAssignment
          //     : null
          // }
          // TABLE={{
          //   title: "Packaging Assets List",
          //   header: CONSTANT?.DATA_TABLE_COLUME?.admin_Package_Assignment,
          //   export: {
          //     fileName: "PackagingAsset_Report.csv",
          //     header: PackagingAssetsHeaderData,
          //   },
          // }}
          FORM={{
            formField: CONSTANT.FORM_FIELDS?.PACKAGEASSIGNMENT,
            addFormTitle: "Add packaging assets",

            addNewRecordCtaText: "Add packaging assets", //===========
            // isSubmitButtonVisible: vehicleData?.registration_number ? true : false,
            extraConstPayload: getExtraConstPayload(idData),

            option: {
              packagingTypeId: pacakgeData
                ? Object?.values(
                    pacakgeData
                      ?.filter((item) => item?.type == "Reusable")
                      ?.reduce((acc, data) => {
                        const { DropdownName, id } = data;
                        if (!acc[DropdownName]) {
                          acc[DropdownName] = {
                            label: DropdownName,
                            value: id,
                          };
                        }
                        return acc;
                      }, {})
                  )
                : {},
              routeId: routeData
                ? routeData
                    ?.filter((item) => {
                      return (
                        item?.routeType == "Forward" &&
                        (item?.plantId?.toString() == SiteID?.toString() ||
                          item?.destinationPlantId?.toString() ===
                            SiteID?.toString())
                      );
                    })
                    ?.map((res, data) => {
                      return { label: res?.routeName, value: res?.id };
                      // const { SubType, id } = data;
                      // if (!acc[SubType]) {
                      //   acc[SubType] = { label: SubType, value: id };
                      // }
                      // return acc;
                    }, {})
                : {},
            },
            onChange: (e) => {
              if (e?.routeId) {
                setIdData(() => {
                  const chooseRoute = routeData?.find(
                    (item) => item.id == e?.routeId
                  );

                  return {
                    clientId: chooseRoute?.plant?.client?.id,
                    plantId: chooseRoute?.plant?.id,
                  };
                });
              }
            },
          }}
          // ModalName="PACKAGEASSIGNMENT"
          // addName="Add Packaging Assets"
          // addFormTitle="Add Packaging Assets"
          // leftSidePageName="PACKAGING Assets"
          // pageHeading={`SITES / ${siteData?.unitName}`}
          // backNavPath={`/sites`}
          // withBackButton={true}
          // extraConstPayload={getExtraConstPayload(idData)}
          minHeight="400px"
          // onChange={(e) => {
          //   if (e?.routeId) {
          //     setIdData(() => {
          //       const chooseRoute = routeData?.find(
          //         (item) => item.id == e?.routeId
          //       );
          //       return {
          //         clientId: chooseRoute?.plant?.clientId,
          //         plantId: chooseRoute?.plant?.id,
          //       };
          //     });
          //   }
          // }}
          // option={{
          //   packagingTypeId: pacakgeData
          //     ? Object?.values(
          //         pacakgeData
          //           ?.filter((item) => item?.type == "Reusable")
          //           ?.reduce((acc, data) => {
          //             const { DropdownName, id } = data;
          //             if (!acc[DropdownName]) {
          //               acc[DropdownName] = { label: DropdownName, value: id };
          //             }
          //             return acc;
          //           }, {})
          //       )
          //     : {},
          //   routeId: routeData
          //     ? routeData
          //         ?.filter((item) => {
          //           return (
          //             item?.routeType == "Forward" &&
          //             (item?.plantId?.toString() == SiteID?.toString() ||
          //               item?.destinationPlantId?.toString() ===
          //                 SiteID?.toString())
          //           );
          //         })
          //         ?.map((res, data) => {
          //           return { label: res?.routeName, value: res?.id };
          //           // const { SubType, id } = data;
          //           // if (!acc[SubType]) {
          //           //   acc[SubType] = { label: SubType, value: id };
          //           // }
          //           // return acc;
          //         }, {})
          //     : {},
          // }}
          setRefresh={setRefresh}
        />
        <div className="custom-Table mt-4">
          <Table
            title="Packaging asset list"
            data={{
              columns: tableHeader,
              rows: pacakegingData,
            }}
            // exportHeader={exportHeaders}
            // exportValue={ExportValues}
            // excelName={excelName}
          />
        </div>
      </div>

      <PackagingLocationDetail
        siteId={SiteID}
        packageId={viewAction?.packageId}
        packageName={viewAction?.packageName}
        onClose={onCloseModal}
      />
    </>
  );
};

export default SitePackageInfo;
