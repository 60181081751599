import React, { useEffect } from "react";
import VerticalLayout from "../VerticalLayout";
import {
  getCustomerStart,
  getDriverStart,
  getPlantStart,
  getRouteStart,
  getShipmentStart,
  getTransporterStart,
  getVehicleStart,
  getPackagingStart,
  getRoleAndAccessStart,
  getJobBulkStart,
  getUsersDetailSuccess,
} from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import useHttp from "../Hook/Use-http";
import CONSTANT, { MyData } from "../../pages/Utility/constnt";
import { getItem, menuGen, notifications } from "../../pages/Utility/function";
import HorizontalLayout from "../HorizontalLayout";
import useCheckInternet from "../Hook/use-checkInternet";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../pages/Utility/Route";
import authStorage from "../../pages/Utility/API/authStroge";

const AdminLayout = () => {
  useCheckInternet("/noInternet");
  const API_CALL = useHttp();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPath = location.pathname;

  const navigate = useNavigate();
  const myData = useSelector((state) => state.userGetMeData);
  let userRole = localStorage.getItem("userRole");

  useEffect(() => {
    const isLogin =
      authStorage?.getAuthToken() !== undefined &&
      authStorage?.getAuthToken() !== null;
    if (!isLogin || !userRole) {
      notifications.error("Session Expired!");
      return navigate("/login");
    }
    if (
      (!currentPath.includes("client") && userRole == "client") ||
      (!currentPath.includes("siteViewer") && userRole == "siteViewer")
    )
      navigate(ROUTES.PAGES403);
    (async () => {
      if (!currentPath.includes("data-not-found")) {
        if (userRole != "admin") return navigate(ROUTES.PAGES403);

        if (userRole === "admin") {
          API_CALL.sendRequest(
            CONSTANT.API.ADMIN.getMe,
            getMeAdminDataHandler,
            null,
            null,
            (err) => {}
          );
        }
      }
    })();
  }, []);

  const startReduceData = () => {
    dispatch(getVehicleStart());
    dispatch(getTransporterStart());
    dispatch(getCustomerStart());
    dispatch(getDriverStart());
    dispatch(getShipmentStart());
    dispatch(getRoleAndAccessStart());
    dispatch(getRouteStart());
    dispatch(getPlantStart());

    dispatch(getPackagingStart());
  };

  const getMeAdminDataHandler = (res) => {
    dispatch(getUsersDetailSuccess(res?.data));
    MyData.data = res?.data;
    startReduceData();
  };

  return (
    <>
      {/* <VerticalLayout SideBarMenu={menuGen("admin")} /> */}
      <HorizontalLayout SideBarMenu={menuGen("admin")} />
    </>
  );
};

export default AdminLayout;
