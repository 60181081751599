import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Button, Col, Modal } from "reactstrap";
import useHttp from "../Hook/Use-http";
import Table from "./table";
import { getTableData } from "../../pages/Utility/constnt";
import CustomModalMain from "./CustomModalMain";
import { DetailsCard, isIdExists } from "../../pages/Utility/function";
import PageTitle from "../pageTitle";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader";
import {
  addCustomer,
  addCustomerStart,
  deleteCustomerStart,
  getCustomerStart,
  updateCustomerStart,
} from "../../store/actions";
import { Form } from "antd";
import AntdForm from "./antdForm";
import { useParams } from "react-router";

// const CustomPage = ({
// GetAPI = null,
// DeleteAPI = null,
// UpdateAPI = null,
// CreateAPI = null,

const defaultDataFunction = (res) => res;
const defaultOnChange = () => {};

const CustomPage = ({
  editClick = null,
  setEditClick = null,
  val = null,
  setVal = null,
  API = {},
  PAGE = {
    title: "CUSTOMER",
    dataFunction: (res) => res,
    withBackButton: false,
    pageRefresh: null,
  },
  TABLE = {},
  FORM = {
    selectedValue: true,
    prepareUpdatePayload: (res) => res,
    editDataPayload: (res) => res,
  },
  REDUX = {
    getDataRedux: null,
    getApiDataRedux: () => {
      return null;
    },
    deleteApiDataRedux: () => {
      return null;
    },
    getMeDetail: null,
  },
  //for edit default or InputField
  editDefaultorInput = false,
  extrakeyForedit = null,

  extraValue = null,
  GetSingle = null,
  formData = false,

  // customValueChange = null,
  accessTeam = null,
  minHeight = "auto",

  setRefresh,
  modalType = "formModal",
  // withBackButton = false,
  backNavPath = "/",
  noHeader = true,
  isOnEditJson = false,
  changeInputPayload = null,
  setChangeData = () => {},
  // getApiDataRedux = () => {},
  // addApiDataRedux = () => {},
  // updateApiDataRedux = () => {},
  // deleteApiDataRedux = () => null,
  getMeDetail = null,
  //redux
  // getDataRedux = {},
  getAllDataRedux = [],
  connectionSent = false,
  connectionRecieved = false,
}) => {
  const [showModel, setShowModel] = useState(false);
  const [itemData, setItemData] = useState([]);
  const [actionData, setActionData] = useState(null);
  const [clearFormData, setClearFormData] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [flag, setFlag] = useState(true);

  const [inputData, setInputData] = useState({});

  // Form Variables and Default Value
  const OptionalOnEdit = FORM?.optionalFieldOnEdit || [];
  const isSubmitButtonVisible = FORM?.isSubmitButtonVisible || true;
  const extraConstPayload = FORM?.extraConstPayload || [];
  const option = FORM?.option || {};
  const onChange = FORM?.onChange || defaultOnChange;
  const deleteKeyOnEdit = FORM?.deleteKeyOnEdit || [];
  const removeFieldOnEdit = FORM?.removeFieldOnEdit || [];

  // Page Variables and Default Value
  const dataFunction = PAGE?.dataFunction || defaultDataFunction;
  const editDataPayload = FORM?.editDataPayload;

  // Table Variables and Default Value
  const TableHeader = TABLE?.header;

  // viewModel Variables and Default Value
  let viewModelData = FORM?.viewModel?.viewModelData || {};
  const viewModelImage = FORM?.viewModel?.viewModelImage || "";
  const cardVisibleOn = FORM?.viewModel?.cardVisibleOn || "";
  const viewModelLoading = FORM?.viewModel?.viewModelLoading || false;
  const viewModelDetails = FORM?.viewModel?.viewModelDetails || [];
  const errorMessage = FORM?.viewModel?.errorMessage || null;
  const onVerifyHandler = FORM?.viewModel?.onVerifyHandler || defaultOnChange;
  const [form] = Form.useForm();
  const { id } = useParams();
  const myData = useSelector((state) => state.userGetMeData);
  const dispatch = useDispatch();
  const API_CALL = useHttp();
  const [rowId, setRowId] = useState();
  const [clientId, setClientId] = useState();
  useEffect(() => {
    let len = Object.keys(viewModelData).length;
    if (editClick == false && len >= 2) {
      if (actionData?.id) {
        setRowId(actionData?.id);
      }
      if (actionData?.clientId) {
        setClientId(actionData?.clientId);
      }
      setActionData(viewModelData);
    }
  }, [editClick, viewModelData]);
  const Operation = {
    setDeleteData: setDeleteData,
    editRecord: (Data) => {
      deleteKeyOnEdit.map((item) => {
        if (Data[item]) delete Data[item];
      });
      setClearFormData((prv) => !prv);
      if (setVal) {
        setVal(true);
      }
      if (editClick == false) {
        setEditClick(true);
      }
      setActionData(Data);
      FORM?.editDataPayload && editDataPayload(Data);
    },
  };

  useEffect(() => {
    setFlag((pr) => !pr);
  }, [PAGE?.pageRefresh?.flag]);

  useEffect(() => {
    if (REDUX?.getDataRedux == null) {
      if (API?.get || GetSingle) {
        const URL = { ...API?.get };

        if (GetSingle) {
          URL.endpoint = URL?.endpoint + GetSingle;
        } else {
          if (Array.isArray(URL)) {
            API_CALL.getAll(URL, (res) => {
              setItemData(dataFunction(res, Operation));
            });
          } else {
            API_CALL.sendRequest(URL, (res) => {
              setItemData(dataFunction(res, Operation));
            });
          }
        }
      }
    }
  }, [flag, myData]);

  useEffect(() => {
    if (REDUX?.getDataRedux != null) {
      setTimeout(() => {
        if (Object?.keys(REDUX?.getDataRedux)?.length > 0) {
          setItemData(dataFunction(REDUX?.getDataRedux, Operation));
        }
      }, 100);
    }
  }, [REDUX?.getDataRedux]);

  const onDelete = () => {
    const URL = { ...API?.delete };
    const [path, query] = URL?.endpoint?.split("?");
    const URLParams = path?.split("/:");
    URLParams.map((URLParam) => {
      if (URLParam in deleteData) {
        URL.endpoint = URL?.endpoint?.replace(
          `:${URLParam}`,
          deleteData[URLParam]
        );
      }
    });
    if (query) {
      const queryParams = query.split("&"); // Split in case there are multiple query params
      queryParams.map((param) => {
        const [key, value] = param.split("=:");

        // Handle clientId case with connectionFromId or connectionToId
        if (key === "clientId") {
          if (
            "connectionFromId" in deleteData &&
            value === "connectionFromId"
          ) {
            // Replace connectionFromId in the query string
            URL.endpoint = URL?.endpoint?.replace(
              `:${value}`,
              deleteData["connectionFromId"]
            );
          } else if (
            "connectionToId" in deleteData &&
            value === "connectionToId"
          ) {
            // Replace connectionToId in the query string
            URL.endpoint = URL?.endpoint?.replace(
              `:${value}`,
              deleteData["connectionToId"]
            );
          }
        }
      });
    }

    if (
      // REDUX?.deleteApiDataRedux != null ||
      REDUX?.deleteApiDataRedux() != null
    ) {
      dispatch(
        REDUX?.deleteApiDataRedux(deleteData?.id, () => {
          setDeleteData(null);
          setFlag((previous) => !previous);
        })
      );
    } else {
      API_CALL.sendRequest(
        URL,
        () => {
          setItemData((previous) =>
            previous.filter((item) => item?.id !== deleteData?.id)
          );
          setDeleteData(null);
        },
        null,
        "Deleted Successfully"
      );
    }

    // const URL = { ...DeleteAPI };
    // URL.endpoint = URL.endpoint.replace(":id", deleteData?.id);
    // API_CALL.sendRequest(
    //   URL,
    //   () => {
    //     setFlag((previous) => !previous);
    //     setDeleteData(null);
    //     Object?.keys(getDataRedux)?.length > 0 && dispatch(REDUX?.getApiDataRedux());
    //   },
    //   null,
    //   "Delete Successfully"
    // );
  };
  const onSubmitForm = (payload) => {
    let formDataObj = {};
    payload.forEach((value, key) => (formDataObj[key] = value));
    if (extraConstPayload?.length) {
      extraConstPayload?.map((item) => {
        if ("condition" in item) {
          if (item?.condition) {
            payload.append(item.id, item.value);
            formDataObj = { ...formDataObj, [item.id]: item.value };
          }
        } else if (item.value) {
          payload.append(item.id, item.value);
          formDataObj = { ...formDataObj, [item.id]: item.value };
        }
      });
    }
    if (FORM?.selectedValue == false) {
      formDataObj = FORM?.prepareUpdatePayload(actionData, formDataObj);
      delete formDataObj?.id;
      delete formDataObj?.createdAt;
      delete formDataObj?.deletedAt;
      delete formDataObj?.updatedAt;

      Object.keys(formDataObj).map((key) => {
        if (payload.has(key)) {
          payload.delete(key);
          formDataObj[key] && payload.append(key, formDataObj[key]);
        } else {
          formDataObj[key] && payload.append(key, formDataObj[key]);
        }
      });
    }

    // if (extraValue !== null) {
    //   payload.append("plantId", extraValue[0]?.id);
    // }

    // if (customValueChange) {
    //   let newobj = {};
    //   newobj = { ...actionData };
    //   delete newobj?.id;
    //   delete newobj?.createdAt;
    //   delete newobj?.deletedAt;
    //   delete newobj?.updatedAt;

    //   formDataObj = { ...newobj, ...formDataObj };

    //   // if (formDataObj.DimensionInmm) {
    //   formDataObj.VolumeCBM = parseInt(formDataObj.DimensionInmm) / 1000000000;
    //   formDataObj.VolumetricWeightInKG = formDataObj.VolumeCBM * 350;
    //   // }

    //   // if (formDataObj.ActualWeight && formDataObj.co2EmissionPerKG) {
    //   formDataObj.lifeTimetCO2e =
    //     (formDataObj.ActualWeight * formDataObj.co2EmissionPerKG) / 1000;
    //   // }

    //   // if (formDataObj.lifeTimetCO2e) {
    //   formDataObj.type === "Reusable"
    //     ? (formDataObj.lifeTimeTrips = "150")
    //     : (formDataObj.lifeTimeTrips = "1");
    //   formDataObj.tCO2PerShipment =
    //     formDataObj.lifeTimetCO2e / formDataObj.lifeTimeTrips;
    //   // }

    //   // if (formDataObj.SubType && formDataObj.DimensionInmm) {
    //   formDataObj.DropdownName = `${formDataObj.SubType}-${formDataObj.DimensionInmm}`;
    //   // }
    // }

    // if (accessTeam == "roleAccess") {
    //   if (formDataObj?.role === "client") {
    //     payload.delete("plantId");
    //   }
    //   if (formDataObj?.role === "siteViewer") {
    //     payload.delete("clientId");
    //   }
    // }

    // const ff = {};
    // payload.forEach((value, key) => (ff[key] = value));
    // formDataObj["mobile"] = formDataObj?.mobile?.toString();

    if (actionData?.id || rowId) {
      const URL = { ...API?.update };
      if (actionData?.id) {
        URL.endpoint = URL.endpoint.replace(":id", actionData?.id);
      } else {
        URL.endpoint = URL.endpoint.replace(":id", rowId);
      }
      API_CALL.sendRequest(
        URL,
        () => {
          // debugger;

          // if (REDUX?.getMeDetail != null) {
          //   REDUX?.getDataRedux &&
          //     Object?.keys(REDUX?.getDataRedux)?.length > 0 &&
          //     dispatch(REDUX?.getApiDataRedux(id));
          // } else {
          REDUX?.getDataRedux &&
            Object?.keys(REDUX?.getDataRedux)?.length > 0 &&
            dispatch(REDUX?.getApiDataRedux);
          // }

          setFlag((previous) => !previous);
          setActionData(null);
          FORM?.viewModel?.setVerifyData(null);
        },
        formData ? (isOnEditJson ? formDataObj : payload) : formDataObj,
        "Data updated successfully"
      );
      // }
      // dispatch(
      //   updateApiDataRedux(
      //     formData ? (isOnEditJson ? formDataObj : payload) : formDataObj,
      //     actionData?.id,
      //     () => {
      //       setShowModel(false);
      //       setActionData(null);
      //     }
      //   )
      // );
    } else {
      // dispatch(
      //   addApiDataRedux(
      //     formData ? (isOnEditJson ? formDataObj : payload) : formDataObj,
      //     () => {
      //       setShowModel(false);
      //       setActionData(null);
      //     }
      //   )
      // );

      API_CALL.sendRequest(
        API?.create,
        () => {
          // if (REDUX?.getMeDetail != null) {
          //   REDUX?.getDataRedux &&
          //     Object?.keys(REDUX?.getDataRedux)?.length > 0 &&
          //     dispatch(REDUX?.getApiDataRedux(REDUX?.getMeDetail?.clientId));
          // } else {
          REDUX?.getDataRedux &&
            Object?.keys(REDUX?.getDataRedux)?.length > 0 &&
            dispatch(REDUX?.getApiDataRedux);
          // }
          // Object?.keys(REDUX?.getDataRedux)?.length > 0 && dispatch(REDUX?.getApiDataRedux);
          setFlag((previous) => !previous);
          setRefresh && setRefresh((previous) => !previous);
          setShowModel(false);
          FORM?.viewModel?.setVerifyData(null);
        },
        formData ? (isOnEditJson ? formDataObj : payload) : formDataObj,
        "Data added successfully"
      );
    }
  };

  return (
    <React.Fragment>
      {noHeader && (
        <PageTitle
          pageHeading={PAGE?.title}
          backNavPath={backNavPath}
          withBackButton={PAGE?.withBackButton}
        />
      )}
      {!API_CALL?.isLoading && API?.create && (
        <Button
          color="primary"
          className="btn btn-primary waves-effect waves-light "
          style={{
            zIndex: "50",
          }}
          onClick={() => {
            setClearFormData((prv) => !prv);
            setShowModel(true);
            setActionData(null);
            setChangeData({});
          }}
        >
          {/* <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> */}
          {FORM?.addNewRecordCtaText || "Add New Record"}
        </Button>
      )}

      {API_CALL?.isLoading ? (
        <Loader />
      ) : (
        TableHeader && (
          <div
            className=" mt-4 pt-0 scrollSticky custom-Table"
            style={{
              paddingTop: "0",
              paddingBottom: "0",
            }}
          >
            <Table
              title={TABLE?.title || "List"}
              isLoading={API_CALL?.isLoading}
              data={getTableData(TableHeader, itemData)}
              exportHeader={TABLE?.export?.header}
              excelName={TABLE?.export?.fileName || []}
            />
          </div>
        )
      )}

      {(showModel || actionData != null) &&
      FORM?.formField &&
      modalType == "formModal" ? (
        <CustomModalMain
          modalType={modalType}
          show={showModel || actionData != null}
          close={() => {
            setShowModel(false);
            setActionData(null);
            setDeleteData(null);
            if (editClick) {
              setEditClick(false);
            }
            FORM?.viewModel?.setVerifyData(null);
            setChangeData({});
          }}
          onChange={onChange}
          onSingleChange={FORM?.onSingleChange}
          minHeight={minHeight}
          isAutoClose={false}
          // extraConstPayload={[]}
          accessTeam={accessTeam}
          onVerifyHandler={onVerifyHandler}
          changeInputPayload={changeInputPayload}
          viewModel={
            FORM?.viewModel ? (
              <DetailsCard
                viewModelImage={
                  actionData != null ? actionData?.image : viewModelImage
                }
                errorMessage={errorMessage}
                idBase64={actionData == null}
                data={actionData == null ? viewModelData : actionData}
                isCardVisible={
                  actionData != null ||
                  (viewModelData != null
                    ? cardVisibleOn in viewModelData
                      ? true
                      : false
                    : false)
                }
                isCardLoading={viewModelLoading}
                details={viewModelDetails}
              />
            ) : (
              <></>
            )
          }
          extraValues={extraValue}
          modalTitle={
            actionData != null
              ? `${FORM?.editFormTitle || "Edit"}`
              : `${FORM?.addFormTitle || "Add"}`
          }
          editAllPayloadSubmit={FORM?.editAllPayloadSubmit}
          onSubmit={(data) => {
            onSubmitForm(data);
            setActionData(null);
          }}
          data={FORM?.formField
            ?.filter(
              (item) =>
                !isIdExists(extraConstPayload, item?.name) &&
                (actionData == null || !removeFieldOnEdit.includes(item?.name))
            )
            ?.map((item) => {
              if (actionData !== null && OptionalOnEdit.includes(item.name)) {
                item.required = false;
              }
              if (actionData == null && OptionalOnEdit.includes(item.name)) {
                item.required = true;
              }

              return {
                ...item,
              };
            })}
          isSubmitButtonVisible={actionData != null || isSubmitButtonVisible}
          defaultData={actionData}
          setDefaultData={setActionData}
          formData={true}
          isEdit={actionData != null}
          option={option}
          cleanData={clearFormData}
          extrakeyForedit={extrakeyForedit}
          editDefaultorInput={editDefaultorInput}
        />
      ) : (
        <>
          <Col sm={6} md={4} xl={4}>
            <Modal
              isOpen={showModel || actionData != null}
              style={{
                minHeight: minHeight,
                maxHeight: `${550}px`,
                overflowY: "scroll",
                overflowX: `${"hidden"}`,
              }}
            >
              <div className="modal-body">
                <h5>
                  {actionData != null
                    ? `${FORM?.editFormTitle || "Edit"}`
                    : `${FORM?.addFormTitle || "Add"}`}
                </h5>
              </div>
              <div>
                <AntdForm
                  formFields={FORM?.formField
                    ?.filter(
                      (item) =>
                        !isIdExists(extraConstPayload, item?.name) &&
                        (actionData == null ||
                          !removeFieldOnEdit.includes(item?.name))
                    )
                    ?.map((item) => {
                      if (
                        actionData !== null &&
                        OptionalOnEdit.includes(item.name)
                      ) {
                        item.required = false;
                      }
                      if (
                        actionData == null &&
                        OptionalOnEdit.includes(item.name)
                      ) {
                        item.required = true;
                      }

                      return {
                        ...item,
                      };
                    })}
                  form={form}
                  formData={inputData}
                  dynamicOption={option}
                  changedFields={(key, value) => {
                    // onChange((prev) => ({ ...prev, [key]: value }));
                    setInputData((prev) => ({ ...prev, [key]: value }));
                  }}
                  onSubmit={(data) => {
                    onSubmitForm(data);
                  }}
                  close={() => {
                    setShowModel(false);
                    setActionData(null);
                    setDeleteData(null);
                    FORM?.viewModel?.setVerifyData(null);
                    setChangeData({});
                  }}
                />
              </div>
            </Modal>
          </Col>
        </>
      )}
      {deleteData != null ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            onDelete();
          }}
          onCancel={() => {
            setDeleteData(null);
          }}
        >
          {connectionSent
            ? "You are about to remove your connection with Partner. Data sharing will be impacted. Do you wish to continue?"
            : connectionRecieved
            ? "You are about to remove your connection with Customer. Data sharing will be impacted. Do you wish to continue?"
            : "You won't be able to revert this!"}
        </SweetAlert>
      ) : null}
    </React.Fragment>
  );
};

CustomPage.defaultProps = {
  API: {},
  PAGE: {
    title: "CUSTOMER",
    dataFunction: (res) => res,
    withBackButton: false,
  },
  TABLE: {},
  FORM: {
    prepareUpdatePayload: (res) => res,
    editDataPayload: (res) => res,
  },
  REDUX: {
    getDataRedux: null,
    getApiDataRedux: () => null,
    deleteApiDataRedux: () => null,
    getMeDetail: null,
  },
  extraValue: null,
  GetSingle: null,
  formData: false,

  // customValueChange: null,
  accessTeam: null,
  minHeight: "auto",

  setRefresh: () => {},
  // withBackButton :false,
  backNavPath: "/",
  noHeader: true,
  isOnEditJson: false,
  changeInputPayload: null,
  setChangeData: () => {},
  getApiDataRedux: () => {},
  addApiDataRedux: () => {},
  updateApiDataRedux: () => {},
  deleteApiDataRedux: () => null,

  //redux
  // getDataRedux :{},
  getAllDataRedux: [],
};
export default CustomPage;
