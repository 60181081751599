import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import PageTitle from "../../components/pageTitle";
import CONSTANT, { TAB_DATA, tab } from "../Utility/constnt";
import classnames from "classnames";

import useHttp from "../../components/Hook/Use-http";
import React, { useEffect, useState } from "react";

import BulkUpload from "./bulkUpload";
import SingleUpload from "./singleUpload";

const AddDataEntry = () => {
  // let clientId = window.location.pathname.split("/")[2];

  const [activeTab, setactiveTab] = useState(tab?.tabActiveId);

  const [subCategory, setSubCategory] = useState([]);

  const API_CALL = useHttp();
  const toggleCustomJustified = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  useEffect(() => {
    API_CALL.sendRequest(CONSTANT.API.SUBCATEGORY.get, (res) => {
      setSubCategory(res?.data?.rows?.filter((el) => el?.isVisible == true));
    });
  }, []);

  return (
    <React.Fragment>
      <div className="page-content ">
        <PageTitle pageHeading={`EMISSIONS`} />

        <Row>
          <Col sm={12} className="">
            <div className="page-content mt-0 pt-0 scrollSticky">
              <Nav tabs className="nav-tabs-custom nav-justified">
                {TAB_DATA.EMISSION_ADD_TAB.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="mt-2"
                      style={{ width: "100px" }}
                    >
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab === data.tabId,
                            activeTabDesign: activeTab === data.tabId,
                            TabDesign: true,
                          })}
                          onClick={() => {
                            tab.tabActiveId = data?.tabId;
                            toggleCustomJustified(data.tabId);
                          }}
                        >
                          <span className="d-sm-block">{data.name}</span>
                        </NavLink>
                      </NavItem>
                    </div>
                  );
                })}
              </Nav>
              <TabContent activeTab={activeTab} className="mt-3 p-0 TabBody">
                <TabPane tabId={1} className="py-3">
                  <SingleUpload
                    extraValue={{
                      subCategory: subCategory,
                      activeTab: activeTab,
                    }}
                  />
                </TabPane>
                <TabPane tabId={2} className="py-3 px-0">
                  <BulkUpload subCategory={subCategory} />
                </TabPane>
              </TabContent>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default AddDataEntry;
