import {
  GET_PACKAGING_START,
  GET_PACKAGING_SUCCESS,
  GET_PACKAGING_FAILURE,
  DELETE_PACKAGING_START,
  DELETE_PACKAGING_SUCCESS,
  DELETE_PACKAGING_FAILURE,
} from "./actionTypes";

// export const getMyProfile = () => ({
//   type: GET_MY_PROFILE,
// });

// export const getPackagingDetailSuccess = (users) => {
//   return {
//     type: PACKAGING_DETAIL,
//   };
//   // return {
//   //   type: GET_PACKAGING_DETAIL_SUCCESS,
//   //   payload: users,
//   // };
// };

//GET
export const getPackagingStart = () => {
  return {
    type: GET_PACKAGING_START,
  };
};
export const getPackagingSuccess = (users) => {
  return {
    type: GET_PACKAGING_SUCCESS,
    payload: users,
  };
};
export const getPackagingError = (error) => {
  return {
    type: GET_PACKAGING_FAILURE,
    payload: error,
  };
};

//DELETE
export const deletePackagingStart = (id, successCallback) => ({
  type: DELETE_PACKAGING_START,
  payload: id,
  successCallback,
});

export const deletePackagingSucess = (id) => ({
  type: DELETE_PACKAGING_SUCCESS,
  payload: id,
});

export const deletePackagingError = (error) => ({
  type: DELETE_PACKAGING_FAILURE,
  payload: error,
});
