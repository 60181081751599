import React from "react";
import { Bar, Doughnut, Pie } from "react-chartjs-2";

const DoughnutChart = (props) => {
  return (
    <React.Fragment>
      <Doughnut
        redraw={true}
        options={{
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                const datasetIndex = tooltipItem.datasetIndex;
                const index = tooltipItem.index;
                return data.datasets[datasetIndex].labels[index];
              },
            },
          },
          // legend: {
          //   display: true,
          //   position: "bottom",
          //   labels: {
          //     generateLabels: function (chart) {
          //       const { datasets } = chart.data;
          //       const legendLabels = [];
          //       datasets.forEach((dataset, datasetIndex) => {
          //         dataset.data.forEach((_, index) => {
          //           console.log(dataset);
          //           legendLabels.push({
          //             text: `${dataset.labels[index]} (${dataset.data[index]} tCo2e)`,
          //             fillStyle: dataset.backgroundColor[index],
          //             hidden: false,
          //           });
          //         });
          //       });
          //       return legendLabels;
          //     },
          //   },
          // },
        }}
        data={{
          datasets: [
            {
              data: props?.chartData[1]?.map((el) => el.value), // Change these values as needed
              backgroundColor: props?.colorOutside,
              pointLabels: {
                display: false, 
              },
              datalabels: {
                display: false,
              },
              // hoverBackgroundColor: [
              //   "rgba(255, 206, 86, 0.8)",
              //   "rgba(75, 192, 192, 0.8)",
              // ],
              labels: props?.chartData[1]?.map((el) => el.label),
            },
            {
              data: props?.chartData[0]?.map((el) => el.value),
              backgroundColor: props?.colorInner,
              // hoverBackgroundColor: [
              //   "rgba(255, 99, 132, 0.8)",
              //   "rgba(54, 162, 235, 0.8)",
              // ],
              labels: props?.chartData[0]?.map((el) => el.label),
              weight: 2,
              pointLabels: {
                display: false, 
              },
              datalabels: {
                display: false, 
              },
            },
          ],
        }}
      />
    </React.Fragment>
  );
};

export default DoughnutChart;
