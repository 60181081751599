import React, { useEffect, useState } from "react";
import CustomPage from "../../../Custome/CustomPage";
import {
  emissionEntryCategoryWiseDataLoad,
  emissionForEditFormDataCallLoad,
  jobViewDataMask,
} from "../../../../pages/Utility/spread-function";
import CONSTANT, {
  getTableColumConfig,
} from "../../../../pages/Utility/constnt";
import {
  getRecordId,
  optionArrayWithSort,
  replaceId,
  tableHeaderToExportHeader,
} from "../../../../pages/Utility/function";
import { useLocation, useParams } from "react-router-dom";
import PcfServices from "../../../../pages/Utility/API/pcfService";
import useHttp from "../../../Hook/Use-http";

const PurchasedGoodAndServiceView = (props) => {
  const {
    jobId,
    jobSingleData,
    API,
    initHeader,
    setJobViewData,
    onViewReason,
    responceStatus,
    flag,
  } = props;
  const [subCategoryModal, setSubCategoryModal] = useState([]);
  const [subHeader, setHeader] = useState([]);
  const [dynamicOption, setDynamicOption] = useState({});
  const [editData, setEditData] = useState({});
  const [formData, setFormData] = useState({});
  // const [submitformData, setSubmitFormData] = useState({});
  const [newFormData, setNewFormData] = useState({});
  const [alldynamicOption, setAllDynamicOption] = useState({});
  // const [purchasedCategoryData, setPurchasedCategoryData] = useState([]);
  // const [purchasedSubCategory, setPurchasedSubCategory] = useState([]);
  // const [purchasedRawMateerialType, setPurchasedRawMateerialType] = useState(
  //   []
  // );
  const [isFirstEditFlag, setIsFirstEditFlag] = useState(false);
  const API_CALL = useHttp();
  // const [defaultData, setDefaultData] = useState({});
  const { id } = useParams();
  const userRole = localStorage.getItem("userRole");
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    jobSingleData &&
      API?.getAll(
        [
          CONSTANT?.API.SUBCATEGORYATTRIBUTE.get.endpoint,
          CONSTANT?.API?.ATTRIBUTEOPTION.get?.endpoint,
          CONSTANT?.API.SITE.get?.endpoint,
          CONSTANT?.API.JOB.getPurchasedGoodServiceCategory?.endpoint,
          //PRODUCT CATEGORY API & PRODUCT SUBCATEGORY & PRODUCT RAW  TYPE===================================
          //   CONSTANT?.API.JOB.getWasteUsed?.endpoint,
        ],
        (res) => {
          let categoryOption = {};
          const subCategoryAttributeOptionRes = res[1]?.data?.data;
          const sites =
            userRole == "admin" && currentPath.includes("/client") && id
              ? res[2]?.data?.data?.rows?.filter((el) => el?.clientId == id)
              : res[2]?.data?.data?.rows;
          const Other = {
            site: sites,
            purchasedcategory: res[3]?.data?.data,
          };
          //API CALL ENABLE THEN UNCOMMENT BELOW===========================
          // setPurchasedCategoryData(res[3]?.data?.data);
          //   setPurchasedSubCategory(res[4]?.data?.data?.rows);
          //   setPurchasedRawMateerialType(res[5]?.data?.data?.rows);

          const rearrangedData = res[0]?.data?.data?.rows
            ?.filter((it) => it?.subCategoryId == jobSingleData?.subCategoryId)
            ?.sort((a, b) => +a.index - +b.index);

          let arrHeader = [...initHeader];

          const dddd = emissionEntryCategoryWiseDataLoad(
            subCategoryAttributeOptionRes,
            Other,
            rearrangedData,
            setDynamicOption,
            arrHeader
          );

          setSubCategoryModal(dddd?.arr);
          setAllDynamicOption((prev) => ({
            ...prev,
            ...categoryOption,
          }));
          setHeader((pr) => {
            return [...dddd?.arrHeader, getTableColumConfig("action")];
          });
        }
      );
  }, [jobSingleData]);

  useEffect(() => {
    emissionForEditFormDataCallLoad(
      subCategoryModal,
      setDynamicOption,
      setSubCategoryModal,
      formData,
      alldynamicOption,
      editData
    );
  }, [formData, editData]);

  //For Edit option Set
  useEffect(() => {
    if (!isFirstEditFlag) return;
    if (Object.keys(editData)?.length == 0) return;
    if (!formData?.selectedValue && !formData?.data) return;
    const arr = [];
    const keyOnly = subCategoryModal?.filter(
      (el) => el?.extraValue?.depenededFieldId
    );
    keyOnly?.map((el) => arr?.push(el?.extraValue?.depenededFieldId));

    if (
      !!formData?.selectedValue?.[arr[0]] &&
      formData?.selectedValue?.[arr[0]]?.id
    ) {
      const purchsedCategoryId = formData?.selectedValue?.[arr[0]]?.id;
      if (
        (formData?.selectedValue?.[keyOnly[0]?.id] == undefined ||
          formData?.selectedValue?.[keyOnly[0]?.id] == null ||
          Object.keys(formData?.selectedValue?.[keyOnly[0]?.id])?.length ==
            0) &&
        (formData?.selectedValue?.[keyOnly[1]?.id] == undefined ||
          formData?.selectedValue?.[keyOnly[1]?.id] == null ||
          Object.keys(formData?.selectedValue?.[keyOnly[1]?.id])?.length == 0)
      ) {
        API.getAll(
          [
            replaceId(
              CONSTANT.API.JOB.getPurchasedGoodServiceSubCategory,
              purchsedCategoryId
            ).endpoint,
          ],
          (res) => {
            const purchsedSubCategoryId = res[0]?.data?.data?.find(
              (el) => el?.name == editData?.[keyOnly?.[0]?.id]
            )?.id;

            purchsedSubCategoryId &&
              API.getAll(
                [
                  replaceId(
                    CONSTANT.API.JOB.getPurchasedGoodServiceRawMaterialType,
                    purchsedSubCategoryId
                  ).endpoint,
                ],
                (resChid) => {
                  setDynamicOption((prev) => {
                    //API SET SUBCATEGORY====================
                    return {
                      ...prev,
                      [keyOnly?.[0]?.id]: optionArrayWithSort(
                        res[0]?.data?.data,
                        "name",
                        "name"
                      ), //subCategory list
                      [keyOnly?.[1]?.id]: optionArrayWithSort(
                        resChid[0]?.data?.data,
                        "name",
                        "name",
                        "unit"
                      ), //raw MAterial Type list
                    };
                  });

                  setSubCategoryModal((prev) => {
                    const newValue = prev?.map((it) => {
                      if (
                        it?.extraValue?.labelChangeDependedFieldId &&
                        formData?.selectedValue
                      ) {
                        const labelDependendId = Object?.keys(
                          formData?.selectedValue
                        )?.find(
                          (el) =>
                            +el == +it?.extraValue?.labelChangeDependedFieldId
                        );
                        const quatityUnit = resChid[0]?.data?.data?.find(
                          (el) => el?.name == editData?.[labelDependendId]
                        )?.unit;

                        if (!!quatityUnit) {
                          it.label = (
                            <span>
                              Quantity{" "}
                              {`${quatityUnit ? `(in ${quatityUnit})` : ""}`}
                            </span>
                          );
                        }
                      }
                      return it;
                    });

                    return newValue;
                  });
                  setIsFirstEditFlag(false);
                }
              );
          }
        );
      }
    }
  }, [editData, formData]);

  useEffect(() => {
    if (!formData?.selectedValue && !formData?.data) return;
    const arr = [];
    const keyOnly = subCategoryModal?.filter(
      (el) => el?.extraValue?.depenededFieldId
    );
    keyOnly?.map((el) => arr?.push(el?.extraValue?.depenededFieldId));
    const keyFindId = Object?.keys(newFormData)?.[0];
    const optionlistCheck = Object.keys(formData?.selectedValue)?.find(
      (el) => el == keyFindId
    );
    const purchsedCategoryId = formData?.selectedValue[arr?.[0]]?.id;
    const purchsedSubCategoryId = formData?.selectedValue[arr?.[1]]?.id;
    if (
      newFormData?.[arr?.[0]] &&
      keyFindId == arr?.[0] &&
      optionlistCheck == arr?.[0] &&
      !!purchsedCategoryId
    ) {
      API_CALL.getAll(
        [
          replaceId(
            CONSTANT.API.JOB.getPurchasedGoodServiceSubCategory,
            purchsedCategoryId
          ).endpoint,
        ],
        (res) => {
          delete formData?.data?.[keyOnly?.[0]?.id]; //153 sub-category
          delete formData?.data?.[keyOnly?.[1]?.id]; //154 raw material type
          delete formData?.selectedValue?.[keyOnly?.[0]?.id]; //153 sub-category
          delete formData?.selectedValue?.[keyOnly?.[1]?.id]; //154 raw material type

          formData?.operations?.inputData(formData?.data);
          formData?.operations?.selectedValue(formData?.selectedValue);
          formData?.operations?.setDefaultValue((pr) => {
            delete pr?.[keyOnly?.[1]?.id];
            delete pr?.[keyOnly?.[0]?.id];
            return { ...pr };
          });
          setDynamicOption((prev) => {
            //API SET SUBCATEGORY====================
            return {
              ...prev,
              [keyOnly?.[1]?.id]: [],
              [keyOnly?.[0]?.id]: optionArrayWithSort(
                res[0]?.data?.data,
                "name",
                "name"
              ), //raw MAterial Type list
            };
          });
          setNewFormData({});
        }
      );

      // setSubCategoryModal((pr) => [...pr]);
    } else if (
      newFormData?.[arr?.[1]] &&
      keyFindId == arr?.[1] &&
      optionlistCheck == arr?.[1] &&
      !!purchsedSubCategoryId
    ) {
      API_CALL.getAll(
        [
          replaceId(
            CONSTANT.API.JOB.getPurchasedGoodServiceRawMaterialType,
            purchsedSubCategoryId
          ).endpoint,
        ],
        (res) => {
          delete formData?.data?.[keyOnly?.[1]?.id]; //154 raw material type
          delete formData?.selectedValue?.[keyOnly?.[1]?.id]; //154 raw material type

          formData?.operations?.inputData(formData?.data);
          formData?.operations?.selectedValue(formData?.selectedValue);
          formData?.operations?.setDefaultValue((pr) => {
            delete pr?.[keyOnly?.[1]?.id];
            return { ...pr };
          });
          setDynamicOption((prev) => {
            //API SET RAW MATERIAL TYPE
            return {
              ...prev,
              [keyOnly?.[1]?.id]: optionArrayWithSort(
                res[0]?.data?.data,
                "name",
                "name",
                "unit"
              ), //raw MAterial Type list
            };
          });

          setNewFormData({});
        }
      );
    }
  }, [formData, editData]);
  const prepareUpdatePayload = (editPayload, payload) => {
    let objPayload = {
      data: {
        ...formData?.defaultData,
        ...formData?.data,
      },
    };

    Object?.keys(objPayload?.data)?.map((itm) => {
      objPayload.data[`id${itm}`] = payload[itm] || editPayload[itm];
    });
    delete objPayload?.data?.id;

    return {
      ...objPayload,
    };
  };

  return (
    <>
      <CustomPage
        PAGE={{
          title: `JOB / ${getRecordId?.job(jobId)}`,
          dataFunction: (res, Actions) => {
            setJobViewData(res?.result);
            return jobViewDataMask(res, Actions, onViewReason, responceStatus);
          },
          pageRefresh: { flag },
        }}
        noHeader={false}
        API={{
          get: replaceId(CONSTANT?.API.JOBVIEW.get, jobId),
          update: CONSTANT?.API.JOBVIEW.update,
          delete: CONSTANT?.API.JOBVIEW.delete,
        }}
        FORM={{
          formField: subCategoryModal,

          editFormTitle: "Edit Job",
          option: {
            ...dynamicOption,
          },
          editDataPayload: (editData) => {
            setEditData(editData);
            setIsFirstEditFlag(true);
          },
          prepareUpdatePayload: prepareUpdatePayload,
          onChange: (data, selectedValue, operations, defaultData) => {
            // setEditData(data);

            const keyOnly = subCategoryModal?.find(
              (el) => el?.extraValue?.keyOrEquetion?.[0]
            );

            // setPurchasedSubCategory(data?.[keyOnly?.id]);
            setFormData({ data, selectedValue, operations, defaultData });
          },
          onSingleChange: (data) => {
            setNewFormData(data);
          },

          selectedValue: false,
        }}
        TABLE={{
          title: "Record List",
          header: subHeader,
          export: {
            fileName: `${getRecordId?.job(jobId)}_Record.csv`,
            header: tableHeaderToExportHeader?.header(subHeader),
          },
        }}
      />
    </>
  );
};

export default PurchasedGoodAndServiceView;
