import { Card, CardBody, Col, Label, Row } from "reactstrap";
import PageTitle from "../../components/pageTitle";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import useHttp from "../../components/Hook/Use-http";
import CONSTANT from "../Utility/constnt";

import DoughnutChart from "../../components/doughnutChart";
import { colorCode } from "../Utility/option";
import { emissionDashboardDataLoad } from "../Utility/spread-function";

let colorOutside = [];
let categoryData = {};

const Dashboard = () => {
  const [chartView, setChartView] = useState(<></>);
  const [financialYear, setFinancialYear] = useState("2023-24");
  const [AvailableFinancialYear, setAvailableFinancialYear] = useState([]);

  const [hideScope, setHideScope] = useState({});
  const API = useHttp();
  const colorInner = ["#3CBDC9", "#9FB328", "#F96232"];

  useEffect(() => {
    let analyticsAPIendpoint =
      CONSTANT.API.CATEGORYFORM.getCarbonEmissionAnalysis.endpoint;
    if (financialYear != null) {
      analyticsAPIendpoint += "?value=" + financialYear;
    }
    API.getAll(
      [
        // analyticsAPIendpoint,
        // CONSTANT.API.DASHBOARD.getAnalysis.endpoint,

        // CONSTANT.API.GetAllcategory.endpoint,
        // CONSTANT.API.subCategoryForClient.endpoint,
        analyticsAPIendpoint,
        CONSTANT.API.CATEGORY.get.endpoint,
        CONSTANT.API.SUBCATEGORY.get.endpoint,
      ],
      (res) => {
        emissionDashboardDataLoad(
          res,
          categoryData,
          colorOutside,
          setChartView,
          setAvailableFinancialYear
        );
      }
    );
  }, [financialYear]);

  return (
    <React.Fragment>
      <div className="page-content">
        <PageTitle pageHeading={`DASHBOARD`} />

        <Row>
          <Col sm={4} className="d-none">
            <Card
              style={{
                width: "100%",
              }}
            >
              <CardBody
                style={{ backgroundColor: "#2f4395", color: "white" }}
              ></CardBody>
            </Card>
          </Col>
          <Col sm={12} className="p-0">
            <Row>
              <Col xs="5">
                <Col>
                  <Label style={{ display: "flex", alignItems: "center" }}>
                    Financial year
                  </Label>

                  <Select
                    className="mb-3"
                    options={AvailableFinancialYear}
                    required={true}
                    maxMenuHeight={200}
                    onChange={(selected) => {
                      setFinancialYear(selected?.value);
                    }}
                  />
                  <Card>
                    <CardBody>
                      <div className="d-flex align-items-center mb-4">
                        <div className="avatar-xs font-size-18 me-3">
                          <span className="avatar-title bg-soft-primary text-primary rounded">
                            <i className={"mdi mdi-leaf"}></i>
                          </span>
                        </div>
                        <div className="flex-1">
                          <div className="font-size-18  ">
                            <p
                              style={{
                                fontWeight: "700",
                                color: "#000",
                                padding: "0",
                                margin: "0",
                              }}
                            >
                              Net emissions (FY : {financialYear})
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* <i className=""></i> */}

                      <h5
                        style={{
                          color: "rgb(146 148 164)",
                          fontSize: "25px",
                          marginBottom: "25px",
                          fontWeight: "700",
                          textAlign: "center",
                        }}
                      >
                        {Object.values(categoryData)
                          ?.reduce((totalCategory, item) => {
                            return (
                              +totalCategory +
                              Object.values(item?.data)?.reduce((total, it) => {
                                return +it?.count + total;
                              }, 0)
                            );
                          }, 0)
                          ?.toFixed(2)}{" "}
                        tCO<sub>2</sub>e
                      </h5>

                      {Object.keys(categoryData).length > 0 ? (
                        Object.values(categoryData)?.map((itm, i) => {
                          return (
                            <div>
                              <div
                                key={colorInner[i]}
                                className="mt-3 mb-2 d-flex align-items-center"
                                style={{
                                  gap: "5px",
                                }}
                              >
                                <div
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    border: "1px solid",
                                    borderRadius: "4px",
                                    backgroundColor: `${colorInner[i]}`,
                                  }}
                                ></div>

                                <p
                                  className="font-size-18 m-0 p-0"
                                  style={{
                                    fontWeight: "700",
                                    color: "#000",
                                  }}
                                >
                                  {itm?.name}
                                </p>
                                <p style={{ margin: "0px" }}>
                                  (
                                  {Object.values(itm?.data)
                                    ?.reduce((total, it) => {
                                      return it?.count + total;
                                    }, 0)
                                    ?.toFixed(2)}{" "}
                                  tCO
                                  <sub>2</sub>e)
                                </p>

                                <i
                                  className={`${
                                    hideScope?.[itm?.name]
                                      ? "bx bx-chevron-up"
                                      : "bx bx-chevron-down"
                                  }`}
                                  style={{ fontSize: "20px" }}
                                  onClick={() => {
                                    // setHideScope((pr) => !pr);

                                    setHideScope((pr) => ({
                                      ...pr,
                                      [itm?.name]: !pr?.[itm?.name],
                                    }));
                                  }}
                                ></i>
                              </div>

                              {hideScope?.[itm?.name] &&
                                Object.values(itm?.data)?.map((it, index) => {
                                  return (
                                    <div
                                      key={colorCode[i][index]}
                                      className="mb-2 d-flex align-items-center"
                                      style={{
                                        gap: "5px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          height: "10px",
                                          width: "10px",
                                          border: "1px solid",
                                          borderRadius: "50px",
                                          backgroundColor: `${colorCode[i][index]}`,
                                        }}
                                      ></div>

                                      <p style={{ margin: "0px" }}>
                                        {it?.label} : {it?.count} tCO
                                        <sub>2</sub>e
                                      </p>
                                    </div>
                                  );
                                })}
                            </div>
                          );
                        })
                      ) : (
                        <p
                          className="font-size-18 p-0 m-0"
                          style={{
                            fontWeight: "700",
                            color: "rgb(146 148 164)",
                            textAlign: "center",
                          }}
                        >
                          No carbon emission data found
                        </p>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Col>

              <Col xs="7">{chartView}</Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
