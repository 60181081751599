// import { VEHICLE_DETAIL } from "./actionTypes";

// // export const getMyProfile = () => ({
// //   type: GET_MY_PROFILE,
// // });

// export const getVehicleDetailSuccess = (users) => {
//   return {
//     type: VEHICLE_DETAIL,
//   };
//   // return {
//   //   type: GET_DRIVER_DETAIL_SUCCESS,
//   //   payload: users,
//   // };
// };

import {
  GET_VEHICLE_START,
  GET_VEHICLE_SUCCESS,
  GET_VEHICLE_FAILURE,
  DELETE_VEHICLE_START,
  DELETE_VEHICLE_SUCCESS,
  DELETE_VEHICLE_FAILURE,
} from "./actionTypes";

//GET
export const getVehicleStart = () => {
  return {
    type: GET_VEHICLE_START,
  };
};
export const getVehicleSuccess = (users) => {
  return {
    type: GET_VEHICLE_SUCCESS,
    payload: users,
  };
};
export const getVehicleError = (error) => {
  return {
    type: GET_VEHICLE_FAILURE,
    payload: error,
  };
};

//DELETE
export const deleteVehicleStart = (id, successCallback) => ({
  type: DELETE_VEHICLE_START,
  payload: id,
  successCallback,
});

export const deleteVehicleSucess = (id) => ({
  type: DELETE_VEHICLE_SUCCESS,
  payload: id,
});

export const deleteVehicleError = (error) => ({
  type: DELETE_VEHICLE_FAILURE,
  payload: error,
});
