import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import { colorCode, colorInner } from "../../../Utility/constnt";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const EmissionGen = (props) => {
  const { responseData, totalScore, eScore, sScore, gScore } = props;
  const [expandedSections, setExpandedSections] = useState({
    environmental: false,
    social: false,
    governance: false,
  });

  const toggleExpansion = (section) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <Card>
      <CardBody>
        {/* ESG score section */}
        <div className="d-flex align-items-center mb-4">
          <div className="flex-1">
            <div className="font-size-18">
              <p
                style={{
                  fontWeight: "700",
                  color: "#000",
                  padding: "0",
                  margin: "0",
                }}
              >
               {` ESG score: ${totalScore}/300`}
              </p>
            </div>
          </div>
        </div>

        {Object.keys(responseData).map((section, index) => {
          let keyName = "";
          if (index == 0) {
            keyName = "Environmental score";
          } else if (index == 1) {
            keyName = "Social score";
          } else if (index == 2) {
            keyName = "Governance score";
          }

          return (
            <div key={index}>
              <div
                className="mt-3 mb-2"
                style={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    height: "20px",
                    width: "20px",
                    border: "1px solid",
                    borderRadius: "4px",
                    backgroundColor: `${colorInner[index]}`,
                  }}
                ></div>
                <p
                  className="font-size-18"
                  style={{
                    fontWeight: "700",
                    color: "#000",
                    padding: "0",
                    margin: "0",
                  }}
                >
                  {keyName}
                </p>
                <p style={{ margin: "0px" }}>
                  {keyName == "Environmental score" && `${eScore} / 81`}
                  {keyName == "Social score" && `${sScore} / 143`}
                  {keyName == "Governance score" && `${gScore} / 76`}
                  {/* ({`${esgScore[index]} / ${esgScore[index + 5]}`}) */}
                </p>
                {expandedSections[section] ? (
                  <FaAngleUp
                    style={{ fontSize: "15px" }}
                    onClick={() => toggleExpansion(section)}
                  />
                ) : (
                  <FaAngleDown
                    style={{ fontSize: "15px" }}
                    onClick={() => toggleExpansion(section)}
                  />
                )}
              </div>

              {expandedSections[section] &&
                Object.values(responseData[section]).map(
                  (item, itemIndex) =>
                    item?.sectionMaxScore != "0" && (
                      <div
                        key={itemIndex}
                        className="mb-2"
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          style={{
                            height: "10px",
                            width: "10px",
                            border: "1px solid",
                            borderRadius: "50px",
                            backgroundColor: `${
                              item.color || colorCode[index][itemIndex]
                            }`,
                          }}
                        ></div>
                        <p
                          style={{ margin: "0px" }}
                        >{`${item.sectionName} : ${item.sectionScore} / ${item.sectionMaxScore}`}</p>
                      </div>
                    )
                )}
            </div>
          );
        })}

        {/* Rendered if no ESG score found */}
        {(Object.keys(responseData).length === 0 ||
          responseData === undefined) && (
          <p
            className="font-size-18"
            style={{
              fontWeight: "700",
              color: "rgb(146 148 164)",
              padding: "0",
              margin: "0",
              textAlign: "center",
            }}
          >
            No ESG score found
          </p>
        )}
      </CardBody>
    </Card>
  );
};

export default EmissionGen;
