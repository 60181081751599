import { Col, Row, Tooltip, Typography } from "antd";
import React from "react";
import { provideWidthArea } from "../../pages/Utility/function";

const { Paragraph } = Typography;

const HeaderGen = (props) => {
  const {
    tableFieldList,
    upDownButton = false,
    setOpen,
    open,
    tableHeader = false,
  } = props;

  const columnWidth = provideWidthArea(tableFieldList?.length - 1);

  return (
    <>
      {tableFieldList
        ?.filter((el) => !Array.isArray(el))
        ?.map((el, i) => (
          <Col
            key={i + 1}
            lg={el?.xl || columnWidth?.lg}
            md={columnWidth?.md}
            xl={el?.xl || columnWidth?.xl}
            sm={12}
            xs={24}
            xxl={el?.xl || columnWidth?.xxl}
          >
            <Row align="middle" justify="start">
              <Paragraph
                className="m-0"
                style={{ fontSize: "13px", fontWeight: "600" }}
              >
                {el?.label}
                {el?.required ? (
                  <sup
                    style={{
                      color: "red",
                      marginLeft: "5px",
                    }}
                    className=""
                  >
                    *
                  </sup>
                ) : null}{" "}
                {el?.helpText ? (
                  <Tooltip className="table--label" title={el?.helpText}>
                    <i
                      className="bx bx-info-circle"
                      id={"icon"}
                      style={{ fontSize: "15px", marginLeft: "2px" }}
                    ></i>
                  </Tooltip>
                ) : null}
              </Paragraph>
              {/* {tableFieldList?.length - 1 == i + 1 && upDownButton ? (
                tableHeader ? (
                  <div
                    style={{
                      position: "absolute",
                      // top: "-4px",
                      right: "0px",
                      alignSelf: "center",
                    }}
                  >
                    <div className="child-table-field-show">
                      <i
                        className={`${
                          open
                            ? "bx bx-chevron-up"
                            : //   }
                              "bx bx-chevron-down"
                        }`}
                        style={{ fontSize: "20px" }}
                        onClick={() => {
                          setOpen((pr) => !pr);
                        }}
                      ></i>
                    </div>
                  </div>
                ) : null
              ) : null} */}
            </Row>
          </Col>
        ))}
    </>
  );
};

export default HeaderGen;
