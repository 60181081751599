import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
  NavLink,
} from "reactstrap";
import classnames from "classnames";
import CONSTANT, {
  StatusButton,
  TABLE_HEADER,
  TAB_DATA,
  getTableData,
} from "../../Utility/constnt";
import moment from "moment";
import useHttp from "../../../components/Hook/Use-http";
import Table from "../../../components/Custome/table";
import { useParams } from "react-router-dom";
import {
  getDateAndTimeColumn,
  getDateAndTimeColumnFor,
  getMobileNumber,
  getUpperCaseText,
  getVehicleNumberText,
  replaceId,
} from "../../Utility/function";
import { tripHeaderData } from "../../Utility/exportValue";
import PageTitle from "../../../components/pageTitle";
import { useSelector } from "react-redux";
import { tripMask, vehicleDataMask } from "../../Utility/spread-function";

let tripCreateHeader = [];

const TransporterInfo = () => {
  // let TransporterId = window.location.pathname.split("/")[2];
  const { transposerId } = useParams();
  const [activeTab, setactiveTab] = useState(1);
  const [tripData, setTripData] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [transpoterData, setTranspoterData] = useState([]);

  const getShipmentData = useSelector((state) => state.shipmentDetailData);
  const getVehicleData = useSelector((state) => state.vehicleDetailData);
  const getTransporterData = useSelector(
    (state) => state.transporterDetailData
  );

  const API_CALL = useHttp();
  const toggleCustomJustified = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };
  // useEffect(() => {
  //   (async () => {
  //     const PLANT_URL = {
  //       endpoint: `/trip/?transporterId=${TransporterId}`,
  //       type: "GET",
  //     };
  //     API_CALL.sendRequest(PLANT_URL, tripDataHandler);
  //     const VEHICLES_URL = {
  //       endpoint: `/vehicle/?transporterId=${TransporterId}`,
  //       type: "GET",
  //     };
  //     API_CALL.sendRequest(
  //       replaceId(CONSTANT.API.TRANSPORTER.getSingleTransporter, TransporterId),
  //       (res) => setTranspoterData(res?.data[0])
  //     );

  //     API_CALL.sendRequest(VEHICLES_URL, vehicleDataHandler);
  //   })();
  // }, []);

  useEffect(() => {
    // const PLANT_URL = {
    //   endpoint: `/trip/?transporterId=${TransporterId}`,
    //   type: "GET",
    // };
    // API_CALL.sendRequest(PLANT_URL, tripDataHandler);
    // const VEHICLES_URL = {
    //   endpoint: `/vehicle/?transporterId=${TransporterId}`,
    //   type: "GET",
    // };
    // API_CALL.sendRequest(
    //   replaceId(CONSTANT.API.TRANSPORTER.getSingleTransporter, TransporterId),
    //   (res) => setTranspoterData(res?.data[0])
    // );

    // API_CALL.sendRequest(VEHICLES_URL, vehicleDataHandler);
    tripDataHandler(getShipmentData?.users);
    setTranspoterData(
      getTransporterData?.users?.data?.rows?.find(
        (it) => it?.id == transposerId
      )
    );
    vehicleDataHandler(getVehicleData?.users);
  }, [getTransporterData, getVehicleData, getShipmentData]);

  const tripDataHandler = (res) => {
    const clientTrip = res?.data?.rows?.filter(
      (itm) => itm?.transporterId == transposerId
    );

    setTripData(tripMask(clientTrip));

    // setTripData(
    //   res?.data
    //     ?.filter((it) => it?.transporterId == TransporterId)
    //     ?.map((tripData, index) => {
    //       const startDate = moment(
    //         tripData?.targetedDateAndTime,
    //         "DD-MM-YYYY hh:mm A"
    //       );
    //       const completedDate = moment(tripData?.completedDateAndTime);

    //       const isStartDateAfterCompletedDate =
    //         startDate.isAfter(completedDate);
    //       return {
    //         ...tripData,
    //         no: `CO${tripData?.id + 20000000006000}`,
    //         clientName: getUpperCaseText(tripData?.client?.name),
    //         transporterName: getUpperCaseText(
    //           tripData?.transporter?.transporterName
    //         ),
    //         utilisation: parseInt(tripData?.utilisation),
    //         driverName: getUpperCaseText(tripData?.driver?.name),
    //         driverPhoneNumber: getMobileNumber(tripData?.driver?.mobile),
    //         vehicleNumber:
    //           getVehicleNumberText(tripData?.vehicle?.registrationNumber) || "",
    //         plantName: getUpperCaseText(tripData?.plant?.unitName),
    //         startDateAndTime: getDateAndTimeColumn(tripData?.startDateAndTime),
    //         targetedDateAndTime: getDateAndTimeColumn(
    //           tripData?.targetedDateAndTime
    //         ),
    //         targetedDate: moment
    //           .utc(tripData?.targetedDateAndTime, "DD-MM-YYYY hh:mm A")
    //           .format("DD-MM-YYYY"),
    //         startDate: moment
    //           .utc(tripData?.startDateAndTime, "DD-MM-YYYY hh:mm A")
    //           .format("DD-MM-YYYY"),
    //         startTime: moment(
    //           tripData?.startDateAndTime,
    //           "DD-MM-YYYY hh:mm A"
    //         ).format("hh:mm A"),
    //         targetedTime: moment(
    //           tripData?.targetedDateAndTime,
    //           "DD-MM-YYYY hh:mm A"
    //         ).format("hh:mm A"),
    //         startDateAfterCompletedDate: isStartDateAfterCompletedDate
    //           ? "On Time"
    //           : "Delayed",
    //         statusdata:
    //           tripData?.status === 1
    //             ? "In Transit"
    //             : tripData?.status === 2
    //             ? "In Transit"
    //             : "Completed",
    //         statusData: (
    //           <>
    //             <StatusButton value={tripData?.status} />
    //           </>
    //         ),
    //       };
    //     })
    // );
  };
  const vehicleDataHandler = (res) => {
    let transpoterVehicle = {
      data: {},
    };
    transpoterVehicle.data["rows"] = res?.data?.rows?.filter(
      (itm) => itm?.transporterId == transposerId
    );

    setVehicleData(vehicleDataMask(transpoterVehicle));
    // setVehicleData(
    //   res?.data
    //     ?.filter((it) => it?.transporterId == TransporterId)
    //     ?.map((vehicleData, index) => {
    //       return {
    //         ...vehicleData,
    //         no: `V${(vehicleData?.id).toString().padStart(7, "0")}`,
    //         transporterName: vehicleData?.transporter?.transporterName,
    //       };
    //     })
    // );
  };

  useEffect(() => {
    if (localStorage.getItem("userRole") === "admin") {
      tripCreateHeader = [
        tripHeaderData?.filter(([key]) => {
          return ![
            "transporterName",
            "driverName",
            "driverPhoneNumber",
          ].includes(key);
        }),
      ];
    }
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <PageTitle
          pageHeading={`TRANSPORTER / ${transpoterData?.transporterName || ""}`}
          // backNavPath={`/customers`}
          withBackButton
        />

        <Row>
          <Col sm={4} className="d-none">
            <Card
              style={{
                width: "100%",
              }}
            >
              <CardBody style={{ backgroundColor: "#2f4395", color: "white" }}>
                Name : Nayan
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} className="p-0">
            {/* <Card>
              <CardBody> */}
            <div className="page-content mt-0 pt-0 scrollSticky">
              <Nav tabs className="nav-tabs-custom nav-justified">
                {TAB_DATA.VEHICLES_TAB.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="mt-2"
                      style={{ width: "100px" }}
                    >
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab === data.tabId,
                            activeTabDesign: activeTab === data.tabId,
                            TabDesign: true,
                          })}
                          onClick={() => {
                            toggleCustomJustified(data.tabId);
                          }}
                        >
                          <span className="d-none d-sm-block">{data.name}</span>
                        </NavLink>
                      </NavItem>
                    </div>
                  );
                })}
              </Nav>
              <TabContent activeTab={activeTab} className="mt-3 p-0 TabBody">
                <TabPane tabId={1} className="py-3">
                  <div className="custom-Table">
                    <Table
                      title="Shipment list"
                      data={getTableData(
                        TABLE_HEADER?.shipment?.filter((el) => {
                          let Hide = [];
                          Hide = [
                            "pod",
                            "mapView",
                            "billDownload",
                            "packageEmission",
                            "totalGen",
                            "invoiceNumber",
                            "packageType",
                            "action",
                          ];

                          return !Hide.includes(el);
                        }),
                        tripData
                      )}
                      exportHeader={
                        localStorage.getItem("userRole") !== "admin"
                          ? tripCreateHeader
                          : tripHeaderData
                      }
                      // exportValue={tripValueData(tripData)}
                      excelName="Shipment_Report.csv"
                    />
                  </div>
                </TabPane>
                <TabPane tabId={2} className="py-3 px-0">
                  <div className="custom-Table">
                    <Table
                      title="Vehicles list"
                      data={getTableData(
                        TABLE_HEADER?.vehicles?.filter((el) => {
                          let Hide = [];
                          Hide = ["action"];

                          return !Hide.includes(el);
                        }),
                        vehicleData
                      )}
                      excelName="Vehicle_Report.csv"
                    />
                  </div>
                </TabPane>
              </TabContent>
              {/* </CardBody>
            </Card> */}
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default TransporterInfo;
