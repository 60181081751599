import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody } from "reactstrap";
// Redux
import { useLocation, useNavigate, useParams } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
// actions
import CustomForm from "../../components/Custome/CustomForm";
import useHttp from "../../components/Hook/Use-http";
import CONSTANT, { TABLE_HEADER } from "../Utility/constnt";
import CustomModalMain from "../../components/Custome/CustomModalMain";
import CustomPage from "../../components/Custome/CustomPage";
import { categoryDataMask } from "../Utility/spread-function";
import { replaceId } from "../Utility/function";
import PageTitle from "../../components/pageTitle";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}
const Settings = (props) => {
  const [flag, setFlag] = useState(false);
  const [settings, setSettings] = useState({});
  const API_CALL = useHttp();

  const [subCategory, setSubCategory] = useState([]);

  useEffect(() => {
    API_CALL.sendRequest(CONSTANT.API.CATEGORY.get, (res) => {
      setSubCategory(res?.data?.rows);
    });
  }, []);

  const updateVisiblity = (selected, id) => {
    API_CALL.sendRequest(
      replaceId(CONSTANT.API.SUBCATEGORY.update, id),
      () => {
        setFlag((pr) => !pr);
      },
      { isVisible: selected },
      "Data updated successfully"
    );
  };

  const getSettingsHandler = (res) => {
    setSettings(res.data[0]);
  };

  const loadSettings = () => {
    API_CALL.sendRequest(CONSTANT.API.SETTING.get, getSettingsHandler);
  };

  useEffect(() => {
    loadSettings();
  }, []);

  const onSubmitForm = (payload) => {
    (async () => {
      API_CALL.sendRequest(
        CONSTANT.API.SETTING.update,
        updateMeHandler,
        {
          value: payload.distance,
        },
        "Your data was updated successfully"
      );
    })();
  };

  const updateMeHandler = () => {
    setFlag((pr) => !pr);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {/* <Breadcrumb title="Fitsol" breadcrumbItem="Settings" /> */}
        <PageTitle pageHeading={`Settings`} />
        {/* <h4 className="page-title mb-3 font-size-18 mt-4">Settings</h4> */}
        {/* <h4 className="card-title mb-4 mt-4">Settings</h4> */}

        <Card>
          <CardBody>
            <CustomModalMain
              data={CONSTANT.FORM_FIELDS.SETTINGS}
              defaultData={{ distance: settings.value }}
              labelSize={"25%"}
              fieldSize={"70%"}
              // submitBtnName="Calculate Shipment Emission"
              onSubmit={(data) => onSubmitForm(data)}
              isCloseButtonVisible={false}
              // minHeight={"300px"}
              onChange={(data) => {}}
              // formData={true}
            />
            {/* <CustomForm
              data={CONSTANT.FORM_FIELDS.SETTINGS}
              onSubmit={(data) => onSubmitForm(data)}
              defaultData={{ distance: settings.value }}
              // formData={true}
              // isEdit={isEdit}
            /> */}
          </CardBody>
        </Card>
      </div>

      <div className="mt-2">
        <CustomPage
          // API
          noHeader={false}
          PAGE={{
            title: "Category",
            pageRefresh: {
              flag: flag,
            },
            dataFunction: (res, Actions) => {
              delete Actions?.setDeleteData;
              return categoryDataMask(res, Actions, updateVisiblity);
            },
          }}
          API={{
            get: CONSTANT.API.SUBCATEGORY?.get,
            update: CONSTANT.API.SUBCATEGORY?.update,
          }}
          FORM={{
            formField: CONSTANT.FORM_FIELDS?.CATEGORYSETTING,
            addFormTitle: "Create category",
            editFormTitle: "Edit category",
            addNewRecordCtaText: "Create category",
            // optionalFieldOnEdit: ["tripEndDistance", "insuranceNumber"],
            // removeFieldOnEdit: ["sampleFile"],
            option: {
              categoryId: subCategory?.map((data) => {
                return {
                  label: data?.name,
                  value: data?.id,
                  // categoryId: data?.categoryId,
                  // categoryName: data?.category?.name,
                };
              }),
            },
          }}
          TABLE={{
            title: "Category list",
            header: TABLE_HEADER?.admin_Category_setting,
            // export: {
            //   fileName: "Transporter_Report.csv",
            //   header: admin_Category,
            // },
          }}
          formData={true}
        />
      </div>
    </React.Fragment>
  );
};

export default withRouter(Settings);
