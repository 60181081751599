import React, { useEffect, useState } from "react";
import useHttp from "../../components/Hook/Use-http";
import CONSTANT, { TABLE_HEADER } from "../Utility/constnt";
import CustomPage from "../../components/Custome/CustomPage";

import { vehicleDataMask } from "../Utility/spread-function";
import {
  // getCo2,
  getExtraConstPayload,
  getTonneFromKg,
  isValidVehicleNumber,
  notifications,
  optionArray,
} from "../Utility/function";
import notify from "../Utility/coustemFunction";
import { vehicleMapping } from "../Utility/models/mapping-model";
import { getCo2, engineType } from "../Utility/option";
import { vehicleViewCardDetails} from "../Utility/models/view-details";
import { vehicalHeaderData } from "../Utility/exportValue";
import { useSelector } from "react-redux";
import { deleteVehicleStart, getVehicleStart } from "../../store/actions";

const Vehicals = () => {
  const [vehicleData, setVehicleData] = useState(null);
  const getVehicleData = useSelector((state) => state.vehicleDetailData);
  const getTransporterData = useSelector(
    (state) => state.transporterDetailData
  );
  
  const API_CALL2 = useHttp();

  const verifyHandler = (res) => {
    const VehicleNumbers = res?.VehicleNumber;
    const VehicleNumber = isValidVehicleNumber(VehicleNumbers);
    if (VehicleNumber?.isMatch) {
      API_CALL2.sendRequest(
        CONSTANT.API.VEHICLE.addVehicleVerifyByNumber,
        (res) => {
          setVehicleData((prev) => {
            const vehicleData = res?.data;
            delete vehicleData?.id;
            return {
              ...prev,
              ...vehicleData,
              registrationNumber: vehicleData?.registration_number,
              fuelType:  vehicleData?.fuel_type,
              manufacture: vehicleData?.manufacturer,
              unladden_weight: getTonneFromKg(vehicleData?.unladden_weight),
              gross_vehicle_weight: getTonneFromKg(
                vehicleData?.gross_vehicle_weight
              ),
              capacity: getTonneFromKg(
                +vehicleData?.gross_vehicle_weight -
                  +vehicleData?.unladden_weight
              ),

              allocate: true,
            };
          });
        },
        {
          vehicleNumber: VehicleNumber?.normalizedNumber,
        }
      );
    } else {
      notifications.error("Please Enter Valid Vehicle Number");
    }
  };

  return (
    <div className="page-content">
      <CustomPage
        PAGE={{
          title: "Vehicle",
          dataFunction: vehicleDataMask,
        }}
        API={CONSTANT.API.VEHICLE}
        REDUX={{
          getDataRedux: getVehicleData?.users,
          getApiDataRedux: getVehicleStart(),
          deleteApiDataRedux: deleteVehicleStart,
        }}
        FORM={{
          formField: CONSTANT.FORM_FIELDS?.VEHICLES,
          addFormTitle: "Add vehicle",
          editFormTitle: "Edit vehicle",
          addNewRecordCtaText: "Add vehicle", //===========
          removeFieldOnEdit: ["VehicleNumber"],
          isSubmitButtonVisible: vehicleData?.registration_number
            ? true
            : false,
          extraConstPayload: vehicleData?.registration_number
            ? getExtraConstPayload(vehicleData, vehicleMapping)
            : [],
          option: {
            transporterId: optionArray(
              getTransporterData?.users?.data?.rows,
              "transporterName",
              "id"
            ),
            engineType,
          },
          onChange: (data) => {
            if (data?.engineType) {
              setVehicleData((prev) => {
                let objectData = {
                  co2PerKm: getCo2[data?.engineType] || 0.45,
                };
                if (prev) {
                  objectData = {
                    ...prev,
                    ...objectData,
                  };
                }
                return objectData;
              });
            }
          },

          viewModel: {
            setVerifyData: setVehicleData,
            viewModelData: vehicleData,
            cardVisibleOn: "registration_number",
            viewModelLoading: API_CALL2?.isLoading,
            viewModelDetails: vehicleViewCardDetails,
            onVerifyHandler: verifyHandler,
          },
        }}
        TABLE={{
          title: "Vehicles list",
          header: TABLE_HEADER?.vehicles,
          export: {
            fileName: "Vehicle_Report.csv",
            header: vehicalHeaderData,
          },
        }}
        />
    </div>
  );
};

export default Vehicals;
