import {
  GET_JOBBULK_FAILURE,
  GET_JOBBULK_START,
  GET_JOBBULK_SUCCESS,
} from "./actionTypes";

const initialState = {
  users: [],
  loading: false,
  error: null,
};

const jobBulkDetailData = (state = initialState, action) => {
  switch (action.type) {
    case GET_JOBBULK_START:
      return { ...state, loading: true };
    case GET_JOBBULK_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      };
    // return action.data;

    case GET_JOBBULK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };

    default:
      return state;
  }
};
export default jobBulkDetailData;
