import { useState, useCallback } from "react";
import Services from "../../pages/Utility/API/service";
// import notify from "../../pages/Utility/coustemFunction";
import axios from "axios";
import { notifications } from "../../pages/Utility/function";

const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaderCount, setIsLoaderCount] = useState(false);

  const sendRequest = useCallback(
    async (
      url,
      responseHandler,
      payload,
      successMessage,
      errorHandler,
      progressHandler,
      services = Services
    ) => {
      if (services == null) {
        services = Services;
      }
      setIsLoading(true);
      try {
        let response;
        switch (url.type) {
          case "POST":
            response = await services.post(url.endpoint, payload, {
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                if (progressHandler) {
                  progressHandler(percentCompleted);
                }
              },
            });
            break;

          case "PUT":
            response = await services.put(url.endpoint, payload);

            break;
          case "DELETE":
            response = await services.delete(url.endpoint);
            break;

          case "PATCH":
            response = await services.patch(url.endpoint, payload, {
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                // setTotalProgress(
                //   (prevProgress) => prevProgress + percentCompleted
                // );
              },
            });
            break;

          default:
            response = await services.get(url.endpoint, {
              onDownloadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );

                if (progressHandler) {
                  progressHandler(percentCompleted);
                }
              },
            });
            break;
        }

        const data = await response.data;
        if (successMessage) {
          notifications.success(successMessage);
        }
        try {
          if (responseHandler) {
            responseHandler(data);
          }
        } catch (e) {
          console.log(e);
        }
      } catch (err) {
        if (err?.response?.data) {
          if (errorHandler) {
            errorHandler(err?.response?.data?.message);
          }
          notifications.error(err?.response?.data?.message);
        } else if (err?.response?.data?.message) {
          notifications.error(err?.response?.data?.message);
          if (errorHandler) {
            errorHandler(err?.response?.data?.message);
          }
        } else if (err?.response?.data?.message?.message) {
          notifications.error(err?.response?.data?.message?.message);
        } else {
          notifications.error("Something Wrong Please Try again");
        }
      }
      setIsLoading(false);
    },
    []
  );

  const getAll = useCallback(
    (endpoints, responseHandler, services = Services) => {
      setIsLoading(true);

      axios
        .all(endpoints.map((endpoint) => services.get(endpoint)))
        .then((data) => {
          try {
            if (responseHandler) {
              setIsLoading(false);
              responseHandler(data);
            }
          } catch (e) {
            setIsLoading(false);
            console.log(e);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          if (err?.response?.data) {
            notifications.error(err?.response?.data?.message);
          } else if (err?.response?.data?.message) {
            notifications.error(err?.response?.data?.message);
          } else {
            notifications.error("Something Wrong Please Try again");
          }
        });
    },
    []
  );

  const sendBulkRequest = useCallback(
    async (urls, responseHandler, successMessage, errorHandler) => {
      setIsLoading(true);
      try {
        let response;
        response = await axios.all(
          urls?.map((url) => {
            switch (url?.url?.type) {
              case "POST":
                return Services.post(url?.url?.endpoint, url?.payload);

              case "PUT":
                return Services.put(url?.url?.endpoint, url?.payload);

              case "DELETE":
                return Services.delete(url?.url?.endpoint);

              case "PATCH":
                return Services.patch(url?.url?.endpoint, url?.payload);

              default:
                return Services.get(url?.endpoint);
            }
          })
        );

        const data = await response.data;
        if (successMessage) {
          notifications.success(successMessage);
        }
        try {
          if (responseHandler) {
            responseHandler(data);
          }
        } catch (e) {
          console.log(e);
        }
      } catch (err) {
        if (err?.response?.data) {
          notifications.error(err?.response?.data?.message);
        } else if (err?.response?.data?.message) {
          notifications.error(err?.response?.data?.message);
          if (errorHandler) {
            errorHandler(err?.response?.data?.message);
          }
        } else {
          notifications.error("Something Wrong Please Try again");
        }
      }
      setIsLoading(false);
    },
    []
  );

  return {
    isLoading,
    sendRequest,
    sendBulkRequest,
    getAll,
  };
};

export default useHttp;
