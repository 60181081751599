import { all, fork } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import calendarSaga from "./calendar/saga";
import chatSaga from "./chat/saga";
import tasksSaga from "./tasks/saga";
import contactsSaga from "./contacts/saga";
import GetUserDetailSaga from "./getMeData/saga";
import GetDriverDetailSaga from "./driverFinal/saga";
import GetVehicleDetailSaga from "./vehicle/saga";
import GetTransporterDetailSaga from "./transporter/saga";
import GetCustomerDetailSaga from "./customer/saga";
import GetPlantDetailSaga from "./plant/saga";
import GetRouteDetailSaga from "./route/saga";
import GetPackagingDetailSaga from "./packaging/saga";
import GetRoleAndAccessDetailSaga from "./roleAndAccess/saga";
import GetShipmentDetailSaga from "./trip/saga";
import GetAdminDashboardDetailSaga from "./adminDashboard/saga";
import GetJobBulkDetailSaga from "./bulkUploadJob/saga";

export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    fork(AuthSaga),
    ProfileSaga(),
    ForgetSaga(),
    fork(LayoutSaga),
    fork(calendarSaga),
    fork(chatSaga),
    fork(tasksSaga),
    fork(contactsSaga),
    GetUserDetailSaga(),
    GetDriverDetailSaga(),
    GetVehicleDetailSaga(),
    GetTransporterDetailSaga(),
    GetCustomerDetailSaga(),
    GetPlantDetailSaga(),
    GetRouteDetailSaga(),
    GetPackagingDetailSaga(),
    GetRoleAndAccessDetailSaga(),
    GetShipmentDetailSaga(),
    GetAdminDashboardDetailSaga(),
    GetJobBulkDetailSaga(),
  ]);
}
