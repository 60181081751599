import ForgetPassword from "../Authentication/ForgetPassword";
import Logout from "../Authentication/Logout";
import Register from "../Authentication/Register";

import Login from "../Authentication/Login";
import SignupPage from "../Authentication/SignUp";
// import LockScreen from "../AuthenticationInner/auth-lock-screen";
import Datanotfound from "./page-data";
import PagesUnAuthorised from "./pages-401_unAuthorised";
import Pages404 from "./pages-404";
import Pages403 from "./pages-403";

import Pages500 from "./pages-500";
import PagesComingsoon from "./pages-comingsoon";
import PagesMaintenance from "./pages-maintenance";

// Inner Authentication
import Login1 from "../AuthenticationInner/Login";
import Register1 from "../AuthenticationInner/Register";
import Recoverpw from "../AuthenticationInner/Recoverpw";
import LockScreen from "../AuthenticationInner/auth-lock-screen";
import Report from "../Report";
import Tracking from "../Tracking";
import ShipmentForm from "../ShipmentForm";
import DownloadBill from "../BillForm/DownloadBill";
import Admin from "../User";
import Driver from "../User/driver";
import ClientInfo from "../User/Customer/ClientInfo";
import Customers from "../User/Customer";
import DriverInfo from "../User/driver/DriverInfo";
import Transporter from "../User/transporter";
import TransporterInfo from "../User/transporter/transpoterInfo";
import Vehicals from "../Vehicals";
import VehiclesInfo from "../Vehicals/VehiclesInfo";
import SitesAccess from "../Plants/siteAccess";
import Trip from "../Trip";
import Dashboard from "../EmissionCalculation/dashboard";
import AddDataEntry from "../EmissionCalculation/addEntry";
import BulkUploadView from "../EmissionCalculation/bulkUploadView";
import GenerateReportForEmission from "../EmissionCalculation/generateReport";
import DownloadReportForEmission from "../EmissionCalculation/dowloadData";
import Categorys from "../EmissionCalculation/AdminEmission/category";
import Attributes from "../EmissionCalculation/AdminEmission/attribute";
import CategoryForm from "../EmissionCalculation/AdminEmission/category-form";
import TasksKanban from "../old/Tasks/tasks-kanban";
import Plants from "../Plants";
import SitePackageInfo from "../Plants/site-pacakegingInfo";
import SiteInfo from "../Plants/site-Info";
import UserProfile from "../Authentication/user-profile";
import RoleAccess from "../roleAccess/roleAccess";
import Team from "../team";
import Settings from "../Settings";
import RoutePage from "../Plants/route-info";
import Packaging from "../User/Packageing";
import ClientReport from "../Client/dashboard";
import ClientTrackMap from "../Client/trackMap";
import ClientPackaging from "../Client/packaging";
import ClientSite from "../Client/site";
import ClientSitePackageInfo from "../Client/site/client-sitePackageInfo";
import ClientSiteAccess from "../Client/site/clientSiteAccess";
import ClientRoleAndAccess from "../Client/roleAndAccess";
import ClientEmissionDashboard from "../Client/emission/emissionDashboard";
import ClientAddEntry from "../Client/emission";
import ClientBulkUploadView from "../Client/emission/clientBulkUploadView";
import SiteViewerReport from "../SiteViewerRole/dashboard";
import SiteViewerTrackMap from "../SiteViewerRole/trackMap";
import SiteViewerPackaging from "../SiteViewerRole/packaging";
import SiteViewerShipment from "../SiteViewerRole/shipment";
import SiteViewerRoute from "../SiteViewerRole/route";
import {
  adminRoute,
  appRoot,
  clientRoute,
  siteRoute,
  spocRoute,
  tripRoute,
} from "./constnt";
import NonAuthLayout from "../../components/NonAuthLayout";
import VerticalLayout from "../../components/VerticalLayout";
import Authorize from "../../components/authorize";
import AdminLayout from "../../components/layout/adminLayout";
import ClientLayout from "../../components/layout/clientLayout";
import SiteLayout from "../../components/layout/siteLayout";
import TripLayout from "../../components/layout/tripLayout";
import SpocLayout from "../../components/layout/spocLayout";
import { Navigate } from "react-router-dom";
import ClientRoute from "../Client/route";
import ClientShipment from "../Client/shipment";
import NoInternet from "../noInternet";
import ClientProfile from "../Client/client_profile";
import SiteProfile from "../SiteViewerRole/site_profile";
import SpocProfile from "../SpocRole/spoc_profile";
import ApiDashboard from "../apiDashboardRole/dashbaord";
import EditShipmentForm from "../ShipmentForm/editShipmentForm";
import PcfDemo from "../pcf";
import TrackingShipmentMap from "../TripCreaterRole/trackingShipment";
import TripRoleShipmentHistory from "../TripCreaterRole/shipmentHistory";
import PcfProductPage from "../Client/pcfProduct";
import Scope3Report from "../Client/categoryReport/Scope3Report";
import Scope2Report from "../Client/categoryReport/Scope2Report";
import Scope1Report from "../Client/categoryReport/Scope1Report";
import ScopeOverallReport from '../Client/categoryReport/ScopeOverallReport';
import BiogenicEmissionReport from '../Client/categoryReport/BiogenicEmissions';
import BrsrForm from "../BRSR/brsrForm";
import Temp from "../BRSR/temp";
import BrsrPage from "../BRSR";
import SpocBrsrPage from "../SpocRole/brsr";
import SpocsList from "../BRSR/spocslist";
import ChangeLogs from "../Client/changeLogs";
import AddEmissionFactor from '../EmissionCalculation/AddEmissionFactor';
import PartnerOnboardingForm from "../Client/Networking/PartnerOnboardingForm";
import ManageConnections from "../Client/Networking/ManageConnections";
import BulkOnboardView from "../EmissionCalculation/bulkOnboardView";
import ClientBulkOnboardView from "../Client/Networking/PartnerOnboardingForm/clientBulkOnboardView";
export const ROUTES = {
  //AUTHROUTEPATH
  LOGIN: "/login",
  SIGNUP:"/signup",
  LOGOUT: "/logout",
  FORGOT_PASSWORD: "/forgot-password",
  REGISTER: "/register",
  PAGESMAINTENANCE: "/pages-maintenance",
  PAGESCOMINGSOON: "/pages-comingsoon",
  PAGES404: "/pages-404",
  PAGES500: "/pages-500",
  PAGES403: "/pages-403",

  PAGESUNAUTHORISED: "/pages-401",
  DATANOTFOUND: "/data-not-found",
  LOGIN1: "/pages-login",
  REGISTER1: "/pages-register",
  RECOVERPW: "/page-recoverpw",
  LOCKSCREEN: "/auth-lock-screen",

  //ADMIN
  ADMIN_DASHBOARD: "/",
  ADMIN_TRACKING: "tracking",
  ADMIN_TRACKING_DETAIL: "tracking/:tripId",
  ADMIN_INVOICE_BILL: "invoice",
  ADMIN_SHIPMENTFORM: "shipmentform",
  ADMIN_SHIPMENTFORM_DETAIL: "shipmentform/:shipmentId",
  ADMIN_ADMIN: "admins",
  ADMIN_CLIENT: "customers",
  ADMIN_CLIENT_INFO: "customers/:clientId",
  ADMIN_DRIVER: "drivers",
  ADMIN_DRIVER_INFO: "driversInfo/:driversId",
  ADMIN_TRANSPORTER: "transporter",
  ADMIN_TRANSPORTER_INFO: "transporter/transposerInfo/:transposerId",
  ADMIN_VEHICLE: "vehicles",
  ADMIN_VEHICLE_INFO: "vehiclesInfo/:vehiclesId",
  ADMIN_SITE_SITEACCESS: "sites/sitesAccess/:SiteID",
  ADMIN_SHIPMENT: "shipments",
  ADMIN_EMISSION_DASHBOARD: "emission-dashboard",
  ADMIN_ADD_SINGLE_ENTRY: "add-entry",
  ADMIN_ADD_BULK_ENTRY: "add-entry/bulk",
  ADMIN_BULK_UPLOAD_VIEW: "add-entry/:jobId",
  ADMIN_BULK_ONBOARD_VIEW:"network/partneronboard/:jobId",
  ADMIN_GENERATE_REPORT_FOR_EMISSION: "generate-report",
  ADMIN_DOWNLOAD_REPORT_FOR_EMISSION: "download-report",
  ADMIN_CATEGORY: "category",
  ADMIN_ATTRIBUTE: "attribute",
  ADMIN_BRSR_ATTRIBUTE: "brsr-attribute",
  TEMP: "temp",
  ADMIN_CATEGORY_FORM: "category-form",
  ADMIN_BRSR_FORM: "brsr/brsr-form/:sectionId/:formId",
  ADMIN_BRSR_SPOC_LIST: "brsr/brsr-form/:formId/spoclist",
  ADMIN_TASK_KANBAN: "task",
  ADMIN_PLANT: "sites",
  ADMIN_SITE_PACKAGE_INFO: "sites/:SiteID",
  ADMIN_SITE_SITE_INFO: "sites/:SiteID/:packageId",
  ADMIN_PACKAGING: "packaging",
  ADMIN_SITE_PACKAING_INFO: "site-packaging",
  ADMIN_ROUTE: "routeInfo",
  ADMIN_ROUTE_INFO: "routeInfo/:id",
  ADMIN_SETTING: "settings",
  ADMIN_TEAM: "team",
  ADMIN_ROLEANDACCESS: "roleaccess",
  ADMIN_USERPROFILE: "profile",
  ADMIN_PAGE_COMING_SOON: "pages-comingsoon",
  ADMIN_ADD_EMISSION_FACTOR:"addEF",

  //CLIENT
  CLIENT_DASHBOARD: "client",
  CLIENT_TRACKING: "tracking/:tripId",
  CLIENT_TRACKING_DETAIL: "tracking",
  CLIENT_PACKAGING: "packaging",
  CLIENT_PLANT: "sites",
  CLIENT_PLANT_PACKAGING_INFO: "sites/:SiteID",
  CLIENT_PLANT_PLANTACCESS: "sites/sitesAccess/:SiteID",
  CLIENT_ROUTE: "routeInfo",
  CLIENT_ROLEANDACCESS: "roleaccess",
  CLIENT_SHIPMENT: "shipments",
  CLIENT_BRSR_FORM: "brsr/brsr-form/:sectionId/:formId",
  CLIENT_BRSR_SPOC_LIST: "brsr/brsr-form/:formId/spoclist",
  CLIENT_EMISSION_DASHBOARD: "emission-dashboard",
  CLIENT_ADD_SINGLE_ENTRY: "add-entry",
  SCOPE_3_REPORT: "scope3",
  SCOPE_2_REPORT: "scope2",
  SCOPE_1_REPORT: "scope1",
  SCOPE_OVERALL_REPORT: "scope",
  BIOGENIC_EMISSION_REPORT: "biogenic",
  CLIENT_BULK_UPLOAD_VIEW: "add-entry/:jobId",
  CLIENT_BULK_ONBOARD_VIEW: "network/partneronboard/:jobId",
  CLIENT_USERPROFILE: "profile",
  CLIENT_BRSR_DASHBOARD: "brsr",
  CLIENT_CHANGE_LOG: 'changeLogs',
  CLIENT_PARTNER_ONBOARDING:"network/partneronboard",
  CLIENT_MANAGE_CONNECTIONS:"network/manageconnection",

  //site
  SITEVIEWER_DASHBOARD: "siteviewer",
  SITEVIEWER_TRACKING: "tracking/:tripId",
  SITEVIEWER_TRACKING_DETAIL: "tracking",
  SITEVIEWER_PACKAGING: "site-packaging",
  SITEVIEWER_ROUTE: "routeInfo",
  SITEVIEWER_SHIPMENT: "shipments",
  SITEVIEWER_USERPROFILE: "profile",

  //TRIP
  SHIPMENT_CREATE: "/",
  SHIPMENT_TRACKING: "tracking/:tripId",
  SHIPMENT_TRACKING_DETAIL: "tracking",
  SHIPMENT_SHIPMENT: "shipments",

  // SPOC
  SPOC_PROFILE: "profile",
  SPOC_BRSR_DASHBOARD: "brsr",
  SPOC_BRSR_FORM: "brsr/brsr-form/:sectionId/:formId",

  API_DASHBOARD: "api",
};

const LOGIN_ROUTES = [
  // {
  //   path: "/",
  //   element: <SidePage />,
  //   errorElement: <Error />,
  //   children: [
  //     {
  //       index: true,
  //       element: <LogIn />,
  //     },
  //     {
  //       path: "registration",
  //       element: <Registration />,
  //     },
  //   ],
  // },
  {
    index: true,
    path: "/login",
    element: <Login />,
  },
  {
    index:true,
    path:"/signup",
    element:<SignupPage/>
  },

  //   {
  //     path: "registration",
  //     element: <Registration />,
  //   },
  //   {
  //     path: ROUTES.FORGOT_PASSWORD,
  //     element: <ForgetPassword />,
  //     errorElement: <Error />,
  //   },
];

const ALL_ROUTES = [
  ...LOGIN_ROUTES,
  {
    path: "/",
    // index: true,
    element: <Navigate to={`/login`} />,
  },
  {
    path:"/signup",
    element:<Navigate to={'/signup'}/>,
  },
  //   {
  //     path: `${adminRoute}`, //role Admin
  //     element: <Authorize />,
  //     children:[],
  //   }

  {
    path: `${adminRoute}`, //role Admin
    element: <AdminLayout />,
    children: [
      //   {
      //     index: true,
      //     element: <Navigate to={`${adminRoute}`} />,
      //   },
      {
        index: true,
        element: <Report />,
      },
      {
        path: `${"pcftab"}`,
        element: <PcfDemo />,
      },
      {
        path: `${ROUTES.API_DASHBOARD}`,
        element: <ApiDashboard />,
      },
      //   {
      //     path: `${adminRoute}`,
      //     element: <Report />,
      //   },
      {
        path: `${ROUTES.ADMIN_TRACKING}`,
        element: <Tracking />,
      },
      {
        path: `${ROUTES.ADMIN_TRACKING_DETAIL}`,
        element: <Tracking />,
      },
      {
        path: `${ROUTES.ADMIN_INVOICE_BILL}`,
        element: <DownloadBill />,
      },
      {
        path: `${ROUTES.ADMIN_SHIPMENTFORM}`,
        element: <ShipmentForm />,
      },
      {
        path: `${ROUTES.ADMIN_SHIPMENTFORM_DETAIL}`,
        element: <EditShipmentForm />,
      },
      {
        path: `${ROUTES.ADMIN_ADMIN}`,
        element: <Admin />,
      },
      {
        path: `${ROUTES.ADMIN_CLIENT}`,

        element: <Customers />,
      },
      {
        path: `${ROUTES.ADMIN_CLIENT_INFO}`,
        element: <ClientInfo />,
      },
      {
        path: `${ROUTES.ADMIN_DRIVER}`,
        element: <Driver />,
      },
      {
        path: `${ROUTES.ADMIN_DRIVER_INFO}`,
        element: <DriverInfo />,
      },
      {
        path: `${ROUTES.ADMIN_TRANSPORTER}`,
        element: <Transporter />,
      },
      {
        path: `${ROUTES.ADMIN_TRANSPORTER_INFO}`,
        element: <TransporterInfo />,
      },
      {
        path: `${ROUTES.ADMIN_VEHICLE}`,
        element: <Vehicals />,
      },
      {
        path: `${ROUTES.ADMIN_VEHICLE_INFO}`,
        element: <VehiclesInfo />,
      },
      {
        path: `${ROUTES.ADMIN_SITE_SITEACCESS}`,
        element: <SitesAccess />,
      },
      {
        path: `${ROUTES.ADMIN_SHIPMENT}`,
        element: <Trip />,
      },
      {
        path: `${ROUTES.ADMIN_EMISSION_DASHBOARD}`,
        element: <Dashboard />,
      },
      {
        path: `${ROUTES.ADMIN_ADD_SINGLE_ENTRY}`,
        element: <AddDataEntry />,
      },
      {
        path: `${ROUTES.ADMIN_ADD_SINGLE_ENTRY}`,
        element: <AddDataEntry />,
      },
      {
        path: `${ROUTES.ADMIN_BULK_UPLOAD_VIEW}`,
        element: <BulkUploadView />,
      },
      {
        path: `${ROUTES.ADMIN_BULK_ONBOARD_VIEW}`,
        element: <BulkOnboardView />,
      },
      {
        path: `${ROUTES.ADMIN_GENERATE_REPORT_FOR_EMISSION}`,
        element: <GenerateReportForEmission />,
      },
      {
        path: `${ROUTES.ADMIN_DOWNLOAD_REPORT_FOR_EMISSION}`,
        element: <DownloadReportForEmission />,
      },
      {
        path: `${ROUTES.ADMIN_CATEGORY}`,
        element: <Categorys />,
      },
      {
        path: `${ROUTES.ADMIN_ATTRIBUTE}`,
        element: <Attributes />,
      },
      {
        path: `${ROUTES.TEMP}`,
        element: <Temp />,
      },
      {
        path: `${ROUTES.ADMIN_CATEGORY_FORM}`,
        element: <CategoryForm />,
      },
      {
        path: `${ROUTES.ADMIN_BRSR_FORM}`,
        element: <BrsrForm />,
      },
      {
        path: `${ROUTES.ADMIN_BRSR_SPOC_LIST}`,
        element: <SpocsList />,
      },
      {
        path: `${ROUTES.ADMIN_TASK_KANBAN}`,
        element: <TasksKanban />,
      },
      {
        path: `${ROUTES.ADMIN_PLANT}`,
        element: <Plants />,
      },
      {
        path: `${ROUTES.ADMIN_SITE_PACKAGE_INFO}`,
        element: <SitePackageInfo />,
      },
      {
        path: `${ROUTES.ADMIN_SITE_SITE_INFO}`,
        element: <SiteInfo />,
      },

      {
        path: `${ROUTES.ADMIN_PACKAGING}`,
        element: <Packaging />,
      },
      {
        path: `${ROUTES.ADMIN_SITE_PACKAING_INFO}`,
        element: <SitePackageInfo />,
      },
      {
        path: `${ROUTES.ADMIN_ROUTE}`,
        element: <RoutePage />,
      },
      {
        path: `${ROUTES.ADMIN_ROUTE_INFO}`,
        element: <RoutePage />,
      },
      {
        path: `${ROUTES.ADMIN_SETTING}`,
        element: <Settings />,
      },
      {
        path: `${ROUTES.ADMIN_TEAM}`,
        element: <Team />,
      },
      {
        path: `${ROUTES.ADMIN_ROLEANDACCESS}`,
        element: <RoleAccess />,
      },
      {
        path: `${ROUTES.ADMIN_USERPROFILE}`,
        element: <UserProfile />,
      },
      {
        path: `${ROUTES.ADMIN_PAGE_COMING_SOON}`,
        element: <PagesComingsoon />,
      },
      {
        path:`${ROUTES.ADMIN_ADD_EMISSION_FACTOR}`,
        element:<AddEmissionFactor/>
      }
      //   {
      //     path: `${appRoot}${ROUTES.USERS}`,
      //     element: <Users />,
      //   },

      //   {
      //     path: `${appRoot}${ROUTES.USERS_DETAIL}`,
      //     element: <UserDetail />,
      //   },
    ],
  },
  {
    path: `${clientRoute}`, //role Client
    element: <ClientLayout />,
    children: [
      //   {
      //     index: true,
      //     element: <Navigate to={`${clientRoute}`} />,
      //   },
      {
        path: ":id", // Child route with a dynamic parameter
        children: [
          {
            index: true,
            element: <ClientReport />,
          },
          //   {
          //     path: `${clientRoute}`,
          //     element: <ClientReport />,
          //   },
          {
            path: `${ROUTES.CLIENT_CHANGE_LOG}`,
            element: <ChangeLogs />,
          },
          {
            path: `${ROUTES.CLIENT_BRSR_DASHBOARD}`,
            element: <BrsrPage />,
          },
          {
            path: `${ROUTES.CLIENT_BRSR_FORM}`,
            element: <BrsrForm />,
          },
          {
            path: `${ROUTES.CLIENT_BRSR_SPOC_LIST}`,
            element: <SpocsList />,
          },
          {
            path: `${"pcfproduct/pcftab"}`,
            element: <PcfDemo />,
          },
          {
            path: `${"pcfproduct"}`,
            element: <PcfProductPage />,
          },
          {
            path: `${ROUTES.CLIENT_TRACKING}`,
            element: <ClientTrackMap />,
          },
          {
            path: `${ROUTES.CLIENT_TRACKING_DETAIL}`,
            element: <ClientTrackMap />,
          },
          {
            path: `${ROUTES.CLIENT_PACKAGING}`,
            element: <ClientPackaging />,
          },
          {
            path: `${ROUTES.CLIENT_PLANT}`,
            element: <ClientSite />,
          },
          {
            path: `${ROUTES.CLIENT_PLANT_PACKAGING_INFO}`,
            element: <ClientSitePackageInfo />,
          },
          {
            path: `${ROUTES.CLIENT_PLANT_PLANTACCESS}`,
            element: <ClientSiteAccess />,
          },
          {
            path: `${ROUTES.CLIENT_ROUTE}`,
            element: <ClientRoute />,
          },
          {
            path: `${ROUTES.CLIENT_ROLEANDACCESS}`,
            element: <ClientRoleAndAccess />,
          },
          {
            path: `${ROUTES.CLIENT_SHIPMENT}`,
            element: <ClientShipment />,
          },

          {
            path: `${ROUTES.CLIENT_EMISSION_DASHBOARD}`,
            element: <ClientEmissionDashboard />,
          },
          {
            path: `${ROUTES.CLIENT_ADD_SINGLE_ENTRY}`,
            element: <ClientAddEntry />,
          },
          {
            path: `${ROUTES.SCOPE_3_REPORT}`,
            element: <Scope3Report />,
          },
          {
            path: `${ROUTES.SCOPE_2_REPORT}`,
            element: <Scope2Report />,
          },
          {
            path: `${ROUTES.SCOPE_1_REPORT}`,
            element: <Scope1Report />,
          },
          {
            path: `${ROUTES.SCOPE_OVERALL_REPORT}`,
            element: <ScopeOverallReport />,
          },
          {
            path: `${ROUTES.BIOGENIC_EMISSION_REPORT}`,
            element: <BiogenicEmissionReport />,
          },
          {
            path: `${ROUTES.CLIENT_BULK_UPLOAD_VIEW}`,
            element: <ClientBulkUploadView />,
          },
          {
            path: `${ROUTES.CLIENT_BULK_ONBOARD_VIEW}`,
            element: <ClientBulkOnboardView />,
          },
          {
            path: `${ROUTES.CLIENT_PARTNER_ONBOARDING}`,
            element: <PartnerOnboardingForm />,
          },
          {
            path: `${ROUTES.CLIENT_MANAGE_CONNECTIONS}`,
            element: <ManageConnections />,
          },
          {
            path: `${ROUTES.CLIENT_USERPROFILE}`,
            element: <ClientProfile />,
          },
        ],
      },
    ],
  },

  {
    path: `${siteRoute}`, //role SITE
    element: <SiteLayout />,
    children: [
      //   {
      //     index: true,
      //     element: <Navigate to={`${siteRoute}`} />,
      //   },
      {
        path: ":id",
        children: [
          {
            index: true,
            element: <SiteViewerReport />,
          },
          //   {
          //     path: `${siteRoute}`,
          //     element: <SiteViewerReport />,
          //   },
          {
            path: `${ROUTES.SITEVIEWER_TRACKING}`,
            element: <SiteViewerTrackMap />,
          },
          {
            path: `${ROUTES.SITEVIEWER_TRACKING_DETAIL}`,
            element: <SiteViewerTrackMap />,
          },
          {
            path: `${ROUTES.SITEVIEWER_PACKAGING}`,
            element: <SiteViewerPackaging />,
          },
          {
            path: `${ROUTES.SITEVIEWER_ROUTE}`,
            element: <SiteViewerRoute />,
          },
          {
            path: `${ROUTES.SITEVIEWER_SHIPMENT}`,
            element: <SiteViewerShipment />,
          },
          {
            path: `${ROUTES.SITEVIEWER_USERPROFILE}`,
            element: <SiteProfile />,
          },
          {
            path: `${ROUTES.CLIENT_CHANGE_LOG}`,
            element: <ChangeLogs />,
          },
        ],
      },
    ],
  },
  {
    path: `${tripRoute}`, //role TRIPCREATOR
    element: <TripLayout />,
    children: [
      //   {
      //     index: true,
      //     element: <Navigate to={`${tripRoute}`} />,
      //   },
      {
        index: true,
        // path: "shipmentform",
        element: <ShipmentForm />,
      },
      {
        path: `${ROUTES.SHIPMENT_TRACKING}`,
        element: <TrackingShipmentMap />,
      },
      {
        path: `${ROUTES.SHIPMENT_TRACKING_DETAIL}`,
        element: <TrackingShipmentMap />,
      },
      {
        path: `${ROUTES.SHIPMENT_SHIPMENT}`,
        element: <TripRoleShipmentHistory />,
      },
      //   {
      //     path: `${tripRoute}`,
      //     element: <SiteViewerReport />,
      //   },
    ],
  },
  {
    path: `${spocRoute}`, //role SPOC
    element: <SpocLayout />,
    children: [
      {
        path: ":id",
        children: [
          {
            index: true,
            element: <Navigate to={ROUTES.SPOC_BRSR_DASHBOARD} replace />,
          },
          {
            path: `${ROUTES.SPOC_BRSR_DASHBOARD}`,
            element: <SpocBrsrPage />,
          },
          {
            path: `${ROUTES.SPOC_BRSR_FORM}`,
            element: <BrsrForm />,
          },
          {
            path: `${ROUTES.SPOC_PROFILE}`,
            element: <SpocProfile />,
          },
        ]
      }
    ],
  },

  {
    path: ROUTES.LOGOUT,
    element: <Logout />,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    element: <ForgetPassword />,
  },
  {
    path: ROUTES.REGISTER,
    element: <Register />,
  },
  {
    path: ROUTES.PAGESMAINTENANCE,
    element: <PagesMaintenance />,
  },
  {
    path: ROUTES.PAGESCOMINGSOON,
    element: <PagesComingsoon />,
  },
  {
    path: ROUTES.PAGES404,
    element: <Pages404 />,
  },
  {
    path: ROUTES.PAGES500,
    element: <Pages500 />,
  },
  {
    path: ROUTES.PAGES403,
    element: <Pages403 />,
  },
  {
    path: "/noInternet",
    element: <NoInternet />,
  },
  {
    path: ROUTES.PAGESUNAUTHORISED,
    element: <PagesUnAuthorised />,
  },
  {
    path: ROUTES.DATANOTFOUND,
    element: <Datanotfound />,
  },
  {
    path: ROUTES.LOGIN1,
    element: <Login1 />,
  },
  {
    path: ROUTES.REGISTER1,
    element: <Register1 />,
  },
  {
    path: ROUTES.RECOVERPW,
    element: <Recoverpw />,
  },
  {
    path: ROUTES.LOCKSCREEN,
    element: <LockScreen />,
  },
  //   {
  //     path: "/error",
  //     element: <Error />,
  //   },
  //   {
  //     path: "/unAuthorize",
  //     element: <UnAuthorize />,
  //   },
  {
    path: "*",
    element: <Pages404 />,
  },
];

export default ALL_ROUTES;
