import moment from "moment/moment";
import React, { createRef, useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Button, Col, Modal, Row } from "reactstrap";

import CustomModal from "../../components/Custome/CustomModal";
import Table from "../../components/Custome/table";
import useHttp from "../../components/Hook/Use-http";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getDate,
  isNonEmpty,
  replaceId,
  subTotalGen,
  totalGen,
  updatePlant,
  updateVehicleConcent,
} from "../Utility/function";
import { GoogleApiWrapper } from "google-maps-react";
import jsPDF from "jspdf";
import CONSTANT, {
  adminRoute,
  getTableData,
  StatusButton,
} from "../Utility/constnt";
import {
  MyData,
  TABLE_HEADER,
  packageSubtype,
} from "../../pages/Utility/constnt";
import BillForm from "../BillForm";
import {
  getDateAndTimeColumn,
  getMobileNumber,
  getUpperCaseText,
  getVehicleNumberText,
  getDateAndTimeColumnFor,
} from "../Utility/function";
import { tripHeaderData } from "../Utility/exportValue";
import PageTitle from "../../components/pageTitle";
import {
  allClientData,
  allDriverData,
  allPackageData,
  allPlantData,
  allTransporterData,
  allVehicleData,
  checkIsDataExist,
} from "../Utility/helperData";
import Loader from "../../components/Loader";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getShipmentStart } from "../../store/actions";
import { DeleteButton, EditButton, tripMask } from "../Utility/spread-function";
import PackageModal from "../../components/shipmentPage/packageModal";
import DateFilterCard from "../../components/shipmentPage/dateFilterCard";
import ClientShipment from "../Client/shipment";
import { ROUTES } from "../Utility/Route";
import { DatePicker } from "antd";

const LoadingContainer = () => <div>Loading...</div>;

const { RangePicker } = DatePicker;
let tripCreateHeader = [];
const Trip = (props) => {
  const [showModel, setShowModel] = useState(false);
  const [showModel_1, setShowModel_1] = useState(false);
  const [tripData, setTripData] = useState([]);
  const [actionData, setActionData] = useState({});
  const [confirm_both, setConfirm_both] = useState(false);
  const [flag, setFlag] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [plantData, setPlantData] = useState([]);
  const [vehiclesData, setVehiclesData] = useState([]);
  const [vehiclesDataRes, setVehiclesDataRes] = useState([]);
  const [driverData, setDriverData] = useState([]);
  const [packageTypeData, setPackageTypeData] = useState([]);

  const [transporterData, setTransporterData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [downloadData, setDownloadData] = useState({});
  const [ptypeData, setPtypeData] = useState("Reusable");

  const [packageOpen, setPackageOpen] = useState(false);
  const [packageData, setPackageData] = useState({});
  const [filterParams, setFilterParams] = useState("");
  const myData = useSelector((state) => state.userGetMeData);
  const [loading, setLoading] = useState(true);

  const reportTemplateRef = useRef(null);
  const API_CALL = useHttp();
  const SEARCH_API_CALL = useHttp();
  const dispatch = useDispatch();
  const getSiteData = useSelector((state) => state.plantDetailData);
  const getClientData = useSelector((state) => state.customerDetailData);
  const getShipmentData = useSelector((state) => state.shipmentDetailData);
  const getVehicleData = useSelector((state) => state.vehicleDetailData);
  const getDriverData = useSelector((state) => state.driverDetailData);
  const getTransporterData = useSelector(
    (state) => state.transporterDetailData
  );
  const getPackagingData = useSelector((state) => state.packagingDetailData);
  const navigate = useNavigate();
  const { id } = useParams();
  const packageType = [
    {
      label: `Reusable`,
      value: "Reusable",
    },
    {
      label: `Non-reusable`,
      value: "Non-Reusable",
    },
  ];

  // useEffect(() => {
  //   updateVehicleConcent();
  // }, []);

  //not admin

  useEffect(() => {
    if (localStorage.getItem("userRole") !== "admin") {
      // const tripApi = { ...CONSTANT.API.SHIPMENT.getSingleFilterTripClient };
      // tripApi.endpoint = tripApi.endpoint.replace(
      //   ":id",
      //   `${myData?.users?.id}`
      // );
      setTimeout(() => {
        if (myData?.users?.id) {
          const tripData = getShipmentData?.users?.data?.rows?.filter(
            (it) => it?.clientId == id
          );
          tripDataHandler(tripData);
        }
      }, 100);

      // myData?.users?.id && API_CALL.sendRequest(tripApi, tripDataHandler);
      // API_CALL.sendRequest(CONSTANT.API.getAllPackage, packageDataHandler);
    }
  }, [flag, myData?.users, getShipmentData]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      if (localStorage.getItem("userRole") === "admin") {
        checkIsDataExist(allDriverData)
          ? setDriverData(allDriverData)
          : driverDataHandler(getDriverData?.users, allDriverData);
        // API_CALL.sendRequest(
        //     CONSTANT.API.DRIVER.getDriver,
        //     driverDataHandler
        //   );
        checkIsDataExist(allVehicleData)
          ? setVehiclesData(allVehicleData)
          : vehiclesDataHandler(getVehicleData?.users, allVehicleData);
        //  API_CALL.sendRequest(
        //     CONSTANT.API.VEHICLE.getVehicle,
        //     vehiclesDataHandler
        //   );
        checkIsDataExist(allPlantData)
          ? setPlantData(allPlantData)
          : plantDataHandler(getSiteData?.users, allPlantData);
        //  API_CALL.sendRequest(CONSTANT.API.SITE.getPlant, plantDataHandler);

        checkIsDataExist(allClientData)
          ? setClientData(allClientData)
          : clientDataHandler(getClientData?.users, allClientData);
        //  API_CALL.sendRequest(
        //     CONSTANT.API.CLIENT.getClient,
        //     clientDataHandler
        //   );
        checkIsDataExist(allTransporterData)
          ? setTransporterData(allTransporterData)
          : transporterDataHandler(
              getTransporterData?.users,
              allTransporterData
            );
        // API_CALL.sendRequest(
        //     CONSTANT.API.TRANSPORTER.getTransporter,
        //     transporterDataHandler
        //   );
      }
      checkIsDataExist(allPackageData)
        ? setPackageTypeData(allPackageData)
        : packageDataHandler(getPackagingData?.users, allPackageData);
      // API_CALL.sendRequest(
      //     CONSTANT.API.PACKAGEING.getPackage,
      //     packageDataHandler
      //   );
      setLoading(false);
    }, 100);
  }, [
    getVehicleData,
    getDriverData,
    getSiteData,
    getClientData,
    getTransporterData,
    getPackagingData,
  ]);

  useEffect(() => {
    const apiObject = { ...CONSTANT.API.SHIPMENT.get };

    if (filterParams !== "") {
      apiObject.endpoint += filterParams;
      SEARCH_API_CALL.sendRequest(apiObject, (res) => {
        tripDataHandler(res?.data?.rows);
      });
    } else {
      setTimeout(() => {
        tripDataHandler(getShipmentData?.users?.data?.rows);
      }, 100);
    }
  }, [flag, filterParams, getShipmentData?.users?.data]);

  const driverDataHandler = (res, allDriverData) => {
    setDriverData(res?.data?.rows);
    allDriverData = res?.data?.rows;
  };
  const packageDataHandler = (res, allPackageData) => {
    setPackageTypeData(res?.data?.rows);
    allPackageData = res?.data?.rows;
  };

  const transporterDataHandler = (res, allTransporterData) => {
    setTransporterData(res?.data?.rows);
    allTransporterData = res?.data?.rows;
  };

  const vehiclesDataHandler = (res, allVehicleData) => {
    setVehiclesData(res?.data?.rows);
    setVehiclesDataRes(res?.data?.rows);
    allVehicleData = res?.data?.rows;
  };

  const plantDataHandler = (res, allPlantData) => {
    setPlantData(res?.data?.rows);
    allPlantData = res?.data?.rows;
  };

  const clientDataHandler = (res, allClientData) => {
    setClientData(res?.data?.rows);
    allClientData = res?.data?.rows;
  };

  // const goToMapPage = (trip) => {
  //   // window.location.replace(`/tracking/${trip?.id}`);
  //   return <Link to={`/tracking/${trip?.id}`}></Link>;
  // };

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      orientation: "1",
      format: [2480, 3508],
      unit: "px",
    });

    // Adding the fonts.
    doc.setFont("Inter-Regular", "normal");

    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        await doc.save("document");
      },
    });
  };
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef([]);

  const tripDataHandler = (res) => {
    hiddenFileInput.current = res?.map?.(
      // (tripDataB, i) => hiddenFileInput?.current[i] ?? createRef()
      (tripDataB, i) => hiddenFileInput?.current?.[i] ?? createRef()
    );

    (async () => {
      // const packageRes = await Services.get(
      //   CONSTANT.API.PACKAGEING.getPackage.endpoint
      // );
      let PackagingEmission = {};
      packageTypeData?.users?.data?.rows?.map((packageData) => {
        PackagingEmission = {
          ...PackagingEmission,
          [packageData?.id]: packageData?.tCO2PerShipment,
        };
      });

      setTripData(
        tripMask(
          res,
          hiddenFileInput,
          PackagingEmission,
          handleChange,
          handleGeneratePdf,
          setDownloadData,
          onPackage,
          onEditTrip,
          setShowModel_1,
          setShowModel,
          openConfirmationDeleteModal,
          `${adminRoute}/${ROUTES.ADMIN_TRACKING}/`
        )
      );
    })();
  };

  const openConfirmationDeleteModal = (tripData) => {
    setConfirm_both(true);
    setActionData(tripData);
  };

  const onEditTrip = (tripData) => {
    setActionData(tripData);

    setIsEdit(true);
  };

  const onPackage = (tripData) => {
    setPackageData(tripData);

    setPackageOpen(true);
  };

  const onDeleteTrip = () => {
    API_CALL.sendRequest(
      replaceId(CONSTANT.API.SHIPMENT.delete, actionData?.id),
      () => {
        setFlag((previous) => !previous);
        dispatch(getShipmentStart());
      },
      null,
      "Deleted successfully"
    );
  };

  const handleChange = (e, tripDatas) => {
    const payload = new FormData();

    // tripData.POD = e.target?.files[0];
    payload.append("POD", e.target.files[0]);

    (async () => {
      const fileUploaded = e.target.files[0];
      if (e.target?.files[0]) {
        API_CALL.sendRequest(
          replaceId(CONSTANT.API.SHIPMENT.update, tripDatas?.id),
          () => {
            setFlag((previous) => !previous);
            dispatch(getShipmentStart());
          },
          payload,
          "Shipment updated successfully"
        );
        //  payload.append("profilePic", e.target.files[0]);
        //  const res = await editDoctor(doctorDetails?.id, payload);
        //  if (res !== -1) {
        //    setRefresh((previous) => !previous);
        //  }
      }

      if (e.target?.files[0] === null) {
      }
    })();
  };

  const onCloseModal = () => {
    setPackageOpen(false);
    setPackageData(null);
  };

  const onSubmitForm = (payload) => {
    if (showModel_1) {
      payload = { ...payload };
    } else {
      payload = { ...actionData, ...payload };
    }

    (async () => {
      if (!parseInt(payload?.vehicleId)) {
        payload.vehicleRegistrationNumber = payload?.vehicleId;
        payload.vehicleId = payload?.vehicleId;
        // payload.vehicleId = null
      } else {
        payload.vehicleId = payload?.vehicleId;
      }
      // let i =
      //   +(+(payload.subTotal * payload.IGST) / 100).toFixed(0) +
      //   +((payload.subTotal * payload.CGST) / 100).toFixed(0) +
      //   +((payload.subTotal * payload.SGST) / 100).toFixed(0) +
      //   payload.subTotal;
      // payload.total = i;
      if (!showModel_1) {
        const ClientData = plantData.filter((e) => e.id === payload?.plantId);
        const TransporterData = vehiclesData.filter(
          (e) => e.id === payload?.vehicleId
        );
        payload.clientId = ClientData[0]?.client?.id;
        payload.transporterId = TransporterData[0]?.transporter?.id;
        payload.subTotal = payload.subTotal;
        payload.carbonEmission = payload?.carbonEmission
          ? (payload?.carbonEmission / 1000).toFixed(0)
          : 0;
        payload.subTotal = subTotalGen(
          payload.subTotal,
          payload.basicFreightAmount,
          payload.FuelSurcharges,
          payload.docetCharges,
          payload.FOV,
          payload.loadingUnloadingCharges,
          payload.collectionCharges,
          payload.delieveryCharges,
          payload.codDodCharges,
          payload.toPay,
          payload.otherCharges
        );
        payload.total = totalGen(
          payload.subTotal,
          payload.IGST,
          payload.CGST,
          payload.SGST
        );
      }

      if (actionData?.id) {
        // delete payload.startDateAndTime;
        // delete payload.targetedDateAndTime;
        if (!payload.completedDateAndTime && !showModel_1) {
          payload.completedDateAndTime = "";
        }

        API_CALL.sendRequest(
          replaceId(CONSTANT.API.SHIPMENT.update, actionData?.id),
          () => {
            setFlag((previous) => !previous);
            dispatch(getShipmentStart());
          },
          payload,
          "Shipment updated successfully"
        );
        setIsEdit(false);
      } else {
        API_CALL.sendRequest(
          CONSTANT.API.POST.addTrip,
          () => {
            setFlag((previous) => !previous);
            dispatch(getShipmentStart());
          },
          payload,
          "Shipment added successfully"
        );
      }
    })();
    // }
  };

  const openNewTab = (st) => {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = st;
    a.target = "_blank";
    a.referrerPolicy = "no-referrer";
    a.click();
  };

  useEffect(() => {
    if (localStorage.getItem("userRole") != "admin") {
      tripCreateHeader = [
        tripHeaderData?.filter(([key]) => {
          return ![
            "transporterName",
            "driverName",
            "driverPhoneNumber",
          ].includes(key);
        }),
      ];
    }
    // tripHeaderData["transporterName"] = "Transporter name";
    // tripHeaderData["driverName"] = "Driver Name";
    // tripHeaderData["driverPhoneNumber"] = "Driver Contact number";

    // tripHeaderData["vehicleNumber"] = "Vehicle Number";
    // tripHeaderData["startDateAfterCompletedDate"] = "Completion status";
  }, []);

  const handlerFormSubmission = (e) => {
    e.preventDefault();
    setFilterParams(
      `&startDate=${e.target.startDate.value}&endDate=${e.target.endDate.value}`
    );
  };

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <PageTitle pageHeading={`Shipments`} />
        {localStorage.getItem("userRole") === "admin" && (
          // <Link to={`${adminRoute}/shipmentform`}>
          <div className="d-flex justify-content-between mt-3 mb-3">
            <Button
              color="primary"
              className="btn btn-primary waves-effect waves-light "
              style={{
                zIndex: "50",
              }}
              onClick={() => {
                // window.location.assign(`/shipmentForm`);
                navigate(`${adminRoute}/shipmentform`);
              }}
            >
              Add shipment
            </Button>
            <RangePicker
              onChange={(e) => {
                if (e == null) {
                  setFilterParams("");
                } else {
                  setFilterParams(
                    `&startDate=${e?.[0]?.format(
                      "YYYY-MM-DD"
                    )}&endDate=${e?.[1]?.format("YYYY-MM-DD")}`
                  );
                }
              }}
            />
          </div>
          // </Link>
        )}
      </div>
      {API_CALL?.isLoading || loading ? (
        <Loader />
      ) : (
        <div className="page-content mt-0 pt-0 scrollSticky custom-Table">
          <Table
            title="Shipment list"
            data={getTableData(
              TABLE_HEADER?.shipment?.filter((it) => {
                return (
                  localStorage.getItem("userRole") == "admin" || it != "action"
                );
              }),
              tripData
            )}
            exportHeader={
              localStorage.getItem("userRole") !== "admin"
                ? tripCreateHeader
                : tripHeaderData
            }
            // exportValue={tripValueData(tripData)}
            excelName="Shipment_Report.csv"
          />
        </div>
      )}

      <CustomModal
        modalType="formModal"
        show={showModel}
        close={() => setShowModel(false)}
        modalTitle={isEdit ? "Edit shipment" : "Add shipment"}
        onSubmit={(data) => onSubmitForm(data)}
        data={CONSTANT.FORM_FIELDS.TRIP}
        defaultData={actionData}
        formData={false}
        isEdit={isEdit}
        changedetect={{ ptype: setPtypeData }}
        option={{
          vehicleId: vehiclesDataRes?.map((data) => {
            return {
              label: data.registrationNumber,
              value: data.id,
            };
          }),
          plantId: plantData?.map((data) => {
            return { label: data.unitName, value: data.id };
          }),
          driverId: driverData?.map((data) => {
            return { label: data.name, value: data.id };
          }),
          transporterId: transporterData?.map((data) => {
            return { label: data.transporterName, value: data.id };
          }),
          clientId: clientData?.map((data) => {
            return { label: data.name, value: data.id };
          }),
          ptype: packageType,
          subtype: packageSubtype[ptypeData],
        }}
      />
      <CustomModal
        modalType="formModal"
        show={showModel_1}
        close={() => setShowModel_1(false)}
        modalTitle="Edit shipment"
        onSubmit={(data) => onSubmitForm(data)}
        data={CONSTANT.FORM_FIELDS.TRIP_STATUS}
        defaultData={{ status: actionData?.status }}
        formData={false}
        isEdit={isEdit}
        footerHeight="140px"
      />

      {packageOpen && (
        <PackageModal
          extraValue={{
            packageData: packageData,
            packageTypeData: packageTypeData,
            onCloseModal: onCloseModal,
          }}
        />
      )}

      {confirm_both ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            onDeleteTrip();
            setConfirm_both(false);
          }}
          onCancel={() => {
            setConfirm_both(false);
          }}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}
      <div className="d-none">
        <div className="" ref={reportTemplateRef}>
          <BillForm trip={downloadData} packageList={packageTypeData} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(null)(
  GoogleApiWrapper({
    apiKey: process.env.REACT_APP_MAP_KEY,
    LoadingContainer: LoadingContainer,
    v: "3",
  })(Trip)
);
