import PcfServices from "./API/pcfService";
import CONSTANT from "./constnt";
import {
  formateToFixed,
  notifications,
  optionArrayWithSort,
  removeKeys,
  replaceId,
} from "./function";

export const multipleChanges = (
  multipleData,
  dynamicOption,
  subCategoryData,
  qualityData,
  processCategoryData,
  processSubCategoryData,
  tableAdd
) => {};

const selectField = {
  onchange: () => {},
  value: "",
  onDeSelect: () => {},
  option: [],
};

export const productTable = (el = {}) => {
  const {
    productName = "",
    weight = "",
    productCategoryId = {
      ...selectField,
    },
    productSubCategoryId = {
      ...selectField,
    },
    productCode = "",
    isNew = true,
    recordId = -1,
    onChange = () => {},
  } = el;
  return {
    isNew,
    recordId,
    data: [
      {
        id: "name",
        name: "name",
        label: "Product name",
        placeholder: "Enter product name",
        type: "text",
        required: true,
        value: productName,

        status: "",
        validate: (value) => {
          return !!value ? "" : "error";
        },
        onChange: onChange,
        isValueChange: false,
        isLoading: false,
        xl: 5,
      },
      {
        id: "weight",
        name: "weight",
        label: "Total product weight",
        placeholder: "Enter total product weight",
        helpText: "Product weight excluding packaging",
        type: "number",
        width: "10%",
        required: true,
        value: weight,
        suffix: "grams",
        status: "",
        validate: (value) => {
          return !!value ? "" : "error";
        },
        onChange: onChange,
        isValueChange: false,
        isLoading: false,
        xl: 5,
      },
      {
        id: "productCategoryId",
        name: "productCategoryId",
        key: "productCategoryId",
        label: "Product category",
        placeholder: "Enter product category",
        helpText:
          "Currently the calculator only supports a limited number of categories",
        type: "select",
        xl: 5,
        isLoading: false,
        status: "",
        validate: (value) => {
          return !!value ? "" : "error";
        },
        onChange: onChange,
        required: true,
        value: productCategoryId?.value,
        onDeSelect: productCategoryId?.onDeSelect,
        option: productCategoryId?.option,
      },
      {
        id: "productSubCategoryId",
        name: "productSubCategoryId",
        label: "Product type",
        placeholder: "Enter product type",
        type: "select",
        xl: 4,
        isLoading: false,
        status: "",
        validate: (value) => {
          return !!value ? "" : "error";
        },
        required: true,
        onChange: onChange,
        value: productSubCategoryId?.value,
        onDeSelect: productSubCategoryId?.onDeSelect,
        option: productSubCategoryId?.option,
      },
      {
        id: "productCode",
        key: "productCode",
        label: "Product code (EAN/UPC)",
        placeholder: "Enter product code",
        helpText:
          "Enter a unique identifier for your product. If none exists leave field empty",
        type: "text",

        value: productCode,
        status: "",
        validate: (value) => {
          return !!value ? "" : "error";
        },
        onChange: onChange,
        isValueChange: false,
        isLoading: false,
        xl: 5,
      },
    ],
  };
};

export const childTable = (el = {}) => {
  const {
    process = "",
    processCategoryId = {
      ...selectField,
    },
    processSubCategoryId = {
      ...selectField,
    },
    // energySourceId = {
    //   ...selectField,
    // },
    // intensityInkWHPerKg = "",
    processLocation = {
      ...selectField,
      latlog: {
        processLatitude: "",
        processLongitude: "",
      },
    },
    transportationModeId = {
      ...selectField,
    },
    isNew = true,
    recordId = -1,
    onChange = () => {},
  } = el;

  return {
    isNew,
    recordId,
    data: [
      {
        // id: "processes",
        name: "processes",
        label: "Processes",
        placeholder: "Enter processes",
        type: "text",
        fieldShow: true,
        value: process,

        status: "",
        validate: (value) => {
          return !!value ? "" : "error";
        },
        onChange: onChange,
        isValueChange: false,
        isLoading: false,
        xl: 3,
      },
      {
        id: "processCategoryId",
        name: "processCategoryId",
        label: "Process category",
        placeholder: "Enter process category",
        type: "select",
        required: true,
        isLoading: false,
        validate: (value) => {
          return !!value ? "" : "error";
        },
        onChange: onChange,
        value: processCategoryId?.value,
        // onchange: processCategoryId?.onchange,
        onDeSelect: processCategoryId?.onDeSelect,
        option: processCategoryId?.option,
        xl: 5,
      },
      {
        id: "processSubCategoryId",
        name: "processSubCategoryId",
        label: "Process",
        placeholder: "Enter process",
        type: "select",
        required: true,
        validate: (value) => {
          return !!value ? "" : "error";
        },
        isLoading: false,
        onChange: onChange,
        value: processSubCategoryId?.value,
        // onchange: processSubCategoryId?.onchange,
        onDeSelect: processSubCategoryId?.onDeSelect,
        option: processSubCategoryId?.option,
        xl: 5,
      },
      // {
      //   id: "energySourceId",
      //   name: "energySourceId",
      //   label: "Energy Source",
      //   placeholder: "Enter Energy Source",
      //   type: "select",
      //   required: true,
      //   validate: (value) => {
      //     return !!value ? "" : "error";
      //   },
      //   onChange: onChange,
      //   value: energySourceId?.value,
      //   // onchange: energySourceId?.onchange,
      //   onDeSelect: energySourceId?.onDeSelect,
      //   option: energySourceId?.option,
      //   xl: 4,
      // },
      // {
      //   id: "intensityInkWHPerKg",
      //   name: "intensityInkWHPerKg",
      //   label: "Emission Factor",
      //   placeholder: "Enter Energy Intensity",
      //   type: "text",
      //   xl: 4,
      //   required: true,
      //   value: intensityInkWHPerKg,
      //   status: "",
      //   validate: (value) => {
      //     return !!value ? "" : "error";
      //   },
      //   onChange: onChange,
      //   isValueChange: false,
      //   isLoading: false,
      //   suffix: (
      //     <span>
      //       kgco<sub>2</sub>e
      //     </span>
      //   ),
      // },
      {
        id: "processLocation",
        name: "processLocation",
        label: "Process location",
        placeholder: "Search process location",
        type: "GoogleAutoComplete",
        helpText:
          "Give an as exact location as possible (address, city or country)",
        value: processLocation?.value,
        latlog: processLocation?.latlog,
        onDeSelect: processLocation?.onDeSelect,
        option: processLocation?.option,
        status: "",
        validate: (value) => {
          return !!value ? "" : "error";
        },
        onChange: onChange,
        isValueChange: false,
        isLoading: false,
        xl: 6,
        required: true,
      },
      {
        id: "transportationModeId",
        name: "transportationModeId",
        label: "Transport mode",
        placeholder: "Enter transport mode",
        type: "select",
        helpText:
          "Emission factors are as per GHG India transport emission factors and average gross capacity across vehicle category",
        onChange: onChange,
        validate: (value) => {
          return !!value ? "" : "error";
        },
        value: transportationModeId?.value,
        // onchange: transportationModeId?.onchange,
        onDeSelect: transportationModeId?.onDeSelect,
        option: transportationModeId?.option,
        xl: 5,
        required: true,
      },
    ],
  };
};
//Alway child Process Field Table Add on Last inside the Object else show Error ===========================
export const tableArrayGen = (el = {}) => {
  const {
    name = "",
    productCategoryId = {
      ...selectField,
    },
    productSubCategoryId = {
      ...selectField,
    },
    productQualityId = {
      ...selectField,
    },
    weight = "",
    supplierName = "",
    supplierLocation = {
      ...selectField,
      latlog: {
        supplierLatitude: "",
        supplierLongitude: "",
      },
    },
    transportationModeId = {
      ...selectField,
    },
    Proceess = [],
    isNew = true,
    recordId = -1,
    onchange = () => {},
  } = el;

  return {
    isNew,
    recordId,
    onchange,
    data: [
      {
        id: "name",
        name: "name",
        label: "Part name",
        placeholder: "Enter part name",
        type: "text",
        value: name,
        onchange: onchange,
        status: "",
        validate: (value) => {
          return !!value ? "" : "error";
        },
        isValueChange: false,
        isLoading: false,
        required: true,
      },
      {
        id: "productCategoryId",
        name: "productCategoryId",
        label: "Material category",
        placeholder: "Enter material category",
        type: "select",
        isLoading: false,
        required: true,
        onchange: onchange,
        status: "",
        validate: (value) => {
          return !!value ? "" : "error";
        },
        value: productCategoryId?.value,
        // onchange: productCategoryId?.onchange,
        onDeSelect: productCategoryId?.onDeSelect,
        option: productCategoryId?.option,
      },
      {
        id: "productSubCategoryId",
        name: "productSubCategoryId",
        label: "Sub category",
        placeholder: "Enter sub category",
        type: "select",
        onchange: onchange,
        status: "",
        validate: (value) => {
          return !!value ? "" : "error";
        },
        isLoading: false,
        required: true,
        value: productSubCategoryId?.value,
        // onchange: productSubCategoryId?.onchange,
        onDeSelect: productSubCategoryId?.onDeSelect,
        option: productSubCategoryId?.option,
      },
      {
        id: "productQualityId",
        name: "productQualityId",
        label: "Quality",
        placeholder: "Enter quality",
        type: "select",
        onchange: onchange,
        status: "",
        validate: (value) => {
          value = undefined;

          return !!value ? "" : "error";
        },
        isLoading: false,
        required: true,
        value: productQualityId?.value,
        // onchange: productQualityId?.onchange,
        onDeSelect: productQualityId?.onDeSelect,
        option: productQualityId?.option,
      },
      {
        id: "weight",
        name: "weight",
        label: "Weight",
        placeholder: "Enter weight",
        type: "number",
        onchange: onchange,
        isLoading: false,
        required: true,
        value: weight,
        suffix: "grams",
        status: "",
        validate: (value) => {
          return !!value ? "" : "error";
        },
        isValueChange: false,
      },
      {
        id: "supplierName",
        name: "supplierName",
        label: "Supplier name",
        placeholder: "Enter supplier name",
        type: "text",
        onchange: onchange,
        isLoading: false,
        required: true,
        value: supplierName,
        status: "",
        validate: (value) => {
          return !!value ? "" : "error";
        },
        isValueChange: false,
      },
      {
        id: "supplierLocation",
        name: "supplierLocation",
        label: "Supplier location",
        placeholder: "Search supplier location",
        helpText:
          "Give an as exact location as possible (address, city or country)",
        type: "GoogleAutoComplete",
        onchange: onchange,
        latlog: supplierLocation?.latlog,
        isLoading: false,
        required: true,
        status: "",
        validate: (value) => {
          return !!value ? "" : "error";
        },
        value: supplierLocation?.value,
        // onchange: supplierLocation?.onchange,
        onDeSelect: supplierLocation?.onDeSelect,
        option: supplierLocation?.option,
      },
      {
        id: "transportationModeId",
        name: "transportationModeId",
        label: "Transport mode",
        placeholder: "Enter transport mode",
        type: "select",
        helpText:
          "Emission factors are as per GHG India transport emission factors and average gross capacity across vehicle category",
        isLoading: false,
        required: true,
        onchange: onchange,
        status: "",
        validate: (value) => {
          return !!value ? "" : "error";
        },
        value: transportationModeId?.value,
        // onchange: transportationModeId?.onchange,
        onDeSelect: transportationModeId?.onDeSelect,
        option: transportationModeId?.option,
      },
      Proceess?.map((el) => childTable(el)),
    ],
  };
};

export const packagingTable = (el = {}) => {
  const {
    name = "",
    productCategoryId = {
      ...selectField,
    },
    productSubCategoryId = {
      ...selectField,
    },
    productQualityId = {
      ...selectField,
    },
    weight = "",
    supplierName = "",
    supplierLocation = {
      ...selectField,
      latlog: {
        supplierLatitude: "",
        supplierLongitude: "",
      },
    },
    transportationModeId = {
      ...selectField,
    },
    isNew = true,
    recordId = -1,
    onChange = () => {},
  } = el;

  return {
    isNew,
    recordId,
    data: [
      {
        id: "name",
        name: "name",
        label: "Packaging name",
        placeholder: "Enter packaging name",
        type: "text",
        required: true,
        value: name,
        status: "",
        validate: (value) => {
          return !!value ? "" : "error";
        },
        onChange: onChange,
        isValueChange: false,
        isLoading: false,
        xl: 3,
      },
      {
        id: "productCategoryId",
        name: "productCategoryId",
        label: "Material category",
        placeholder: "Enter material category",
        type: "select",
        isLoading: false,
        required: true,
        status: "",
        validate: (value) => {
          return !!value ? "" : "error";
        },
        onChange: onChange,
        value: productCategoryId?.value,
        onDeSelect: productCategoryId?.onDeSelect,
        option: productCategoryId?.option,
        xl: 3,
      },
      {
        id: "productSubCategoryId",
        name: "productSubCategoryId",
        label: "Sub category",
        placeholder: "Enter sub category",
        type: "select",
        xl: 3,
        isLoading: false,
        required: true,
        status: "",
        validate: (value) => {
          return !!value ? "" : "error";
        },
        onChange: onChange,
        value: productSubCategoryId?.value,
        onDeSelect: productSubCategoryId?.onDeSelect,
        option: productSubCategoryId?.option,
      },
      {
        id: "productQualityId",
        name: "productQualityId",
        label: "Quality",
        placeholder: "Enter quality",
        type: "select",
        xl: 3,
        required: true,
        isLoading: false,
        status: "",
        validate: (value) => {
          return !!value ? "" : "error";
        },
        onChange: onChange,
        value: productQualityId?.value,
        onDeSelect: productQualityId?.onDeSelect,
        option: productQualityId?.option,
      },
      {
        id: "weight",
        name: "weight",
        label: "Weight",
        placeholder: "Enter weight",
        type: "number",
        xl: 3,
        suffix: "grams",
        required: true,
        value: weight,
        status: "",
        validate: (value) => {
          return !!value ? "" : "error";
        },
        onChange: onChange,
        isValueChange: false,
        isLoading: false,
      },
      {
        id: "supplierName",
        name: "supplierName",
        label: "Supplier name",
        placeholder: "Enter supplier name",
        type: "text",
        xl: 3,
        // required: true,
        value: supplierName,
        status: "",
        validate: (value) => {
          return !!value ? "" : "error";
        },
        onChange: onChange,
        isValueChange: false,
        isLoading: false,
      },
      {
        id: "supplierLocation",
        name: "supplierLocation",
        label: "Supplier location",
        placeholder: "Search supplier location",
        type: "GoogleAutoComplete",
        helpText:
          "Give an as exact location as possible (address, city or country)",
        xl: 3,
        required: true,
        isLoading: false,
        status: "",
        validate: (value) => {
          return !!value ? "" : "error";
        },
        onChange: onChange,
        latlog: supplierLocation?.latlog,
        value: supplierLocation?.value,
        onDeSelect: supplierLocation?.onDeSelect,
        option: supplierLocation?.option,
      },
      {
        id: "transportationModeId",
        name: "transportationModeId",
        label: "Transport mode",
        placeholder: "Enter transport mode",
        type: "select",
        helpText:
          "Emission factors are as per GHG India transport emission factors and average gross capacity across vehicle category",
        xl: 3,
        required: true,
        isLoading: false,
        status: "",
        validate: (value) => {
          return !!value ? "" : "error";
        },
        onChange: onChange,
        value: transportationModeId?.value,
        onDeSelect: transportationModeId?.onDeSelect,
        option: transportationModeId?.option,
      },
    ],
  };
};

export const productAssemblyTable = (el = {}) => {
  const {
    manufacturingLocation = {
      ...selectField,
      latlog: {
        manufacturingLatitude: "",
        manufacturingLongitude: "",
      },
    },
    energySourceId = {
      ...selectField,
    },
    energyConsumed = "",
    brandDistributionLocation = {
      ...selectField,
      latlog: {
        brandDistributionLatitude: "",
        brandDistributionLongitude: "",
      },
    },
    transportationModeId = {
      ...selectField,
    },
    isNew = true,
    recordId = -1,
    onChange = () => {},
  } = el;

  return {
    isNew,
    recordId,
    data: [
      {
        id: "manufacturingLocation",
        name: "manufacturingLocation",
        label: "Manufacturing facility location",
        placeholder: "Search manufacturing facility location",
        type: "GoogleAutoComplete",
        helpText:
          "Give an as exact location as possible (address, city or country)",
        xl: 6,
        isLoading: false,
        status: "",
        validate: (value) => {
          return !!value ? "" : "error";
        },
        required: true,
        latlog: manufacturingLocation?.latlog,
        onChange: onChange,
        value: manufacturingLocation?.value,
        onDeSelect: manufacturingLocation?.onDeSelect,
        option: manufacturingLocation?.option,
      },
      {
        id: "energySourceId",
        name: "energySourceId",
        label: "Energy source",
        placeholder: "Enter energy source",
        type: "select",
        xl: 4,
        required: true,
        isLoading: false,
        status: "",
        validate: (value) => {
          return !!value ? "" : "error";
        },
        helpText:
          "National Grid Mix - energy grid mix in the country where the production takes place",
        onChange: onChange,
        value: energySourceId?.value,
        onDeSelect: energySourceId?.onDeSelect,
        option: energySourceId?.option,
      },
      {
        id: "energyConsumed",
        name: "energyConsumed",
        label: "Total energy consumed",
        placeholder: "Enter total energy consumed",
        type: "text",
        xl: 4,
        suffix: "kWh",
        required: true,
        value: energyConsumed,
        helpText:
          "Provide energy consumption for one product as (Total energy consumption/Total products manufactured)",
        status: "",
        validate: (value) => {
          return !!value ? "" : "error";
        },
        onChange: onChange,
        isValueChange: false,
        isLoading: false,
      },
      {
        id: "brandDistributionLocation",
        name: "brandDistributionLocation",
        label: "Brand distribution center location",
        placeholder: "Search brand distributor location",
        // helpText:
        //   "Leave empty if location is the same as manufacturing facility.",
        type: "GoogleAutoComplete",
        helpText:
          "Give an as exact location as possible (address, city or country)",
        xl: 6,
        isLoading: false,
        required: true,
        status: "",
        validate: (value) => {
          return !!value ? "" : "error";
        },
        latlog: brandDistributionLocation?.latlog,
        onChange: onChange,
        value: brandDistributionLocation?.value,
        onDeSelect: brandDistributionLocation?.onDeSelect,
        option: brandDistributionLocation?.option,
      },
      {
        id: "transportationModeId",
        name: "transportationModeId",
        label: "Mode of transport",
        helpText:
          "Emission factors are as per GHG India transport emission factors and average gross capacity across vehicle category",
        placeholder: "Enter mode of transport",
        type: "select",
        xl: 4,
        required: true,
        isLoading: false,
        status: "",
        validate: (value) => {
          return !!value ? "" : "error";
        },
        onChange: onChange,
        value: transportationModeId?.value,
        onDeSelect: transportationModeId?.onDeSelect,
        option: transportationModeId?.option,
      },
    ],
  };
};

export const validationForMaterial = (productData, setTabIndex) => {
  if (!productData?.name) {
    notifications.error("Product name is required!");
    return false;
  } else if (!productData?.weight) {
    notifications.error("weight is required!");
    return false;
  } else {
    return true;
  }
};
export const materialContinuePayload = (
  setTableData,
  finalPayload,
  mainProductData,
  API,
  navigate,
  currentPath,
  setTabIndex
) => {
  const payload = [];

  setTableData((prevtableData) => {
    let isError = false;
    prevtableData?.forEach((el, index) => {
      let partObj = { ["processData"]: [] };

      //material is neww el?.isNew
      el?.data?.map((partData, partIndex) => {
        if (partIndex == el?.data?.length - 1) {
          partData?.map((ell, inx) => {
            let processObj = {};
            //process isnew ell?.isNew
            ell?.data?.map((processData, processIndex) => {
              if (processData?.id) {
                if (processData?.required) {
                  if (processData?.value && processData?.value != "") {
                    processObj[processData?.id] = processData?.value || "";
                    if (processData?.latlog) {
                      const keyarr = Object?.keys(processData?.latlog);

                      processObj[keyarr[0]] = processData?.latlog[keyarr[0]];
                      processObj[keyarr[1]] = processData?.latlog[keyarr[1]];
                    }
                  } else {
                    processData.status = processData?.validate(
                      processData?.value
                    );
                    if (
                      !isError &&
                      processData?.validate(processData?.value) == "error"
                    ) {
                      isError = true;
                    }
                  }
                } else {
                  processObj[processData?.id] = processData?.value || "";
                  if (processData?.latlog) {
                    const keyarr = Object?.keys(processData?.latlog);

                    processObj[keyarr[0]] = processData?.latlog[keyarr[0]];
                    processObj[keyarr[1]] = processData?.latlog[keyarr[1]];
                  }
                }
                // processObj[processData?.id] = processData?.value || "";
              }
            });
            if (ell?.isNew && !el?.isNew) {
              if (ell?.isNew && el?.recordId != -1) {
                processObj["productMaterialId"] = el?.recordId;
              }
              processObj["intensityInkWHPerKg"] = 0;
              processObj["energySourceId"] =
                process.env.REACT_APP_PCF_ENERGY_SOURCE_ID || 11;
              // add processObj materialId here  like processObj.productMaterialId = materialId
              finalPayload.data.addProcessInMaterial.push(processObj);
            } else if (!ell?.isNew && !el?.isNew) {
              if (!ell?.isNew && ell?.recordId != -1) {
                processObj["id"] = ell?.recordId;
              }
              processObj["intensityInkWHPerKg"] = 0;
              processObj["energySourceId"] =
                process.env.REACT_APP_PCF_ENERGY_SOURCE_ID || 11;
              // add processObj processid here  like processObj.id = processid
              finalPayload.data.updateProcess.push(processObj);
            }

            if (el?.isNew && el?.isNew) {
              processObj["intensityInkWHPerKg"] = 0;
              processObj["energySourceId"] =
                process.env.REACT_APP_PCF_ENERGY_SOURCE_ID || 11;
              partObj["processData"]?.push(processObj);
            }
            // partObj["processData"]?.push(processObj);
          });
        } else {
          if (partData?.required) {
            if (partData?.value && partData?.value != "") {
              partObj[partData?.id] = partData?.value || "";
              if (partData?.latlog) {
                const keyarr = Object?.keys(partData?.latlog);

                partObj[keyarr[0]] = partData?.latlog[keyarr[0]];
                partObj[keyarr[1]] = partData?.latlog[keyarr[1]];
              }
            } else {
              partData.status = partData?.validate(partData?.value);
              if (!isError && partData?.validate(partData?.value) == "error") {
                isError = true;
              }
            }
          } else {
            partObj[partData?.id] = partData?.value || "";
            if (partData?.latlog) {
              const keyarr = Object?.keys(partData?.latlog);

              partObj[keyarr[0]] = partData?.latlog[keyarr[0]];
              partObj[keyarr[1]] = partData?.latlog[keyarr[1]];
            }
          }
        }
      });
      if (el?.isNew) {
        if (
          finalPayload &&
          finalPayload.data &&
          finalPayload.data.addMaterialWithProcess
        ) {
          finalPayload.data.addMaterialWithProcess.push(partObj);
        }
      } else {
        if (!el?.isNew && el?.recordId != -1) {
          partObj["id"] = el?.recordId;
        }
        // add partObj materialId here  like partObj.id = materialId
        finalPayload.data.updateMaterial.push(partObj);
      }
      payload?.push(partObj);
    });
    finalPayload["productId"] = mainProductData?.id;
    isError && notifications.error("Please fill up the required field");
    !isError &&
      API.sendRequest(
        CONSTANT.API.PCFPRODUCTMATERIAL.createBulkUpload,
        (res) => {
          // setMainProductData(res?.data);
          navigate(
            `${currentPath}?tab=packaging&productid=${mainProductData?.id}`
          );
          setTabIndex("3");
        },
        finalPayload,
        "Product material successfully added",
        null,
        null,
        PcfServices
      );
    return [...prevtableData];
  });

  // return isError;
};

export const materialAddNewProcess = (
  setTableData,
  partIndex,
  processCategoryData,
  energySourceData,
  transportModeData
) => {
  return setTableData((prev) => {
    const prevData = [...prev];

    const currentMaterial = prevData?.[partIndex];
    const currentMaterialProcess =
      currentMaterial?.data?.[currentMaterial?.data?.length - 1];

    const subCategoryId = currentMaterial?.data?.find(
      (elll) => elll?.id == "productSubCategoryId"
    )?.value;

    currentMaterialProcess?.push(
      childTable({
        processCategoryId: {
          option: optionArrayWithSort(
            processCategoryData?.filter(
              (ell) => ell?.productSubCategory?.id == subCategoryId
            )
          ),

          value: "",
        },
        energySourceId: {
          option: optionArrayWithSort(energySourceData),

          value: "",
        },
        transportationModeId: {
          option: optionArrayWithSort(transportModeData),

          value: "",
        },
      })
    );
    return prevData;
  });
};

export const materialGetAll = () => {};

//Result Logic===============================================

export const colorsList = [
  "#E6E7F9",
  "#CDCFF2",
  "#9B9EE5",
  "#6A6ED9",
  "#060dbf",
];
export const tooltipColorList = ["#D8B4FE", "#6EE7B7"];

export const calculateSumUntilIndex = (index, newDataMains) => {
  // Ensure index is within valid range
  if (index < 0 || index >= newDataMains?.length) {
    throw new Error("Invalid index provided");
  }

  let sum = 0;
  for (let i = 0; i <= index; i++) {
    sum += newDataMains[i].percentage;
  }

  return +sum?.toPrecision(2);
};

export const tooltipDataCalculate = (calculateData) => {
  return calculateData?.materials
    ? calculateData?.materials?.reduce((accumulator, el, index) => {
        const supplierName = el?.supplierName;
        const footPrint =
          // !!el?.transportFootprint && !!el?.materialFootprint
          // ?
          +(el?.materialFootprint + el?.transportFootprint);
        // : 0;
        // Find the existing entry for the supplier in the accumulator array
        const existingEntry = accumulator.find(
          (entry) => entry.supplierName === supplierName
        );

        if (existingEntry) {
          // If the supplier already exists, update the footPrint and percentage
          const existingPercentage = +(
            +(
              footPrint / calculateData?.["materialAcquisitionFootprint"] || 0
            ) * 100
          );
          existingEntry.footPrint += +footPrint.toFixed(0);
          existingEntry.percentage += +existingPercentage?.toFixed(2);
        } else {
          // If the supplier doesn't exist, add a new entry
          accumulator.push({
            supplierName: supplierName,
            footPrint: +footPrint.toFixed(0),
            color: tooltipColorList[accumulator?.length % 2 == 0 ? 0 : 1],
            percentage: +(
              +(
                footPrint / calculateData?.["materialAcquisitionFootprint"] || 0
              ) * 100
            )?.toFixed(2),
          });
        }
        return accumulator;
      }, [])
    : [];
};

export const newDataMainCalculation = (calculateData) => {
  return [
    {
      percentage: +(
        (calculateData?.["materialAcquisitionFootprint"] /
          calculateData?.["totalProductFootprint"]) *
        100
      )?.toFixed(0),
    },
    {
      percentage: +(
        (calculateData?.["materialProcessingFootprint"] /
          calculateData?.["totalProductFootprint"]) *
        100
      )?.toFixed(0),
    },
    {
      percentage: +(
        (calculateData?.["assemblyFootprint"] /
          calculateData?.["totalProductFootprint"]) *
        100
      )?.toFixed(0),
    },
    {
      percentage: +(
        (calculateData?.["packagingFootprint"] /
          calculateData?.["totalProductFootprint"]) *
        100
      )?.toFixed(0),
    },
    {
      percentage: +(
        (calculateData?.["distributionFootprint"] /
          calculateData?.["totalProductFootprint"]) *
        100
      )?.toFixed(0),
    },
  ];
};

export const setSeriesData = (calculateData, newDataMain) => {
  return [
    {
      data: [
        {
          x: "material acquisition",
          y: [0, calculateSumUntilIndex(0, newDataMain)],
          percentage: newDataMain[0]?.percentage,
          fill: "#fffff",
          footPrint: formateToFixed(
            +calculateData?.["materialAcquisitionFootprint"]
          ),
          // +calculateData?.["materialAcquisitionFootprint"]?.toFixed(0),
          color: colorsList[3],
          items: tooltipDataCalculate(calculateData, tooltipColorList),
        },
        {
          x: "material processing",
          y: [
            calculateSumUntilIndex(0, newDataMain),
            calculateSumUntilIndex(1, newDataMain),
          ],
          percentage: newDataMain[1]?.percentage,
          footPrint: formateToFixed(
            +calculateData?.["materialProcessingFootprint"]
          ),
          // +calculateData?.["materialProcessingFootprint"]?.toFixed(0),
        },
        {
          x: "assembly",
          y: [
            calculateSumUntilIndex(1, newDataMain),
            calculateSumUntilIndex(2, newDataMain),
          ],
          percentage: newDataMain[2]?.percentage,
          footPrint: formateToFixed(+calculateData?.["assemblyFootprint"]),
          // +calculateData?.["assemblyFootprint"]?.toFixed(0),
          color: colorsList[0],
        },
        {
          x: "packaging",
          y: [
            calculateSumUntilIndex(2, newDataMain),
            calculateSumUntilIndex(3, newDataMain),
          ],
          percentage: newDataMain[3]?.percentage,
          footPrint: formateToFixed(+calculateData?.["packagingFootprint"]),
          // +calculateData?.["packagingFootprint"]?.toFixed(0),
          color: colorsList[0],
        },
        {
          x: "distribution",
          y: [
            calculateSumUntilIndex(3, newDataMain),
            calculateSumUntilIndex(4, newDataMain),
          ],
          percentage: newDataMain[4]?.percentage,
          footPrint: formateToFixed(+calculateData?.["distributionFootprint"]),
          // +calculateData?.["distributionFootprint"]?.toFixed(0),
          color: colorsList[4],
        },
      ],
    },
  ];
};

export const generateBlocks = (items) => {
  return items
    .map((item, index) => {
      return item?.percentage < 5
        ? `
      <div style="margin: 5px 1px 5px 7px; height: ${item?.percentage}%; width: 100%;">
      <div style="height: 100%; width: 155px; background:  ${item?.color}; display: flex; align-items: center; justify-content: center; flex-direction: column;">
       
      </div>
    </div>`
        : `
      <div style="margin: 5px 1px 5px 7px; height: ${
        item?.percentage
      }%; width: 100%;">
      <div style="height: 100%; width: 155px; background:  ${
        item?.color
      }; display: flex; align-items: center; justify-content: center; flex-direction: column;">
        <p style="margin:0; ${item?.percentage < 8 ? "display:none; " : " "}">${
            item?.supplierName || ""
          }</p>
        <p style="margin:0; ${
          item?.percentage < 19 ? "display:none; " : " "
        } word-wrap: normal;
        text-wrap: wrap;">${item?.footPrint ?? 0} kgCO2e</p>
          <p style="margin:0; color: black; ${
            item?.percentage < 30 ? "display:none; " : " "
          }">( ${item?.percentage}%)</p>
      </div>
    </div>
      `;
    })
    .join("");
};

export const generateTooltipContent = (data) => {
  return `<div style="height: 240px; width: 170px; background: rgb(230, 231, 249);">
    <p style="margin-bottom: 2px; display: flex; justify-content: center;">Supplier Footprint</p>
    <div style="height: 202px; width: 170px; background: rgb(230, 231, 249);">
    ${generateBlocks(data)}

      </div>
    </div>
  </div>`;
};

export const optionForChart = () => {
  return {
    chart: {
      type: "rangeBar",
      height: 10,
      width: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 8,
        columnWidth: "100%",
        active: false,
      },
    },
    states: {
      hover: {
        filter: {
          type: "none",
          value: 0.15,
        },
      },
      active: {
        filter: {
          type: "none",
          value: 0.35,
        },
      },
    },
    chart: {
      animations: {
        enabled: false, // Disable animations for better control
      },
    },
    dataLabels: {
      enabled: false,
      formatter: function (val, opt) {
        return (
          opt.w.config.series[0]?.data[opt.dataPointIndex]["percentage"] + "%"
        );
      },
    },
    legend: {
      show: true,
      position: "top",
    },
    colors: [
      function ({ value, seriesIndex, dataPointIndex, w }) {
        return colorsList[dataPointIndex];
      },
    ],
    fill: {
      type: "solid",
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

        if (dataPointIndex === 0) {
          return generateTooltipContent(data?.items);
        }
        return "";
      },
    },
  };
};

//============================================================

//=================Material logic==========================

export const tableArrayPropertyGen = (res, setTableData, setSumOfWeight) => {
  let tableFormData = [];
  if (res?.[0]?.data?.data?.rows?.length != 0) {
    let sumOfTotalWeight = 0;

    res?.[0]?.data?.data?.rows?.forEach((el, index) => {
      sumOfTotalWeight += el?.weight;
    });
    setSumOfWeight(sumOfTotalWeight);
    res?.[0]?.data?.data?.rows?.map((matData, index) => {
      let processTableFormData = [];
      // const arrSort = matData?.productProcessings?.sort((a, b) => a.id - b.id);
      matData?.productProcessings
        ?.sort((a, b) => a.id - b.id)
        ?.map((proccesData) => {
          processTableFormData?.push({
            isNew: false,
            recordId: proccesData?.id,
            process: "",
            processCategoryId: {
              option: optionArrayWithSort(
                res?.[3]?.data?.data?.rows?.filter(
                  (elll) =>
                    elll?.productSubCategory?.id ==
                    matData?.productSubCategoryId
                )
              ),
              value: proccesData?.processCategory?.id || "",
            },
            processSubCategoryId: {
              option: optionArrayWithSort(
                res?.[5]?.data?.data?.rows?.filter(
                  (elll) =>
                    elll?.processCategory?.id ==
                    proccesData?.processCategory?.id
                )
              ),
              value: proccesData?.processSubCategory?.id || "",
            },
            energySourceId: {
              option: optionArrayWithSort(res?.[6]?.data?.data?.rows),
              value: proccesData?.energySource?.id || "",
            },
            intensityInkWHPerKg: proccesData?.intensityInkWHPerKg,
            processLocation: {
              value: proccesData?.processLocation || "",
              latlog: {
                processLatitude: proccesData?.processLatitude,
                processLongitude: proccesData?.processLongitude,
              },
            },
            transportationModeId: {
              option: optionArrayWithSort(res?.[7]?.data?.data?.rows),
              value: proccesData?.transportationMode?.id || "",
            },
          });
        });

      tableFormData?.push(
        tableArrayGen({
          isNew: false,
          recordId: matData?.id,
          name: matData?.name,
          weight: matData?.weight,
          productCategoryId: {
            option: optionArrayWithSort(res?.[1]?.data?.data?.rows),
            value: matData?.productCategoryId || "",
          },
          productSubCategoryId: {
            option: optionArrayWithSort(
              res?.[4]?.data?.data?.rows?.filter(
                (ell) => ell?.productCategoryId == matData?.productCategoryId
              )
            ),
            value: matData?.productSubCategoryId || "",
          },
          supplierLocation: {
            value: matData?.supplierLocation || "",
            latlog: {
              supplierLatitude: matData?.supplierLatitude,
              supplierLongitude: matData?.supplierLongitude,
            },
          },
          productQualityId: {
            option: optionArrayWithSort(
              res?.[2]?.data?.data?.rows?.filter(
                (ell) =>
                  ell?.productSubCategoryId == matData?.productSubCategoryId
              )
            ),
            value: matData?.productQualityId || "",
          },
          supplierName: matData?.supplierName || "",

          transportationModeId: {
            option: optionArrayWithSort(res?.[7]?.data?.data?.rows),
            value: matData?.transportationModeId || "",
          },
          Proceess: processTableFormData,
        })
      );
    });

    setTableData(tableFormData);
  } else {
    setTableData([
      tableArrayGen({
        productCategoryId: {
          option: optionArrayWithSort(res?.[1]?.data?.data?.rows),
          value: "",
        },
        energySourceId: {
          option: optionArrayWithSort(res?.[6]?.data?.data?.rows),
          value: "",
        },
        transportationModeId: {
          option: optionArrayWithSort(res?.[7]?.data?.data?.rows),
          value: "",
        },
      }),
    ]);
  }
};

//===================product Assembly =========================

export const assemblyTableArrayPropertyGen = (
  res,
  assemblyData,
  setTableGenData
) => {
  if (res?.[2]?.data?.data?.rows?.length != 0) {
    setTableGenData(
      productAssemblyTable({
        isNew: false,
        recordId: assemblyData?.id,
        manufacturingLocation: {
          value: assemblyData?.manufacturingLocation,
          latlog: {
            manufacturingLatitude: assemblyData?.manufacturingLatitude,
            manufacturingLongitude: assemblyData?.manufacturingLongitude,
          },
        },
        brandDistributionLocation: {
          value: assemblyData?.brandDistributionLocation,
          latlog: {
            brandDistributionLatitude: assemblyData?.brandDistributionLatitude,
            brandDistributionLongitude:
              assemblyData?.brandDistributionLongitude,
          },
        },
        energyConsumed: assemblyData?.energyConsumed,
        energySourceId: {
          option: optionArrayWithSort(
            res?.[0]?.data?.data?.rows?.filter(
              (el) => el?.id != process.env.REACT_APP_PCF_ENERGY_SOURCE_ID
            )
          ),
          value: assemblyData?.energySourceId,
        },

        transportationModeId: {
          option: optionArrayWithSort(res?.[1]?.data?.data?.rows),
          value: assemblyData?.transportationModeId,
        },
      })
    );
  } else {
    setTableGenData(
      productAssemblyTable({
        energySourceId: {
          option: optionArrayWithSort(
            res?.[0]?.data?.data?.rows?.filter(
              (el) => el?.id != process.env.REACT_APP_PCF_ENERGY_SOURCE_ID
            )
          ),
        },
        transportationModeId: {
          option: optionArrayWithSort(res?.[1]?.data?.data?.rows),
        },
      })
    );
  }
};

export const assemblyContinueBtn = (
  setTableGenData,
  API,
  navigate,
  setTabIndex,
  mainProductData,
  currentPath
) => {
  const payload = {};

  setTableGenData((prevtableData) => {
    let isError = false;

    prevtableData?.data?.forEach((el) => {
      if (el?.required) {
        if (el?.value && el?.value != "") {
          payload[el?.id] = el?.value;

          if (el?.latlog) {
            const keyarr = Object?.keys(el?.latlog);

            payload[keyarr[0]] = el?.latlog[keyarr[0]];
            payload[keyarr[1]] = el?.latlog[keyarr[1]];
          }
        } else {
          el.status = el?.validate(el?.value);
          if (!isError && el?.validate(el?.value) == "error") {
            isError = true;
          }
        }
      } else {
        if (el?.value && el?.value != "") {
          payload[el?.id] = el?.value || "";
          if (el?.latlog) {
            const keyarr = Object?.keys(el?.latlog);

            payload[keyarr[0]] = el?.latlog[keyarr[0]];
            payload[keyarr[1]] = el?.latlog[keyarr[1]];
          }
        }
      }
    });
    payload["productId"] = mainProductData?.id;
    if (isError) {
      notifications.error("Please fill up the required fields");
    }

    if (
      prevtableData?.recordId != -1 &&
      prevtableData?.isNew == false &&
      !isError
    ) {
      //update

      API.sendRequest(
        replaceId(
          CONSTANT.API.PCFPRODUCTASSEMBLY.update,
          prevtableData?.recordId
        ),
        (res) => {
          navigate(
            `${currentPath}?tab=results&productid=${mainProductData?.id}`
          );
          setTabIndex("5");
        },
        payload,
        "Product assembly successfully updated",
        null,
        null,
        PcfServices
      );
    } else if (
      prevtableData?.recordId == -1 &&
      prevtableData?.isNew != false &&
      !isError
    ) {
      //add
      API.sendRequest(
        CONSTANT.API.PCFPRODUCTASSEMBLY.create,
        (res) => {
          navigate(
            `${currentPath}?tab=results&productid=${mainProductData?.id}`
          );
          setTabIndex("5");
        },
        payload,
        "Product assembly successfully added",
        null,
        null,
        PcfServices
      );
    }
    return { ...prevtableData };
  });
};
