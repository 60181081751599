import React from "react";
import { Container, Row, Col, Card, CardBody, CustomInput } from "reactstrap";

const MyComponent = ({ boxData }) => {
  return (
    <Container>
      <Row>
        <Col className="m-2 rounded">
          <Card
            style={{
              height: "250px",
            }}
          >
            <CardBody
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <h5
                style={{
                  fontSize: "20px",
                }}
              >
                {boxData.heading}
              </h5>
              <h6
                style={{
                  fontSize: "42px",
                  fontWeight: "600",
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: boxData.count }}></div>
              </h6>
              <p>{boxData.units}</p>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MyComponent;