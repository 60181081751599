import { Col, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import ResultTabHeaderGen from "./resultTabHeaderGen";
import ReactApexChart from "react-apexcharts";
import {
  newDataMainCalculation,
  optionForChart,
  setSeriesData,
} from "../../pages/Utility/pcfSpreadFunction";
import useHttp from "../Hook/Use-http";
import PcfServices from "../../pages/Utility/API/pcfService";
import Loader from "../Loader";
import { replaceId } from "../../pages/Utility/function";
import CONSTANT from "../../pages/Utility/constnt";
import ResultTabFooterPrGen from "./resultTabFooterPrGen";

const ProductChartModal = (props) => {
  const { title, open, onCancel, data } = props;
  const API = useHttp();
  const [calculateData, setCalculateData] = useState({});
  const [series, setSeries] = useState([]);

  useEffect(() => {
    if (open == null) return;
    if (!open?.isCompleted) return setCalculateData({});
    open?.id &&
      API.getAll(
        [
          replaceId(CONSTANT.API.PCFRESULTCALCULATION.getProductWise, open?.id)
            .endpoint,
        ],
        (res) => {
          setCalculateData(res?.[0]?.data?.data);
        },
        PcfServices
      );
  }, [open]);

  useEffect(() => {
    if (Object.keys(calculateData)?.length != 0) {
      setSeries(
        setSeriesData(calculateData, newDataMainCalculation(calculateData))
      );
    }
  }, [calculateData]);

  return (
    <>
      <Modal
        title={open?.name || ""}
        open={open}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={() => onCancel(null)}
        width={1000}
      >
        <div className=" h-525">
          {Object.keys(calculateData)?.length > 0 ? (
            <>
              <ResultTabHeaderGen
                calculateData={calculateData}
                series={series}
              />{" "}
              <Row>
                <Col xs={0} sm={0} lg={4} style={{ marginTop: "60px" }}>
                  <div className="pcf-chart-box"></div>
                </Col>
                <Col xs={24} sm={24} lg={20}>
                  <ReactApexChart
                    options={optionForChart()}
                    series={series}
                    height="310"
                    type="rangeBar"
                    className="apex-charts"
                  />
                </Col>
              </Row>
              <ResultTabFooterPrGen
                calculateData={calculateData}
                series={series}
                bottom={"10%"}
              />
            </>
          ) : (
            <>
              {API?.isLoading ? (
                <Loader />
              ) : (
                <Row
                  className="pcf-total-carbon-label d-flex align-items-center justify-content-center"
                  style={{ fontSize: "20px" }}
                >
                  Result Not Found
                </Row>
              )}
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ProductChartModal;
