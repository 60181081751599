import { call, put, takeEvery } from "redux-saga/effects";
import useHttp from "../../components/Hook/Use-http";
// Crypto Redux States
import {
  GET_USERS,
  GET_USERS_GETME_SUCCESS,
  GET_USER_PROFILE,
} from "./actionTypes";
// import {
//   getUsersSuccess,
//   getUsersFail,
//   getUserProfileSuccess,
//   getUserProfileFail,
//   getUsersDetailSuccess,
// } from "./actions";

//Include Both Helper File with needed methods
import {
  getUsers,
  getUserProfile,
  getMeUserDetail,
} from "../../helpers/fakebackend_helper";

import CONSTANT from "../../pages/Utility/constnt";
import { getUsersSuccess } from "../actions";
import { getUsersDetailSuccess } from "./actions";
import Services from "../../pages/Utility/API/service";

function* getUserGetMe() {
  // const api = useHttp();
  try {
    // const response = yield call(getMeUserDetail);
    // const response = yield fetch(
    //   process.env.REACT_APP_BASE_URL + CONSTANT.API.ADMIN.getMe.endpoint
    // );
    // const response = yield Services.get(CONSTANT.API.ADMIN.getMe.endpoint);
    // yield put({ type: GET_USERS_GETME_SUCCESS, data: "res?.data" });
    // debugger;
    // api.sendRequest(
    //   CONSTANT.API.ADMIN.getMe,
    //   (res) => put({ type: GET_USERS_GETME_SUCCESS, data: res?.data }) // getUsersDetailSuccess(res?.data)
    // );
  } catch (error) {
    console.log(error);
    // yield put(getUsersFail(error))
  }
}
// function* fetchUsers() {
//   try {
//     const response = yield call(getUsers);
//     yield put(getUsersSuccess(response));
//   } catch (error) {
//     yield put(getUsersFail(error));
//   }
// }

// function* fetchUserProfile() {
//   try {
//     const response = yield call(getUserProfile);
//     yield put(getUserProfileSuccess(response));
//   } catch (error) {
//     yield put(getUserProfileFail(error));
//   }
// }

function* GetUserDetailSaga() {
  // yield takeEvery(GET_USERS, fetchUsers);
  // yield takeEvery(GET_USER_PROFILE, fetchUserProfile);
  // yield takeEvery(GET_USERS_GETME_SUCCESS, getUserGetMe);
}

export default GetUserDetailSaga;
