import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Spinner,
} from "reactstrap";

import Services from "../Utility/API/service";
import CONSTANT, { adminRoute } from "../Utility/constnt";
import CreatableSelect from "react-select/creatable";
import CustomModal from "../../components/Custome/CustomModal";
import useHttp from "../../components/Hook/Use-http";
import MultiSelectField from "../../components/customselect.js";
import { connect, useDispatch, useSelector } from "react-redux";
import GooglePlacesAutocomplete, {
  geocodeByLatLng,
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import moment from "moment";

import Select, { components } from "react-select";
import defaultImage from "../../assets/images/UserImage.jpg";

import notify from "../Utility/coustemFunction";
import jsPDF from "jspdf";
import { GoogleApiWrapper } from "google-maps-react";
import BillForm from "../BillForm";
import "./style.css";
import {
  cardBackground,
  clientSubmitHandler,
  driverSubmitHandler,
  getFormDataType,
  getMappingDetails,
  getModelFormat,
  getModelTitle,
  getVisible,
  getVisibleDetails,
  initValue,
  mobileNumberResponseHandler,
  plantSubmitHandler,
  textColor,
  transporterSubmitHandler,
  vehicleSubmitHandler,
} from "../Utility/tripFormExtraObject";
import CustomModalMain from "../../components/Custome/CustomModalMain";
import {
  DetailsCard,
  base64ToFile,
  getCurrentIST,
  getExtraConstPayload,
  getRecordId,
  getTonneFromKg,
  getUpperCaseText,
  getVehicleNumberText,
  isNotEmpty,
  isValidVehicleNumber,
  notifications,
  replaceId,
  selectedObjGenerator,
} from "../Utility/function";
import { engineType } from "../Utility/option";
import {
  driverCardDetails,
  vehicleViewCardDetails,
} from "../Utility/models/view-details";
import { driverMapping, vehicleMapping } from "../Utility/models/mapping-model";
import {
  getDriverStart,
  getPlantStart,
  getShipmentStart,
  getTransporterStart,
  getVehicleStart,
} from "../../store/actions";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const { Option, SingleValue } = components;

const LoadingContainer = () => <div>Loading...</div>;

const getCo2 = {
  "20ft": 0.5,
  "32ft SA": 0.55,
  "32ft MA": 0.6,
  "For pickup": 0.3,
  "20ft CNG": 0.45,
};

const EditShipmentForm = (props) => {
  let tripId = window.location.pathname.split("/")[2];
  const { shipmentId } = useParams();
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [formModel, setFormModel] = useState(-1);
  const [tripForm, setTripForm] = useState(initValue);
  const navigate = useNavigate();

  const [viewModelData, setViewModelData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const [driverName, setDriverName] = useState({});
  const [vehicalNumber, setVehicalNumber] = useState({});

  const [tripResponse, setTripResponse] = useState(null);
  const [driverConcent, setDriverConcent] = useState("Loading...");
  const [downloadData, setDownloadData] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputError, setInputError] = useState(false);
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);

  const [sameTripData, setSameTripData] = useState(null);
  const [continues, setContinues] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});

  const reportTemplateRef = useRef(null);
  const API_CALL = useHttp();
  const API_CALL2 = useHttp();
  const Concent_API_CALL = useHttp();
  const location = useLocation();
  const currentPath = location.pathname;
  const dispatch = useDispatch();
  const getSiteData = useSelector((state) => state.plantDetailData);
  const getClientData = useSelector((state) => state.customerDetailData);
  const getShipmentData = useSelector((state) => state.shipmentDetailData);
  const getVehicleData = useSelector((state) => state.vehicleDetailData);
  const getDriverData = useSelector((state) => state.driverDetailData);
  const getTransporterData = useSelector(
    (state) => state.transporterDetailData
  );
  const getPackagingData = useSelector((state) => state.packagingDetailData);
  const getRouteData = useSelector((state) => state.routeDetailData);
  const [allDataLoad, setAllDataLoad] = useState({});

  const verifyHandler = (res) => {
    switch (formModel?.type) {
      case "driver":
        if (
          isNotEmpty(viewModelData?.dateOfBirthDate) &&
          isNotEmpty(viewModelData?.drivingLicenseNumber?.trim())
        ) {
          API_CALL.sendRequest(
            CONSTANT.API.DRIVER.addDriverVerifyByLicense,
            (res) => {
              if (res?.data?.Succeeded)
                setViewModelData((prev) => {
                  const vehicleData = res?.data;
                  delete vehicleData?.id;
                  const resData = res?.data?.Succeeded?.data?.result;
                  return {
                    ...prev,
                    ...resData,
                    name: resData?.name,
                    profilePic: resData?.image
                      ? base64ToFile(resData?.image, "profilePic")
                      : defaultImage,
                    dl_no: resData?.["dl_no."],
                  };
                });
              else if (res?.data?.Failed) {
                setErrorMessage(res?.data?.Failed?.statusMessage);
              }
            },
            {
              dlnumber: viewModelData?.drivingLicenseNumber?.trim(),
              dob: viewModelData?.dateOfBirthDate,
            }
          );
        } else {
          notifications.error(
            "Please enter valid date of birth and driving license number"
          );
        }
        break;
      case "transporter":
        if (isNotEmpty(viewModelData?.gstNumber?.trim())) {
          API_CALL.sendRequest(
            CONSTANT.API.TRANSPORTER.addTransporterVerifyByGst,
            (res) => {
              if (res?.data?.Succeeded)
                setViewModelData((prev) => {
                  const resData = res?.data?.Succeeded?.Gst_Details?.result;
                  return {
                    ...prev,
                    ...resData,
                    transporter_name: resData?.tradeNam,
                    registration_date: resData?.rgdt,
                    business_type: resData?.ctb,
                    business_address: resData?.pradr?.adr,
                    pradr: resData?.pradr?.adr,
                    gst_status: resData?.sts,
                    is_aadhar_verified: resData?.adhrVFlag,
                    is_field_visit_conducted: resData?.isFieldVisitConducted,
                  };
                });
              else if (res?.message?.Failed) {
                setErrorMessage(res?.message?.Failed?.errorMessage);
              }
            },
            {
              GSTN: viewModelData?.gstNumber?.trim(),
            }
          );
        } else {
          notifications.error("Please enter valid GST number");
        }

        break;
      case "vehicle":
        const VehicleNumbers = res?.VehicleNumber;
        const VehicleNumber = isValidVehicleNumber(VehicleNumbers);

        if (VehicleNumber?.isMatch) {
          API_CALL2.sendRequest(
            CONSTANT.API.VEHICLE.addVehicleVerifyByNumber,
            (res) => {
              setViewModelData((prev) => {
                const vehicleData = res?.data;
                delete vehicleData.id;
                return {
                  ...prev,
                  ...vehicleData,
                  registrationNumber: vehicleData?.registration_number,
                  unladden_weight: getTonneFromKg(vehicleData?.unladden_weight),
                  gross_vehicle_weight: getTonneFromKg(
                    vehicleData?.gross_vehicle_weight
                  ),
                  capacity: getTonneFromKg(
                    +vehicleData?.gross_vehicle_weight -
                    +vehicleData?.unladden_weight
                  ),
                  allocate: true,
                };
              });
            },
            {
              vehicleNumber: VehicleNumber?.normalizedNumber,
            }
          );
        } else {
          notifications.error("Please enter valid vehicle number");
        }
        break;

      default:
        // Handle other cases or provide a default behavior
        break;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (
      getSiteData?.users?.data?.rows &&
      getClientData?.users?.data?.rows &&
      getShipmentData?.users?.data?.rows &&
      getVehicleData?.users?.data?.rows &&
      getDriverData?.users?.data?.rows &&
      getTransporterData?.users?.data?.rows &&
      getPackagingData?.users?.data?.rows &&
      getRouteData?.users?.data?.rows
    ) {
      setTimeout(() => {
        setLoading(true);
        setAllDataLoad({
          vehicle: getVehicleData?.users?.data?.rows, //done
          driver: getDriverData?.users?.data?.rows, //done
          client: getClientData?.users?.data?.rows, //done
          route: getRouteData?.users?.data?.rows, //done
          plant: getSiteData?.users?.data?.rows, //done
          transporter: getTransporterData?.users?.data?.rows, //done
          packageData: getPackagingData?.users?.data?.rows, //done
          inTransitData: getShipmentData?.users?.data?.rows, //done
        });

        setLoading(false);
      }, 100);
    }
  }, [
    refresh,
    getSiteData,
    getClientData,
    getShipmentData,
    getVehicleData,
    getDriverData,
    getTransporterData,
    getPackagingData,
    getRouteData,
  ]);

  useEffect(() => {
    if (!shipmentId) return;

    const findTrip = allDataLoad?.inTransitData?.find(
      (el) => el?.id == shipmentId
    );
    if (!findTrip) return;

    const clientData = allDataLoad?.client?.find(
      (data) => data?.id == findTrip?.clientId
    );
    const plantData = allDataLoad?.plant?.find(
      (data) => data?.id == findTrip?.plantId
    );
    const routeData = allDataLoad?.route?.find(
      (data) => data?.id == findTrip?.routeId
    );
    const transporterData = allDataLoad?.transporter?.find(
      (data) => data?.id == findTrip?.transporterId
    );
    const vehicleData = allDataLoad?.vehicle?.find(
      (data) => data?.id == findTrip?.vehicleId
    );
    const driverData = allDataLoad?.driver?.find(
      (data) => data?.id == findTrip?.driverId
    );

    setSelectedValue({
      //client
      ["consignor"]: selectedObjGenerator(clientData?.name, clientData?.id),
      //site
      ["consignorLocation"]: selectedObjGenerator(
        plantData?.unitName,
        plantData?.id
      ),
      //Route
      ["Consignee"]: selectedObjGenerator(routeData?.routeName, routeData?.id),
      ["transporterId"]: selectedObjGenerator(
        transporterData?.transporterName,
        transporterData?.id
      ),
      ["VehicleNumber"]: selectedObjGenerator(
        vehicleData?.registrationNumber,
        vehicleData?.id
      ),
      ["driver"]: selectedObjGenerator(
        `${driverData?.mobile || ""} (${driverData?.name || ""})`,
        findTrip?.driverId
      ),
    });

    setContinues(true);
    findTrip?.plantId &&
      API_CALL.sendRequest(
        replaceId(
          CONSTANT.API.PACKAGEING.getSinglepackageDataSite,
          findTrip?.plantId
        ),
        (res) => {
          const arrs = [];
          const newArrForSelectedOption = [];

          res?.packageAssignedData?.map((item) => {
            arrs.push({
              label: `${item["packagingType.DropdownName"]}`,
              value: `${item["packagingType.DropdownName"]}`,
              id: `${item?.packagingTypeId}`,
            });
            const findSameValue =
              findTrip?.package &&
              findTrip?.package?.find(
                (el) => +el?.packageId == +item?.packagingTypeId
              );

            findSameValue &&
              newArrForSelectedOption?.push({
                label: `${item["packagingType.DropdownName"]}`,
                value: `${item["packagingType.DropdownName"]}`,
                id: `${findSameValue?.packageId}`,
                cvalue: findSameValue?.count,
              });
          });

          findTrip?.package &&
            setSelectedOptions((pr) => {
              const newArr = [];

              return [...pr, ...newArrForSelectedOption];
            });
          setSelectedValue((pr) => {
            return { ...pr, ["reusable"]: arrs };
          });

          // setReusableData(arrs);
        }
      );

    packageDatastore(findTrip);
    const resultObject = {};
    findTrip["sourceLocation"] = `${findTrip?.plant?.addressLine1}, ${findTrip?.plant?.addressLine2 ? findTrip?.plant?.addressLine2 + "," : ""
      } ${findTrip?.plant?.city}, ${findTrip?.plant?.state}, ${findTrip?.plant?.pincode
      }. `;
    const editDataInNewObj = { ...findTrip };

    Object?.entries(findTrip)?.forEach((el) => {
      el[1] &&
        typeof el[1] === "object" &&
        el?.[1].constructor === Object &&
        delete findTrip?.[el[0]];
    });
    delete findTrip?.id;
    delete findTrip?.createdAt;
    delete findTrip?.deletedAt;
    delete findTrip?.updatedAt;
    delete findTrip?.POD;
    setTripForm((pr) => {
      return {
        ...findTrip,
      };
    });
  }, [allDataLoad?.inTransitData]);

  useEffect(() => {
    if (!shipmentId) return;
  }, [allDataLoad?.packageData]);

  const driverListByVehicle = (res) => {
    setFormModel(-1);
    setViewModelData(null);
    API_CALL.sendRequest(
      replaceId(CONSTANT.API.VEHICLE.getDriverByVehicle, res),
      driverListByVehicleResponseHandler
    );
  };

  const driverListByVehicleResponseHandler = (res) => {
    setAllDataLoad((pr) => {
      const allDriverData = [...res?.driverData, ...pr?.driver];

      allDriverData?.reduce((driverArray, driver) => {
        if (
          driver?.id &&
          driverArray?.filter((el) => el.id == driver?.id)?.length == 0
        ) {
          driverArray?.push(driver);
        }

        return [...driverArray];
      }, []);

      return { ...pr, driver: [...allDriverData] };
    });
  };

  const tripSubmitHandler = (res) => {
    setTripResponse(res?.data[0]);
    getConcent();

    setSelectedValue({});
    // setReusableData([]);
    // setNonReusableData([]);
    setSelectedOptions([]);
  };
  const toggleWarningModal = () => {
    setWarningModalOpen(!isWarningModalOpen);
  };

  const handleConfirm = () => {
    if (shipmentId) {
      API_CALL.sendRequest(
        replaceId(CONSTANT.API.SHIPMENT.update, shipmentId),
        (res) => {
          dispatch(getShipmentStart());
          navigate(`${adminRoute}/shipments`);
          // window.location.assign("/shipments");
        },
        {
          ...tripForm,
        },
        // , total: +e.target.Total.value },
        "Trip successfully updated"
      );
      // toggleWarningModal();
    } else if (!shipmentId) {
      API_CALL.sendRequest(
        CONSTANT.API.SHIPMENT.create,
        tripSubmitHandler,
        {
          ...tripForm,
        },
        // , total: +e.target.Total.value },
        "Trip successfully created"
      );
    }
  };
  const handleCancel = () => {
    // Close the warning modal
    toggleWarningModal();
  };

  const handleSubmit = (e) => {
    if (
      tripForm?.clientId &&
      tripForm?.plantId &&
      tripForm?.routeId &&
      tripForm?.transporterId &&
      tripForm?.vehicleId &&
      tripForm?.driverId &&
      tripForm?.invoiceNumber &&
      tripForm?.invoiceValue &&
      tripForm?.eWayBillNo
    ) {
      e.preventDefault();

      const flag = selectedOptions.map((item) => item?.cvalue === "");

      if (flag.includes(true) || selectedOptions?.length === 0) {
        notifications.error("Please select reusable or non-reusable");
        return;
      } else {
        toggleWarningModal();
      }
    }
  }

  const tripSubmitUpdateHandler = (res) => {
    // setTripResponse(res?.data[0]);
    // getConcent();

    setSelectedValue({});

    setSelectedOptions([]);
  };

  const getModelSubmit = {
    transporter: (payload) => {
      const extraConstPayload = getExtraConstPayload(
        viewModelData,
        getMappingDetails[formModel.type]
      );

      if (extraConstPayload?.length) {
        extraConstPayload?.map((item) => {
          payload = { ...payload, [item.id]: item.value };
        });
      }
      API_CALL.sendRequest(
        CONSTANT.API.TRANSPORTER.create,
        (res) =>
          transporterSubmitHandler(
            res,
            setFormModel,
            // setTransporter,
            setTripForm,
            setSelectedValue,
            setAllDataLoad
          ),
        payload
      );
    },
    driver: (payload) => {
      const extraConstPayload = getExtraConstPayload(
        viewModelData,
        getMappingDetails[formModel.type]
      );

      if (extraConstPayload?.length) {
        extraConstPayload?.map((item) => {
          payload.append(item?.id, item.value);
        });
      }

      API_CALL.sendRequest(
        CONSTANT.API.DRIVER.create,
        (res) =>
          driverSubmitHandler(
            res,
            setFormModel,
            // setDriver,
            setTripForm,
            setSelectedValue,
            setDriverName,
            setViewModelData,
            API_CALL,
            setAllDataLoad
          ),
        payload
      );
    },
    vehicle: (payload) => {
      const extraConstPayload = getExtraConstPayload(
        viewModelData,
        getMappingDetails[formModel.type]
      );

      if (extraConstPayload?.length) {
        extraConstPayload?.map((item) => {
          payload = { ...payload, [item.id]: item.value };
        });
      }

      API_CALL.sendRequest(
        CONSTANT.API.VEHICLE.create,
        (res) =>
          vehicleSubmitHandler(
            res,
            setFormModel,
            setViewModelData,
            // setVehicle,
            setSelectedValue,
            setTripForm,
            setAllDataLoad
          ),
        {
          ...payload,
          co2PerKm: `${getCo2[payload.length]}`,
          transporterId: `${tripForm?.transporterId}`,
          allocate: `${false}`,
          registrationNumber: payload?.VehicleNumber,
        }
      );
    },
  };

  const getConcent = () => {
    Concent_API_CALL.sendRequest(
      replaceId(CONSTANT.API.LOCATION.getLocationConsentApi, tripForm?.mobile),
      (res) => {
        setDriverConcent(res?.data?.Consent?.status);
      }
    );
  };

  const datinationFind = (request, directionsService) => {
    directionsService.route(request, function (response, status) {
      if (status === "OK") {
        const DistanceOfTrip =
          response?.routes[0]?.legs[0]?.distance?.value || 0;
        setTripForm((previous) => {
          return {
            ...previous,
            distanceOfTrip: +(DistanceOfTrip / 1000).toFixed(0),
          };
        });
      }
    });
  };

  const packageDatastore = (findTrip = null) => {
    const arr = [];
    const arr1 = [];
    const newArrForSelectedOption = [];

    if (allDataLoad?.packageData !== null) {
      allDataLoad?.packageData?.map((data) => {
        if (data?.SubType !== null) {
          if (data?.type !== "Reusable") {
            arr1.push({
              label: `${data?.DropdownName}`,
              value: `${data?.SubType}`,
              id: `${data?.id}`,
            });
            if (findTrip != null) {
              const findSameValue =
                findTrip?.package &&
                findTrip?.package?.find((el) => +el?.packageId == +data?.id);

              findSameValue &&
                newArrForSelectedOption?.push({
                  label: `${data?.DropdownName}`,
                  value: `${data?.SubType}`,
                  id: `${findSameValue?.packageId}`,
                  cvalue: findSameValue?.count,
                });
              shipmentId &&
                setSelectedOptions((pr) => {
                  return [...pr, ...newArrForSelectedOption];
                });
            }
          }
        }
      });
      setSelectedValue((pr) => {
        return { ...pr, ["nonReusable"]: arr1 };
      });

      // setNonReusableData(arr1);
    }
  };

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      orientation: "1",
      format: [2480, 3508],
      unit: "px",
    });

    // Adding the fonts.
    doc.setFont("Inter-Regular", "normal");

    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        await doc.save("document");
      },
    });
  };
  const onChange = (selectedOption) => {
    // Perform additional logic or update state
  };
  // const [selectedOptions, setSelectedOptions] = useState([]);
  const seletedTransit = allDataLoad?.driver
    ? allDataLoad?.driver?.filter((itm) =>
      allDataLoad?.inTransitData?.some(
        (dat) =>
          dat?.driverId == itm?.id && dat?.vehicleId == tripForm?.vehicleId
      )
    )
    : [];

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18"> </h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active"></li>
                </ol>
              </div>
            </div>
          </div>
        </Row>

        <Row
          className="d-flex justify-content-center"
          style={{ marginTop: "100px" }}
        >
          {currentPath?.includes(adminRoute) ? (
            <div style={{ marginLeft: "40px" }}>
              <i
                className="fa fa-arrow-left  "
                style={{
                  fontSize: "1.4rem",
                  color: "#4a5057",
                  cursor: "pointer",
                  marginRight: "10px",
                  zIndex: "50000",
                  position: "relative",
                }}
                aria-hidden="true"
                onClick={() => {
                  dispatch(getShipmentStart());
                  window.history.back();
                }}
              ></i>
            </div>
          ) : (
            <></>
          )}
          <Card style={{ zIndex: "99", minHeight: "200vh", maxWidth: "600px" }}>
            <CardBody className="p-1 m-1">
              <form
                className="needs-validation"
                method="post"
                onSubmit={(e) => {
                  e.preventDefault();

                  const flag = selectedOptions.map(
                    (item) => item?.cvalue === ""
                  );

                  if (flag.includes(true) || selectedOptions?.length === 0) {
                    notifications.error(
                      "Please select reusable or non-reusable"
                    );
                    return;
                  } else {
                    if (shipmentId) {
                      API_CALL.sendRequest(
                        replaceId(CONSTANT.API.SHIPMENT.update, shipmentId),
                        (res) => {
                          dispatch(getShipmentStart());
                          navigate(`${adminRoute}/shipments`);
                          // window.location.assign("/shipments");
                        },
                        {
                          ...tripForm,
                        },
                        // , total: +e.target.Total.value },
                        "Trip successfully updated"
                      );
                    } else if (!shipmentId) {
                      API_CALL.sendRequest(
                        CONSTANT.API.SHIPMENT.create,
                        tripSubmitHandler,
                        {
                          ...tripForm,
                        },
                        // , total: +e.target.Total.value },
                        "Trip successfully created 456"
                      );
                    }
                  }
                }}
              >
                <Row>
                  <Col md="12">
                    <div className="mb-3 pe-2">
                      {/* Consignor */}
                      {loading == false && (
                        <div className="mb-4">
                          <Label htmlFor="validationCustom01">
                            Consignor<sub style={{ color: "red" }}>*</sub>
                          </Label>

                          <Select
                            options={allDataLoad?.client?.map((data) => {
                              return {
                                label: data?.name,
                                value: data?.id,
                              };
                            })}
                            classNamePrefix="select2-selection"
                            name={"consignor"}
                            placeholder={"Select consignor "}
                            type="Select"
                            required
                            id={"consignor"}
                            value={selectedValue?.["consignor"] || null}
                            onChange={(selected) => {
                              setSameTripData(null);
                              setSelectedValue((pr) => {
                                delete pr?.["consignorLocation"];
                                delete pr?.["Consignee"];
                                // delete pr?.["transporterId"];
                                // delete pr?.["VehicleNumber"];
                                // delete pr?.["driver"];

                                return { ...pr, ["consignor"]: selected };
                              });
                              setTripForm((previous) => {
                                // delete previous?.eWayBillNo;
                                // delete previous?.invoiceNumber;
                                // delete previous?.invoiceValue;
                                delete previous?.clientId;
                                delete previous?.plantId;
                                delete previous?.routeId;
                                // delete previous?.transporterId;
                                // delete previous?.vehicleId;
                                // delete previous?.driverId;
                                delete previous?.package;
                                delete previous?.sourceLocation;

                                return {
                                  ...previous,
                                };
                              });
                              setContinues(false);
                              setTripForm((previous) => {
                                return {
                                  ...previous,
                                  clientId: selected.value,
                                };
                              });
                            }}
                          />
                        </div>
                      )}

                      {/* Consignor Location*/}
                      {tripForm?.clientId && (
                        <div className="mb-4">
                          <Label htmlFor="validationCustom01">
                            Consignor site <sub style={{ color: "red" }}>*</sub>
                          </Label>

                          <Select
                            options={allDataLoad?.plant
                              ?.filter(
                                (data) => data.clientId === tripForm?.clientId
                              )
                              ?.map((data) => {
                                return {
                                  label: data?.unitName,
                                  value: data?.id,
                                  location: data?.location,
                                  locationId: data?.locationId,
                                  ...data,
                                };
                              })}
                            classNamePrefix="select2-selection"
                            name={"consignorLocation"}
                            placeholder={"Select or create consignor location "}
                            type="SingleSelect"
                            required
                            id={"consignorLocation"}
                            value={
                              // consignorSiteSelected ||
                              selectedValue?.["consignorLocation"]
                                ? selectedValue?.["consignorLocation"]
                                : null
                            }
                            onChange={(selected) => {
                              setSelectedValue((pr) => {
                                delete pr?.["Consignee"];
                                // delete pr?.["transporterId"];
                                // delete pr?.["VehicleNumber"];
                                // delete pr?.["driver"];
                                return {
                                  ...pr,
                                  ["consignorLocation"]: selected,
                                };
                              });

                              setTripForm((previous) => {
                                delete previous?.routeId;
                                // delete previous?.transporterId;
                                // delete previous?.vehicleId;
                                // delete previous?.driverId;
                                delete previous?.package;
                                return {
                                  ...previous,
                                  plantId: selected.value,
                                  sourceLocation: `${selected?.addressLine1}, ${selected?.addressLine2
                                    ? selected?.addressLine2 + ","
                                    : ""
                                    } ${selected?.city}, ${selected?.state}, ${selected?.pincode
                                    }. `,
                                  sourceLatitude: selected?.latitude,
                                  sourceLogtitude: selected?.logtitude,
                                };
                              });
                              setSameTripData(null);
                              setContinues(false);
                              setTripForm((previous) => {
                                // delete previous?.eWayBillNo;
                                // delete previous?.invoiceNumber;
                                // delete previous?.invoiceValue;
                                return {
                                  ...previous,
                                };
                              });
                              geocodeByLatLng({
                                lat: selected?.latitude,
                                lng: selected?.logtitude,
                              })
                                .then((results) => {
                                  setTripForm((previous) => {
                                    return {
                                      ...previous,
                                      sourceId: results[0].place_id,
                                    };
                                  });
                                })
                                .catch((error) => console.error(error));
                            }}
                          />
                          {!tripForm?.plantId && (
                            <p style={{ color: "red" }}>
                              Please select consignor site
                            </p>
                          )}
                        </div>
                      )}

                      {/* Route */}
                      {tripForm?.sourceLocation && (
                        <div className="mb-4">
                          <Label htmlFor="validationCustom01">
                            Route <sub style={{ color: "red" }}>*</sub>
                          </Label>

                          <Select
                            options={allDataLoad?.route
                              ?.filter(
                                (data) => data.plantId === tripForm?.plantId
                              )
                              ?.map((data) => {
                                return {
                                  label: data?.routeName,
                                  value: data?.routeName,
                                  ...data,
                                };
                              })}
                            classNamePrefix="select2-selection"
                            name={"Consignee"}
                            placeholder={"Select or create route "}
                            type="SingleSelect"
                            required
                            id={"Consignee"}
                            value={
                              selectedValue?.["Consignee"]
                                ? selectedValue?.["Consignee"]
                                : null
                            }
                            onChange={(selected) => {
                              setSelectedValue((pr) => {
                                // delete pr?.["transporterId"];
                                // delete pr?.["VehicleNumber"];
                                // delete pr?.["driver"];
                                return { ...pr, ["Consignee"]: selected };
                              });
                              // setRouteSelected(selected);
                              // setTransporterSelected(null);
                              // setVehicalSelected(null);
                              // setDriverSelected(null);

                              //resuable and non-resuseble packging=======================
                              API_CALL.sendRequest(
                                replaceId(
                                  CONSTANT.API.PACKAGEING
                                    .getSinglepackageDataSite,
                                  tripForm?.plantId
                                ),
                                (res) => {
                                  const arrs = [];

                                  res?.packageAssignedData?.map((item) => {
                                    item["packagingType.DropdownName"] &&
                                      arrs.push({
                                        label: `${item["packagingType.DropdownName"]}`,
                                        value: `${item["packagingType.DropdownName"]}`,
                                        id: `${item?.packagingTypeId}`,
                                      });
                                  });

                                  setSelectedOptions([]);
                                  packageDatastore();
                                  setSelectedValue((pr) => {
                                    return { ...pr, ["reusable"]: arrs };
                                  });
                                  // setReusableData(arrs);
                                }
                              );

                              setSameTripData(null);
                              setContinues(false);
                              setTripForm((previous) => {
                                // delete previous?.eWayBillNo;
                                // delete previous?.invoiceNumber;
                                // delete previous?.invoiceValue;

                                // delete previous?.transporterId;
                                // delete previous?.vehicleId;
                                // delete previous?.driverId;
                                delete previous?.package;
                                return {
                                  ...previous,
                                };
                              });

                              setTripForm((previous) => {
                                return {
                                  ...previous,
                                  destinationLocation:
                                    selected?.destinationPlant?.addressLine1 +
                                    selected?.destinationPlant?.addressLine2 +
                                    selected?.destinationPlant?.city +
                                    selected?.destinationPlant?.state +
                                    ", " +
                                    selected?.destinationPlant?.pincode +
                                    ".",
                                  targetedDateAndTime: getCurrentIST(
                                    selected?.expectedCompletionTimeInHR
                                  ),
                                  destinationPlantId:
                                    selected?.destinationPlantId,
                                  distanceOfTrip: selected?.distanceOfTrip,
                                  destinationLatitude:
                                    selected?.destinationPlant?.latitude,
                                  destinationLongtitude:
                                    selected?.destinationPlant?.logtitude,
                                  weight: +selected?.freightWeightInTon,
                                  routeId: selected?.id,
                                };
                              });
                              // setDestinationCity(
                              //   selected?.destinationPlant?.city
                              // );
                              geocodeByLatLng({
                                lat: selected?.latitude,
                                lng: selected?.logtitude,
                              })
                                .then((results) => {
                                  setTripForm((previous) => {
                                    return {
                                      ...previous,
                                      destinationId: results[0].place_id,
                                    };
                                  });
                                })
                                .catch((error) => console.error(error));

                              const directionsService =
                                new props.google.maps.DirectionsService();

                              const request = {
                                origin: {
                                  lat: tripForm?.sourceLatitude,
                                  lng: tripForm?.sourceLogtitude,
                                },
                                destination: {
                                  lat: selected?.latitude,
                                  lng: selected?.logtitude,
                                },
                                travelMode: "DRIVING",
                              };

                              directionsService.route(
                                request,
                                function (response, status) {
                                  if (status === "OK") {
                                    const DistanceOfTrip =
                                      response?.routes[0]?.legs[0]?.distance
                                        ?.value || 0;

                                    setTripForm((previous) => {
                                      return {
                                        ...previous,
                                        distanceOfTrip: +(
                                          DistanceOfTrip / 1000
                                        ).toFixed(0),
                                      };
                                    });
                                  }
                                }
                              );
                            }}
                          />
                          {!tripForm?.routeId && (
                            <p style={{ color: "red" }}>Please select route</p>
                          )}
                        </div>
                      )}

                      {/*  PickUp Date & Time  */}

                      {tripForm?.routeId && (
                        <div className="mb-4">
                          <Label>
                            {" "}
                            PickUp date & time{" "}
                            <sub style={{ color: "red" }}>*</sub>
                          </Label>
                          <input
                            className="form-control"
                            type="datetime-local"
                            defaultValue={
                              shipmentId
                                ? moment(tripForm?.startDateAndTime)
                                  .utc()
                                  .format("YYYY-MM-DD HH:mm")
                                : getCurrentIST()
                            }
                            disabled
                            id="example-datetime-local-input"
                          // onChange={(date) =>
                          //   !shipmentId &&
                          //   setTripForm((previous) => {
                          //     return {
                          //       ...previous,
                          //       startDateAndTime: getCurrentIST(),
                          //     };
                          //   })
                          // }
                          />
                        </div>
                      )}

                      {/* Transporter*/}
                      {tripForm?.routeId && (
                        <div className="mb-4">
                          <Label htmlFor="validationCustom01">
                            Transporter <sub style={{ color: "red" }}>*</sub>
                          </Label>

                          <CreatableSelect
                            options={allDataLoad?.transporter?.map((data) => {
                              return {
                                label: data?.transporterName,
                                value: data?.id,
                              };
                            })}
                            classNamePrefix="select2-selection"
                            name={"transporterId"}
                            placeholder={
                              "Select or create consignor transporter "
                            }
                            type="SingleSelectWithCreate"
                            required
                            id={"transporterId"}
                            value={
                              selectedValue?.["transporterId"]
                                ? selectedValue?.["transporterId"]
                                : null
                            }
                            isValidNewOption={(inputValue) => {
                              if (
                                localStorage.getItem("userRole") !== "admin"
                              ) {
                                return null;
                              } else {
                                return inputValue;
                              }
                            }}
                            onChange={(selected) => {
                              if (selected?.__isNew__) {
                                setFormModel({
                                  type: "transporter",
                                  extra: {
                                    transporterName: selected.value,
                                  },
                                  formData: false,
                                });
                              } else {
                                setSelectedValue((pr) => {
                                  delete pr?.["VehicleNumber"];
                                  delete pr?.["driver"];
                                  return { ...pr, ["transporterId"]: selected };
                                });

                                setTripForm((previous) => {
                                  delete previous?.vehicleId;
                                  delete previous?.driverId;
                                  //   delete previous?.package;
                                  return {
                                    ...previous,
                                  };
                                });
                                setSameTripData(null);
                                setContinues(false);

                                setTripForm((previous) => {
                                  return {
                                    ...previous,
                                    transporterId: selected.value,
                                  };
                                });
                              }
                            }}
                          />
                          {!tripForm?.transporterId && (
                            <p style={{ color: "red" }}>
                              Please select transporter
                            </p>
                          )}
                        </div>
                      )}

                      {/* Vehicle Number*/}
                      {tripForm?.transporterId && (
                        <div className="mb-4">
                          <Label htmlFor="validationCustom01">
                            Vehicle number <sub style={{ color: "red" }}>*</sub>
                          </Label>

                          <CreatableSelect
                            options={allDataLoad?.vehicle
                              ?.filter(
                                (data) =>
                                  data.transporterId ===
                                  tripForm?.transporterId ||
                                  data.transporterId == null
                              )
                              ?.map((data) => {
                                return {
                                  label: data?.registrationNumber,
                                  value: data?.id,
                                  capacity: data?.capacity,
                                  co2PerKm: data?.co2PerKm,
                                };
                              })}
                            classNamePrefix="select2-selection"
                            name={"VehicleNumber"}
                            placeholder={
                              "Select or create consignor vehicle number "
                            }
                            type="SingleSelectWithCreate"
                            required
                            id={"VehicleNumber"}
                            value={
                              selectedValue?.["VehicleNumber"]
                                ? selectedValue?.["VehicleNumber"]
                                : null
                            }
                            onChange={(selected) => {
                              if (selected?.__isNew__) {
                                setFormModel({
                                  type: "vehicle",
                                  extra: {
                                    VehicleNumber: selected.value,
                                  },
                                  formData: false,
                                });
                              } else {
                                setSelectedValue((pr) => {
                                  delete pr?.["driver"];
                                  return { ...pr, ["VehicleNumber"]: selected };
                                });

                                setTripForm((previous) => {
                                  delete previous?.driverId;
                                  //   delete previous?.package;
                                  return {
                                    ...previous,
                                  };
                                });
                                driverListByVehicle(selected?.value);
                                setVehicalNumber({
                                  number: selected?.label,
                                });
                                setSameTripData(null);
                                setContinues(false);

                                setTripForm((previous) => {
                                  return {
                                    ...previous,
                                    vehicleId: selected.value,
                                    capacity: selected?.capacity,
                                    co2PerKm: selected?.co2PerKm,
                                  };
                                });
                              }
                            }}
                          />
                          {!tripForm?.vehicleId && (
                            <p style={{ color: "red" }}>
                              Please select vehicle number
                            </p>
                          )}
                        </div>
                      )}

                      {/* Driver*/}
                      {tripForm?.vehicleId && (
                        <div className="mb-4">
                          <Label htmlFor="validationCustom01">
                            Driver name <sub style={{ color: "red" }}>*</sub>
                          </Label>

                          {seletedTransit.length == 0 ? (
                            <CreatableSelect
                              options={allDataLoad?.driver
                                ?.filter(
                                  (itm) =>
                                    !allDataLoad?.inTransitData
                                      ?.filter((el) => el?.status == "2")
                                      ?.some((dat) => dat?.driverId == itm?.id)
                                )
                                ?.map((data) => {
                                  return {
                                    label: `${data?.mobile} (${data?.name})`,
                                    value: data?.id,
                                    mobile: data?.mobile,
                                    name: data?.name,
                                  };
                                })}
                              classNamePrefix="select2-selection"
                              name={"driver"}
                              placeholder={"Select or create driver "}
                              type="SingleSelect"
                              required
                              id={"driver"}
                              value={
                                selectedValue?.["driver"]
                                  ? selectedValue?.["driver"]
                                  : null
                              }
                              onChange={(selected) => {
                                if (selected?.__isNew__) {
                                  setFormModel({
                                    type: "driver",
                                    extra: {
                                      mobile: selected.value,
                                    },
                                    formData: true,
                                  });
                                  setDriverName({
                                    name: selected.name,
                                    mobile: selected.mobile,
                                  });
                                  setSelectedOptions([]);

                                  packageDatastore();
                                } else {
                                  setSelectedValue((pr) => {
                                    return { ...pr, ["driver"]: selected };
                                  });
                                  // setDriverSelected(selected);
                                  setDriverName({
                                    name: selected.name,
                                    mobile: selected.mobile,
                                  });
                                  setSameTripData(null);
                                  setTripForm((previous) => {
                                    // delete previous?.eWayBillNo;
                                    // delete previous?.invoiceNumber;
                                    // delete previous?.invoiceValue;

                                    delete previous?.package;
                                    return {
                                      ...previous,
                                    };
                                  });
                                  setContinues(true);
                                  setTripForm((previous) => {
                                    API_CALL.sendRequest(
                                      replaceId(
                                        CONSTANT.API.LOCATION
                                          .getLocationConsentApi,
                                        selected.mobile
                                      ),
                                      (res) =>
                                        mobileNumberResponseHandler(
                                          res,
                                          selected.value,
                                          API_CALL
                                        )
                                    );
                                    return {
                                      ...previous,
                                      driverId: selected.value,
                                      mobile: selected.mobile,
                                    };
                                  });
                                  setSelectedOptions([]);

                                  packageDatastore();
                                }
                              }}
                            />
                          ) : (
                            <Select
                              options={allDataLoad?.driver
                                ?.filter(
                                  (itm) =>
                                    !allDataLoad?.inTransitData
                                      ?.filter((el) => el?.status == "2")
                                      ?.some((dat) => dat?.driverId == itm?.id)
                                )
                                ?.map((data) => {
                                  return {
                                    label: `${data?.mobile} (${data?.name})`,
                                    value: data?.id,
                                    mobile: data?.mobile,
                                    name: data?.name,
                                  };
                                })}
                              classNamePrefix="select2-selection"
                              name={"driver"}
                              placeholder={"Select or create driver "}
                              type="SingleSelect"
                              required
                              id={"driver"}
                              value={
                                selectedValue?.["driver"]
                                  ? selectedValue?.["driver"]
                                  : null
                              }
                              onChange={(selected) => {
                                setSelectedValue((pr) => {
                                  return { ...pr, ["driver"]: selected };
                                });
                                // setDriverSelected(selected);
                                setDriverName({
                                  name: selected.name,
                                  mobile: selected.mobile,
                                });
                                // getSameTrip: {
                                //   endpoint: `/trip/?status=2&driverId=1&routeId=1&vehicleId=1`,
                                //   type: "GET",
                                // },

                                API_CALL.sendRequest(
                                  {
                                    type: "GET",
                                    endpoint: `${localStorage.getItem(
                                      "userRole"
                                    )}/trip/?status=2&driverId=${selected?.value
                                      }&vehicleId=${tripForm?.vehicleId}`,
                                    // &routeId=${tripForm?.routeId}
                                  },
                                  (res) => {
                                    if (
                                      Object?.keys(res?.data?.rows)?.length > 0
                                    ) {
                                      setSameTripData(res?.data?.rows);
                                    } else {
                                      setContinues(true);
                                    }
                                  }
                                );

                                setTripForm((previous) => {
                                  API_CALL.sendRequest(
                                    replaceId(
                                      CONSTANT.API.LOCATION
                                        .getLocationConsentApi,
                                      selected.mobile
                                    ),
                                    (res) =>
                                      mobileNumberResponseHandler(
                                        res,
                                        selected.value,
                                        API_CALL
                                      )
                                  );
                                  return {
                                    ...previous,
                                    driverId: selected.value,
                                    mobile: selected.mobile,
                                  };
                                });
                                setSelectedOptions([]);

                                packageDatastore();
                              }}
                            />
                          )}
                        </div>
                      )}

                      {sameTripData && (
                        <>
                          <Card>
                            <CardHeader
                              style={{ textAlign: "center", fontSize: "18px" }}
                            >
                              Driver and vehicle already exist
                            </CardHeader>
                            <h2 style={{ textAlign: "center" }}></h2>
                            <CardBody>
                              <p>
                                Shipment number:
                                <span>
                                  {sameTripData?.[0]?.id
                                    ? `CO${sameTripData?.[0]?.id + 20000000006000
                                    }`
                                    : ""}
                                </span>
                              </p>
                              <p>
                                Driver name:
                                <span>
                                  {sameTripData?.[0]?.driver?.name
                                    ? getUpperCaseText(
                                      sameTripData?.[0]?.driver?.name
                                    )
                                    : ""}
                                </span>
                              </p>
                              <p>
                                Vehicle number:
                                <span>
                                  {sameTripData?.[0]?.vehicle
                                    ?.registrationNumber
                                    ? getVehicleNumberText(
                                      sameTripData?.[0]?.vehicle
                                        ?.registrationNumber
                                    )
                                    : ""}
                                </span>
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Button
                                  style={{
                                    marginTop: "10px",
                                    background: "#2f4395",
                                    border: "0",
                                  }}
                                  onClick={() => {
                                    setTripForm(initValue);
                                    setRefresh((previous) => !previous);
                                    setTripResponse(null);
                                    setSameTripData(null);
                                    setContinues(false);
                                  }}
                                >
                                  Create another shipment
                                </Button>
                                <Button
                                  style={{
                                    marginTop: "10px",
                                    background: "white",
                                    border: "2px solid #364194",
                                    color: "black !important",
                                  }}
                                  onClick={() => {
                                    setContinues(true);
                                    // setSameTripData(null);
                                  }}
                                >
                                  <p style={{ color: "black", margin: 0 }}>
                                    Continue
                                  </p>
                                </Button>
                              </div>
                            </CardBody>

                            {/* <p>
                            Customer N:<span>123456</span>
                          </p> */}
                          </Card>
                        </>
                      )}

                      {/* Package SUB type */}
                      {tripForm?.driverId && (
                        <>
                          {" "}
                          <div className="mb-4">
                            <Label htmlFor="validationCustom01">
                              Select reusable:
                              <sub style={{ color: "red" }}>*</sub>
                            </Label>

                            <MultiSelectField
                              options={
                                selectedValue?.["reusable"] &&
                                selectedValue?.["reusable"]
                              }
                              selectedOptions={selectedOptions}
                              setSelectedOptions={setSelectedOptions}
                              setTripForm={setTripForm}
                              optionTitle={"Select reusable"}
                              type={"reusable"}
                            />
                            {!tripForm?.package && (
                              <p style={{ color: "red" }}>
                                Please select package
                              </p>
                            )}
                          </div>
                        </>
                      )}
                      {tripForm?.driverId && (
                        <div className="mb-4">
                          <Label htmlFor="validationCustom01">
                            Select non-reusable
                            <sub style={{ color: "red" }}>*</sub>
                          </Label>

                          <MultiSelectField
                            options={
                              selectedValue?.["nonReusable"] &&
                              selectedValue?.["nonReusable"]
                            }
                            optionTitle={"Select non-reusable"}
                            selectedOptions={selectedOptions}
                            setSelectedOptions={setSelectedOptions}
                            setTripForm={setTripForm}
                            type={"nonReusable"}
                          />
                          {!tripForm?.package && (
                            <p style={{ color: "red" }}>
                              Please select package
                            </p>
                          )}
                        </div>
                      )}

                      {/* Invoice Number */}
                      {tripForm?.driverId ? (
                        <div className="mb-4">
                          <Label htmlFor="validationCustom01">
                            Invoice number
                            <sub style={{ color: "red" }}>*</sub>
                          </Label>

                          <input
                            placeholder={"Invoice Number"}
                            type={"text"}
                            className="form-control"
                            required
                            id={"invoiceNumber"}
                            value={
                              tripForm?.["invoiceNumber"]
                                ? tripForm?.["invoiceNumber"]
                                : ""
                            }
                            onChange={(e) =>
                              setTripForm((previous) => {
                                return {
                                  ...previous,
                                  invoiceNumber: e.target.value,
                                };
                              })
                            }
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      {/* Invoice Value */}
                      {tripForm?.invoiceNumber && (
                        <div className="mb-4">
                          <Label htmlFor="validationCustom01">
                            Invoice value
                            <sub style={{ color: "red" }}>*</sub>
                          </Label>
                          <CreatableSelect
                            options={[
                              {
                                label: "As per invoice",
                                value: "As Per Invoice",
                              },
                            ]}
                            classNamePrefix="select2-selection"
                            name={"InvoiceValue"}
                            placeholder={"Select invoice value"}
                            type="SingleSelectWithCreate"
                            required
                            id={"InvoiceValue"}
                            value={
                              tripForm?.["invoiceValue"]
                                ? {
                                  label: tripForm?.["invoiceValue"],
                                  value: tripForm?.["invoiceValue"],
                                }
                                : selectedValue?.["InvoiceValue"]
                                  ? selectedValue?.["InvoiceValue"]
                                  : null
                            }
                            onChange={(selected) => {
                              setSelectedValue((pr) => {
                                return { ...pr, ["InvoiceValue"]: selected };
                              });
                              setTripForm((previous) => {
                                return {
                                  ...previous,
                                  invoiceValue: selected.value,
                                };
                              });
                            }}
                          />
                        </div>
                      )}

                      {/* E-way Bill Number */}
                      {tripForm?.invoiceValue ? (
                        <div className="mb-4">
                          <Label htmlFor="validationCustom01">
                            E-way bill number
                            <sub style={{ color: "red" }}>*</sub>
                          </Label>

                          <input
                            placeholder={"E-way bill number"}
                            type={"text"}
                            className="form-control"
                            required
                            id={"eWayBillNumber"}
                            value={
                              tripForm?.["eWayBillNo"]
                                ? tripForm?.["eWayBillNo"]
                                : ""
                            }
                            onChange={(e) =>
                              setTripForm((previous) => {
                                return {
                                  ...previous,
                                  eWayBillNo: e.target.value,
                                };
                              })
                            }
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                </Row>

                {tripForm?.clientId &&
                  tripForm?.plantId &&
                  tripForm?.routeId &&
                  tripForm?.transporterId &&
                  tripForm?.vehicleId &&
                  tripForm?.driverId &&
                  tripForm?.invoiceNumber &&
                  tripForm?.invoiceValue &&
                  tripForm?.eWayBillNo ? (
                  <div className="text-center mb-5">
                    <button
                      // type="submit"
                      onClick={handleSubmit}
                      className="btn btn-primary waves-effect waves-light"
                    >
                      Submit
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </form>
            </CardBody>
          </Card>
        </Row>

        {/*  Loader  */}
        <Modal
          isOpen={API_CALL.isLoading || loading}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            fontSize: "15px",
          }}
        >
          <div
            style={{
              textAlign: "center",
              paddingTop: "20px",
              paddingBottom: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "13px",
            }}
          >
            <Spinner style={{ width: "15px", height: "15px" }} type="grow" />
            {loading
              ? "Preparing form please wait..."
              : "System is working please wait"}
          </div>
        </Modal>

        <Modal
          isOpen={tripResponse != null}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            fontSize: "15px",
          }}
        >
          <div style={{ padding: "30px" }}>
            <p style={{ fontSize: "20px", color: "green" }}>
              <i class="bx bxs-badge-check"></i> Shipment created successfully{" "}
            </p>
            <p
              style={{
                textAlign: "center",
                marginTop: "28px",
                marginBottom: "0px",
              }}
            >
              Shipment Number
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "black",
                fontWeight: "700",
              }}
            >
              {getRecordId?.shipmentRecordId(6000 + tripResponse?.id)}
              {/* {`CO2${(6000 + tripResponse?.id).toString().padStart(13, "0")}`} */}
            </p>

            <p
              style={{
                textAlign: "center",
                marginTop: "10px",
                marginBottom: "28px",
              }}
            >
              Please write the shipment number on the physical bilty/docket!
            </p>

            <div
              style={{
                background: cardBackground[driverConcent],
                borderRadius: "8px",
                padding: "13px",
              }}
            >
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontSize: "18px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "500",
                  marginBottom: "16px",
                  justifyContent: "space-between",
                }}
              >
                Driver details{" "}
                <i
                  class={`bx bxl-firefox ${Concent_API_CALL.isLoading
                    ? "refreashIcon"
                    : "refreashIconNormal"
                    }`}
                  onClick={getConcent}
                ></i>
              </div>
              <p style={{ marginBottom: "0" }}>{driverName?.name} </p>
              <p style={{ marginBottom: "0" }}>+91 {driverName?.mobile}</p>
              <p style={{ marginBottom: "0", color: textColor[driverConcent] }}>
                Consent {driverConcent}
              </p>
            </div>

            <div
              style={{
                marginTop: "20px",
                display: "flex",
                gap: "9px",
              }}
            >
              <Button
                style={{
                  background: "green",
                  border: "0px",
                  width: "100%",
                }}
                onClick={() => {
                  handleGeneratePdf();
                  setDownloadData(tripResponse);
                }}
              >
                Download bilty/docket PDF
              </Button>

              <Button
                style={{
                  background: "green",
                  border: "0",
                }}
                onClick={async () => {
                  const textField = document.createElement("textarea");
                  textField.innerText =
                    `New Shipment Created \nShipment ID : ${getRecordId?.shipmentRecordId(
                      6000 + tripResponse?.id
                    )} \nVehicle Number : ${vehicalNumber.number
                      } \nDriver name : ${driverName?.name}`
                      .replace(/<br>/g, "\n")
                      .replace(/<\/?[^>]+(>|$)/g, "");
                  document.body.appendChild(textField);
                  textField.select();

                  try {
                    const successful = document.execCommand("copy");
                    const message = successful
                      ? "Text copied to clipboard!"
                      : "Unable to copy text.";
                  } catch (err) { }

                  document.body.removeChild(textField);
                }}
              >
                <i class="bx bx-copy-alt"></i>
              </Button>
            </div>

            <Button
              style={{ marginTop: "10px", background: "#2f4395", border: "0" }}
              className="w-100"
              onClick={() => {
                setTripForm(initValue);
                setRefresh((previous) => !previous);
                setTripResponse(null);
                setSameTripData(null);
                setContinues(false);
                dispatch(getShipmentStart());
              }}
            >
              Create another shipment
            </Button>
          </div>
        </Modal>

        {formModel !== -1 && (
          <CustomModalMain
            modalType="formModal"
            show={formModel !== -1}
            close={() => {
              setFormModel(-1);
              setViewModelData(null);
            }}
            // defaultData={formModel.extra}
            extraConstPayload={formModel.extra}
            // onChange={onChange}
            // minHeight={minHeight}
            option={{
              engineType,
              // transporterId: optionArray(transporter, "transporterName", "id"),
            }}
            isAutoClose={false}
            onVerifyHandler={verifyHandler}
            isSubmitButtonVisible={
              viewModelData != null
                ? getVisible[formModel.type] in viewModelData
                : false
            }
            viewModel={
              <DetailsCard
                viewModelImage={viewModelData?.image || null}
                errorMessage={errorMessage}
                data={viewModelData}
                isCardVisible={
                  viewModelData != null
                    ? getVisible?.[formModel?.type] in viewModelData
                    : false
                }
                isCardLoading={API_CALL2?.isLoading}
                details={getVisibleDetails?.[formModel?.type]}
              />
            }
            modalTitle={getModelTitle[formModel?.type]}
            onChange={(data) => {
              if (data?.dateOfBirth && formModel?.type == "driver") {
                setViewModelData((prev) => {
                  return {
                    ...prev,
                    dateOfBirthDate: moment
                      .utc(data?.dateOfBirth, "YYYY-MM-DD")
                      .format("DD-MM-YYYY"),
                  };
                });
              }

              if (data?.drivingLicense && formModel.type == "driver") {
                setViewModelData((prev) => {
                  return {
                    ...prev,
                    drivingLicenseNumber: data?.drivingLicense,
                  };
                });
              }

              if (data?.gstNumber && formModel.type == "transporter") {
                setViewModelData((prev) => {
                  return {
                    ...prev,
                    gstNumber: data?.gstNumber,
                  };
                });
              }

              if (data?.engineType && formModel.type == "vehicle") {
                setViewModelData((prev) => {
                  let objectData = {
                    co2PerKm: getCo2[data?.engineType] || 0.45,
                  };
                  if (prev) {
                    objectData = {
                      ...prev,
                      ...objectData,
                    };
                  }
                  return objectData;
                });
              }
            }}
            onSubmit={getModelSubmit[formModel.type]}
            data={getModelFormat[formModel.type]}
            formData={getFormDataType[formModel.type]}
          />
        )}
      </div>
      <div className="d-none">
        <div className="" ref={reportTemplateRef}>
          <BillForm
            trip={downloadData}
            packageList={allDataLoad?.packageData}
          />
        </div>
      </div>
      <Modal isOpen={isWarningModalOpen} toggle={toggleWarningModal}>
        <ModalHeader toggle={toggleWarningModal}>Warning</ModalHeader>
        <ModalBody>
          Are you sure you want to edit the shipment form?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleConfirm} type="submit">
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default connect(null)(
  GoogleApiWrapper({
    apiKey: process.env.REACT_APP_MAP_KEY,
    LoadingContainer: LoadingContainer,
    v: "3",
  })(EditShipmentForm)
);
