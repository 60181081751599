import React, { useEffect, useState } from "react";
import { Form, Select, Button } from "antd";
import ProductAndServiceForm from "./SectionA/ProductsandServices";
import { useParams } from "react-router-dom";
import Employees from "./SectionA/Employees";
import Principle8L from "./SectionC/Principle8/Principle8L";
import Principle8E from "./SectionC/Principle8/Principle8E";
import Principle3E from "./SectionC/Principle3/principle3E";
import Principle3L from "./SectionC/Principle3/principle3L";
import Principle5E from "./SectionC/Principle5/principle5E";
import Principle5L from "./SectionC/Principle5/principle5L";
import Principle9E from "./SectionC/Principle9/principle9E";
import Principle9L from "./SectionC/Principle9/principle9L";
import DetailsForm from "./SectionA/DetailsForm";
import SectionB from "./SectionB/sectionB";
import Operations from "./SectionA/Operations";
import HSAC from "./SectionA/HSAC";
import CSRDetails from "./SectionA/CSRDetails";
import TransparencyAndDisclosures from "./SectionA/TransparencyAndDisclosures";
import EssentialIndicators from "./SectionC/Principle1/EssentialIndicators";
import LeadershipIndicators from "./SectionC/Principle1/LeadershipIndicators";
import Principle2E from "./SectionC/Principle2/EssentialIndicators";
import Principle2L from "./SectionC/Principle2/LeadershipIndicators";
import Principle4E from "./SectionC/Principle4/EssentialIndicators";
import Principle4L from "./SectionC/Principle4/LeadershipIndicators";
import Principle6E from "./SectionC/Principle6/EssentialIndicators";
import Principle6L from "./SectionC/Principle6/LeadershipIndicators";
import Principle7E from "./SectionC/Principle7/EssentialIndicators";
import Principle7L from "./SectionC/Principle7/LeadershipIndicators";
import GrievanceRedressal from "./newBRSRForm/GrivienceRedressal";
// import GrievanceRedressal from "./newwBRSR/GrievanceRedressal";
// import GeneralDisclosures from "./newwBRSR/GeneralDisclosures";
import GeneralDisclosures from "./newBRSRForm/GeneralDisclosures";
// import CSR from "./newwBRSR/CSR";
// import Location from "./newwBRSR/Location";
// import Product from "./newwBRSR/Product";
import Location from "./newBRSRForm/Locations";
import Product from "./newBRSRForm/Productservices";
import StakeholderIdentification from "./newBRSRForm/StakeHolder";
// import StakeholderIdentification from "./newwBRSR/StakeholderIdentification";
// import GeneraDisclousers from "./newBRSRForm/GeneralDisclosures";
import CSR from "./newBRSRForm/CSR";
import HoldingsAndSubsidiaries from "./newBRSRForm/Holdings";
import EmployeeWellBeing from "./newBRSRForm/EmployeeWellBeing";
import DiversityAndInclusion from "./newBRSRForm/DiversityAndInclusion";
import NGRBCPrinciples from "./newBRSRForm/NGRBCPrinciples";
import EmployeesCount from './newBRSRForm/EmployeesCount';
import LaborManagement from './newBRSRForm/LaborManagement';
import TrainingEducation from './newBRSRForm/Training&Education';
import OccupationalHealth from './newBRSRForm/OccupationalHealth';
const { Option } = Select;

const BRSRForm = (props) => {
  const [section, setSection] = useState();
  const { id, sectionId, formId } = useParams();
  const [data, setData] = useState({ A_I: {} });
  useEffect(() => {
    setSection(sectionId);
    // console.log("id", id, sectionId);
  }, sectionId);
  const [selectedSection, setSelectedSection] = useState("");
  const [formData, setFormData] = useState({});
  const handleSectionChange = (value) => {
    setSelectedSection(value);
  };
  const handleSubmit = (values) => {
    // let sectionData = {};
    // for (let key in values) {
    //     const [questionNumber, questionKey] = key.split(':');
    //     if (!sectionData[questionNumber]) {
    //         sectionData[questionNumber] = {};
    //     }
    //     sectionData[questionNumber][questionKey] = values[key];
    // }
    // console.log('Details Form Data:', sectionData);
    // setFormData(sectionData);
  };

  return (
    <div>
      {section === "General Disclosures" && (
        <GeneralDisclosures data={data} setData={setData} />
      )}
      {section === "CSR" && <CSR data={data} setData={setData} />}
      {section === "Grivance Redressal" && <GrievanceRedressal />}
      {section === "Locations" && <Location />}
      {section === "Stakeholder Identification" && (
        <StakeholderIdentification />
      )}
      {section === "Holdings and Subsidiaries" && <HoldingsAndSubsidiaries />}
      {section === "Products and Services - General" && <Product />}
      {section === "Employees Count" && <EmployeesCount />}
      {section === "Employee Well Being" && (
        <EmployeeWellBeing />
      )}
      {section === "Diversity and Inclusion" && (
        <DiversityAndInclusion />
      )}
      {section === "Section 2 NGRBC Principles" && (
        <NGRBCPrinciples />
      )}
      {
        section === "Labormanagement relations" && (
          <LaborManagement />
        )
      }
      {
        section === "Training and education" && (
          <TrainingEducation />
        )
      }
      {
        section === "Occupational health and safety" && (
          <OccupationalHealth />
        )
      }



      {/* Remaining Sections Mappings to Components */}
      {/* "Products and Services - General"
      
      "Employee Well Being"
      "Diversity and Inclusion"
      "Labor/management relations"
      "Training and education"
      "Occupational health and safety"
      "9 Principles of NGRBC"
      "Section 2 NGRBC Principles" */}
      {/* {section === "A_I" && (
        <GeneralDisclosures data={data} setData={setData} />
      )}

      {section === "A_II" && <CSR data={data} setData={setData} />}

      {section === "A_III" && <GrievanceRedressal />}
      {section === "A_IV" && <Location />}
      {section === "A_V" && <StakeholderIdentification />}
      {section === "A_VI" && <Product/>}
      {section === "A_VII" && <HoldingsAndSubsidiaries/>}
      {section === "A_VII" && <Employees />}

      {section === "B" && <SectionB />} */}

      {/* {section == "C_P1_E" && <EssentialIndicators />}
      {section == "C_P1_L" && <LeadershipIndicators />} */}

      {/* {section == "C_P2_E" && <Principle2E />}
      {section == "C_P2_L" && <Principle2L />} */}

      {/* {section === "C_P3_E" && <Principle3E />}
      {section === "C_P3_L" && <Principle3L />} */}

      {/* {section == "C_P4_E" && <Principle4E />}
      {section == "C_P4_L" && <Principle4L />} */}

      {/* {section === "C_P5_E" && <Principle5E />}
      {section === "C_P5_L" && <Principle5L />} */}

      {/* {section == "C_P6_E" && <Principle6E />}
      {section == "C_P6_L" && <Principle6L />} */}

      {/* {section == "C_P7_E" && <Principle7E />}
      {section == "C_P7_L" && <Principle7L />} */}

      {/* {section === "C_P8_E" && <Principle8E />}
      {section === "C_P8_L" && <Principle8L />} */}

      {/* {section === "C_P9_E" && <Principle9E />}
      {section === "C_P9_L" && <Principle9L />} */}

      {/* <GeneraDisclousers /> */}
    </div>
  );
};

export default BRSRForm;
