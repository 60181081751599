import {
  GET_JOBBULK_START,
  GET_JOBBULK_SUCCESS,
  GET_JOBBULK_FAILURE,
} from "./actionTypes";

// export const getJobBulkDetailSuccess = (users) => {
//   return {
//     type: JOBBULK_DETAIL,
//   };
// };

//GET
export const getJobBulkStart = (api = null) => {
  return {
    type: GET_JOBBULK_START,
    payload: api,
  };
};
export const getJobBulkSuccess = (users) => {
  return {
    type: GET_JOBBULK_SUCCESS,
    payload: users,
  };
};
export const getJobBulkError = (error) => {
  return {
    type: GET_JOBBULK_FAILURE,
    payload: error,
  };
};
