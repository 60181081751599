import React, { useEffect, useState } from "react";
import CustomPage from "../../../components/Custome/CustomPage";
import { useSelector } from "react-redux";
import CONSTANT, { TABLE_HEADER } from "../../Utility/constnt";
import { optionArray } from "../../Utility/function";
import { routeDataMask } from "../../Utility/spread-function";
import { deleteRouteStart, getRouteStart } from "../../../store/actions";
import { routeHeaderData } from "../../Utility/exportValue";

const SiteViewerRoute = () => {
  const myData = useSelector((state) => state.userGetMeData);
  const getRouteData = useSelector((state) => state.routeDetailData);
  const getClientData = useSelector((state) => state.customerDetailData);
  const getSiteData = useSelector((state) => state.plantDetailData);

  const [siteData, setSiteData] = useState([]);
  const [routeData, setRouteData] = useState([]);

  const [selectedSourceSiteData, setSelectedSourceSiteData] = useState({});

  const [filterSiteData, setFilterSite] = useState([]);
  const [customerData, setCustomerData] = useState([]);

  useEffect(() => {
    (async () => {
      //   if (localStorage.getItem("userRole") == "admin") {
      //     siteDataHandler();
      //   }
    })();
  }, [routeData, getClientData, getSiteData]);
  return (
    <React.Fragment>
      <div className="page-content">
        <CustomPage
          PAGE={{
            title: "Route",
            dataFunction: (res, Operation) => {
              setRouteData(res);
              return routeDataMask(res, Operation);
            },
          }}
          API={{
            ...CONSTANT.API.ROUTE,
            create: null,
          }}
          REDUX={{
            getDataRedux: getRouteData?.users,
            getApiDataRedux: getRouteStart(),
            getMeDetail: myData?.users,
            deleteApiDataRedux: deleteRouteStart,
          }}
          FORM={{
            formField: CONSTANT.FORM_FIELDS?.ROUTE,
            addFormTitle: "Add route",
            editFormTitle: "Edit route",
            addNewRecordCtaText: "Add route",
            removeFieldOnEdit: ["routeType"],

            onChange: (data) => {
              if (data?.clientId) {
                setFilterSite(
                  siteData?.filter((el) => el?.clientId === data?.clientId)
                );
              }
              if (data?.plantId) {
                setSelectedSourceSiteData(
                  siteData?.filter((el) => el?.id === data?.plantId)[0]
                );
              }
            },
            option: {
              plantId: optionArray(filterSiteData, "unitName", "id"),
              destinationPlantId: optionArray(
                filterSiteData?.filter(
                  (item) => selectedSourceSiteData?.id !== item?.id
                ),
                "unitName",
                "id"
              ),
              clientId: optionArray(customerData, "name", "id"),
            },
          }}
          TABLE={{
            title: "Route list",
            header: TABLE_HEADER?.route?.filter((it) => {
              return (
                window.location.pathname.includes("/admin") || it != "action"
              );
            }),
            export: {
              fileName: "Route_Report.csv",
              header: routeHeaderData,
            },
          }}
          //redux
          getData={getRouteData?.data || []}
          //   GetSingle={`${`?clientId=${myData?.users?.clientId}`}`}
        />
      </div>
    </React.Fragment>
  );
};

export default SiteViewerRoute;
