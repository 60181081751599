import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import Chart from "chart.js";
import { Row, Col } from "reactstrap";
import CONSTANT from "../pages/Utility/constnt";
import useHttp from "./Hook/Use-http";
import { useParams } from "react-router-dom";
import { Select } from "antd";

const MultiAxisLineChart = (props) => {
  const [filledFYData, setFilledFYData] = useState(null);
  const API_CALL = useHttp();
  const { id } = useParams();
  const [metricValues, setMetricValues] = useState({});
  const [skuList, setSkuList] = useState([]);
  const [selectedSKU, setSelectedSKU] = useState("");
  useEffect(() => {
    let metricData = { ...CONSTANT.API.IntensityMetrics.get };
    metricData.endpoint = `${metricData.endpoint}/${id}`;
    API_CALL.getAll([metricData.endpoint], (res) => {
      let data = res[0]?.data?.data;
      let obj = {};
      const skuNames = new Set();
      let skulist = [];
      Object.keys(data).map((item) => {
        if (!obj[item]) {
          obj[item] = { revenue: "", items: {} };
        }
        obj[item].revenue = data?.[item]?.totalRevenue;
        let metricArr = data?.[item]?.intensityMetricSKUs;
        metricArr.forEach((sku) => {
          if (!obj[item].items[sku.name]) {
            obj[item].items[sku.name] = { quantity: 0, revenue: 0 };
          }
          obj[item].items[sku.name].quantity = sku.quantity;
          obj[item].items[sku.name].revenue = sku.revenue;
          skuNames.add(sku.name);
        });
      });
      for (const item of skuNames){
        skulist.push({
          label:item,
          value:item
        })
      }
      setSkuList(skulist);
      setFilledFYData(data);
      setMetricValues(obj);
    });
  }, []);
  const handleChange = (val) => {
    setSelectedSKU(val);
  }
  const { labels, chartData} = props;
  let unitArr = [],
    revenuArr = [];
  for (const label of labels) {
    let unitProduction = metricValues?.[label]?.items?.[selectedSKU]?.quantity;
    let unitRevenue = metricValues?.[label]?.items?.[selectedSKU]?.revenue;
    if (unitRevenue == "undefined" || unitRevenue == 0){
      unitRevenue = 1;
    }
    let revenue = metricValues?.[label]?.revenue;
    let val1 = (((unitRevenue / revenue) * parseFloat(chartData?.[label])) / unitProduction).toFixed(2) || 0;
    let val2 = (parseFloat(chartData?.[label]) / revenue).toFixed(2) || 0;
    unitArr.push(val1);
    revenuArr.push(val2);
  }
  const data = {
    labels: labels,
    // labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: `Unit based emissions intensity (tCO\u2082e)/SKU`,
        borderColor: "rgba(255, 255, 0, 1)",
        backgroundColor: "rgba(255, 255, 0, 0.2)",
        pointBorderColor: "rgba(255, 255, 0, 1)",
        pointBackgroundColor: "#fff",
        pointLabels: {
          display: false, // This will hide point labels
        },
        datalabels: {
          display: false, // This will hide data labels if using the datalabels plugin
        },
        yAxisID: "y-axis-1",
        data: unitArr,
        lineTension: 0,
      },
      {
        label: "Revenue based emissions intensity (tCO\u2082e)/'000$",
        borderColor: "rgba(0, 128, 255, 1)",
        backgroundColor: "rgba(0, 128, 255, 0.2)",
        pointBorderColor: "rgba(0, 128, 255, 1)",
        pointBackgroundColor: "#fff",
        pointLabels: {
          display: false,
        },
        datalabels: {
          display: false,
        },
        yAxisID: "y-axis-2",
        data: revenuArr,
        lineTension: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    stacked: false,
    scales: {
      yAxes: [
        {
          id: "y-axis-1",
          type: "linear",
          position: "left",
          scaleLabel: {
            display: true,
            labelString: "Units",
          },
        },
        {
          id: "y-axis-2",
          type: "linear",
          position: "right",
          gridLines: {
            drawOnChartArea: false, // only want the grid lines for one axis to show up
          },
          scaleLabel: {
            display: true,
            labelString: "Revenue in USD",
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Financial year",
          },
        },
      ],
    },
  };

  return (
    <>
      <Row>
        <Col>
        <Select
          style={{ width: '15%'}}
          placeholder = "Choose SKU"
          onChange={handleChange}
          options = {skuList}
        ></Select>
        </Col>
      </Row>
      <Row>
        <Col className="bg-white m-2">
          {data.datasets.length === options.scales.yAxes.length && (
            <Line
              data={data}
              options={options}
              style={{
                borderRadius: 20,
              }}
              height={"100%"}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default MultiAxisLineChart;
