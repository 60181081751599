import PropTypes from "prop-types";
import React, {
  useEffect,
  useRef,
  useCallback,
  Fragment,
  useState,
} from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { MyData, viewRole } from "../../pages/Utility/constnt";
//i18n
import { withTranslation } from "react-i18next";
import CONSTANT from "../../pages/Utility/constnt";
import { Menu } from "antd";
import { useSelector } from "react-redux";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    // props["location"] = location;
    // props["navigate"] = navigate;
    // props["params"] = params;

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

// function getItem(label, key, icon, children) {
//   return {
//     key,
//     icon,
//     children,
//     label,
//   };
// }

// const items = [
//   ...CONSTANT.MENU[
//     !viewRole
//       ? localStorage.getItem("userRole")
//       : Object.entries(localStorage)?.find(
//           (el) => el?.[0] == "client" || el?.[0] == "siteViewer"
//         )?.[0]
//   ][0].submenu.map((el) =>
//     getItem(
//       el.lable,
//       `${el.to}`,
//       el?.icon,
//       el.submenu &&
//         el.submenu.map((elp) =>
//           getItem(
//             elp.lable,
//             `${elp.to}`,
//             elp?.icon,
//             elp.submenu &&
//               elp.submenu.map((elpd) =>
//                 getItem(elpd?.lable, `${elpd.to}`, elpd.icon)
//               )
//           )
//         )
//     )
//   ),
// ];

const SidebarContent = (props) => {
  const ref = useRef();
  const { id } = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  let userRole = localStorage.getItem("userRole");
  const [current, setCurrent] = useState("mail");
  const getClientData = useSelector((state) => state.customerDetailData);
  let activeMenuItemIndex = CONSTANT?.MENU?.client?.findIndex((item) => {
    item?.submenu &&
      item?.submenu?.findIndex((inItem) => {
        inItem?.submenu &&
          inItem?.submenu?.findIndex((inItems) => {
            if (currentPath?.includes(":id")) {
              return currentPath == inItems?.to?.replace(":id", id);
            } else {
              return currentPath == inItems?.to;
            }
          });
        if (currentPath?.includes(":id")) {
          return currentPath == inItem?.to?.replace(":id", id);
        } else {
          return currentPath == inItem?.to;
        }
        // return currentPath == inItem?.to?.replace(":id", id);
      });

    if (currentPath?.includes(":id")) {
      return currentPath == item?.to?.replace(":id", id);
    } else {
      return currentPath == item?.to;
    }
    // return currentPath == item?.to?.replace(":id", id);
  });

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }
    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;
      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag
        const parent3 = parent2.parentElement; // li tag
        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.router.location.pathname;
    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");

      for (let i = 0; i < items.length; ++i) {
        items[i].classList.remove("active");
        items[i].classList.remove("mm-active");
        items[i].parentElement.classList.remove("mm-active");
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          // break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.router.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);
  const scrollElement = (item) => {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  };

  return (
    <React.Fragment>
      <SimpleBar ref={ref} className="vertical-simplebar">
        <div id="sidebar-menu">
          <Menu
            defaultSelectedKeys={currentPath}
            // selectedKeys={[currentPath]}
            // activeKey=""
            // defaultSelectedKeys={[current]}

            mode="inline"
            items={props?.SideBarMenu}
            onClick={(e) => {
              setCurrent(e.key);
              if (e.keyPath.length) {
                if (e.key?.includes(":id")) {
                  const newKey = e.key?.replace(":id", id);

                  navigate(newKey);
                } else {
                  navigate(e.key);
                }
              }
            }}
          />
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* {CONSTANT?.MENU[
              `${
                !viewRole
                  ? localStorage.getItem("userRole")
                  : Object.entries(localStorage)?.find(
                      (el) => el?.[0] == "client" || el?.[0] == "siteViewer"
                    )?.[0]
              }`
            ]?.map((menuItem) => {
              return (
                <Fragment key={`menuItem${menuItem?.id}`}>
                  <li className="menu-title">{props.t(menuItem?.title)} </li>
                  {menuItem?.submenu &&
                    menuItem?.submenu?.map((subMenuItem, index) => {
                      console.log(
                        window.location.href.includes(subMenuItem?.to),
                        subMenuItem?.to,
                        window.location.href
                      );
                      return (
                        <li key={`subMenuItem${index}`}>
                          <Link
                            to={subMenuItem?.to}
                            className={subMenuItem?.className}
                          >
                            <i className={subMenuItem?.icon}></i>
                            <span>{props.t(subMenuItem?.lable)}</span>
                          </Link>

                          {subMenuItem?.submenu && (
                            <ul className="sub-menu">
                              {subMenuItem?.submenu?.map(
                                (dropDownMenuItem, index) => {
                                  return (
                                    <li
                                      key={`dropDownMenuItem${
                                        dropDownMenuItem + index
                                      }`}
                                    >
                                      <Link
                                        to={dropDownMenuItem?.to}
                                        className={dropDownMenuItem?.className}
                                        // className={
                                        //   window.location.href.includes(
                                        //     dropDownMenuItem?.to
                                        //   )
                                        //     ? "active"
                                        //     : ""
                                        // }
                                      >
                                        <i
                                          className={dropDownMenuItem?.icon}
                                        ></i>
                                        <span>
                                          {props.t(dropDownMenuItem?.lable)}
                                        </span>
                                      </Link>
                                    
                                      {dropDownMenuItem?.submenu && (
                                        <ul className="sub-sub-menu">
                                          {dropDownMenuItem?.submenu?.map(
                                            (subdropDownMenuItem, index) => {
                                              console.log(
                                                window.location.href.includes(
                                                  subdropDownMenuItem?.to
                                                )
                                              );
                                              return (
                                                <li
                                                  key={`subdropDownMenuItem${
                                                    subdropDownMenuItem + index
                                                  }`}
                                                >
                                                  <Link
                                                    to={subdropDownMenuItem?.to}
                                                    className={
                                                      window.location.href.includes(
                                                        subdropDownMenuItem?.to
                                                      )
                                                        ? "active"
                                                        : ""
                                                    }
                                                  >
                                                    {props.t(
                                                      subdropDownMenuItem?.lable
                                                    )}
                                                  </Link>
                                                
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      )}
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          )}
                        </li>
                      );
                    })}
                </Fragment>
              );
            })} */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
