// import PropTypes from "prop-types";
// import React, { Component } from "react";
// import { connect } from "react-redux";
// import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

// import {
//   changeLayout,
//   changeTopbarTheme,
//   changeLayoutWidth,
// } from "../../store/actions";

// // Other Layout related Component

// import Header from "./Header";
// import Footer from "./Footer";
// import Rightbar from "../CommonForBoth/Rightbar";

// function withRouter(Component) {
//   function ComponentWithRouterProp(props) {
//     let location = useLocation();
//     let navigate = useNavigate();
//     let params = useParams();
//     return <Component {...props} router={{ location, navigate, params }} />;
//   }

//   return ComponentWithRouterProp;
// }

// class Layout extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isMenuOpened: false,
//     };
//   }

//   componentDidMount() {
//     if (this.props.isPreloader === true) {
//       document.getElementById("preloader").style.display = "block";
//       document.getElementById("status").style.display = "block";

//       setTimeout(function () {
//         document.getElementById("preloader").style.display = "none";
//         document.getElementById("status").style.display = "none";
//       }, 2500);
//     } else {
//       document.getElementById("preloader").style.display = "none";
//       document.getElementById("status").style.display = "none";
//     }

//     // Scrollto 0,0
//     window.scrollTo(0, 0);

//     const title = this.props.router.location.pathname;
//     let currentage = title.charAt(1).toUpperCase() + title.slice(2);

//     document.title = currentage + " | Fitsol Dashboard";

//     this.props.changeLayout("horizontal");
//     if (this.props.topbarTheme) {
//       this.props.changeTopbarTheme(this.props.topbarTheme);
//     }
//     if (this.props.layoutWidth) {
//       this.props.changeLayoutWidth(this.props.layoutWidth);
//     }
//   }

//   /**
//    * Opens the menu - mobile
//    */
//   openMenu = () => {
//     this.setState({ isMenuOpened: !this.state.isMenuOpened });
//   };
//   render() {
//     return (
//       <React.Fragment>
//         <div id="preloader">
//           <div id="status">
//             <div className="spinner-chase">
//               <div className="chase-dot"></div>
//               <div className="chase-dot"></div>
//               <div className="chase-dot"></div>
//               <div className="chase-dot"></div>
//               <div className="chase-dot"></div>
//               <div className="chase-dot"></div>
//             </div>
//           </div>
//         </div>
//         <div className="container-fluid">
//           <div id="layout-wrapper">
//             <header id="page-topbar">
//               <Header
//                 theme={this.props.topbarTheme}
//                 isMenuOpened={this.state.isMenuOpened}
//                 openLeftMenuCallBack={this.openMenu}
//               ></Header>
//             </header>
//             <div className="main-content">
//               {this.props.children}
//               <Outlet />
//               <Footer />
//             </div>
//           </div>
//         </div>
//         {this.props.showRightSidebar ? <Rightbar /> : null}
//       </React.Fragment>
//     );
//   }
// }

// Layout.propTypes = {
//   changeLayout: PropTypes.func,
//   changeLayoutWidth: PropTypes.func,
//   changeTopbarTheme: PropTypes.func,
//   children: PropTypes.object,
//   isPreloader: PropTypes.any,
//   layoutWidth: PropTypes.any,
//   location: PropTypes.object,
//   showRightSidebar: PropTypes.any,
//   topbarTheme: PropTypes.any,
// };

// const mapStatetoProps = (state) => {
//   return {
//     ...state.Layout,
//   };
// };
// export default connect(mapStatetoProps, {
//   changeTopbarTheme,
//   changeLayout,
//   changeLayoutWidth,
// })(withRouter(Layout));

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
} from "../../store/actions";
import { Outlet, useNavigate, useLocation, useParams } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Rightbar from "../CommonForBoth/Rightbar";
import Sidebar from "../VerticalLayout/Sidebar";
import { menuGen } from "../../pages/Utility/function";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

function Layout(props) {
  const isPreloader = useSelector((state) => state.Layout.isPreloader);
  const topbarTheme = useSelector((state) => state.Layout.topbarTheme);
  const layoutWidth = useSelector((state) => state.Layout.layoutWidth);
  const showRightSidebar = useSelector(
    (state) => state.Layout.showRightSidebar
  );
  const leftSideBarType = useSelector((state) => state.Layout.leftSideBarType);
  const leftSideBarTheme = useSelector(
    (state) => state.Layout.leftSideBarTheme
  );
  const [isMobile, setIsMobile] = useState(
    /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  );

  // const isMobile = useSelector((state) => state.Layout.isMobile);

  const location = useLocation();
  const navigate = useNavigate();

  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isPreloader === true) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
      }, 2500);
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }

    window.scrollTo(0, 0);

    const title = location.pathname;
    let currentage = title.charAt(1).toUpperCase() + title.slice(2);

    document.title = currentage + " | Fitsol Dashboard";

    const layout =
      // isMobile ? "detached" :
      "horizontal";

    dispatch(changeLayout(layout));

    if (isMobile && leftSideBarType) {
      dispatch(changeSidebarType(leftSideBarType));
    }
    if (isMobile && leftSideBarTheme) {
      dispatch(changeSidebarTheme(leftSideBarTheme));
    }
    if (topbarTheme) {
      dispatch(changeTopbarTheme(topbarTheme));
    }
    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth));
    }
  }, [
    isPreloader,
    location.pathname,
    topbarTheme,
    layoutWidth,
    leftSideBarType,
    isMobile,
  ]);

  const openMenu = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  return (
    <React.Fragment>
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div id="layout-wrapper">
          <header id="page-topbar">
            <Header
              theme={topbarTheme}
              isMenuOpened={isMenuOpened}
              openLeftMenuCallBack={openMenu}
              // isMobile={isMobile}
              SideBarMenu={props.SideBarMenu}
            />
          </header>

          <div className="horizontal-with-verticle ">
            <Sidebar
              theme={leftSideBarTheme}
              type={leftSideBarType}
              isMobile={isMobile}
              SideBarMenu={props.SideBarMenu || []}
            />
          </div>

          <div className="main-content">
            <Outlet />
            <Footer />
          </div>
        </div>
      </div>
      {showRightSidebar ? <Rightbar /> : null}
    </React.Fragment>
  );
}

// export default Layout;

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
  };
};
export default connect(mapStatetoProps, {
  changeTopbarTheme,
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeLayoutWidth,
})(withRouter(Layout));
