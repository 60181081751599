import React, { useEffect, useState } from "react";
import CustomPage from "../../../components/Custome/CustomPage";
import { material, packageType } from "../../Utility/option";
import { packageHeaderData } from "../../Utility/exportValue";

import CONSTANT, { TABLE_HEADER } from "../../Utility/constnt";
import { useSelector } from "react-redux";
import {
  deletePackagingStart,
  getPackagingStart,
} from "../../../store/packaging/actions";
import { packageDataMask } from "../../Utility/spread-function";
import ClientPackaging from "../../Client/packaging";

const Packaging = () => {
  const getPackagingData = useSelector((state) => state.packagingDetailData);
  const [formData, setFormData] = useState([]);

  return (
    <React.Fragment>
      <div>
        {localStorage.getItem("userRole") == "admin" ? (
          <div className="page-content">
            <CustomPage
              PAGE={{
                title: "Packaging",
                dataFunction: packageDataMask,
              }}
              API={CONSTANT.API.PACKAGEING}
              REDUX={{
                getDataRedux: getPackagingData?.users,
                getApiDataRedux: getPackagingStart(),
                deleteApiDataRedux: deletePackagingStart,
                getData: { getPackagingData },
              }}
              FORM={{
                formField: CONSTANT.FORM_FIELDS?.PACKAGE,
                addFormTitle: "Add packaging Type",
                editFormTitle: "Edit packaging",
                addNewRecordCtaText: "Add packaging",
                prepareUpdatePayload: (editPayload, payload) => {
                  const newObje = { ...editPayload, ...payload };

                  // if (newObje?.["material"]) {
                  //   return {
                  //     ...formData?.data,

                  //   };
                  // }
                  newObje["co2EmissionPerKG"] =
                    formData?.selectedValue?.["material"]?.id;
                  const dimensions = newObje.DimensionInmm.split("x")

                  newObje.VolumeCBM =
                    // parseInt(newObje.DimensionInmm) / 1000000000;
                    parseInt(dimensions[0])*parseInt(dimensions[1])*parseInt(dimensions[2]) / 1000000000 || 0;
                  newObje.VolumetricWeightInKG = newObje.VolumeCBM * 350;
                  // }

                  // if (newObje.ActualWeight && newObje.co2EmissionPerKG) {
                  newObje.lifeTimetCO2e =
                    (newObje.ActualWeight * newObje.co2EmissionPerKG) / 1000;
                  // }

                  // if (newObje.lifeTimetCO2e) {
                  newObje.type === "Reusable"
                    ? (newObje.lifeTimeTrips = "150")
                    : (newObje.lifeTimeTrips = "1");
                  newObje.tCO2PerShipment =
                    newObje.lifeTimetCO2e / newObje.lifeTimeTrips;
                  // }

                  // if (newObje.SubType && newObje.DimensionInmm) {
                  newObje.DropdownName = `${newObje.SubType}-${newObje.DimensionInmm}`;
                  return { ...newObje };
                },
                selectedValue: false,

                onChange: (data, selectedValue, operations, defaultData) => {
                  // setEditData(data);
                  setFormData({ data, selectedValue, operations, defaultData });
                },
                option: {
                  type: packageType,
                  material: material,
                },
              }}
              TABLE={{
                title: "Packaging type list",
                header: TABLE_HEADER?.packageType,
                export: {
                  fileName: "Packaging_Report.csv",
                  header: packageHeaderData,
                },
              }}
              //redux
              // getData={getPackagingData}      ==========================redux baki
              // customValueChange="1"
            />
          </div>
        ) : (
          <>
            <ClientPackaging />
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default Packaging;
