import React, { useEffect, useState } from "react";
import CONSTANT from "../../../pages/Utility/constnt";
import CustomModalMain from "../../Custome/CustomModalMain";
import { replaceId } from "../../../pages/Utility/function";

import {
  emissionEntryCategoryWiseDataLoad,
  emissionForFormDataCallLoad,
} from "../../../pages/Utility/spread-function";
import { useLocation, useParams } from "react-router";

const Electricity = (props) => {
  const {
    setFinancialYear,
    setInputDataForEmission,
    findEmissionData,
    subCategoryAttribute,
    extraValue,
    category,
    setCategory,
    setCarbonEmission,
    setSubCategoryAttribute,
    API_CALL,
  } = props;

  const [dynamicOption, setDynamicOption] = useState({});
  const [alldynamicOption, setAllDynamicOption] = useState({});
  const { id } = useParams();
  const userRole = localStorage.getItem("userRole");
  const location = useLocation();
  const currentPath = location.pathname;
  const [formData, setFormData] = useState([]);
  const [defaultData, setDefaultData] = useState({});
  const [cleanData, setCleanData] = useState(true);

  useEffect(() => {
    const api = {
      ...replaceId(
        CONSTANT.API.SUBCATEGORYATTRIBUTE.getSingleFilterSubCategoryAttribute,
        category
      ),
    };

    setCleanData(true);
    setDefaultData({ category: category });
    api.endpoint += "&definationType=userDefined";
    category != null &&
      API_CALL.getAll(
        [
          api?.endpoint,
          CONSTANT?.API?.ATTRIBUTEOPTION.get?.endpoint,
          CONSTANT?.API.SITE.get?.endpoint,
          //   CONSTANT?.API.JOB.getFuelType?.endpoint,
          //   CONSTANT?.API.JOB.getGasUsed?.endpoint,
        ],
        (res) => {
          // setSubCategoryAttribute(res?.data);
          let categoryOption = {};
          const subCategoryAttributeRes = res[0]?.data;
          const subCategoryAttributeOptionRes = res[1]?.data?.data;
          const sites =
            userRole == "admin" && currentPath.includes("/client") && id
              ? res[2]?.data?.data?.rows?.filter((el) => el?.clientId == id)
              : res[2]?.data?.data?.rows;

          const Other = {
            site: sites,
          };

          // setFuelTypeData(res[3]?.data?.data?.rows);

          const rearrangedData = subCategoryAttributeRes?.data?.rows?.sort(
            (a, b) => +a.index - +b.index
          );
          const dddd = emissionEntryCategoryWiseDataLoad(
            subCategoryAttributeOptionRes,
            Other,
            rearrangedData,
            setDynamicOption,
            null,
            formData
          );

          setAllDynamicOption((prev) => ({
            ...prev,
            ...categoryOption,
          }));
          setSubCategoryAttribute([
            ...CONSTANT.FORM_FIELDS.ADDSINGLEEMISSIONENTRY,
            ...dddd?.arr,
          ]);
          setCleanData(false);
        }
      );
  }, [category]);

  useEffect(() => {
    emissionForFormDataCallLoad(
      subCategoryAttribute,
      setDynamicOption,
      setSubCategoryAttribute,
      setFinancialYear,
      formData,
      alldynamicOption
    );
  }, [formData]);

  const onSubmit = (value) => {
    const e = { ...value };
    let payload = {};
    payload.createFlag = false;
    payload.subCategoryId = category;
    if (userRole == "admin" && id) {
      payload.clientId = id;
    }
    // delete e?.category;
    payload.data = e;

    Object.keys(payload.data)?.map((el) => {
      payload.data[`id${el}`] = payload.data[el];
    });

    setInputDataForEmission({ ...payload, createFlag: true });
    findEmissionData(payload);
  };

  return (
    <CustomModalMain
      data={subCategoryAttribute}
      defaultData={defaultData}
      labelSize={"25%"}
      submitBtnPosition={"center"}
      fieldSize={"70%"}
      submitBtnName="Calculate Emissions"
      isSubmitButtonVisible={category != null && !API_CALL?.isLoading}
      option={{
        ...dynamicOption,
      }}
      disabledValue={
        extraValue?.subCategory?.find((el) => el?.id == category)?.category
          ?.name
      }
      isCloase={cleanData}
      close={() => {
        if (cleanData) {
          setCarbonEmission(null);
          setSubCategoryAttribute([
            ...CONSTANT.FORM_FIELDS.ADDSINGLEEMISSIONENTRY,
          ]);
        }
      }}
      onSubmit={onSubmit}
      isCloseButtonVisible={false}
      minHeight={"90vh"}
      scrollCard="none"
      onChange={(data) => {
        setFormData(data);
      }}
    />
  );
};

export default Electricity;
