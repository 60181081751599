// import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
// import { SET_PLANT_DETAIL, PLANT_DETAIL } from "./actionTypes";
// import Apicall from "../../components/Hook/Api-call";
// import CONSTANT from "../../pages/Utility/constnt";

// const API_URL = "https://jsonplaceholder.typicode.com/users";

// function* getPlant() {
//   try {
//     const res = yield Apicall.get(CONSTANT.API.SITE.getPlant.endpoint);

//     yield put({ type: SET_PLANT_DETAIL, data: res });
//     // debugger;
//   } catch (error) {
//     console.log(error);
//     // yield put({ type: "GET_MY_PROFILE_ERROR", error });
//   }
// }

// function* GetPlantDetailSaga() {
//   yield takeEvery(PLANT_DETAIL, getPlant);
// }

// export default GetPlantDetailSaga;

import { put, takeLatest } from "redux-saga/effects";
import { DELETE_PLANT_START, GET_PLANT_START } from "./actionTypes";
import Apicall from "../../components/Hook/Api-call";
import CONSTANT from "../../pages/Utility/constnt";
import {
  deletePlantError,
  deletePlantSucess,
  getPlantError,
  getPlantSuccess,
} from "./actions";
import { replaceEndPointId } from "../../pages/Utility/function";

function* getPlant(api = null) {
  try {
    const res = yield Apicall?.get(
      api?.payload != null ? api?.payload : CONSTANT.API.SITE.get.endpoint
    );
    yield put(getPlantSuccess(res));
  } catch (error) {
    console.log(error);
    yield put(getPlantError(error?.message));

    // yield put({ type: GET_CUSTOMER_FAILURE, error });
  }
}

function* deletePlantSaga(user) {
  try {
    const response = yield Apicall?.remove(
      replaceEndPointId(CONSTANT?.API?.SITE?.delete?.endpoint, user?.payload)
    );

    if (response?.status == "success") {
      if (user?.successCallback) {
        user?.successCallback();
      }
    }
    yield put(deletePlantSucess(user));
  } catch (error) {
    console.log(error?.message, "responce");
    yield put(deletePlantError(error?.message));
  }
}

function* GetPlantDetailSaga() {
  yield takeLatest(GET_PLANT_START, getPlant);
  yield takeLatest(DELETE_PLANT_START, deletePlantSaga);
}

export default GetPlantDetailSaga;
