import { Button, Card, Col, Divider, Row } from "antd";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import "../../../assets/css/Custom.css";
import useHttp from "../../../components/Hook/Use-http";
import CONSTANT from "../../Utility/constnt";
import { notifications, replaceId } from "../../Utility/function";
import PcfServices from "../../Utility/API/pcfService";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";
import FooterGen from "../../../components/pcfComponent/footerGen";
import {
  newDataMainCalculation,
  optionForChart,
  setSeriesData,
} from "../../Utility/pcfSpreadFunction";
import ResultTabHeaderGen from "../../../components/pcfComponent/resultTabHeaderGen";
import ResultTabFooterPrGen from "../../../components/pcfComponent/resultTabFooterPrGen";

const ResultTab = (props) => {
  const { productId, tabIndex, setTabIndex, mainProductData } = props;
  const API = useHttp();
  const [calculateData, setCalculateData] = useState({});
  const [series, setSeries] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  let newDataMain = [];

  useEffect(() => {
    mainProductData?.id &&
      API.getAll(
        [
          replaceId(
            CONSTANT.API.PCFRESULTCALCULATION.getProductWise,
            mainProductData?.id
          ).endpoint,
        ],
        (res) => {
          setCalculateData(res?.[0]?.data?.data);
        },
        PcfServices
      );
  }, []);

  useEffect(() => {
    if (Object.keys(calculateData)?.length != 0) {
      newDataMain = newDataMainCalculation(calculateData);
      setSeries(setSeriesData(calculateData, newDataMain));
    }
  }, [calculateData]);

  const previewButton = () => {
    navigate(
      `${currentPath}?tab=energyAndTransport&productid=${mainProductData?.id}`
    );
    setTabIndex("4");
  };

  const continueButton = () => {
    notifications.success("Product successfully saved");
    navigate(`${currentPath?.replace("/pcftab", "")}`);
  };

  return (
    <React.Fragment>
      <Card style={{ width: "100%" }}>
        {Object.keys(calculateData)?.length > 0 ? (
          <>
            <ResultTabHeaderGen calculateData={calculateData} series={series} />{" "}
            <Row>
              <Col xs={0} lg={4} style={{ marginTop: "60px" }}>
                <div className="pcf-chart-box"></div>
              </Col>
              <Col xs={24} lg={20}>
                <ReactApexChart
                  options={optionForChart()}
                  series={series}
                  width={"100%"}
                  height="310"
                  type="rangeBar"
                  className="apex-charts"
                />
              </Col>
            </Row>
            <ResultTabFooterPrGen
              calculateData={calculateData}
              series={series}
            />
            <Divider />
            <Row>
              <Col sm={24}>
                <FooterGen
                  previewButton={previewButton}
                  continueButton={continueButton}
                  continueButtonLabel={"Add More Product"}
                />

                <Row>
                  The calculations are based on average emission factors
                  validated by third-party open data sources.
                </Row>
              </Col>
            </Row>
          </>
        ) : (
          <>
            {API?.isLoading ? (
              <Loader />
            ) : (
              <Row
                className="pcf-total-carbon-label d-flex align-items-center justify-content-center"
                style={{ fontSize: "20px" }}
              >
                Result not found
              </Row>
            )}
          </>
        )}
      </Card>
    </React.Fragment>
  );
};

export default ResultTab;
