import PropTypes from "prop-types";
import React from "react";
import { connect, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";

import defaultImage from "../../assets/images/UserImage.jpg";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}
const Sidebar = (props) => {
  const myData = useSelector((state) => state.userGetMeData);

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="h-100">
          <div className="user-wid text-center py-4">
            <div className="user-img">
              <img
                src={
                  myData?.users?.profilePic
                    ? myData?.users?.profilePic
                    : defaultImage
                }
                alt=""
                className="avatar-md mx-auto rounded-circle"
              />
            </div>

            {/* <div className="mt-3">
              <Link to="#" className="text-dark fw-medium font-size-16">
                {MyData.data.name}
              </Link>
              <p className="text-body mt-1 mb-0 font-size-13">Admin Company</p>
            </div> */}
          </div>
          <div data-simplebar className="h-100">
            {props.type !== "condensed" ? (
              <SidebarContent SideBarMenu={props?.SideBarMenu} />
            ) : (
              <SidebarContent SideBarMenu={props?.SideBarMenu} />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = (state) => {
  return {
    layout: state.Layout,
  };
};
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)));
