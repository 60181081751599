import  React,{useState} from 'react';
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { tab, TAB_DATA } from '../../../Utility/constnt';
import PageTitle from '../../../../components/pageTitle';
import classNames from 'classnames';
import ConnectionSent from './connectionSent';
import ConnectionRecieved from './connectionRecieved';

const ConnectionManagement = () => {
  const [activeTab, setactiveTab] = useState(tab?.tabActiveId);
  const toggleCustomJustified = (tab) => {
    if (activeTab !== tab){
      setactiveTab(tab);
    }
  }
  return (
    <React.Fragment>
      <div className='page-content'>
        <PageTitle pageHeading={'Partner Onboarding'}/>
          <Row>
            <Col sm={12}>
            <div  className="page-content mt-0 pt-0 scrollSticky">
              <Nav tabs className='nav-tabs-custom nav-justified'>
                {TAB_DATA.MANAGE_CONNECTIONS_TAB.map((data, index) => {
                  return (
                    <div key={index} className='mt-2' style={{width:"300px"}}>
                      <NavItem>
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classNames({
                          active: activeTab === data.tabId,
                          activeTabDesign: activeTab === data.tabId,
                          TabDesign: true,
                        })}
                        onClick={() => {
                          tab.tabActiveId = data?.tabId;
                          toggleCustomJustified(data.tabId);
                        }}
                        >
                          <span className="d-sm-block">{data.name}</span>
                        </NavLink>
                      </NavItem>
                    </div>
                  )
                })}
              </Nav>
              <TabContent activeTab={activeTab} className='mt-3 p-0 TabBody'>
                <TabPane tabId={1} className='py-3'>
                  <ConnectionSent/>
                </TabPane>
                <TabPane tabId={2} className='py-3'>
                  <ConnectionRecieved/>
                </TabPane>
              </TabContent>
            </div>
            
            </Col>
          </Row>

      </div>
    </React.Fragment>
    
  )
}

export default ConnectionManagement;

