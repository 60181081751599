import React from "react";
import CustomPage from "../../components/Custome/CustomPage";
import { getExtraConstPayload } from "../Utility/function";
import { TeamMasK } from "../Utility/spread-function";
import { Fitsol_Team } from "../Utility/exportValue";
import CONSTANT from "../Utility/constnt";

const Team = () => {
  return (
    <div className="page-content">
      <CustomPage
        // API
        CreateAPI={CONSTANT.API.ROLEANDACCESS.addTeam}
        UpdateAPI={CONSTANT.API.ROLEANDACCESS.updateTeam}
        DeleteAPI={CONSTANT.API.ROLEANDACCESS.deleteTeam}
        GetAPI={CONSTANT.API.ROLEANDACCESS.getTeam}
        // Model
        ModalName="TEAM" //Form Model
        tableColumn="team" //Vehicle Model
        //Form Customization
        addName="Add Teammates "
        addFormTitle="Add Teammates"
        editFormTitle="Edit Teammates"
        formData={true}
        removeFieldOnEdit={["password"]}
        extraConstPayload={getExtraConstPayload({ role: "tripCreator" })}
        // Table Customization
        titleHeader="Driver List"
        datafunction={(res) => {
          return TeamMasK(res);
        }}
        ExportHeaders={Fitsol_Team}
        // ExportValues={driverValueData(driverDataExcel)}
        excelName="Fitsol_Team.csv"
        viewModelData={false}
      />
    </div>
  );
};

export default Team;
