import { Button, Result } from "antd";
import React from "react";
import { Container, Row, Col, CardBody, Card } from "reactstrap";
import errorImage from "../assets/images/error-img.png";

const NoInternet = () => {
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody>
                  <div className="text-center p-3">
                    <div className="img">
                      {/* <img src={errorImage} className="img-fluid" alt="" /> */}
                    </div>

                    <Result
                      status="warning"
                      title={
                        <p style={{ fontSize: "40px", fontWeight: "800" }}>
                          Whoops!
                        </p>
                      }
                      subTitle={
                        <p style={{ fontSize: "25px", fontWeight: "500" }}>
                          Something seems broken!
                        </p>
                      }
                      // extra={

                      // }
                      style={{ padding: "30px 32px" }}
                    />
                    <Button
                      disabled
                      type="primary"
                      key="console"
                      style={{
                        display: "flex",
                        textWrap: "wrap",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      Please refresh and if the problem persists then please
                      check your internet connection and try after some time.
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NoInternet;
