import React from "react";
import { FlippingSquare } from "react-cssfx-loading";
import { Row } from "reactstrap";

function Loader() {
  return (
    <Row className="d-flex justify-content-center">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <FlippingSquare color="#3b5de7" />
        <pre style={{ color: "#3b5de7" }}>{`  Loading...`}</pre>
      </div>
    </Row>
  );
}

export default Loader;

// style={{
//   height: "200px", // Set the height to 200px
//   width: "100%", // Set the width to 100%
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "center",
//   justifyContent: "center", // Center content vertically
// }}
