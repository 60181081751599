import React, { useEffect, useState } from "react";
import CustomPage from "../../../Custome/CustomPage";
import {
  emissionEntryCategoryWiseDataLoad,
  emissionForEditFormDataCallLoad,
  jobViewDataMask,
} from "../../../../pages/Utility/spread-function";
import CONSTANT, {
  getTableColumConfig,
} from "../../../../pages/Utility/constnt";
import {
  getRecordId,
  replaceId,
  tableHeaderToExportHeader,
} from "../../../../pages/Utility/function";
import { useLocation, useParams } from "react-router-dom";

const PurchasedGasesView = (props) => {
  const {
    jobId,
    jobSingleData,
    API,
    initHeader,

    // setAllDynamicOption,
    setJobViewData,
    onViewReason,
    responceStatus,
    flag,
  } = props;
  const [subCategoryModal, setSubCategoryModal] = useState([]);
  const [subHeader, setHeader] = useState([]);
  const [dynamicOption, setDynamicOption] = useState({});
  const [editData, setEditData] = useState({});
  const [formData, setFormData] = useState([]);
  const [alldynamicOption, setAllDynamicOption] = useState({});
  const { id } = useParams();
  const userRole = localStorage.getItem("userRole");
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    jobSingleData &&
      API?.getAll(
        [
          CONSTANT?.API.SUBCATEGORYATTRIBUTE.get.endpoint,
          CONSTANT?.API?.ATTRIBUTEOPTION.get?.endpoint,
          CONSTANT?.API.SITE.get?.endpoint,
          CONSTANT?.API.JOB.getGasUsed?.endpoint,
        ],
        (res) => {
          // const subCategoryAttributeRes = res[0]?.data;
          let categoryOption = {};
          const subCategoryAttributeOptionRes = res[1]?.data?.data;
          const sites =
            userRole == "admin" && currentPath.includes("/client") && id
              ? res[2]?.data?.data?.rows?.filter((el) => el?.clientId == id)
              : res[2]?.data?.data?.rows;
          const Other = {
            site: sites,
            gasUsed: res[3]?.data?.data?.rows,
          };

          const rearrangedData = res[0]?.data?.data?.rows
            ?.filter((it) => it?.subCategoryId == jobSingleData?.subCategoryId)
            ?.sort((a, b) => +a.index - +b.index);

          let arrHeader = [...initHeader];

          const dddd = emissionEntryCategoryWiseDataLoad(
            subCategoryAttributeOptionRes,
            Other,
            rearrangedData,
            setDynamicOption,
            arrHeader
          );

          setSubCategoryModal(dddd?.arr);
          setAllDynamicOption((prev) => ({
            ...prev,
            ...categoryOption,
          }));
          setHeader((pr) => {
            return [...dddd?.arrHeader, getTableColumConfig("action")];
          });
        }
      );
  }, [jobSingleData]);

  useEffect(() => {
    emissionForEditFormDataCallLoad(
      subCategoryModal,
      setDynamicOption,
      setSubCategoryModal,
      formData,
      alldynamicOption,
      editData
    );
  }, [formData, editData]);

  const preParePayloadOnSubmit = (editPayload, payload) => {
    subCategoryModal?.map((it) => {
      if (it?.extraValue?.getShowDefaultSelect?.length) {
        const objectNew = {};

        it?.extraValue?.getShowDefaultSelect?.map((elp) => {
          Object?.keys(elp)?.map((elpc) => {
            if (objectNew?.[elpc]) {
              objectNew?.[elpc]?.push(elp?.[elpc]);
            } else {
              objectNew[elpc] = [elp?.[elpc]];
            }
          });
        });

        if (
          !objectNew[Object?.keys(objectNew)[0]]?.includes(
            payload?.[Object?.keys(objectNew)[0]]
          )
        ) {
          delete editPayload?.[it?.id];
        }
      }
    });

    const objPayload = {
      data: {
        ...editPayload,
        ...payload,
      },
    };
    delete objPayload?.data?.id;

    Object?.keys(objPayload?.data)?.map((itm) => {
      objPayload.data[`id${itm}`] = payload[itm] || editPayload[itm];
    });

    return {
      ...objPayload,
    };
  };

  return (
    <>
      <CustomPage
        PAGE={{
          // withBackButton: true,
          title: `JOB / ${getRecordId?.job(jobId)}`,
          dataFunction: (res, Actions) => {
            setJobViewData(res?.result);

            return jobViewDataMask(res, Actions, onViewReason, responceStatus);
          },
          pageRefresh: { flag },
        }}
        noHeader={false}
        API={{
          get: replaceId(CONSTANT?.API.JOBVIEW.get, jobId),
          update: CONSTANT?.API.JOBVIEW.update,
          delete: CONSTANT?.API.JOBVIEW.delete,
        }}
        // pageHeading={`JOB / ${jobId}`}
        //Form Customization
        // pageHeading="CUSTOMER"

        FORM={{
          formField: subCategoryModal,

          editFormTitle: "Edit Job",
          option: {
            ...dynamicOption,
          },
          editDataPayload: (editData) => {
            setEditData(editData);
          },

          prepareUpdatePayload: preParePayloadOnSubmit,
          // (editPayload, payload) => {

          //   preParePayloadOnSubmit(editPayload, payload);
          //   // const objPayload = {
          //   //   data: {
          //   //     ...editPayload,
          //   //     ...payload,
          //   //   },
          //   // };
          //   // delete objPayload?.data?.id;

          //   // Object?.keys(objPayload?.data)?.map((itm) => {
          //   //   objPayload.data[`id${itm}`] = payload[itm] || editPayload[itm];
          //   // });

          //   // return {
          //   //   ...objPayload,
          //   // };
          // },
          onChange: (data, selectedValue, operations) => {
            // setEditData(data);
            setFormData({ data, selectedValue, operations });
          },
          selectedValue: false,
        }}
        editDefaultorInput={true}
        extrakeyForedit={"true"}
        TABLE={{
          title: "Record List",
          header: subHeader,
          export: {
            fileName: `${getRecordId?.job(jobId)}_Record.csv`,
            header: tableHeaderToExportHeader?.header(subHeader),
          },
        }}
      />
    </>
  );
};

export default PurchasedGasesView;
