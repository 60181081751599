import React, { useEffect, useState } from "react";

import CustomPage from "../../../components/Custome/CustomPage";
import useHttp from "../../../components/Hook/Use-http";
import notify from "../../Utility/coustemFunction";
import CONSTANT, { TABLE_HEADER } from "../../Utility/constnt";
import {
  getExtraConstPayload,
  isNotEmpty,
  parseAddress,
  notifications,
} from "../../Utility/function";
import { transporterDataMask } from "../../Utility/spread-function";
import { transporterCardDetails, transporterCardDetailsonEdit } from "../../Utility/models/view-details";
import { transporterMapping } from "../../Utility/models/mapping-model";
import { transporterHeaderData } from "../../Utility/exportValue";
import { useSelector } from "react-redux";
import {
  deleteTransporterStart,
  getTransporterStart,
} from "../../../store/actions";

const keyCondition = "trade_name";
const Transporter = () => {
  const [transporter, setTransporter] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [transporterData, setTransporterData] = useState(null);
  const myData = useSelector((state) => state.transporterDetailData);
  const [editClick, setEditClick] = useState(false);
  const [val, setVal] = useState(false);
  const getTransporterData = useSelector(
    (state) => state.transporterDetailData
  );
  const API_CALL = useHttp();
  const verifyHandler = (res) => {
    if(editClick == true){
      setEditClick(false);
    }
    if (isNotEmpty(transporter?.gst?.trim())) {
      API_CALL.sendRequest(
        CONSTANT.API.TRANSPORTER.addTransporterVerifyByGst,
        (res) => {
          if (res?.data?.Succeeded) {
            setTransporter((prev) => {
              const resData = res?.data?.Succeeded?.Gst_Details?.result;
              const address = parseAddress(resData?.address);
              return {
                ...prev,
                ...resData,
                transporter_name: resData?.trade_name,
                date_of_registration: resData?.date_of_registration,
                business_type: resData?.constitution_of_business,
                business_address: address?.AddressString,
                address: address?.AddressString,
                gst_status: resData?.gstin_status,
                is_aadhar_verified: resData?.adhrVFlag,
                is_field_visit_conducted: resData?.isFieldVisitConducted,
              };
            });
          }else if (res?.message?.Failed) {
            setErrorMessage(res?.message?.Failed?.errorMessage);
          }
        },
        {
          GSTN: transporter?.gst?.trim(),
        }
      );
    } else {
      notifications.error("Please Enter Valid GST Number");
    }
  };
  return (
    <div className="page-content">
      <CustomPage
        PAGE={{
          title: "Transporter",
          dataFunction: transporterDataMask,
        }}
        API={CONSTANT.API.TRANSPORTER}
        REDUX={{
          getDataRedux: getTransporterData?.users,
          getApiDataRedux: getTransporterStart(),
          deleteApiDataRedux: deleteTransporterStart,
          getData: { myData },
        }}
        FORM={{
          formField: CONSTANT.FORM_FIELDS?.TRANSPORTER,
          addFormTitle: "Add transporter",
          editFormTitle: "Edit transporter",
          addNewRecordCtaText: "Add transporter",
          // optionalFieldOnEdit: ["tripEndDistance", "insuranceNumber"],
          isSubmitButtonVisible: transporter?.hasOwnProperty(keyCondition)
            ? true
            : false,
          extraConstPayload: transporter?.hasOwnProperty(keyCondition)
            ? getExtraConstPayload(transporter, transporterMapping)
            : [],
          onChange: (data) => {
            if (data?.gstNumber) {
              setTransporter((prev) => {
                return {
                  ...prev,
                  gst: data?.gstNumber,
                };
              });
            }
          },
          viewModel: {
            setVerifyData: setTransporter,
            viewModelData: transporter,
            viewModelImage: transporter?.image,
            cardVisibleOn: keyCondition,
            viewModelLoading: API_CALL?.isLoading,
            viewModelDetails: editClick == true ? transporterCardDetailsonEdit : transporterCardDetails,
            errorMessage: errorMessage,
            onVerifyHandler: verifyHandler,
          },
        }}
        TABLE={{
          title: "Transporter list",
          header: TABLE_HEADER?.transporter,
          export: {
            fileName: "Transporter_Report.csv",
            header: transporterHeaderData,
          },
        }}
        //redux
        // getData={myData}      ===============================redux set baki
        formData={false}
        editClick = {editClick}
        setEditClick={setEditClick}
        val = {val}
        setVal = {setVal}
      />
    </div>
  );
};

export default Transporter;
