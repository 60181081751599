import React, { useEffect, useState } from "react";

import { Card } from "antd";
import SingleTabel from "../../../components/pcfComponent/singleTabel";
import CONSTANT, {
  pcfProductDetailTableFields,
  roles,
} from "../../Utility/constnt";
import useHttp from "../../../components/Hook/Use-http";
import PcfServices from "../../Utility/API/pcfService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  notifications,
  optionArrayWithSort,
  replaceId,
} from "../../Utility/function";
import { productTable } from "../../Utility/pcfSpreadFunction";

const ProductTab = (props) => {
  const {
    tabIndex = null,
    productId = null,
    setMainProductData,
    setTabIndex,
    mainProductData,
  } = props;
  const API = useHttp();
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;

  const [tableGenData, setTableGenData] = useState({});
  const [isCompleted, setIsCompleted] = useState(null);

  let dynamicOption = {};

  //for new data
  useEffect(() => {
    Object?.keys(mainProductData)?.length == 0 &&
      !mainProductData?.id &&
      // !mainProductData?.productCategoryId &&
      API.getAll(
        [CONSTANT.API.PCFPRODUCTCATEGORY.getForProduct.endpoint],
        (res) => {
          // setCategoryData(res?.[0]?.data?.data?.rows);
          const data = res?.[0]?.data?.data?.rows;

          setTableGenData(
            productTable({
              productCategoryId: {
                option: optionArrayWithSort(data),
              },
            })
          );
        },
        PcfServices
      );
  }, []);

  //UpdateData
  useEffect(() => {
    let apiendpoint = CONSTANT.API.PCFPRODUCTSUBCATEGORY.get;
    if (mainProductData?.productCategoryId) {
      apiendpoint = replaceId(
        CONSTANT.API.PCFPRODUCTSUBCATEGORY.getSubCategoryFilterByCategory,
        mainProductData?.productCategoryId
      );
    }
    mainProductData?.id &&
      API.getAll(
        [
          CONSTANT.API.PCFPRODUCTCATEGORY.getForProduct.endpoint,
          apiendpoint.endpoint,
        ],
        (res) => {
          setTableGenData(
            productTable({
              isNew: false,
              recordId: mainProductData?.id,
              productName: mainProductData?.name,
              weight: mainProductData?.weight,
              productCategoryId: {
                option: optionArrayWithSort(res?.[0]?.data?.data?.rows),

                value: mainProductData?.productCategoryId,
              },
              productSubCategoryId: {
                option: mainProductData?.productCategoryId
                  ? optionArrayWithSort(res?.[1]?.data?.data?.rows)
                  : [],
                value: mainProductData?.productSubCategoryId,
              },
              productCode: mainProductData?.productCode,
            })
          );
        },
        PcfServices
      );
  }, [mainProductData]);

  const previewButton = () => {};

  const continueButton = () => {
    //if add isCompleted = true and if update isCompleted = false
    const payload = {};
    setTableGenData((prevtableData) => {
      let isError = false;

      prevtableData?.data?.forEach((el) => {
        if (el?.required) {
          if (el?.value) {
            payload[el?.id] = el?.value;
          } else {
            el.status = el?.validate(el?.value);
            if (!isError && el?.validate(el?.value) == "error") {
              isError = true;
            }
          }
        } else {
          payload[el?.id] = el?.value || "";
        }
      });
      isError && notifications.error("Please fill up the required field");
      if (roles == "admin") payload["clientId"] = id;
      if (
        prevtableData?.recordId != -1 &&
        prevtableData?.isNew == false &&
        !isError
      ) {
        if (isCompleted != null) {
          payload["isCompleted"] = isCompleted;
        }
        //update
        API.sendRequest(
          replaceId(CONSTANT.API.PCFPRODUCT.update, prevtableData?.recordId),
          (res) => {
            setMainProductData((prev) => {
              return {
                ...prev,
                ...payload,
              };
            });
            navigate(
              `${currentPath}?tab=parts&productid=${mainProductData?.id}`
            );
            setTabIndex("2");
          },
          payload,
          "Product successfully updated",
          null,
          null,
          PcfServices
        );
      } else if (
        prevtableData?.recordId == -1 &&
        prevtableData?.isNew != false &&
        !isError
      ) {
        payload["isCompleted"] = false;
        //add
        API.sendRequest(
          CONSTANT.API.PCFPRODUCT.create,
          (res) => {
            setMainProductData(res?.data);
            navigate(`${currentPath}?tab=parts&productid=${res?.data?.id}`);
            setTabIndex("2");
          },
          payload,
          "Product successfully added",
          null,
          null,
          PcfServices
        );
      }
      return { ...prevtableData };
    });
  };

  const getProductSubCategory = (elementIndex, categoryId) => {
    categoryId &&
      API.getAll(
        [
          replaceId(
            CONSTANT.API.PCFPRODUCTSUBCATEGORY.getSubCategoryFilterByCategory,
            categoryId
          ).endpoint,
        ],
        (res) => {
          // setSubCategoryData(res?.[0]?.data?.data?.rows);
          setTableGenData((prev) => {
            const prevData = { ...prev };

            const currentPart = prevData?.data;
            currentPart[elementIndex].option = optionArrayWithSort(
              res?.[0]?.data?.data?.rows
            );
            // res[0]?.data?.data?.rows?.map(
            //   (el) => {
            //     return {
            //       label: el?.name,
            //       value: el?.id,
            //     };
            //   }
            // );
            currentPart[elementIndex].isLoading = false;
            return prevData;
          });
        },
        PcfServices
      );
  };

  const onProductChange = (elementIndex, fieldData, value) => {
    setTableGenData((prev) => {
      const prevData = { ...prev };
      const productField = prevData?.data;
      const productCurrentValue = productField[elementIndex].value;
      productField[elementIndex].value = value;
      switch (elementIndex) {
        case 0:
          if (productCurrentValue != value) setIsCompleted(false);
          break;
        case 1:
          if (productCurrentValue != value) setIsCompleted(false);
          break;
        case 2:
          productField[elementIndex + 1].isLoading = true;
          productField[elementIndex + 1].value = null;
          productField[elementIndex + 1].option = [];
          getProductSubCategory(elementIndex + 1, value);
          break;
        default:
          return prevData;
      }
      return prevData;
    });
  };

  return (
    <>
      <SingleTabel
        tableField={tableGenData?.data}
        continueButton={continueButton}
        tabIndex={tabIndex}
        productId={productId}
        dynamicOption={dynamicOption}
        onSingleChange={(singleData) => {
          // setNewFormData(singleData);
        }}
        isPreviewButton={true}
        changedFields={(index, fieldData, value, multipleIndex) => {
          onProductChange(index, fieldData, value);
        }}
      />
    </>
  );
};

export default ProductTab;
