import React, { useEffect, useState } from 'react';
import { Form, Input, Row, Col, Select, Button } from 'antd';
import { MailOutlined, PhoneOutlined, GlobalOutlined } from '@ant-design/icons';
import useHttp from '../../../components/Hook/Use-http';
import CONSTANT from '../../Utility/constnt';
import { replaceId } from '../../Utility/function';
import { useParams } from 'react-router-dom';
import Submit from '../submitButton';
import BrsrFormHeading from '../BrsrFormHeading';

const { TextArea } = Input;
const { Option } = Select;

const DetailsForm = () => {
    const API = useHttp();
    const { formId } = useParams();
    const [data, setData] = useState({ A_I: {} });

    const handleInputChange = (e) => {
        const qnos = e.target.getAttribute('qnos');
        const name = e.target.name;
        const value = e.target.value;

        setData(prevData => ({
            ...prevData,
            A_I: {
                ...prevData.A_I,
                [qnos]: {
                    ...prevData.A_I[qnos],
                    [name]: value
                }
            }
        }));
    };

    useEffect(() => {
        try {
            API.sendRequest(replaceId(CONSTANT.API.BRSRFORMS.get2, formId), (res) => {
                setData(res?.data || { A_I: {} });
            });
        } catch (err) {
            console.log(err);
        }
    }, []);


    const getInputValue = (qnos, key) => {
        return data?.A_I?.[qnos]?.[key] || '';
    };

    const handleSubmit = () => {
        try {
            API.sendRequest(replaceId(CONSTANT.API.BRSRFORMS.create, formId),
                (res) => {
                    console.log(res);
                },
                { data: data }, "Data saved successfully")
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div style={{ paddingTop: "100px" }}>
            <BrsrFormHeading
              title="I. Details of the listed entity" 
              subtitle="Section A: General Disclosures" 
            />
            <Form>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item>
                            <div style={{ marginBottom: 3 }}>1. Corporate Identity Number (CIN) of the Listed Entity</div>
                            <Input
                                name="CorporateIdentityNumber|DCYMain"
                                qnos="1"
                                onChange={handleInputChange}
                                value={getInputValue('1', 'CorporateIdentityNumber|DCYMain')}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item>
                            <div style={{ marginBottom: 3 }}>2. Name of the Listed Entity</div>
                            <Input
                                name="NameOfTheCompany|ICYMain"
                                qnos="2"
                                onChange={handleInputChange}
                                value={getInputValue('2', 'NameOfTheCompany|ICYMain')}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item>
                            <div style={{ marginBottom: 3 }}>2. Year of Incorporation</div>
                            <Input
                                name="YearOfIncorporation|DCYMain"
                                qnos="3"
                                onChange={handleInputChange}
                                value={getInputValue('3', 'YearOfIncorporation|DCYMain')}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item>
                            <div style={{ marginBottom: 3 }}>4. Registered Office Address</div>
                            <TextArea
                                rows={2}
                                name="AddressOfRegisteredOfficeOfCompany|DCYMain"
                                qnos="4"
                                onChange={handleInputChange}
                                value={getInputValue('4', 'AddressOfRegisteredOfficeOfCompany|DCYMain')}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item>
                            <div style={{ marginBottom: 3 }}>5. Corporate Address</div>
                            <TextArea
                                rows={2}
                                name="AddressOfCorporateOfficeOfCompany|DCYMain"
                                qnos="5"
                                onChange={handleInputChange}
                                value={getInputValue('5', 'AddressOfCorporateOfficeOfCompany|DCYMain')}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item>
                            <div style={{ marginBottom: 3 }}>6. E-mail</div>
                            <Input
                                prefix={<MailOutlined />}
                                name="EMailOfTheCompany|DCYMain"
                                qnos="6"
                                onChange={handleInputChange}
                                value={getInputValue('6', 'EMailOfTheCompany|DCYMain')}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item>
                            <div style={{ marginBottom: 3 }}>7. Telephone</div>
                            <Input
                                prefix={<PhoneOutlined />}
                                name="TelephoneOfCompany|DCYMain"
                                qnos="7"
                                onChange={handleInputChange}
                                value={getInputValue('7', 'TelephoneOfCompany|DCYMain')}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item>
                            <div style={{ marginBottom: 3 }}>8. Website</div>
                            <Input
                                prefix={<GlobalOutlined />}
                                name="WebsiteOfCompany|DCYMain"
                                qnos="8"
                                onChange={handleInputChange}
                                value={getInputValue('8', 'WebsiteOfCompany|DCYMain')}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item>
                            <div style={{ marginBottom: 3 }}>9. Financial Year for which reporting is being done</div>
                            <Input
                                name="DateOfStartOfFinancialYear|DCYMain"
                                qnos="9"
                                onChange={handleInputChange}
                                value={getInputValue('9', 'DateOfStartOfFinancialYear|DCYMain')}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item>
                            <div style={{ marginBottom: 3 }}>10. Name of the Stock Exchange(s) where shares are listed</div>
                            <Input
                                name="NameOfStockExchangeWhereTheCompanyIsListed|D_StockExchangeAxis"
                                qnos="10"
                                onChange={handleInputChange}
                                value={getInputValue('10', 'NameOfStockExchangeWhereTheCompanyIsListed|D_StockExchangeAxis')}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item>
                            <div style={{ marginBottom: 3 }}>11. Paid-up Capital</div>
                            <Input
                                name="ValueOfSharesPaidUp|ICYMain"
                                qnos="11"
                                onChange={handleInputChange}
                                value={getInputValue('11', 'ValueOfSharesPaidUp|ICYMain')}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item>
                            <div style={{ marginBottom: 3 }}>12. Name and contact details (telephone, email address) of the person who may be contacted in case of any queries on the BRSR report</div>
                            <TextArea
                                rows={2}
                                name="NameOfContactPerson|DCYMain"
                                qnos="12"
                                onChange={handleInputChange}
                                value={getInputValue('12', 'NameOfContactPerson|DCYMain')}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item>
                            <div style={{ marginBottom: 3 }}>13. Reporting boundary</div>
                            <Input
                                name="ReportingBoundary|DCYMain"
                                qnos="13"
                                onChange={handleInputChange}
                                value={getInputValue('13', 'ReportingBoundary|DCYMain')}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                {/* <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item>
                            <div style={{marginBottom: 3}}>Name of Assurance Provider</div>
                            <Input
                                name="assuranceProvider"
                                qnos="14"
                                onChange={handleInputChange}
                                value={getInputValue('14', 'assuranceProvider')}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item>
                            <div style={{marginBottom: 3}}>Type of Assurance Obtained</div>
                            <Input
                                name="assuranceType"
                                qnos="15"
                                onChange={handleInputChange}
                                value={getInputValue('15', 'assuranceType')}
                            />
                        </Form.Item>
                    </Col>
                </Row> */}

                <Submit handleSubmit={handleSubmit} loading={API.isLoading}></Submit>
            </Form>
        </div>
    );
};

export default DetailsForm;
