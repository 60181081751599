import React, { useState } from "react";
import { Col, Label, Modal, Row, Input } from "reactstrap";
import Select from "react-select";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import moment from "moment/moment";
import CreatableSelect from "react-select/creatable";
import {
  getDateAndTimeColumn,
  notifications,
} from "../../pages/Utility/function";
import notify from "../../pages/Utility/coustemFunction";
const CustomModal = (props) => {
  const {
    isAutoClose = true,
    changedetect = {},
    modalType,
    show,
    close,
    modalTitle,
    data,
    onSubmit,
    defaultData,
    formData,
    isEdit,
    onChangeFunction = {},
    option = {},
    footerHeight,
  } = props;

  const todayDate = moment(new Date()).format("YYYY-MM-DDTHH:MM");
  const [inputData, setInputData] = useState({
    // startDateAndTime: todayDate,
    // targetedDateAndTime: todayDate,
  });
  const [address, setAddress] = useState(null);
  const [selectedValue, setSelectedValue] = useState({});
  const [isChange, setIsChange] = useState(false);

  const onChangeInput = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };
  const onSelectValue = (selected, key) => {
    setSelectedValue({ ...selectedValue, [key]: selected });

    setInputData({ ...inputData, [key]: selected.value });
    setIsChange(true);
  };
  const onChangeFileValue = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.files[0] });
  };

  const onChangeGoogleAddress = (e, fieldName) => {
    if (fieldName === "sourceLocation") {
      setInputData({
        ...inputData,
        sourceLocation: e?.label,
        sourceId: e?.value?.place_id,
      });
    } else if (fieldName === "location") {
      setInputData({
        ...inputData,
        location: e?.label,
      });
      geocodeByPlaceId(e?.value?.place_id)
        .then((results) => {
          const destinationLat = results[0].geometry.location.lat();
          const destinationLong = results[0].geometry.location.lng();
          setInputData({
            ...inputData,
            latitude: destinationLat,
            logtitude: destinationLong,
            location: e?.label?.toUpperCase(),
            locationId: e?.value?.place_id,
          });
        })
        .catch((error) => console.error(error));
      Object.keys(inputData).map((key) => {
        payload.append(key, inputData[key]);
      });
    } else {
      setInputData({
        ...inputData,
        destinationLocation: e?.label,
        destinationId: e?.value?.place_id,
      });
    }
  };

  let payload = new FormData();
  const onSubmitForm = (e) => {
    e.preventDefault();
    if (formData) {
      Object.keys(inputData).map((key) => {
        payload.append(key, inputData[key]);
      });
      onSubmit(payload);
    } else {
      onSubmit(inputData);
    }
    // setAddress(null);
    isAutoClose && close();
  };

  const onCloseModal = () => {
    close();
    setInputData({});
    setIsChange(false);
    setSelectedValue({});
  };

  const onChangeNumber = (value, country, e, formattedValue) => {
    setInputData({
      ...inputData,
      mobile: value.replace(country.dialCode, ""),
      countryCode: country.dialCode,
    });
  };

  const formValidation = (e) => {
    e.preventDefault();
    // notify.error("address required");
    notifications.error("address required");
  };
  return (
    <>
      <Col sm={6} md={4} xl={3}>
        {modalType == "formModal" && (
          <Modal isOpen={show}>
            <div className="modal-body">
              <h5>{modalTitle}</h5>
            </div>

            <div>
              <form
                className="needs-validation"
                onSubmit={onSubmitForm}
                style={{
                  maxHeight: "550px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                <Row
                  style={{ height: `${footerHeight ? footerHeight : "auto"}` }}
                >
                  <Col md="12">
                    <div className="mb-3 px-3">
                      {data.map((fieldName, index) => {
                        if (
                          fieldName.type === "text" ||
                          fieldName.type === "number"
                        ) {
                          return (
                            <div key={index} className="mb-4">
                              <Label htmlFor="validationCustom01">
                                {fieldName?.label}{" "}
                                {fieldName?.required && (
                                  <sub style={{ color: "red" }}>*</sub>
                                )}
                              </Label>
                              {fieldName.name === "total" ||
                              fieldName.name === "subTotal" ? (
                                <input
                                  autoComplete="no"
                                  name={fieldName?.name}
                                  defaultValue={
                                    defaultData &&
                                    defaultData !== "null" &&
                                    defaultData !== "undefined" &&
                                    defaultData[fieldName?.name]
                                  }
                                  placeholder={fieldName?.placeholder}
                                  type={
                                    fieldName?.type ? fieldName?.type : "text"
                                  }
                                  className="form-control"
                                  required={fieldName?.required}
                                  id={"validationCustom" + index}
                                  onChange={onChangeInput}
                                  disabled
                                />
                              ) : (
                                <input
                                  autoComplete="no"
                                  name={fieldName?.name}
                                  defaultValue={
                                    defaultData &&
                                    defaultData !== "null" &&
                                    defaultData !== "undefined" &&
                                    defaultData[fieldName?.name]
                                  }
                                  placeholder={fieldName?.placeholder}
                                  type={
                                    fieldName?.type ? fieldName?.type : "text"
                                  }
                                  className="form-control"
                                  required={fieldName?.required}
                                  id={"validationCustom" + index}
                                  onChange={onChangeInput}
                                />
                              )}
                            </div>
                          );
                        } else if (fieldName.type === "SingleSelect") {
                          const OldValue = isEdit
                            ? defaultData[fieldName?.name]
                            : null;
                          {
                          }
                          return (
                            <div key={index} className="mb-4">
                              <Label>
                                {fieldName?.label}{" "}
                                {fieldName?.required && (
                                  <sub style={{ color: "red" }}>*</sub>
                                )}
                              </Label>
                              <Select
                                value={
                                  selectedValue[fieldName?.name] ||
                                  option[fieldName?.name]?.filter(
                                    (item) => item.value == OldValue
                                  )[0] ||
                                  fieldName?.options?.filter(
                                    (item) =>
                                      item?.value?.toString()?.toUpperCase() ==
                                      OldValue?.toString()?.toUpperCase()
                                  )[0]
                                }
                                options={
                                  fieldName?.options
                                    ? fieldName?.options
                                    : option[fieldName?.name] || []
                                }
                                selected={"clientName" == fieldName?.name}
                                classNamePrefix="select2-selection"
                                onChange={(selected) => {
                                  onSelectValue(selected, fieldName?.name);
                                  if (onChangeFunction[fieldName?.name]) {
                                    onChangeFunction[fieldName?.name](selected);
                                  }
                                  changedetect[fieldName?.name] &&
                                    changedetect[fieldName?.name](
                                      selected?.label
                                    );
                                }}
                              />
                            </div>
                          );
                        } else if (
                          fieldName.type === "SingleSelectWithCreate"
                        ) {
                          const OldValue = isEdit
                            ? defaultData[fieldName?.name]
                            : null;
                          return (
                            <div key={index} className="mb-4">
                              <Label>
                                {fieldName?.label}{" "}
                                {fieldName?.required && (
                                  <sub style={{ color: "red" }}>*</sub>
                                )}
                              </Label>
                              <CreatableSelect
                                value={
                                  isEdit
                                    ? !isChange
                                      ? option[fieldName?.name] ||
                                        fieldName?.options.filter(
                                          (e) => e.value === OldValue
                                        )
                                      : selectedValue[fieldName?.name]
                                    : selectedValue[fieldName?.name]
                                }
                                options={
                                  fieldName?.options
                                    ? fieldName?.options
                                    : option[fieldName?.name]
                                    ? option[fieldName?.name]
                                    : []
                                }
                                selected={"clientName" == fieldName?.name}
                                classNamePrefix="select2-selection"
                                onChange={(selected) => {
                                  onSelectValue(selected, fieldName?.name);
                                  if (onChangeFunction[fieldName?.name]) {
                                    onChangeFunction[fieldName?.name](selected);
                                  }
                                }}
                              />
                            </div>
                          );
                        } else if (fieldName.type === "selectTime") {
                          return (
                            <div key={index} className="mb-4">
                              <Label>
                                {fieldName?.label}{" "}
                                {fieldName?.required && (
                                  <sub style={{ color: "red" }}>*</sub>
                                )}
                              </Label>
                              <input
                                name={fieldName?.name}
                                className="form-control"
                                type="time"
                                defaultValue="13:45:00"
                                id="example-time-input"
                                onChange={onChangeInput}
                                required={fieldName?.required}
                              />
                            </div>
                          );
                        } else if (fieldName.type === "GoogleAutoComplete") {
                          return (
                            <div key={index} className="mb-4">
                              <Label>
                                {fieldName?.label}{" "}
                                {fieldName?.required && (
                                  <sub style={{ color: "red" }}>*</sub>
                                )}
                              </Label>
                              <br />
                              {/* 
                              <Controller
                                name="location"
                                rules={{
                                  required: "This is a required field",
                                }}
                                control={"control"}

                                render={
                                  <GooglePlacesAutocomplete
                                    apiKey={
                                      "AIzaSyAIh5rjUYY8SoLb14LUnxrbhD2XnRsF_78"
                                    }
                                    apiOptions={{
                                      types: ["(cities)"],
                                      componentRestrictions: { country: "IN" },
                                    }}
                                    selectProps={{
                                      placeholder: fieldName.placeholder,
                                      onChange: (e) =>
                                        onChangeGoogleAddress(
                                          e,
                                          fieldName?.name
                                        ),
                                    }}
                                  />
                                }
                              /> */}

                              <GooglePlacesAutocomplete
                                apiKey={process.env.REACT_APP_MAP_KEY}
                                apiOptions={{
                                  types: ["(cities)"],
                                  componentRestrictions: { country: "IN" },
                                }}
                                selectProps={{
                                  placeholder: fieldName.placeholder,
                                  defaultInputValue:
                                    defaultData[fieldName?.name],
                                  onChange: (e) => {
                                    setAddress(e.label);
                                    // if (address) {

                                    return onChangeGoogleAddress(
                                      e,
                                      fieldName?.name
                                    );
                                    // } else {

                                    // }
                                  },
                                }}
                              />
                            </div>
                          );
                          // mobilenumber field required=================================================================================
                        } else if (fieldName.type === "mobileNumber") {
                          return (
                            <div key={index} className="mb-4">
                              <Label>
                                {fieldName?.label}{" "}
                                {fieldName?.required && (
                                  <sub style={{ color: "red" }}>*</sub>
                                )}
                              </Label>
                              <br />
                              <PhoneInput
                                inputProps={{
                                  name: "mobile",

                                  required: fieldName?.required,
                                }}
                                country="in"
                                onChange={(value, country, e, formattedValue) =>
                                  onChangeNumber(
                                    value,
                                    country,
                                    e,
                                    formattedValue
                                  )
                                }
                                inputStyle={{
                                  width: "100%",
                                }}
                              />
                            </div>
                          );
                        } else if (fieldName.type === "date") {
                          return (
                            <div key={index} className="mb-4">
                              <Label>
                                {fieldName?.label}{" "}
                                {fieldName?.required && (
                                  <sub style={{ color: "red" }}>*</sub>
                                )}
                              </Label>
                              <input
                                name={fieldName?.name}
                                className="form-control"
                                type="date"
                                defaultValue="13:45:00"
                                id="example-time-input"
                                onChange={onChangeInput}
                                required={fieldName?.required}
                              />
                            </div>
                          );
                        } else if (fieldName.type === "selectDate&Time") {
                          return (
                            <div key={index} className="mb-4">
                              <Label>
                                {fieldName?.label}{" "}
                                {fieldName?.required && (
                                  <sub style={{ color: "red" }}>*</sub>
                                )}
                              </Label>
                              <input
                                name={fieldName?.name}
                                className="form-control"
                                type="datetime-local"
                                defaultValue={moment(
                                  defaultData[fieldName?.name]
                                ).format("YYYY-MM-DDTHH:MM")}
                                id="example-datetime-local-input"
                                onChange={onChangeInput}
                                required={fieldName?.required}
                              />
                            </div>
                          );
                        } else if (fieldName.type === "file") {
                          return (
                            <div key={index} className="mb-4">
                              <Label>
                                {fieldName?.label}{" "}
                                {fieldName?.required && (
                                  <sub style={{ color: "red" }}>*</sub>
                                )}
                              </Label>
                              <Input
                                type={fieldName.type}
                                className="form-control"
                                name={fieldName?.name}
                                onChange={onChangeFileValue}
                                required={fieldName?.required}
                              />
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </div>
                  </Col>
                </Row>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={onCloseModal}
                    className="btn btn-primary waves-effect"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary waves-effect waves-light"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </Modal>
        )}
      </Col>
    </>
  );
};

export default CustomModal;
