import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, CardTitle, Label } from "reactstrap";

import Select from "react-select";

import CONSTANT, { MonthName, viewRole } from "../../Utility/constnt";

import useHttp from "../../../components/Hook/Use-http";
// import HomeChart1 from "../../components/Custome/Charts/HomeChart1";
import HomeChart1 from "../../../components/Custome/Charts/HomeChart1";

import { useSelector } from "react-redux";
import { analysisFilter, cardAnalyticsData } from "../../Utility/option";
import CardGen from "../../../components/dashboard/cardGen";
import TableGen from "../../../components/dashboard/tableGen";
import {
  dashboardAnalysis,
  dashboardAnalysisNew,
  emissionDashboardDataLoad,
  emissionDashboardDataLoadNew,
  headerFortSiteGen,
} from "../../Utility/spread-function";
import EmissionGen from "../../../components/dashboard/emissionGen";
import PageTitle from "../../../components/pageTitle";
import { useLocation, useParams } from "react-router-dom";
import authStorage from "../../Utility/API/authStroge";

let fiscalyear = "";
const today = new Date();
if (today.getMonth() + 1 <= 3) {
  fiscalyear = today.getFullYear() - 1;
} else {
  fiscalyear = today.getFullYear();
}

const CardData = {
  admin: cardAnalyticsData,
  client: cardAnalyticsData,
  siteviewer: cardAnalyticsData,
};

const SiteViewerReport = () => {
  const [tripList, setTripList] = useState([]);
  const myData = useSelector((state) => state.userGetMeData);
  const [financialYear, setFinancialYear] = useState("2023-24");
  const [categoryDataLabel, setCategoryDataLabel] = useState({});
  const [subCategory, setSubCategory] = useState([]);

  const userRole = localStorage.getItem("userRole")?.toLocaleLowerCase();
  const isLogin =
    authStorage?.getAuthToken() !== undefined &&
    authStorage?.getAuthToken() !== null;
  const location = useLocation();
  const currentPath = location.pathname?.toLocaleLowerCase();
  const { id } = useParams();
  const API_CALL = useHttp();

  // useEffect(() => {
  //   let analyticsAPIendpoint =
  //     CONSTANT.API.CATEGORYFORM.getCarbonEmissionAnalysis.endpoint;
  //   if (financialYear != null) {
  //     analyticsAPIendpoint += "?value=" + financialYear;
  //   }
  //   API_CALL.getAll(
  //     [
  //       analyticsAPIendpoint,
  //       CONSTANT.API.CATEGORY.get.endpoint,
  //       CONSTANT.API.SUBCATEGORY.get.endpoint,
  //     ],
  //     (res) => {
  //       const rees = emissionDashboardDataLoad(
  //         res,
  //         {},
  //         [],
  //         setChartView,
  //         setAvailableFinancialYear
  //       );

  //       setCategoryDataLabel(rees?.categoryData);
  //     }
  //   );
  // }, [financialYear]);

  useEffect(() => {
    if (!isLogin || !userRole) return;
    let analyticsAPIendpoint = { ...CONSTANT.API.DASHBOARD.getAnalysis };

    // if (financialYear != null) {
    if (userRole == "admin" && currentPath?.includes(id)) {
      analyticsAPIendpoint.endpoint = `${analyticsAPIendpoint.endpoint}/plant/${id}?value=${financialYear}`;
    } else {
      analyticsAPIendpoint.endpoint = `${analyticsAPIendpoint.endpoint}?value=${financialYear}`;
    }
    // }
    API_CALL.getAll(
      [
        analyticsAPIendpoint.endpoint,
        CONSTANT.API.CATEGORY.get.endpoint,
        CONSTANT.API.SUBCATEGORY.get.endpoint,
      ],
      (res) => {
        // setAnalyticData(res?.[0]?.data);
        const rees = emissionDashboardDataLoadNew(res, {}, []);
        setSubCategory(res[2]?.data?.data?.rows);
        analysisDataHandler(res?.[0]?.data);
        setCategoryDataLabel(rees?.categoryData);
      }
    );

    // API_CALL.sendRequest(analyticsAPIendpoint, analysisDataHandler);
  }, []);

  const analysisDataHandler = (res) => {
    // For Admin
    dashboardAnalysisNew(res, CardData, null, setTripList, currentPath, id);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <PageTitle />
        <Row>
          <h4 className="page-title mb-3 font-size-18 mt-4">
            Snapshot (FY {fiscalyear}-{(fiscalyear + 1).toString().substring(2)}
            )
          </h4>
          <CardGen
            CardData={CardData?.["siteviewer"]}
            fiscalyear={fiscalyear}
          />
        </Row>
        <TableGen
          transporterList={null}
          myData={myData}
          tripList={tripList}
          categoryDataLabel={subCategory}
          siteWiseList = {true}
        />
      </div>
    </React.Fragment>
  );
};

export default SiteViewerReport;
