import React, { createRef, useEffect, useRef, useState } from "react";
import useHttp from "../../components/Hook/Use-http";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { allPackageData, checkIsDataExist } from "../Utility/helperData";
import { tripMask } from "../Utility/spread-function";
import CONSTANT, {
  TABLE_HEADER,
  getTableData,
  siteRoute,
  tripRoute,
  viewRole,
} from "../Utility/constnt";
import PackageModal from "../../components/shipmentPage/packageModal";
import BillForm from "../BillForm";
import Table from "../../components/Custome/table";
import PageTitle from "../../components/pageTitle";
import { replaceId } from "../Utility/function";
import { DatePicker } from "antd";
import jsPDF from "jspdf";
import { ROUTES } from "../Utility/Route";
import { getShipmentStart } from "../../store/actions";
import { tripHeaderData } from "../Utility/exportValue";
import Loader from "../../components/Loader";

const { RangePicker } = DatePicker;
let tripCreateHeader = [];

const TripRoleShipmentHistory = () => {
  const [showModel, setShowModel] = useState(false);
  const [showModel_1, setShowModel_1] = useState(false);
  const [tripData, setTripData] = useState([]);
  const [actionData, setActionData] = useState({});
  const [confirm_both, setConfirm_both] = useState(false);
  const [flag, setFlag] = useState(true);
  const [isEdit, setIsEdit] = useState(false);

  const [packageTypeData, setPackageTypeData] = useState([]);

  const [downloadData, setDownloadData] = useState({});

  const [packageOpen, setPackageOpen] = useState(false);
  const [packageData, setPackageData] = useState({});
  const [filterParams, setFilterParams] = useState("");
  const myData = useSelector((state) => state.userGetMeData);
  const [loading, setLoading] = useState(true);

  const reportTemplateRef = useRef(null);
  const API_CALL = useHttp();
  const SEARCH_API_CALL = useHttp();
  const dispatch = useDispatch();

  const getShipmentData = useSelector((state) => state.shipmentDetailData);

  const { id } = useParams();
  const getPackagingData = useSelector((state) => state.packagingDetailData);

  //not admin
  useEffect(() => {
    if (localStorage.getItem("userRole") !== "admin") {
      setTimeout(() => {
        if (myData?.users?.id) {
          const tripData = getShipmentData?.users?.data?.rows?.filter(
            (it) => it?.clientId == myData?.users?.id
          );
          tripDataHandler(tripData);
        }
      }, 100);
    }
  }, [flag, myData?.users, getShipmentData]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      checkIsDataExist(allPackageData)
        ? setPackageTypeData(allPackageData)
        : packageDataHandler(getPackagingData?.users, allPackageData);
    }, 100);
  }, [getPackagingData]);

  useEffect(() => {
    const apiObject = { ...CONSTANT.API.SHIPMENT.get };

    if (filterParams !== "") {
      apiObject.endpoint += filterParams;
      SEARCH_API_CALL.sendRequest(apiObject, (res) => {
        tripDataHandler(res?.data?.rows);
      });
    } else {
      setTimeout(() => {
        tripDataHandler(getShipmentData?.users?.data?.rows);
      }, 100);
    }
  }, [flag, filterParams, getShipmentData?.users?.data]);

  const packageDataHandler = (res, allPackageData) => {
    setPackageTypeData(res?.data?.rows);
    allPackageData = res?.data?.rows;
  };

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      orientation: "1",
      format: [2480, 3508],
      unit: "px",
    });

    // Adding the fonts.
    doc.setFont("Inter-Regular", "normal");

    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        await doc.save("document");
      },
    });
  };
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef([]);

  const tripDataHandler = (res) => {
    hiddenFileInput.current = res?.map?.(
      // (tripDataB, i) => hiddenFileInput?.current[i] ?? createRef()
      (tripDataB, i) => hiddenFileInput?.current?.[i] ?? createRef()
    );

    (async () => {
      // const packageRes = await Services.get(
      //   CONSTANT.API.PACKAGEING.getPackage.endpoint
      // );
      let PackagingEmission = {};
      packageTypeData?.users?.data?.map((packageData) => {
        PackagingEmission = {
          ...PackagingEmission,
          [packageData?.id]: packageData?.tCO2PerShipment,
        };
      });

      setTripData(
        tripMask(
          res,
          hiddenFileInput,
          PackagingEmission,
          handleChange,
          handleGeneratePdf,
          setDownloadData,
          onPackage,
          onEditTrip,
          setShowModel_1,
          setShowModel,
          openConfirmationDeleteModal,
          `${tripRoute}/${ROUTES.ADMIN_TRACKING}/`
        )
      );
      setLoading(false);
    })();
  };

  const openConfirmationDeleteModal = (tripData) => {
    setConfirm_both(true);
    setActionData(tripData);
  };

  const onEditTrip = (tripData) => {
    setActionData(tripData);

    setIsEdit(true);
  };

  const onPackage = (tripData) => {
    setPackageData(tripData);

    setPackageOpen(true);
  };

  const handleChange = (e, tripDatas) => {
    const payload = new FormData();

    // tripData.POD = e.target?.files[0];
    payload.append("POD", e.target.files[0]);

    (async () => {
      const fileUploaded = e.target.files[0];
      if (e.target?.files[0]) {
        API_CALL.sendRequest(
          replaceId(CONSTANT.API.SHIPMENT.update, tripDatas?.id),
          () => {
            setFlag((previous) => !previous);
            dispatch(getShipmentStart());
          },
          payload,
          "Shipment updated successfully"
        );
      }

      if (e.target?.files[0] === null) {
      }
    })();
  };

  const onCloseModal = () => {
    setPackageOpen(false);
    setPackageData(null);
  };

  useEffect(() => {
    if (localStorage.getItem("userRole") != "admin") {
      tripCreateHeader = [
        tripHeaderData?.filter(([key]) => {
          return ![
            "transporterName",
            "driverName",
            "driverPhoneNumber",
          ].includes(key);
        }),
      ];
    }
  }, []);

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <PageTitle pageHeading={`Shipments`} />
        <div className="d-flex justify-content-between mt-3 mb-3">
          <div></div>
          <RangePicker
            onChange={(e) => {
              if (e == null) {
                setFilterParams("");
              } else {
                setFilterParams(
                  `&startDate=${e?.[0]?.format(
                    "YYYY-MM-DD"
                  )}&endDate=${e?.[1]?.format("YYYY-MM-DD")}`
                );
              }
            }}
          />
        </div>
      </div>

      {API_CALL?.isLoading || loading ? (
        <Loader />
      ) : (
        <div className="page-content mt-0 pt-0 scrollSticky custom-Table">
          <Table
            title="Shipment list"
            data={getTableData(
              TABLE_HEADER?.shipment?.filter((it) => {
                return !(it === "action" || it === "pod");
              }),
              tripData
            )}
            exportHeader={
              localStorage.getItem("userRole") !== "admin"
                ? tripCreateHeader
                : tripHeaderData
            }
            // exportValue={tripValueData(tripData)}
            excelName="Shipment_Report.csv"
          />
        </div>
      )}

      {packageOpen && (
        <PackageModal
          extraValue={{
            packageData: packageData,
            packageTypeData: packageTypeData,
            onCloseModal: onCloseModal,
          }}
        />
      )}

      <div className="d-none">
        <div className="" ref={reportTemplateRef}>
          <BillForm trip={downloadData} packageList={packageTypeData} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default TripRoleShipmentHistory;
