import React from "react";
import { attributeDataMask } from "../../Utility/spread-function";
import CustomPage from "../../../components/Custome/CustomPage";
import CONSTANT, { TABLE_HEADER } from "../../Utility/constnt";

const Attributes = () => {
  return (
    <>
      <div className="page-content">
        <CustomPage
          // API
          PAGE={{
            title: "ATTRIBUTE",
            dataFunction: attributeDataMask,
          }}
          API={CONSTANT.API.ATTRIBUTE}
          // CreateAPI={CONSTANT.API.ATTRIBUTE.addAttribute}
          // UpdateAPI={CONSTANT.API.ATTRIBUTE.updateAttribute}
          // DeleteAPI={CONSTANT.API.ATTRIBUTE.deleteAttribute}
          // GetAPI={CONSTANT.API.ATTRIBUTE.getAttribute}
          // GetSingle={`${
          //   localStorage.getItem("userRole") == "client"
          //     ? `?clientId=${myData?.users?.clientId}`
          //     : ""
          // }`}
          FORM={{
            formField: CONSTANT.FORM_FIELDS?.ATTRIBUTE,
            addFormTitle: "Create Attribute",
            editFormTitle: "Edit Attribute",
            addNewRecordCtaText: "Add Attribute",
            // optionalFieldOnEdit: ["tripEndDistance", "insuranceNumber"],
          }}
          TABLE={{
            title: "Attribute List",
            header: TABLE_HEADER?.admin_Attribute,
          }}
          pageHeading="Attribute"
          // Model
          ModalName={`ATTRIBUTE`} //Form Model
          tableColumn="admin_Attribute"
          //Form Customization
          // addName="Create Attribute"
          // addFormTitle="Create New Attribute"
          // editFormTitle="Edit Attribute"
          // formData={true}
          // removeFieldOnEdit={[]}
          option={
            {
              //   categoryId: subCategory?.map((data) => {
              //     return {
              //       label: data?.name,
              //       value: data?.id,
              //       // categoryId: data?.categoryId,
              //       // categoryName: data?.category?.name,
              //     };
              //   }),
            }
          }
          // onChange={(res) => {}}
          // extraConstPayload={

          // }
          // Table Customization
          // titleHeader="Attribute List"
          // datafunction={(res) => {
          //   return attributeDataMask(res);
          // }}
          // ExportHeaders={Fitsol_Team}
          // ExportValues={driverValueData(driverDataExcel)}
          // excelName="Fitsol_Team.csv"
          // viewModelData={false}
        />
      </div>
    </>
  );
};

export default Attributes;
