import React, { useState } from "react";
import { Col, Label, Row, Input, Modal } from "reactstrap";

const FormForModal = (props) => {
  const {
    onSubmitForm,
    data,
    viewModel,
    isSubmitButtonVisible,
    minHeight,
    maxHeight = 550,
    scroll = "scroll",
    onCloseModal,
    formField,
    inputData,
    isCloseButtonVisible,
    submitBtnName,
    fieldSize,
    labelSize,
    submitBtnPosition,
    modalType,
  } = props;

  return (
    <>
      <div>
        <form
          className="needs-validation"
          onSubmit={onSubmitForm}
          style={{
            minHeight: minHeight,
            maxHeight: `${maxHeight}px`,
            overflowY: scroll,
            overflowX: `${scroll == "none" ? "none" : "hidden"}`,
          }}
        >
          <Row
            className="mb-3 "
            style={{ paddingRight: "15px", paddingLeft: "15px" }}
          >
            {/* <Col md="12"l
              <div > */}
            {data?.map((fieldName, index) => {
              if (fieldName?.isShow(inputData)) {
                return formField(fieldName, index);
              }
            })}
            {/* </div>
            </Col> */}
          </Row>

          {viewModel}

          {modalType == "formModal" ? (
            <div className="modal-footer">
              {isCloseButtonVisible && (
                <button
                  type="button"
                  onClick={onCloseModal}
                  className="btn btn-primary waves-effect"
                  data-dismiss="modal"
                >
                  Close
                </button>
              )}
              {isSubmitButtonVisible && (
                <button
                  type="submit"
                  className="btn btn-primary waves-effect waves-light"
                >
                  {submitBtnName}
                </button>
              )}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: submitBtnPosition,
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              {isSubmitButtonVisible && (
                <button
                  type="submit"
                  className="btn btn-primary waves-effect waves-light"
                >
                  {submitBtnName}
                </button>
              )}
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export default FormForModal;
