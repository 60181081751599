// import PropTypes from "prop-types";
// import React, { Component } from "react";

// import { connect } from "react-redux";
// import {
//   Navigate,
//   Outlet,
//   useLocation,
//   useNavigate,
//   useParams,
//   useResolvedPath,
// } from "react-router-dom";
// import {
//   changeLayout,
//   changeSidebarTheme,
//   changeSidebarType,
//   changeTopbarTheme,
//   changeLayoutWidth,
// } from "../../store/actions";

// import Header from "./Header";
// import Sidebar from "./Sidebar";
// import Footer from "./Footer";
// import Rightbar from "../CommonForBoth/Rightbar";

// function withRouter(Component) {
//   function ComponentWithRouterProp(props) {
//     let location = useLocation();
//     let navigate = useNavigate();
//     let params = useParams();
//     // const { pathname } = useResolvedPath();

//     return (
//       <Component
//         {...props}
//         // pathname={pathname}
//         router={{ location, navigate, params }}
//       />
//     );
//   }

//   return ComponentWithRouterProp;
// }

// class Layout extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
//     };
//     this.toggleMenuCallback = this.toggleMenuCallback.bind(this);
//   }

//   capitalizeFirstLetter = (string) => {
//     return string.charAt(1).toUpperCase() + string.slice(2);
//   };

//   componentDidMount() {
//     if (this.props.isPreloader === true) {
//       document.getElementById("preloader").style.display = "block";
//       document.getElementById("status").style.display = "block";

//       setTimeout(function () {
//         document.getElementById("preloader").style.display = "none";
//         document.getElementById("status").style.display = "none";
//       }, 2500);
//     } else {
//       document.getElementById("preloader").style.display = "none";
//       document.getElementById("status").style.display = "none";
//     }

//     // Scroll Top to 0
//     window.scrollTo(0, 0);
//     let currentage = this.capitalizeFirstLetter(
//       this.props.router.location.pathname
//     );

//     document.title = currentage + " | Fitsol Dashboard";
//     if (this.props.leftSideBarTheme) {
//       this.props.changeSidebarTheme(this.props.leftSideBarTheme);
//     }

//     this.props.changeLayout("detached");

//     if (this.props.layoutWidth) {
//       this.props.changeLayoutWidth(this.props.layoutWidth);
//     }

//     if (this.props.leftSideBarType) {
//       this.props.changeSidebarType(this.props.leftSideBarType);
//     }
//     if (this.props.topbarTheme) {
//       this.props.changeTopbarTheme(this.props.topbarTheme);
//     }
//   }

//   toggleMenuCallback = () => {
//     if (this.props.leftSideBarType === "default") {
//       this.props.changeSidebarType("condensed", this.state.isMobile);
//     } else if (this.props.leftSideBarType === "condensed") {
//       this.props.changeSidebarType("default", this.state.isMobile);
//     }
//   };

//   render() {
//     console.log(this.props);
//     return (
//       <React.Fragment>
//         <div id="preloader">
//           <div id="status">
//             <div className="spinner-chase">
//               <div className="chase-dot"></div>
//               <div className="chase-dot"></div>
//               <div className="chase-dot"></div>
//               <div className="chase-dot"></div>
//               <div className="chase-dot"></div>
//               <div className="chase-dot"></div>
//             </div>
//           </div>
//         </div>
//         <div className="container-fluid">
//           <div id="layout-wrapper">
//             <Header toggleMenuCallback={this.toggleMenuCallback} />
//             {localStorage.getItem("userRole") !== "tripCreator" ? (
//               <Sidebar
//                 theme={this.props.leftSideBarTheme}
//                 type={this.props.leftSideBarType}
//                 isMobile={this.state.isMobile}
//               />
//             ) : (
//               <Navigate to="/" />
//             )}

//             <div className="main-content">
//               {this.props.children}
//               <Outlet />
//               <Footer />
//             </div>
//           </div>
//         </div>
//         {this.props.showRightSidebar ? <Rightbar /> : null}
//       </React.Fragment>
//     );
//   }
// }

// Layout.propTypes = {
//   changeLayoutWidth: PropTypes.func,
//   changeSidebarTheme: PropTypes.func,
//   changeSidebarType: PropTypes.func,
//   changeTopbarTheme: PropTypes.func,
//   children: PropTypes.object,
//   isPreloader: PropTypes.any,
//   layoutWidth: PropTypes.any,
//   leftSideBarTheme: PropTypes.any,
//   leftSideBarType: PropTypes.any,
//   location: PropTypes.object,
//   showRightSidebar: PropTypes.any,
//   topbarTheme: PropTypes.any,
// };

// const mapStatetoProps = (state) => {
//   return {
//     ...state.Layout,
//   };
// };
// export default connect(mapStatetoProps, {
//   changeLayout,
//   changeSidebarTheme,
//   changeSidebarType,
//   changeTopbarTheme,
//   changeLayoutWidth,
// })(withRouter(Layout));

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
} from "../../store/actions";

import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { connect } from "react-redux";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Rightbar from "../CommonForBoth/Rightbar";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    // const { pathname } = useResolvedPath();

    return (
      <Component
        {...props}
        // pathname={pathname}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

function Layout(props) {
  const isPreloader = useSelector((state) => state.Layout.isPreloader);
  const leftSideBarTheme = useSelector(
    (state) => state.Layout.leftSideBarTheme
  );
  const leftSideBarType = useSelector((state) => state.Layout.leftSideBarType);
  const layoutWidth = useSelector((state) => state.Layout.layoutWidth);
  const topbarTheme = useSelector((state) => state.Layout.topbarTheme);
  const showRightSidebar = useSelector(
    (state) => state.Layout.showRightSidebar
  );

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const [isMobile, setIsMobile] = useState(
    /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  );

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(2);
  };

  useEffect(() => {
    if (isPreloader === true) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
      setTimeout(function () {
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
      }, 2500);
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
    window.scrollTo(0, 0);
    let currentage = capitalizeFirstLetter(location.pathname);
    document.title = currentage + " | Fitsol Dashboard";
    if (leftSideBarTheme) {
      dispatch(changeSidebarTheme(leftSideBarTheme));
    }
    dispatch(changeLayout("detached"));
    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth));
    }
    if (leftSideBarType) {
      dispatch(changeSidebarType(leftSideBarType));
    }
    if (topbarTheme) {
      dispatch(changeTopbarTheme(topbarTheme));
    }
  }, [
    isPreloader,
    leftSideBarTheme,
    leftSideBarType,
    layoutWidth,
    topbarTheme,
    location.pathname,
  ]);

  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      changeSidebarType("condensed", isMobile);
    } else if (leftSideBarType === "condensed") {
      changeSidebarType("default", isMobile);
    }
  };

  return (
    <React.Fragment>
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div id="layout-wrapper">
          <Header toggleMenuCallback={toggleMenuCallback} />
          {props?.SideBarMenu &&
          !window.location.pathname.includes("/shipmentform") ? (
            <Sidebar
              theme={leftSideBarTheme}
              type={leftSideBarType}
              isMobile={isMobile}
              SideBarMenu={props?.SideBarMenu}
            />
          ) : (
            // <Navigate to="/" />
            <></>
          )}

          <div className="main-content">
            {props.children}
            <Outlet />
            <Footer />
          </div>
        </div>
      </div>
      {showRightSidebar ? <Rightbar /> : null}
    </React.Fragment>
  );
}

// export default Layout;
const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
  };
};
export default connect(mapStatetoProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
})(withRouter(Layout));
