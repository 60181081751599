/* USERS */
// export const GET_USERS = "GET_USERS";
// export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
// export const GET_USERS_FAIL = "GET_USERS_FAIL";

// /* USERS PROFILE */
// export const GET_USER_PROFILE = "GET_USER_PROFILE";
// export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
// export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL";

// export const GET_DRIVER_DETAIL_SUCCESS = "GET_DRIVER_DETAIL_SUCCESS";
// export const GET_MY_PROFILE = "GET_MY_PROFILE";

export const PACKAGING_DETAIL = "PACKAGING_DETAIL";
export const SET_PACKAGING_DETAIL = "SET_PACKAGING_DETAIL";

export const DELETE_ADMIN_DASHBOARD_START = "DELETE_ADMIN_DASHBOARD_START";
export const DELETE_ADMIN_DASHBOARD_SUCCESS = "DELETE_ADMIN_DASHBOARD_SUCCESS";
export const DELETE_ADMIN_DASHBOARD_FAILURE = "DELETE_ADMIN_DASHBOARD_FAILURE";

export const GET_ADMIN_DASHBOARD_START = "GET_ADMIN_DASHBOARD_START";
export const GET_ADMIN_DASHBOARD_SUCCESS = "GET_ADMIN_DASHBOARD_SUCCESS";
export const GET_ADMIN_DASHBOARD_FAILURE = "GET_ADMIN_DASHBOARD_FAILURE";
