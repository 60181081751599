// import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
// import { SET_VEHICLE_DETAIL, VEHICLE_DETAIL } from "./actionTypes";
// import Apicall from "../../components/Hook/Api-call";
// import CONSTANT from "../../pages/Utility/constnt";

// const API_URL = "https://jsonplaceholder.typicode.com/users";

// function* getVehicle() {
//   try {
//     const res = yield Apicall.get(CONSTANT.API.VEHICLE.getVehicle.endpoint);

//     yield put({ type: SET_VEHICLE_DETAIL, data: res });
//     // debugger;
//   } catch (error) {
//     console.log(error);
//     // yield put({ type: "GET_MY_PROFILE_ERROR", error });
//   }
// }

// function* GetVehicleDetailSaga() {
//   yield takeEvery(VEHICLE_DETAIL, getVehicle);
// }

// export default GetVehicleDetailSaga;

import { put, takeLatest } from "redux-saga/effects";
import { DELETE_VEHICLE_START, GET_VEHICLE_START } from "./actionTypes";
import Apicall from "../../components/Hook/Api-call";
import CONSTANT from "../../pages/Utility/constnt";
import {
  deleteVehicleError,
  deleteVehicleSucess,
  getVehicleError,
  getVehicleSuccess,
} from "./actions";
import { replaceEndPointId } from "../../pages/Utility/function";

function* getVehicle() {
  try {
    const res = yield Apicall?.get(CONSTANT.API.VEHICLE.get.endpoint);
    yield put(getVehicleSuccess(res));
  } catch (error) {
    console.log(error);
    yield put(getVehicleError(error?.message));

    // yield put({ type: GET_CUSTOMER_FAILURE, error });
  }
}

function* deleteVehicleSaga(user) {
  try {
    const response = yield Apicall?.remove(
      replaceEndPointId(CONSTANT?.API?.VEHICLE?.delete?.endpoint, user?.payload)
    );

    if (response?.status == "success") {
      if (user?.successCallback) {
        user?.successCallback();
      }
    }
    yield put(deleteVehicleSucess(user));
  } catch (error) {
    console.log(error?.message, "responce");
    yield put(deleteVehicleError(error?.message));
  }
}

function* GetVehicleDetailSaga() {
  yield takeLatest(GET_VEHICLE_START, getVehicle);
  yield takeLatest(DELETE_VEHICLE_START, deleteVehicleSaga);
}

export default GetVehicleDetailSaga;
