import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Label,
  Tooltip,
  Card,
  CardBody,
  CardSubtitle,
} from "reactstrap";
import PageTitle from "../../../../components/pageTitle";
import CardGen from "../../../../components/dashboard/cardGen";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useHttp from "../../../../components/Hook/Use-http";
import CONSTANT, {
  getLatestEightFinancialYears,
  scope3SubCategories,
  scopeWiseEmissionEquivalent,
  truncateAndAddCommas,
} from "../../../Utility/constnt";
import { Carousel, Button, Switch, Form } from "antd";
import TotalEmission from "../../../../components/scopecomponents/totalEmission";
import {
  scopeWiseDashboardAnalysis,
  scopeWiseEmissionDashboardDataLoad,
} from "../../../Utility/spread-function";
import { useSelector } from "react-redux";
import { cardAnalyticsData } from "../../../Utility/option";
import authStorage from "../../../Utility/API/authStroge";
import EmissionGen from "../../../../components/scopecomponents/emissionGen";
import CheckBox from "../../../../components/scopecomponents/checkBox";
import {
  categoryAttributeDataType,
  categoryAttributeForm,
} from "../../../Utility/models/form-model";
import { ConsolidatedItemsPosition } from "igniteui-react-charts";
import TableGen from "../../../../components/dashboard/tableGen";
import MapComponent from "../Scope3Report/MapComponent";
import MapComponent2 from "../Scope3Report/MapComponent2";
import { update } from "lodash";
import CheckBox1 from "../../../../components/scopecomponents/checkBox1";
import useSessionStorage from "../../ToggleFunction/useSessionStorage.js";
let fiscalyear = "";
const today = new Date();
if (today.getMonth() + 1 <= 3) {
  fiscalyear = today.getFullYear() - 1;
} else {
  fiscalyear = today.getFullYear();
}

const CardData = {
  admin: cardAnalyticsData,
  client: cardAnalyticsData,
  siteViewer: cardAnalyticsData,
};
const Index = () => {
  const [tripList, setTripList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const myData = useSelector((state) => state.userGetMeData);
  const isLogin =
    authStorage?.getAuthToken() !== undefined &&
    authStorage?.getAuthToken() !== null;
  const userRole = localStorage.getItem("userRole")?.toLocaleLowerCase();
  const [financialYear, setFinancialYear] = useState(
    `${fiscalyear}-${(fiscalyear + 1).toString().substring(2)}`
  );
  const [prevFinancialYear, setPrevFinancialYear] = useState(
    `${fiscalyear}-${(fiscalyear + 1).toString().substring(2)}`
  );
  const [prevSelectedFY, setPrevSelectedFY] = useState([]);
  const [AvailableFinancialYear, setAvailableFinancialYear] = useState([]);
  const [selectedCalendarYear, setSelectedCalendarYear] = useState([]); // New line
  const [selectedFY, setSelectedFY] = useState([financialYear]);
  const API_CALL = useHttp();
  const location = useLocation();
  const { id } = useParams();
  const currentPath = location.pathname?.toLocaleLowerCase();
  const [categoryDataLabel, setCategoryDataLabel] = useState({});
  const [categoryHead, setCategoryHead] = useState([]);
  const [subCategoryCheckboxData, setSubCategoryCheckboxData] = useState([]);
  const [fyCheckboxData, setFYCheckboxData] = useState([]);
  const [calendarYearCheckboxData, setCalendarYearCheckboxData] = useState([]); // new added
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [scope3Data, setScope3Data] = useState({});
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [categoryDataLoading, setCategoryDataLoading] = useState(true);
  const [totalEmission, setTotalEmission] = useState("0");
  const [chartView, setChartView] = useState(<></>);
  const [stackView, setStackView] = useState(<></>);
  const [lineView, setLineView] = useState(<></>);
  const [mapData, setMapData] = useState([]);
  const [tableHeading, setTableHeading] = useState([]);
  const [table2Heading, setTable2Heading] = useState([]);
  const [intensityMetrics, setIntensityMetrics] = useState(false);

  const isFY =
    localStorage.getItem("selectedYear") == "financialYear" ? true : false;
  const [isFinancialYear, setIsFinancialYear] = useState(isFY);

  useEffect(() => {
    API_CALL.getAll(
      [
        CONSTANT.API.CATEGORY.get.endpoint,
        CONSTANT.API.SUBCATEGORY.get.endpoint,
      ],
      (res) => {
        setCategoryData(res[0]?.data?.data?.rows);
        setSubCategoryData(res[1]?.data?.data?.rows);
      }
    );
    // }
  }, []);

  useEffect(() => {
    if (!isLogin || !userRole) return;
    if (categoryData.length == 0 || subCategoryData.length == 0) return;
    if (
      selectedFY.length > 0 &&
      JSON.stringify(selectedFY) == JSON.stringify(prevSelectedFY)
    ) {
      return;
    }
    let analyticsAPIendpoint = { ...CONSTANT.API.DASHBOARD.getAnalysis };
    if (selectedFY && selectedFY?.length > 0) {
      if (userRole == "admin" && currentPath?.includes(id)) {
        analyticsAPIendpoint.endpoint = selectedFY
          .filter((fy) => !(fy in scope3Data))
          .map((fy) => {
            return `${analyticsAPIendpoint.endpoint}/${id}?value=${fy}`;
          });
      } else {
        analyticsAPIendpoint.endpoint = selectedFY
          .filter((fy) => !(fy in scope3Data))
          .map((fy) => {
            return `${analyticsAPIendpoint.endpoint}?value=${fy}`;
          });
      }
    } else {
      if (userRole == "admin" && currentPath?.includes(id)) {
        analyticsAPIendpoint.endpoint = `${analyticsAPIendpoint.endpoint}/${id}`;
      }
    }
    const apiEndpoints = Array.isArray(analyticsAPIendpoint.endpoint)
      ? analyticsAPIendpoint.endpoint
      : [analyticsAPIendpoint.endpoint];
    API_CALL.getAll([...apiEndpoints], (res) => {
      const scopeDataObj = scope3Data;
      const filtered_rows = [];
      for (const response of res) {
        const fy = response?.config?.url?.split("?value=")[1];
        if (fy) scopeDataObj[fy] = response?.data?.data;
        for (const subcategory of response?.data?.data?.clientEmissionData) {
          if (
            subcategory?.subCategoryId != undefined &&
            subcategory?.scope == "Biogenic" &&
            subcategory?.carbonEmissionSum > 0 &&
            filtered_rows.filter((el) => el.id == subcategory?.subCategoryId)
              .length == 0
          ) {
            filtered_rows.push(
              subCategoryData.filter((row) => {
                return row?.id == subcategory?.subCategoryId;
              })[0]
            );
          }
        }
      }
      setScope3Data((prevData) => ({
        ...prevData,
        ...scope3Data,
      }));
      if (
        res[0]?.data?.data?.getAllFinancialYearData &&
        AvailableFinancialYear.length == 0
      )
        setAvailableFinancialYear(res[0]?.data?.data?.getAllFinancialYearData);
      if (
        filtered_rows.length > 0 &&
        categoryHead.length !== filtered_rows.length
      ) {
        setCategoryHead(filtered_rows);
      }
    });
    setPrevSelectedFY(selectedFY);
  }, [subCategoryData, selectedFY]);

  useEffect(() => {
    const [scopeObj] = categoryData.filter((row) => {
      return row?.name === "Biogenic";
    });
    const scopeId = scopeObj?.id;
    setCategoryDataLoading(false);
    const rees = scopeWiseEmissionDashboardDataLoad(
      scope3Data,
      {},
      [],
      scopeId,
      setStackView,
      setChartView,
      setLineView,
      setAvailableFinancialYear,
      selectedSubCategories,
      selectedFY,
      setTripList,
      "Biogenic",
      setTableHeading,
      setCategoryList,
      setTable2Heading,
      setMapData
    );
    setCategoryDataLabel(rees?.categoryData);

    setPrevFinancialYear(financialYear);
  }, [scope3Data, selectedSubCategories, selectedFY]);

  useEffect(() => {
    setSubCategoryCheckboxData({
      options: categoryHead.map((el) => {
        return {
          value: el?.id,
          label: el?.name,
        };
      }),
      defaultData: categoryHead.map((item) => {
        return item?.id;
      }),
    });
    setSelectedSubCategories(
      categoryHead.map((item) => {
        return item?.id;
      })
    );
    setFYCheckboxData({
      options: AvailableFinancialYear.filter((year) =>
        String(year.value).match(/^\d{4}-\d{2}$/)
      ).map((fy) => ({
        value: fy.value,
        label: fy.value,
      })),
      defaultData: [financialYear],
    });
    // New: Set calendar year checkbox data
    setCalendarYearCheckboxData({
      options: AvailableFinancialYear.filter(
        (year) => !String(year.value).match(/^\d{4}-\d{2}$/)
      ).map((fy) => ({
        value: fy.value,
        label: fy.value,
      })),
      defaultData: [financialYear],
    });
    let calendarYears = [],
      financialYears = [];
    for (const item of AvailableFinancialYear) {
      if (item.value) {
        let flag = item.value;
        if (flag.includes("-")) {
          financialYears.push(flag);
        } else {
          calendarYears.push(flag);
        }
      }
    }

    if (isFinancialYear) {
      setSelectedFY(financialYears);
    } else {
      setSelectedFY(calendarYears);
    }
  }, [categoryHead, AvailableFinancialYear, financialYear, isFinancialYear]);

  useEffect(() => {
    let data = categoryDataLabel;
    const countsArray = Object.keys(data).map((yearKey) =>
      Object.keys(data[yearKey]).map((subKey) => {
        if (data[yearKey][subKey]?.name === "Biogenic") {
          return Object.values(data[yearKey][subKey].data).map(
            (subData) => subData.count
          );
        }
        return null;
      })
    );
    const totalSum = countsArray.flat(2).reduce((sum, count) => sum + count, 0);
    let formattedTotal = totalSum.toLocaleString("en-IN");
    formattedTotal = formattedTotal.split(".")[0];
    setTotalEmission(formattedTotal);
  }, [categoryDataLabel]);

  // new added for persit changes in toggle---

  const handleCategoryFilter = (category) => {
    if (selectedSubCategories.includes(category)) {
      setSelectedSubCategories(
        selectedSubCategories.filter((c) => c !== category)
      );
    } else {
      setSelectedSubCategories([...selectedSubCategories, category]);
    }
  };

  const handleToggleChange = (checked) => {
    setIsFinancialYear(checked);
    if (checked) {
      localStorage.setItem("selectedYear", "financialYear");
    } else {
      localStorage.setItem("selectedYear", "calendarYear");
    }
  };

  return (
    <div className="page-content">
      <PageTitle pageHeading={`Biogenic emissions`} />
      <Row>
        <Col md={3}>
          <TotalEmission
            boxData={{
              heading: "Biogenic emissions",
              count: `${totalEmission} <small>tCO<sub>2</sub>e</small>`,
            }}
          />
        </Col>
        <Col md={3}>
          <Carousel autoplay dotPosition="bottom" effect="fade">
            {scopeWiseEmissionEquivalent(totalEmission).map((item, index) => (
              <div key={index}>
                <TotalEmission boxData={item} />
              </div>
            ))}
          </Carousel>
        </Col>

        <Col md={3}>
          <Card
            className="w-full md:w-1/4 lg:w-1/5 xl:w-1/6 xs:w-1/10"
            style={{
              height: "250px",
              overflow: "hidden",
              transform: "translateY(10px)",
            }}
          >
            <CardBody
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardSubtitle className="mb-1"></CardSubtitle>
              <Switch
                style={{ marginBottom: "10px" }}
                checkedChildren="Financial Year"
                unCheckedChildren="Calendar Year"
                checked={isFinancialYear}
                // onChange={() => setIsFinancialYear(!isFinancialYear)}
                onChange={handleToggleChange}
              />
              <div>
                {isFinancialYear ? (
                  fyCheckboxData?.defaultData &&
                  fyCheckboxData?.defaultData?.length > 0 ? (
                    <CheckBox1
                      heading={"Filter by financial year"}
                      names={fyCheckboxData}
                      onChange={(checkValues) => {
                        checkValues.sort();
                        setSelectedFY(checkValues);
                        setSelectedCalendarYear(checkValues);
                      }}
                      disabled={false}
                    />
                  ) : (
                    "Loading...."
                  )
                ) : calendarYearCheckboxData?.defaultData &&
                  calendarYearCheckboxData?.defaultData?.length > 0 ? (
                  <CheckBox1
                    heading={"Filter by calendar year"}
                    names={calendarYearCheckboxData}
                    onChange={(checkValues) => {
                      checkValues.sort();
                      setSelectedFY(checkValues);
                      setSelectedCalendarYear(checkValues);
                    }}
                    disabled={false}
                  />
                ) : (
                  "Loading...."
                )}
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md={3}>
          {subCategoryCheckboxData?.defaultData &&
          subCategoryCheckboxData?.defaultData?.length > 0 ? (
            <CheckBox
              names={subCategoryCheckboxData}
              heading={"Filter by subcategory"}
              onChange={(checkValues) => {
                setSelectedSubCategories(checkValues);
              }}
              disabled={true}
            />
          ) : (
            "Loading......"
          )}
        </Col>
      </Row>
      <Row className="py-4">
        <Col>
          <span className="font-poppins font-semibold">
            Want to view Intensity metrics?{" "}
          </span>
          <Switch
            onChange={() => {
              setIntensityMetrics(!intensityMetrics);
            }}
          />
        </Col>
      </Row>
      <Row>
        {intensityMetrics && (
          <Col xs={12}>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-3">
                  <h5 style={{ fontSize: "20px", textAlign: "center" }}>
                    <b>Biogenic emissions YoY comparison</b>
                  </h5>
                </CardSubtitle>
                {totalEmission == "0" ? "No Data Present" : lineView}
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
      <Row>
        {!intensityMetrics && (
          <Col xs={12}>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-3">
                  <h5 style={{ fontSize: "20px", textAlign: "center" }}>
                    <b>Biogenic emissions YoY comparison</b>
                  </h5>
                </CardSubtitle>
                {totalEmission == "0" ? "No Data Present" : stackView}
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <Card>
            <CardBody>
              <CardSubtitle className="mb-3">
                <h5 style={{ fontSize: "20px", textAlign: "center" }}>
                  <b>Site-wise breakup </b>
                </h5>
              </CardSubtitle>
              <MapComponent2
                tripList={mapData}
                selectedFY={selectedFY}
                scope={"Biogenic"}
              />
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card>
            <CardBody
              style={{
                height: "820px",
              }}
            >
              {!categoryDataLoading && (
                <TableGen
                  title={
                    <span>
                      Site-wise biogenic emissions in tCO<sub>2</sub>e
                    </span>
                  }
                  myData={myData}
                  tripList={tripList}
                  tableHeading={tableHeading}
                  categoryDataLabel={tableHeading}
                  scope3List={true}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row xs={12}>
        <Col xs={12} md={6}>
          <Card>
            <CardBody
              style={{
                height: "950px",
              }}
            >
              <CardSubtitle className="mb-3">
                <h5 style={{ fontSize: "20px", textAlign: "center" }}>
                  <b>Category breakup</b>
                </h5>
              </CardSubtitle>
              {totalEmission == "0" ? "No Data Present" : chartView}
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card>
            <CardBody
              style={{
                height: "950px",
              }}
            >
              {!categoryDataLoading && (
                <TableGen
                  title={
                    <span>
                      Category-wise biogenic emissions in tCO<sub>2</sub>e
                    </span>
                  }
                  myData={myData}
                  tripList={categoryList}
                  tableHeading={table2Heading}
                  categoryDataLabel={table2Heading}
                  scope3List={true}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Index;
