import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import { colorCode, colorInner } from "../../pages/Utility/option";
import useSessionStorage from "../../pages/Client/ToggleFunction/useSessionStorage.js";

const EmissionGen = (props) => {
  const selectedYear = localStorage.getItem("selectedYear");
  const { extraValue, onClickSubCategory } = props;
  const [hideScope, setHideScope] = useState({});

  const netEmission =
    +(
      extraValue?.analyticData?.data?.getAllShipmentEmissionData?.[0]
        ?.carbonEmissionSum / 1000
    )?.toFixed(0) || 0;

  const handleClick = (path) => {
    let updatedPath = "";
    if (path == "Scope 1") {
      updatedPath = "/scope1";
    } else if (path == "Scope 2") {
      updatedPath = "/scope2";
    } else if (path == "Scope 3") {
      updatedPath = "/scope3";
    }
    const currentPath = window.location.pathname;
    const newPath = currentPath + updatedPath;
    window.location.href = newPath;
  };
  return (
    <>
      <Card className="p-3">
        <CardBody>
          <div className="d-flex align-items-center mb-4">
            <div className="avatar-xs font-size-18 me-3">
              <span className="avatar-title bg-soft-primary text-primary rounded">
                <i className={"mdi mdi-leaf"}></i>
              </span>
            </div>
            <div className="flex-1">
              <div className="font-size-18  ">
                <p
                  style={{
                    fontWeight: "700",
                    color: "#000",
                    padding: "0",
                    margin: "0",
                  }}
                >
                  Total emissions (
                  {selectedYear == "financialYear" ? "FY" : "CY"} :{" "}
                  {extraValue?.financialYear})
                  {/* Total emissions (FY : {selectedYear}) */}
                </p>
              </div>
            </div>
          </div>

          {/* <i className=""></i> */}

          <h5
            style={{
              color: "rgb(146 148 164)",
              fontSize: "25px",
              marginBottom: "25px",
              fontWeight: "700",
              textAlign: "center",
            }}
          >
            {/* {Object.values(extraValue?.categoryDataLabel)
              ?.reduce((totalCategory, item) => {
               
                return (
                  +totalCategory +
                  Object.values(item?.data)?.reduce((total, it) => {
                    return +it?.count + total;
                  }, 0)
                );
              }, 0)
              ?.toFixed(2)}{" "} */}
            {netEmission?.toLocaleString("en-IN")} tCO<sub>2</sub>e
          </h5>

          {Object.keys(extraValue?.categoryDataLabel).length > 0 ? (
            Object.values(extraValue?.categoryDataLabel)?.map((itm, i) => {
              if (itm?.name === "Biogenic") {
                return null;
              }
              const scopeEmission = +Object.values(itm?.data)
                ?.reduce((total, it) => {
                  return it?.count + total;
                }, 0)
                ?.toFixed(0);
              return (
                <div>
                  <div
                    key={`${itm?.name}`}
                    href="https://google.com"
                    className="mt-3 mb-2"
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        height: "20px",
                        width: "20px",
                        border: "1px solid",
                        borderRadius: "4px",
                        backgroundColor: `${colorInner[i]}`,
                      }}
                    ></div>

                    <p
                      className="font-size-18"
                      style={{
                        fontWeight: "700",
                        color: "#000",
                        padding: "0",
                        margin: "0",
                      }}
                      onClick={() => handleClick(itm?.name)}
                    >
                      {itm?.name}
                    </p>
                    <p style={{ margin: "0px" }}>
                      ({scopeEmission?.toLocaleString("en-IN")} tCO
                      <sub>2</sub>e)
                    </p>

                    <i
                      className={`${
                        hideScope?.[itm?.name]
                          ? "bx bx-chevron-up"
                          : "bx bx-chevron-down"
                      }`}
                      style={{ fontSize: "20px" }}
                      onClick={() => {
                        // setHideScope((pr) => !pr);

                        setHideScope((pr) => ({
                          ...pr,
                          [itm?.name]: !pr?.[itm?.name],
                        }));
                      }}
                    ></i>
                  </div>

                  {hideScope?.[itm?.name] &&
                    Object.values(itm?.data)?.map((it, index) => {
                      const subCategoryEmission = +it?.count?.toFixed(0);
                      return (
                        <div
                          key={`clr-${colorCode[i][index]}`}
                          className="mb-2"
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          <div
                            style={{
                              height: "10px",
                              width: "10px",
                              border: "1px solid",
                              borderRadius: "50px",
                              backgroundColor: `${
                                it?.color || colorCode[i][index]
                              }`,
                            }}
                          ></div>

                          <p style={{ margin: "0px" }}>
                            {it?.label} :{" "}
                            {subCategoryEmission?.toLocaleString("en-IN")} tCO
                            <sub>2</sub>e
                          </p>
                        </div>
                      );
                    })}
                </div>
              );
            })
          ) : (
            <p
              className="font-size-18"
              style={{
                fontWeight: "700",
                color: "rgb(146 148 164)",
                padding: "0",
                margin: "0",
                textAlign: "center",
              }}
            >
              No carbon emission data found
            </p>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default EmissionGen;
