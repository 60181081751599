import React, { useEffect, useState } from "react";
import CustomPage from "../../../components/Custome/CustomPage";
import CONSTANT, { TABLE_HEADER } from "../../Utility/constnt";
import { brsrFormHeaderData, brsrSpocsHeaderData, globalSectionHeaderData } from "../../Utility/exportValue";
import { brsrFormDataMask, brsrSpocDataMask, globalSectionsDataMask } from "../../Utility/spread-function";
import { useParams } from "react-router-dom";
import useHttp from "../../../components/Hook/Use-http";
import { getExtraConstPayload } from "../../Utility/function";
import { createEndPoint } from "../../Utility/function";
import { useSelector } from "react-redux";
import { brsrSectionType } from "../../Utility/option";
import { brsrFormMapping } from "../../Utility/models/mapping-model";
import { sectionId } from "../../Utility/models/form-model";

const FormsTab = () => {
  const { id } = useParams();
  const myData = useSelector((state) => state.userGetMeData);
  // const API_CALL = useHttp();
  const role = localStorage.getItem("userRole");
  const [formData, setFormData] = useState(null);
  // const [spoc, setSpoc] = useState(null);

  // useEffect(() => {
  //   if (id && role) return;
  //   if (role === "client") {
  //     setSpoc((prev) => {
  //       return {
  //         ...prev,
  //         clientId: id,
  //       };
  //     });
  //   }
  // }, [id, role]);

  return (
    <div className="w-full">
      <CustomPage
        PAGE={{
          title: "Global forms",
          dataFunction: globalSectionsDataMask,
        }}
        API={{
          // need to change at client login level time url
          ...CONSTANT.API.GLOBAL_SECTIONS,
          get: createEndPoint.get(
            role === "admin"
              ? `${role}/globalForm/global-sections?clientId=${id}`
              : `${role}/globalForm/global-sections`
          ),
          // create: role === "spoc" ? null : CONSTANT.API.BRSR_FORMS.bulkUpdateETA,
        }}
        FORM={{
          formField: CONSTANT.FORM_FIELDS?.BRSR_FORMS,
          addFormTitle: "Set ETA",
          editFormTitle: "Edit ETA",
          addNewRecordCtaText: "Set ETA",
          removeFieldOnEdit: ["sections"],
          extraConstPayload: [
            {
              id: "clientId",
              value: id,
            },
          ],
          option: {
            section: brsrSectionType,
            deadline: "deadline",
          },
        }}
        TABLE={{
          title: "Forms list",
          header: role === "spoc" ? TABLE_HEADER.spocGlobalSections : TABLE_HEADER?.globalSections,
          export: {
            fileName: "forms_List.csv",
            header: globalSectionHeaderData,
          },
        }}
        // editClick={editClick}
        // setEditClick={setEditClick}
        // val={val}
        // setVal={setVal}
      />
    </div>
  );
};

export default FormsTab;
