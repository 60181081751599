import React, { useState, useEffect } from "react";
import { Input, Button, Form, Typography, Table, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import BrsrFormHeading from "../BrsrFormHeading";
import useHttp from "../../../components/Hook/Use-http";
import CONSTANT from "../../Utility/constnt";
import { useParams } from "react-router-dom";
import { replaceFormIdandClientId, replaceId } from "../../Utility/function";
import Questions from "../../../components/Report/Questions";
import { countForPackage } from "../../Utility/models/form-model";
import { tooltip } from "leaflet";
import { Spinner } from "reactstrap";
const { TextArea } = Input;
const { Text } = Typography;

const Location = () => {
  const { id } = useParams();
  const userRole = localStorage.getItem("userRole");
  const API_CALL = useHttp();
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);

  const getTooltipText = {
    52: "Geographical locations of the entity w.r.t. manufacturing, distribution & sale activities",
    53:"Geographical locations of the entity w.r.t. distribution & sale activities",
    54:"This question allows investors to understand about how much exports contribute to the total turnover of the entity in terms of absolute numbers as well as percentage. Ideally previous year numbers are also shared to show a comparison",
    55:"This question allows investors to understand about the kind of customers a company has. This can be further segmented into B2B, B2C, B2G segments",
  };

  useEffect(() => {
    try {
      const parser = (data = []) => {
        return data.map((curr) => {
          const dataSchema = JSON.parse(curr.Question.answerSchema);
          let answerString = curr.Question?.Answers[0]?.value;
          return {
            id: curr.Question.id,
            question: curr.Question.description,
            options: dataSchema.options,
            answerSchema: dataSchema,
            type: dataSchema.type || "userDefined",
            answer: answerString,
            valueType: curr.Question?.Answers[0]?.valueType,
            tooltip:getTooltipText?.[curr.Question.id],
          };
        });
      };
      let apiData;
      let sectionId = 5; // need to call this api and call from api not statically
      if (userRole == "client") {
        API_CALL.sendRequest(
          replaceId(CONSTANT.API.GLOBALFORMS.get, sectionId),
          (res) => {
            let globalQuestions = parser(res?.data?.GlobalSectionQuestions);
            setQuestions(globalQuestions);
            setLoading(false);
          }
        );
      } else if (userRole == "admin") {
        API_CALL.sendRequest(
          replaceFormIdandClientId(
            CONSTANT.API.GLOBALFORMS.get2,
            sectionId,
            id
          ),
          (res) => {
            let globalQuestions = parser(res?.data?.GlobalSectionQuestions);
            setQuestions(globalQuestions);
            setLoading(false);
          }
        );
      } else if (userRole == "spoc") {
        API_CALL.sendRequest(
          replaceFormIdandClientId(
            CONSTANT.API.GLOBALFORMS.get,
            sectionId,
            id
          ),
          (res) => {
            let globalQuestions = parser(res?.data[0]?.GlobalSection?.GlobalSectionQuestions);
            setQuestions(globalQuestions);
            setLoading(false);
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleSubmit = (data = {}) => {
    try {
      const formatObject = (obj) => JSON.stringify(obj).replace(/"/g, '\\"');
      const transformData = (data) => ({
        answers: Object.entries(data).map(([key, value]) => {
          const question = questions.find((q) => q.id === Number(key));
          let formattedValue;
          if (
            (question && question.type === "fixedtable") ||
            (question.type === "dynamictable" && question.transformedAnswer)
          ) {
            formattedValue = JSON.stringify(
              question?.transformedAnswer
            )?.replace(/"/g, '"');
          } else {
            formattedValue =
              typeof value === "object"
                ? formatObject([{ id: value.key }])
                : value;
          }
          return {
            qid: Number(key),
            value: formattedValue,
          };
        }),
      });

      if (userRole === "client") {
        API_CALL.sendRequest(
          CONSTANT.API.GLOBALFORMS.create,
          null,
          transformData(data),
          "Your data added successfully"
        );
      } else if (userRole === "admin") {
        API_CALL.sendRequest(
          replaceId(CONSTANT.API.GLOBALFORMS.create2, id),
          null,
          transformData(data),
          "Your data added successfully"
        );
      }  else if (userRole === "spoc") {
        const clientId = localStorage.getItem("client");
        API_CALL.sendRequest(
          replaceId(CONSTANT.API.GLOBALFORMS.create2, clientId),
          null,
          transformData(data),
          "Your data added successfully"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  


  return (
    <div style={{ marginTop: 100 }}>
      <BrsrFormHeading
        title="Location Details"
        explanation="This question allows forms to understand about the proximity of a entity’s operations to environmentally sensitive sites such as protected areas, water- stressed zones, etc., also it provides information about the group of customers & region targeted by the entity."
      />
      <p style={{ fontStyle: "italic", marginBottom: "16px" }}>
        Note: Answers marked with ✨ are recommended by our AI. You can edit
        them if needed.
      </p>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <Spinner className="m-1" color="primary" />
        </div>
      ) : (
      <Form layout="vertical" onFinish={handleSubmit}>
        {questions.map((ques, index) =>
            <Questions key={ques.id} ques={ques} index={index} />
        )}
        <Button
          type="primary"
          htmlType="submit"
          style={{
            position: "fixed",
            bottom: "30px",
            backgroundColor: "#060DBF",
            color: "#62C941",
            right: "74px",
            zIndex: 1000,
            // width: "125px",
            height: "40px",
            cursor: "pointer",
          }}
        >
          Save changes
        </Button>
      </Form>
      )}
    </div>
  );
};

export default Location;
