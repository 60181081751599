import React, { useState, useEffect } from "react";
import { Checkbox } from "antd";
import { Container, Row, Col, Card, CardBody, CustomInput } from "reactstrap";
const CheckBox = (props) => {
  const { names, heading, defaultValue, disabled, onChange } = props;

  // Create an array of all option values
  const allOptions = names.options.map((option) => option.value);

  const [checkedValues, setCheckedValues] = useState([]);
  useEffect(() => {
    setCheckedValues(allOptions);
  }, [names]);
  return (
    <Container>
      <Row>
        <Col>
          <div
            className="bg-white m-2 rounded"
            style={{
              height: "250px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                fontSize: "14px",
              }}
              className="px-2"
            >
              {heading}
            </p>
            <Checkbox.Group
              value={checkedValues} // new added
              onChange={(values) => {
                setCheckedValues(values);
                onChange(values);
              }}
              style={{
                display: "flex",
                flexDirection: "column",
                overflowY: "auto", // Enable vertical scrolling
                maxHeight: "100px", // Set a max height for the scroll area
                overflowX: "hidden", // Hide horizontal overflow
                flexWrap: "nowrap",
              }}
              className="px-1 rounded"
              options={names.options}
            ></Checkbox.Group>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default CheckBox;
