// import { PLANT_DETAIL } from "./actionTypes";

// // export const getMyProfile = () => ({
// //   type: GET_MY_PROFILE,
// // });

// export const getPlantDetailSuccess = (users) => {
//   return {
//     type: PLANT_DETAIL,
//   };
//   // return {
//   //   type: GET_DRIVER_DETAIL_SUCCESS,
//   //   payload: users,
//   // };
// };

import {
  GET_PLANT_START,
  GET_PLANT_SUCCESS,
  GET_PLANT_FAILURE,
  DELETE_PLANT_START,
  DELETE_PLANT_SUCCESS,
  DELETE_PLANT_FAILURE,
} from "./actionTypes";

//GET
export const getPlantStart = (api = null) => {
  return {
    type: GET_PLANT_START,
    payload: api,
  };
};
export const getPlantSuccess = (users) => {
  return {
    type: GET_PLANT_SUCCESS,
    payload: users,
  };
};
export const getPlantError = (error) => {
  return {
    type: GET_PLANT_FAILURE,
    payload: error,
  };
};

//DELETE
export const deletePlantStart = (id, successCallback) => ({
  type: DELETE_PLANT_START,
  payload: id,
  successCallback,
});

export const deletePlantSucess = (id) => ({
  type: DELETE_PLANT_SUCCESS,
  payload: id,
});

export const deletePlantError = (error) => ({
  type: DELETE_PLANT_FAILURE,
  payload: error,
});
