// import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
// import { SET_TRANSPORTER_DETAIL, TRANSPORTER_DETAIL } from "./actionTypes";
// import Apicall from "../../components/Hook/Api-call";
// import CONSTANT from "../../pages/Utility/constnt";

// const API_URL = "https://jsonplaceholder.typicode.com/users";

// function* getTransporter() {
//   try {
//     const res = yield Apicall.get(
//       CONSTANT.API.TRANSPORTER.getTransporter.endpoint
//     );

//     yield put({ type: SET_TRANSPORTER_DETAIL, data: res });
//     // debugger;
//   } catch (error) {
//     console.log(error);
//     // yield put({ type: "GET_MY_PROFILE_ERROR", error });
//   }
// }

// function* GetTransporterDetailSaga() {
//   yield takeEvery(TRANSPORTER_DETAIL, getTransporter);
// }

// export default GetTransporterDetailSaga;

import { put, takeLatest } from "redux-saga/effects";
import { DELETE_TRANSPORTER_START, GET_TRANSPORTER_START } from "./actionTypes";
import Apicall from "../../components/Hook/Api-call";
import CONSTANT from "../../pages/Utility/constnt";
import {
  deleteTransporterError,
  deleteTransporterSucess,
  getTransporterError,
  getTransporterSuccess,
} from "./actions";
import { replaceEndPointId } from "../../pages/Utility/function";

function* getTransporter() {
  try {
    const res = yield Apicall?.get(CONSTANT.API.TRANSPORTER.get.endpoint);
    yield put(getTransporterSuccess(res));
  } catch (error) {
    console.log(error);
    yield put(getTransporterError(error?.message));

    // yield put({ type: GET_CUSTOMER_FAILURE, error });
  }
}

function* deleteTransporterSaga(user) {
  try {
    const response = yield Apicall?.remove(
      replaceEndPointId(
        CONSTANT?.API?.TRANSPORTER?.delete?.endpoint,
        user?.payload
      )
    );

    if (response?.status == "success") {
      if (user?.successCallback) {
        user?.successCallback();
      }
    }
    yield put(deleteTransporterSucess(user));
  } catch (error) {
    console.log(error?.message, "responce");
    yield put(deleteTransporterError(error?.message));
  }
}

function* GetTransporterDetailSaga() {
  yield takeLatest(GET_TRANSPORTER_START, getTransporter);
  yield takeLatest(DELETE_TRANSPORTER_START, deleteTransporterSaga);
}

export default GetTransporterDetailSaga;
