export const vehicleViewCardDetails = [
  ["registrationNumber", "permit_type"],
  ["permit_no", "fitness_upto"],
  ["insurance_validity", "registration_date"],
  ["permit_validity_upto", "capacity"],
  ["fuelType", "vehicle_category"],
  ["unladden_weight", "gross_vehicle_weight"],
  "manufacture",
];

export const driverCardDetails = [
  ["dl_no", "name"],
  ["dob", "gender"],
  ["cov", "blood_group"],
  "permanent_address",
  "temporary_address",
];

export const transporterCardDetails = [
  ["transporter_name", "date_of_registration"],
  ["business_type", "is_field_visit_conducted"],
  ["gst_status", "is_aadhar_verified"],
  "business_address",
];

export const transporterCardDetailsonEdit = [
  ["transporterName:transporter_name", "rgdt:Date_of_registration"],
  ["ctb:business_type", "isFieldVisitConducted:is_field_visit_conducted"],
  ["sts:gst_status", "adhrVFlag:is_aadhar_verified"],
  "pradr:business_address",
];

export const siteDetails = [
  ["business_name:site_name", "date_of_registration"],
  ["business_type", "is_field_visit_conducted"],
  ["gst_status", "is_aadhar_verified"],
  "business_address",
];

export const siteDetailsonEdit = [
  ["unitName:site_name", "rgdt:Date_of_Registration"],
  ["ctb:business_type", "isFieldVisitConducted:is_field_visit_conducted"],
  ["sts:gst_status", "adhrVFlag:is_aadhar_verified"],
  "pradr:business_address",
];