// import { ROLEANDACCESS_DETAIL } from "./actionTypes";

// // export const getMyProfile = () => ({
// //   type: GET_MY_PROFILE,
// // });

// export const getRoleAndAccessDetailSuccess = (users) => {
//   return {
//     type: ROLEANDACCESS_DETAIL,
//   };
//   // return {
//   //   type: GET_DRIVER_DETAIL_SUCCESS,
//   //   payload: users,
//   // };
// };

import {
  GET_ROLEANDACCESS_START,
  GET_ROLEANDACCESS_SUCCESS,
  GET_ROLEANDACCESS_FAILURE,
  DELETE_ROLEANDACCESS_START,
  DELETE_ROLEANDACCESS_SUCCESS,
  DELETE_ROLEANDACCESS_FAILURE,
} from "./actionTypes";

//GET
export const getRoleAndAccessStart = (api = null) => {
  return {
    type: GET_ROLEANDACCESS_START,
    payload: api,
  };
};
export const getRoleAndAccessSuccess = (users) => {
  return {
    type: GET_ROLEANDACCESS_SUCCESS,
    payload: users,
  };
};
export const getRoleAndAccessError = (error) => {
  return {
    type: GET_ROLEANDACCESS_FAILURE,
    payload: error,
  };
};

//DELETE
export const deleteRoleAndAccessStart = (id, successCallback) => ({
  type: DELETE_ROLEANDACCESS_START,
  payload: id,
  successCallback,
});

export const deleteRoleAndAccessSucess = (id) => ({
  type: DELETE_ROLEANDACCESS_SUCCESS,
  payload: id,
});

export const deleteRoleAndAccessError = (error) => ({
  type: DELETE_ROLEANDACCESS_FAILURE,
  payload: error,
});
