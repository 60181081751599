import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Row } from "reactstrap";
import { adminRoute } from "../pages/Utility/constnt";

const PageTitle = (props) => {
  const { pageHeading = null, backNavPath, withBackButton } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const userRole = localStorage.getItem("userRole");
  const currentPath = location.pathname;
  const path = currentPath.includes("/client")
    ? `${adminRoute}/customers`
    : currentPath.includes("/siteViewer")
    ? `${adminRoute}/sites`
    : `/`;

  return (
    <Row>
      <div className="col-12" sm={12} style={{ height: "60px" }}>
        <div className="page-title-box d-flex align-items-center justify-content-between">
          <div className="page-titles  m-0  rounded d-flex align-items-center  p-0 pl-3">
            {withBackButton && (
              // <Link to={backNavPath || -1}>
              //   <i
              //     className="fa fa-arrow-left  mr-3"
              //     style={{ fontSize: "1.4rem", color: "white" }}
              //     aria-hidden="true"
              //   ></i>
              // </Link>
              // <Link to={backNavPath || -1}>
              <i
                className="fa fa-arrow-left  "
                style={{
                  fontSize: "1.4rem",
                  color: "#4a5057",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
                aria-hidden="true"
                onClick={() => {
                  window.history.back();
                }}
              ></i>
            )}
            <p
              className="page-title mb-0 font-size-18 font-weight-bold"
              // style={{ marginLeft: "15px" }}
              style={{ cursor: "pointer", fontWeight: "700" }}
              onClick={() => {
                navigate("/");
              }}
            >
              {/* <Link to={"/"} style={{ color: "white" }}> */}
              Home
              {/* </Link> */}
            </p>

            {pageHeading !== null && pageHeading !== undefined && (
              <p
                className="page-title mb-0 font-size-18"
                style={{ marginLeft: "5px", fontWeight: "700" }}
              >
                {` / ${pageHeading}` || ""}
              </p>
            )}
          </div>
          {/* <h4 className="page-title mb-0 font-size-18">Client Info</h4> */}

          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">
                Welcome to Fitsol dashboard
              </li>
            </ol>
          </div>
        </div>
      </div>

      {(currentPath.includes("/client") ||
        currentPath.includes("/siteViewer")) &&
      userRole == "admin" ? (
        <div
          className="mb-2 "
          style={{
            color: "#3533bb",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => {
            // navigate(path);
            window.location.replace(path);
          }}
        >
          {`Back to ${path.includes("customers") ? "customer" : "site"} page`}
        </div>
      ) : (
        <></>
      )}
    </Row>
  );
};

export default PageTitle;
