import React from "react";
import { MDBDataTable } from "mdbreact";
import { CSVLink } from "react-csv";

import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap";
import "./datatables.scss";
import TableView from "./tableView";

const Table = (props) => {
  const { title, data } = props;
  return (
    <React.Fragment>
      <Row>
        <Col className="col-12 OverFlowScroll">
          <Card>
            <CardBody>
              <TableView {...props} />
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* </div> */}
    </React.Fragment>
  );
};

export default Table;
