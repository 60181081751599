import React from "react";

import Board from "@asseinfo/react-kanban";
import { Row, Col } from "reactstrap";
import CardTaskBox from "./card-task-box";
import RenderCardTitle from "./render-card-title";
import "@asseinfo/react-kanban/dist/styles.css";

const UncontrolledBoard = (props) => {
  const content = props.board;

  return (
    <React.Fragment>
      <Row className="mb-4">
        <Col>
          <Board
            allowRemoveLane
            allowRenameColumn
            allowRemoveCard
            onLaneRemove={console.log}
            onCardRemove={console.log}
            onLaneRename={console.log}
            initialBoard={content}
            onCardDragEnd={console.log}
            allowAddCard={{ on: "top" }}
            onNewCardConfirm={(draftCard) => {
              return {
                id: new Date().getTime(),
                ...draftCard,
              };
            }}
            onCardNew={console.log}
            renderCard={(data, { dragging }) => {
              return (
                <CardTaskBox
                  data={data}
                  dragging={dragging}
                  option={props.option}
                >
                  {data}
                </CardTaskBox>
              );
            }}
          />
          {/* <Board
            initialBoard={content}
            renderColumnHeader={({ title }) => (
              <RenderCardTitle title={title} />
            )}
            renderCard={(data, { dragging }) => (
              <CardTaskBox data={data} dragging={dragging}>
                {data}
              </CardTaskBox>
            )}
            onNewCardConfirm={(draftCard) => ({
              id: new Date().getTime(),
              ...draftCard,
            })}
          /> */}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UncontrolledBoard;
