import React from "react";
import { Link } from "react-router-dom";
import { CardBody, Container, Row, Col, Card } from "reactstrap";

import logo from "../../assets/images/logo-dark.png";
import errorImage from "../../assets/images/error-img.png";

const PagesUnAuthorised = () => {
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/logout" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="my-5 pt-sm-5">
        <Container>
          <Row>
            <div className="col-12 text-center">
              <div className="home-wrapper">
                <div className="mb-5">
                  {/* <Link to="/"> */}
                  <img src={logo} alt="" height="24" />
                  {/* </Link> */}
                </div>
                <Row className="justify-content-center">
                  <Col sm={4}>
                    <div className="maintenance-img">
                      <img
                        src={errorImage}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </Col>
                </Row>
                <h3 className="mt-5">Site is Under Maintenance</h3>
                <p>
                  Please reach out to your organisation's admin or send a mail
                  to <a href="mailto:cx@fitsolscs.com">cx@fitsolscs.com</a>.
                  {/* <br /> regular than that of the individual languages. */}
                </p>
                {/* <Row>
                      <Col md={4}>
                        <Card className="mt-4 maintenance-box">
                          <CardBody>
                            <h5 className="font-size-15 text-uppercase">Why is the Site Down?</h5>
                            <p className="text-muted mb-0">There are many variations of passages of
                              Lorem Ipsum available, but the majority have suffered alteration.</p>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md={4}>
                        <Card className="mt-4 maintenance-box">
                          <CardBody>
                            <h5 className="font-size-15 text-uppercase">
                              What is the Downtime?</h5>
                            <p className="text-muted mb-0">Contrary to popular belief, Lorem Ipsum is not
                              simply random text. It has roots in a piece of classical.</p>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md={4}>
                        <Card className="mt-4 maintenance-box">
                          <CardBody>
                            <h5 className="font-size-15 text-uppercase">
                              Do you need Support?</h5>
                            <p className="text-muted mb-0">If you are going to use a passage of Lorem
                            Ipsum, you need to be sure there isn't anything embar..
                                <Link to="mailto:no-reply@domain.com"
                                className="text-decoration-underline"> no-reply@domain.com</Link></p>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row> */}
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PagesUnAuthorised;
