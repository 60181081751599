import axios from "axios";

const PcfServices = axios.create({
  baseURL: process.env.REACT_APP_PCF_SERVICE,
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("authUser")}`,
  },
});

export default PcfServices;
