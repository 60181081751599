import React, { useEffect, useState } from "react";
import PageTitle from "../../../components/pageTitle";
import CONSTANT from "../../Utility/constnt";
import { Row } from "reactstrap";
import { Loader, replaceId } from "../../Utility/function";
import TrackMap from "../../Tracking/TrackMap";
import ShipmentModal from "../../../components/trackingMapPage/shipmentModal";
import SweetAlert from "react-bootstrap-sweetalert";
import useHttp from "../../../components/Hook/Use-http";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getShipmentStart } from "../../../store/actions";
import TripCardGen from "../../../components/trackingMapPage/tripCardGen";
import { useParams } from "react-router-dom";

const SiteViewerTrackMap = () => {
  // let TripID = window.location.pathname.split("/")[3];

  const [isModalShow, setIsModalShow] = useState(false);
  const { tripId, id } = useParams();
  const [selectTripData, setSelectTripData] = useState({});
  const [showSweetAlert, setShowSweetAlert] = useState(false);

  const [userInput, setUserInput] = useState("");
  const [shipmentList, setShipmentList] = useState([]);
  const [shipmentAllList, setShipmentAllList] = useState([]);

  const API_CALL = useHttp();
  const dispatch = useDispatch();
  const API_CALL_2 = useHttp();

  const getShipmentData = useSelector((state) => state.shipmentDetailData);

  useEffect(() => {
    setTimeout(() => {
      getTripData();
    }, 100);
  }, [getShipmentData]);

  const getTripData = () => {
    if (!tripId) {
      const onGoingTrip = { ...CONSTANT.API.SHIPMENT.get };
      onGoingTrip.endpoint =
        onGoingTrip.endpoint + `&plantId=${id}` + `&status=2`;
      API_CALL.sendRequest(onGoingTrip, tripDataHandler);
    } else {
      getOneTripDataHandler(
        getShipmentData?.users?.data?.rows?.find((it) => it?.id == tripId)
      );
    }
  };

  const getOneTripDataHandler = (res) => {
    setSelectTripData(res);
  };
  const tripDataHandler = async (res) => {
    setShipmentList(
      res?.data?.rows?.filter((e) => (e.status === "2" ? true : false))
    );
    setShipmentAllList(
      res?.data?.rows?.filter((e) => (e.status === "2" ? true : false))
    );
  };

  const onClickSelectCard = (Item, index) => {
    setSelectTripData(Item);
  };

  const openConfirmationDeleteModal = (Item) => {
    setSelectTripData(Item);
    setShowSweetAlert(true);
  };

  const onSubmitForm = () => {
    const payload = {};
    if (selectTripData?.id) {
      // payload.targetedDateAndTime = moment().format("YYYY-MM-DDThh:mm");
      payload.status = 3;
      // const URL = {
      //   endpoint: `/trip/${selectTripData?.id}`,
      //   type: "PATCH",
      // };
      API_CALL_2.sendRequest(
        replaceId(CONSTANT.API.SHIPMENT.update, selectTripData?.id),
        () => {
          setShipmentList((previous) =>
            previous.filter((shipment) => shipment?.id !== selectTripData?.id)
          );
          setSelectTripData({});
          dispatch(getShipmentStart());
        },
        payload,
        "Shipment updated successfully"
      );
      setShowSweetAlert(false);
    }
  };
  const filterItemHandler = (filterData) => {
    if (filterData?.trim()) {
      setShipmentList(
        shipmentAllList.filter((item) => {
          const filId = `CO${(item?.id + 20000000006000)
            .toString()
            .toLowerCase()}`;
          return filId?.toLowerCase()?.includes(filterData?.toLowerCase());
        })
      );
    } else {
      setShipmentList(shipmentAllList);
    }
  };
  const handleChange = (e) => {
    setUserInput(e.target.value);

    filterItemHandler(e.target.value);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <PageTitle
          pageHeading={`${
            tripId
              ? `Tracking / ${
                  selectTripData?.id
                    ? `CO${selectTripData?.id + 20000000006000}`
                    : ""
                }`
              : "Tracking"
          }`}
          withBackButton
          backNavPath="/shipments"
        />

        <Row className={`${tripId ? "d-none" : "d-block"}`}>
          <div className="d-flex justify-content-between align-items-center mt-5 mt-md-0">
            <h4 className="page-title mb-3 font-size-18">InTransit shipment</h4>

            {shipmentList.length > 0 ? (
              <div>
                <i
                  className="bx bx-left-arrow-circle project-button-prev"
                  onClick={
                    () => {}
                    // horizontalScroll(elementRef.current, 25, 320, -20)
                  }
                  style={{
                    fontSize: "30px",
                    cursor: "pointer",
                  }}
                ></i>
                <i
                  className="bx bx-right-arrow-circle project-button-next"
                  onClick={
                    () => {}
                    // horizontalScroll(elementRef.current, 25, 320, 20)
                  }
                  style={{
                    fontSize: "30px",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
            ) : null}
          </div>
        </Row>
        {!tripId && (
          <div
            class="input-group rounded"
            style={{ width: "200px", marginBottom: "10px" }}
          >
            <input
              type="search"
              class="form-control rounded"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search-addon"
              value={userInput}
              onChange={handleChange}
            />
          </div>
        )}
        <div className={`${tripId ? "d-none" : "d-block"}`}>
          {API_CALL?.isLoading ? (
            <Loader />
          ) : shipmentList.length > 0 ? (
            <TripCardGen
              extraValue={{
                shipmentList: shipmentList,
                selectTripData: selectTripData,
                onClickSelectCard: onClickSelectCard,
                openConfirmationDeleteModal: openConfirmationDeleteModal,
                setSelectTripData: setSelectTripData,
                setIsModalShow: setIsModalShow,
              }}
            />
          ) : (
            <>
              {" "}
              <div className="alert alert-danger text-center" role="alert">
                No ongoing trips found
              </div>
            </>
          )}
        </div>
        <TrackMap
          selectTripData={selectTripData}
          TripID={tripId}
          setModalData={setSelectTripData}
          setIsModalShow={setIsModalShow}
        />
      </div>

      {/*====================== SHIPMENT MODAL====================== */}
      <ShipmentModal
        extraValue={{
          isModalShow: isModalShow,
          setIsModalShow: setIsModalShow,
          selectTripData: selectTripData,
        }}
      />

      {showSweetAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={onSubmitForm}
          onCancel={() => {
            setShowSweetAlert(false);
          }}
        >
          Do you really want to complete trip number {selectTripData?.id}?
        </SweetAlert>
      ) : null}
    </React.Fragment>
  );
};

export default SiteViewerTrackMap;
