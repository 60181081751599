import React, { useEffect, useState } from "react";
import { Form, Input, Row, Col, Checkbox, Button, Select, Typography } from "antd";
import useHttp from "../../../components/Hook/Use-http";
import CONSTANT from "../../Utility/constnt";
import { replaceFormIdandClientId, replaceId } from "../../Utility/function";
import { useParams } from "react-router-dom";
import BrsrFormHeading from "../BrsrFormHeading";
import Questions from "../../../components/Report/Questions";
import { Spinner } from "reactstrap";

const { Option } = Select;
const { Title } = Typography;

const StakeholderIdentification = () => {

  const stakeholders = [
    "Communities",
    "Investors (other than shareholders)",
    "Shareholders",
    "Employees and Workers",
    "Customers",
    "Value Chain members (upstream & downstream) & other business partners",
    "Regulators",
    "Civil society actors, & Media",
  ];

  const API_CALL = useHttp();
  const { id } = useParams();
  const userRole = localStorage.getItem("userRole");
  const [questions, setQuestions] = useState([]);
  const [form] = Form.useForm();
  const [stakeholdersGroup, setStakeholdersGroup] = useState(stakeholders);
  const [selectedStakeholders, setSelectedStakeholders] = useState({});
  const [isAddingStakeholder, setIsAddingStakeholder] = useState(false);
  const [other, setOther] = useState(false);
  const [loading, setLoading] = useState(true);




  useEffect(() => {
    const parser = (data = []) => data.map((curr) => {
      const dataSchema = JSON.parse(curr.Question.answerSchema);
      let answerString = curr.Question?.Answers[0]?.value;
      return {
        id: curr.Question.id,
        question: curr.Question.description,
        options: dataSchema.options,
        answerSchema: dataSchema,
        type: dataSchema.type || "userDefined",
        answer: answerString,
        valueType: curr.Question?.Answers[0]?.valueType,
      };
    });

    const sectionId = 4;
    const fetchData = (url) => {
      API_CALL.sendRequest(url, (res) => {
        const globalQuestions = parser(res?.data?.GlobalSectionQuestions);
        setQuestions(globalQuestions);
        setLoading(false);
        const initialValues = {};
        globalQuestions.forEach((q) => {
          if (q.answer) {
            const parsedAnswer = JSON.parse(q.answer);
            if (parsedAnswer.stakeholders) {
              parsedAnswer.stakeholders.forEach((stakeholder) => {
                setSelectedStakeholders(prev => ({
                  ...prev,
                  [stakeholder.name]: true,
                }));
                setStakeholdersGroup(prev => {
                  if (!prev.find(st => st === stakeholder.name)) {
                    return [...prev, stakeholder.name];
                  }
                  return prev;
                });
              });
            }
            initialValues[q.id] = parsedAnswer;
          }
        });
        form.setFieldsValue(initialValues);
      });
    };

    if (userRole === "client") {
      fetchData(replaceId(CONSTANT.API.GLOBALFORMS.get, sectionId));
    } else if (userRole === "admin") {
      fetchData(replaceFormIdandClientId(CONSTANT.API.GLOBALFORMS.get2, sectionId, id));
    } else if (userRole == "spoc") {
      API_CALL.sendRequest(
        replaceFormIdandClientId(
          CONSTANT.API.GLOBALFORMS.get,
          sectionId,
          id
        ),
        (res) => {
          let globalQuestions = parser(res?.data[0]?.GlobalSection?.GlobalSectionQuestions);
          setQuestions(globalQuestions);
          setLoading(false);
        }
      );
    }
  }, []);


  // watch list
  const communicatorList = Form.useWatch(43, form);
  console.log("value////////////////////////", communicatorList)




  const handleCheckboxChange = (stakeholder, checked) => {
    setSelectedStakeholders(prev => ({
      ...prev,
      [stakeholder]: checked,
    }));
  };

  const handleNewStakeholderChange = (e) => {
    form.setFieldsValue({ newStakeholder: e.target.value });
  };

  const handleAddNewStakeholder = () => {
    const newStakeholder = form.getFieldValue("newStakeholder").trim();
    if (newStakeholder) {
      setStakeholdersGroup(prev => [...prev, newStakeholder]);
      setSelectedStakeholders(prev => ({
        ...prev,
        [newStakeholder]: true,
      }));
      form.resetFields(["newStakeholder"]);
      setIsAddingStakeholder(false);
    }
  };

  const handleSubmit = (values) => {
    const result = {};
    const transformedData = questions.map((question) => {
      const answer = values[question.id] || {};
      if (question.id == 40) {
        const stakeholdersList = Object.entries(selectedStakeholders)
          .filter(([, checked]) => checked)
          .map(([name]) => ({ name }));

        return { qid: 40, value: JSON.stringify({ stakeholders: stakeholdersList }) };
      }


      return {
        qid: question.id,
        value: JSON.stringify(
          answer
        ),
      };
    });




    const requestUrl = userRole === "client"
      ? CONSTANT.API.GLOBALFORMS.create
      : replaceId(CONSTANT.API.GLOBALFORMS.create2, id);
    API_CALL.sendRequest(requestUrl, null, { answers: transformedData }, "Your data added successfully");
  };

  const renderQuestions = () => {
    return questions.map((ques, index) => {
      const stakeholdersList = Object.keys(selectedStakeholders).filter(stakeholder => selectedStakeholders[stakeholder]);

      const questionComponents = {
        40: (
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={<span style={{fontSize:16, fontWeight: "bold" }}>{`${index + 1}. ${ques.question}`}</span>}>
                <Row gutter={16} style={{ marginLeft: 20 }}>
                  {stakeholdersGroup.map((stakeholder, i) => (
                    <Col span={10} key={i} style={{ marginBottom: 0 }}>
                      <Form.Item>
                        <Checkbox
                          checked={selectedStakeholders[stakeholder] || false}
                          onChange={(e) => handleCheckboxChange(stakeholder, e.target.checked)}
                        >
                          {stakeholder}
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  ))}
                  <Col span={24}>
                    <Form.Item>
                      <Checkbox
                        checked={other}
                        onChange={(e) => setOther(e.target.checked)}
                      >
                        Others (Please specify)
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  {(!isAddingStakeholder && other) &&
                    <Col span={24}>
                      <Form.Item>
                        <Button
                          style={{
                            bottom: "0px",
                            marginTop: "0px",
                            backgroundColor: "#060DBF",
                            color: "#62C941",
                            height: "40px",
                            cursor: "pointer",
                          }}
                          onClick={() => setIsAddingStakeholder(true)}
                        >
                          + Add New Stakeholder
                        </Button>
                      </Form.Item>
                    </Col>
                  }
                  {isAddingStakeholder && other && (
                    <Col span={24}>
                      <Form.Item>
                        <Input
                          name="newStakeholder"
                          placeholder="Enter new stakeholder name"
                          onChange={handleNewStakeholderChange}
                          style={{ width: 500, marginBottom: 10 }}
                        />
                        <Button type="primary" style={{
                          bottom: "0px",
                          marginLeft: "10px",
                          backgroundColor: "#060DBF",
                          color: "#62C941",
                          height: "40px",
                          cursor: "pointer",
                        }} onClick={handleAddNewStakeholder}>
                          Submit
                        </Button>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Form.Item>
            </Col>
          </Row>
        ),
        42: (
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={<span style={{fontSize:16, fontWeight: "bold" }}>{`${index + 1}. ${ques.question}`}</span>}>
                <Row gutter={16} style={{ marginLeft: 20 }}>
                  {stakeholdersList.map((stakeholder, i) => (
                    <Col span={24} key={i}>
                      <Form.Item
                        label={stakeholder}
                        name={[ques.id, stakeholder]}
                        rules={[
                          {
                            required: true,
                            message: `Please provide input for ${stakeholder}`,
                          },
                        ]}
                      >
                        <Select placeholder="Select an option" style={{ width: 500 }}>
                          <Option value="yes">Yes</Option>
                          <Option value="no">No</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  ))}
                </Row>
              </Form.Item>
            </Col>
          </Row>
        ),
        43: (
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={<span style={{fontSize:16, fontWeight: "bold" }}>{`${index + 1}. ${ques.question}`}</span>}>
                <Row gutter={16} style={{ marginLeft: 20 }}>
                  {stakeholdersList.map((stakeholder, i) => (
                    <Col span={24} key={i}>
                      <Form.Item
                        label={stakeholder}
                        name={[ques.id, stakeholder]}
                        rules={[
                          {
                            required: true,
                            message: `Please provide input for ${stakeholder}`,
                          },
                        ]}
                      >
                        <Select placeholder="Select an option" style={{ width: 500 }}>
                          <Option value="yes">Yes</Option>
                          <Option value="no">No</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  ))}
                </Row>
              </Form.Item>
            </Col>
          </Row>
        ),
        44: (
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={<span style={{fontSize:16, fontWeight: "bold" }}>{`${index + 1}. ${ques.question}`}</span>}>
                <Row gutter={16} style={{ marginLeft: 20 }}>
                  {Object.keys(communicatorList || {}).map((stakeholder, i) => {
                    if (communicatorList[stakeholder] == 'no') return <div></div>
                    return <Col span={24} key={i}>
                      <Form.Item
                        label={stakeholder}
                        name={[ques.id, stakeholder]}
                        rules={[
                          {
                            required: true,
                            message: `Please provide input for ${stakeholder}`,
                          },
                        ]}
                      >
                        <Select placeholder="Select an option" style={{ width: 500 }}>
                          <Option value="annually">Annually</Option>
                          <Option value="half-yearly">Half yearly</Option>
                          <Option value="quarterly">Quarterly</Option>
                          <Option value="others">Others</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  })}
                </Row>
              </Form.Item>
            </Col>
          </Row>
        ),
        45: (
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={<span style={{fontSize:16, fontWeight: "bold" }}>{`${index + 1}. ${ques.question}`}</span>}>
                <Row gutter={16} style={{ marginLeft: 20 }}>
                  {Object.keys(communicatorList || {}).map((stakeholder, i) => {
                    if (communicatorList[stakeholder] == 'no') return <div></div>
                    return <Col span={24} key={i}>
                      <Form.Item
                        label={stakeholder}
                        name={[ques.id, stakeholder]}
                        rules={[
                          {
                            required: true,
                            message: `Please provide input for ${stakeholder}`,
                          },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          placeholder="Select options"
                          style={{ width: 500 }}
                        >
                          <Option value="Email">Email</Option>
                          <Option value="SMS">SMS</Option>
                          <Option value="Newspaper">Newspaper</Option>
                          <Option value="Pamphlets">Pamphlets</Option>
                          <Option value="Advertisement">Advertisement</Option>
                          <Option value="Community Meetings">Community Meetings</Option>
                          <Option value="Notice Board">Notice Board</Option>
                          <Option value="Website">Website</Option>
                          <Option value="Other">Other</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  })}
                </Row>
              </Form.Item>
            </Col>
          </Row>
        ),
        46: (
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={<span style={{fontSize:16, fontWeight: "bold" }}>{`${index + 1}. ${ques.question}`}</span>}>
                <Row gutter={16} style={{ marginLeft: 20 }}>
                  {stakeholdersList.map((stakeholder, i) => (
                    <Col span={24} key={i}>
                      <Form.Item
                        label={stakeholder}
                        name={[ques.id, stakeholder]}
                        rules={[
                          {
                            required: true,
                            message: `Please provide input for ${stakeholder}`,
                          },
                        ]}
                      >
                        <Input placeholder="Enter your input" style={{ width: 500 }} />
                      </Form.Item>
                    </Col>
                  ))}
                </Row>
              </Form.Item>
            </Col>
          </Row>
        ),
        48: (
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={<span style={{fontSize:16, fontWeight: "bold" }}>{`${index + 1}. ${ques.question}`}</span>}>
                <Row gutter={16} style={{ marginLeft: 20 }}>
                  {stakeholdersList.map((stakeholder, i) => (
                    <Col span={24} key={i}>
                      <Form.Item
                        label={stakeholder}
                        name={[ques.id, stakeholder]}
                        rules={[
                          {
                            required: true,
                            message: `Please provide input for ${stakeholder}`,
                          },
                        ]}
                      >
                        <Input placeholder="Enter your input" style={{ width: 500 }} />
                      </Form.Item>
                    </Col>
                  ))}
                </Row>
              </Form.Item>
            </Col>
          </Row>
        ),
        // Add cases for other question IDs as needed
      };

      return questionComponents[ques.id] || <Questions key={ques.id} ques={ques} index={index} />;
    });
  };

  return (
    <div style={{ paddingTop: "100px" }}>
      <BrsrFormHeading title="Stakeholder Identification" />
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <Spinner className="m-1" color="primary" />
        </div>
      ) : (
      <Form layout="vertical" onFinish={handleSubmit} form={form}>
        {renderQuestions()}
        <Button
          type="primary"
          htmlType="submit"
          style={{
            position: "fixed",
            bottom: "30px",
            backgroundColor: "#060DBF",
            color: "#62C941",
            right: "74px",
            zIndex: 1000,
            height: "40px",
            cursor: "pointer",
          }}
        >
          Save changes
        </Button>
      </Form>
      )}
    </div>
  );
};

export default StakeholderIdentification;
