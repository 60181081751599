import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Row,
  Statistic,
  Progress,
  Tooltip,
} from "antd";
import { BiNews, BiGroup, BiCheckCircle, BiCircle } from "react-icons/bi";
import useHttp from "../../../components/Hook/Use-http";
import CONSTANT, { BRSRSectionsKeys } from "../../Utility/constnt";
import { createEndPoint, replaceId } from "../../Utility/function";
import { useNavigate, useParams } from "react-router-dom";
import ESGReport from "./ESG/ESGReport";

const { Panel } = Collapse;

const organizeData = (data) => {
  const reorganized = {};
  Object.keys(data).forEach((key) => {
    if (!reorganized[key]) {
      reorganized[key] = {};
    }

    reorganized[key].section = {};

    Object.keys(data[key]).forEach((subKey) => {
      if (BRSRSectionsKeys.includes(subKey)) {
        reorganized[key].section[subKey] = data[key][subKey];
      } else {
        reorganized[key][subKey] = data[key][subKey];
      }
    });

    // Calculate totalSPOCs for the sections
    const totalSPOCs = Object.values(reorganized[key].section).reduce(
      (sum, section) => {
        return sum + (section.totalSPOCs || 0);
      },
      0
    );

    Object.keys(reorganized).forEach((key) => {
      let totalSections = 0;
      let completeSections = 0;

      Object.keys(reorganized[key].section).forEach((sectionKey) => {
        let section = reorganized[key].section[sectionKey];
        let sectionComplete = false;
        totalSections++;

        for (let questionKey of Object.keys(section)) {
          if (questionKey === "deadline" || questionKey === "totalSPOCs") {
            continue;
          }
          if (section[questionKey] === "Answered") {
            sectionComplete = true;
          } else {
            sectionComplete = false;
            break;
          }
        }

        if (sectionComplete) {
          completeSections++;
        }
      });

      reorganized[key].completedSectionCount = completeSections;
      reorganized[key].totalSections = totalSections;

      reorganized[key].sectionComplete =
        reorganized[key].totalQuestionsAnswered ===
        reorganized[key].totalQuestionsUnanswered;
    });
  });

  return reorganized;
};

const ScoreTab = () => {
  const API = useHttp();
  const API_2 = useHttp();
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [summaryData, setSummaryData] = useState({});
  const [dataLoading, setDataLoading] = useState(false);
  const [initiatingBRSR, setInitiatingBRSR] = useState(false);
  const [totalForms, setTotalForms] = useState(0);
  const [sections, setSections] = useState([]);
  const role = localStorage.getItem("userRole");
  let reportDeadline;
  const colors = ["#00A36C", "#4169E1", "#FFAC1C"];

  useEffect(() => {
    const brsrFormAPI = createEndPoint.get(
      role === "admin" ? `${role}/brsrForm?clientId=${id}` : `${role}/brsrForm`
    );
    const analysisAPI =
      role === "admin"
        ? replaceId(CONSTANT.API.ANALYSIS.getAdmin, id)
        : CONSTANT.API.ANALYSIS.get;
    setDataLoading(true);
    API.getAll([analysisAPI?.endpoint, brsrFormAPI?.endpoint], (res) => {
      setTotalForms(res[1]?.data?.data?.rows?.length || 0);
      const { deadline, ...resultData } = res[0]?.data.result;
      reportDeadline = deadline;
      const reorganizedData = organizeData(resultData);
      setData(reorganizedData);

      const summary = {
        company_name: "Company Name Pvt Ltd",

        total_questions: Object.values(reorganizedData).reduce(
          (sum, section) =>
            sum +
            (section.totalQuestionsAnswered || 0) +
            (section.totalQuestionsUnanswered || 0),
          0
        ),
        answered_questions: Object.values(reorganizedData).reduce(
          (sum, section) => sum + (section.totalQuestionsAnswered || 0),
          0
        ),
        unanswered_questions: Object.values(reorganizedData).reduce(
          (sum, section) => sum + (section.totalQuestionsUnanswered || 0),
          0
        ),
        spocs_assigned: Object.values(reorganizedData).reduce(
          (sum, section) => sum + (section.totalSPOCs || 0),
          0
        ),
        total_sections: Object.keys(reorganizedData).length,
        sections_completed: Object.values(reorganizedData).reduce(
          (sum, section) => (sum + section.sectionComplete ? 1 : 0),
          0
        ),
        totalSubSection: Object.values(reorganizedData).reduce(
          (sum, section) => sum + section.totalSections,
          0
        ),
        completedSubSections: Object.values(reorganizedData).reduce(
          (sum, section) => sum + section.completedSectionCount,
          0
        ),

        report_due: deadline,
      };
      const sectionDetails = Object.keys(reorganizedData).map((key) => (
        {
        key: key,
        name: `Section ${key}`,
        totalQuestions:
          (reorganizedData[key].totalQuestionsAnswered || 0) +
          (reorganizedData[key].totalQuestionsUnanswered || 0),
        answeredQuestions: reorganizedData[key].totalQuestionsAnswered || 0,
        unansweredQuestions: reorganizedData[key].totalQuestionsUnanswered || 0,
        spocsAssigned: reorganizedData[key].totalSPOCs || 0,
      }));
      setSummaryData(summary);
      setSections(sectionDetails);
      setDataLoading(false);
    });
  }, []);

  const formatProgressCircle = (section) => {
    return (percent) => (
      <div>
        <small>{section}</small>
        <br />
        <small>{`${percent}%`}</small>
      </div>
    );
  };

  const initiateBRSR = async () => {
    if (role === "spoc") return;
    setInitiatingBRSR(true);
    API_2.sendRequest(
      CONSTANT.API.BRSR_FORMS.create,
      (res) => {
        setInitiatingBRSR(false);
        navigate("?tab=forms");
      },
      {
        clientId: id,
        sections:
          "A_I,A_II,A_III,A_IV,A_V,A_VI,A_VII,B,C_P1_E,C_P1_L,C_P2_E,C_P2_L,C_P3_E,C_P3_L,C_P4_E,C_P4_L,C_P5_E,C_P5_L,C_P6_E,C_P6_L,C_P7_E,C_P7_L,C_P8_E,C_P8_L,C_P9_E,C_P9_L",
      },
      "BRSR Initiated Sucessfully"
    );
  };

  const InitiateBRSRButtonDiv = () => (
    <div
      className="position-absolute d-flex justify-content-center align-items-start w-full mt-4"
      style={{ zIndex: "100", minHeight: "90vh" }}
    >
      <Button
        color="primary"
        className="btn btn-primary waves-effect waves-light px-4 py-2"
        onClick={initiateBRSR}
        loading={initiatingBRSR}
      >
        {initiatingBRSR ? "Initiating BRSR" : "Initiate BRSR"}
      </Button>
    </div>
  );

  return (
    <>
      {totalForms === 0 && !dataLoading && <InitiateBRSRButtonDiv />}
      <a
        href="https://fitsol-bucket.s3.ap-south-1.amazonaws.com/Gensol+Enginnering+Limited+-+Business+Responsibility+and+Sustainability+Report.pdf"
        target="_blank"
      >
        <Button
          style={{
            padding: "5px",
            bottom: "30px",
            marginTop: "30px",
            backgroundColor: "#060DBF",
            // right:"-90%",
            color: "#62C941",
            height: "40px",
            cursor: "pointer",
          }}
        >
          Download report
        </Button>
      </a>
      <div
        className="w-full"
        style={totalForms === 0 && !dataLoading ? { filter: "blur(5px)" } : {}}
      >
        <Row gutter={[16, 16]} className="w-full">
          <Col span={6}>
            <Card
              size="small"
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
                justifyContent: "center",
              }}
              loading={dataLoading}
            >
              <div>{summaryData.company_name}</div>
            </Card>
          </Col>
          <Col span={6}>
            <Card
              size="small"
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
                justifyContent: "center",
              }}
              loading={dataLoading}
            >
              <div>
                <span className="text-secondary">Report Due: </span>
                <span style={{ marginLeft: 4 }}>
                  {summaryData?.report_due
                    ? new Date(summaryData?.report_due)
                        .toLocaleString("en-GB")
                        .split(",")[0]
                    : "-"}
                </span>
              </div>
            </Card>
          </Col>
          <Col span={12}>
            <Card size="small" loading={dataLoading}>
              <div style={{ marginBottom: 8, fontSize: 14, color: "#555" }}>
                Form Completion Status
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Progress
                  percent={Math.floor(
                    ((summaryData?.answered_questions || 0) /
                      (summaryData?.total_questions || 1)) *
                      100
                  )}
                  style={{ margin: 0, flex: 1 }}
                />
              </div>
            </Card>
          </Col>
        </Row>
        <Row gutter={[0, 16]} className="w-full mt-3">
          <Col span={12}>
            <Row wrap={true} gutter={[16, 16]} className="w-full">
              <Col span={12}>
                <Card style={{ height: "100%" }} loading={dataLoading}>
                  <Collapse accordion bordered={false} ghost>
                    <Panel
                      header={
                        <Statistic
                          title="Forms Completed"
                          value={summaryData.completedSubSections}
                          prefix={<BiNews />}
                          suffix={`/${summaryData.totalSubSection}`}
                        />
                      }
                      key="1"
                    >
                      {sections.map((section, index) => (
                        <div
                          key={section.name}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <div
                            style={{
                              height: "15px",
                              width: "15px",
                              borderRadius: "50%",
                              backgroundColor: `${
                                colors[index % colors.length]
                              }`,
                            }}
                          ></div>
                          <p style={{ margin: "0px", paddingLeft: "10px" }}>
                            {`${section.name} (${
                              data[section.key].completedSectionCount
                            } / ${data[section.key].totalSections} )`}
                          </p>
                        </div>
                      ))}
                    </Panel>
                  </Collapse>
                </Card>
              </Col>
              <Col span={12}>
                <Card style={{ height: "100%" }} loading={dataLoading}>
                  <Collapse bordered={false} ghost>
                    <Panel
                      header={
                        <Statistic
                          title="Users Assigned"
                          value={+summaryData.spocs_assigned + 1}
                          prefix={<BiGroup />}
                        />
                      }
                      key="2"
                    >
                      {sections.map((section, index) => (
                        <div
                          key={section.name}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <div
                            style={{
                              height: "15px",
                              width: "15px",
                              borderRadius: "50%",
                              backgroundColor: `${
                                colors[index % colors.length]
                              }`,
                            }}
                          ></div>
                          <p style={{ margin: "0px", paddingLeft: "10px" }}>
                            {`${section.name} (${+section.spocsAssigned + 1})`}
                          </p>
                        </div>
                      ))}
                    </Panel>
                  </Collapse>
                </Card>
              </Col>
              <Col span={12}>
                <Card style={{ height: "100%" }} loading={dataLoading}>
                  <Collapse bordered={false} ghost>
                    <Panel
                      header={
                        <Statistic
                          title="Answered Questions"
                          value={summaryData.answered_questions}
                          prefix={<BiCheckCircle />}
                          suffix={`/${summaryData.total_questions}`}
                        />
                      }
                      key="3"
                    >
                      {sections.map((section, index) => (
                        <div
                          key={section.name}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <div
                            style={{
                              height: "15px",
                              width: "15px",
                              borderRadius: "50%",
                              backgroundColor: `${
                                colors[index % colors.length]
                              }`,
                            }}
                          ></div>
                          <p style={{ margin: "0px", paddingLeft: "10px" }}>
                            {`${section.name} (${section.answeredQuestions} / ${section.totalQuestions})`}
                          </p>
                        </div>
                      ))}
                    </Panel>
                  </Collapse>
                </Card>
              </Col>
              <Col span={12}>
                <Card style={{ height: "100%" }} loading={dataLoading}>
                  <Collapse bordered={false} ghost>
                    <Panel
                      header={
                        <Statistic
                          title="Unanswered Questions"
                          value={summaryData.unanswered_questions}
                          prefix={<BiCircle />}
                          suffix={`/${summaryData.total_questions}`}
                        />
                      }
                      key="4"
                    >
                      {sections.map((section, index) => (
                        <div
                          key={section.name}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <div
                            style={{
                              height: "15px",
                              width: "15px",
                              borderRadius: "50%",
                              backgroundColor: `${
                                colors[index % colors.length]
                              }`,
                            }}
                          ></div>
                          <p style={{ margin: "0px", paddingLeft: "10px" }}>
                            {`${section.name} (${section.unansweredQuestions} / ${section.totalQuestions})`}
                          </p>
                        </div>
                      ))}
                    </Panel>
                  </Collapse>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Card
              style={{ textAlign: "center", height: "100%" }}
              loading={dataLoading}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "16px",
                }}
              >
                {sections.map((section, index) => (
                  <Tooltip
                    key={index}
                    title={`${section.answeredQuestions} / ${section.totalQuestions} answered`}
                  >
                    <Progress
                      percent={Math.ceil(
                        (section.answeredQuestions / section.totalQuestions) *
                          100
                      )}
                      type="dashboard"
                      gapDegree={90}
                      size={125}
                      strokeColor={`${colors[index % colors.length]}`}
                      format={formatProgressCircle(section.name)}
                    />
                  </Tooltip>
                ))}
              </div>
              <Divider style={{ margin: "10px" }} />
              <div>Section Wise Progress</div>
            </Card>
          </Col>
        </Row>
        <Row gutter={[0, 16]} className="w-full">
          <ESGReport />
        </Row>
      </div>
    </>
  );
};

export default ScoreTab;
