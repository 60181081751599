import React from "react";
// import "./style/global.css";
// import "./style/index.css";
import "./style/custom.css";
import logo from "../../assets/images/image-2@2x.png";
import logoMain from "../BillForm/logo-light.3f82f4ae.png";
import moment from "moment";
import BillTableGen from "./billTableGen";

const BillForm = (props) => {
  const { trip, packageList } = props;

  return (
    <>
      <div
        className="bilty"
        style={{ padding: "35px", width: "2390px", height: "3300px" }}
      >
        <BillTableGen trip={trip} packageList={packageList} />
        <BillTableGen trip={trip} packageList={packageList} />
      </div>
    </>
  );
};

export default BillForm;
