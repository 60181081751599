import { Col, Result, Row } from "antd";
import React from "react";
import { Button, Card, CardBody, Container } from "reactstrap";
import errorImage from "../../assets/images/error-img.png";
import { useNavigate } from "react-router";

const Pages403 = () => {
  const navigate = useNavigate();
  return (
    <>
      <React.Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={16} lg={12} xl={10}>
                <Card className="overflow-hidden">
                  <CardBody>
                    <div className="text-center p-3">
                      <div className="img">
                        {/* <img src={errorImage} className="img-fluid" alt="" /> */}
                      </div>

                      <Result
                        status="403"
                        title={<p style={{ fontSize: "60px" }}>403!</p>}
                        subTitle={
                          <p style={{ fontSize: "15px" }}>
                            Sorry, you are not authorized to access this page.
                          </p>
                        }
                        extra={
                          <Button
                            color="primary"
                            // className="btn btn-primary waves-effect waves-light"
                            onClick={() => {
                              navigate("/");
                            }}
                          >
                            Back Dashboard
                          </Button>
                        }
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
      {/* <React.Fragment>
        <Result
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page."
          extra={
            <Button
              color="primary"
              // className="btn btn-primary waves-effect waves-light"
              onClick={() => {
                navigate("/");
              }}
            >
              Back Dashboard
            </Button>
          }
        />
      </React.Fragment> */}
    </>
  );
};

export default Pages403;
