import React, { useEffect, useState } from "react";
import { Empty } from "antd";
import { Card, Button, Row, Col } from "reactstrap";
import CONSTANT, { TABLE_HEADER } from "../../Utility/constnt";
import { createEndPoint, replaceId } from "../../Utility/function";
import useHttp from "../../../components/Hook/Use-http";
import create from "@ant-design/icons/lib/components/IconFont";
import CustomPage from "../../../components/Custome/CustomPage";
import { userApprovalDataMask } from "../../Utility/spread-function";
const UserApprovalCard = ({ userData, clientId, setUpdatedList }) => {
  const userRole = localStorage.getItem("userRole");
  const [data, setData] = useState(userData || []);
  const [flag, setFlag] = useState(false);
  useEffect(() => {
    if (userData) {
      setData(userData);
      setFlag ((prevState) => !prevState);
    }
  }, [userData]);
  return (
    <div className="w-full">
      <CustomPage
        PAGE={{
          title: "New users approval request",
          dataFunction: (res, action) => {
            return userApprovalDataMask(data, action, setUpdatedList);
          },
          pageRefresh: { flag },
        }}
        API={{
          ...CONSTANT.API.USERAPPROVAL,
          get: createEndPoint.get(
            `${userRole}/connections/getUnverifiedClientList?clientId=${clientId}`
          ),
          update: createEndPoint.patch(
            `${userRole}/connections/user/verifyNewClient`
          ),
        }}
        // FORM = {{
        // }}
        TABLE={{
          title: "New users approval list",
          header: TABLE_HEADER?.newUserData,
        }}
      />
    </div>
  );
};

export default UserApprovalCard;
