/* USERS */
// export const GET_USERS = "GET_USERS";
// export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
// export const GET_USERS_FAIL = "GET_USERS_FAIL";

// /* USERS PROFILE */
// export const GET_USER_PROFILE = "GET_USER_PROFILE";
// export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
// export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL";

// export const GET_DRIVER_DETAIL_SUCCESS = "GET_DRIVER_DETAIL_SUCCESS";
// export const GET_MY_PROFILE = "GET_MY_PROFILE";

export const SHIPMENT_DETAIL = "SHIPMENT_DETAIL";
export const SET_SHIPMENT_DETAIL = "SET_SHIPMENT_DETAIL";

export const DELETE_SHIPMENT_START = "DELETE_SHIPMENT_START";
export const DELETE_SHIPMENT_SUCCESS = "DELETE_SHIPMENT_SUCCESS";
export const DELETE_SHIPMENT_FAILURE = "DELETE_SHIPMENT_FAILURE";

export const GET_SHIPMENT_START = "GET_SHIPMENT_START";
export const GET_SHIPMENT_SUCCESS = "GET_SHIPMENT_SUCCESS";
export const GET_SHIPMENT_FAILURE = "GET_SHIPMENT_FAILURE";
