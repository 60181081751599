import { Card, Col, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import TableComponent from "./tableComponent";
import { AndroidOutlined, AppleOutlined } from "@ant-design/icons";
import HeaderGen from "../../components/pcfComponent/headerGen";
import TableFormFields from "../../components/pcfComponent/tableFormFields";
import SingleTabel from "../../components/pcfComponent/singleTabel";
import CONSTANT, {
  PCFTabsList,
  pcfProductDetailTableFields,
} from "../Utility/constnt";
import "../../assets/css/tableComponent.css";
import ProductTab from "./tabs/productTab";
import MaterialTab from "./tabs/materialTab";
import PackagingTab from "./tabs/packagingTab";
import ProductAssemblyTab from "./tabs/productAssemblyTab";
import { useLocation, useNavigate } from "react-router-dom";
import useHttp from "../../components/Hook/Use-http";
import { notifications, replaceId } from "../Utility/function";
import PcfServices from "../Utility/API/pcfService";
import ResultTab from "./tabs/resultTab";
import {
  conditionForValidation,
  validationForMaterial,
} from "../Utility/pcfSpreadFunction";

const PcfPage = () => {
  const [tabIndex, setTabIndex] = useState("1");
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();
  const [mainProductData, setMainProductData] = useState({});
  const API = useHttp();

  const urlParams = new URLSearchParams(location?.search);

  const productId = urlParams.get("productid");
  const onChange = (key) => {
    switch (key) {
      case "1":
        setTabIndex(key);
        return navigate(
          `${currentPath}?tab=product${
            productId ? `&productid=${productId}` : ""
          }`
        );

      case "2":
        if (validationForMaterial(mainProductData)) {
          setTabIndex(key);
          return navigate(
            `${currentPath}?tab=parts${
              productId ? `&productid=${productId}` : ""
            }`
          );
        } else return;

      case "3":
        if (validationForMaterial(mainProductData)) {
          setTabIndex(key);
          return navigate(
            `${currentPath}?tab=packaging${
              productId ? `&productid=${productId}` : ""
            }`
          );
        } else return;

      case "4":
        if (validationForMaterial(mainProductData)) {
          setTabIndex(key);
          return navigate(
            `${currentPath}?tab=energyAndTransport${
              productId ? `&productid=${productId}` : ""
            }`
          );
        } else return;
      case "5":
        if (validationForMaterial(mainProductData)) {
          setTabIndex(key);
          return navigate(
            `${currentPath}?tab=results${
              productId ? `&productid=${productId}` : ""
            }`
          );
        } else return;
      default:
        return navigate(
          `${currentPath}?tab=product${
            productId ? `&productid=${productId}` : ""
          }`
        );
    }
  };

  const tabSelected = () => {
    switch (tabIndex) {
      case "1":
        // navigate(`${currentPath}?tab=product`);
        return (
          <ProductTab
            productId={productId}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            setMainProductData={setMainProductData}
            mainProductData={mainProductData}
          />
        );
      case "2":
        // navigate(`${currentPath}?tab=parts`);
        return (
          <MaterialTab
            productId={productId}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            mainProductData={mainProductData}
          />
        );
      case "3":
        return (
          <PackagingTab
            productId={productId}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            mainProductData={mainProductData}
          />
        );
      case "4":
        return (
          <ProductAssemblyTab
            productId={productId}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            mainProductData={mainProductData}
          />
        );
      case "5":
        return (
          <ResultTab
            productId={productId}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            mainProductData={mainProductData}
          />
        );

      default:
        return (
          <ProductTab
            productId={productId}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            setMainProductData={setMainProductData}
            mainProductData={mainProductData}
          />
        );
    }
  };
  useEffect(() => {
    productId &&
      productId != "undefined" &&
      API.getAll(
        [replaceId(CONSTANT.API.PCFPRODUCT.getSingle, productId).endpoint],
        (res) => {
          setMainProductData(res?.[0]?.data?.data);
          navigate(
            `${currentPath}?tab=product&productid=${res?.[0]?.data?.data?.id}`
          );
        },
        PcfServices
      );
  }, []);

  return (
    <>
      <div className="page-content pcf-table">
        <Row className="">
          <Col xs={24}>
            <p className="pcf-calculator-div">CALCULATOR</p>
          </Col>
          <Col xs={24} className="">
            <p className="pcf-heading-text">Product Carbon Footprint (PCF)</p>
          </Col>
          <Col xs={24} className="">
            <p className="pcf-heading-sec-text">
              Use the calculator fields below to calculate the total carbon
              footprint for your organisation
            </p>
          </Col>
        </Row>
        <Row className="w-full ">
          <Tabs
            className="pcf-tabs w-full "
            activeKey={`${tabIndex}`}
            items={PCFTabsList}
            onChange={onChange}
          />
        </Row>
        <Row className="" style={{ marginTop: "30px" }}>
          {tabSelected()}
        </Row>
      </div>
    </>
  );
};

export default PcfPage;
