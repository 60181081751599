import React, { useEffect, useState } from "react";
import { Form, Input, Row, Col, Button } from "antd";
import useHttp from "../../../components/Hook/Use-http";
import CONSTANT from "../../Utility/constnt";
import Helper from "../Helper";
// import useHttp from '../../components/Hook/Use-http';
// import CONSTANT from '../Utility/constnt';
// import FormTable from '../../components/BRSR_forms/formTable';
// import Helper from './helper';
import { useParams } from "react-router-dom"
import { replaceId } from "../../Utility/function";
import Submit from "../submitButton";
import BrsrFormHeading from "../BrsrFormHeading";
const { TextArea } = Input;

const ProductAndServiceForm = () => {
  const API = useHttp();
  const { formId } = useParams();
  const [finalData, setFinalData] = useState({ A_II: {} });
  const sectionName = "A_II";
  const [formTableData, setFormTableData] = useState([
    {
      qnos: "16",
      type: "table",
      isDynamic: true,
      title: "16. Details of business activities (accounting for 90% of the turnover):",
      keyMappings: {
        1: {
          "DescriptionOfMainActivity|D_BusinessActivities":
            "DescriptionOfMainActivity|D_BusinessActivities",
          "DescriptionOfBusinessActivity|D_BusinessActivities":
            "DescriptionOfBusinessActivity|D_BusinessActivities",
          "PercentageOfTotalTurnoverForBusinessActivities|D_BusinessActivities":
            "PercentageOfTotalTurnoverForBusinessActivities|D_BusinessActivities",
        },
      },
      data: [
        {
          key: "1",
          serialNumber: "1",
          "DescriptionOfMainActivity|D_BusinessActivities": "",
          "DescriptionOfBusinessActivity|D_BusinessActivities": "",
          "PercentageOfTotalTurnoverForBusinessActivities|D_BusinessActivities": "",
        },
      ],
    },
    {
      qnos: "17",
      type: "table",
      isDynamic: true,
      title: "17.  Products/Services sold by the entity (accounting for 90% of the entity’s Turnover):",
      keyMappings: {
        1: {
          "ProductOrServiceSoldByTheEntity|D_ProductServiceSold":
            "ProductOrServiceSoldByTheEntity|D_ProductServiceSold",
          "NICCodeOfProductOrServiceSoldByTheEntity|D_ProductServiceSold":
            "NICCodeOfProductOrServiceSoldByTheEntity|D_ProductServiceSold",
          "PercentageOfTotalTurnoverForProductOrServiceSold|D_ProductServiceSold":
            "PercentageOfTotalTurnoverForProductOrServiceSold|D_ProductServiceSold",
        },
      },
      data: [
        {
          key: "1",
          serialNumber: "1",
          "ProductOrServiceSoldByTheEntity|D_ProductServiceSold": "",
          "NICCodeOfProductOrServiceSoldByTheEntity|D_ProductServiceSold": "",
          "PercentageOfTotalTurnoverForProductOrServiceSold|D_ProductServiceSold": "",
        },
      ],
    },
  ]);

  const handleAddRow = (qnos) => {
    setFormTableData((prevData) => {
      return prevData.map((item) => {
        if (item.qnos === qnos) {
          const newKey = (item.data.length + 1).toString();
          const newRow = { key: newKey, serialNumber: newKey };

          Object.values(item.keyMappings[1]).forEach((key) => {
            newRow[key] = "";
          });

          return {
            ...item,
            data: [...item.data, newRow],
          };
        }
        return item;
      });
    });

    setFinalData((prevData) => {
      // Create a shallow copy of the previous state to ensure immutability
      const newData = { ...prevData };

      // Ensure the section exists
      if (!newData[sectionName]) {
        newData[sectionName] = {};
      }

      // Ensure the qnos is an array
      if (!Array.isArray(newData[sectionName][qnos])) {
        const obj = newData[sectionName][qnos];
        newData[sectionName][qnos] = obj ? [obj] : [];
      }

      // Add the first element to the array again
      const row = newData[sectionName][qnos][0];
      Object.keys(row).map((key) => {
        row[key] = '';
      })
      if (row) {
        newData[sectionName][qnos].push(row);
      }

      return newData;
    });

  };


  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       let apiData;
  //       API.sendRequest(replaceId(CONSTANT.API.BRSRFORMS.get2, formId), (res) => {
  //         apiData = res?.data || { A_II: {} };
  //         const updatedFormTableData = formTableData.map((item) => {
  //           const apiItemData = apiData.A_II[item.qnos];
  //           if (apiItemData) {
  //             const updatedData = item.data.map((row) => {
  //               const updatedRow = { ...row };
  //               Object.keys(item.keyMappings[row.key] || {}).forEach((columnName) => {
  //                 const brsrKey = item.keyMappings[row.key][columnName];
  //                 updatedRow[columnName] = apiItemData[brsrKey] || '';
  //               });
  //               return updatedRow;
  //             });
  //             return { ...item, data: updatedData };
  //           }
  //           return item;
  //         });
  //         setFormTableData(updatedFormTableData);
  //         setFinalData(apiData);
  //       });
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };

  //   fetchData();
  // }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        let apiData;
        API.sendRequest(replaceId(CONSTANT.API.BRSRFORMS.get2, formId), (res) => {
          apiData = res?.data || { A_II: {} };
          const updatedFormTableData = formTableData.map((item) => {
            const apiItemData = apiData.A_II[item.qnos];
            if (Array.isArray(apiItemData)) {
              // Manually assign serialNumber and key
              const updatedData = apiItemData.map((rowData, index) => ({
                ...rowData,
                serialNumber: (index + 1).toString(), // Serial number starts from 1
                key: (index + 1).toString(), // Key can be any unique identifier
              }));
              return { ...item, data: updatedData };
            } else {
              const updatedData = item.data.map((row) => {
                const updatedRow = { ...row };
                Object.keys(item.keyMappings[row.key] || {}).forEach((columnName) => {
                  const brsrKey = item.keyMappings[row.key][columnName];
                  updatedRow[columnName] = apiItemData[brsrKey] || '';
                });
                return updatedRow;
              });
              return { ...item, data: updatedData };
            }
            return item;
          });
          setFormTableData(updatedFormTableData);
          setFinalData(apiData);
        });
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {

    console.log('finaldata:', finalData);
  }, [finalData])

  const handleSubmit = () => {
    try {
      API.sendRequest(
        replaceId(CONSTANT.API.BRSRFORMS.create, formId),
        (res) => {
          console.log(res);
        },
        { data: finalData },
        "Data saved successfully"
      );
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div style={{
      paddingTop: "100px",
    }}>
      <BrsrFormHeading 
        title="II. Products/Services" 
        subtitle="Section A: General Disclosures" 
      />
      {formTableData.map((item) => (
        <Helper key={item.qnos} {...item} setFinalData={setFinalData} sectionName={"A"} handleAddRow={handleAddRow} />
      ))}
      <Submit handleSubmit = {handleSubmit} loading={API.isLoading}></Submit>
    </div>
  );
};

export default ProductAndServiceForm;
