import React, { useEffect, useState } from "react";
import CustomPage from "../../../Custome/CustomPage";
import {
  emissionEntryCategoryWiseDataLoad,
  emissionForEditFormDataCallLoad,
  jobViewDataMask,
} from "../../../../pages/Utility/spread-function";
import CONSTANT, {
  getTableColumConfig,
} from "../../../../pages/Utility/constnt";
import {
  getRecordId,
  replaceId,
  tableHeaderToExportHeader,
} from "../../../../pages/Utility/function";
import { useLocation, useParams } from "react-router-dom";

const StationaryCombustionView = (props) => {
  const {
    jobId,
    jobSingleData,
    API,
    initHeader,

    setJobViewData,
    onViewReason,
    responceStatus,
    flag,
  } = props;
  const [subCategoryModal, setSubCategoryModal] = useState([]);
  const [subHeader, setHeader] = useState([]);
  const [dynamicOption, setDynamicOption] = useState({});
  const [editData, setEditData] = useState({});
  const [fuelTypeData, setfuelTypeData] = useState(null);
  const [fuelTypeTreatment, setfuelTypeTreatment] = useState([]);

  const [formData, setFormData] = useState([]);
  const [alldynamicOption, setAllDynamicOption] = useState({});
  const userRole = localStorage.getItem("userRole");
  const location = useLocation();
  const currentPath = location.pathname;
  const { id } = useParams();
  useEffect(() => {
    jobSingleData &&
      API?.getAll(
        [
          CONSTANT?.API.SUBCATEGORYATTRIBUTE.get.endpoint,
          CONSTANT?.API?.ATTRIBUTEOPTION.get?.endpoint,
          CONSTANT?.API.SITE.get?.endpoint,
          CONSTANT?.API.JOB.getFuelType?.endpoint,
        ],
        (res) => {
          // const subCategoryAttributeRes = res[0]?.data;
          let categoryOption = {};
          const subCategoryAttributeOptionRes = res[1]?.data?.data;
          const sites =
            userRole == "admin" && currentPath.includes("/client") && id
              ? res[2]?.data?.data?.rows?.filter((el) => el?.clientId == id)
              : res[2]?.data?.data?.rows;
          const Other = {
            site: sites,
            fuelType: res[3]?.data?.data?.rows?.map((el) => {
              categoryOption = {
                ...categoryOption,
                [el.fuelType]: el?.availableUnits,
              };

              return {
                ...el,
              };
            }),
          };
          setfuelTypeData(res[3]?.data?.data?.rows);

          const rearrangedData = res[0]?.data?.data?.rows
            ?.filter((it) => it?.subCategoryId == jobSingleData?.subCategoryId)
            ?.sort((a, b) => +a.index - +b.index);

          let arrHeader = [...initHeader];

          const dddd = emissionEntryCategoryWiseDataLoad(
            subCategoryAttributeOptionRes,
            Other,
            rearrangedData,
            setDynamicOption,
            arrHeader
          );

          setSubCategoryModal(dddd?.arr);
          setAllDynamicOption((prev) => ({
            ...prev,
            ...categoryOption,
          }));
          setHeader((pr) => {
            return [...dddd?.arrHeader, getTableColumConfig("action")];
          });
        }
      );
  }, [jobSingleData]);

  useEffect(() => {
    emissionForEditFormDataCallLoad(
      subCategoryModal,
      setDynamicOption,
      setSubCategoryModal,
      formData,
      alldynamicOption,
      editData
    );
  }, [formData, editData]);

  useEffect(() => {
    const keyOnly = subCategoryModal?.find(
      (el) => el?.extraValue?.keyOrEquetion?.[0]
    );

    // keyOnly?.id
    const keyOrEquetionArr = keyOnly?.extraValue?.keyOrEquetion;

    if (formData?.data && editData) {
      const findUnit = fuelTypeData?.find(
        (el) =>
          el?.fuelType ==
          formData?.data?.[keyOnly?.extraValue?.keyOrEquetion?.[0]]
      );

      const unitpayloadvalue =
        formData?.data[keyOnly?.id] || editData?.[keyOnly?.id];

      const fuelUnit = findUnit?.availableUnits?.filter(
        (ell) => ell?.value == unitpayloadvalue
      );

      if (fuelUnit?.length == 0) {
        // return ["87"];
        const ddd = formData?.data;
        delete ddd?.[keyOnly?.id]; //87

        const ssss = formData?.selectedValue;

        delete ssss?.[keyOnly?.id]; //87

        formData?.operations?.inputData(ddd);
        formData?.operations?.selectedValue(ssss);
      }
    }
  }, [formData, editData]);

  return (
    <>
      <CustomPage
        PAGE={{
          // withBackButton: true,
          title: `JOB / ${getRecordId?.job(jobId)}`,
          dataFunction: (res, Actions) => {
            setJobViewData(res?.result);

            return jobViewDataMask(res, Actions, onViewReason, responceStatus);
          },
          pageRefresh: { flag },
        }}
        noHeader={false}
        API={{
          get: replaceId(CONSTANT?.API.JOBVIEW.get, jobId),
          update: CONSTANT?.API.JOBVIEW.update,
          delete: CONSTANT?.API.JOBVIEW.delete,
        }}
        // pageHeading={`JOB / ${jobId}`}
        //Form Customization
        // pageHeading="CUSTOMER"

        FORM={{
          formField: subCategoryModal,

          editFormTitle: "Edit Job",
          option: {
            ...dynamicOption,
          },
          editDataPayload: (editData) => {
            setEditData(editData);
          },

          prepareUpdatePayload: (editPayload, payload) => {
            const objPayload = {
              data: {
                ...editPayload,
                ...payload,
              },
            };
            const keyOnly = subCategoryModal?.find(
              (el) => el?.extraValue?.keyOrEquetion?.[0]
            );

            const findUnit = fuelTypeData
              ?.find(
                (el) =>
                  el?.fuelType ==
                  formData?.data?.[keyOnly?.extraValue?.keyOrEquetion?.[0]]
              )
              ?.availableUnits?.filter(
                (ell) => ell?.value == fuelTypeTreatment
              );

            const e = { ...objPayload };

            if (findUnit?.length == 0) {
              delete objPayload?.data?.[keyOnly?.id];
            }

            delete objPayload?.data?.id;

            Object?.keys(objPayload?.data)?.map((itm) => {
              objPayload.data[`id${itm}`] = payload[itm] || editPayload[itm];
            });

            return {
              ...objPayload,
            };
          },
          onChange: (data, selectedValue, operations) => {
            // setEditData(data);
            const keyOnly = subCategoryModal?.find(
              (el) => el?.extraValue?.keyOrEquetion?.[0]
            );
            setfuelTypeTreatment(data?.[keyOnly?.id]);
            setFormData({ data, selectedValue, operations });
          },
          selectedValue: false,
        }}
        TABLE={{
          title: "Record List",
          header: subHeader,
          export: {
            fileName: `${getRecordId?.job(jobId)}_Record.csv`,
            header: tableHeaderToExportHeader?.header(subHeader),
          },
        }}
      />
    </>
  );
};

export default StationaryCombustionView;
