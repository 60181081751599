import React, { useEffect, useState } from "react";
import AntDFormFields from "./Antd-FormFields";
import { Button, Form } from "antd";

const AntdForm = ({
  form,
  dynamicOption,
  changedFields,
  formFields,
  onSubmit = () => {},
  submitName = "Submit",
  formData = {},
  modalType = "formantmodal",
  close = () => null,
}) => {
  // const [form] = Form.useForm();
  const [defaultValue, setDefaultValue] = useState({});
  useEffect(() => {
    if (modalType == "formantmodal") {
      setDefaultValue(formData);
    }
  }, [formData]);
  return (
    <>
      <AntDFormFields
        formFields={formFields}
        form={form}
        dynamicOption={dynamicOption}
        changedFields={changedFields}
        formData={defaultValue}
      />
      {modalType == "formantmodal" ? (
        <>
          <div className="mt-4 mb-3 d-flex align-items-center justify-content-end gap-3 m-2">
            <Button
              type="primary"
              className="btn btn-primary waves-effect waves-light d-flex align-items-center"
              onClick={() => {
                close();
              }}
              //   className="btn btn-primary waves-effect"
              data-dismiss="modal"
            >
              Close
            </Button>
            <Button
              className="  btn btn-primary waves-effect waves-light d-flex align-items-center"
              type="primary"
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    setDefaultValue(values);

                    const clear = () => {
                      setDefaultValue({});
                    };
                    onSubmit(values, clear);
                  })
                  .catch((info) => {
                    console.log("Validate Failed:", info);
                  });
              }}
            >
              {submitName}
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="d-flex align-items-center justify-content-center">
            <Button
              className="mt-4  btn btn-primary waves-effect waves-light d-flex align-items-center"
              type="primary"
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    setDefaultValue(values);

                    const clear = () => {
                      setDefaultValue({});
                    };
                    onSubmit(values, clear);
                  })
                  .catch((info) => {
                    console.log("Validate Failed:", info);
                  });
              }}
            >
              {submitName}
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default AntdForm;
