import React, { useState, useEffect } from "react";
import BulkUploadPartner from "../../../../components/emissionEntry/bulkUploadView/BulkUploadPartner";
import TableView from "../../../../components/Custome/tableView";
import { Card, CardBody } from "reactstrap";
import useHttp from "../../../../components/Hook/Use-http";
import { useSelector } from "react-redux";
import { bulkUploadConnectionJobLoad, bulkUploadJobLoad } from "../../../Utility/spread-function";
import Loader from "../../../../components/Loader";
import CONSTANT, { getTableData, TABLE_HEADER } from "../../../Utility/constnt";
import { useParams } from "react-router-dom";
import { replaceId } from "../../../Utility/function";
const BulkOnboard = () => {
  const {id} = useParams();
  const userRole = localStorage.getItem("userRole");
  const API_CALL = useHttp();
  const [jobData, setJobData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    const fetchJobData = async () => {
        if (userRole === "admin" || userRole === "client"){
            API_CALL.sendRequest(
                replaceId(CONSTANT.API.CONNECTIONS.getClientJob, id),
                (res) => {
                    let formattedData = bulkUploadConnectionJobLoad(res?.data);
                    setJobData(formattedData);
                    setLoading(false);
                }
            )
        } else{
          console.error("you are not allowed to access this");
        }
    }
    fetchJobData();
  },[]);


  return (
    <React.Fragment>
      <BulkUploadPartner
      // extraValue={{
      //     subCategory: subCategory,
      //     setFlag: setFlag,
      //   }}
      />
      {loading ? (
        <Loader />
      ) : (
        <Card style={{ borderRadius: "7px" }}>
          <CardBody>
            <TableView 
            title="Partners list" 
            data={getTableData(TABLE_HEADER?.connectionsJobTable, jobData)}
            // exportheaders to be added 
            excelName="Invited_Partners.csv" />
          </CardBody>
        </Card>
       )} 
    </React.Fragment>
  );
};
export default BulkOnboard;

