import React, { useState, useEffect } from "react";
import { Form, Button, Checkbox, Input, Row, Col, Select, InputNumber } from "antd";
import useHttp from "../../../components/Hook/Use-http";
import BrsrFormHeading from "../BrsrFormHeading";
import Questions from "../../../components/Report/Questions";
import { replaceFormIdandClientId, replaceId } from "../../Utility/function";
import CONSTANT from "../../Utility/constnt";
import { StarFilled } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import PhoneInput from "react-phone-input-2";

const GeneraDisclousers = () => {
  const { id } = useParams();
  const userRole = localStorage.getItem("userRole");
  const API_CALL = useHttp();

  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();


  const getTooltipText = {
    3: "General disclosures about the entity act as ready reference for the entity’s investors. This also helps data users to understand your responses in the context of your business activities and sector as well as their connection to environmental issues and corporate strategy.",
    4: "Provide the unique identification number of the company",
    12: "Reporting period as adopted by the Entity. An entity must ensure that the reporting period for its sustainability reporting aligns with the period for its financial reporting.",
    13: "This question helps to provide a clear time frame for year-to-year comparisons and evaluations of an organization's environmental progress. It increases transparency and accountability and helps data users understand your responses in relation to the reported time period.",
    17: "This question helps people understand how your answers are related to your business. Your response to this question defines the groups or entities for which you are providing data.",
    18: "The question is asking about the firm which provided the third party assurance (audit)",
    19: "The question is asking about the type of assurance (audit) provided, whether is reasonable or limited",
  };

  useEffect(() => {
    try {
      const parser = (data = []) => {
        return data.map((curr) => {
          const dataSchema = JSON.parse(curr.Question.answerSchema);
          let answerString = curr.Question?.Answers[0]?.value;
          if (
            curr.Question.id === 7 ||
            curr.Question.id === 8 ||
            curr.Question.id === 16
          ) {
            try {
              answerString = JSON.parse(answerString.replace(/\\"/g, '"'));
            } catch (e) {
              console.error("Error parsing address field", e);
            }
          }
          return {
            id: curr.Question.id,
            question: curr.Question.description,
            options: dataSchema.options,
            type: dataSchema.type || "userDefined",
            answer: answerString,
            valueType: curr.Question?.Answers[0]?.valueType,
            tooltip: getTooltipText?.[curr.Question.id],
          };
        });
      };

      let sectionId = 1; // need to call this api and call from api not statically
      if (userRole == "client") {
        API_CALL.sendRequest(
          replaceId(CONSTANT.API.GLOBALFORMS.get, sectionId),
          (res) => {
            let globalQuestions = parser(res?.data?.GlobalSectionQuestions);
            setQuestions(globalQuestions);
            setLoading(false);
          }
        );
      } else if (userRole == "admin") {
        API_CALL.sendRequest(
          replaceFormIdandClientId(
            CONSTANT.API.GLOBALFORMS.get2,
            sectionId,
            id
          ),
          (res) => {
            let globalQuestions = parser(res?.data?.GlobalSectionQuestions);
            setQuestions(globalQuestions);
            setLoading(false);
          }
        );
      } else if (userRole == "spoc") {
        API_CALL.sendRequest(
          replaceFormIdandClientId(
            CONSTANT.API.GLOBALFORMS.get,
            sectionId,
            id
          ),
          (res) => {
            let globalQuestions = parser(res?.data[0]?.GlobalSection?.GlobalSectionQuestions);
            setQuestions(globalQuestions);
            setLoading(false);
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleSubmit = (data = {}) => {
    try {
      const formatObject = (obj) => JSON.stringify(obj).replace(/"/g, '\\"');
      const transformData = (data) => {
        const transformedAnswers = Object.entries(data).reduce(
          (acc, [key, value]) => {
            const question = questions.find((q) => q.id === Number(key));
            let formattedValue;
            if (
              question &&
              (question.type === "fixedtable" ||
                (question.type === "dynamictable" &&
                  question.transformedAnswer))
            ) {
              formattedValue = JSON.stringify(
                question.transformedAnswer
              ).replace(/"/g, '\\"');
            } else {
              formattedValue =
                typeof value === "object"
                  ? formatObject([{ id: value.key }])
                  : value;
            }

            // Group address fields together
            if (key === "7" || key === "8") {
              acc.push({
                qid: Number(key),
                value: JSON.stringify({
                  addressLine: value.addressLine1,
                  city: value.city,
                  district: value.district,
                  pincode: value.pincode,
                  address: `${value.addressLine1},${value.city},${value.district},${value.pincode}`,
                }).replace(/"/g, '\\"'),
              });
            }else if (key === "14") {
              acc.push({
                qid: Number(key),
                value: JSON.stringify(value),
              });
            } else if (key === "16") {
              acc.push({
                qid: Number(key),
                value: JSON.stringify({
                  name: value.name,
                  phone: value.phone,
                  email: value.email,
                }),
              });
            } else {
              acc.push({
                qid: Number(key),
                value: formattedValue,
              });
            }

            return acc;
          },
          []
        );
        transformedAnswers.pop();
        return { answers: transformedAnswers };
      };

      if (userRole === "client") {
        API_CALL.sendRequest(
          CONSTANT.API.GLOBALFORMS.create,
          null,
          transformData(data),
          "Your data added successfully"
        );
      } else if (userRole === "admin") {
        API_CALL.sendRequest(
          replaceId(CONSTANT.API.GLOBALFORMS.create2, id),
          null,
          transformData(data),
          "Your data added successfully"
        );
      } else if (userRole === "spoc") {
        const clientId = localStorage.getItem("client");
        API_CALL.sendRequest(
          replaceId(CONSTANT.API.GLOBALFORMS.create2, clientId),
          null,
          transformData(data),
          "Your data added successfully"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      const registeredAddress = form.getFieldValue(7);
      form.setFieldsValue({ 8: registeredAddress });
    }
  };

  const handleSelectChange = (value, questionId) => {
      form.setFieldsValue({ [questionId]: value });
  };

  return (
    <div style={{ marginTop: 100 }}>
      <BrsrFormHeading
        title="General disclosures"
        explanation="The entity's general disclosures serve as a helpful reference for its investors and data users. They provide an understanding of the entity's business activities, sector, and their connection to environmental issues and corporate strategy."
      />
      <p style={{ fontStyle: "italic", marginBottom: "16px" }}>
        Note: Answers marked with ✨ are recommended by our AI. You can edit
        them if needed.
      </p>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <Spinner style={{ width: "4rem", height: "4rem" }} color="primary" />
        </div>
      ) : (
        <Form layout="vertical" onFinish={handleSubmit} form={form}>
          {questions.map((ques, index) => (
            <React.Fragment key={ques.id}>
              {ques.id === 7 && (
                <Row gutter={16} key={ques.id}>
                  <Col span={24}>
                    <Form.Item
                      label={
                        <span style={{ fontSize: 16, fontWeight: "bold" }}>
                          {`${index + 1}. ${ques.question}`}
                        </span>
                      }
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            name={[ques.id, "addressLine1"]}
                            label="Address Line 1"
                            initialValue={ques.answer?.addressLine}
                            rules={[
                              {
                                required: true,
                                message: "Address Line 1 is required",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name={[ques.id, "city"]}
                            label="City"
                            initialValue={ques.answer?.city}
                            rules={[
                              { required: true, message: "City is required" },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            name={[ques.id, "district"]}
                            label="District"
                            initialValue={ques.answer?.district}
                            rules={[
                              {
                                required: true,
                                message: "District is required",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name={[ques.id, "pincode"]}
                            label="Pincode"
                            initialValue={ques.answer?.pincode}
                            rules={[
                              {
                                required: true,
                                message: "Pincode is required",
                              },
                            ]}
                          >
                            <InputNumber style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col>
                </Row>
              )}
              {ques.id === 7 && (
                <Form.Item name="sameAsRegistered">
                  <Checkbox onChange={handleCheckboxChange}>
                    Registered address is the same as corporate address
                  </Checkbox>
                </Form.Item>
              )}
              {ques.id === 8 && (
                <Row gutter={16} key={ques.id}>
                  <Col span={24}>
                    <Form.Item
                      label={
                        <span style={{ fontSize: 16, fontWeight: "bold" }}>
                          {`${index + 1}. ${ques.question}`}
                        </span>
                      }
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            name={[ques.id, "addressLine1"]}
                            label="Address Line 1"
                            initialValue={ques.answer?.addressLine}
                            rules={[
                              {
                                required: true,
                                message: "Address Line 1 is required",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name={[ques.id, "city"]}
                            label="City"
                            initialValue={ques.answer?.city}
                            rules={[
                              { required: true, message: "City is required" },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            name={[ques.id, "district"]}
                            label="District"
                            initialValue={ques.answer?.district}
                            rules={[
                              {
                                required: true,
                                message: "District is required",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name={[ques.id, "pincode"]}
                            label="Pincode"
                            initialValue={ques.answer?.pincode}
                            rules={[
                              {
                                required: true,
                                message: "Pincode is required",
                              },
                            ]}
                          >
                            <InputNumber style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col>
                </Row>
              )}
              {ques.id === 14 && (
                <Form.Item
                  label={
                    <span style={{ fontSize: 16, fontWeight: "bold" }}>
                      {`${index + 1}. ${ques.question}`}
                    </span>
                  }
                  name={ques.id}
                  initialValue={ques.answer}
                >
                  <Select
                    mode="multiple"
                    options={ques.options.map(option => ({
                      value: option.id,
                      label: option.label
                    }))}
                    onChange={(value) => handleSelectChange(value, ques.id)}
                  />
                </Form.Item>
              )}
              {ques.id == 16 && (
                <Row gutter={16} key={ques.id}>
                  <Col span={24}>
                    <Form.Item
                      label={
                        <span style={{ fontWeight: "bold" }}>
                          {`${index + 1}. ${ques.question}`}
                        </span>
                      }
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            name={[ques.id, "name"]}
                            label="Name"
                            initialValue={ques.answer?.name}
                            rules={[
                              { required: true, message: "Name is required" },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name={[ques.id, "phone"]}
                            label="Telephone"
                            initialValue={ques.answer?.phone}
                            rules={[
                              {
                                required: true,
                                message: "Telephone number is required",
                              },
                            ]}
                          >
                            <PhoneInput
                              country={"in"}
                              placeholder="Enter telephone number"
                              inputStyle={{ width: "100%" }}
                              containerStyle={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            name={[ques.id, "email"]}
                            label="Email"
                            initialValue={ques.answer?.email}
                            rules={[
                              { required: true, message: "Email is required" },
                              {
                                type: "email",
                                message: "Enter a valid email address",
                              },
                            ]}
                          >
                            <Input placeholder="Enter email" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col>
                </Row>
              )}
              {ques.id !== 7 && ques.id !== 8 && ques.id !==14 && ques.id !== 16 && (
                <Questions key={ques.id} ques={ques} index={index} />
              )}
            </React.Fragment>
          ))}
          <Button
            type="primary"
            htmlType="submit"
            style={{
              position: "fixed",
              bottom: "30px",
              backgroundColor: "#060DBF",
              color: "#62C941",
              right: "74px",
              zIndex: 1000,
              height: "40px",
              cursor: "pointer",
            }}
          >
            Save changes
          </Button>
        </Form>
      )}
    </div>
  );
};

export default GeneraDisclousers;
