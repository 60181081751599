import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, CardTitle, Label } from "reactstrap";
import Select from "react-select";
import CONSTANT, { MonthName } from "../Utility/constnt";
import useHttp from "../../components/Hook/Use-http";
import HomeChart1 from "../../components/Custome/Charts/HomeChart1";
import { useSelector } from "react-redux";
import { analysisFilter, cardAnalyticsData } from "../Utility/option";
import CardGen from "../../components/dashboard/cardGen";
import TableGen from "../../components/dashboard/tableGen";
import {
  dashboardAnalysis,
  dashboardAnalysisNew,
  emissionDashboardDataLoad,
} from "../Utility/spread-function";
import EmissionGen from "../../components/dashboard/emissionGen";
import { useLocation } from "react-router-dom";
import authStorage from "../Utility/API/authStroge";

if (
  window.location?.pathname?.toLocaleLowerCase()?.split("/")?.[1] !==
    "siteviewer" &&
  localStorage.getItem("userRole") != "siteViewer"
) {
  cardAnalyticsData.push({
    icon: "bx bxs-factory",
    name: "Sites added",
    count: 120,
    value: "Loading...",
  });
}

let fiscalyear = "";
const today = new Date();

if (today.getMonth() + 1 <= 3) {
  fiscalyear = today.getFullYear() - 1;
} else {
  fiscalyear = today.getFullYear();
}

const CardData = {
  admin: cardAnalyticsData,
  client: cardAnalyticsData,
  siteViewer: cardAnalyticsData,
};

const Report = () => {
  // const [analysisData, setAnalysisData] = useState({});
  const [transporterList, setTransporterList] = useState([]);
  const [tripList, setTripList] = useState([]);
  const myData = useSelector((state) => state.userGetMeData);
  const userRole = localStorage.getItem("userRole")?.toLocaleLowerCase();
  const isLogin =
    authStorage?.getAuthToken() !== undefined &&
    authStorage?.getAuthToken() !== null;
  const location = useLocation();
  const currentPath = location.pathname?.toLocaleLowerCase();
  const API_CALL = useHttp();
  const [financialYear, setFinancialYear] = useState(
    `${fiscalyear}-${(fiscalyear + 1).toString().substring(2)}`
  );
  const [financialYearsList, setFinancialYearsList] = useState([]);

  useEffect(() => {
    if (!isLogin || !userRole) return;
    let analyticsAPIendpoint = { ...CONSTANT.API.DASHBOARD.getAnalysis };
    if (financialYear != null) {
      analyticsAPIendpoint.endpoint = `${analyticsAPIendpoint.endpoint}?value=${financialYear}`;
    }
    API_CALL.sendRequest(analyticsAPIendpoint, analysisDataHandler);
  }, [financialYear]);

  const analysisDataHandler = (res) => {
    // For Admin
    dashboardAnalysisNew(res, CardData, setTransporterList, setTripList);
  };

  useEffect(() => {
    const getRecentFiscalYearsList = () => {
      const currentYear = new Date().getFullYear();
      const fiscalyears = [];
      for (let i = 0; i < 5; i++) {
        const startYear = currentYear - i;
        const endYear = startYear + 1;
        fiscalyears.push({
          label: `${startYear}-${endYear.toString().substring(2)}`,
          value: `${startYear}-${endYear.toString().substring(2)}`,
        });
      }
      return fiscalyears;
    };
    setFinancialYearsList(getRecentFiscalYearsList());
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 ">Dashboard</h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Welcome to Fitsol dashboard
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </Row>
        <Row className="d-flex justify-content-between">
          <p className="page-title mb-3 font-size-18 mt-4">
            Snapshot (FY {financialYear})
          </p>
          <Label className="d-flex align-items-center">Financial year</Label>
          <Col xs={12} md={4}>
            <div
              // style={{
              //   width: "35%",
              // }}
            >
              <Select
                className="mb-3"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    cursor: "pointer",
                  }),
                }}
                options={financialYearsList}
                required={true}
                maxMenuHeight={200}
                value={{ value: financialYear, label: financialYear }}
                onChange={(selected) => {
                  setFinancialYear(selected?.value);
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <CardGen
            CardData={CardData?.[currentPath?.split("/")?.[1]]}
            fiscalyear={financialYear}
          />
        </Row>
        <TableGen
          transporterList={transporterList}
          myData={myData}
          tripList={tripList}
          siteWiseList={true}
        />
      </div>
    </React.Fragment>
  );
};

export default Report;
