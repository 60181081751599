import { Col, Row, Tabs } from "antd";
import { BRSRTabsList } from "../Utility/constnt";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ScoreTab from "./tabs/ScoreTab";
import ScoreTabNew from "./tabs/ScoreTabNew";
import FormsTab from "./tabs/FormsTab";
import SPOCsTab from "./tabs/SPOCsTab";

const BrsrPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location?.pathname;
  const searchQuery = location?.search;
  const [tabIndex, setTabIndex] = useState("1");

  // To switch tab based upon search params
  useEffect(() => {
    if (!searchQuery.includes("?tab")) return;
    let tab = searchQuery.split("=")[1];

    switch (tab) {
      case "score":
        setTabIndex("1");
        break;
      case "forms":
        setTabIndex("2");
        break;
      case "spocs":
        setTabIndex("3");
        break;
      default:
        setTabIndex("1");
    }
  }, [searchQuery]);

  const onChange = (key) => {
    switch (key) {
      case "1":
        setTabIndex(key);
        navigate(`${currentPath}?tab=score`);
        break;

      case "2":
        setTabIndex(key);
        navigate(`${currentPath}?tab=forms`);
        break;

      case "3":
        setTabIndex(key);
        navigate(`${currentPath}?tab=spocs`);
        break;
    }
  };

  const tabSelected = () => {
    switch (tabIndex) {
      case "1":
        return <ScoreTabNew />;
      case "2":
        return <FormsTab />;
      case "3":
        return <SPOCsTab />;

      default:
        return <FormsTab />;
    }
  };

  return (
    <div className="brsr-page">
      <Row>
        <Col xs={24}>
          <p className="brsr-heading-text">
            Business Responsibility & Sustainability Reporting (BRSR)
          </p>
        </Col>
      </Row>
      <Row className="w-full">
        <Tabs
          className="pcf-tabs w-full"
          activeKey={`${tabIndex}`}
          items={BRSRTabsList}
          onChange={onChange}
        />
      </Row>
      <Row>{tabSelected()}</Row>
    </div>
  );
};

export default BrsrPage;
