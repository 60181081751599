import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  ADD_CUSTOMER_FAILURE,
  ADD_CUSTOMER_START,
  ADD_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_START,
  GET_CUSTOMER_FAILURE,
  GET_CUSTOMER_START,
  GET_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_START,
} from "./actionTypes";
import Apicall from "../../components/Hook/Api-call";
import CONSTANT from "../../pages/Utility/constnt";
import {
  addCustomerError,
  addCustomerSucess,
  deleteCustomerError,
  deleteCustomerSucess,
  getCustomerError,
  getCustomerSuccess,
  updateCustomerError,
  updateCustomerSucess,
} from "./actions";
import { replaceEndPointId } from "../../pages/Utility/function";

function* getCustomer() {
  try {
    // let getAPI = "";
    // if (localStorage.getItem("userRole") == "admin") {
    //   getAPI = CONSTANT?.API.CLIENT?.getClient?.endpoint;
    // } else {
    //   getAPI = CONSTANT?.API.CLIENT?.getMeClient?.endpoint;
    // }
    const res = yield Apicall?.get(CONSTANT.API.CLIENT.get.endpoint);
    // const res = "aaaaaaaabb";

    // yield put({ type: GET_CUSTOMER_SUCCESS, data: res });
    yield put(getCustomerSuccess(res));

    // debugger;
  } catch (error) {
    console.log(error);
    yield put(getCustomerError(error?.message));

    // yield put({ type: GET_CUSTOMER_FAILURE, error });
  }
}

function* addCustomerSaga(user) {
  try {
    // action.payload should contain the updated CUSTOMER data
    const response = yield Apicall?.post(
      CONSTANT?.API?.CLIENT?.create?.endpoint,
      user?.payload
    );
    if (response?.status == "success") {
      if (user?.successCallback) {
        user?.successCallback();
      }
    }

    // const response = yield call(axios.put, `/api/items/${action.payload.id}`, action.payload);
    yield put(addCustomerSucess(response?.data));
    // { type: ADD_CUSTOMER_SUCCESS, data: response.data });
  } catch (error) {
    yield put(addCustomerError(error?.message));

    // yield put({ type: ADD_CUSTOMER_FAILURE, error });
  }
}

function* deleteCustomerSaga(user) {
  try {
    const response = yield Apicall?.remove(
      replaceEndPointId(CONSTANT?.API?.CLIENT?.delete?.endpoint, user?.payload)
    );
    if (response?.status == "success") {
      if (user?.successCallback) {
        user?.successCallback();
      }
    }
    yield put(deleteCustomerSucess(user));
    // { type: ADD_CUSTOMER_SUCCESS, data: response.data });
  } catch (error) {
    console.log(error);
    console.log(error?.message, "responce");
    yield put(deleteCustomerError(error?.message));
    // yield put({ type: ADD_CUSTOMER_FAILURE, error });
  }
}

function* updateCustomerSaga(user) {
  try {
    const response = yield Apicall?.patch(
      replaceEndPointId(CONSTANT?.API?.CLIENT?.update?.endpoint, user?.id),
      user?.payload
    );
    if (response?.status == "success") {
      if (user?.successCallback) {
        user?.successCallback();
      }
    }

    yield put(updateCustomerSucess(user));
    // { type: ADD_CUSTOMER_SUCCESS, data: response.data });
  } catch (error) {
    console.log(error, "responce");
    yield put(updateCustomerError(error?.message));

    // yield put({ type: ADD_CUSTOMER_FAILURE, error });
  }
}

function* GetCustomerDetailSaga() {
  yield takeLatest(GET_CUSTOMER_START, getCustomer);
  yield takeLatest(ADD_CUSTOMER_START, addCustomerSaga);
  yield takeLatest(UPDATE_CUSTOMER_START, updateCustomerSaga);
  yield takeLatest(DELETE_CUSTOMER_START, deleteCustomerSaga);
}

export default GetCustomerDetailSaga;
