/* USERS */
// export const GET_USERS = "GET_USERS";
// export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
// export const GET_USERS_FAIL = "GET_USERS_FAIL";

// /* USERS PROFILE */
// export const GET_USER_PROFILE = "GET_USER_PROFILE";
// export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
// export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL";

// export const GET_DRIVER_DETAIL_SUCCESS = "GET_DRIVER_DETAIL_SUCCESS";
// export const GET_MY_PROFILE = "GET_MY_PROFILE";

export const PLANT_DETAIL = "PLANT_DETAIL";
export const SET_PLANT_DETAIL = "SET_PLANT_DETAIL";

export const DELETE_PLANT_START = "DELETE_PLANT_START";
export const DELETE_PLANT_SUCCESS = "DELETE_PLANT_SUCCESS";
export const DELETE_PLANT_FAILURE = "DELETE_PLANT_FAILURE";

export const GET_PLANT_START = "GET_PLANT_START";
export const GET_PLANT_SUCCESS = "GET_PLANT_SUCCESS";
export const GET_PLANT_FAILURE = "GET_PLANT_FAILURE";
