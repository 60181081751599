/* USERS */
// export const GET_USERS = "GET_USERS";
// export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
// export const GET_USERS_FAIL = "GET_USERS_FAIL";

// /* USERS PROFILE */
// export const GET_USER_PROFILE = "GET_USER_PROFILE";
// export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
// export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL";

// export const GET_DRIVER_DETAIL_SUCCESS = "GET_DRIVER_DETAIL_SUCCESS";
// export const GET_MY_PROFILE = "GET_MY_PROFILE";

export const VEHICLE_DETAIL = "VEHICLE_DETAIL";
export const SET_VEHICLE_DETAIL = "SET_VEHICLE_DETAIL";

export const DELETE_VEHICLE_START = "DELETE_VEHICLE_START";
export const DELETE_VEHICLE_SUCCESS = "DELETE_VEHICLE_SUCCESS";
export const DELETE_VEHICLE_FAILURE = "DELETE_VEHICLE_FAILURE";

export const GET_VEHICLE_START = "GET_VEHICLE_START";
export const GET_VEHICLE_SUCCESS = "GET_VEHICLE_SUCCESS";
export const GET_VEHICLE_FAILURE = "GET_VEHICLE_FAILURE";
