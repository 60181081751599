import { delay, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  DELETE_ADMIN_DASHBOARD_START,
  GET_ADMIN_DASHBOARD_START,
} from "./actionTypes";
import Apicall from "../../components/Hook/Api-call";
import CONSTANT from "../../pages/Utility/constnt";
import {
  deleteAdminDashboardError,
  deleteAdminDashboardSucess,
  getAdminDashboardError,
  getAdminDashboardSuccess,
} from "./actions";
import { replaceEndPointId } from "../../pages/Utility/function";

function* getAdminDashboard() {
  try {
    const res = yield Apicall?.get(CONSTANT.API.PACKAGEING.get.endpoint);
    yield put(getAdminDashboardSuccess(res));
  } catch (error) {
    console.log(error);
    yield put(getAdminDashboardError(error?.message));
  }
}

function* deleteAdminDashboardSaga(user) {
  try {
    const response = yield Apicall?.remove(
      replaceEndPointId(
        CONSTANT?.API?.PACKAGEING?.delete?.endpoint,
        user?.payload
      )
    );
    if (response?.status == "success") {
      if (user?.successCallback) {
        user?.successCallback();
      }
      yield put(deleteAdminDashboardSucess(user));
    }
  } catch (error) {
    console.log(error?.message, "responce");
    yield put(deleteAdminDashboardError(error?.message));
  }
}

function* GetAdminDashboardDetailSaga() {
  yield takeEvery(GET_ADMIN_DASHBOARD_START, getAdminDashboard);
  yield takeEvery(DELETE_ADMIN_DASHBOARD_START, deleteAdminDashboardSaga);
}

export default GetAdminDashboardDetailSaga;
