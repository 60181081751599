import { Form } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import CONSTANT from "../../../pages/Utility/constnt";
import { getFormFiled, replaceId, optionArrayWithSort } from "../../../pages/Utility/function";
import {
  emissionEntryCategoryWiseDataLoad,
  emissionForFormDataCallLoad,
} from "../../../pages/Utility/spread-function";
import AntdForm from "../../Custome/antdForm";

const BusinessTravel = (props) => {
  const {
    setFinancialYear,
    setInputDataForEmission,
    findEmissionData,
    subCategoryAttribute,
    extraValue,
    category,
    setCategory,
    setCarbonEmission,
    setSubCategoryAttribute,
    API_CALL,
    setSuccessPreviewdata,
    findBulkEmissionData,
  } = props;

  const [dynamicOption, setDynamicOption] = useState({});
  const [alldynamicOption, setAllDynamicOption] = useState({});
  const [clearBtn, setClearBtn] = useState(() => {});
  const [formData, setFormData] = useState([]);
  const [defaultData, setDefaultData] = useState({});
  const [newFormData, setNewFormData] = useState({});
  const [cleanData, setCleanData] = useState(true);
  const [airports, setAirports] = useState({});
  const [form] = Form.useForm();
  const { id } = useParams();
  const userRole = localStorage.getItem("userRole");
  const location = useLocation();
  const currentPath = location.pathname;

  const change = {};
  useEffect(() => {
    const api = {
      ...replaceId(
        CONSTANT.API.SUBCATEGORYATTRIBUTE.getSingleFilterSubCategoryAttribute,
        category
      ),
    };

    setCleanData(true);

    api.endpoint += "&definationType=userDefined";
    category != null &&
      API_CALL.getAll(
        [
          api?.endpoint,
          CONSTANT?.API?.ATTRIBUTEOPTION.get?.endpoint,
          CONSTANT?.API.SITE.get?.endpoint,
          CONSTANT?.API.JOB.getEmployeeLabelCommute?.endpoint,
          //   CONSTANT?.API.JOB.getGasUsed?.endpoint,
        ],
        (res) => {
          // setSubCategoryAttribute(res?.data);
          let categoryOption = {};
          const subCategoryAttributeRes = res[0]?.data;
          const subCategoryAttributeOptionRes = res[1]?.data?.data;
          const sites =
            userRole == "admin" && currentPath.includes("/client") && id
              ? res[2]?.data?.data?.rows?.filter((el) => el?.clientId == id)
              : res[2]?.data?.data?.rows;
          const Other = {
            site: sites,
            employeeMode: res[3]?.data?.data?.rows,
          };

          //   setFuelTypeData(res[3]?.data?.data?.rows);

          const rearrangedData = subCategoryAttributeRes?.data?.rows?.sort(
            (a, b) => +a.index - +b.index
          );
          const dddd = emissionEntryCategoryWiseDataLoad(
            subCategoryAttributeOptionRes,
            Other,
            rearrangedData,
            setDynamicOption,
            null,
            formData
          );

          setAllDynamicOption((prev) => ({
            ...prev,
            ...categoryOption,
          }));

          let pr = getFormFiled(
            "Travel",
            `${"Travel details"}`,
            "",
            "multifield"
          );
          dddd.arr?.map((ell) => {
            if (ell?.type == "SingleSelect") {
              ell.type = "select";
            }
          });
          const listFilter = dddd?.arr?.filter(
            (el) => el?.extraValue?.getShowlistAntd == true
          );
          dddd.arr = dddd?.arr?.filter(
            (el) => !(el?.extraValue?.getShowlistAntd == true)
          );

          pr["fields"] = [...listFilter];
          pr["addName"] = "+ Add more Route";
          pr["initialValue"] = [{}];

          pr = [pr];

          setSubCategoryAttribute([
            ...CONSTANT.FORM_FIELDS.ADDSINGLEEMISSIONENTRY,
            ...dddd?.arr,
            ...pr,
          ]);
          setCleanData(false);
        }
      );
  }, [category]);

  useEffect(() => {
    emissionForFormDataCallLoad(
      subCategoryAttribute,
      setDynamicOption,
      setSubCategoryAttribute,
      setFinancialYear,
      formData,
      alldynamicOption
      //   form?.getFieldsValue()
    );
  }, []);

  useEffect(() => {
    (formData?.data || formData) &&
      Object?.values(formData?.data || formData)
        ?.map((el) => (typeof el === "string" ? el?.split("-") : []))
        ?.map((yy) => {
          if (yy?.[0]?.length == 4 && yy?.[1]?.length == 2) {
            if (yy?.[2]?.length) {
              const lastFind = yy[0].substring(yy[0].length - 2);

              setFinancialYear(
                `${yy?.[0]}-${(parseInt(lastFind) + 1)?.toString()}`
              );
            } else {
              setFinancialYear(yy?.join("-"));
            }
          }
        });
  }, [formData]);

  useEffect(() => {
    if (formData.length<=0) return;
    const arr = [];
    const routeIndex = subCategoryAttribute.findIndex(el => el?.id == "Travel");
    const keyOnly = subCategoryAttribute?.[routeIndex]?.fields?.filter(
      (el) => el?.extraValue?.depenededFieldId
    );
    keyOnly?.map((el) => arr?.push(el?.extraValue?.depenededFieldId));
    const newFormSplit = Object?.keys(newFormData)?.[0]?.split(",");
    const keyFindId = newFormSplit?.slice(-1);
    // const optionlistCheck = Object.keys(formData)?.find(
    //   (el) => el == keyFindId
    // );
    if(keyFindId?.[0] == arr?.[0] && newFormSplit.length === 3){
      const shipmentOriginIndex = subCategoryAttribute[routeIndex]?.fields?.findIndex(el => el?.id == keyOnly?.[0]?.id);
      const shipmentDestinationIndex = subCategoryAttribute[routeIndex]?.fields?.findIndex(el => el?.id == keyOnly?.[1]?.id);
      const newFormDataKey = Object?.keys(newFormData)?.[0];
      if(!Array.isArray(subCategoryAttribute[routeIndex].fields[shipmentOriginIndex].type)){
        subCategoryAttribute[routeIndex].fields[shipmentOriginIndex].type = []
        subCategoryAttribute[routeIndex].fields[shipmentDestinationIndex].type = []
      }
      while(newFormSplit[1]>subCategoryAttribute[routeIndex].fields[shipmentOriginIndex].type.length){
        subCategoryAttribute[routeIndex].fields[shipmentOriginIndex].type.push("text")
        subCategoryAttribute[routeIndex].fields[shipmentDestinationIndex].type.push("text")
      }
      if (newFormData?.[newFormDataKey] == "Aircraft"){
        if(subCategoryAttribute[routeIndex].fields[shipmentOriginIndex].type?.[newFormSplit[1]]){
          subCategoryAttribute[routeIndex].fields[shipmentOriginIndex].type[newFormSplit[1]] = "select"
          subCategoryAttribute[routeIndex].fields[shipmentDestinationIndex].type[newFormSplit[1]] = "select"
        }
        else{
          subCategoryAttribute[routeIndex].fields[shipmentOriginIndex].type.push("select")
          subCategoryAttribute[routeIndex].fields[shipmentDestinationIndex].type.push("select")
        }
        if(Object.keys(airports).length === 0){
          API_CALL.getAll(
            [CONSTANT.API.JOB?.getAirports?.endpoint],
            (res) => {
              setDynamicOption((prev) => {
                return {
                  ...prev,
                  [keyOnly?.[0]?.id]: optionArrayWithSort(
                    res[0]?.data?.data,
                    "name",
                    "name"
                  ),
                  [keyOnly?.[1]?.id]: optionArrayWithSort(
                    res[0]?.data?.data,
                    "name",
                    "name"
                  ),
                }
              })
              setAirports(res[0]);
            } 
          )
        }else{
          setDynamicOption((prev) => {
            return {
              ...prev,
              [keyOnly?.[0]?.id]: optionArrayWithSort(
                airports?.data?.data,
                "name",
                "name"
              ),
              [keyOnly?.[1]?.id]: optionArrayWithSort(
                airports?.data?.data,
                "name",
                "name"
              ),
            }
          })
        }
        
      }
      else{
        if(subCategoryAttribute[routeIndex].fields[shipmentOriginIndex].type?.[newFormSplit[1]]){
          subCategoryAttribute[routeIndex].fields[shipmentOriginIndex].type[newFormSplit[1]] = "GoogleAutoComplete"
          subCategoryAttribute[routeIndex].fields[shipmentDestinationIndex].type[newFormSplit[1]] = "GoogleAutoComplete"
        }
        else{
          subCategoryAttribute[routeIndex].fields[shipmentOriginIndex].type.push("GoogleAutoComplete")
          subCategoryAttribute[routeIndex].fields[shipmentDestinationIndex].type.push("GoogleAutoComplete")
        }
      }
      const route = form.getFieldValue("Travel");
      route[newFormSplit[1]][keyOnly?.[0]?.id] = undefined;
      route[newFormSplit[1]][keyOnly?.[1]?.id] = undefined;
      form.setFieldValue("Travel",route);
      const originKey =`${newFormSplit[0]},${newFormSplit[1]},${keyOnly?.[0]?.id}`
      const destKey =`${newFormSplit[0]},${newFormSplit[1]},${keyOnly?.[1]?.id}`
      delete formData?.[originKey]; 
      delete formData?.[destKey];
      
    }
    setNewFormData({});
    
  },[formData])

  const onSubmit = (value, clear) => {
    let newArr = [];

    const daysId = subCategoryAttribute
      ?.find((el) => el?.type == "multifield")
      ?.fields?.find((el) => el?.type == "date")?.id;

    value?.Travel?.map((rl) => {
      rl?.[daysId]?.format("DD-MM-YYYY");
      const fff = { ...value };
      delete fff?.["Travel"];

      let payload = {};
      payload.createFlag = false;
      payload.subCategoryId = category;
      if (localStorage.getItem("userRole") == "admin" && id) {
        payload.clientId = id;
      }
      // delete e?.category;

      payload.data = {
        ...rl,
        ...fff,
        [daysId]: rl?.[daysId]?.format("DD-MM-YYYY"),
      };

      Object.keys(payload.data)?.map((el) => {
        payload.data[`id${el}`] = payload.data[el];
      });
      newArr?.push(payload);
    });

    findBulkEmissionData(newArr);

    setInputDataForEmission(newArr);
  };

  return (
    <>
      <AntdForm
        formFields={subCategoryAttribute}
        form={form}
        dynamicOption={dynamicOption}
        changedFields={(key, value) => {
          const ff = form?.getFieldsValue();
          if(value?.value){
            value = value.value;
          }
          setNewFormData({[key]: value});
          setFormData((prev) => ({ ...prev, [key]: value }));
        }}
        submitName={"Calculate Emissions"}
        onSubmit={onSubmit}
        modalType={"formField"}
      />
    </>
  );
};

export default BusinessTravel;
