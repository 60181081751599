import React from "react";
import { Navigate, useResolvedPath } from "react-router-dom";
import NonAuthLayout from "./NonAuthLayout";
import authStorage from "../pages/Utility/API/authStroge";

const isBool = {
  true: true,
  false: false,
  1: true,
  0: false,
};

const Authorize = () => {
  const path = useResolvedPath();
  if (isBool[process.env.REACT_APP_MAINTENANCE]) {
    return (
      <Navigate
        to={{
          pathname: "/pages-maintenance",
          state: { from: path },
        }}
      />
    );
  } else {
    if (
      !(
        authStorage?.getAuthToken() != undefined &&
        authStorage?.getAuthToken() != null
      )
      // !localStorage.getItem("userRole")
    ) {
      return (
        <Navigate
          to={{
            pathname: "/login",
            state: { from: path },
          }}
        />
      );
    }
  }

  return <NonAuthLayout>{/* <Component {...props} /> */}</NonAuthLayout>;
};

export default Authorize;
