import React, { useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import useHttp from "../Hook/Use-http";
import {
  menuGen,
  notifications,
  replaceId,
} from "../../pages/Utility/function";
import CONSTANT, { MyData } from "../../pages/Utility/constnt";
import {
  getCustomerStart,
  getJobBulkStart,
  getPackagingStart,
  getPlantStart,
  getRoleAndAccessStart,
  getRouteStart,
  getShipmentStart,
  getUsersDetailSuccess,
} from "../../store/actions";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import useCheckInternet from "../Hook/use-checkInternet";
import HorizontalLayout from "../HorizontalLayout";
import authStorage from "../../pages/Utility/API/authStroge";
import { ROUTES } from "../../pages/Utility/Route";

const ClientLayout = () => {
  useCheckInternet("/noInternet");

  const API_CALL = useHttp();
  const { id } = useParams();
  const dispatch = useDispatch();
  // const myData = useSelector((state) => state.userGetMeData);
  // const ref = useRef();

  let userRole = localStorage.getItem("userRole");

  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const getClientData = useSelector((state) => state.customerDetailData);
  const isLogin =
    authStorage?.getAuthToken() !== undefined &&
    authStorage?.getAuthToken() !== null;
  useEffect(() => {
    if (!isLogin || !userRole) {
      notifications.error("Session Expired!");
      return navigate("/login");
    }
    (async () => {
      if (!currentPath.includes("data-not-found")) {
        // if (userRole == "siteViewer") return navigate(ROUTES.PAGES403);

        if (userRole === "client") {
          if (!currentPath.includes("client")) navigate(ROUTES.PAGES403);
          API_CALL.sendRequest(
            CONSTANT.API.ADMIN.getMe,
            getMeClcintDataHandler,
            null,
            null,
            (err) => {}
          );
        }
        userRole == "admin" && id && startReduceData();
      }
    })();
  }, []);

  useEffect(() => {
    if (getClientData?.users?.data?.rows) {
      if (userRole == "admin" && id) {
        dispatch(
          getUsersDetailSuccess(
            getClientData?.users?.data?.rows?.find((el) => el?.id == id)
          )
        );
      }
    }
  }, [getClientData?.users?.data?.rows]);

  const startReduceData = () => {
    if (userRole == "admin" && id) userRole = "clientViewRole";
    switch (userRole) {
      case "client":
        dispatch(getRoleAndAccessStart());
        dispatch(getShipmentStart());
        dispatch(getJobBulkStart());
        dispatch(getPlantStart());

        break;

      case "clientViewRole":
        dispatch(getCustomerStart());
        dispatch(
          getRoleAndAccessStart(
            replaceId(CONSTANT.API.ROLEANDACCESS.getClientRoleAndAccess, id)
              ?.endpoint
          )
        );
        dispatch(
          getShipmentStart(
            replaceId(CONSTANT.API.SHIPMENT.getSingleFilterTripClient, id)
              ?.endpoint
          )
        );
        dispatch(
          getJobBulkStart(
            replaceId(CONSTANT.API.JOB.getClientJob, id)?.endpoint
          )
        );
        dispatch(
          getPlantStart(
            replaceId(CONSTANT.API.SITE.getClientPlant, id)?.endpoint
          )
        );
        dispatch(
          getRouteStart(
            replaceId(CONSTANT.API.ROUTE.getClientRoute, id)?.endpoint
          )
        );

        break;

      default:
        break;
    }

    dispatch(getPackagingStart());
  };

  const getMeClcintDataHandler = (res) => {
    MyData.data = res.data[0];

    dispatch(getUsersDetailSuccess(res?.data));
    dispatch(
      getRouteStart(
        replaceId(CONSTANT.API.ROUTE.getClientRoute, res?.data?.clientId)
          ?.endpoint
      )
    );

    startReduceData();

    if (currentPath == "/client") {
      navigate(currentPath + `/${res?.data?.clientId}`);
    }
    if (currentPath == "/client/") {
      navigate(currentPath + `${res?.data?.clientId}`);
    }
    if (
      !currentPath?.includes(res?.data?.clientId) &&
      currentPath != "/client" &&
      currentPath != "/client/"
    ) {
      navigate(
        currentPath?.replace(currentPath.split("/")[2], res?.data?.clientId)
      );
    }

    if (res?.data?.client === null) {
      window.location.replace("data-not-found");
    }
  };

  return (
    <>
      {isLogin || userRole ? (
        <HorizontalLayout SideBarMenu={menuGen("client")} />
      ) : (
        <></>
      )}

      {/* <VerticalLayout SideBarMenu={menuGen("client")} /> */}
    </>
  );
};

export default ClientLayout;
