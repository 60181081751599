import React, { useEffect } from "react";
import VerticalLayout from "../VerticalLayout";
import CONSTANT, { MyData } from "../../pages/Utility/constnt";
import { useDispatch } from "react-redux";
import useHttp from "../Hook/Use-http";
import {
  getVehicleStart,
  getCustomerStart,
  getDriverStart,
  getPlantStart,
  getRouteStart,
  getShipmentStart,
  getTransporterStart,
  getUsersDetailSuccess,
  getPackagingStart,
} from "../../store/actions";
import { menuGen, notifications } from "../../pages/Utility/function";
import useCheckInternet from "../Hook/use-checkInternet";
import HorizontalLayout from "../HorizontalLayout";
import authStorage from "../../pages/Utility/API/authStroge";
import { useNavigate } from "react-router-dom";

const TripLayout = () => {
  useCheckInternet("/noInternet");
  const navigate = useNavigate();
  const API_CALL = useHttp();
  const dispatch = useDispatch();
  let userRole = localStorage.getItem("userRole");

  //Get My Data
  useEffect(() => {
    const isLogin =
      authStorage?.getAuthToken() !== undefined &&
      authStorage?.getAuthToken() !== null;
    if (!isLogin || !userRole) {
      notifications.error("Session Expired!");
      return navigate("/login");
    }
    (async () => {
      if (!window?.location?.href.includes("data-not-found")) {
        let errHandler = null;
        // if (userRole === "tripCreator") {
        API_CALL.sendRequest(
          CONSTANT.API.ADMIN.getMe,
          getMeAdminDataHandler,
          null,
          null,
          (err) => {}
        );
        // }
      }
    })();
  }, []);

  const getMeAdminDataHandler = (res) => {
    dispatch(getUsersDetailSuccess(res?.data));
    MyData.data = res?.data;
    dispatch(getVehicleStart());
    dispatch(getTransporterStart());
    dispatch(getCustomerStart());
    dispatch(getDriverStart());
    dispatch(getShipmentStart(`${userRole}/trip`));
    dispatch(getRouteStart());
    dispatch(getPlantStart());
    dispatch(getPackagingStart());
  };

  return (
    <>
      {/* <VerticalLayout /> */}
      <HorizontalLayout SideBarMenu={menuGen("tripCreator")} />
    </>
  );
};

export default TripLayout;
