import React, { useState, useEffect, useMemo } from "react";
import { Table, Button, Select } from "antd";
import moment from 'moment';
import { CSVLink } from "react-csv";
import { useParams } from "react-router-dom";
import useHttp from "../Hook/Use-http";
import './PaginatedTable.scss';
import CONSTANT from "../../pages/Utility/constnt";

const { Option } = Select;

const PaginatedTable = ({ title, excelName = "Report.csv", exportHeader }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const API_CALL = useHttp();
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [actionTypeFilter, setActionTypeFilter] = useState("all");
    const [CSVData, setCSVData] = useState([]);

    const fetchData = async (params = {}) => {
        setLoading(true);
        const paramData = {
            page: params.pagination.current,
            pageSize: params.pagination.pageSize,
            clientId: id,
            actionType: actionTypeFilter === "all" ? null : actionTypeFilter,
        };
        const endpoint = CONSTANT.API.CHANGELOGS.get.endpoint;

        const buildQueryString = (params) => {
            return Object.keys(params)
                .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
                .join("&");
        };

        const queryString = buildQueryString(paramData);
        const endpointWithParams = `${endpoint}?${queryString}`;
        const apiLog = {
            type: "GET",
            endpoint: endpointWithParams,
        };

        await API_CALL.sendRequest(apiLog, (res) => {
            setData(res?.rows);

            setPagination({
                ...params.pagination,
                total: res?.total,
            });
            setLoading(false);
            generateCSVData(res?.rows);
        });
    };

    useEffect(() => {
        fetchData({
            pagination,
        });
    }, [actionTypeFilter]);

    const handleTableChange = (pagination, filters, sorter) => {
        fetchData({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            ...filters,
        });
    };

    const columns = useMemo(
        () => [
            { title: "Action On", dataIndex: "actionType", key: "actionType" },
            {
                title: "Reference ID",
                dataIndex: "reference",
                key: "reference.id",
                render: (text, record) => {
                    if (record.reference)
                        return record.reference?.id
                    else return "N/A"
                }
            },
            { title: "Action", dataIndex: "action", key: "action" },
            {
                title: "Performed By",
                dataIndex: "performedBy",
                key: "performedBy.name",
                render: (text, record) => record.performedBy.name,
            },
            {
                title: "Email",
                dataIndex: "performedBy",
                key: "performedBy.email",
                render: (text, record) => record.performedBy.email,
            },
            {
                title: "Date",
                dataIndex: "createdAt",
                key: "createdAt.date",
                render: (text, record) => moment(record.createdAt).format('MMMM Do YYYY'),
            },
            {
                title: "Time",
                dataIndex: "createdAt",
                key: "createdAt.time",
                render: (text, record) => moment(record.createdAt).format('h:mm:ss a'),
            },
        ],
        []
    );

    function generateCSVData(rows) {
        if (!rows || rows.length === 0) return;

        const csvData = rows.map((row) => {
            return {
                actionType: row.actionType,
                reference: row.reference.id,
                action: row.action,
                performedBy: row.performedBy?.name ?? "N/A",
                email: row.performedBy?.email ?? "N/A",
                date: moment(row.createdAt).format('MMMM Do YYYY'),
                time: moment(row.createdAt).format('h:mm:ss a'),
            }
        });
        setCSVData(csvData);
    }

    const handleActionTypeChange = (value) => {
        setActionTypeFilter(value);
    };

    return (
        <div className="paginated-table-container">
            <div className="table-header">
                <h2 className="table-title">{title}</h2>
                {CSVData.length > 0 && (
                    <CSVLink data={CSVData} headers={exportHeader} filename={excelName}>
                        <Button className="outline-btn waves-effect waves-light">
                            Export
                        </Button>
                    </CSVLink>
                )}
            </div>

            <Select
                defaultValue="all"
                style={{ width: 200, marginBottom: 20 }}
                onChange={handleActionTypeChange}
            >
                <Option value="all">All Actions</Option>
                <Option value="Records">Records</Option>
                <Option value="Customer">Customer</Option>
                <Option value="Package">Package</Option>
                <Option value="Site">Site</Option>
                <Option value="Route">Route</Option>
                <Option value="Trip">Trip</Option>
                <Option value="Login">Login</Option>
            </Select>

            <Table
                columns={columns}
                rowKey={(record) => record._id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                className="custom-table"
            />
        </div>
    );
};

PaginatedTable.defaultProps = {
    title: "",
    exportHeader: [],
};

export default PaginatedTable;
