// import { TRANSPORTER_DETAIL } from "./actionTypes";

// // export const getMyProfile = () => ({
// //   type: GET_MY_PROFILE,
// // });

// export const getTransporterDetailSuccess = (users) => {
//   return {
//     type: TRANSPORTER_DETAIL,
//   };
//   // return {
//   //   type: GET_TRANSPORTER_DETAIL_SUCCESS,
//   //   payload: users,
//   // };
// };

import {
  GET_TRANSPORTER_START,
  GET_TRANSPORTER_SUCCESS,
  GET_TRANSPORTER_FAILURE,
  DELETE_TRANSPORTER_START,
  DELETE_TRANSPORTER_SUCCESS,
  DELETE_TRANSPORTER_FAILURE,
} from "./actionTypes";

//GET
export const getTransporterStart = () => {
  return {
    type: GET_TRANSPORTER_START,
  };
};
export const getTransporterSuccess = (users) => {
  return {
    type: GET_TRANSPORTER_SUCCESS,
    payload: users,
  };
};
export const getTransporterError = (error) => {
  return {
    type: GET_TRANSPORTER_FAILURE,
    payload: error,
  };
};

//DELETE
export const deleteTransporterStart = (id, successCallback) => ({
  type: DELETE_TRANSPORTER_START,
  payload: id,
  successCallback,
});

export const deleteTransporterSucess = (id) => ({
  type: DELETE_TRANSPORTER_SUCCESS,
  payload: id,
});

export const deleteTransporterError = (error) => ({
  type: DELETE_TRANSPORTER_FAILURE,
  payload: error,
});
