import React from "react";

import defaultImage from "../../assets/images/OBJECTS.png";
import { Spinner, UncontrolledTooltip } from "reactstrap";

import { formatNumber, formateToFixed } from "../../pages/Utility/function";
import { BIOGENIC_EMISSIONS_LINK } from "../../pages/Utility/constnt";

const SingleUploadRightSide = (props) => {
  const { extraValue } = props;
  let biogenicEmissions = null;
  const breakdown = extraValue?.emissionBreakdown;
  const refferlink = extraValue?.successPreviewdata?.refLink;

  if (breakdown && Array.isArray(breakdown) && breakdown?.length !== 0) {
    // extracting the biogenic emission from breakdown
    biogenicEmissions = breakdown[1].carbonEmissionData;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "70px",
      }}
    >
      <img
        src={defaultImage}
        alt=""
        className="mx-auto"
        style={{ maxHeight: "100%", maxWidth: "100%" }}
      />
      <p
        className="font-size-14"
        style={{
          textAlign: "center",
          fontWeight: "400",
        }}
      >
        {extraValue?.carbonEmission == null ? (
          "Fill in the details to calculate the emissions for your supply chain!"
        ) : (
          <>
            {/* The Shipment Emission is<br></br> */}
            <span
              className="font-size-20"
              style={{
                textAlign: "center",
                fontWeight: "700",
                color: "#000",
                padding: "0",
                margin: "0",
              }}
            >
              {+extraValue?.carbonEmission < 1000
                ? `${+formateToFixed(+extraValue?.carbonEmission)} kgCO`
                : `${+formateToFixed(+extraValue?.carbonEmission / 1000)} tCO`}
              {/* {+(+extraValue?.carbonEmission / 1000)?.toFixed(2)} tCO */}
              <sub>2</sub>e{biogenicEmissions !== null ? <>*</> : null}
              <br></br>
            </span>
            emissions generated against{" "}
            <span className="fw-bold">
              {extraValue?.parentExtraValue?.subCategory?.find(
                (el) => el?.id == extraValue?.category
              )?.name || ""}
            </span>{" "}
            under{" "}
            <span className="fw-bold">
              {" "}
              {extraValue?.successPreviewdata?.subCategory}
            </span>{" "}
            for reporting year{" "}
            <span className="fw-bold">{extraValue?.financialYear}</span>
            <>
              {" "}
              <UncontrolledTooltip
                placement="top"
                target={"icon"}
                autohide={false}
              >
                {/* Emissions are calculated as per{" "}
                <a
                  href={props?.extraValue?.successPreviewdata?.refLink || ""}
                  target="_blank"
                >
                  Link
                </a>
                . We are committed towards keeping our emission factor databases
                as per the evolving research to calculate carbon emissions as
                accurately as possible. */}
                Emissions are calculated as per the factors provided
                {Array?.isArray(extraValue?.successPreviewdata?.refLink) ? (
                  <>
                    {" "}
                    here{` (`}
                    {extraValue?.successPreviewdata?.refLink?.map(
                      (el, index, array) => (
                        <React.Fragment key={index}>
                          <a href={el || ""} target="_blank">
                            {`link ${index + 1}`}
                          </a>
                          {index < array.length - 1 && ","}
                        </React.Fragment>
                      )
                    )}
                    {`)`}
                  </>
                ) : (
                  <a
                    href={props?.extraValue?.successPreviewdata?.refLink || ""}
                    target="_blank"
                  >
                    {" "}
                    here
                  </a>
                )}
                {/* (
                <a
                  href={props?.extraValue?.successPreviewdata?.refLink || ""}
                  target="_blank"
                >
                  hyperlink here
                </a>
                ) */}
                . We are committed towards keeping our emission factor databases
                as per the evolving research to calculate carbon emissions as
                accurately as possible.
              </UncontrolledTooltip>
              <i
                className="bx bx-info-circle"
                id={"icon"}
                style={{ fontSize: "15px", marginLeft: "2px" }}
              ></i>
            </>
            {biogenicEmissions !== null ? (
              <>
                <br />
                <p className="font-size-12 mt-2 mb-0 text-secondary">
                  *Biogenic emissions against stationary combustion have avoided{" "}
                  <span className="fw-bold text-nowrap">
                    {+biogenicEmissions < 1000
                      ? `${+formateToFixed(+biogenicEmissions)} kgCO`
                      : `${+formateToFixed(+biogenicEmissions / 1000)} tCO`}
                    <sub>2</sub>e{" "}
                  </span>{" "}
                  of emissions for reporting year{" "}
                  <span className="fw-bold">{extraValue?.financialYear}</span>
                  <UncontrolledTooltip
                    placement="top"
                    target={"biogenic-icon"}
                    autohide={false}
                  >
                    Emissions are calculated as per the factors provided{" "}
                    <a href={BIOGENIC_EMISSIONS_LINK} target="_blank">
                      here
                    </a>
                    . We are committed towards keeping our emission factor
                    databases as per the evolving research to calculate carbon
                    emissions as accurately as possible.
                  </UncontrolledTooltip>
                  <i
                    className="bx bx-info-circle"
                    id={"biogenic-icon"}
                    style={{ fontSize: "12px", marginLeft: "2px" }}
                  ></i>
                </p>
              </>
            ) : null}
            <br></br>
            {extraValue?.gwpBreakdown != null && (
              <>
              <br></br>
                <span>
                  <strong>*breakup</strong>
                  <br />
                  {extraValue?.gwpBreakdown?.map((item, index) => (
                    <span key={index}>
                      {Object.keys(item)?.map((key) => (
                        <span key={key} className="px-2">
                          <strong>{`${key} : `}</strong>
                          {+extraValue?.carbonEmission < 1000
                            ? `${Number(item?.[key]).toFixed(2)} kgCO`
                            : `${(Number(item?.[key]) / 1000).toFixed(2)} tCO`}
                          <sub>2</sub>e
                          {biogenicEmissions !== null ? <>*</> : null}
                        </span>
                      ))}
                    </span>
                  ))}
                </span>
                <br></br>
              </>
            )}
          </>
        )}
      </p>
      {/* <Link className="d-flex justify-content-end" to={""}>
        Reference Link
      </Link> */}
      {extraValue?.carbonEmission != null && (
        <>
          <button
            type="submit"
            className="btn btn-primary waves-effect waves-light"
            style={{ margin: "15px" }}
            disabled={extraValue?.API_CALL2.isLoading}
            ref={extraValue?.addButtonReff}
            onClick={() => {
              extraValue?.addEmissionData(extraValue?.inputDataForEmission);
            }}
          >
            {extraValue?.API_CALL2.isLoading && (
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: "5px" }}
              />
            )}
            Add to emission Data
          </button>
          <button
            type="reset"
            className="btn btn-primary waves-effect waves-light"
            style={{ margin: "15px" }}
            onClick={() => {
              extraValue?.resetForm();
            }}
          >
            Calculate new emission
          </button>
        </>
      )}
    </div>
  );
};

export default SingleUploadRightSide;
