import React, { useEffect, useState } from "react";
import moment from "moment";
import useHttp from "../../components/Hook/Use-http";
import CONSTANT, {
  StatusButton,
  TAB_DATA,
  getTableData,
} from "../Utility/constnt";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import Table from "../../components/Custome/table";
import classnames from "classnames";
import {
  getDateAndTimeColumn,
  getUpperCaseText,
  getVehicleNumberText,
  replaceId,
} from "../Utility/function";
import { tripHeaderData } from "../Utility/exportValue";

let tripCreateHeader = [];

const VehiclesInfo = () => {
  let DriverId = window.location.pathname.split("/")[2];
  const [activeTab, setactiveTab] = useState(1);
  const [tripData, setTripData] = useState([]);
  const API_CALL = useHttp();
  const toggleCustomJustified = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };
  useEffect(() => {
    (async () => {
      // const TRIP_URL = {
      //   endpoint: `admin/trip/?vehicleId=${DriverId}`,
      //   type: "GET",
      // };
      API_CALL.sendRequest(
        replaceId(
          CONSTANT.API.SHIPMENT.getwithoutLimit,
          `/?vehicleId=${DriverId}`
        ),
        tripDataHandler
      );
    })();
  }, []);

  const tripDataHandler = (res) => {
    setTripData(
      res?.data.map((tripData, index) => {
        const startDate = moment(
          tripData?.targetedDateAndTime,
          "DD-MM-YYYY hh:mm A"
        );
        const completedDate = moment(tripData?.completedDateAndTime);

        const isStartDateAfterCompletedDate = startDate.isAfter(completedDate);

        return {
          ...tripData,
          no: index + 1,
          clientName: getUpperCaseText(tripData?.client?.name),
          transporterName: getUpperCaseText(
            tripData?.transporter?.transporterName
          ),
          driverName: getUpperCaseText(tripData?.driver?.name),
          driverPhoneNumber: tripData?.driver?.mobile,
          vehicleNumber: getVehicleNumberText(
            tripData?.vehicle?.registrationNumber
          ),
          carbonEmission: (tripData.carbonEmission = tripData?.carbonEmission
            ? (tripData?.carbonEmission / 1000).toFixed(0)
            : 0),
          plantName: getUpperCaseText(tripData?.plant?.unitName),
          startDateAndTime: getDateAndTimeColumn(tripData?.startDateAndTime),
          targetedDateAndTime: getDateAndTimeColumn(
            tripData?.targetedDateAndTime
          ),
          targetedDate: moment
            .utc(tripData?.targetedDateAndTime, "DD-MM-YYYY hh:mm A")
            .format("DD-MM-YYYY"),
          startDate: moment
            .utc(tripData?.startDateAndTime, "DD-MM-YYYY hh:mm A")
            .format("DD-MM-YYYY"),
          startTime: moment(
            tripData?.startDateAndTime,
            "DD-MM-YYYY hh:mm A"
          ).format("hh:mm A"),
          targetedTime: moment(
            tripData?.targetedDateAndTime,
            "DD-MM-YYYY hh:mm A"
          ).format("hh:mm A"),
          startDateAfterCompletedDate: isStartDateAfterCompletedDate
            ? "On Time"
            : "Delayed",
          statusdata:
            tripData?.status === 1
              ? "In Transit"
              : tripData?.status === 2
              ? "In Transit"
              : "Completed",
          statusData: (
            <>
              <StatusButton value={tripData?.status} />
            </>
          ),
        };
      })
    );
  };

  useEffect(() => {
    if (localStorage.getItem("userRole") === "admin") {
      tripCreateHeader = [
        tripHeaderData?.filter(([key]) => {
          return ![
            "transporterName",
            "driverName",
            "driverPhoneNumber",
          ].includes(key);
        }),
      ];
    }
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">Client Info</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Welcome to Fitsol Dashboard
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </Row>
        <Row>
          <Col sm={4} className="d-none">
            <Card
              style={{
                width: "100%",
              }}
            >
              <CardBody style={{ backgroundColor: "#2f4395", color: "white" }}>
                Name : Nayan
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} className="p-0">
            <Card>
              <CardBody>
                <Nav tabs className="nav-tabs-custom nav-justified">
                  {TAB_DATA.DRIVER_TAB.map((data, index) => {
                    return (
                      <div
                        key={index}
                        className="mt-2"
                        style={{ width: "100px" }}
                      >
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab === data.tabId,
                              activeTabDesign: activeTab === data.tabId,
                              TabDesign: true,
                            })}
                            onClick={() => {
                              toggleCustomJustified(data.tabId);
                            }}
                          >
                            <span className="d-none d-sm-block">
                              {data.name}
                            </span>
                          </NavLink>
                        </NavItem>
                      </div>
                    );
                  })}
                </Nav>
                <TabContent activeTab={activeTab} className="mt-3 p-0 TabBody">
                  <TabPane tabId={1} className="py-3">
                    <Table
                      title="Trips List"
                      data={getTableData("trips", tripData)}
                      exportHeader={
                        localStorage.getItem("userRole") !== "admin"
                          ? tripCreateHeader
                          : tripHeaderData
                      }
                      // exportValue={tripValueData(tripData)}
                      excelName="Shipment_Report.csv"
                    />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default VehiclesInfo;
