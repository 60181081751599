import {
  GET_DRIVER_START,
  GET_DRIVER_SUCCESS,
  GET_DRIVER_FAILURE,
  DELETE_DRIVER_START,
  DELETE_DRIVER_SUCCESS,
  DELETE_DRIVER_FAILURE,
} from "./actionTypes";

// export const getDriverDetailSuccess = (users) => {
//   return {
//     type: DRIVER_DETAIL,
//   };
// };

//GET
export const getDriverStart = () => {
  return {
    type: GET_DRIVER_START,
  };
};
export const getDriverSuccess = (users) => {
  return {
    type: GET_DRIVER_SUCCESS,
    payload: users,
  };
};
export const getDriverError = (error) => {
  return {
    type: GET_DRIVER_FAILURE,
    payload: error,
  };
};

//DELETE
export const deleteDriverStart = (id, successCallback) => ({
  type: DELETE_DRIVER_START,
  payload: id,
  successCallback,
});

export const deleteDriverSucess = (id) => ({
  type: DELETE_DRIVER_SUCCESS,
  payload: id,
});

export const deleteDriverError = (error) => ({
  type: DELETE_DRIVER_FAILURE,
  payload: error,
});
