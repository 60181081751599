import { Row } from "antd";
import React from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import { BiCreditCard } from "react-icons/bi";
import { Col } from "reactstrap";

const ApiDashboard = () => (
    <React.Fragment>
        <div className="page-content">
            <Row>
                <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h4 className="page-title mb-0 font-size-18">Dashboard</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item active">
                                    Welcome to Fitsol Dashboard
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </Row>

            <Row>
                <Col className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h4 className="page-title mb-3 font-size-18 mt-4">Snapshot</h4>

                        <Row className="page-title-right">
                            <Row
                                style={{
                                    marginRight: "20px",
                                    display: "flex",
                                    color: "#23b88",
                                    alignItems: "center",
                                    gap: "2px",
                                }}
                            >
                                {" "}
                                <AiFillPlusCircle
                                    color="#45cb85"
                                    size={"25px"}
                                    height="25px" />
                                <p
                                    style={{
                                        fontSize: "20px",
                                        color: "black",
                                        fontWeight: "600",
                                        margin: "0px",
                                    }}
                                >
                                    Live Credits
                                </p>
                            </Row>
                            <Row
                                style={{
                                    fontSize: "20px",
                                    border: "1px solid gray",
                                    borderRadius: "8px",
                                    padding: "3px 13px",
                                    display: "flex",
                                    alignItems: "center",
                                    background: "white",
                                }}
                            >
                                <BiCreditCard color={"#23b88"} />

                                <p
                                    style={{ color: "black", fontWeight: "500", margin: "0px" }}
                                >
                                    12345
                                </p>
                            </Row>
                        </Row>
                    </div>
                </Col>

                {/* <CardGen CardData={CardData} fiscalyear={fiscalyear} /> */}

                <Col><p className="">Suggested APIs</p></Col>
            </Row>
        </div>
    </React.Fragment>
);

export default ApiDashboard;
