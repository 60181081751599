import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { Link } from "react-router-dom";

const BrsrFormHeading = ({ title = "", subtitle = "", explanation = "" }) => {
  return (
    <div className="p-3" >
      <Link to={-1} className="py-2">
        <BiArrowBack size={20} />
        <span style={{ marginLeft: "5px" }}>Back to forms page</span>
      </Link>
      {title && <h3 className="py-2">{title}</h3>}
      {subtitle && <h5 className="mt-2">{subtitle}</h5>}
      <p>{explanation}</p>
    </div>
  );
};

export default BrsrFormHeading;
