import { Card } from "antd";
import React, { useEffect, useState } from "react";
import SingleTabel from "../../../components/pcfComponent/singleTabel";
import CONSTANT, { pcfProductAssemblyTableFields } from "../../Utility/constnt";
import useHttp from "../../../components/Hook/Use-http";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PcfServices from "../../Utility/API/pcfService";
import {
  assemblyContinueBtn,
  assemblyTableArrayPropertyGen,
  productAssemblyTable,
} from "../../Utility/pcfSpreadFunction";
import {
  notifications,
  optionArrayWithSort,
  replaceId,
} from "../../Utility/function";

const ProductAssemblyTab = (props) => {
  const {
    tabIndex = null,
    productId = null,
    setMainProductData,
    setTabIndex,
    mainProductData,
  } = props;
  const API = useHttp();
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const [tableGenData, setTableGenData] = useState({});

  useEffect(() => {
    Object?.keys(mainProductData)?.length == 0 &&
      !mainProductData?.id &&
      API.getAll(
        [
          CONSTANT.API.PCFPRODUCTEMISSIONSOURCE.getEnergySource.endpoint,
          CONSTANT.API.PCFPRODUCTEMISSIONSOURCE.getTranportMode.endpoint,
        ],
        (res) => {
          // setProductData(res?.[0]?.data?.data?.rows);
          // setTransportModeData(res?.[1]?.data?.data?.rows);
          setTableGenData(
            productAssemblyTable({
              energySourceId: {
                option: optionArrayWithSort(
                  res?.[0]?.data?.data?.rows?.filter(
                    (el) => el?.id != process.env.REACT_APP_PCF_ENERGY_SOURCE_ID
                  )
                ),
              },
              transportationModeId: {
                option: optionArrayWithSort(res?.[1]?.data?.data?.rows),
              },
            })
          );
        },
        PcfServices
      );
  }, []);

  //UpdateData
  useEffect(() => {
    Object?.keys(mainProductData)?.length != 0 &&
      mainProductData?.id &&
      API.getAll(
        [
          CONSTANT.API.PCFPRODUCTEMISSIONSOURCE.getEnergySource.endpoint,
          CONSTANT.API.PCFPRODUCTEMISSIONSOURCE.getTranportMode.endpoint,
          replaceId(
            CONSTANT.API.PCFPRODUCTASSEMBLY.getProductWise,
            mainProductData?.id
          ).endpoint,
        ],
        (res) => {
          // setProductData(res?.[0]?.data?.data?.rows);
          // setEnergySourceData(res?.[0]?.data?.data?.rows);
          // setTransportModeData(res?.[1]?.data?.data?.rows);
          const assemblyData = res?.[2]?.data?.data?.rows[0];
          assemblyTableArrayPropertyGen(res, assemblyData, setTableGenData);
        },
        PcfServices
      );
  }, [mainProductData]);

  const previewButton = () => {
    navigate(`${currentPath}?tab=packaging&productid=${mainProductData?.id}`);
    setTabIndex("3");
  };

  const continueButton = () => {
    assemblyContinueBtn(
      setTableGenData,
      API,
      navigate,
      setTabIndex,
      mainProductData,
      currentPath
    );
    const payload = {};
  };

  const onProductAssemblyChange = (
    elementIndex,
    fieldData,
    value,
    latitude,
    logtitude
  ) => {
    setTableGenData((prev) => {
      const prevData = { ...prev };
      const productField = prevData?.data;

      if (latitude && logtitude) {
        const keyarr = Object?.keys(productField[elementIndex].latlog);
        productField[elementIndex].latlog[keyarr[0]] = latitude;
        productField[elementIndex].latlog[keyarr[1]] = logtitude;
        productField[elementIndex].value = value;
      } else {
        productField[elementIndex].value = value;
      }
      switch (elementIndex) {
        case 1:
          // productField[elementIndex + 1].isLoading = true;
          // productField[elementIndex + 1].value = null;
          // const energyConsume = energySourceData?.find(
          //   (el) => +el?.id == +value
          // )?.emissionFactor;
          // if (energyConsume) {
          //   productField[elementIndex + 1].value = energyConsume;
          // }
          break;

        default:
          return prevData;
      }
      return prevData;
    });
  };

  return (
    <>
      <SingleTabel
        tableField={tableGenData?.data}
        continueButton={continueButton}
        tabIndex={tabIndex}
        productId={productId}
        // dynamicOption={dynamicOption}
        onSingleChange={(singleData) => {
          // setNewFormData(singleData);
        }}
        previewButton={previewButton}
        changedFields={(
          index,
          fieldData,
          value,
          multipleIndex,
          latitude,
          logtitude
        ) => {
          onProductAssemblyChange(index, fieldData, value, latitude, logtitude);
        }}
      />
    </>
  );
};

export default ProductAssemblyTab;
