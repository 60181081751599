import React, { useEffect, useState } from "react";
import useHttp from "../../components/Hook/Use-http";
import {
  getExtraConstPayload,
  isNotEmpty,
  notifications,
  optionArray,
  parseAddress,
} from "../Utility/function";
import CustomPage from "../../components/Custome/CustomPage";
import CONSTANT, { TABLE_HEADER } from "../Utility/constnt";
import notify from "../Utility/coustemFunction";
import { siteDataMask } from "../Utility/spread-function";
import { siteDetails, siteDetailsonEdit } from "../Utility/models/view-details";
import { siteMapping } from "../Utility/models/mapping-model";
import { siteHeaderData } from "../Utility/exportValue";
import { useDispatch, useSelector } from "react-redux";
import { deletePlantStart, getPlantStart } from "../../store/actions";
import GoogleMapModal from "../../components/googleMapModal";
const keyCondition = "business_name";

const Plants = () => {
  const [customerData, setCustomerData] = useState([]);
  const dispatch = useDispatch();
  const myData = useSelector((state) => state.userGetMeData);
  const getSiteData = useSelector((state) => state.plantDetailData);
  const getClientData = useSelector((state) => state.customerDetailData);

  const API_CALL = useHttp();
  const [site, setSite] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [mapViewOpen, setMapViewOpen] = useState(false);
  const [inputData, setInputData] = useState(null);
  const [editClick, setEditClick] = useState(false);
  const [val, setVal] = useState(false);

  const verifyHandler = () => {
    if (editClick == true) {
      setEditClick(false);
    }
    if (isNotEmpty(site?.gst?.trim())) {
      API_CALL.sendRequest(
        CONSTANT.API.SITE.addPlantVerifyByGst,
        (res) => {
          if (res?.data?.Succeeded)
            setSite((prev) => {
              const resData = res?.data?.Succeeded?.Gst_Details?.result;
              // const address = parseAddress(resData?.pradr?.adr);
              const address = parseAddress(resData?.address);
              return {
                ...prev,
                ...resData,
                GST:resData?.gstin,
                business_name: resData?.trade_name,
                addressLine1: address?.AddressLine1,
                addressLine2: address?.City,
                city: address?.City,
                state: address?.State,
                pin_code: address?.Pincode,
                date_of_registration: resData?.date_of_registration,
                business_type: resData?.constitution_of_business,
                business_address: address?.AddressString,
                pradr: address?.AddressString,
                gst_status: resData?.gstin_status,
                is_aadhar_verified: resData?.adhrVFlag,
                is_field_visit_conducted: resData?.isFieldVisitConducted,
              };
            });
          else if (res?.message?.Failed) {
            setErrorMessage(res?.message?.Failed?.errorMessage);
          }
        },
        {
          GSTN: site?.gst?.trim(),
        }
      );
    } else {
      notifications.error("Please Enter Valid GST Number");
    }
  };
  useEffect(() => {
    dispatch(getPlantStart());
  }, []);
  useEffect(() => {
    // (async () => {
    if (localStorage.getItem("userRole") == "admin") {
      setCustomerData(getClientData?.users?.data?.rows);
    }
    if (localStorage.getItem("userRole") == "client") {
      setSite((prev) => {
        return {
          ...prev,
          clientId: myData.users?.id,
        };
      });
    }
  }, [getClientData?.users]);

  return (
    <>
      {" "}
      <div className="page-content">
        <CustomPage
          PAGE={{
            title: "Sites",
            dataFunction: (res, Actions) =>
              siteDataMask(res, Actions, setMapViewOpen, setInputData),
          }}
          // API={CONSTANT.API.SITE}
          API={{
            ...CONSTANT.API.SITE,
            create: CONSTANT.API.SITE.create,
          }}
          REDUX={{
            getDataRedux: getSiteData?.users,
            getApiDataRedux: getPlantStart(),
            deleteApiDataRedux: deletePlantStart,
            // getData:{myData}
          }}
          FORM={{
            formField: CONSTANT.FORM_FIELDS?.SITE,
            addFormTitle: "Add site",
            editFormTitle: "Edit site",
            addNewRecordCtaText: "Add site",
            // removeFieldOnEdit: ["GST"],
            // optionalFieldOnEdit: ["tripEndDistance", "insuranceNumber"],
            isSubmitButtonVisible: site?.hasOwnProperty(keyCondition)
              ? true
              : false,
            extraConstPayload: site?.hasOwnProperty(keyCondition)
              ? getExtraConstPayload(site, siteMapping)
              : [],
            onChange: (data) => {
              if (data?.gstNumber) {
                setSite((prev) => {
                  return {
                    ...prev,
                    gst: data?.gstNumber,
                  };
                });
              }
            },
            option: {
              clientId: optionArray(customerData, "name", "id"),
            },
            viewModel: {
              setVerifyData: setSite,
              viewModelData: site,
              viewModelImage: site?.image,
              cardVisibleOn: keyCondition,
              viewModelLoading: API_CALL?.isLoading,
              viewModelDetails:
                editClick == true ? siteDetailsonEdit : siteDetails,
              errorMessage: errorMessage,
              onVerifyHandler: verifyHandler,
            },
          }}
          TABLE={{
            title: "Sites list",
            header: TABLE_HEADER?.site,
            export: {
              fileName: "Site_Report.csv",
              header: siteHeaderData,
            },
          }}
          editClick={editClick}
          setEditClick={setEditClick}
          val={val}
          setVal={setVal}
          //redux
          // getData={getSiteData}       =======================================redux
        />
      </div>
      <GoogleMapModal
        isOpen={mapViewOpen}
        inputData={inputData}
        setmapViewOpen={setMapViewOpen}
        autoSerach={false}
        viewOnly={true}
        onSubmit={(data) => {}}
      ></GoogleMapModal>
    </>
  );
};

export default Plants;
