import React, { useEffect, useState } from "react";
import CustomPage from "../../components/Custome/CustomPage";
import { getExtraConstPayload, replaceId } from "../Utility/function";
import { SiteAccessMask } from "../Utility/spread-function";
import { Fitsol_Team } from "../Utility/exportValue";
import useHttp from "../../components/Hook/Use-http";
import CONSTANT, { TABLE_HEADER } from "../Utility/constnt";
import CustomModalMain from "../../components/Custome/CustomModalMain";
import {
  deleteRoleAndAccessStart,
  getRoleAndAccessStart,
} from "../../store/actions";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const SitesAccess = () => {
  const API_CALL2 = useHttp();
  // let SiteID = window.location.pathname.split("/")[2];
  const { SiteID } = useParams();
  const getRolAndAccessData = useSelector(
    (state) => state.roleAndAccessDetailData
  );
  const getSiteData = useSelector((state) => state.plantDetailData);

  const [siteData, setSiteData] = useState([]);
  const [actionData, setActionData] = useState({});

  const [showModel_1, setShowModel_1] = useState(false);
  useEffect(() => {
    // const apiData = replaceId(CONSTANT.API.SITE.getSinglePlant, SiteID);
    // API_CALL2.sendRequest(apiData, siteDataHandler);
    siteDataHandler(
      getSiteData?.users?.data?.rows?.find((it) => it?.id == SiteID)
    );
  }, [getSiteData]);
  const siteDataHandler = (res) => {
    setSiteData(res);
  };

  const onSubmitForm = (payload, setFormData) => {
    API_CALL2.sendRequest(
      replaceId(CONSTANT.API.ROLEANDACCESS.update, actionData?.id),
      () => {
        setShowModel_1(false);
        setActionData(null);
        setFormData({});
      },
      payload,
      "Password updated"
    );
  };
  return (
    <>
      <div className="page-content">
        <CustomPage
          PAGE={{
            title: `Sites / ${siteData?.unitName || ""}`,
            withBackButton: true,
            dataFunction: (res, Actions) => {
              return SiteAccessMask(
                res,
                SiteID,

                setShowModel_1,
                setActionData,
                Actions
              );
            },
          }}
          REDUX={{
            getDataRedux: getRolAndAccessData?.users,
            getApiDataRedux: getRoleAndAccessStart(),
            deleteApiDataRedux: deleteRoleAndAccessStart,
          }}
          API={CONSTANT.API.ROLEANDACCESS}
          FORM={{
            formField: CONSTANT.FORM_FIELDS?.TEAM,

            addFormTitle: "Create access",
            editFormTitle: "Edit access",
            addNewRecordCtaText: "Create access",
            removeFieldOnEdit: ["password"],
            extraConstPayload: getExtraConstPayload({
              role: "siteViewer",
              plantId: SiteID,
            }),
          }}
          TABLE={{
            title: "Roles and access list",
            header: TABLE_HEADER?.team,
            export: {
              fileName: siteData?.unitName + "_Access.csv",
              header: Fitsol_Team,
            },
          }}
          // API
          //   CreateAPI={CONSTANT.API.ROLEANDACCESS.addTeam}
          //  UpdateAPI={CONSTANT.API.ROLEANDACCESS.updateTeam}
          // DeleteAPI={CONSTANT.API.ROLEANDACCESS.deleteTeam}
          // pageHeading={`SITES / ${siteData?.unitName}`}
          //   withBackButton
          // GetAPI={CONSTANT.API.ROLEANDACCESS.getTeam}
          //  getDataRedux={getRolAndAccessData?.users}
          //  getApiDataRedux={getRoleAndAccessStart}
          //  deleteApiDataRedux={deleteRoleAndAccessStart}
          // Model
          //  ModalName="TEAM" //Form Model
          //  tableColumn="team" //Vehicle Model
          //Form Customization
          //   addName="Add Access "
          //  addFormTitle="Add Access"
          //  editFormTitle="Edit Access"
          formData={true}
          // removeFieldOnEdit={["password"]}
          // extraConstPayload={getExtraConstPayload({
          //   role: "siteViewer",
          //   plantId: SiteID,
          // })}
          // Table Customization
          // titleHeader="Roles And Access List"
          // datafunction={(res) => {
          //   return SiteAccessMask(res, SiteID, setShowModel_1, setActionData);
          // }}
          // ExportHeaders={Fitsol_Team}
          // ExportValues={driverValueData(driverDataExcel)}
          //  excelName={siteData?.unitName + "_Access.csv"}
          viewModelData={false}
        />
      </div>
      <CustomModalMain
        modalType="formModal"
        show={showModel_1}
        close={() => setShowModel_1((prev) => !prev)}
        modalTitle="Reset password"
        onSubmit={(data, setFormData) => onSubmitForm(data, setFormData)}
        data={CONSTANT.FORM_FIELDS.RESETPASSWORD}
        formData={false}
      />
    </>
  );
};

export default SitesAccess;
