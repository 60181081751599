import React, { useEffect, useRef, useState } from "react";
import useHttp from "../../../components/Hook/Use-http";
import CONSTANT, { StatusButton } from "../../Utility/constnt";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
  NavLink,
} from "reactstrap";
import classnames from "classnames";
import { getTableData, TAB_DATA } from "../../Utility/constnt";
import Table from "../../../components/Custome/table";
import moment from "moment";
import {
  getDateAndTimeColumn,
  getMobileNumber,
  getUpperCaseText,
  getVehicleNumberText,
  replaceId,
} from "../../Utility/function";
import jsPDF from "jspdf";
import BillForm from "../../BillForm";
import { tripHeaderData } from "../../Utility/exportValue";

let tripCreateHeader = [];

const DriverInfo = () => {
  let DriverId = window.location.pathname.split("/")[2];
  const [activeTab, setactiveTab] = useState(1);
  const [tripData, setTripData] = useState([]);
  const [downloadData, setDownloadData] = useState({});
  const API_CALL = useHttp();
  const reportTemplateRef = useRef(null);
  const toggleCustomJustified = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };
  useEffect(() => {
    (async () => {
      // const TRIP_URL = {
      //   endpoint: `/trip/?driverId=${DriverId}`,
      //   type: "GET",
      // };
      API_CALL.sendRequest(
        replaceId(
          CONSTANT.API.SHIPMENT.getwithoutLimit,
          `/?driverId=${DriverId}`
        ),
        tripDataHandler
      );
    })();
  }, []);

  const goToMapPage = (trip) => {
    window.location.assign(`/tracking/${trip?.id}`);
  };

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      orientation: "l",
      format: [1130, 1100],
      unit: "px",
    });

    // Adding the fonts.
    doc.setFont("Inter-Regular", "normal");

    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        await doc.save("document");
      },
    });
  };
  const tripDataHandler = (res) => {
    setTripData(
      res?.data.map((tripData, index) => {
        const startDate = moment(
          tripData?.targetedDateAndTime,
          "DD-MM-YYYY hh:mm A"
        );
        const completedDate = moment(tripData?.completedDateAndTime);

        const isStartDateAfterCompletedDate = startDate.isAfter(completedDate);
        return {
          ...tripData,
          no: `CO${tripData?.id + 20000000006000}`,
          clientName: getUpperCaseText(tripData?.client?.name),
          transporterName: getUpperCaseText(
            tripData?.transporter?.transporterName
          ),
          driverName: getUpperCaseText(tripData?.driver?.name),
          driverPhoneNumber: getMobileNumber(tripData?.driver?.mobile),
          vehicleNumber: getVehicleNumberText(
            tripData?.vehicle?.registrationNumber
          ),
          plantName: getUpperCaseText(tripData?.plant?.unitName),
          startDateAndTime: getDateAndTimeColumn(tripData?.startDateAndTime),
          targetedDateAndTime: getDateAndTimeColumn(
            tripData?.targetedDateAndTime
          ),
          targetedDate: moment
            .utc(tripData?.targetedDateAndTime, "DD-MM-YYYY hh:mm A")
            .format("DD-MM-YYYY"),
          startDate: moment
            .utc(tripData?.startDateAndTime, "DD-MM-YYYY hh:mm A")
            .format("DD-MM-YYYY"),
          startTime: moment(
            tripData?.startDateAndTime,
            "DD-MM-YYYY hh:mm A"
          ).format("hh:mm A"),
          targetedTime: moment(
            tripData?.targetedDateAndTime,
            "DD-MM-YYYY hh:mm A"
          ).format("hh:mm A"),
          startDateAfterCompletedDate: isStartDateAfterCompletedDate
            ? "On Time"
            : "Delayed",
          statusdata:
            tripData?.status === 1
              ? "In Transit"
              : tripData?.status === 2
              ? "In Transit"
              : "Completed",
          statusData: (
            <>
              <StatusButton value={tripData?.status} />
            </>
          ),
          mapView: (
            <i
              role="button"
              onClick={() => goToMapPage(tripData)}
              className="mdi mdi-eye-circle-outline fs-4"
            ></i>
          ),
          billDownload: (
            <i
              role="button"
              className="bx bxs-download fs-4 mt-2"
              onClick={() => {
                handleGeneratePdf();
                setDownloadData(tripData);
              }}
            ></i>
          ),
        };
      })
    );
  };

  useEffect(() => {
    if (localStorage.getItem("userRole") === "admin") {
      tripCreateHeader = [
        tripHeaderData?.filter(([key]) => {
          return ![
            "transporterName",
            "driverName",
            "driverPhoneNumber",
          ].includes(key);
        }),
      ];
    }
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">Client Info</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Welcome to Fitsol dashboard
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </Row>
        <Row>
          <Col sm={4} className="d-none">
            <Card
              style={{
                width: "100%",
              }}
            >
              <CardBody style={{ backgroundColor: "#2f4395", color: "white" }}>
                Name : Nayan
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} className="p-0">
            <Card>
              <CardBody>
                <Nav tabs className="nav-tabs-custom nav-justified">
                  {TAB_DATA.DRIVER_TAB.map((data, index) => {
                    return (
                      <div
                        key={index}
                        className="mt-2"
                        style={{ width: "100px" }}
                      >
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab === data.tabId,
                              activeTabDesign: activeTab === data.tabId,
                              TabDesign: true,
                            })}
                            onClick={() => {
                              toggleCustomJustified(data.tabId);
                            }}
                          >
                            <span className="d-none d-sm-block">
                              {data.name}
                            </span>
                          </NavLink>
                        </NavItem>
                      </div>
                    );
                  })}
                </Nav>
                <TabContent activeTab={activeTab} className="mt-3 p-0 TabBody">
                  <TabPane tabId={1} className="py-3">
                    <Table
                      title="Shipment list"
                      data={{
                        columns: getTableData("trips")["columns"],
                        rows: tripData,
                      }}
                      exportHeader={
                        localStorage.getItem("userRole") !== "admin"
                          ? tripCreateHeader
                          : tripHeaderData
                      }
                      // exportValue={tripValueData(tripData)}
                      excelName="Shipment_Report.csv"
                    />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <div className="d-none">
        <div className="" ref={reportTemplateRef}>
          <BillForm trip={downloadData} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DriverInfo;
