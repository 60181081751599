import React, {useState, useEffect} from 'react'
import CustomPage from '../../../Custome/CustomPage'
import CONSTANT, { TABLE_HEADER } from '../../../../pages/Utility/constnt'
import { createEndPoint, replaceFormIdandClientId } from '../../../../pages/Utility/function'
import { useLocation, useParams } from 'react-router-dom'
import { getRecordId } from '../../../../pages/Utility/function';
import { jobViewDataMask, emissionEntryCategoryWiseDataLoad, bulkUploadConnectionJobLoad, bulkUploadSingleJobLoad } from '../../../../pages/Utility/spread-function';
import { getTableColumConfig } from '../../../../pages/Utility/constnt'
import Loader from '../../../Loader';
import {Card, CardBody} from 'reactstrap';
import TableView from '../../../Custome/tableView';
import { getTableData } from '../../../../pages/Utility/constnt';
import useHttp from '../../../Hook/Use-http'
const BulkOnboardPartnerView = (props) => {
  const {
    jobId,
    jobSingleData,
    API,
    initHeader,
    // setAllDynamicOption,
    setJobViewData,
    onViewReason,
    responceStatus,
    flag,
  } = props;
  const [alldynamicOption, setAllDynamicOption] = useState({});
  const userRole = localStorage.getItem("userRole");
  const [dynamicOption, setDynamicOption] = useState({});
  const [subCategoryModal, setSubCategoryModal] = useState([]);
  const [jobData ,setJobData] = useState([]);
  const API_CALL = useHttp();
  const [subHeader, setHeader] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const role = localStorage.getItem("userRole");
  const location = useLocation();
  const currentPath = location.pathname;
  let jobIdNum = parseInt(jobId, 10);

  useEffect(()=>{
    const fetchJobData = async () => {
        if (userRole === "admin"){
            API_CALL.sendRequest(
                replaceFormIdandClientId(CONSTANT.API.CONNECTIONS.getSingleJob, jobIdNum, id),
                (res) => {
                    let formattedData = bulkUploadSingleJobLoad(res?.data);
                    setJobData(formattedData);
                    setLoading(false);
                },
                null,
                null,
                (err)=> {
                  console.log("err", err);
                }
            )
        }
    }
    fetchJobData();
  },[]);
  

  return (
    <div>
      <CustomPage
      
      PAGE={{
        // withBackButton: true,
        title: `JOB / ${getRecordId?.job(jobId)}`,
        dataFunction: (res, Actions) => {
          setJobViewData(res?.result);

          return jobViewDataMask(res, Actions, onViewReason, responceStatus);
        },
        pageRefresh: { flag },
      }}
      noHeader={false}
      // API={{
      //   get: createEndPoint.get(
      //     role === "admin" ?
      //     `${role}/connectionJobs/${jobId}&clientId=${id}`:
      //     `${role}/connectionJobs/${jobId}`
      //   )
      // }}
      />
      <>
      {loading ? (<Loader/>):(
        <Card style={{ borderRadius: "7px" }}>
          <CardBody>
            <TableView 
            title="Job entries list" 
            data={getTableData(TABLE_HEADER?.jobEntriesTable, jobData)}
            // exportheaders to be added 
            excelName="Invited_Partners.csv" />
          </CardBody>
        </Card>
      )}
      </>
    </div>
  )
}

export default BulkOnboardPartnerView