import { Button, Col, Row } from "antd";
import React from "react";

const FooterGen = (props) => {
  const {
    isPreviewButton = false,
    isContinueButton = false,
    previewButton = () => {},
    continueButton = () => {},
    previousLabel = "Previous",
    continueButtonLabel = "Save and Continue",
  } = props;
  return (
    <>
      <Row className="gap-4 p-3 d-flex justify-content-end">
        <Col className="">
          <Button
            className="outline-btn waves-effect waves-light"
            disabled={isPreviewButton}
            onClick={previewButton}
          >
            {previousLabel}
          </Button>
        </Col>
        <Col className="">
          <Button
            type=""
            className="btn btn-primary waves-effect waves-light"
            onClick={continueButton}
            disabled={isContinueButton}
            style={
              {
                // color: "#62C941",
                // background: "#060DBF",
                // fontWeight: "600",
              }
            }
          >
            {continueButtonLabel}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default FooterGen;
