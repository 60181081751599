import { GET_USERS_GETME_SUCCESS } from "./actionTypes";

const INIT_STATE = {
  users: [],
  userProfile: {},
  error: {},
};

const userGetMeData = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS_GETME_SUCCESS:
      return {
        ...state,
        users: action.payload,
      };

    // case GET_USERS_GETME_SUCCESS:
    //   return {
    //     ...state,
    //     users: action.payload,
    //   };

    // case GET_USERS_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   };

    // case GET_USER_PROFILE_SUCCESS:
    //   return {
    //     ...state,
    //     userProfile: action.payload,
    //   };

    // case GET_USER_PROFILE_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   };

    default:
      return state;
  }
};

export default userGetMeData;
