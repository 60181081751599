import { GET_USERS_GETME_SUCCESS } from "./actionTypes";

// export const getUsers = () => ({
//   type: GET_USERS,
// });

export const getUsersDetailSuccess = (users) => {
  return {
    type: GET_USERS_GETME_SUCCESS,
    payload: users,
  };
};

// export const updateUsersDetailSuccess = (users) => {
//   return {
//     type: "GET_USERS_GETME_SUCCESS",
//     payload: users,
//   };
// };

// export const getUsersFail = (error) => ({
//   type: GET_USERS_FAIL,
//   payload: error,
// });

// export const getUserProfile = () => ({
//   type: GET_USER_PROFILE,
// });

// export const getUserProfileSuccess = (userProfile) => ({
//   type: GET_USER_PROFILE_SUCCESS,
//   payload: userProfile,
// });

// export const getUserProfileFail = (error) => ({
//   type: GET_USER_PROFILE_FAIL,
//   payload: error,
// });
