// import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
// import { SET_SHIPMENT_DETAIL, SHIPMENT_DETAIL } from "./actionTypes";
// import Apicall from "../../components/Hook/Api-call";
// import CONSTANT from "../../pages/Utility/constnt";

// const API_URL = "https://jsonplaceholder.typicode.com/users";

// function* getShipment() {
//   try {
//     const res = yield Apicall.get(CONSTANT.API.SHIPMENT.getTrip.endpoint);

//     yield put({ type: SET_SHIPMENT_DETAIL, data: res });
//     // debugger;
//   } catch (error) {
//     console.log(error);
//     // yield put({ type: "GET_MY_PROFILE_ERROR", error });
//   }
// }

// function* GetShipmentDetailSaga() {
//   yield takeEvery(SHIPMENT_DETAIL, getShipment);
// }

// export default GetShipmentDetailSaga;

import { put, takeLatest } from "redux-saga/effects";
import { DELETE_SHIPMENT_START, GET_SHIPMENT_START } from "./actionTypes";
import Apicall from "../../components/Hook/Api-call";
import CONSTANT from "../../pages/Utility/constnt";
import {
  deleteShipmentError,
  deleteShipmentSucess,
  getShipmentError,
  getShipmentSuccess,
} from "./actions";
import { replaceEndPointId } from "../../pages/Utility/function";

function* getShipment(api = null) {
  try {
    const res = yield Apicall?.get(
      api?.payload != null ? api?.payload : CONSTANT.API.SHIPMENT.get.endpoint
    );
    yield put(getShipmentSuccess(res));
  } catch (error) {
    console.log(error);
    yield put(getShipmentError(error?.message));

    // yield put({ type: GET_CUSTOMER_FAILURE, error });
  }
}

function* deleteShipmentSaga(user) {
  try {
    const response = yield Apicall?.remove(
      replaceEndPointId(
        CONSTANT?.API?.SHIPMENT?.delete?.endpoint,
        user?.payload
      )
    );

    if (response?.status == "success") {
      if (user?.successCallback) {
        user?.successCallback();
      }
    }
    yield put(deleteShipmentSucess(user));
  } catch (error) {
    console.log(error?.message, "responce");
    yield put(deleteShipmentError(error?.message));
  }
}

function* GetShipmentDetailSaga() {
  yield takeLatest(GET_SHIPMENT_START, getShipment);
  yield takeLatest(DELETE_SHIPMENT_START, deleteShipmentSaga);
}

export default GetShipmentDetailSaga;
