// import { SHIPMENT_DETAIL } from "./actionTypes";

// // export const getMyProfile = () => ({
// //   type: GET_MY_PROFILE,
// // });

// export const getShipmentDetailSuccess = (users) => {
//   return {
//     type: SHIPMENT_DETAIL,
//   };
//   // return {
//   //   type: GET_DRIVER_DETAIL_SUCCESS,
//   //   payload: users,
//   // };
// };

import {
  GET_SHIPMENT_START,
  GET_SHIPMENT_SUCCESS,
  GET_SHIPMENT_FAILURE,
  DELETE_SHIPMENT_START,
  DELETE_SHIPMENT_SUCCESS,
  DELETE_SHIPMENT_FAILURE,
} from "./actionTypes";

//GET
export const getShipmentStart = (api = null) => {
  return {
    type: GET_SHIPMENT_START,
    payload: api,
  };
};
export const getShipmentSuccess = (users) => {
  return {
    type: GET_SHIPMENT_SUCCESS,
    payload: users,
  };
};
export const getShipmentError = (error) => {
  return {
    type: GET_SHIPMENT_FAILURE,
    payload: error,
  };
};

//DELETE
export const deleteShipmentStart = (id, successCallback) => ({
  type: DELETE_SHIPMENT_START,
  payload: id,
  successCallback,
});

export const deleteShipmentSucess = (id) => ({
  type: DELETE_SHIPMENT_SUCCESS,
  payload: id,
});

export const deleteShipmentError = (error) => ({
  type: DELETE_SHIPMENT_FAILURE,
  payload: error,
});
