import React from "react";
import ReactApexChart from "react-apexcharts";

const HomeChart1 = (props) => {
  let { data, showEmission } = props;
  const series = [
    {
      name: `Carbon emission (tCO<sub>2</sub>e)`,

      data: data?.map((e) => e.value.toFixed(0)),
      type: "column",
    },
    {
      name: `Carbon Efficiency  (tCO<sub>2</sub>e/Freight Tonne-km)`,
      data: data?.map((e) => e.effValue.toFixed(0)),
      type: "column",
    },
  ];

  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 1,
    },
    plotOptions: {
      bar: {
        columnWidth: "10%",
      },
    },
    series: [
      {
        name: "series1",
        data: data?.map((e) => e.key),
        type: "column",
      },
    ],
    colors: ["#45cb85", "#eeb902"],
    xaxis: {
      categories: data?.map((e) => e.key),
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      type: "solid",
      opacity: [0.2, 0.1],
    },

    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
  };
  return (
    <div>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height="350"
        className="apex-charts"
      />
    </div>
  );
};

export default HomeChart1;
