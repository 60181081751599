/* USERS */
// export const GET_USERS = "GET_USERS";
// export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
// export const GET_USERS_FAIL = "GET_USERS_FAIL";

// /* USERS PROFILE */
// export const GET_USER_PROFILE = "GET_USER_PROFILE";
// export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
// export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL";

// export const GET_DRIVER_DETAIL_SUCCESS = "GET_DRIVER_DETAIL_SUCCESS";
// export const GET_MY_PROFILE = "GET_MY_PROFILE";

export const ROLEANDACCESS_DETAIL = "ROLEANDACCESS_DETAIL";
export const SET_ROLEANDACCESS_DETAIL = "SET_ROLEANDACCESS_DETAIL";

export const DELETE_ROLEANDACCESS_START = "DELETE_ROLEANDACCESS_START";
export const DELETE_ROLEANDACCESS_SUCCESS = "DELETE_ROLEANDACCESS_SUCCESS";
export const DELETE_ROLEANDACCESS_FAILURE = "DELETE_ROLEANDACCESS_FAILURE";

export const GET_ROLEANDACCESS_START = "GET_ROLEANDACCESS_START";
export const GET_ROLEANDACCESS_SUCCESS = "GET_ROLEANDACCESS_SUCCESS";
export const GET_ROLEANDACCESS_FAILURE = "GET_ROLEANDACCESS_FAILURE";
