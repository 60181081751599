import { put, takeLatest } from "redux-saga/effects";
import { GET_JOBBULK_START } from "./actionTypes";
import Apicall from "../../components/Hook/Api-call";
import CONSTANT from "../../pages/Utility/constnt";
import { getJobBulkError, getJobBulkSuccess } from "./actions";

function* getJobBulk(api = null) {
  try {
    const res = yield Apicall?.get(
      api?.payload != null ? api?.payload : CONSTANT.API.JOB.get.endpoint
    );
    yield put(getJobBulkSuccess(res));
  } catch (error) {
    console.log(error);
    yield put(getJobBulkError(error?.message));
  }
}

function* GetJobBulkDetailSaga() {
  yield takeLatest(GET_JOBBULK_START, getJobBulk);
}

export default GetJobBulkDetailSaga;
