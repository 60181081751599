import React, { useEffect, useRef, useState } from "react";

import "../../assets/css/tableComponent.css";
import {
  Col,
  Row,
  Tooltip,
  Typography,
  Button,
  Input,
  Divider,
  Card,
} from "antd";
import { Label } from "../../components/Custome/Antd-FormFields";
import TableFormFields from "../../components/pcfComponent/tableFormFields";
import HeaderGen from "../../components/pcfComponent/headerGen";
import { removeKeys } from "../Utility/function";
import { multipleChanges } from "../Utility/pcfSpreadFunction";
import { pcfMaterialPartDetailTableFields } from "../Utility/constnt";
import { IoMdClose } from "react-icons/io";
import Loader from "../../components/Loader";
import FooterGen from "../../components/pcfComponent/footerGen";

const { Paragraph } = Typography;

const TableComponent = (props) => {
  const {
    tableFieldList,
    categoryData,
    subCategoryData,
    qualityData,
    processCategoryData,
    processSubCategoryData,
    dynamicOption,
    addNewProcess = () => {},
    addPart = () => {},
    ondeleteButton = () => {},
    deletePart = () => {},
    deleteProcess = () => {},
    onPartValueChange = () => {},
    onProcessValueChange = () => {},
    continueButton = () => {},
    mainProductData,
    sumOfWeight,
    previewButton = () => {},
    setDeleteData,
  } = props;
  const [tableAdd, setTableAdd] = useState([]);
  const [tableHeader, setTableHeader] = useState(true);
  const [formMainData, setFormMainData] = useState([]);
  const [multipleChangeData, setMultipleChangeData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const containerRef = useRef();

  const checkLayout = () => {
    const totalGapWidth = 8;

    let totalWidth = 0;
    const rowZeroElement = document.getElementById("row_0");

    totalWidth = tableFieldList[0]?.data?.reduce((sum, el, i) => {
      const tempDiv = document.getElementById("col-0-" + i);
      const tempWidth = tempDiv?.offsetWidth || 0;

      return sum + tempWidth;
    }, 0);

    setTableHeader(rowZeroElement?.offsetWidth >= totalWidth + totalGapWidth);
    setIsLoading(false);
  };

  useEffect(() => {
    // Check initial layout
    setTimeout(() => {
      checkLayout();
      // Listen to window resize for dynamic changes
      window.addEventListener("resize", checkLayout);
      // Cleanup on component unmount
      return () => {
        window.removeEventListener("resize", checkLayout);
      };
    }, 1000);
  }, [tableFieldList]);

  useEffect(() => {
    multipleChanges(
      multipleChangeData,
      dynamicOption,
      subCategoryData,
      qualityData,
      processCategoryData,
      processSubCategoryData,
      tableAdd
    );
  }, [multipleChangeData]);

  return (
    <>
      {isLoading ? (
        <div xs={24} className=" w-full">
          <Loader />
        </div>
      ) : (
        <Card style={{ width: "100%" }}>
          <Row className="responsive-table">
            <Col md={24} className="table-border-bottom text-uppercase">
              <Row
                className={`${!tableHeader && "d-none"}  p-3`}
                gutter={[8, 8]}
              >
                <HeaderGen tableFieldList={tableFieldList?.[0]?.data} />
              </Row>
            </Col>
            <Col md={24} className={`${""}`} ref={containerRef}>
              {tableFieldList?.map((tableRow, rowIndex) => (
                <Row
                  key={`row_${rowIndex}`}
                  id={`row_${rowIndex}`}
                  className="p-2 w-full"
                  gutter={[8, 8]}
                  style={{
                    borderTop: `${rowIndex != 0 ? "1px solid #dbdbdb" : ""}`,
                    position: "relative",
                  }}
                >
                  <TableFormFields
                    formFields={tableRow?.data}
                    rowIndex={rowIndex}
                    tableHeader={tableHeader}
                    dynamicOption={dynamicOption}
                    multipleTableList={
                      tableRow?.data?.[tableRow?.data?.length - 1]
                    }
                    setFormMainData={setFormMainData}
                    changedFields={(
                      index,
                      fieldData,
                      value,
                      multipleIndex,
                      latitude,
                      logtitude
                    ) => {
                      onPartValueChange(
                        rowIndex,
                        index,
                        value,
                        multipleIndex,
                        latitude,
                        logtitude
                      );
                      onProcessValueChange(
                        rowIndex,
                        index,
                        value,
                        multipleIndex,
                        latitude,
                        logtitude
                      );
                    }}
                    onSingleChange={(res) => {}}
                    formMainData={formMainData}
                    onMultipleFormData={(multipleData) => {
                      setMultipleChangeData({ ...multipleData });
                      // onChangeFliterOption(res);
                    }}
                    addNewProcess={() => {
                      addNewProcess(rowIndex);
                    }}
                    ondeleteButton={ondeleteButton}
                    deletePart={deletePart}
                    deleteProcess={deleteProcess}
                    setDeleteData={setDeleteData}

                    // removeField={removeField}
                  />
                  <Col
                    style={{
                      position: "absolute",
                      right: `${tableHeader ? "-18px" : "-5px"}`,
                      top: `${tableHeader ? "10px" : "5px"}`,
                      alignSelf: "center",
                    }}
                  >
                    <div
                      className="child-table-field-show"
                      onClick={() => {
                        setDeleteData({ partIndex: rowIndex });
                      }}
                    >
                      <IoMdClose />
                    </div>
                  </Col>
                </Row>
              ))}
            </Col>

            {/* Field child table */}

            {/* multiple part Added */}
            <Col sm={24} className="table-border-top table-border-bottom">
              <Row className="gap-4 p-3">
                {/* multiple table with multiple fields */}
                <Col
                  xs={24}
                  sm={24}
                  className="d-flex justify-content-between "
                >
                  <Row className="d-flex justify-content-between w-full">
                    <Col xs={24} sm={12}>
                      <Button
                        className="btn hover-color btn-primary waves-effect waves-light"
                        onClick={addPart}
                      >
                        Add new Part +
                      </Button>
                    </Col>
                    <Col xs={24} sm={12} style={{ marginTop: "8px" }}>
                      <Row className="gap-3 d-flex justify-content-end ">
                        <Col xs={24} sm={7}>
                          <Col className="">
                            <p className="footer-header-label text-uppercase m-0">
                              {"Sum Of Weights of Parts"}
                            </p>
                          </Col>

                          <Col
                            className="font-dmserif footer-header-value"
                            style={{
                              color: `${
                                (+mainProductData?.weight || 0) < +sumOfWeight
                                  ? "#ff3300"
                                  : ""
                              } `,
                            }}
                          >
                            {sumOfWeight || 0} grams
                          </Col>
                        </Col>
                        <Col xs={24} sm={7}>
                          <Col className="">
                            <p className="footer-header-label text-uppercase m-0">
                              {"Product Total Weight"}
                            </p>
                          </Col>
                          <Col className="font-dmserif footer-header-value ">
                            {mainProductData?.weight || 0} grams
                          </Col>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            {/* footer for continue or previous */}
            <Col sm={24}>
              <FooterGen
                isContinueButton={
                  (+mainProductData?.weight || 0) < +sumOfWeight
                }
                previewButton={previewButton}
                continueButton={continueButton}
              />

              {(+mainProductData?.weight || 0) < +sumOfWeight ? (
                <Row
                  style={{
                    color: "red",
                  }}
                >
                  Sum of weight of parts does not match total weight of product
                  given in step 1. Please make sure you correct these.
                </Row>
              ) : null}
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
};

export default TableComponent;
