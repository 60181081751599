import React from "react";
import { useState, useEffect } from "react";
import PageTitle from "../../../../components/pageTitle";
import { Row, Col, Label, Card, CardBody, CardSubtitle } from "reactstrap";
import useHttp from "../../../../components/Hook/Use-http";
import CONSTANT, {
  scopeWiseEmissionEquivalent,
  truncateAndAddCommas,
} from "../../../Utility/constnt";
import TotalEmission from "../../../../components/scopecomponents/totalEmission";
import CheckBox from "../../../../components/scopecomponents/checkBox";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  calculateScope2Emission,
  scope2TableData,
  scope2TableData1,
  scopeWiseDashboardAnalysis,
  scopeWiseDashboardAnalysis2,
  scopeWiseEmissionDashboardDataLoad,
} from "../../../Utility/spread-function";
import TableGen from "../../../../components/dashboard/tableGen";
import { useSelector } from "react-redux";
import { Carousel, Switch } from "antd";
import { replaceId } from "../../../../pages/Utility/function";
import authStorage from "../../../Utility/API/authStroge";
import { cardAnalyticsData } from "../../../Utility/option";
import MapComponent2 from "../Scope3Report/MapComponent2";
import CheckBox1 from "../../../../components/scopecomponents/checkBox1";
import useSessionStorage from "../../ToggleFunction/useSessionStorage.js";
let fiscalyear = "";
const today = new Date();
if (today.getMonth() + 1 <= 3) {
  fiscalyear = today.getFullYear() - 1;
} else {
  fiscalyear = today.getFullYear();
}
const Scope2Report = () => {
  const [tripList, setTripList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const myData = useSelector((state) => state.userGetMeData);
  const isLogin =
    authStorage?.getAuthToken() !== undefined &&
    authStorage?.getAuthToken() !== null;
  const userRole = localStorage.getItem("userRole")?.toLocaleLowerCase();
  const [financialYear, setFinancialYear] = useState(
    `${fiscalyear}-${(fiscalyear + 1).toString().substring(2)}`
  );
  const [selectedFY, setSelectedFY] = useState([]);
  const [selectedCalendarYear, setSelectedCalendarYear] = useState([]); // new added
  const [electricityData, setElectricityData] = useState({});
  const [subCategoryAttributes, setSubCategoryAttributes] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const API_CALL = useHttp();
  const location = useLocation();
  const { id } = useParams();
  const currentPath = location.pathname?.toLocaleLowerCase();
  const [categoryDataLabel, setCategoryDataLabel] = useState({});
  const [AvailableFinancialYear, setAvailableFinancialYear] = useState([]);
  const [categoryHead, setCategoryHead] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [subCategoryCheckboxData, setSubCategoryCheckboxData] = useState({});
  const [fyCheckboxData, setFYCheckboxData] = useState([]);
  const [calendarYearCheckboxData, setCalendarYearCheckboxData] = useState([]); // new added
  const [electricitySubCategoryId, setElectricitySubCategoryId] = useState();
  const [analyticData, setAnalyticData] = useState({});
  const [subCategory, setSubCategory] = useState([]);
  const [categoryDataLoading, setCategoryDataLoading] = useState(true);
  const [totalEmission, setTotalEmission] = useState("0");
  const [stackView, setStackView] = useState(<></>);
  const [lineView, setLineView] = useState(<></>);
  const [speedoMeterView, setSpeedoMeterView] = useState(<></>);
  const [gridCount, setGridCount] = useState(0);
  const [renewableCount, setRenewableCount] = useState(0);
  const [scope2res, setScope2Res] = useState();
  const [siteNameData, setSiteNameData] = useState([]);
  const [tableHeading, setTableHeading] = useState([]);
  const [table2Heading, setTable2Heading] = useState([]);
  const [intensityMetrics, setIntensityMetrics] = useState(false);

  const isFY =
    localStorage.getItem("selectedYear") == "financialYear" ? true : false;
  const [isFinancialYear, setIsFinancialYear] = useState(isFY);

  useEffect(() => {
    try {
      API_CALL.getAll(
        [
          CONSTANT.API.CATEGORY.get.endpoint,
          CONSTANT.API.SUBCATEGORY.get.endpoint,
        ],
        (res) => {
          const filtered_rows = res?.[res.length - 1]?.data?.data?.rows.filter(
            (row) => {
              return row?.category?.name === "Scope 2";
            }
          );
          if (filtered_rows && !(categoryHead?.length > 0)) {
            setCategoryHead(filtered_rows);
          }
          const [elecId] = filtered_rows.map((el) => {
            if (el.name == "Electricity") {
              return el.id;
            }
          });
          setElectricitySubCategoryId(elecId);
          if (
            subCategoryAttributes.length == 0 ||
            Object.keys(electricityData).length == 0
          ) {
            const api = {
              ...replaceId(
                CONSTANT.API.SUBCATEGORYATTRIBUTE
                  .getSingleFilterSubCategoryAttribute,
                elecId
              ),
            };
            let scope2endpoint = { ...CONSTANT.API.DASHBOARD.getAnalysis };
            if (elecId) {
              if (userRole == "admin" && currentPath?.includes(id)) {
                scope2endpoint.endpoint = `${scope2endpoint.endpoint}/${id}/subCategory/${elecId}`;
              } else {
                scope2endpoint.endpoint = `${scope2endpoint.endpoint}/subCategory/${elecId}`;
              }
            }
            try {
              API_CALL.getAll(
                [scope2endpoint.endpoint, api.endpoint],
                (res) => {
                  setScope2Res(res);
                  setElectricityData(res[0]?.data?.data);
                  setSubCategoryAttributes(res[1]?.data?.data?.rows);
                }
              );
            } catch (error) {
              console.error("Error fetching scope2 data:", error);
            }
          }
        }
      );
    } catch (error) {
      console.error("Error fetching scope2 data:", error);
    }
  }, []);
  function isEmptyObject(obj) {
    return Object.keys(obj).length === 0;
  }

  useEffect(() => {
    try {
      const siteId = id;
      API_CALL.getAll(
        [replaceId(CONSTANT.API.SITE.getClientPlant, siteId).endpoint],
        (res) => {
          let data = res[0]?.data?.data?.rows;
          const filteredData = data.map((item) => ({
            siteId: item?.id,
            lat: item?.latitude,
            lng: item?.logtitude,
            name: item?.unitName,
          }));
          setSiteNameData(filteredData);
        }
      );
    } catch (error) {
      console.error("Error fetching location data:", error);
    }
  }, [id]);

  useEffect(() => {
    if (
      electricityData &&
      subCategoryAttributes &&
      selectedFY &&
      !isEmptyObject(electricityData) &&
      !isEmptyObject(subCategoryAttributes)
    ) {
      const rees = calculateScope2Emission(
        electricityData,
        subCategoryAttributes,
        setSpeedoMeterView,
        setStackView,
        setLineView,
        selectedFY
      );

      if (selectedFY.length >= 0) {
        analysisDataHandler(selectedFY, siteNameData);
      }
    }
  }, [electricityData, subCategoryAttributes, selectedFY]);

  useEffect(() => {
    setSubCategoryCheckboxData({
      options: categoryHead.map((el) => {
        return {
          value: el?.id,
          label: el?.name,
        };
      }),
      defaultData: categoryHead.map((el) => {
        return el?.id;
      }),
    });

    if (electricityData && Object.keys(electricityData).length > 0) {
      const calendarYears = Object.keys(electricityData).filter((key) =>
        /^\d{4}$/.test(key)
      );
      const financialYears = Object.keys(electricityData).filter((key) =>
        /^\d{4}-\d{2}$/.test(key)
      );

      setFYCheckboxData({
        options: financialYears.map((el) => {
          return {
            value: el,
            label: el,
          };
        }),
        defaultData: financialYears,
      });

      // Set state for calendar year checkbox data
      setCalendarYearCheckboxData({
        options: calendarYears.map((el) => {
          return {
            value: el,
            label: el,
          };
        }),
        defaultData: calendarYears,
      });

      if (isFinancialYear) {
        setSelectedFY(financialYears.sort());
      } else {
        setSelectedFY(calendarYears.sort());
      }
    }
  }, [categoryHead, electricityData, isFinancialYear]);

  const analysisDataHandler = (selectedFY, siteNameData) => {
    scope2TableData(
      scope2res,
      setTripList,
      setCategoryList,
      selectedFY,
      subCategoryAttributes,
      setCategoryDataLoading,
      siteNameData,
      setMapData,
      setTotalEmission,
      setTableHeading,
      setTable2Heading
    );
  };
  // Filter tripList based on selected financial years and calendar years
  const filteredTripList = tripList.filter((trip) => {
    const year = trip.year || trip.financialYear;
    return selectedFY.includes(year) || selectedCalendarYear.includes(year);
  });

  // new added for persit changes in toggle---

  const handleCategoryFilter = (category) => {
    if (selectedSubCategories.includes(category)) {
      setSelectedSubCategories(
        selectedSubCategories.filter((c) => c !== category)
      );
    } else {
      setSelectedSubCategories([...selectedSubCategories, category]);
    }
  };
  const handleToggleChange = (checked) => {
    setIsFinancialYear(checked);
    if (checked) {
      localStorage.setItem("selectedYear", "financialYear");
    } else {
      localStorage.setItem("selectedYear", "calendarYear");
    }
  };

  return (
    <div className="page-content">
      <PageTitle pageHeading={`Scope 2 Emissions`} />
      <Row>
        <Col md={3}>
          {!categoryDataLoading && (
            <TotalEmission
              boxData={{
                heading: "Scope 2 emissions",
                count: `${truncateAndAddCommas(
                  totalEmission
                )} <small>tCO<sub>2</sub>e</small>`,
              }}
            />
          )}
        </Col>
        <Col md={3}>
          <Carousel autoplay dotPosition="bottom" effect="fade">
            {scopeWiseEmissionEquivalent(totalEmission).map((item, index) => (
              <div key={index}>
                <TotalEmission boxData={item} />
              </div>
            ))}
          </Carousel>
        </Col>

        {/* new added for toggle switch between calender and financial year */}

        <Col md={3}>
          <Card
            className="w-full md:w-1/4 lg:w-1/5 xl:w-1/6 xs:w-1/10"
            style={{
              height: "250px",
              overflow: "hidden",
              transform: "translateY(10px)",
            }}
          >
            <CardBody
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Switch
                checked={isFinancialYear}
                onChange={handleToggleChange}
                checkedChildren="Financial Year"
                unCheckedChildren="Calendar Year"
              />
              <div>
                {isFinancialYear ? (
                  fyCheckboxData?.defaultData &&
                  fyCheckboxData?.defaultData?.length > 0 ? (
                    <CheckBox1
                      heading={"Filter by financial year"}
                      names={fyCheckboxData}
                      onChange={(checkValues) => {
                        checkValues.sort();
                        setSelectedFY(checkValues);
                        setSelectedCalendarYear(checkValues);
                      }}
                      disabled={false}
                    />
                  ) : (
                    "Loading...."
                  )
                ) : calendarYearCheckboxData?.defaultData &&
                  calendarYearCheckboxData?.defaultData?.length > 0 ? (
                  <CheckBox1
                    heading={"Filter by calendar year"}
                    names={calendarYearCheckboxData}
                    onChange={(checkValues) => {
                      checkValues.sort();
                      setSelectedFY(checkValues);
                      setSelectedCalendarYear(checkValues);
                    }}
                    disabled={false}
                  />
                ) : (
                  "Loading...."
                )}
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md={3}>
          {subCategoryCheckboxData?.defaultData &&
          subCategoryCheckboxData?.defaultData?.length > 0 ? (
            <CheckBox
              names={subCategoryCheckboxData}
              onChange={handleCategoryFilter}
              disabled={true}
            />
          ) : (
            "Loading......"
          )}
        </Col>
      </Row>
      <Row className="py-4">
        <Col>
          <span className="font-poppins font-semibold">
            Want to view Intensity metrics?{" "}
          </span>
          <Switch
            onChange={() => {
              setIntensityMetrics(!intensityMetrics);
            }}
          />
        </Col>
      </Row>
      <Row>
        {intensityMetrics && (
          <Col xs={12}>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-3">
                  <h5 style={{ fontSize: "20px", textAlign: "center" }}>
                    <b>Scope 2 emissions YoY comparison</b>
                  </h5>
                </CardSubtitle>
                {totalEmission == "0" ? "No Data Present" : lineView}
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
      <Row>
        {!intensityMetrics && (
          <Col xs={12}>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-3">
                  <h5 style={{ fontSize: "20px", textAlign: "center" }}>
                    <b>Scope 2 emissions YoY comparison</b>
                  </h5>
                </CardSubtitle>
                {totalEmission == "0" ? "No Data Present" : stackView}
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <Card>
            <CardBody>
              <CardSubtitle className="mb-3">
                <h5 style={{ fontSize: "20px", textAlign: "center" }}>
                  <b>Site-wise breakup </b>
                </h5>
              </CardSubtitle>
              <MapComponent2
                tripList={mapData}
                selectedFY={selectedFY}
                // new added for calender year
                selectedCalendarYear={selectedCalendarYear}
                scope={2}
              />
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card>
            <CardBody
              style={{
                height: "820px",
              }}
            >
              {!categoryDataLoading && (
                <TableGen
                  title={
                    <span>
                      Site-wise scope 2 emissions in tCO<sub>2</sub>e
                    </span>
                  }
                  myData={myData}
                  // tripList={filteredTripList}
                  tripList={tripList}
                  tableHeading={tableHeading}
                  categoryDataLabel={tableHeading}
                  scope2List={true}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row xs={12}>
        <Col xs={12} md={6}>
          <Card>
            <CardBody
              style={{
                height: "742px",
              }}
            >
              <CardSubtitle className="mb-3">
                <h5 style={{ fontSize: "20px", textAlign: "center" }}>
                  <b>Renewable Vs Non-Renewable consumption in MWh</b>
                </h5>
              </CardSubtitle>
              {speedoMeterView}
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card>
            <CardBody
              style={{
                height: "742px",
              }}
            >
              {!categoryDataLoading && (
                <TableGen
                  title={
                    <span>
                      Category-wise scope 2 emissions in tCO<sub>2</sub>e
                    </span>
                  }
                  myData={myData}
                  tripList={categoryList}
                  tableHeading={table2Heading}
                  categoryDataLabel={table2Heading}
                  scope2List={true}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Scope2Report;
