import React, { useEffect, useState } from "react";
import { Row, Col, Label, Button } from "reactstrap";

import Select from "react-select";

import CONSTANT from "../../Utility/constnt";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useHttp from "../../../components/Hook/Use-http";
import { useSelector } from "react-redux";
import { cardAnalyticsData } from "../../Utility/option";
import CardGen from "../../../components/dashboard/cardGen";
import TableGen from "../../../components/dashboard/tableGen";
import {
  dashboardAnalysisNew,
  emissionDashboardDataLoadNew,
} from "../../Utility/spread-function";
import EmissionGen from "../../../components/dashboard/emissionGen";
import PageTitle from "../../../components/pageTitle";
import authStorage from "../../Utility/API/authStroge";
import { replaceId } from "../../Utility/function";
import Table from "../../../components/Custome/table";
import { Switch } from "antd";
import useSessionStorage from "../ToggleFunction/useSessionStorage.js";

let fiscalyear = "";
const today = new Date();
if (today.getMonth() + 1 <= 3) {
  fiscalyear = today.getFullYear() - 1;
} else {
  fiscalyear = today.getFullYear();
}

const CardData = {
  admin: cardAnalyticsData,
  client: cardAnalyticsData,
  siteViewer: cardAnalyticsData,
};
const ClientReport = () => {
  const [tripList, setTripList] = useState([]);
  const myData = useSelector((state) => state.userGetMeData);
  const isLogin =
    authStorage?.getAuthToken() !== undefined &&
    authStorage?.getAuthToken() !== null;
  const userRole = localStorage.getItem("userRole")?.toLocaleLowerCase();
  const [financialYear, setFinancialYear] = useState(
    `${fiscalyear}-${(fiscalyear + 1).toString().substring(2)}`
  );
  const [calendarYear, setCalendarYear] = useState(`${fiscalyear - 1}`);
  // const isFY = (localStorage.getItem("selectedYear") == "financialYear" ) ? "financialYear" : "calendarYear";
  // const [selectedYear, setSelectedYear] = useState(isFY);
  let val = localStorage.getItem("selectedYear");
  if (!val) {
    val = "financialYear";
  }
  const [selectedYear, setSelectedYear] = useSessionStorage(
    "selectedYear",
    val
  );
  const [AvailableFinancialYear, setAvailableFinancialYear] = useState([]);
  const [AvailableCalendarYear, setAvailableCalendarYear] = useState([]);
  const [categoryDataLabel, setCategoryDataLabel] = useState({});
  const [analyticData, setAnalyticData] = useState({});
  const [subCategory, setSubCategory] = useState([]);
  const [chartView, setChartView] = useState(<></>);
  const { id } = useParams();
  const API_CALL = useHttp();
  const location = useLocation();
  const currentPath = location.pathname?.toLocaleLowerCase();
  useEffect(() => {
    if (!isLogin || !userRole) return;
    let analyticsAPIendpoint = { ...CONSTANT.API.DASHBOARD.getAnalysis };

    // todo
    if (selectedYear == "financialYear") {
      if (financialYear != null) {
        if (userRole == "admin" && currentPath?.includes(id)) {
          analyticsAPIendpoint.endpoint = `${analyticsAPIendpoint.endpoint}/${id}?value=${financialYear}`;
        } else {
          analyticsAPIendpoint.endpoint = `${analyticsAPIendpoint.endpoint}?value=${financialYear}`;
        }
      }
    } else {
      if (calendarYear != null) {
        if (userRole == "admin" && currentPath?.includes(id)) {
          analyticsAPIendpoint.endpoint = `${analyticsAPIendpoint.endpoint}/${id}?value=${calendarYear}`;
        } else {
          analyticsAPIendpoint.endpoint = `${analyticsAPIendpoint.endpoint}?value=${calendarYear}`;
        }
      }
    }
    API_CALL.getAll(
      [
        analyticsAPIendpoint.endpoint,
        CONSTANT.API.CATEGORY.get.endpoint,
        CONSTANT.API.SUBCATEGORY.get.endpoint,
      ],
      (res) => {
        setAnalyticData(res?.[0]?.data);
        setSubCategory(res[2]?.data?.data?.rows);
        const rees = emissionDashboardDataLoadNew(
          res,
          {},
          [],
          setChartView,
          setAvailableFinancialYear,
          setAvailableCalendarYear
        );

        analysisDataHandler(res?.[0]?.data);
        setCategoryDataLabel(rees?.categoryData);
      }
    );
  }, [financialYear, calendarYear, selectedYear]);

  const analysisDataHandler = (res) => {
    // For Admin
    dashboardAnalysisNew(res, CardData, null, setTripList, currentPath, id);
  };
  const redirectAPIDashboard = () => {
    if (userRole == "admin") {
      API_CALL.sendRequest(
        replaceId(CONSTANT.API.ADMIN.clientToken, id),
        (res) => {
          res?.token &&
            window.open(
              `${process.env.REACT_APP_API_DASHBOARD}/login?token=${
                res?.token
              }&userRole=${localStorage.getItem("userRole")}`,
              "_blank"
            );
        }
      );
    } else {
      window.open(
        `${
          process.env.REACT_APP_API_DASHBOARD
        }/login?token=${localStorage.getItem("authUser")}`,
        "_blank"
      );
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <PageTitle />
        <Row>
          <p className="page-title mb-3 font-size-18 mt-4">
            Snapshot (
            {selectedYear == "financialYear"
              ? `FY ${financialYear}`
              : `CY ${calendarYear}`}
            )
          </p>
          {/* <Label className="d-flex align-items-center">Financial year</Label> */}
          <Label className="d-flex align-items-center">
            {selectedYear == "financialYear"
              ? "Financial Year"
              : "Calendar Year"}
          </Label>

          <Row className="d-flex flex-column align-items-start">
            <div
              className="toggle-container"
              style={{
                width: "200px",
                height: "10px",
                marginBottom: "30px",
              }}
            >
              <Switch
                // style={{
                //   height: "20px",

                // }}
                // onChange={() => setIsFinancialYear(!isFinancialYear)}
                onChange={(e) => {
                  if (selectedYear == "financialYear") {
                    setSelectedYear("calendarYear");
                    localStorage.setItem("selectedYear", "calendarYear");
                  } else {
                    setSelectedYear("financialYear");
                    localStorage.setItem("selectedYear", "financialYear");
                  }
                }}
                checked={selectedYear == "financialYear"}
                unchecked={!selectedYear == "financialYear"}
                onColor="#007bff"
                offColor="#bbb"
                checkedIcon={false} // Ensure icons are not shown
                uncheckedIcon={false} // Ensure icons are not shown
              />
            </div>

            {/* </Row> */}
          </Row>
          <Row className="d-flex justify-content-between">
            <div
              style={{
                width: "35%",
              }}
            >
              <Select
                className="mb-3"
                options={
                  selectedYear == "financialYear"
                    ? AvailableFinancialYear
                    : AvailableCalendarYear
                }
                required={true}
                maxMenuHeight={200}
                value={{
                  value:
                    selectedYear == "financialYear"
                      ? financialYear
                      : calendarYear,
                  label:
                    selectedYear == "financialYear"
                      ? financialYear
                      : calendarYear,
                }}
                onChange={(selected) => {
                  if (selectedYear == "financialYear")
                    setFinancialYear(selected?.value);
                  else setCalendarYear(selected?.value);
                }}
              />
            </div>
            <div
              className="d-flex justify-content-end"
              style={{
                width: "35%",
              }}
            >
              <Button
                color="primary"
                className="btn btn-primary waves-effect waves-light mb-3"
                onClick={redirectAPIDashboard}
              >
                View API Dashboard
              </Button>
            </div>
          </Row>
          <CardGen
            CardData={CardData?.[currentPath?.split("/")?.[1]]}
            fiscalyear={fiscalyear}
          />
        </Row>
        <Row>
          <Col xs="5" className="d-flex align-items-center">
            <EmissionGen
              extraValue={{
                financialYear:
                  selectedYear == "financialYear"
                    ? financialYear
                    : calendarYear,
                categoryDataLabel: categoryDataLabel,
                analyticData: analyticData,
              }}
            />
          </Col>
          <Col xs="7" className="mb-4">
            {chartView}
          </Col>
        </Row>
        <TableGen
          myData={myData}
          tripList={tripList}
          categoryDataLabel={subCategory}
          siteWiseList={true}
        />
      </div>
    </React.Fragment>
  );
};

export default ClientReport;
