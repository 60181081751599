import { getFormFiled } from "../function";

/* getFormFiled Function Argument 
1. Name
2. Label
3. placeholder
4. Type
5. Required

Note: if you not pass placeholder it will take label automatically
Default Type = text & Required = true
*/

// Plant
export const unitName = getFormFiled(
  "unitName",
  "Site name",
  "Site name",
  "text",
  true
);

export const location = getFormFiled(
  "location",
  "Site address",
  "Site address",
  "text"
);

export const GST = getFormFiled(
  "GST",
  "GST number",
  "GST number",
  "textWithVerifyButton",
  false
);

// PACKAGE
export const packageType = getFormFiled(
  "type",
  "Package type",
  null,
  "SingleSelect"
);

export const packageSubType = getFormFiled(
  "SubType",
  "Package sub-type",
  null,
  "text"
);

export const DimensionInmm = getFormFiled(
  "DimensionInmm",
  "Dimension (LxBxH in mm)",
  "Enter dimension(LxBxH in mm)"
);

export const material = getFormFiled(
  "material",
  "Material",
  null,
  "SingleSelect"
);

export const ActualWeight = getFormFiled(
  "ActualWeight",
  "Actual weight(KG)",
  "Enter actual weight(KG)",
  "number"
);

// Client
export const clientName = getFormFiled(
  "name",
  "Company name",
  "Enter company name as ABC Pvt Ltd"
);

export const POCName = getFormFiled("POCName", "POC Name", "Enter POC Name");

export const customerEmail = getFormFiled(
  "email",
  "Customer email",
  "Enter POC email id"
);

export const customerMobile = getFormFiled(
  "mobile",
  "Mobile no",
  null,
  "mobileNumber"
);


export const tripEndDistance = getFormFiled(
  "tripEndDistance",
  "Shipment end distance",
  null,
  "number",
  true
);

export const customerPassword = getFormFiled(
  "password",
  "Password",
  "Enter password"
);

// Consignor
export const ConsignorEmail = getFormFiled(
  "email",
  "Consignor email",
  "Enter consignor email id"
);

export const ConsignorName = getFormFiled(
  "name",
  "Consignor name",
  "Enter consignor name as ABC Pvt Ltd"
);

//Route

export const destinationPlantId = getFormFiled(
  "destinationPlantId",
  "Destination site name",
  "Select destination site",
  "SingleSelect"
);

export const vehicleCapacity = getFormFiled(
  "vehicleCapacity",
  "Vehicle capacity",
  "Enter vehicle capacity",
  "number"
);

export const expectedCompletionTimeInHR = getFormFiled(
  "expectedCompletionTimeInHR",
  "Expected completion(in hour)",
  "Enter expected completion(in hour)",
  "text"
);

export const freightWeightInTon = getFormFiled(
  "freightWeightInTon",
  "Freight weight(in ton)",
  "Enter freight weight(in ton)",
  "text"
);

// Site

export const clientId = getFormFiled(
  "clientId",
  "Customer name",
  "Customer name",
  "SingleSelect"
);
export const plantId = getFormFiled(
  "plantId",
  "Source site name",
  "Select source site",
  "SingleSelect"
);
export const routePlantId = getFormFiled(
  "plantId",
  "Source site name",
  "Select source site",
  "SingleSelect",
  true,
  false,
  (data, defaultData) => {
    if (data?.clientId || defaultData?.clientId) {
      return true;
    } else {
      return false;
    }
  }
);

export const routeDestinationPlantId = getFormFiled(
  "destinationPlantId",
  "Destination site name",
  "Select destination site",
  "SingleSelect",
  true,
  false,
  (data, defaultData) => {
    if (data?.clientId || defaultData?.clientId) {
      return true;
    } else {
      return false;
    }
  }
);

export const packageId = getFormFiled(
  "packagingTypeId",
  "Package name",
  "Package name",
  "SingleSelect"
);

export const routeName = getFormFiled(
  "routeId",
  "Route name",
  "Route name",
  "SingleSelect"
);

export const countForPackage = getFormFiled(
  "count",
  "Count",
  "Enter count",
  "number",
  true
);

export const notificationType = getFormFiled(
  "notificationType",
  "Notification type",
  "Notification type",
  "SingleSelect"
);

export const roleWise = getFormFiled("role", "Role", null, "SingleSelect");

export const plantForRoleId = getFormFiled(
  "plantId",
  "Site name",
  "Site name",
  "SingleSelect",
  true,
  false,
  (data, defaultData) => {
    if (
      (data?.role && data?.role === "siteViewer") ||
      (!data?.["role"] &&
        defaultData?.role &&
        defaultData?.role === "siteViewer")
    ) {
      return true;
    } else {
      return false;
    }
  }
);

export const clientForRoleId = getFormFiled(
  "clientId",
  "Customer name",
  "Customer name",
  "SingleSelect",
  true,
  false,
  (data, defaultData) => {
    if (
      (data?.role && data?.role === "client") ||
      (!data?.["role"] && defaultData?.role && defaultData?.role === "client")
    ) {
      return true;
    } else {
      return false;
    }
  }
);

export const siteAddressLine1 = getFormFiled(
  "addressLine1",
  "Site address line 1",
  "Enter site address line 1"
);

export const siteAddressLine2 = getFormFiled(
  "addressLine2",
  "Site address line 2",
  "Enter site address line 2"
);

export const pinCode = getFormFiled("pincode", "Pincode", null, "pincode");

export const pinCodeCity = getFormFiled("city", "City", null, "pincode");
export const pinCodeSiteState = getFormFiled("state", "State", null, "pincode");

export const actualAddress = getFormFiled(
  "actualAddress",
  "Google location",
  "Enter google location link",
  "google-map-view"
);
export const scopeForCategory = getFormFiled(
  "categoryId",
  "Scope",
  "Select scope",
  "SingleSelect"
);

export const name = getFormFiled("name", "Name", null);
export const email = getFormFiled("email", "Email", null);
export const mobile = getFormFiled("mobile", "Mobile no", null, "mobileNumber");
export const distance = getFormFiled("distance", "Distance", null);
export const csvName = getFormFiled("csv", "Csv name", null);
export const formModalName = getFormFiled(
  "categoryFormModalName",
  "Form modal name",
  null
);
export const description = getFormFiled(
  "description",
  "Descripition",
  null,
  "text",
  false
);
export const postmanLink = getFormFiled(
  "postmanLink",
  "Postman link",
  null,
  "text",
  false
);

export const color = getFormFiled("color", "Color", null, "color");

export const sampleFile = getFormFiled(
  "sampleFile",
  "Sample file",
  null,
  "file",
  false
);

export const profilePic = getFormFiled(
  "profilePic",
  "Profile pic",
  null,
  "file"
);

export const transporterName = getFormFiled("transporterName", "Name", null);
export const gstNumber = getFormFiled(
  "gstNumber",
  "GST number",
  null,
  "textWithVerifyButton"
);

export const registrationNumber = getFormFiled(
  "registrationNumber",
  "Registration number",
  null
);

export const VehicleNumber = getFormFiled(
  "VehicleNumber",
  "Vehicle number",
  null,
  "textWithVerifyButton"
);

export const categorySelect = getFormFiled(
  "category",
  "Category",
  "Select category",
  "SingleSelect"
);
export const categoryAttributeForm = getFormFiled(
  "attributeId",
  "Attribute",
  "Select attribute",
  "SingleSelect"
);
export const definationType = getFormFiled(
  "definationType",
  "Answer type",
  "Select defination",
  "SingleSelect"
);
export const categoryAttributeDataType = getFormFiled(
  "dataType",
  "Data type",
  "Select data type",
  "SingleSelect",
  true,
  false,
  (data) => {
    if (data?.definationType) {
      return true;
    } else {
      return false;
    }
  }
);
export const categoryAttributeIsrequiared = getFormFiled(
  "isRequired",
  "Required",
  "",
  "switch",
  true,
  false,
  (data) => {
    if (data?.definationType == "userDefined") {
      return true;
    } else {
      return false;
    }
  }
);

export const scope = getFormFiled("scope", "Scope", null, "text", true, true);

export const shipmentDate = getFormFiled(
  "shipmentDate",
  "Shipment date",
  "Enter shipment date",
  "selectDate&Time"
);

export const transportationSource = getFormFiled(
  "transportationSource",
  "Transportation source",
  "Enter transportation source"
);
export const transportationDestination = getFormFiled(
  "transportationDestination",
  "Transportation destination",
  "Enter transportation destination"
);

export const vehicleNumber = getFormFiled(
  "vehicleNumber",
  "Vehicle number",
  "Enter vehicle number"
);
export const partNumber = getFormFiled(
  "partNumber",
  "Part number",
  "Enter part number"
);

export const partCount = getFormFiled(
  "partCount",
  "Part count",
  "Enter part count"
);

export const freightWeight = getFormFiled(
  "freightWeight",
  "Freight weight",
  "Enter freight weight"
);

export const shipmentId = getFormFiled(
  "shipmentId",
  "Shipment id",
  "Enter shipment id"
);
export const shipperName = getFormFiled(
  "shipperName",
  "Shipper name",
  "Enter shipper name"
);

export const capacity = getFormFiled("capacity", "Capacity", null);
export const fuelType = getFormFiled("fuelType", "Fuel Type", null);
export const manufacture = getFormFiled("manufacture", "Manufacturer", null);
export const co2PerKm = getFormFiled(
  "co2PerKm",
  "Truck category",
  "Select truck category",
  "SingleSelect"
);

export const mileage = getFormFiled("mileage", "Mileage", null, "number");
export const transporterId = getFormFiled(
  "transporterId",
  "Transporter name",
  "Select transporter name",
  "SingleSelect"
);
export const vehicleCategory = getFormFiled(
  "engineType",
  "Truck category",
  "Select truck category",
  "SingleSelect"
);

// Driver
export const drivingLicense = getFormFiled(
  "drivingLicense",
  "Driving license number",
  "Enter driving license number",
  "textWithVerifyButton"
);
export const dob = getFormFiled("dateOfBirth", "Date of Birth", null, "date");

export const brsrSpocId = getFormFiled(
  "name",
  "Username",
  "Enter username"
);
export const brsrSpocEmail = getFormFiled("email", "Email", null);
export const brsrSpocPhone = getFormFiled("phone", "Phone", null);
export const brsrSpocDepartment = getFormFiled(
  "department",
  "Department",
  "Select Department",
  "SingleSelectWithCreate"
);
export const sectionId = getFormFiled(
  "sections",
  "Section name",
  null,
  // "text"

  // SingleSelect
  // multipleSelectWithCheckBox text
);
export const spocsList = getFormFiled(
  "spoclist",
  "Spoc Name",
  null,
  "SingleSelect"
);
export const deadLine = getFormFiled(
  "deadline",
  "ETA",
  "Enter the ETA",
  "date",
  true,
  false,
  () => true,
  "Note: This will change the ETA for all the Forms",
);
export const getAllSPOCS = getFormFiled(
  "spocId",
  "Select user",
  "Enter the SPOC name",
  "SingleSelect"
);

export const department = getFormFiled(
  "department",
  "Department",
  "Select department",
  "SingleSelect"
);

export const financialYear = getFormFiled(
  "financialYear",
  "Financial year",
  "Select financial year",
  "SingleSelect",
  true,
);
export const calendarYear = getFormFiled(
  "financialYear",
  "Calendar year",
  "Select calendar year",
  "SingleSelect",
  true,
);

export const totalRevenue = getFormFiled(
  "totalRevenue",
  "Total revenue in USD",
  "Enter total Revenue in selected financial year",
  "number",
  true,
)

export const clientSKU = getFormFiled(
  "Add SKU",
  "Add SKU",
  "enter sku",
  "multiInput",
)

export const status = getFormFiled(
  "status",
  "status",
  "Select status",
  "SingleSelect",
)

export const insuranceNumber = getFormFiled(
  "insuranceNumber",
  "Insurance number",
  "Enter insurance number",
  "text",
  true,
  false,
  ()=>{
    return true;
  },
  null, 
  6
);

export const companyName = getFormFiled("name","Company name","Enter company name", "text", true, false, 
  () => {
  return true;
},
 null,6);
export const gstIn = getFormFiled("gstin", "GST", "Enter gst number","text",true,true,()=>{return true},null,6);
export const yearOfTurnover = getFormFiled("yearOfTurnover", "Turn over year", "Enter year of turnover","text", false,false,()=>{return true},null,6);
export const yearOfIncorporation = getFormFiled("yearOfIncorporation", "Year of incorporation", "Enter year of incorporation", "text", false,false,()=>{return true},null,6);
export const turnover = getFormFiled("turnover", "Turn over", "Enter year of turnover", "text", false,false,()=>{return true},null,6);
export const ciiMemberShipNo = getFormFiled("ciiMembershipNo", "CII membership number", "Enter CII membership number", "text", false,false,()=>{return true},null,6);
export const registeredAddress1 = getFormFiled("registeredAddress1", "Registered address1", "Enter registered address1", "text", false,false,()=>{return true},null,6);
export const registeredAddress2 = getFormFiled("registeredAddress2", "Registered address2", "Enter registered address1", "text", false,false,()=>{return true},null,6);
export const registeredDistrict = getFormFiled("registeredDistrict", "Registered district", "Enter registered district", "text", false,false,()=>{return true},null,6);
export const registeredState = getFormFiled("registeredState", "Registered state", "Enter registered state", "text",false,false,()=>{return true},null,6);
export const registeredPincode = getFormFiled("registeredPincode", "Registered pincode", "Enter registered pincode", "text", false,false,()=>{return true},null,6);
export const corporateAddress1 = getFormFiled("corporateAddress1", "Corporate address 1", "Enter corporate address 1", "text",false,false,()=>{return true},null,6);
export const corporateAddress2 = getFormFiled("corporateAddress2", "Corporate address 2", "Enter corporate address 2","text",false,false,()=>{return true},null,6);
export const corporateDistrict = getFormFiled("corporateDistrict", "Corporate district", "Enter corporate district", "text", false,false,()=>{return true},null,6);
export const corporateState = getFormFiled("corporateState", "Corporate state", "Enter corporate state", "text", false,false,()=>{return true},null,6);
export const corporatePincode = getFormFiled("corporatePincode", "Corporate pincode","Enter corporate pincode", "text", false,false,()=>{return true},null,6);
export const cinNumber = getFormFiled("cin", "CIN", "Enter CIN number", "text", false,false,()=>{return true},null,6);