// import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
// import { SET_PACKAGING_DETAIL, PACKAGING_DETAIL } from "./actionTypes";
// import Apicall from "../../components/Hook/Api-call";
// import CONSTANT from "../../pages/Utility/constnt";

// function* getPackaging() {
//   try {
//     const res = yield Apicall.get(CONSTANT.API.PACKAGEING.getPackage.endpoint);

//     yield put({ type: SET_PACKAGING_DETAIL, data: res });
//     // debugger;
//   } catch (error) {
//     console.log(error);
//     // yield put({ type: "GET_MY_PROFILE_ERROR", error });
//   }
// }

// function* GetPackagingDetailSaga() {
//   yield takeEvery(PACKAGING_DETAIL, getPackaging);
// }

// export default GetPackagingDetailSaga;

import { delay, put, takeEvery, takeLatest } from "redux-saga/effects";
import { DELETE_PACKAGING_START, GET_PACKAGING_START } from "./actionTypes";
import Apicall from "../../components/Hook/Api-call";
import CONSTANT from "../../pages/Utility/constnt";
import {
  deletePackagingError,
  deletePackagingSucess,
  getPackagingError,
  getPackagingSuccess,
} from "./actions";
import { replaceEndPointId } from "../../pages/Utility/function";

function* getPackaging() {
  try {
    const res = yield Apicall?.get(CONSTANT.API.PACKAGEING.get.endpoint);
    // yield delay(500);
    yield put(getPackagingSuccess(res));
  } catch (error) {
    console.log(error);
    yield put(getPackagingError(error?.message));

    // yield put({ type: GET_CUSTOMER_FAILURE, error });
  }
}

function* deletePackagingSaga(user) {
  try {
    const response = yield Apicall?.remove(
      replaceEndPointId(
        CONSTANT?.API?.PACKAGEING?.delete?.endpoint,
        user?.payload
      )
    );

    if (response?.status == "success") {
      if (user?.successCallback) {
        user?.successCallback();
      }
      // yield delay(2000);
      yield put(deletePackagingSucess(user));
    }
  } catch (error) {
    console.log(error?.message, "responce");
    yield put(deletePackagingError(error?.message));
  }
}

// function* getDriver() {
//   try {
//     const res = yield Apicall.get(CONSTANT.API.PACKAGING.getDriver.endpoint);
//     // const res = "aaaaaaaabb";

//     yield put({ type: GET_PACKAGING_SUCCESS, data: res });
//     // debugger;
//   } catch (error) {
//     console.log(error);
//     // yield put({ type: "GET_MY_PROFILE_ERROR", error });
//   }
// }

function* GetPackagingDetailSaga() {
  yield takeEvery(GET_PACKAGING_START, getPackaging);
  yield takeEvery(DELETE_PACKAGING_START, deletePackagingSaga);
}

export default GetPackagingDetailSaga;
