import React from "react";
import { Col, Progress, Row } from "reactstrap";

import { useDispatch } from "react-redux";

import { StatusButtonDisplay } from "../../../pages/Utility/spread-function";
import { getJobBulkStart } from "../../../store/actions";

const BulkUploadHeaderCardGen = (props) => {
  const { extraValue } = props;

  const dispatch = useDispatch();

  return (
    <>
      <Row>
        <Col className="col-12 OverFlowScroll">
          <div className="card p-4 ">
            <Row>
              <Col className="col-6">
                <div className="d-flex align-items-center mb-4">
                  <>
                    <h4 className="mb-0 fw-bold" style={{ marginRight: "5px" }}>
                      {extraValue?.jobViewData?.length}
                      <span style={{ fontSize: "17px" }}>
                        /{extraValue?.jobSingleData?.totalEntryCount || 0}
                      </span>
                      &nbsp; Entry
                    </h4>
                    <i
                      className="bx bx-sync fs-4 "
                      style={{ color: "#4959e6", cursor: "pointer" }}
                      onClick={() => {
                        dispatch(getJobBulkStart());
                        extraValue?.setFlag((pr) => !pr);
                      }}
                    ></i>
                  </>
                </div>

                <div className="d-flex align-items-center gap-3">
                  <h5 className="m-0 text-success fw-bold">
                    {+extraValue?.succesCount || 0}
                  </h5>
                  {/* #e7e7e7 !important */}
                  <StatusButtonDisplay
                    className="bg-soft-success text-success p-1"
                    textName="Success"
                    onClick={() => {
                      // extraValue.responceStatus = "success";
                      extraValue?.onSuccess();
                      extraValue?.setFlag((pr) => !pr);
                    }}
                  />
                  <h5 className="m-0 text-danger fw-bold">
                    {+extraValue?.failedCount || 0}
                  </h5>

                  <StatusButtonDisplay
                    className="bg-soft-danger text-danger p-1"
                    textName="Failed"
                    onClick={() => {
                      // extraValue.responceStatus = "failed";
                      extraValue?.onFailed();
                      extraValue?.setFlag((pr) => !pr);
                    }}
                  />
                  {extraValue.responceStatus != null ? (
                    <div
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        extraValue.onAll();
                        extraValue?.setFlag((pr) => !pr);
                      }}
                    >
                      <p className="m-0">Clear Filter</p>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </Col>

              <Col className="col-6 d-flex align-items-center justify-content-end">
                <Row>
                  <Col>
                    {extraValue?.jobViewData?.length &&
                    extraValue?.jobSingleData?.totalEntryCount ? (
                      <>
                        <div className=" ">
                          {extraValue?.jobViewData?.length !==
                          extraValue?.jobSingleData?.totalEntryCount ? (
                            <Progress
                              striped
                              animated
                              color="primary"
                              value={
                                extraValue?.jobViewData?.length &&
                                extraValue?.jobSingleData?.totalEntryCount
                                  ? (
                                      (+extraValue?.jobViewData?.length /
                                        +extraValue?.jobSingleData
                                          ?.totalEntryCount) *
                                      100
                                    ).toFixed()
                                  : 0
                              }
                              style={{
                                height: "20px",
                                borderRadius: "10px",
                                width: "300px",
                              }}
                            >
                              {extraValue?.jobViewData?.length &&
                              extraValue?.jobSingleData?.totalEntryCount
                                ? (
                                    (+extraValue?.jobViewData?.length /
                                      +extraValue?.jobSingleData
                                        ?.totalEntryCount) *
                                    100
                                  ).toFixed()
                                : 0}{" "}
                              %
                            </Progress>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {/* {extraValue.responceStatus != null ? (
                      <div
                        style={{
                          position: "absolute",
                          textAlign: "end",
                          top: "36px",
                          right: "0px",
                          width: "75px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          extraValue.onAll();
                          extraValue?.setFlag((pr) => !pr);
                        }}
                      >
                        <p className="m-0">Clear Filter</p>
                      </div>
                    ) : (
                      <></>
                    )} */}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default BulkUploadHeaderCardGen;
