/* USERS */
// export const GET_USERS = "GET_USERS";
// export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
// export const GET_USERS_FAIL = "GET_USERS_FAIL";

// /* USERS PROFILE */
// export const GET_USER_PROFILE = "GET_USER_PROFILE";
// export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
// export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL";

// export const GET_DRIVER_DETAIL_SUCCESS = "GET_DRIVER_DETAIL_SUCCESS";
// export const GET_MY_PROFILE = "GET_MY_PROFILE";

export const TRANSPORTER_DETAIL = "TRANSPORTER_DETAIL";
export const SET_TRANSPORTER_DETAIL = "SET_TRANSPORTER_DETAIL";

export const DELETE_TRANSPORTER_START = "DELETE_TRANSPORTER_START";
export const DELETE_TRANSPORTER_SUCCESS = "DELETE_TRANSPORTER_SUCCESS";
export const DELETE_TRANSPORTER_FAILURE = "DELETE_TRANSPORTER_FAILURE";

export const GET_TRANSPORTER_START = "GET_TRANSPORTER_START";
export const GET_TRANSPORTER_SUCCESS = "GET_TRANSPORTER_SUCCESS";
export const GET_TRANSPORTER_FAILURE = "GET_TRANSPORTER_FAILURE";
