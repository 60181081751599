// import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
// import { SET_ROLEANDACCESS_DETAIL, ROLEANDACCESS_DETAIL } from "./actionTypes";
// import Apicall from "../../components/Hook/Api-call";
// import CONSTANT from "../../pages/Utility/constnt";

// const API_URL = "https://jsonplaceholder.typicode.com/users";

// function* getRoleAndAccess() {
//   try {
//     const res = yield Apicall.get(CONSTANT.API.ROLEANDACCESS.getTeam.endpoint);

//     yield put({ type: SET_ROLEANDACCESS_DETAIL, data: res });
//     // debugger;
//   } catch (error) {
//     console.log(error);
//     // yield put({ type: "GET_MY_PROFILE_ERROR", error });
//   }
// }

// function* GetRoleAndAccessDetailSaga() {
//   yield takeEvery(ROLEANDACCESS_DETAIL, getRoleAndAccess);
// }

// export default GetRoleAndAccessDetailSaga;

import { put, takeLatest } from "redux-saga/effects";
import {
  DELETE_ROLEANDACCESS_START,
  GET_ROLEANDACCESS_START,
} from "./actionTypes";
import Apicall from "../../components/Hook/Api-call";
import CONSTANT from "../../pages/Utility/constnt";
import {
  deleteRoleAndAccessError,
  deleteRoleAndAccessSucess,
  getRoleAndAccessError,
  getRoleAndAccessSuccess,
} from "./actions";
import { replaceEndPointId } from "../../pages/Utility/function";

function* getRoleAndAccess(api = null) {
  try {
    const res = yield Apicall?.get(
      api?.payload != null
        ? api?.payload
        : CONSTANT.API.ROLEANDACCESS.get.endpoint
    );
    yield put(getRoleAndAccessSuccess(res));
  } catch (error) {
    console.log(error);
    yield put(getRoleAndAccessError(error?.message));

    // yield put({ type: GET_CUSTOMER_FAILURE, error });
  }
}

function* deleteRoleAndAccessSaga(user) {
  try {
    const response = yield Apicall?.remove(
      replaceEndPointId(
        CONSTANT?.API?.ROLEANDACCESS?.delete?.endpoint,
        user?.payload
      )
    );

    if (response?.status == "success") {
      if (user?.successCallback) {
        user?.successCallback();
      }
    }
    yield put(deleteRoleAndAccessSucess(user));
  } catch (error) {
    console.log(error?.message, "responce");
    yield put(deleteRoleAndAccessError(error?.message));
  }
}

function* GetRoleAndAccessDetailSaga() {
  yield takeLatest(GET_ROLEANDACCESS_START, getRoleAndAccess);
  yield takeLatest(DELETE_ROLEANDACCESS_START, deleteRoleAndAccessSaga);
}

export default GetRoleAndAccessDetailSaga;
