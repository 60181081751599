import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { Row, Col, Card, Alert, Container } from "reactstrap";
// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import { InputOTP } from "antd-input-otp";
import { Form, Button } from "antd";
// actions
import {
  loginUser,
  apiError,
  socialLogin,
  getUsersDetailSuccess,
} from "../../store/actions";
// import images
import logo from "../../assets/images/logo-sm-dark.png";
import authStorage from "../Utility/API/authStroge";
import CONSTANT from "../Utility/constnt";
import useHttp from "../../components/Hook/Use-http";
import { maskEmail } from "../Utility/spread-function";
import { replaceId } from "../Utility/function";
import { clientId } from "../Utility/models/form-model";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

const Login = (props) => {
  const API_CALL = useHttp();
  const dispatch = useDispatch();
  const myData = useSelector((state) => state.userGetMeData);
  const userRole = localStorage.getItem("userRole");
  const [isRegister, setIsRegister] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [isVerified, setIsVerified] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    const loginData = async () => {
      const isLogin =
        authStorage?.getAuthToken() !== undefined &&
        authStorage?.getAuthToken() !== null;
      if (isLogin && userRole) {
        if (userRole === "client") {
          let clientId;
          let isUserVerified = false;
          let isRegistered = false;
          await API_CALL.sendRequest(CONSTANT.API.ADMIN.getMe, (res) => {
            if (res) {
              setIsVerified(res?.data?.isVerified);
              setIsRegister(res?.data?.client?.isRegistered);
              isUserVerified = res?.data?.isVerified;
              isRegistered = res?.data?.client?.isRegistered;
              clientId = res?.data?.client?.id;
            }
          });
          if (clientId && isUserVerified) { 
            if (!isRegistered) {
              return navigate(`/signup`);
            } else {
              return navigate(`/${userRole}`);
            }
          } else if (clientId && !isUserVerified) {
            console.error("you are not verified!");
          } else {
            console.error("No client exists");
          }
        } else {
          return navigate(`/${userRole}`);
        }
      }
    };
    loginData();
  }, [myData.users]);

  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [resendTimer, setResendTimer] = useState(120);
  const [timer, setTimer] = useState(null);
  const [form] = Form.useForm();
  const [invalidAttempts, setInvalidAttempts] = useState(0);
  const [countdownTimer, setCountdownTimer] = useState(0);
  useEffect(() => {
    if (invalidAttempts > 3) {
      // If the user has made 3 invalid attempts, start a 5-minute countdown
      setCountdownTimer(300);
      const countdownInterval = setInterval(() => {
        setCountdownTimer((prevTimer) => {
          if (prevTimer > 0) {
            return prevTimer - 1;
          } else {
            clearInterval(countdownInterval);
            setInvalidAttempts(0); // Reset invalid attempts after the 5-minute window
            return 0;
          }
        });
      }, 1000);
      return () => {
        clearInterval(countdownInterval);
      };
    }
  }, [invalidAttempts]);

  const startResendTimer = () => {
    // Set a countdown timer for 120 seconds to allow resend OTP
    if (timer) {
      clearInterval(timer);
    }
    let newTimer = setInterval(() => {
      setResendTimer((prevTimer) => {
        if (prevTimer === 1) {
          clearInterval(newTimer);
        }
        return prevTimer - 1;
      });
    }, 1000);
    setTimer(newTimer);
  };

  const sendOTP = async (values) => {
    try {
      // Perform API call to send OTP using the provided email
      const responseHandler = (data) => {
        if (data && data.status === "success") {
          setEmail(values.email);
          setStep(2); // Update step to 2 (verify OTP)
          startResendTimer();
        } else {
          console.error("Failed to send OTP");
        }
      };

      await API_CALL.sendRequest(
        CONSTANT.API.ADMIN.getOTP,
        responseHandler,
        { email: values.email },
        "OTP sent successfully"
      );
    } catch (error) {
      console.error("Failed to send OTP:", error);
    }
  };
  const handleResendOTP = () => {
    if (countdownTimer > 0) {
      // console.log("Please wait for the cooldown period to finish.");
    } else if (resendTimer === 0) {
      // Call sendOTP function again to resend OTP
      sendOTP({ email });
      setResendTimer(120); // Reset the resend timer back to 30 seconds
    }
  };
  const handleEditEmail = () => {
    if (timer) {
      clearInterval(timer);
    }
    form.resetFields(["otp"]);
    setResendTimer(120);
    setCountdownTimer(0);
    setStep(1); // Set step back to 1
  };

  const verifyOTP = (values) => {
    (() => {
      const otpArray = values.otp;
      const otpString = otpArray.join("");
      const otpNumber = parseInt(otpString, 10);
      const payload = {
        email,
        otp: otpNumber,
      };
      API_CALL.sendRequest(
        CONSTANT.API.ADMIN.verifyOTP,
        onSetLoginData,
        payload,
        "Logged in successfully.",
        (error) => {
          if (error && error.startsWith("Uh-oh! Incorrect OTP")) {
            setInvalidAttempts((prevAttempts) => prevAttempts + 1);
          } else if (
            error &&
            error ===
              "Oops! You've run out of attempts, Please try again in 5 minutes"
          ) {
            setInvalidAttempts((prevAttempts) => prevAttempts + 1);
          } else {
            console.error("Failed to verify OTP:", error);
          }
        }
      );
    })();
  };
  const onSetLoginData = (res) => {
    authStorage.setAuthDetails(res?.token);
    localStorage.setItem("authUser", res?.token);
    localStorage.setItem("userRole", res?.userRole);
    localStorage.setItem("userEmail", email);
    // dispatch(getUsersDetailSuccess(res));
    // localStorage.setItem("userName", res?.name);

    // localStorage.setItem("dataU", res);
    // if (res?.userRole !== "tripCreator") {
    // window.history.replaceState(null, null, "/");
    // } else {
    // window.history.replaceState(null, null, "/shipmentForm");
    // }

    window.location.reload();
  };
  const maskedEmail = maskEmail(email);

  return (
    <React.Fragment>
      <div
        className="account-pages pt-sm-5"
        style={{
          padding: "100px 0 100px 0",
          height: "100vh",
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('/Truck_wallpaper.jpg')`,
          backgroundColor: `rgba(0,0,0,0.8)`,
          backgroundPositionX: "right",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <Container style={{ marginTop: "6%" }}>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div className="card overflow-hidden">
                <div className="bg-login text-center">
                  <div className="bg-login-overlay"></div>
                  <div className="position-relative">
                    <h5 className="text-white font-size-20">Welcome back!</h5>
                    <p className="text-white-50 mb-0">
                      Sign in to continue to Fitsol.
                    </p>
                    <Link to="/" className="logo logo-admin mt-4">
                      <img src={logo} alt="" height="30" />
                    </Link>
                  </div>
                </div>
                <div className="card-body pt-5">
                  <div className="p-2">
                    {step === 1 && (
                      <AvForm>
                        <AvField
                          name="email"
                          label="Email"
                          type="email"
                          required
                          // errorMessage="Invalid email"
                          placeholder="Enter email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <button
                          className="btn btn-primary mt-3 w-20 waves-effect waves-light"
                          onClick={() => sendOTP({ email })}
                        >
                          Send OTP
                        </button>
                      </AvForm>
                    )}
                    {step === 2 && (
                      <Form onFinish={verifyOTP} form={form}>
                        <div className="text-center">
                          Please verify OTP sent to
                          <div className="fs-5 fw-bold">{maskedEmail}</div>
                          <button
                            className="btn btn-link"
                            onClick={handleEditEmail}
                            disabled={API_CALL?.isLoading}
                          >
                            Edit email
                          </button>
                        </div>
                        <Form.Item className="text-center" name="otp">
                          <InputOTP
                            autoSubmit={form}
                            inputType="numeric"
                            length={4}
                          />
                        </Form.Item>
                        <Form.Item className="text-center">
                          <Button
                            type=""
                            className="btn btn-primary waves-effect waves-light"
                            htmlType="submit"
                            loading={API_CALL?.isLoading}
                            disabled={countdownTimer > 0}
                          >
                            Verify OTP
                          </Button>
                        </Form.Item>
                        <p className="m-3 text-center">
                          {countdownTimer > 0 ? (
                            `Please wait for ${String(
                              Math.floor(countdownTimer / 60)
                            ).padStart(2, "0")}:${String(
                              countdownTimer % 60
                            ).padStart(2, "0")} before trying again.`
                          ) : (
                            <>
                              <div className="text-center">
                                Didn't receive OTP?{" "}
                              </div>
                              <button
                                className="btn btn-link text-gray font-weight-bold"
                                // style={{color:"gray",fontWeight:"600",}}
                                onClick={handleResendOTP}
                                disabled={countdownTimer > 0 || resendTimer > 0}
                              >
                                {resendTimer === 0
                                  ? "Resend OTP"
                                  : `Resend OTP in ${String(
                                      resendTimer
                                    ).padStart(2, "0")} seconds`}
                              </button>
                            </>
                          )}
                        </p>
                      </Form>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
);

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
};
