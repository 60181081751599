import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

const SpeedoMeterChart = (props) => {
  const { renewableCount, gridCount } = props;
  return (
    <div
      className="bg-white m-2"
      style={{
        height: "349px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Doughnut
        height="100px"
        data={{
          labels: ["Grid", "Renewable"],
          datasets: [
            {
              data: [gridCount, renewableCount],
              backgroundColor: ["#FD6363", "#7CD67A"],
              hoverBackgroundColor: ["#E78573", "#7CD67A"],
            },
          ],
        }}
        plugins={[ChartDataLabels]}
        options={{
          plugins: {
            datalabels: {
              display: true, // Show data labels
              align: "center", // Position labels at the end of the arc
              color: "#000000", // Label text color
              font: {
                size: "16", // Label text size
                weight: "bold", // Label text weight
              },
              formatter: (value, ctx) => {
                if (value == 0){
                  return "";
                }
                const dataset = ctx.chart.data.datasets[0];
                const total = dataset.data.reduce((acc, val) => acc + val, 0);
                const percentage = ((value / total) * 100).toFixed(1);
                return percentage < 5 ? "" : (value).toLocaleString("en-IN", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
              });
              },
            },
          },
          redraw: true,
          rotation: 1 * Math.PI,
          circumference: 1 * Math.PI,
          legend: {
            display: true,
          },
          tooltip: {
            enabled: true,
          },
          cutoutPercentage: 70,
        }}
      />
    </div>
  );
};

export default SpeedoMeterChart;
