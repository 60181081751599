import React from "react";
import { Bar, Doughnut, Pie } from "react-chartjs-2";
import { Row, Col } from "reactstrap";
import ChartDataLabels from "chartjs-plugin-datalabels"

const PieChart = (props) => {
  const dataset = [];
  dataset.push({
    data: props?.chartData?.data,
    backgroundColor: props?.chartData?.colors,
  })
  const legendItems = props.labels.map((label, index) => {
    if(dataset[0].data[index]>0){
      return <React.Fragment key={index} >
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', borderWidth: '1px' }}>
          <div style={{ width: '10px', height: '10px', backgroundColor: dataset[0].backgroundColor[index], borderRadius: '50%', marginRight: '5px' }}></div>
          <span style = {{fontSize: 12}}>{label.name}</span>
        </div>
        
      </React.Fragment>
    }
  });
  return (
    <Row>
      <Col className="bg-white m-2"
      style = {{
        // height:"667px",
        display:"flex",
        alignItems:"center",
        justifyItems:"center"
      }}
      >
      <Pie
        redraw={true}
        plugins={[ChartDataLabels]}
        options={{
          borderRadius: 30,
          responsive: true,
          legend: {
            position:'bottom',
            labels: {
              usePointStyle: true,
              pointStyle: 'circle',
            },
            onClick: function (e, legendItem, legend) {
              return
            }
          },
          plugins: {
            datalabels: {
              display: true, // Show data labels
              align: 'end', // Position labels at the end of the arc
              offset: function(context) {
                const chart = context.chart;
                const width = chart.width;
                return 0; // Adjust offset dynamically based on chart width
              },
              color: '#000000', // Label text color
              font: {
                size: '16', // Label text size
                weight: 'bold' // Label text weight
              },
              formatter: (value, ctx) => { // Format label content
                const dataset = ctx.chart.data.datasets[0];
                const total = dataset.data.reduce((acc, val) => acc + val, 0);
                const percentage = ((value / total) * 100).toFixed(1);
                return percentage<5 ? '': percentage + '%';
                // const percentage = ((value / total) * 100).toFixed(2) + '%';

                // return percentage;
              },
            }
          },
          
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                const dataset = data.datasets[tooltipItem.datasetIndex];
                const total = dataset.data.reduce((acc, val) => acc + val, 0);
                const currentValue = dataset.data[tooltipItem.index];
                const percentage = ((currentValue / total) * 100).toFixed(2);
                return `${data.labels[tooltipItem.index]}: ${percentage}%`;
              },
            },
          },
        }}
        data={{
          labels: props?.labels.map((el)=> (el.name)),
          datasets: dataset,
        }}
      />
      </Col>
      {/* <div style={{ padding: '10px',height:"100%", display: "inline-block"}}>{legendItems}</div> */}
    </Row>
  );
};

export default PieChart;
