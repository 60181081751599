import React, { useEffect, useState } from "react";
import { getPackagingAsset, getRow } from "../pages/Utility/function";
import { Col, Modal, Row } from "reactstrap";
import useHttp from "./Hook/Use-http";

export default function PackagingLocationDetail({
  siteId,
  packageId,
  packageName,
  onClose,
  viewData,
}) {
  const [packegingLog, setPackagingLog] = useState(null);
  let sum = 0;
  const API_CALL = useHttp();

  useEffect(() => {
    if (
      siteId !== null &&
      packageId !== null &&
      siteId !== undefined &&
      packageId !== undefined
    ) {
      const apiLog = {
        type: "GET",
        endpoint: `${localStorage.getItem(
          "userRole"
        )}/plant/getPackageInfoByPlantAndPackagingType/${siteId}/${packageId}`,
      };
      API_CALL.sendRequest(apiLog, (res) => {
        setPackagingLog(getPackagingAsset(res, packageName));
      });
    }
    if (viewData !== null && viewData !== undefined) {
      setPackagingLog(viewData);
    }
  }, [packageId, siteId, viewData]);

  return packegingLog?.plantPackageCountPackageCountAtPlant ? (
    <Modal isOpen={true} style={{ width: "550px" }}>
      <div
        className="modal-header"
        style={{
          fontSize: "16px",
          fontWeight: "900",
          color: "black",
          justifyContent: "center",
        }}
      >
        {packegingLog?.title}
      </div>
      <div className="modal-body">
        <Row
          style={{
            borderBottom: "1px solid #bac1c7",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <Col sm={9}>Location</Col>
          <Col sm={3}>Count</Col>
        </Row>
        {Object?.keys(packegingLog?.plantPackageCountPackageCountAtPlant)?.map(
          (item) => {
            sum += +packegingLog?.plantPackageCountPackageCountAtPlant[item];
            return getRow(
              item,
              packegingLog?.plantPackageCountPackageCountAtPlant[item]
            );
          }
        )}

        {packegingLog?.route?.map((item) => {
          sum += +item?.value;
          return getRow(item?.title, item?.value);
        })}
        <Row
          style={{
            borderTop: "1px solid #bac1c7",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <Col sm={9}>Total</Col>
          <Col sm={3}>{sum}</Col>
        </Row>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            onClose();
            setPackagingLog({});
          }}
          className="btn btn-primary waves-effect"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
    </Modal>
  ) : (
    <></>
  );
}
