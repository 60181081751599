import React, { useState } from "react";
import { Button, Card, CardBody, Col, Label, Progress, Row } from "reactstrap";
import Select, { components } from "react-select";
import { FileUploader } from "react-drag-drop-files";
import SweetAlert from "react-bootstrap-sweetalert";
import useHttp from "../Hook/Use-http";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../pages/Utility/coustemFunction";
import CONSTANT from "../../pages/Utility/constnt";
import { getJobBulkStart } from "../../store/actions";
import { getRecordId, replaceId } from "../../pages/Utility/function";
import { useLocation, useParams } from "react-router-dom";

const FileUploadGen = (props) => {
  const { extraValue } = props;

  const [file, setFile] = useState(null);
  const [errorFileType, setErrorFileType] = useState(false);
  const [disableDrag, setDisableDrag] = useState(false);
  const { id } = useParams();
  const [category, setCategory] = useState(null);

  const [sweet_timer, setSweet_timer] = useState(false);
  const [uploadData, setUploadData] = useState(false);
  const [uploadProcessData, setUploadProcessData] = useState(0);

  const [uploadedJobId, setUploadedJobId] = useState(null);

  const myData = useSelector((state) => state.userGetMeData);
  const dispatch = useDispatch();
  const userRole = localStorage.getItem("userRole");
  const location = useLocation();
  const currentPath = location.pathname;

  const API_CALL = useHttp();

  //BULK===============================================
  const fileHandleChange = (file) => {
    if (category == null) {
      // notify.error("Please Select Category");
      setFile(file);
    } else {
      setErrorFileType(false);
      setFile(file);
      setDisableDrag(true);
    }

    // onClick={() => {

    // }}
  };

  const uploadJob = () => {
    setUploadData(true);
    const formData = new FormData();
    formData.append("clientId", id);
    category?.value !== undefined &&
      formData.append("subCategoryId", category?.value);
    formData.append("uploadedData", file);

    API_CALL.sendRequest(
      CONSTANT.API.JOB.create,
      (res) => {
        setUploadData(false);
        setCategory(null);
        setFile(null);
        setDisableDrag(false);
        setErrorFileType(false);
        setSweet_timer(true);
        setUploadProcessData(0);
        if (userRole == "admin" && currentPath.includes("client")) {
          dispatch(
            getJobBulkStart(
              replaceId(CONSTANT.API.JOB.getClientJob, id)?.endpoint
            )
          );
        } else {
          dispatch(getJobBulkStart());
        }

        extraValue?.setFlag((pr) => !pr);
        setUploadedJobId(getRecordId?.job(res?.data?.id));
      },
      formData,
      null,
      (err) => {
        setUploadProcessData(0);
        setUploadData(false);
      },
      (pro) => {
        setUploadProcessData(pro);
      }
    );
  };
  return (
    <Card style={{ borderRadius: "7px", padding: "0px 10px" }}>
      <CardBody>
        <Row style={{ marginTop: "10px" }}>
          <Col sm={12} md={4}>
            <Row>
              <Col>
                <div className="mb-4">
                  <Label htmlFor="validationCustom01">
                    Category <sub style={{ color: "red" }}>*</sub>
                  </Label>

                  <Select
                    options={extraValue?.subCategory?.map((data) => {
                      return {
                        ...data,
                        label: data?.name,
                        value: data?.id,
                        categoryId: data?.categoryId,
                        categoryName: data?.category?.name,
                      };
                    })}
                    classNamePrefix="select2-selection"
                    name={"Category"}
                    placeholder={"Select Category "}
                    type="SingleSelect"
                    required
                    id={"category"}
                    value={
                      category && {
                        label: category?.label,
                        value: category?.value,
                      }
                    }
                    onChange={(selected) => {
                      setCategory(selected);
                      // setDisableDrag(false);
                    }}
                  />
                  {category == null && file ? (
                    <p
                      className="mb-0 mt-1"
                      style={{ color: "red", fontSize: "12px" }}
                    >
                      Please Select Category
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
                {/* <div className="mb-4">
                  <Label htmlFor="validationCustom01">
                    Scope <sub style={{ color: "red" }}>*</sub>
                  </Label>
                  <input
                    name={"scope"}
                    value={
                      category?.categoryName ? category?.categoryName : null
                    }
                    placeholder={"Select Category"}
                    type={"text"}
                    className="form-control"
                    onChange={() => {}}
                    disabled
                  />
                </div> */}
                {category?.sampleFile && (
                  <div className="mb-4">
                    <Label htmlFor="validationCustom01">Download Sample</Label>
                    <Row className="d-flex align-items-center">
                      <Col sm={12} md={2} className="mr-1">
                        <Button
                          style={{ backgroundColor: "#4959e6" }}
                          onClick={() => {
                            window.open(category?.sampleFile, "download");
                          }}
                        >
                          <i className="mdi mdi-download"></i>
                        </Button>
                      </Col>
                      <Col sm={12} md={10}>
                        <p className="m-0 fs-7">{category?.csv || ""}</p>
                      </Col>
                    </Row>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
          <Col sm={12} md={8}>
            <div className="drang-drop">
              <FileUploader
                handleChange={fileHandleChange}
                name="file"
                types={["CSV"]}
                style={{ height: "100px" }}
                classes="drop_area"
                disabled={disableDrag}
                dropMessageStyle={{
                  backgroundColor: "#c8cdf8",
                  border: "none",
                  outLine: "none",
                }}
                onTypeError={(e) => {
                  console.log(e);
                  setErrorFileType(true);
                }}
                onDrop={(file) => {}}
                children={
                  <div className="main-Container-file-upload">
                    {!disableDrag ? (
                      <>
                        <div style={{ textAlign: "center" }}>
                          <i
                            className="fas fa-upload"
                            style={{
                              color: "#4959e6",
                              fontSize: "60px",
                              marginBottom: "10px",
                              marginTop: "10px",
                            }}
                          ></i>
                        </div>
                        <p
                          style={{
                            textAlign: "center",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          Drop your File here or{" "}
                          <span style={{ color: "#4959e6" }}>browse.</span>
                        </p>
                        <div style={{ textAlign: "center" }}>
                          <Button
                            color="primary"
                            className="btn btn-primary mb-3"
                            data-toggle="button"
                            aria-pressed="false"
                            style={{ fontWeight: "bold" }}
                          >
                            Select File
                          </Button>
                        </div>
                        {errorFileType && (
                          <div className="error-container">
                            <Row>
                              <Col
                                className="col-1"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <i className="bx bx-error-alt size-handler"></i>
                              </Col>
                              <Col
                                className="col-10"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <p
                                  style={{
                                    margin: "0",
                                    fontSize: "11px",

                                    color: "white",
                                  }}
                                >
                                  Selected File are not supported.Please select
                                  CSV File only.
                                </p>
                              </Col>
                            </Row>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="main-select-file">
                        <div className="select-file-name">
                          <p
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: "20px",
                              margin: "0px",
                            }}
                          >
                            {file != null && file?.name}
                          </p>
                          {!uploadData && (
                            <i
                              className="mdi mdi-close-circle-outline"
                              style={{
                                marginLeft: "10px",
                                fontSize: "20px",
                                color: "red",
                              }}
                              onClick={() => {
                                setDisableDrag(false);
                              }}
                            ></i>
                          )}
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {!uploadData ? (
                            <Button
                              color="primary"
                              className="btn btn-primary waves-effect waves-light mb-3 center"
                              data-toggle="button"
                              aria-pressed="false"
                              onClick={uploadJob}
                            >
                              Upload
                            </Button>
                          ) : (
                            <Progress
                              striped
                              animated
                              color="primary"
                              value={uploadProcessData}
                              style={{
                                height: "20px",
                                borderRadius: "10px",
                                width: "300px",
                              }}
                            >
                              {uploadProcessData} %
                            </Progress>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                }
              />
            </div>
            {sweet_timer ? (
              <SweetAlert
                title={`File has been uploaded successfully with request id ${uploadedJobId}!`}
                timeout={4000}
                success
                showConfirm={false}
                onConfirm={() => {
                  setSweet_timer(false);
                }}
              >
                Please wait while we process your data...
              </SweetAlert>
            ) : null}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default FileUploadGen;
