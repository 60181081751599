import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//Calendar
import calendar from "./calendar/reducer";

//chat
import chat from "./chat/reducer";

//contacts
import contacts from "./contacts/reducer";

//tasks
import tasks from "./tasks/reducer";
//getmeData

import userGetMeData from "./getMeData/reducer";
import driverDetailData from "./driverFinal/reducer";
import vehicleDetailData from "./vehicle/reducer";
import transporterDetailData from "./transporter/reducer";
import customerDetailData from "./customer/reducer";
import plantDetailData from "./plant/reducer";
import routeDetailData from "./route/reducer";
import packagingDetailData from "./packaging/reducer";
import roleAndAccessDetailData from "./roleAndAccess/reducer";
import shipmentDetailData from "./trip/reducer";
import adminDashboardDetailData from "./adminDashboard/reducer";
import jobBulkDetailData from "./bulkUploadJob/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  calendar,
  chat,
  tasks,
  contacts,
  userGetMeData,
  driverDetailData,
  vehicleDetailData,
  transporterDetailData,
  customerDetailData,
  plantDetailData,
  routeDetailData,
  packagingDetailData,
  roleAndAccessDetailData,
  shipmentDetailData,
  adminDashboardDetailData,
  jobBulkDetailData,
});

export default rootReducer;
