import React, { useEffect } from "react";

import { isEmpty, map } from "lodash";

//Import Breadcrumb
import Breadcrumb from "../../../components/Common/Breadcrumb";

//Import Task Cards
import UncontrolledBoard from "./UncontrolledBoard";

import "../../../assets/scss/tasks.scss";

import { tasks } from "../../../common/data/tasks";

const TasksKanban = (props) => {
  const data = map(tasks, (task) => ({ ...task, cards: task.tasks }));
  data.length = Math.min(data.length, 3);

  return (
    <React.Fragment>
      <div className="page-content">
        {/* Render Breadcrumbs */}
        <Breadcrumb title="Tasks" breadcrumbItem="Kanban Board" />
        {!isEmpty(data) && <UncontrolledBoard board={{ columns: tasks }} />}
      </div>
    </React.Fragment>
  );
};

export default TasksKanban;
