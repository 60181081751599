import { Button, Card, Col, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import HeaderGen from "../../../components/pcfComponent/headerGen";
import TableFormFields from "../../../components/pcfComponent/tableFormFields";
import CONSTANT, { pcfPackagingDetailTableFields } from "../../Utility/constnt";
import { IoMdClose } from "react-icons/io";
import { packagingTable } from "../../Utility/pcfSpreadFunction";
import useHttp from "../../../components/Hook/Use-http";
import PcfServices from "../../Utility/API/pcfService";
import {
  notifications,
  optionArrayWithSort,
  replaceId,
} from "../../Utility/function";
import Loader from "../../../components/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import FooterGen from "../../../components/pcfComponent/footerGen";

const PackagingTab = (props) => {
  const {
    tabIndex = null,
    setMainProductData,
    setTabIndex,
    mainProductData,
  } = props;
  const [tableAdd, setTableAdd] = useState([]);
  const [tableHeader, setTableHeader] = useState(true);
  const [formMainData, setFormMainData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [transportModeData, setTransportModeData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const containerRef = useRef();
  const API = useHttp();

  const checkLayout = () => {
    const totalGapWidth = 8;

    let totalWidth = 0;
    const rowZeroElement = document.getElementById("row_0");

    totalWidth = tableAdd[0]?.data?.reduce((sum, el, i) => {
      const tempDiv = document.getElementById("col-0-" + i);
      const tempWidth = tempDiv?.offsetWidth || 0;

      return sum + tempWidth;
    }, 0);

    setTableHeader(rowZeroElement?.offsetWidth >= totalWidth + totalGapWidth);
    setIsLoading(false);
  };

  useEffect(() => {
    setTimeout(() => {
      checkLayout();
      window.addEventListener("resize", checkLayout);
      return () => {
        window.removeEventListener("resize", checkLayout);
      };
    }, 1000);
  }, [tableAdd]);

  useEffect(() => {
    Object?.keys(mainProductData)?.length == 0 &&
      !mainProductData?.id &&
      API.getAll(
        [
          CONSTANT.API.PCFPRODUCTCATEGORY.getForPackaging.endpoint,
          CONSTANT.API.PCFPRODUCTEMISSIONSOURCE.getTranportMode.endpoint,
        ],
        (res) => {
          setCategoryData(res?.[0]?.data?.data?.rows);
          setTransportModeData(res?.[1]?.data?.data?.rows);
          setTableAdd([
            packagingTable({
              productCategoryId: {
                option: optionArrayWithSort(res?.[0]?.data?.data?.rows),
              },
              transportationModeId: {
                option: optionArrayWithSort(res?.[1]?.data?.data?.rows),
              },
            }),
          ]);
        },
        PcfServices
      );
  }, []);

  //update
  useEffect(() => {
    Object?.keys(mainProductData)?.length != 0 &&
      mainProductData?.id &&
      API.getAll(
        [
          CONSTANT.API.PCFPRODUCTCATEGORY.getForPackaging.endpoint,
          CONSTANT.API.PCFPRODUCTEMISSIONSOURCE.getTranportMode.endpoint,
          replaceId(
            CONSTANT.API.PCFPRODUCTPACKAGING.getProductWise,
            mainProductData?.id
          ).endpoint + "&sortBy=id&sortOrder=ASC",
          CONSTANT.API.PCFPRODUCTQUALITY.get.endpoint,
          CONSTANT.API.PCFPRODUCTSUBCATEGORY.get.endpoint,
        ],
        (res) => {
          setCategoryData(res?.[0]?.data?.data?.rows);
          setTransportModeData(res?.[1]?.data?.data?.rows);
          const tableRowData = [];
          if (res?.[2]?.data?.data?.rows?.length != 0) {
            res?.[2]?.data?.data?.rows
              ?.sort((a, b) => a.id - b.id)
              ?.map((ell) => {
                tableRowData.push(
                  packagingTable({
                    isNew: false,
                    recordId: ell?.id,
                    name: ell?.name,
                    weight: ell?.weight,
                    supplierName: ell?.supplierName,
                    // supplierLocation: ell?.supplierLocation,
                    productCategoryId: {
                      option: optionArrayWithSort(res?.[0]?.data?.data?.rows),
                      value: ell?.productCategory?.id,
                    },

                    productSubCategoryId: {
                      option: optionArrayWithSort(
                        res?.[4]?.data?.data?.rows?.filter(
                          (ells) =>
                            ells?.productCategory?.id ==
                            ell?.productCategory?.id
                        )
                      ),
                      value: ell?.productSubCategory?.id,
                    },
                    productQualityId: {
                      option: optionArrayWithSort(
                        res?.[3]?.data?.data?.rows?.filter(
                          (ells) =>
                            ells?.productSubCategory?.id ==
                            ell?.productSubCategory?.id
                        )
                      ),
                      value: ell?.productQuality?.id,
                    },
                    supplierLocation: {
                      value: ell?.supplierLocation,
                      latlog: {
                        supplierLatitude: ell?.supplierLatitude,
                        supplierLongitude: ell?.supplierLongitude,
                      },
                    },
                    transportationModeId: {
                      option: optionArrayWithSort(res?.[1]?.data?.data?.rows),
                      value: ell?.transportationMode?.id,
                    },
                  })
                );
              });

            setTableAdd(tableRowData);
          } else {
            setTableAdd([
              packagingTable({
                productCategoryId: {
                  option: optionArrayWithSort(res?.[0]?.data?.data?.rows),
                },
                transportationModeId: {
                  option: optionArrayWithSort(res?.[1]?.data?.data?.rows),
                },
              }),
            ]);
          }
        },
        PcfServices
      );
  }, [mainProductData]);

  const getPackageSubCategory = (packageIndex, elementIndex, categoryId) => {
    API.getAll(
      [
        replaceId(
          CONSTANT.API.PCFPRODUCTSUBCATEGORY.getSubCategoryFilterByCategory,
          categoryId
        ).endpoint,
      ],
      (res) => {
        setTableAdd((prev) => {
          const prevData = [...prev];
          const currentPart = prevData?.[packageIndex];
          currentPart.data[elementIndex].option = optionArrayWithSort(
            res?.[0]?.data?.data?.rows
          );
          currentPart.data[elementIndex].isLoading = false;
          return prevData;
        });
      },
      PcfServices
    );
  };

  const getPackageQuality = (packageIndex, elementIndex, subCategoryId) => {
    API.getAll(
      [
        replaceId(
          CONSTANT.API.PCFPRODUCTQUALITY.getQualityFilterBySubCategory,
          subCategoryId
        ).endpoint,
      ],
      (res) => {
        setTableAdd((prev) => {
          const prevData = [...prev];
          const currentPart = prevData?.[packageIndex];

          currentPart.data[elementIndex].option = optionArrayWithSort(
            res?.[0]?.data?.data?.rows
          );
          currentPart.data[elementIndex].isLoading = false;
          return prevData;
        });
      },
      PcfServices
    );
  };

  const addNewPackage = () => {
    setTableAdd((prev) => {
      const prevData = [...prev];

      prevData?.push(
        packagingTable({
          productCategoryId: {
            option: optionArrayWithSort(categoryData),
          },
          transportationModeId: {
            option: optionArrayWithSort(transportModeData),
          },
        })
      );
      return prevData;
    });
  };
  const deletePackage = (packageIndex) => {
    setTableAdd((prev) => {
      const currentPart = prev?.[packageIndex];

      if (currentPart?.recordId != -1) {
        //TO DO API CALL DELETE NI
        API.sendRequest(
          replaceId(
            CONSTANT.API.PCFPRODUCTPACKAGING.delete,
            currentPart?.recordId
          ),
          () => {},
          null,
          "Deleted successfully",
          null,
          null,
          PcfServices
        );
      }
      if (prev?.length == 1) {
        return [
          packagingTable({
            productCategoryId: {
              option: optionArrayWithSort(categoryData),
            },
            transportationModeId: {
              option: optionArrayWithSort(transportModeData),
            },
          }),
        ];
      }
      return prev?.filter((el, index) => packageIndex != index);
    });
  };

  const onPackageValueChange = (
    packageIndex,
    elementIndex,
    value,
    latitude,
    logtitude
  ) => {
    setTableAdd((prev) => {
      const prevData = [...prev];
      const currentPart = prevData?.[packageIndex];

      if (latitude && logtitude) {
        const keyarr = Object?.keys(currentPart.data[elementIndex].latlog);
        currentPart.data[elementIndex].latlog[keyarr[0]] = latitude;
        currentPart.data[elementIndex].latlog[keyarr[1]] = logtitude;
        currentPart.data[elementIndex].value = value;
      } else {
        currentPart.data[elementIndex].value = value;
      }
      // currentPart.data[elementIndex].value = value;
      switch (elementIndex) {
        case 1:
          currentPart.data[elementIndex + 1].isLoading = true;
          currentPart.data[elementIndex + 1].value = null;
          currentPart.data[elementIndex + 1].option = [];
          currentPart.data[elementIndex + 2].value = null;
          currentPart.data[elementIndex + 2].option = [];
          getPackageSubCategory(packageIndex, elementIndex + 1, value);
          break;
        case 2:
          currentPart.data[elementIndex + 1].isLoading = true;
          currentPart.data[elementIndex + 1].value = null;
          currentPart.data[elementIndex + 1].option = [];
          getPackageQuality(packageIndex, elementIndex + 1, value);
          break;

        default:
          return prevData;
      }
      return prevData;
    });
  };

  const previewButton = () => {
    navigate(`${currentPath}?tab=parts&productid=${mainProductData?.id}`);
    setTabIndex("2");
  };

  const continueButton = () => {
    const finalPayload = {
      data: {
        addPackaging: [],
        updatePackaging: [],
      },
    };
    const payload = {};
    setTableAdd((prevtableData) => {
      let isError = false;

      prevtableData?.forEach((elll) => {
        let partObj = {};
        elll?.data?.map((el) => {
          if (el?.required) {
            if (el?.value && el?.value != "") {
              partObj[el?.id] = el?.value;
              if (el?.latlog) {
                const keyarr = Object?.keys(el?.latlog);

                partObj[keyarr[0]] = el?.latlog[keyarr[0]];
                partObj[keyarr[1]] = el?.latlog[keyarr[1]];
              }
            } else {
              el.status = el?.validate(el?.value);
              if (!isError && el?.validate(el?.value) == "error") {
                isError = true;
              }
            }
          } else {
            if (el?.value && el?.value != "") {
              partObj[el?.id] = el?.value;
              if (el?.latlog) {
                const keyarr = Object?.keys(el?.latlog);

                partObj[keyarr[0]] = el?.latlog[keyarr[0]];
                partObj[keyarr[1]] = el?.latlog[keyarr[1]];
              }
            }
          }
        });

        if (elll?.isNew) {
          if (
            finalPayload &&
            finalPayload.data &&
            finalPayload.data.addPackaging
          ) {
            partObj["productId"] = mainProductData?.id;
            finalPayload.data.addPackaging.push(partObj);
          } else {
            // add partObj materialId here  like partObj.id = materialId
            if (!elll?.isNew && elll?.recordId != -1) {
              partObj["id"] = elll?.recordId;
            }
            finalPayload.data.updatePackaging.push(partObj);
          }
        } else {
          // add partObj materialId here  like partObj.id = materialId
          if (!elll?.isNew && elll?.recordId != -1) {
            partObj["id"] = elll?.recordId;
          }
          finalPayload.data.updatePackaging.push(partObj);
        }
        finalPayload["productId"] = mainProductData?.id;
      });
      isError && notifications.error("Please fill up the required fields");
      if (!isError) {
        //add
        API.sendRequest(
          CONSTANT.API.PCFPRODUCTPACKAGING.createBulkUpload,
          (res) => {
            setTabIndex("4");
            navigate(
              `${currentPath}?tab=energyAndTransport&productid=${mainProductData?.id}`
            );

            // setMainProductData(res?.data);
          },
          finalPayload,
          "Packaging successfully added",
          null,
          null,
          PcfServices
        );
      }
      return [...prevtableData];
    });
  };

  return (
    <>
      {isLoading ? (
        <div xs={24} className=" w-full">
          <Loader />
        </div>
      ) : (
        <Card style={{ width: "100%" }}>
          <Row className="responsive-table">
            <>
              {" "}
              <Col md={24} className={`table-Header text-uppercase`}>
                <Row
                  className={`${!tableHeader && "d-none"}  p-3`}
                  gutter={[8, 8]}
                >
                  <HeaderGen tableFieldList={tableAdd[0]?.data} />
                </Row>
              </Col>
              <Col md={24} ref={containerRef}>
                {tableAdd?.map((tableRow, index) => (
                  <Row
                    key={`row_${index}`}
                    id={`row_${index}`}
                    className="p-2 w-full"
                    gutter={[8, 8]}
                    style={{
                      borderTop: `${true ? "1px solid #dbdbdb" : ""}`,
                      position: "relative",
                    }}
                  >
                    <TableFormFields
                      formFields={tableRow?.data}
                      rowIndex={index}
                      tableHeader={tableHeader}
                      changedFields={(
                        eleIndex,
                        fieldData,
                        value,
                        multipleIndex,
                        latitude,
                        logtitude
                      ) => {
                        onPackageValueChange(
                          index,
                          eleIndex,
                          value,
                          latitude,
                          logtitude
                        );
                      }}
                      multipleTableList={null}
                    />

                    <Col className="close-btn-position-set ">
                      <div
                        className="child-table-field-show"
                        onClick={() => {
                          deletePackage(index);
                        }}
                      >
                        <IoMdClose />
                      </div>
                    </Col>
                  </Row>
                ))}
              </Col>
              {/* multiple part Added */}
              <Col md={24} className="table-border-top table-border-bottom">
                <Row className="gap-4 p-3">
                  {/* multiple table with multiple fields */}
                  <Col md={24} className="d-flex justify-content-between">
                    <Button
                      className="btn btn-primary waves-effect waves-light"
                      onClick={() => {
                        addNewPackage();
                      }}
                    >
                      Add Piece +
                    </Button>
                  </Col>
                </Row>
              </Col>
              {/* footer for continue or previous */}
              <Col xs={24} className="">
                <FooterGen
                  previewButton={previewButton}
                  continueButton={continueButton}
                />
              </Col>
            </>
          </Row>
        </Card>
      )}
    </>
  );
};

export default PackagingTab;
