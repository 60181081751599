"use client";
import React, { useEffect, useState } from "react";
import { Button, Select, Modal, Input, Checkbox } from "antd";
// import "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
// import "chartjs-plugin-datalabels";
import EmissionGen from "./EmissionGen";
import CONSTANT, {
  doughnutBRSROuterColors,
  doughnutBRSROuterLabels,
} from "../../../Utility/constnt";
import useHttp from "../../../../components/Hook/Use-http";
import { useParams } from "react-router-dom";
Chart.plugins.register(ChartDataLabels);
const ESGReport = () => {
  const { id } = useParams();
  const API_CALL = useHttp();
  const userRole = localStorage.getItem("userRole");
  const [responseData, setResponseData] = useState({});
  const [esgScore, setEsgScore] = useState([]);
  const [envScore, setEnvScore] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [socialScore, setSocialScore] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [govScore, setGovScore] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [totalData, setTotalData] = useState([]);
  const [outData, setOutData] = useState([]);
  const [resData, setResData] = useState({});
  const [totalScore, setTotalScore] = useState(0);
  const [eScore, setEScore] = useState(0);
  const [sScore, setSScore] = useState(0);
  const [gScore, setGScore] = useState(0);
  const [scoreCardData, setScoreCardData] = useState({});

  useEffect(() => {
    let brsrendpoint = { ...CONSTANT.API.BRSRSCORE.get };
    brsrendpoint.endpoint =
      userRole == "admin"
        ? `${brsrendpoint.endpoint}/${id}`
        : `${brsrendpoint.endpoint}`;
    API_CALL.getAll([brsrendpoint.endpoint], (res) => {
      let data = res[0]?.data;
      if (id == "508") {
        data = {
          "S": [
              {
                  "id": 2,
                  "score": 0,
                  "explanation": "No score as per the guidelines",
                  "maxScore": 0,
                  "section": "A_IV",
                  "questionNo": "21",
                  "esg": "S"
              },
              {
                  "id": 3,
                  "score": 0,
                  "explanation": "Turnover rate for permanent employees is not mentioned in the response.",
                  "maxScore": 2,
                  "section": "A_IV",
                  "questionNo": "22",
                  "esg": "S"
              },
              {
                  "id": 5,
                  "score": 5,
                  "explanation": "2 for engaging women employees > 25% of total employees, 3 for engaging differently abled employees >5% of total employees",
                  "maxScore": 8,
                  "section": "A_IV",
                  "questionNo": "20",
                  "esg": "S"
              },
              {
                  "id": 34,
                  "score": 1,
                  "explanation": "1 for Reported",
                  "maxScore": 1,
                  "section": "C_P9_L",
                  "questionNo": "1",
                  "esg": "S"
              },
              {
                  "id": 35,
                  "score": 1,
                  "explanation": "1 for steps taken",
                  "maxScore": 1,
                  "section": "C_P9_L",
                  "questionNo": "2",
                  "esg": "S"
              },
              {
                  "id": 36,
                  "score": 0,
                  "explanation": "No/Not Reported",
                  "maxScore": 1,
                  "section": "C_P9_L",
                  "questionNo": "3",
                  "esg": "S"
              },
              {
                  "id": 37,
                  "score": 0,
                  "explanation": "No clear answer provided by the company in the response.",
                  "maxScore": 2,
                  "section": "C_P9_L",
                  "questionNo": "4",
                  "esg": "S"
              },
              {
                  "id": 66,
                  "score": 5,
                  "explanation": "3 for covering Directors, Key Managerial Personnel, employees and others, 2 for coverage between 50-60%",
                  "maxScore": 8,
                  "section": "C_P1_E",
                  "questionNo": "1",
                  "esg": "S"
              },
              {
                  "id": 227,
                  "score": 0,
                  "explanation": "No score as per the provided scoring guidelines.",
                  "maxScore": 0,
                  "section": "C_P3_E",
                  "questionNo": "2",
                  "esg": "S"
              },
              {
                  "id": 228,
                  "score": 0,
                  "explanation": "0 as the response is not clear",
                  "maxScore": 1,
                  "section": "C_P3_E",
                  "questionNo": "5",
                  "esg": "S"
              }
          ],
          "N/A": [
              {
                  "id": 4,
                  "score": 0,
                  "explanation": "No score as per the guidelines",
                  "maxScore": 0,
                  "section": "A_II",
                  "questionNo": "16",
                  "esg": ""
              },
              {
                  "id": 17,
                  "score": 0,
                  "explanation": "No score as per the guidelines",
                  "maxScore": 0,
                  "section": "A_III",
                  "questionNo": "18",
                  "esg": ""
              },
              {
                  "id": 18,
                  "score": 0,
                  "explanation": "No score as per the guidelines",
                  "maxScore": 0,
                  "section": "A_III",
                  "questionNo": "19_a",
                  "esg": ""
              },
              {
                  "id": 19,
                  "score": 0,
                  "explanation": "No response provided by the company.",
                  "maxScore": 0,
                  "section": "A_III",
                  "questionNo": "19_b",
                  "esg": ""
              },
              {
                  "id": 20,
                  "score": 0,
                  "explanation": "No score as per the guidelines",
                  "maxScore": 0,
                  "section": "A_III",
                  "questionNo": "19_c",
                  "esg": ""
              },
              {
                  "id": 21,
                  "score": 0,
                  "explanation": "No score as per the provided guidelines.",
                  "maxScore": 0,
                  "section": "A_I",
                  "questionNo": "1",
                  "esg": ""
              },
              {
                  "id": 22,
                  "score": 0,
                  "explanation": "No score as per the provided guidelines.",
                  "maxScore": 0,
                  "section": "A_I",
                  "questionNo": "2",
                  "esg": ""
              },
              {
                  "id": 23,
                  "score": 0,
                  "explanation": "No score as per the provided guidelines.",
                  "maxScore": 0,
                  "section": "A_I",
                  "questionNo": "3",
                  "esg": ""
              },
              {
                  "id": 24,
                  "score": 0,
                  "explanation": "No score as per the provided guidelines",
                  "maxScore": 0,
                  "section": "A_I",
                  "questionNo": "4",
                  "esg": ""
              },
              {
                  "id": 25,
                  "score": 0,
                  "explanation": "No score as per the guidelines",
                  "maxScore": 0,
                  "section": "A_I",
                  "questionNo": "5",
                  "esg": ""
              },
              {
                  "id": 26,
                  "score": 0,
                  "explanation": "No score as per the guidelines",
                  "maxScore": 0,
                  "section": "A_I",
                  "questionNo": "6",
                  "esg": ""
              },
              {
                  "id": 27,
                  "score": 0,
                  "explanation": "No score as per the provided guidelines.",
                  "maxScore": 0,
                  "section": "A_I",
                  "questionNo": "7",
                  "esg": ""
              },
              {
                  "id": 28,
                  "score": 0,
                  "explanation": "No score as per the provided guidelines.",
                  "maxScore": 0,
                  "section": "A_I",
                  "questionNo": "8",
                  "esg": ""
              },
              {
                  "id": 29,
                  "score": 0,
                  "explanation": "No score as per the guidelines",
                  "maxScore": 0,
                  "section": "A_I",
                  "questionNo": "9",
                  "esg": ""
              },
              {
                  "id": 30,
                  "score": 0,
                  "explanation": "No score as per the provided guidelines.",
                  "maxScore": 0,
                  "section": "A_I",
                  "questionNo": "10",
                  "esg": ""
              },
              {
                  "id": 31,
                  "score": 0,
                  "explanation": "No score as per the provided scoring guidelines.",
                  "maxScore": 0,
                  "section": "A_I",
                  "questionNo": "12",
                  "esg": ""
              },
              {
                  "id": 32,
                  "score": 0,
                  "explanation": "No score as per the provided scoring guidelines.",
                  "maxScore": 0,
                  "section": "A_I",
                  "questionNo": "11",
                  "esg": ""
              },
              {
                  "id": 33,
                  "score": 0,
                  "explanation": "No score as per the provided scoring guidelines.",
                  "maxScore": 0,
                  "section": "A_I",
                  "questionNo": "13",
                  "esg": ""
              },
              {
                  "id": 61,
                  "score": 0,
                  "explanation": "No relevant information provided.",
                  "maxScore": 0,
                  "section": "C_P2_E",
                  "questionNo": "3_a",
                  "esg": ""
              },
              {
                  "id": 62,
                  "score": 0,
                  "explanation": "No relevant information provided.",
                  "maxScore": 0,
                  "section": "C_P2_E",
                  "questionNo": "3_b",
                  "esg": ""
              },
              {
                  "id": 63,
                  "score": 0,
                  "explanation": "No information provided in the response to explain the processes in place for safe reclamation, reuse, recycling, or disposal of hazardous waste.",
                  "maxScore": 0,
                  "section": "C_P2_E",
                  "questionNo": "3_c",
                  "esg": ""
              },
              {
                  "id": 64,
                  "score": 0,
                  "explanation": "No information provided in the response to explain the processes in place for safe reclamation, reusing, recycling, and disposal of products.",
                  "maxScore": 0,
                  "section": "C_P2_E",
                  "questionNo": "3_d",
                  "esg": ""
              },
              {
                  "id": 90,
                  "score": 0,
                  "explanation": "No guidelines provided",
                  "maxScore": 0,
                  "section": "C_P3_L",
                  "questionNo": "1_b",
                  "esg": ""
              },
              {
                  "id": 161,
                  "score": 0,
                  "explanation": "No score as per the guidelines",
                  "maxScore": 0,
                  "section": "A_II",
                  "questionNo": "17",
                  "esg": ""
              }
          ],
          "G": [
              {
                  "id": 55,
                  "score": 1,
                  "explanation": "Reported details on the assessment of value chain partners.",
                  "maxScore": 1,
                  "section": "C_P3_L",
                  "questionNo": "5",
                  "esg": "G"
              },
              {
                  "id": 56,
                  "score": 0,
                  "explanation": "No clear information on the number of value chain partners and principles covered.",
                  "maxScore": 3,
                  "section": "C_P1_L",
                  "questionNo": "1",
                  "esg": "G"
              },
              {
                  "id": 57,
                  "score": 0,
                  "explanation": "No clear information provided about the process to avoid or manage conflict of interests.",
                  "maxScore": 2,
                  "section": "C_P1_L",
                  "questionNo": "2",
                  "esg": "G"
              },
              {
                  "id": 67,
                  "score": 0,
                  "explanation": "0 for Not Reported",
                  "maxScore": 3,
                  "section": "C_P1_E",
                  "questionNo": "2",
                  "esg": "G"
              },
              {
                  "id": 68,
                  "score": 0,
                  "explanation": "0 as no details of appeal or revision preferred are provided",
                  "maxScore": 1,
                  "section": "C_P1_E",
                  "questionNo": "3",
                  "esg": "G"
              },
              {
                  "id": 69,
                  "score": 3,
                  "explanation": "3 for Existence of Policy and Reported",
                  "maxScore": 3,
                  "section": "C_P1_E",
                  "questionNo": "4",
                  "esg": "G"
              },
              {
                  "id": 70,
                  "score": 0,
                  "explanation": "0 for Not Reported",
                  "maxScore": 1,
                  "section": "C_P1_E",
                  "questionNo": "5",
                  "esg": "G"
              },
              {
                  "id": 71,
                  "score": 0,
                  "explanation": "0 for Not Reported",
                  "maxScore": 1,
                  "section": "C_P1_E",
                  "questionNo": "6",
                  "esg": "G"
              },
              {
                  "id": 72,
                  "score": 0,
                  "explanation": "0 if Not Reported",
                  "maxScore": 2,
                  "section": "C_P1_E",
                  "questionNo": "7",
                  "esg": "G"
              }
          ],
          "E": [
              {
                  "id": 58,
                  "score": 1,
                  "explanation": "1 for < 10% of R&D",
                  "maxScore": 5,
                  "section": "C_P2_E",
                  "questionNo": "1",
                  "esg": "E"
              },
              {
                  "id": 59,
                  "score": 0,
                  "explanation": "0 as the response does not clearly state the presence of procedures for sustainable sourcing",
                  "maxScore": 1,
                  "section": "C_P2_E",
                  "questionNo": "2_a",
                  "esg": "E"
              },
              {
                  "id": 60,
                  "score": 0,
                  "explanation": "0 for Not Reported",
                  "maxScore": 5,
                  "section": "C_P2_E",
                  "questionNo": "2_b",
                  "esg": "E"
              },
              {
                  "id": 65,
                  "score": 3,
                  "explanation": "3 for Availability of EPR, waste collection plan and Mitigation steps taken",
                  "maxScore": 3,
                  "section": "C_P2_E",
                  "questionNo": "4",
                  "esg": "E"
              },
              {
                  "id": 85,
                  "score": 0,
                  "explanation": "No external agency was involved, and the results are not in the public domain.",
                  "maxScore": 3,
                  "section": "C_P2_L",
                  "questionNo": "1",
                  "esg": "E"
              },
              {
                  "id": 86,
                  "score": 0,
                  "explanation": "No clear information about the percentage of recycled or reused input material. The response mentions RecycledOrReUsedInPutMaterialToTotalMaterial, but it is not clear if this refers to the percentage value or some other data.",
                  "maxScore": 4,
                  "section": "C_P2_L",
                  "questionNo": "3",
                  "esg": "E"
              },
              {
                  "id": 87,
                  "score": 0,
                  "explanation": "No clear information provided about the percentage of products covered or the turnover contribution. The response mentions Percentage of total turnover contributed for conducted lifecycle perspective but does not provide the actual value or percentage.",
                  "maxScore": 3,
                  "section": "C_P2_L",
                  "questionNo": "2",
                  "esg": "E"
              },
              {
                  "id": 88,
                  "score": 1,
                  "explanation": "1 for Reported",
                  "maxScore": 1,
                  "section": "C_P2_L",
                  "questionNo": "4",
                  "esg": "E"
              },
              {
                  "id": 89,
                  "score": 0,
                  "explanation": "No information about the reclaimed products and their packaging materials is provided in the response.",
                  "maxScore": 1,
                  "section": "C_P2_L",
                  "questionNo": "5",
                  "esg": "E"
              }
          ]
      }
      }
      setResData(data);
      let overallScore = 0;
      let eScore = 0,
        sScore = 0,
        gScore = 0;
      const scoreUpdate = async (obj, index) => {
        if (obj?.esg == "S") {
          sScore += obj?.score;
          setSocialScore((prevScores) => {
            const newScores = [...prevScores];
            newScores[index] += obj?.score;
            return newScores;
          });
        } else if (obj?.esg == "E") {
          eScore += obj?.score;
          setEnvScore((prevScores) => {
            const newScores = [...prevScores];
            newScores[index] += obj?.score;
            return newScores;
          });
        } else if (obj?.esg == "G") {
          gScore += obj?.score;
          setGovScore((prevScores) => {
            const newScores = [...prevScores];
            newScores[index] += obj?.score;
            return newScores;
          });
        }
      };
      Object.keys(data).map((item) => {
        let arr = data[item];
        for (const it of arr) {
          let obj = it;
          overallScore += obj?.score;
          if (obj?.section?.startsWith("A")) {
            scoreUpdate(obj, 0);
          } else if (obj?.section?.startsWith("B")) {
            scoreUpdate(obj, 1);
          } else if (obj?.section?.startsWith("C_P1")) {
            scoreUpdate(obj, 2);
          } else if (obj?.section?.startsWith("C_P2")) {
            scoreUpdate(obj, 3);
          } else if (obj?.section?.startsWith("C_P3")) {
            scoreUpdate(obj, 4);
          } else if (obj?.section?.startsWith("C_P4")) {
            scoreUpdate(obj, 5);
          } else if (obj?.section?.startsWith("C_P5")) {
            scoreUpdate(obj, 6);
          } else if (obj?.section?.startsWith("C_P6")) {
            scoreUpdate(obj, 7);
          } else if (obj?.section?.startsWith("C_P7")) {
            scoreUpdate(obj, 8);
          } else if (obj?.section?.startsWith("C_P8")) {
            scoreUpdate(obj, 9);
          } else if (obj?.section?.startsWith("C_P9")) {
            scoreUpdate(obj, 10);
          }
        }
      });
      setTotalScore(overallScore);
      setEScore(eScore);
      setSScore(sScore);
      setGScore(gScore);
    });
  }, []);

  useEffect(() => {
    let arr = [];
    for (const item of envScore) {
      arr.push(item);
    }
    for (const item of socialScore) {
      arr.push(item);
    }
    for (const item of govScore) {
      arr.push(item);
    }
    setTotalData(arr);
  }, [socialScore, envScore, govScore]);
  const donoughtData = {
    datasets: [
      {
        data: totalData,
        backgroundColor: doughnutBRSROuterColors,
        labels: doughnutBRSROuterLabels,
        label: "Detailed Sections",
      },
      {
        data: [eScore, sScore, gScore],
        backgroundColor: ["#C3FF93", "#A0DEFF", "#7ABA78"],
        labels: ["Environmental", "Social", "Governance"],
        label: "ESG Categories",
      },
    ],
    text: "23%",
  };
  const doughnutOptions = {
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const index = tooltipItem.index;
          return dataset.labels
            ? `${dataset.labels[index]}:${dataset.data[index]}`
            : `Value: ${dataset.data[index]}`;
        },
      },
    },
    plugins: {
      datalabels: {
        display: true,
        color: "black",
        align: "center",
        offset: -10,
        font: {
          size: 12,
          weight: 600,
        },
        rotation: (ctx) => {
          if (ctx?.dataset?.labels.length == 3) {
            let sum = 0;
            for (const item of ctx?.dataset?.data) {
              sum += parseInt(item);
            }
            let val = ctx?.dataset?.data[ctx?.dataIndex] / 2;
            for (let i = 0; i < ctx.dataIndex; i++) {
              val += parseInt(ctx?.dataset?.data[i]);
            }
            return (val / sum) * 360;
          } else {
            return 0;
          }
        },
        borderRadius: 25,
        formatter: (value, ctx) => {
          if (value == 0) {
            return "";
          }
          const space = ctx?.dataset?.labels[ctx.dataIndex].length;
          let spacestr = "";
          for (let i = 0; i < space / 2; i++) {
            spacestr += " ";
          }
          if (ctx?.dataset?.labels.length == 3) {
            return `${ctx?.dataset?.labels[ctx.dataIndex]}`;
          }
          return `${value}`;
          // return value >= 90
          //   ? `${ctx?.dataset?.labels[ctx.dataIndex]}`
          //   : `${value}`;
        },
        // formatter: function (value, ctx) {
        //   return value === 0 ? "" : value;
        // },
      },
    },
  };
  //   plugin score to be enabled
  const plugins = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 280).toFixed(2);
        ctx.font = `500 ${fontSize}em Poppins`;
        ctx.textBaseline = "top";
        //     ctx.textAlign = 'center';
        //     ctx.textBaseline = 'middle';
        // ctx.fillStyle = "red";
        ctx.fillStyle = "rgba(50, 50, 50, 0.8)";
        var text1 = `ESG score`,
          text2 = 100,
          //   text2 = `${esgScore[3]} / ${esgScore[4]}`,
          textX = Math.round((width - ctx.measureText(text1).width) / 2),
          textY = height / 2.4;
        ctx.fillText(text1, textX, textY);
        // (textX = Math.round((width - ctx.measureText(text2).width) / 2)),
        // (textY += parseInt(fontSize) + 40);
        ctx.fillText(text2, textX, textY);
        ctx.save();
      },
    },
  ];

  const predefinedSections = [
    "A",
    "B",
    "Principle 1",
    "Principle 2",
    "Principle 3",
    "Principle 4",
    "Principle 5",
    "Principle 6",
    "Principle 7",
    "Principle 8",
    "Principle 9",
    "Total",
  ];
  function initializeSectionData() {
    return predefinedSections.map((section) => ({
      sectionName: section,
      sectionScore: 0,
      sectionMaxScore: 0,
    }));
  }
  function transformApiResponse(data) {
    const transformedData = {
      E: initializeSectionData(),
      S: initializeSectionData(),
      G: initializeSectionData(),
    };
    const updateScores = (category, item) => {
      let total = transformedData[category]?.find(
        (section) => section?.sectionName === "Total"
      );
      total.sectionScore += item?.score;
      total.sectionMaxScore += item?.maxScore;
      // Determine which section 0the item belongs to
      let sectionKey;
      if (item?.section?.startsWith("A")) {
        sectionKey = "A";
      } else if (item?.section?.startsWith("B")) {
        sectionKey = "B";
      } else if (item?.section?.startsWith("C_P")) {
        // Extract the principle number for C sections
        const principleMatch = item?.section?.match(/C_P(\d+)_/);
        if (principleMatch) {
          sectionKey = `Principle ${principleMatch[1]}`;
        }
      }
      // Update the scores for the identified section
      if (sectionKey) {
        let sectionData = transformedData[category].find(
          (sec) => sec?.sectionName === sectionKey
        );
        if (sectionData) {
          sectionData.sectionScore += item?.score;
          sectionData.sectionMaxScore += item?.maxScore;
        }
      }
    };
    for (let category in data) {
      if (category === "N/A") continue; // Skipping  the "NA" category
      data[category].forEach((item) => {
        updateScores(category, item);
      });
    }
    return { data: transformedData };
  }
  useEffect(() => {
    const transformedResponse = transformApiResponse(resData);
    setScoreCardData(transformedResponse?.data);
  }, [resData]);

  useEffect(() => {
    const transformedResponse = transformApiResponse(responseData);
    setScoreCardData(transformedResponse?.data);
  }, [responseData]);

  return (
    <div className="py-4">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          //   minHeight:"100vh"
        }}
      >
        <div style={{ textAlign: "center", flex: "1", maxWidth: "40%" }}>
          <EmissionGen
            totalScore={totalScore}
            eScore={eScore}
            sScore={sScore}
            gScore={gScore}
            responseData={scoreCardData}
            envScore={envScore}
            socialScore={socialScore}
            govScore={govScore}
          />
        </div>
        <div style={{ flex: 1 }}>
          <Doughnut data={donoughtData} options={doughnutOptions} />
        </div>
      </div>
      <div
        style={{
          paddingTop: "100px",
        }}
      >
        <p
          style={{
            fontFamily: "Poppins",
          }}
        >
          <strong>Note:</strong> These scores are representative only and shall
          not be used to evaluate risk or any other inferences required for
          business decision-making. We are continuously training our AI models
          to enhance the outcomes. However, if you find any discrepancies or
          have general feedback, please reach us at{" "}
          <strong>info@fitsol.green</strong> with the subject line "ESG Scores".
        </p>
      </div>
    </div>
  );
};

export default ESGReport;
