import React from "react";
import CustomPage from "../../components/Custome/CustomPage";
import CONSTANT, { TABLE_HEADER } from "../Utility/constnt";
import { packageAssignment } from "../Utility/spread-function";
import { PackagingAssetsHeaderData } from "../Utility/exportValue";

const SiteInfo = () => {
  let SiteID = window.location.pathname.split("/")[2];
  let PackageID = window.location.pathname.split("/")[3];

  return (
    <div className="page-content">
      <CustomPage
        API={{
          get: [
            CONSTANT.API.PACKAGINGASSIGNMENT.get.endpoint +
              `?plantId=${SiteID}&packagingTypeId=${PackageID}`,

            `${localStorage.getItem(
              "userRole"
            )}/packageTracking?packageTrackingPlantId=${SiteID}&packagingTypeId=${PackageID}`,
          ],
        }}
        PAGE={{
          title: "PACKAGEASSIGNMENT",
          datafunction: (res) => packageAssignment(res, SiteID),
        }}
        TABLE={{
          title: "Packaging assets list",
          header: CONSTANT?.DATA_TABLE_COLUME?.admin_Package_Assignment,
          export: {
            fileName: "PackagingAsset_Report.csv",
            header: PackagingAssetsHeaderData,
          },
        }}
        ModalName="PACKAGEASSIGNMENT"
        // titleHeader="Packaging Assets List"
        leftSidePageName="PACKAGING ASSETS"
        // tableColumn={`admin_Package_Assignment`}
        // ExportHeaders={PackagingAssetsHeaderData}
        // excelName="PackagingAsset_Report.csv"
      />
    </div>
  );
};

export default SiteInfo;
