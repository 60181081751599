import { Button, Tooltip } from "reactstrap";
import {
  calculatePackageCount,
  calculation,
  formatToFixedNumber,
  getCurrentIST,
  getDate,
  getDateAndTimeColumn,
  getDateAndTimeColumnFor,
  getFormFiled,
  getLocalDateAndTimeColumn,
  getMobileNumber,
  getRecordId,
  getUpperCaseText,
  getVehicleNumberText,
  isNonEmpty,
  replaceValue,
} from "./function";
import { Progress, Tooltip as AntdTooltip } from "antd";
import moment from "moment";
import { MDBBadge } from "mdbreact";
import { useState } from "react";
import CONSTANT, {
  MonthName,
  StatusButton,
  clientRoute,
  getTableColumConfig,
  siteRoute,
  sortCommaSeparatedString,
  toCamelCase,
  truncateAndAddCommas,
  viewRole,
} from "./constnt";
import DoughnutChart from "../../components/doughnutChart";
import { colorCode, colorInner } from "./option";
import { Link, useParams } from "react-router-dom";
import ReactSwitch from "react-switch";
import { vehicleCardDetails } from "./models/view-details";
import PieChart from "../../components/PieChart";
import BarChart from "../../components/stackChart";
import SpeedoMeterChart from "../../components/speedoMeterChart";
import MultiAxisLineChart from "../../components/LineChart";
import { nameConsts } from "./constnt";
import ApproveAction from "../Client/Notifications/utils/approveAction";
import ConnectionStatusUpdate from "../Client/Networking/ManageConnections/connectionStatusUpdate";
export const ViewButton = (path, extra = () => null) => (
  <Link
    className="TableLink"
    to={path}
    style={{
      color: "gray",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
    }}
  >
    <Button
      color="primary"
      className="btn waves-effect waves-light white-color"
      id="view"
      title="View"
      onClick={extra}
    >
      <i className="fa fa-eye"></i>
    </Button>
  </Link>
);

// export const ViewModalButton = ({ onClick = () => {} }) => {
//   const [ttop, setttop] = useState(false);
//   return (
//     <>
//       <Tooltip
//         placement="top"
//         isOpen={ttop}
//         target="delete"
//         toggle={() => {
//           setttop(!ttop);
//         }}
//       >
//         Delete
//       </Tooltip>

//       <Button
//       color="primary"
//       className="btn waves-effect waves-light white-color"
//       id="view"
//       title="View"
//       onClick={extra}
//     >
//       <i className="fa fa-eye"></i>
//     </Button>
//     </>
//   );
// };

export const DeleteButton = ({ onClick = () => {} }) => {
  const [ttop, setttop] = useState(false);
  return (
    <>
      <Tooltip
        placement="top"
        isOpen={ttop}
        target="delete"
        toggle={() => {
          setttop(!ttop);
        }}
      >
        Delete
      </Tooltip>

      <Button
        color="primary"
        className="btn btn-danger waves-effect waves-light white-color"
        onClick={onClick}
        id="delete"
      >
        <i className="bx bxs-trash-alt"></i>
      </Button>
    </>
  );
};

export const EditButton = ({ onClick = () => {} }) => {
  const [ttop, setttop] = useState(false);
  return (
    <>
      <Tooltip
        placement="top"
        isOpen={ttop}
        target="edit"
        toggle={() => {
          setttop(!ttop);
        }}
      >
        Edit
      </Tooltip>
      <Button
        color="primary"
        className="btn btn-primary waves-effect waves-light me-2 white-color"
        onClick={onClick}
        id="edit"
      >
        <i className="bx bxs-edit-alt"></i>
      </Button>
    </>
  );
};

export const ApproveButton = ({ onClick = () => {} }) => {
  const [ttop, setttop] = useState(false);
  return (
    <>
      <Tooltip
        placement="top"
        isOpen={ttop}
        target="approve"
        toggle={() => {
          setttop(!ttop);
        }}
      >
        Approve
      </Tooltip>

      <Button
        color="success"
        className="btn btn-success waves-effect waves-light me-2 white-color"
        onClick={onClick}
        id="approve"
      >
        <i className="bx bxs-check-circle"
        style={{ fontSize: '1.2rem' }}
        ></i>
      </Button>
    </>
  );
};

export const RejectButton = ({ onClick = () => {} }) => {
  const [ttop, setttop] = useState(false);
  return (
    <>
      <Tooltip
        placement="top"
        isOpen={ttop}
        target="reject"
        toggle={() => {
          setttop(!ttop);
        }}
      >
        Reject
      </Tooltip>

      <Button
        color="danger"
        className="btn btn-danger waves-effect waves-light white-color"
        onClick={onClick}
        id="reject"
      >
        <i className="bx bxs-x-circle"
        style={{ fontSize: '1.2rem' }}
        ></i>
      </Button>
    </>
  );
};

export const AllocateAndNotAllocate = (props) => {
  const { value } = props;
  return (
    <>
      {value ? (
        <i className="bx bx-check-circle text-success fs-3"></i>
      ) : (
        <i className="bx bx-x-circle text-danger fs-3"></i>
      )}
    </>
  );
};

const ActionButton = (Operation, clientData) => (
  <div className=" d-flex justify-content-center">
    {Operation?.editRecord && (
      <EditButton onClick={() => Operation?.editRecord({ ...clientData })} />
    )}
    {Operation?.setDeleteData && (
      <DeleteButton
        onClick={() => Operation?.setDeleteData({ ...clientData })}
      />
    )}
  </div>
);
const statusUpdate = (Operation, clientData) => (
  <div className=" d-flex justify-content-center">
    {Operation?.editRecord && (
      <ApproveButton onClick={() => Operation?.editRecord({ ...clientData })} />
    )}
    {Operation?.setDeleteData && (
      <RejectButton onClick={() => Operation?.editRecord({ ...clientData })} />
    )}
  </div>
);

const OnlyEditButton = (Operation, clientData) => (
  <div className=" d-flex justify-content-center">
    {Operation?.editRecord && (
      <EditButton onClick={() => Operation?.editRecord({ ...clientData })} />
    )}
  </div>
);

const OnlyDeleteButton = (Operation, clientData, isConnectionRecieved) => {
  return (
    <div className=" d-flex justify-content-center">
      {Operation?.setDeleteData &&
        isConnectionRecieved &&
        clientData?.status === "ACCEPTED" && (
          <DeleteButton
            onClick={() => {
              return Operation?.setDeleteData({ ...clientData });
            }}
          />
        )}
      {Operation?.setDeleteData && !isConnectionRecieved && (
        <DeleteButton
          onClick={() => {
            return Operation?.setDeleteData({ ...clientData });
          }}
        />
      )}
    </div>
  );
};

export const customerDataMask = (res, Operation) =>
  res?.data?.rows?.map((clientData) => {
    return {
      ...clientData,
      no: getRecordId?.clientRecordId(clientData?.id),
      viewRole: (
        <Button
          color="primary"
          className="btn waves-effect waves-light white-color"
          id="view"
          title="View"
          onClick={() => {
            localStorage.setItem("client", `${clientData?.id}`);
            // window.location.assign(`/clientView/${clientData?.id}`);
            window.location.replace(`/client/${clientData?.id}`);
          }}
        >
          <i className="fa fa-eye"></i>
        </Button>
      ),

      // ViewButton(`/clientView/${clientData?.id}`, () => {
      //   localStorage.setItem("ViewRole", `client,${clientData?.id}`);
      //   // window.location.assign(`/clientView/${clientData?.id}`);
      //   window.location.replace(`/clientView/${clientData?.id}`);
      // }),
      view: ViewButton(`${window.location.pathname}/${clientData?.id}`),
      action: ActionButton(Operation, clientData),
      tripEndDistance: clientData.tripEndDistance,
      clientName: getUpperCaseText(clientData.name),
      clientId: clientData.id,
    };
  });

export const vehicleDataMask = (res, Operation = {}) =>
  res?.data?.rows?.map((vehicleData, index) => {
    return {
      ...vehicleData,
      no: getRecordId?.vehicleRecordId(vehicleData?.id),
      view: ViewButton(`/clientInfo/${vehicleData?.id}`),
      RegistrationNumbers: getVehicleNumberText(
        vehicleData?.registrationNumber
      ),
      Allocated: <AllocateAndNotAllocate value={vehicleData?.allocate} />,
      transporterName: getUpperCaseText(
        vehicleData?.transporter?.transporterName
      ),
      registration_number: vehicleData?.registrationNumber,
      fuelType: getUpperCaseText(vehicleData?.fuelType),
      manufacture: getUpperCaseText(
        vehicleData?.manufacture || vehicleData?.manufacturer
      ),
      puc_valid_upto: getDate(vehicleData?.puc_valid_upto),
      fitness_upto: getDate(vehicleData?.fitness_upto),
      insurance_validity: getDate(vehicleData?.insurance_validity),
      permit_validity_upto: getDate(vehicleData?.permit_validity_upto),
      registration_date: getDate(vehicleData?.registration_date),
      fuel_type: vehicleData?.fuelType,
      manufacturer: vehicleData?.manufacture,
      action: ActionButton(Operation, vehicleData),
    };
  });

export const packageDataMask = (res, Operation) =>
  res?.data?.rows?.map((packageData, index) => {
    return {
      ...packageData,
      no: getRecordId?.packageRecordId(packageData?.id),
      action: ActionButton(Operation, packageData),
    };
  });

export const routeDataMask = (res, Operation, clientId) => {
  return res?.data?.rows?.map((routeData) => {
    return {
      ...routeData?.plant,
      ...routeData,
      no: getRecordId?.routeRecordId(routeData?.id),
      plantId: routeData?.plantId,

      clientId: routeData?.plant?.clientId,
      clientName: routeData?.plant?.client?.name || "",
      PlantName:
        routeData?.routeType == "Forward"
          ? routeData?.plant?.unitName || ""
          : routeData?.destinationPlant?.unitName || "",
      name: routeData?.name,
      add: `${routeData?.destinationPlant?.addressLine1 || ""}, ${
        routeData?.destinationPlant?.addressLine2 || ""
      }, ${routeData?.destinationPlant?.city || ""}, ${
        routeData?.destinationPlant?.state || ""
      }, ${routeData?.destinationPlant?.pincode || ""}.`,
      add1: `${routeData?.plant?.addressLine1 || ""}, ${
        routeData?.plant?.addressLine2 || ""
      }, ${routeData?.plant?.city || ""}, ${routeData?.plant?.state || ""}, ${
        routeData?.plant?.pincode || ""
      }.`,
      add2: routeData?.addressLine2,
      pincode: routeData?.pincode,
      city: routeData?.city,
      state: routeData?.state,
      gurl: routeData?.URL,
      routeType: routeData?.routeType,
      vehicleType: routeData?.vehicleType,
      vehicleCapacity: routeData?.vehicleCapacity,
      ownerShipType: routeData?.ownerShipType,
      placeId: routeData?.placeId,
      latitude: routeData?.latitude,
      logtitude: routeData?.logtitude,
      expectedtime: routeData?.expectedCompletionTimeInHR,
      displayName: routeData?.routeName,
      freightWeight: routeData?.freightWeightInTon,
      action: ActionButton(Operation, {
        clientId: routeData?.plant?.client?.id,
        ...routeData,
      }),
    };
  });
};
export const maskEmail = (email) => {
  if (!email) {
    return "";
  }

  const atIndex = email.indexOf("@");
  if (atIndex <= 1) {
    // If "@" is at or near the beginning, only show the first character
    return email.charAt(0) + "*".repeat(email.length - 1);
  } else {
    const visibleCharacters = 2; // Number of characters to show before "@"
    const maskedCharacters = email.length - visibleCharacters - 1;
    const maskedPart = "*".repeat(maskedCharacters);
    return (
      email.substring(0, visibleCharacters) +
      maskedPart +
      email.substring(atIndex)
    );
  }
};
export const jobViewDataMask = (
  res,
  Operation,
  onViewReason,
  responceStatus
) => {
  let resp = {};
  if (responceStatus == null) {
    resp = res?.result;
  } else {
    if (responceStatus == "success") {
      resp = res?.result?.filter((itm) => itm?.status == responceStatus);
    } else {
      resp = res?.result?.filter((itm) => itm?.status == responceStatus);
    }
  }
  return resp?.map((data) => {
    let singleAttributeData = {};

    data?.attributeData?.map((it) => {
      if (
        it?.subCategoryAttribute?.subCategoryAttributeId in singleAttributeData
      ) {
        singleAttributeData[it?.subCategoryAttribute?.subCategoryAttributeId] =
          it?.value;
      } else {
        if (
          it?.subCategoryAttribute?.attribute?.id ==
          process.env.REACT_APP_CARBON_EMISSION_KEY
        ) {
          singleAttributeData = {
            ...singleAttributeData,
            [it?.subCategoryAttribute?.subCategoryAttributeId]:
              calculation?.carbonEmission(it?.value),
          };
        } else {
          singleAttributeData = {
            ...singleAttributeData,
            [it?.subCategoryAttribute?.subCategoryAttributeId]: it?.value,
          };
        }
      }
    });

    singleAttributeData = {
      ...singleAttributeData,
      id: data?.formId?.toString(),
    };
    return {
      no: getRecordId?.record(data?.formId),
      status: (
        <div className="d-flex align-items-center gap-2">
          <StatusButtonDisplay
            className={`${
              data?.status == "success"
                ? "bg-soft-success text-success"
                : "bg-soft-danger text-danger"
            } p-1`}
            textName={`${data?.status == "success" ? "Success" : "Failed"}`}
          />
          {data?.status !== "success" ? (
            <i
              role="button"
              onClick={() => {
                onViewReason(data?.reason);
              }}
              className="bx bx-info-circle fs-4"
            ></i>
          ) : (
            <></>
          )}
        </div>
      ),
      isDuplicate:
        data?.status == "success" ? (
          <div className="d-flex align-items-center gap-2">
            <StatusButtonDisplay
              className={`${
                data?.isDuplicate == true
                  ? "bg-soft-warning text-warning"
                  : "bg-soft-success text-success"
              } p-1`}
              textName={`${
                data?.isDuplicate == true ? "Marked duplicate" : "Added"
              }`}
              width={`${data?.isDuplicate == true ? "130px" : "90px"}`}
            />
          </div>
        ) : (
          <></>
        ),
      statusHeader: data?.status,
      isDuplicateHeader:
        data?.isDuplicate == true ? "Marked duplicate" : "Added",
      action: ActionButton(Operation, singleAttributeData),
      ...singleAttributeData,
    };
  });
};

export const driverDataMask = (res, Operation) =>
  res?.data?.rows?.map((driverData, index) => {
    return {
      ...driverData,
      no: getRecordId?.driverRecordId(driverData?.id),
      mobile: getMobileNumber(driverData?.mobile),
      view: ViewButton(`/driversInfo/${driverData?.id}`),
      driverName: getUpperCaseText(driverData?.name),

      "dl_no.": driverData?.dl_no,
      image: driverData?.profilePic,
      dl_no: driverData?.dl_no,
      dob: getDate(driverData?.dob, "DD-MM-YYYY"),
      action: ActionButton(Operation, driverData),
    };
  });

export const transporterDataMask = (res, Operation) =>
  res?.data?.rows?.map((transporterData, index) => {
    return {
      ...transporterData,
      no: getRecordId?.transpoterRecordId(transporterData?.id),
      view: ViewButton(
        `${window.location.pathname}/transposerInfo/${transporterData?.id}`
      ),
      TransporterName: getUpperCaseText(transporterData?.transporterName),
      clientId: transporterData.id,
      action: ActionButton(Operation, transporterData),
    };
  });

export const siteDataMask = (
  res,
  Operation = {},
  setMapViewOpen = () => {},
  setInputData = () => {}
) =>
  res?.data?.rows?.map((plantData) => {
    const role = localStorage.getItem("userRole");
    return {
      ...plantData,
      no: getRecordId?.plantRecordId(plantData?.id),
      clientName: getUpperCaseText(plantData?.client?.name),
      action: ActionButton(Operation, plantData),
      viewRole: (
        <Button
          color="primary"
          className="btn waves-effect waves-light white-color"
          id="view"
          title="View"
          onClick={() => {
            localStorage.setItem("siteViewer", `${plantData?.id}`);
            // window.location.assign(`/clientView/${clientData?.id}`);
            window.location.replace(`/siteViewer/${plantData?.id}`);
          }}
        >
          <i className="fa fa-eye"></i>
        </Button>
      ),
      view: (
        <>
          <Link
            className="TableLink"
            to={`${window.location.pathname}/${plantData?.id}`}
            // onClick={() => GoToClientInfo(clientData)}
            style={{
              color: "gray",
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Button
              color="primary"
              className="btn waves-effect waves-light white-color"
              id="view"
              title="View"
            >
              <i className="fa fa-eye"></i>
            </Button>
          </Link>
        </>
      ),
      access: (
        <>
          <Link
            className="TableLink"
            to={`${window.location.pathname}/sitesAccess/${plantData?.id}`}
            // onClick={() => GoToClientInfo(clientData)}
            style={{
              color: "gray",
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Button
              color="primary"
              className="btn waves-effect waves-light white-color"
              id="view"
              title="View"
            >
              <i className="fa fa-eye"></i>
            </Button>
          </Link>
        </>
      ),
      // link: (
      //   <a href={plantData?.actualAddress} target="_blank" rel="noreferrer">
      //     <i class="bx bxs-map" style={{ fontSize: "40px" }}></i>
      //   </a>
      // ),
      link: (
        <i
          class="bx bxs-map "
          style={{
            fontSize: "40px",
            cursor: "pointer",
            color: `${
              plantData?.latitude && plantData?.logtitude
                ? "#060dbf"
                : "#636c76"
            }`,
          }}
          onClick={() => {
            if (plantData?.latitude && plantData?.logtitude) {
              setMapViewOpen(true);
              setInputData({
                latitude: plantData?.latitude,
                logtitude: plantData?.logtitude,
              });
            }
          }}
        ></i>
      ),
      address: `${plantData?.addressLine1}, ${plantData?.addressLine2}, ${plantData?.city}, ${plantData?.state}, ${plantData?.pincode}.`,
      is_aadhar_verified: plantData?.adhrVFlag,
      registration_date: plantData?.rgdt,
      business_type: plantData?.ctb,
      gst_status: plantData?.sts,
      is_field_visit_conducted: plantData?.isFieldVisitConducted,
      tradeNam: plantData?.tradeNam,
      business_name: plantData?.tradeNam,
      business_address: plantData?.pradr,
      unitName: getUpperCaseText(plantData?.unitName),
    };
  });

export const categoryDataMask = (res, Operation, updateVisiblity) =>
  res?.data?.rows?.map((categoryData) => {
    return {
      ...categoryData,
      no: getRecordId?.categoryRecordId(categoryData?.id),
      name: getUpperCaseText(categoryData?.name),
      scope: getUpperCaseText(categoryData?.category?.name),
      sampleFile: categoryData.sampleFile && (
        <i
          role="button"
          className="bx bxs-download fs-4 mt-2"
          onClick={() => {
            window.open(categoryData.sampleFile, "_blank");
          }}
        ></i>
      ),
      color: categoryData?.color && (
        <div
          style={{
            background: `${categoryData?.color}`,
            border: "1px solid black",
            height: "25px",
            width: "25px",
            borderRadius: "5px",
          }}
        ></div>
      ),
      isVisible: (
        <ReactSwitch
          // defaultChecked={categoryData?.isVisible}
          checked={categoryData?.isVisible}
          onChange={(selected) => {
            updateVisiblity(selected, categoryData?.id);
          }}
          onColor="#9098d1"
          onHandleColor="#303b91"
          handleDiameter={18}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          height={12}
          width={30}
          className="react-switch"
          id="material-switch"
        />
      ),
      description: categoryData?.description,
      postmanLink: (
        <i
          class="mdi mdi-eye-circle-outline fs-4"
          style={{
            fontSize: "40px",
            cursor: "pointer",
            color: `${categoryData?.postmanLink ? "#4959e6" : "#636c76"}`,
          }}
          onClick={() => {
            if (categoryData?.postmanLink) {
              window.open(`${categoryData?.postmanLink}`, "_blank");
            }
          }}
        ></i>
        // <Button

        //   color="primary"
        //   className="btn btn-primary waves-effect waves-light me-2"
        // >
        //   View
        // </Button>
      ),
      action: ActionButton(Operation, categoryData),
    };
  });

export const attributeDataMask = (res, Operation) =>
  res?.data?.rows?.map((attributeData) => {
    return {
      ...attributeData,
      no: getRecordId?.attributeRecordId(attributeData?.id),
      name: getUpperCaseText(attributeData?.name),
      action: ActionButton(Operation, attributeData),
    };
  });

export const StatusButtonDisplay = ({
  className,
  textName,
  width = "80px",
  onClick = () => {},
}) => {
  return (
    <MDBBadge
      className={className}
      style={{
        borderRadius: "4px",
        cursor: "pointer",
        width: width,
        fontSize: "15px",
        height: "30px",
        lineHeight: "21px",
      }}
      onClick={onClick}
    >
      {textName}
    </MDBBadge>
  );
};

export const packageAssignment = (res, id) => {
  const packageAssignment = res[0]?.data?.data.map((packageData, index) => {
    return {
      pacakeSubType: packageData?.packagingType?.DropdownName,
      count: `+${packageData?.count}`,
      type: (
        <StatusButtonDisplay
          className="bg-soft-success text-success p-1"
          textName="Assign"
        />
      ),
      createdAt: moment(packageData?.createdAt, "DD-MM-YYYY hh:mm A")
        .local()
        .format("DD-MM-YYYY hh:mm A"),
      // siteName: packageData?.plant?.unitName,
    };
  });

  const Incoming = res[1]?.data?.data
    ?.filter((item) => item?.trip?.destinationPlantId == id)
    .map((packageData, index) => {
      return {
        pacakeSubType: packageData?.packagingType?.DropdownName,
        count: `+${packageData?.count}`,
        type: (
          <StatusButtonDisplay
            className="bg-soft-success text-success p-1"
            textName="Incoming"
          />
        ),
        createdAt: moment(packageData?.createdAt, "DD-MM-YYYY hh:mm A")
          .local()
          .format("DD-MM-YYYY hh:mm A"),
        // siteName: packageData?.plant?.unitName,
      };
    });

  const OutGoing = res[1]?.data?.data
    ?.filter((item) => item?.trip?.plantId == id)
    .map((packageData, index) => {
      return {
        pacakeSubType: packageData?.packagingType?.DropdownName,
        count: `-${packageData?.count}`,
        type: (
          <StatusButtonDisplay
            className="bg-soft-danger text-danger p-1"
            textName="OutGoing"
          />
        ),
        createdAt: moment(packageData?.createdAt, "DD-MM-YYYY hh:mm A")
          .local()
          .format("DD-MM-YYYY hh:mm A"),
      };
    });
  return [...packageAssignment, ...Incoming, ...OutGoing];
};

//not use
export const packageAssignmentCount = (res, id) => {
  let packageCount = {};

  res[0]?.data?.data.map((packageData) => {
    if (packageData?.packagingType?.id in packageCount) {
      packageCount[packageData?.packagingType?.id] = {
        ...packageCount[packageData?.packagingType?.id],
        count:
          packageCount[packageData?.packagingType?.id].count +
          +packageData?.count,
      };
    } else {
      packageCount = {
        ...packageCount,
        [packageData?.packagingType?.id]: {
          count: +packageData?.count,
          Name: packageData?.packagingType?.DropdownName,
        },
      };
    }
  });
  res[1]?.data?.data
    ?.filter((item) => item?.trip?.destinationPlantId == id)
    .map((packageData) => {
      if (packageData?.packagingType?.id in packageCount) {
        packageCount[packageData?.packagingType?.id] = {
          ...packageCount[packageData?.packagingType?.id],
          count:
            packageCount[packageData?.packagingType?.id].count +
            +packageData?.count,
        };
      } else {
        packageCount = {
          ...packageCount,
          [packageData?.packagingType?.id]: {
            count: +packageData?.count,
            Name: packageData?.packagingType?.DropdownName,
          },
        };
      }
    });

  res[1]?.data?.data
    ?.filter((item) => item?.trip?.plantId == id)
    .map((packageData, index) => {
      if (packageData?.packagingType?.id in packageCount) {
        packageCount[packageData?.packagingType?.id] = {
          ...packageCount[packageData?.packagingType?.id],
          count:
            packageCount[packageData?.packagingType?.id].count -
            +packageData?.count,
        };
      } else {
        packageCount = {
          ...packageCount,
          [packageData?.packagingType?.id]: {
            count: -packageData?.count,
            Name: packageData?.packagingType?.DropdownName,
          },
        };
      }
    });

  return packageCount;
};

export const TeamMasK = (res, Operation, setShowModel_1, setActionData) =>
  res?.data?.rows?.map((roleAndAccessData) => {
    return {
      ...roleAndAccessData,
      no: getRecordId?.roleAndAccessRecordId(roleAndAccessData?.id),
      client: `${
        roleAndAccessData?.role == "client"
          ? roleAndAccessData?.client?.name || ""
          : roleAndAccessData?.role == "siteViewer"
          ? roleAndAccessData?.plant?.client?.name || ""
          : roleAndAccessData?.role == "spoc"
          ? roleAndAccessData?.client?.name || ""
          : "Fitsol"
      }`,
      roleColomn:
        roleAndAccessData?.role == "client"
          ? "Client Admin"
          : roleAndAccessData?.role == "siteViewer"
          ? "Site Admin"
          : roleAndAccessData?.role == "tripCreator"
          ? "Shipment Creator"
          : roleAndAccessData?.role == "spoc"
          ? "SPOC"
          : "",
      reset: (
        <Button
          color="primary"
          className="btn waves-effect waves-light white-color"
          id="view"
          title="View"
          onClick={() => {
            setShowModel_1((prev) => !prev);
            setActionData(roleAndAccessData);
          }}
        >
          <i class="fas fa-user-lock"></i>
        </Button>
      ),
      action: ActionButton(Operation, roleAndAccessData),
    };
  });

export const SiteAccessMask = (
  res,
  id,
  setShowModel_1,
  setActionData,
  Operation
) => {
  return res?.data?.rows
    ?.filter((roleAndAccessData) => roleAndAccessData?.plantId == id)
    ?.map((roleAndAccessData) => {
      return {
        ...roleAndAccessData,
        no: getRecordId?.roleAndAccessRecordId(roleAndAccessData?.id),
        roleColomn: roleAndAccessData?.role,
        client: `${
          roleAndAccessData?.role == "client"
            ? roleAndAccessData?.client?.name || ""
            : roleAndAccessData?.role == "siteViewer"
            ? roleAndAccessData?.plant?.client?.name || ""
            : "Fitsol"
        }`,
        reset: (
          <Button
            color="primary"
            className="btn waves-effect waves-light white-color"
            id="view"
            title="View"
            onClick={() => {
              setShowModel_1((prev) => !prev);
              setActionData(roleAndAccessData);
            }}
          >
            <i class="fas fa-user-lock"></i>
          </Button>
        ),
        action: ActionButton(Operation, roleAndAccessData),
      };
    });
};

export const clientAccessMask = (
  res,
  id,
  setShowModel_1,
  setActionData,
  Operation
) =>
  res?.data?.rows
    ?.filter((data) => data?.client?.id == id)
    ?.map((roleAndAccessData) => {
      return {
        ...roleAndAccessData,
        no: getRecordId?.roleAndAccessRecordId(roleAndAccessData?.id),
        roleColomn: roleAndAccessData?.role,
        client: `${
          roleAndAccessData?.role == "client"
            ? roleAndAccessData?.client?.name || ""
            : roleAndAccessData?.role == "siteViewer"
            ? roleAndAccessData?.plant?.client?.name || ""
            : "Fitsol"
        }`,
        reset: (
          <Button
            color="primary"
            className="btn waves-effect waves-light white-color"
            id="view"
            title="View"
            onClick={() => {
              setShowModel_1((prev) => !prev);
              setActionData(roleAndAccessData);
            }}
          >
            <i class="fas fa-user-lock"></i>
          </Button>
        ),
        action: ActionButton(Operation, roleAndAccessData),
      };
    });

export const tripMask = (
  res = [],
  hiddenFileInput = {},
  PackagingEmission = {},
  handleChange = () => {},
  handleGeneratePdf = () => {},
  setDownloadData = () => {},
  onPackage = () => {},
  onEditTrip = () => {},
  setShowModel_1 = () => {},
  setShowModel = () => {},
  openConfirmationDeleteModal = () => {},
  mapPath
) => {
  return res?.map((tripDataB, i) => {
    const startDate = moment(
      tripDataB?.targetedDateAndTime,
      "DD-MM-YYYY hh:mm A"
    );
    const completedDate = moment(tripDataB?.completedDateAndTime);
    let t3 = startDate._i;
    let t4 = moment(completedDate._i);
    let t6 = t4.add(5, "hours").add(30, "minutes");
    const t7 = t6.toISOString();
    let isStartDateAfterCompletedDate;

    let moment1 = moment(t3);
    const moment2 = moment(t7);
    // isStartDateAfterCompletedDate = startDate.isAfter(completedDate);
    isStartDateAfterCompletedDate = moment1.isAfter(moment2);

    if (!isStartDateAfterCompletedDate) {
      const truckStartDate = tripDataB?.startDateAndTime;
      const moment3 = moment(truckStartDate);
      const targetedDiff = moment1.diff(moment3, "hours");
      if (Math.abs(targetedDiff) > 0) {
        let timeAdd = Math.ceil(0.3 * Math.abs(targetedDiff));
        let t8 = moment(t7).subtract(timeAdd, "hours");
        let t9 = t8.toISOString();
        let moment4 = moment(t9);
        isStartDateAfterCompletedDate = moment1.isAfter(moment4);
      } else if (targetedDiff == 0) {
        isStartDateAfterCompletedDate = true;
      }
    }
    const packageEmission = tripDataB?.packageCarbonEmission?.toFixed(3);
    // tripDataB?.length > 0
    //   ? tripDataB?.package
    //       ?.reduce((total, current) => {
    //         return (
    //           total + PackagingEmission[+current?.packageId] * +current?.count
    //         );
    //       }, 0)
    //       .toFixed(4)
    //   : 0;

    const carbonEmission = tripDataB?.carbonEmission
      ? (tripDataB?.carbonEmission / 1000).toFixed(3)
      : 0;

    const pathRole = localStorage?.getItem("userRole");
    // localStorage?.getItem("userRole") === "client"
    //   ? "client"
    //   : localStorage?.getItem("userRole") === "siteViewer"
    //   ? "siteviewer"
    //   : "";
    // let mapPath = `${pathRole}/tracking/${tripDataB.id}`;
    // //  `${pathRole}/tracking/${tripDataB.id}`;
    // if (window.location.pathname?.includes(clientRoute)) {
    //   mapPath = `/client/${window.location.pathname.split("/")[2]}/tracking/${
    //     tripDataB.id
    //   }`;
    // } else if (window.location.pathname?.includes(siteRoute)) {
    //   mapPath = `/siteviewer/${
    //     window.location.pathname.split("/")[2]
    //   }/tracking/${tripDataB.id}`;
    // }
    return {
      ...tripDataB,
      no: `CO${tripDataB?.id + 20000000006000}`,
      clientName: getUpperCaseText(tripDataB?.client?.name),
      transporterName: getUpperCaseText(
        tripDataB?.transporter?.transporterName
      ),
      pod: (
        <div>
          <i
            role="button"
            class="fa fa-upload fs-5 mt-2"
            onClick={() => {
              hiddenFileInput?.current &&
                hiddenFileInput?.current[i]?.current.click();
            }}
          >
            <input
              type="file"
              key={i}
              id={i}
              ref={hiddenFileInput?.current && hiddenFileInput?.current[i]}
              onChange={(e) => handleChange(e, tripDataB)}
              style={{ display: "none" }}
            />
          </i>

          {/* <i
            role="button"
            class="mdi mdi-eye-circle-outline fs-4"
            onClick={handleClick}
          >
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={(e) => handleChange(e, tripDataB)}
              style={{ display: "none" }}
            />
          </i> */}
        </div>
      ),
      packageEmission,

      utilisation: tripDataB?.utilisation?.toFixed(3),
      driverName: getUpperCaseText(tripDataB?.driver?.name),
      driverPhoneNumber: getMobileNumber(tripDataB?.driver?.mobile),
      vehicleNumber: getVehicleNumberText(
        tripDataB?.vehicle?.registrationNumber
      ),
      plantName: getUpperCaseText(tripDataB?.route?.routeName),
      startDateAndTime: getDateAndTimeColumn(tripDataB?.startDateAndTime),
      startDateAndTime1: tripDataB?.startDateAndTime,
      targetedDateAndTime: getDateAndTimeColumn(tripDataB?.targetedDateAndTime),
      sourceLocation: tripDataB?.plant?.pradr || "",
      destinationLocation: tripDataB?.destinationPlant?.pradr || "",
      carbonEmission,
      actualeta: tripDataB?.completedDateAndTime
        ? getLocalDateAndTimeColumn(tripDataB?.completedDateAndTime)
        : "",
      mapView: (
        <Link to={`${mapPath}${tripDataB?.id}`} style={{ color: "#636c76" }}>
          <i
            role="button"
            // onClick={() => goToMapPage(tripDataB)}
            className="mdi mdi-eye-circle-outline fs-4"
            // onClick={window.location.assign(`${mapPath}${tripDataB?.id}`)}
          ></i>
        </Link>
      ),
      totalGen: (+carbonEmission + +packageEmission).toFixed(3),
      billDownload: (
        <i
          role="button"
          className="bx bxs-download fs-4 mt-2"
          onClick={() => {
            handleGeneratePdf();
            setDownloadData(tripDataB);
          }}
        ></i>
      ),
      packageType: (
        <i
          role="button"
          onClick={() => {
            onPackage(tripDataB);
          }}
          className="mdi mdi-eye-circle-outline fs-4"
        ></i>
      ),
      sourcename: tripDataB?.plant?.unitName,
      destinationName:
        tripDataB?.destinationPlantId !== null
          ? tripDataB?.destinationPlant?.unitName
          : tripDataB?.consigneeName,
      targetedDate: getDate(tripDataB?.targetedDateAndTime, "DD-MM-YYYY"),

      startDate: getDate(tripDataB?.startDateAndTime, "DD-MM-YYYY"),
      startTime: getDate(tripDataB?.startDateAndTime, "hh:mm A"),

      targetedTime: getDate(tripDataB?.targetedDateAndTime, "hh:mm A"),
      startDateAfterCompletedDate: isStartDateAfterCompletedDate
        ? "On Time"
        : "Delayed",
      statusdata:
        tripDataB?.status === 1
          ? "In Transit"
          : tripDataB?.status === 2
          ? "In Transit"
          : "Completed",
      statusData: (
        <>
          <StatusButton
            value={tripDataB?.status}
            onClick={() => {
              if (localStorage.getItem("userRole") === "admin") {
                onEditTrip(tripDataB);
                setShowModel_1(true);
              }
            }}
          />
        </>
      ),

      action:
        localStorage.getItem("userRole") === "admin" ? (
          <>
            <Link
              className="TableLink"
              to={`/${localStorage.getItem("userRole")}/shipmentform/${
                tripDataB?.id
              }`}
              style={{
                color: "gray",
                cursor: "pointer",
              }}
            >
              <EditButton
                onClick={() => {
                  onEditTrip(tripDataB);
                  setShowModel(true);
                }}
              />
            </Link>

            <DeleteButton
              onClick={() => {
                openConfirmationDeleteModal(tripDataB);
              }}
            />
          </>
        ) : (
          <></>
        ),
    };
  });
};

export const brsrSpocDataMask = (res, Operation) =>
  res?.data?.rows?.map((spocData) => {
    return {
      ...spocData,
      no: getRecordId?.brsrSpocId(spocData?.id),
      action: ActionButton(Operation, spocData),
    };
  });
export const brsrFormDataMask = (res, Operation) =>
  res?.data?.rows?.map((formData) => {
    let sectionId = formData?.section;
    let formId = formData?.id;
    let filledFields = formData?.filledFields;
    let totalFields = formData?.totalFields;
    let filledFieldsPercentage;
    if (totalFields > 0) {
      filledFieldsPercentage = ((filledFields / totalFields) * 100).toFixed(0);
    }
    const sectionNameMapping = {
      A_I: "General Disclosures",
      // A_I: "Details of the listed entity",
      A_II: "CSR Details",
      A_III: "Grievance Redressal",
      // A_III: "Operations",
      // A_IV: "Employees",
      A_IV: "Locations",
      A_V: "Stakeholder Identification",
      // A_V: "Holding, Subsidiary and Associate Companies (including joint ventures)",
      A_VI: "Products & services",
      A_VII: "Holdings and Subsidiaries",
      // A_VII: "Transparency and Disclosures Compliances",
      B: "Management and Process Disclosures",
      C_P1_E: "Principal 1 Essential Indicators",
      C_P1_L: "Principal 1 Leadership Indicators",
      C_P2_E: "Principal 2 Essential Indicators",
      C_P2_L: "Principal 2 Leadership Indicators",
      C_P3_E: "Principal 3 Essential Indicators",
      C_P3_L: "Principal 3 Leadership Indicators",
      C_P4_E: "Principal 4 Essential Indicators",
      C_P4_L: "Principal 4 Leadership Indicators",
      C_P5_E: "Principal 5 Essential Indicators",
      C_P5_L: "Principal 5 Leadership Indicators",
      C_P6_E: "Principal 6 Essential Indicators",
      C_P6_L: "Principal 6 Leadership Indicators",
      C_P7_E: "Principal 7 Essential Indicators",
      C_P7_L: "Principal 7 Leadership Indicators",
      C_P8_E: "Principal 8 Essential Indicators",
      C_P8_L: "Principal 8 Leadership Indicators",
      C_P9_E: "Principal 9 Essential Indicators",
      C_P9_L: "Principal 9 Leadership Indicators",
    };
    return {
      ...formData,
      // no: getRecordId?.brsrFormId(formData?.id),
      action: ActionButton(Operation, formData),
      deadline: new Date(formData?.deadline)
        .toLocaleString("en-GB")
        .split(",")[0],
      editForm: ViewButton(
        `${window.location.pathname}/brsr-form/${sectionId}/${formId}`
      ),
      spocs: ViewButton(
        `${window.location.pathname}/brsr-form/${formId}/spoclist`
      ),
      progressBar: (
        <Progress type="circle" percent={filledFieldsPercentage} size={45} />
      ),
      updateBRSRForm: OnlyEditButton(Operation, formData),
      sectionName: (
        <AntdTooltip title={sectionId}>
          <span>{sectionNameMapping[sectionId]}</span>
        </AntdTooltip>
      ),
    };
  });
export const brsrSpocListDataMask = (res, Operation) =>
  res?.data?.spocs?.map((spocData) => {
    return {
      ...spocData,
      no: getRecordId?.brsrAllotedSpocId(spocData?.id),
      SPOCname: spocData?.name,
      SPOCemail: spocData?.email,
      SPOCphone: spocData?.phone,
      SPOCdepartment: spocData?.department,

      // no: getRecordId?.brsrFormId(formData?.id),
      // action: ActionButton(Operation, formData),
      // editForm: ViewButton(`${window.location.pathname}/brsr-form/${sectionId}`),
      // spocs: ViewButton(`${window.location.pathname}/brsr-form/${formId}/spoclist`),
    };
  });
export const headerFortSiteGen = (totalData, id, scope2List) => {
  if (!totalData || totalData == null)
    return [
      [
        "name",
        `${
          localStorage.getItem("userRole") == "admin" && id == null
            ? "Name"
            : "Site Name"
        }`,
      ],
      ["shipment", "Shipments"],
      ["per", "Carbon Emissions (tCO2e)"],
    ];

  let scopes = [];
  let subCateogry = [];

  if (scope2List == true) {
    subCateogry.push(["id", "Site Id"]);
    subCateogry.push(["siteName", "Site Name"]);
    subCateogry.push(["totalElectricity", "Electricity"]);
    subCateogry.push(["gridSource", "Non-Renewable Source"]);
    subCateogry.push(["renewableSource", "Renewable Source"]);
    subCateogry.push([
      "totalCarbonEmission",
      "Scope2 Total Carbon Emissions (tCO2e)",
    ]);

    return [...subCateogry];
  }

  totalData
    ?.sort((a, b) => {
      const categoryA = a.category.name;
      const categoryB = b.category.name;
      if (categoryA < categoryB) return -1;
      if (categoryA > categoryB) return 1;
      return 0;
    })
    ?.map((el) => {
      const categoryName = el?.category?.name;
      if (categoryName?.includes("1")) {
        const scopeName = replaceValue(categoryName, " ", "_");
        const subCategoryName = replaceValue(el?.name, " ", "_");
        if (!scopes.some((scope) => scope[0] === scopeName)) {
          scopes.push([scopeName, categoryName]);
        }
        if (!subCateogry.some((sub) => sub[0] === subCategoryName)) {
          subCateogry.push([subCategoryName, `S1 ${el?.name}`]);
        }
      } else if (categoryName?.includes("2")) {
        const scopeName = replaceValue(categoryName, " ", "_");
        const subCategoryName = replaceValue(el?.name, " ", "_");
        if (!scopes.some((scope) => scope[0] === scopeName)) {
          scopes.push([scopeName, categoryName]);
        }
        if (!subCateogry.some((sub) => sub[0] === subCategoryName)) {
          subCateogry.push([subCategoryName, `S2 ${el?.name}`]);
        }
      } else if (categoryName?.includes("3")) {
        const scopeName = replaceValue(categoryName, " ", "_");
        const subCategoryName = replaceValue(el?.name, " ", "_");
        if (!scopes.some((scope) => scope[0] === scopeName)) {
          scopes.push([scopeName, categoryName]);
        }
        if (!subCateogry.some((sub) => sub[0] === subCategoryName)) {
          subCateogry.push([subCategoryName, `S3 ${el?.name}`]);
        }
      }
    });
  return [
    ["name", "Site Name"],
    ["shipment", "Shipments"],
    ...subCateogry,
    ...scopes,
    ["per", "Carbon Emissions (tCO2e)"],
  ];
  // }
};

export const headerForScopeGen = (totalData, id) => {
  if (!totalData || totalData == null) return [[]];
  let subCateogry = [];
  totalData.map((item) => {
    subCateogry.push([item.field, item.label]);
  });
  return [...subCateogry];
};

export const headerForClientSiteGen = (categoryDataLabel = null, id = null) => {
  if (!categoryDataLabel || categoryDataLabel == null)
    return [
      [
        "name",
        `${
          localStorage.getItem("userRole") == "admin" && id == null
            ? "Name"
            : "Site Name"
        }`,
      ],
      ["shipment", "Shipments"],
      ["per", "Carbon Emissions (tCO2e)"],
    ];
  let scopes = [];
  let subCateogry = [];
  Object?.keys(categoryDataLabel)?.map((el) => {
    if (categoryDataLabel[el]?.name?.includes("1")) {
      const newKeys = categoryDataLabel[el]?.name;
      scopes.push([
        replaceValue(categoryDataLabel[el]?.name, " ", "_"),
        categoryDataLabel[el]?.name,
      ]);
      Object?.keys(categoryDataLabel[el]?.data)?.map((subCat) => {
        subCateogry.push([
          `${replaceValue(
            categoryDataLabel[el]?.data[subCat]?.label,
            " ",
            "_"
          )}`,
          `S1 ${categoryDataLabel[el]?.data[subCat]?.label}`,
        ]);
      });
    } else if (categoryDataLabel[el]?.name?.includes("2")) {
      scopes.push([
        replaceValue(categoryDataLabel[el]?.name, " ", "_"),
        categoryDataLabel[el]?.name,
      ]);
      Object?.keys(categoryDataLabel[el]?.data)?.map((subCat) => {
        subCateogry.push([
          `${replaceValue(
            categoryDataLabel[el]?.data[subCat]?.label,
            " ",
            "_"
          )}`,
          `S2 ${categoryDataLabel[el]?.data[subCat]?.label}`,
        ]);
      });
    } else if (categoryDataLabel[el]?.name?.includes("3")) {
      const newKeys = categoryDataLabel[el]?.name;
      scopes.push([
        replaceValue(categoryDataLabel[el]?.name, " ", "_"),
        categoryDataLabel[el]?.name,
      ]);
      Object?.keys(categoryDataLabel[el]?.data)?.map((subCat) => {
        subCateogry.push([
          `${replaceValue(
            categoryDataLabel[el]?.data[subCat]?.label,
            " ",
            "_"
          )}`,
          `S3 ${categoryDataLabel[el]?.data[subCat]?.label}`,
        ]);
      });
    }
  });
  return [
    ["name", "Site Name"],
    ["shipment", "Shipments"],
    ...subCateogry,
    ...scopes,
    ["per", "Carbon Emissions (tCO2e)"],
    // ["eff", "Carbon Efficiency (tCO2e/Freight Tonne-km)"],
  ];
};
export const dashboardAnalysisNew = (
  res,
  CardData,
  setTransporterList,
  setTripList,
  currentPath,
  id
) => {
  CardData.admin[0].value =
    +(
      res?.data?.getAllShipmentEmissionData?.[0]?.carbonEmissionSum / 1000
    )?.toFixed(0) || 0;

  CardData.admin[0].value = CardData.admin?.[0].value?.toLocaleString("en-IN");

  CardData.admin[1].value =
    res?.data?.getAllShipmentEmissionData[0]?.totalDistanceOfTrip != null
      ? +res?.data?.getAllShipmentEmissionData[0]?.totalDistanceOfTrip?.toFixed(
          0
        )
      : 0;
  CardData.admin[1].value =
    CardData.admin?.[1].value?.toLocaleString("en-IN") || 0;
  CardData.admin[2].value =
    +res?.data?.getAllShipmentEmissionData[0]?.averageUtilizations
      ?.toFixed(0)
      ?.toLocaleString("en-IN") || 0;

  CardData.admin[3].value =
    res?.data?.getTotalShipmentsWithStatus2Data?.toLocaleString("en-IN") || 0;
  CardData.admin[4].value =
    res?.data?.getTotalShipmentsWithStatus3Data?.toLocaleString("en-IN") || 0;
  if (currentPath?.split("/")?.[1] !== "siteviewer") {
    CardData.admin[5].value =
      res?.data?.siteCountData?.toLocaleString("en-IN") || 0;
  }

  CardData.client[0].value =
    +(
      res?.data?.getAllShipmentEmissionData?.[0]?.carbonEmissionSum / 1000
    )?.toFixed(0) || 0;

  CardData.client[0].value =
    CardData.admin[0].value?.toLocaleString("en-IN") || 0;

  CardData.client[1].value =
    res?.data?.getAllShipmentEmissionData[0]?.totalDistanceOfTrip != null
      ? +res?.data?.getAllShipmentEmissionData[0]?.totalDistanceOfTrip?.toFixed(
          0
        )
      : 0;
  CardData.client[1].value =
    CardData.client?.[1].value?.toLocaleString("en-IN") || 0;

  CardData.client[2].value =
    res?.data?.getAllShipmentEmissionData?.[0]?.averageUtilizations?.toFixed(
      3
    ) || 0;
  CardData.client[3].value =
    res?.data?.getTotalShipmentsWithStatus2Data?.toLocaleString("en-IN") || 0;
  CardData.client[4].value =
    res?.data?.getTotalShipmentsWithStatus3Data?.toLocaleString("en-IN") || 0;
  if (currentPath?.split("/")?.[1] !== "siteviewer") {
    CardData.client[5].value =
      res?.data?.siteCountData?.toLocaleString("en-IN") || 0;
  }

  res?.data?.transporterWiseAnalysis &&
    setTransporterList != null &&
    setTransporterList(
      res?.data?.transporterWiseAnalysis?.map((e) => {
        return {
          // per: `Average ${e?.utilisationAvg.toFixed(0)} km`,
          per: (e?.carbonEmissionSum / 1000).toFixed(0),
          // eff: (e?.utilisationAvg).toFixed(0),
          name: e?.transporterName,
          shipment: e?.count,
        };
      })
    );

  if (
    localStorage.getItem("userRole") === "admin" &&
    !currentPath?.includes(id)
  ) {
    setTripList(
      res?.data?.clientEmissionData
        // .filter((ob) => ob?.client)
        ?.map((e) => {
          return {
            per: e?.carbonEmissionSum
              ? (e?.carbonEmissionSum / 1000).toFixed(0)
              : 0, //Total Trip
            // eff: e?.client?.utilisationAvg
            //   ? (e?.client?.utilisationAvg).toFixed(0)
            //   : 0, //Total Trip
            name: e?.name || "Other",
            shipment: e?.count,
          };
        })
    );
  } else {
    let siteEmisionData = [];
    let allSiteData = [
      ...res?.data?.siteWiseShipmentAnalysis,
      res?.data?.siteWiseScopeAnalysis,
    ];
    let scope1Sum = 0,
      scope2Sum = 0,
      scope3Sum = 0;
    res?.data?.clientEmissionData?.map((item) => {
      if (item?.scope == "Scope 1") {
        scope1Sum += +item?.carbonEmissionSum / 1000;
      } else if (item?.scope == "Scope 2") {
        scope2Sum += +item?.carbonEmissionSum / 1000;
      } else if (item?.scope == "Scope 3") {
        scope3Sum += +item?.carbonEmissionSum / 1000;
      }
    });
    res?.data?.siteWiseShipmentAnalysis?.map((e) => {
      siteEmisionData = {
        ...siteEmisionData,
        [e?.plantId]: {
          per: e?.carbonEmissionSum
            ? (e?.carbonEmissionSum / 1000).toFixed(0)
            : 0, //Total Trip
          // eff: e?.utilisationAvg ? (e?.utilisationAvg).toFixed(0) : 0, //Total Trip
          name: e?.plantName || "Other",
          shipmentCount: e?.count,
          shipmentCarbonEmission: e?.carbonEmissionSum
            ? (e?.carbonEmissionSum / 1000).toFixed(0)
            : 0, //Total Trip
        },
      };
    });
    res?.data?.siteWiseScopeAnalysis?.map((item) => {
      const category = item?.categoryId;
      const subCategory = item?.subCategoryId;
      const plantId = item?.plantId;
      if (!item?.categoryId || !item?.subCategoryId || !item?.scope) return;

      if (plantId in siteEmisionData) {
        if (category in siteEmisionData[plantId]) {
          if (subCategory in siteEmisionData[plantId][category]?.data) {
            siteEmisionData[plantId][category].data[subCategory].count += +(
              +item?.carbonEmissionSum / 1000
            );
          } else {
            siteEmisionData[plantId][category].data = {
              ...siteEmisionData[plantId][category]?.data,
              [subCategory]: {
                label: item?.subCategoryName,
                count: +(+item?.carbonEmissionSum / 1000),
              },
            };
          }
        } else {
          siteEmisionData[plantId] = {
            ...siteEmisionData[plantId],
            [category]: {
              categoryName: item?.scope,
              data: {
                [subCategory]: {
                  label: item?.subCategoryName,
                  count: +(+item?.carbonEmissionSum / 1000),
                },
              },
            },
          };
        }
      } else {
        siteEmisionData = {
          ...siteEmisionData,
          [plantId]: {
            name: item?.plantName,

            [category]: {
              categoryName: item?.scope,
              data: {
                [subCategory]: {
                  label: item?.subCategoryName,
                  count: +(+item?.carbonEmissionSum / 1000),
                },
              },
            },
          },
        };
      }
    });
    let siteScope1Sum = 0,
      siteScope2Sum = 0,
      siteScope3Sum = 0;
    let listAll = [];
    listAll = Object.keys(siteEmisionData)?.map((el, index) => {
      let categoryEmisssionSum = 0;
      let scop1SubCategoryEmissionSum = 0;
      let scop2SubCategoryEmissionSum = 0;
      let scop3SubCategoryEmissionSum = 0;
      let scop1SubCategoyList = {};
      let scop2SubCategoyList = {};
      let scop3SubCategoyList = {};
      Object?.values(siteEmisionData?.[el]).forEach((category) => {
        // Iterate through data in each category
        category?.data &&
          Object?.values(category?.data).forEach((dataItem) => {
            // Add count to sum
            if (category?.categoryName == "Scope 1") {
              siteScope1Sum += +dataItem?.count;
              scop1SubCategoryEmissionSum += +dataItem?.count;
              scop1SubCategoyList = {
                ...scop1SubCategoyList,
                [replaceValue(dataItem?.label, " ", "_")]: dataItem?.count,
              };
              // scop1SubCategoryEmissionSum += +dataItem?.count;
            } else if (category?.categoryName == "Scope 2") {
              siteScope2Sum += +dataItem?.count;
              scop2SubCategoryEmissionSum += +dataItem?.count;
              scop2SubCategoyList = {
                ...scop2SubCategoyList,
                [replaceValue(dataItem?.label, " ", "_")]: dataItem?.count,
              };
            } else if (category?.categoryName == "Scope 3") {
              siteScope3Sum += +dataItem?.count;
              scop3SubCategoryEmissionSum += +dataItem?.count;
              scop3SubCategoyList = {
                ...scop3SubCategoyList,
                [replaceValue(dataItem?.label, " ", "_")]: dataItem?.count,
              };
            }
            categoryEmisssionSum += +dataItem.count;
          });
      });

      const totalEmission = +(
        (+siteEmisionData[el]?.shipmentCarbonEmission || 0) +
        +categoryEmisssionSum
      )?.toFixed(0);

      return {
        id: el,
        name: siteEmisionData?.[el]?.name,
        shipment: siteEmisionData?.[el]?.shipmentCount || 0,
        shipmentCarbonEmission:
          siteEmisionData?.[el]?.shipmentCarbonEmission || 0,
        per: totalEmission?.toLocaleString("en-IN") || 0,
        scope1List: scop1SubCategoyList,
        scope2List: scop2SubCategoyList,
        scope3List: scop3SubCategoyList,
        ...scop1SubCategoyList,
        ...scop2SubCategoyList,
        ...scop3SubCategoyList,
        Scope_1: truncateAndAddCommas(scop1SubCategoryEmissionSum) || 0,
        Scope_2: truncateAndAddCommas(scop2SubCategoryEmissionSum) || 0,
        Scope_3: truncateAndAddCommas(scop3SubCategoryEmissionSum) || 0,
      };
    });
    // formatToFixedNumber replaced everywhere at site-wise and non-site emissions
    listAll.push({
      name: "Non-site emissions",
      shipment: 0,
      shipmentCarbonEmission: 0,
      Scope_1: truncateAndAddCommas(scope1Sum - siteScope1Sum) || 0,
      Scope_2: truncateAndAddCommas(scope2Sum - siteScope2Sum) || 0,
      Scope_3: truncateAndAddCommas(scope3Sum - siteScope3Sum) || 0,
      per:
        truncateAndAddCommas(
          scope1Sum -
            siteScope1Sum +
            (scope2Sum - siteScope2Sum) +
            (scope3Sum - siteScope3Sum)
        ) || 0,
    });
    setTripList(listAll);
  }
};

export const dashboardAnalysis = (
  res,
  CardData,
  setTransporterList,
  setTripList,
  setData,
  setCarbonEmissionsData,
  selectedOptions,

  currentPath,
  id
) => {
  CardData.admin[0].value =
    +(
      res?.data?.totalCarbonEmissionutilizationAndKmCompleted[0]
        ?.carbonEmissionSum / 1000
    )?.toFixed(0) || 0;

  CardData.admin[0].value = CardData.admin[0].value?.toLocaleString("en-IN");

  CardData.admin[1].value =
    (+res?.data?.totalCarbonEmissionutilizationAndKmCompleted[0]?.totalKmCompleted?.toFixed(
      0
    ))?.toLocaleString("en-IN") || 0;

  CardData.admin[2].value =
    +res?.data?.totalCarbonEmissionutilizationAndKmCompleted[0]?.utilisationAvg
      ?.toFixed(0)
      ?.toLocaleString("en-IN") || 0;

  CardData.admin[3].value =
    res?.data?.tripInStatus2?.toLocaleString("en-IN") || 0;
  CardData.admin[4].value =
    res?.data?.tripInStatus3?.toLocaleString("en-IN") || 0;
  if (currentPath?.split("/")?.[1] !== "siteviewer") {
    CardData.admin[5].value =
      res?.data?.plant?.plant?.toLocaleString("en-IN") || 0;
  }

  CardData.client[0].value =
    +(
      res?.data?.totalCarbonEmissionutilizationAndKmCompleted[0]
        ?.carbonEmissionSum / 1000
    )?.toFixed(0) || 0;

  CardData.client[0].value =
    CardData.admin[0].value?.toLocaleString("en-IN") || 0;

  CardData.client[1].value =
    res?.data?.totalCarbonEmissionutilizationAndKmCompleted[0]
      ?.totalKmCompleted !== null
      ? (+res?.data?.totalCarbonEmissionutilizationAndKmCompleted[0]?.totalKmCompleted?.toFixed(
          0
        ))?.toLocaleString("en-IN") || 0
      : 0;

  CardData.client[2].value =
    res?.data?.totalCarbonEmissionutilizationAndKmCompleted[0]?.utilisationAvg?.toFixed(
      3
    ) || 0;
  CardData.client[3].value =
    res?.data?.tripInStatus2?.toLocaleString("en-IN") || 0;
  CardData.client[4].value =
    res?.data?.tripInStatus3?.toLocaleString("en-IN") || 0;
  if (currentPath?.split("/")?.[1] !== "siteviewer") {
    CardData.client[5].value =
      res?.data?.plant?.plant?.toLocaleString("en-IN") || 0;
  }

  setTransporterList(
    res?.data?.transporter?.transporterAnalytics.map((e) => {
      return {
        // per: `Average ${e?.utilisationAvg.toFixed(0)} km`,
        per: (e?.carbonEmissionSum / 1000).toFixed(0),
        eff: (e?.utilisationAvg).toFixed(0),
        name: e?.name,
        shipment: e?.count,
      };
    })
  );

  if (
    localStorage.getItem("userRole") === "admin" &&
    !currentPath?.includes(id)
  ) {
    setTripList(
      res?.data?.trip?.clientAnalytics
        .filter((ob) => ob?.client)
        .map((e) => {
          return {
            per: e?.client?.carbonEmissionSum
              ? (e?.client?.carbonEmissionSum / 1000).toFixed(0)
              : 0, //Total Trip
            eff: e?.client?.utilisationAvg
              ? (e?.client?.utilisationAvg).toFixed(0)
              : 0, //Total Trip
            name: e?.client?.name || "Other",
            shipment: e?.client?.count,
          };
        })
    );
  } else {
    setTripList(
      res?.data?.plant?.plantAnalytics
        .filter((ob) => ob?.name)
        .map((e) => {
          return {
            per: e?.carbonEmissionSum
              ? (e?.carbonEmissionSum / 1000).toFixed(0)
              : 0, //Total Trip
            eff: e?.utilisationAvg ? (e?.utilisationAvg).toFixed(0) : 0, //Total Trip
            name: e?.name || "Other",
            shipment: e?.count,
          };
        })
    );
  }
  setData(res?.data?.trip);
  setCarbonEmissionsData(
    res?.data?.trip?.[selectedOptions].map((e) => {
      return {
        key:
          selectedOptions === "lastAllMonthsTripAnalytics"
            ? MonthName[e.label - 1]
            : e.label,
        // value: e.carbonEmissionSum,
        value: e.carbonEmissionSum / 1000,
        effValue: e.utilisationAvg,
      };
    })
  );
};
export const emissionDashboardDataLoadNew = (
  res,
  categoryData,
  colorOutside,

  setChartView = null,

  setAvailableFinancialYear = null,
  setAvailableCalendarYear = null
) => {
  const category = res[1]?.data?.data?.rows?.find(
    (data) => data?.id == process.env.REACT_APP_CATEGORY_KEY
  );
  const subCategory = res[2]?.data?.data?.rows?.find(
    (data) => data?.id == process.env.REACT_APP_SUBCATEGORY_KEY
  );
  if (setAvailableFinancialYear != null && setAvailableCalendarYear != null) {
    const val = res[0]?.data?.data?.getAllFinancialYearData
      ?.sort((a, b) => {
        const yearA = a?.value?.split("-");
        const yearB = b?.value?.split("-");
        a = +yearA[0] + +yearA[1];
        b = +yearB[0] + +yearB[1];
        return b - a;
      })
      ?.map((el) => {
        return { label: el.value, value: el.value };
      });

    let calendarYears = [],
      financialYears = [];

    for (const item of val) {
      if (item.value) {
        let flag = item.value;
        if (flag.includes("-")) {
          financialYears.push(item);
        } else {
          calendarYears.push(item);
        }
      }
    }

    setAvailableCalendarYear(calendarYears);
    setAvailableFinancialYear(financialYears);
  }
  res[0]?.data?.data?.clientEmissionData?.map((item, index) => {
    const category = item?.categoryId;
    const subCategory = item?.subCategoryId;
    if (!item?.categoryId || !item?.subCategoryId || !item?.scope) return;

    if (category in categoryData) {
      if (subCategory in categoryData[category]?.data) {
        categoryData[category].data[subCategory].count += +(
          +item?.carbonEmissionSum / 1000
        )?.toFixed(2);
      } else {
        categoryData[category].data = {
          ...categoryData[category]?.data,
          [subCategory]: {
            label: item?.subCategoryName,
            count: +(+item?.carbonEmissionSum / 1000)?.toFixed(2),
            color: item?.subCategoryColor,
          },
        };
      }
    } else {
      categoryData = {
        ...categoryData,
        [category]: {
          name: item?.scope,
          data: {
            [subCategory]: {
              label: item?.subCategoryName,
              count: +(+item?.carbonEmissionSum / 1000)?.toFixed(2),
              color: item?.subCategoryColor,
            },
          },
        },
      };
    }
  });
  const calendarSeasonsinner = Object.values(categoryData)?.map(
    (itm, index) => {
      return {
        value: Object.values(itm?.data)?.reduce((total, it) => {
          return it?.count + total;
        }, 0),
        label: `${itm?.name}`,
        // label: `${1}`,
      };
    }
  );
  colorOutside = [];

  const calendarSeasons = Object.values(categoryData)
    ?.map((itm, i) => {
      Object.keys(itm?.data)?.map((it, i) => {
        const colorCodess = res[2]?.data?.data?.rows?.find(
          (itm) => +itm?.id == +it
        )?.color;
        colorOutside.push(colorCodess);
      });

      return Object.values(itm?.data)?.map((it, index) => {
        // colorOutside.push(colorCode[i][index]);

        return {
          value: +it?.count?.toFixed(2),
          // label: "ASD",
          label: `${it?.label}`,
        };
      });
    })
    .flat(2);

  // calendarSeasons.pop();
  setChartView != null &&
    setChartView(
      <DoughnutChart
        chartData={[calendarSeasonsinner, calendarSeasons]}
        colorOutside={colorOutside}
        colorInner={colorInner}
      />
    );

  return {
    categoryData,
  };
};

export const emissionDashboardDataLoadNew1 = (
  res,
  categoryData,
  colorOutside,

  setChartView = null,

  setAvailableFinancialYear = null
) => {
  const category = res[1]?.data?.data?.rows?.find(
    (data) => data?.id == process.env.REACT_APP_CATEGORY_KEY
  );
  const subCategory = res[2]?.data?.data?.rows?.find(
    (data) => data?.id == process.env.REACT_APP_SUBCATEGORY_KEY
  );
  setAvailableFinancialYear != null &&
    setAvailableFinancialYear(
      res[0]?.data?.data?.getAllFinancialYearData
        ?.sort((a, b) => {
          const yearA = a?.value?.split("-");
          const yearB = b?.value?.split("-");
          a = +yearA[0] + +yearA[1];
          b = +yearB[0] + +yearB[1];
          return b - a;
        })
        ?.map((el) => {
          return { label: el.value, value: el.value };
        })
    );
  res[0]?.data?.data?.clientEmissionData?.map((item, index) => {
    const category = item?.categoryId;
    const subCategory = item?.subCategoryId;
    if (
      !item?.categoryId ||
      !item?.subCategoryId ||
      !item?.scope ||
      item?.scope == "Scope 1" ||
      item?.scope == "Scope 2"
    )
      return;
    if (category in categoryData) {
      if (subCategory in categoryData[category]?.data) {
        categoryData[category].data[subCategory].count += +(
          +item?.carbonEmissionSum / 1000
        )?.toFixed(2);
      } else {
        categoryData[category].data = {
          ...categoryData[category]?.data,
          [subCategory]: {
            label: item?.subCategoryName,
            count: +(+item?.carbonEmissionSum / 1000)?.toFixed(2),
            color: item?.subCategoryColor,
          },
        };
      }
    } else {
      categoryData = {
        ...categoryData,
        [category]: {
          name: item?.scope,
          data: {
            [subCategory]: {
              label: item?.subCategoryName,
              count: +(+item?.carbonEmissionSum / 1000)?.toFixed(2),
              color: item?.subCategoryColor,
            },
          },
        },
      };
    }
  });
  const calendarSeasonsinner = Object.values(categoryData)?.map(
    (itm, index) => {
      return {
        value: Object.values(itm?.data)?.reduce((total, it) => {
          return it?.count + total;
        }, 0),
        label: `${itm?.name}`,
        // label: `${1}`,
      };
    }
  );
  colorOutside = [];

  const calendarSeasons = Object.values(categoryData)
    ?.map((itm, i) => {
      Object.keys(itm?.data)?.map((it, i) => {
        const colorCodess = res[2]?.data?.data?.rows?.find(
          (itm) => +itm?.id == +it
        )?.color;
        colorOutside.push(colorCodess);
      });

      return Object.values(itm?.data)?.map((it, index) => {
        // colorOutside.push(colorCode[i][index]);

        return {
          value: +it?.count?.toFixed(2),
          // label: "ASD",
          label: `${it?.label}`,
        };
      });
    })
    .flat(2);

  // calendarSeasons.pop();
  setChartView != null &&
    setChartView(
      <PieChart
        chartData={[calendarSeasonsinner, calendarSeasons]}
        colorOutside={colorOutside}
        colorInner={colorInner}
      />
    );

  return {
    categoryData,
  };
};
export const scopeWiseEmissionDashboardDataLoad = (
  scopeData,
  categoryData,
  colorOutside,
  givenCategoryId,
  setStackedView = null,
  setChartView = null,
  setLineView,
  setAvailableFinancialYear = null,
  selectedSubCategories = [],
  selectedFY = [],
  setTripList,
  scope,
  setTableHeading,
  setCategoryList,
  setTable2Heading,
  setMapData
) => {
  const labels = [];
  let tableHeading = [];
  let table2Heading = [];
  if (selectedFY.length > 0) {
    tableHeading.push({
      label: "Site Name",
      field: "siteName",
      sort: "asc",
    });
    table2Heading.push({
      label: "Category Name",
      field: "subCategoryName",
      sort: "asc",
    });
  }
  let siteWiseData = {};
  let categoryWiseData = {};
  let fyEmissionSum = [];
  for (const fy of selectedFY) {
    tableHeading.push({
      label: fy,
      field: fy,
      sort: "asc",
    });
    table2Heading.push({
      label: fy,
      field: fy,
      sort: "asc",
    });

    let tableList = [];
    let categoryList = [];
    categoryData[fy] = {};
    let totalCarbonEmission;
    let data = scopeData[fy]?.siteWiseScopeAnalysis;
    let clientEmissionData = scopeData[fy]?.clientEmissionData;
    clientEmissionData?.map((item) => {
      if (selectedSubCategories.includes(item?.subCategoryId)) {
        if (item?.scope == scope) {
          if (!fyEmissionSum[fy]) {
            fyEmissionSum[fy] = item?.carbonEmissionSum / 1000;
          } else {
            fyEmissionSum[fy] += item?.carbonEmissionSum / 1000;
          }
        }
      }
    });

    data?.map((item) => {
      if (selectedSubCategories.includes(item?.subCategoryId)) {
        let siteId = item?.plantId;
        let scopeType = item?.scope;
        if (scopeType == scope) {
          let carbonEmission = item?.carbonEmissionSum;
          fyEmissionSum[fy] -= carbonEmission / 1000;
          if (!siteWiseData[siteId]) {
            siteWiseData[siteId] = {
              siteId: siteId,
              siteName: item?.plantName,
              [fy]: carbonEmission / 1000,
              totalEmission: carbonEmission / 1000,
            };
          } else {
            if (!siteWiseData[siteId][fy]) {
              siteWiseData[siteId][fy] = carbonEmission / 1000;
              siteWiseData[siteId].totalEmission += carbonEmission / 1000;
            } else {
              siteWiseData[siteId][fy] += carbonEmission / 1000;
              siteWiseData[siteId].totalEmission += carbonEmission / 1000;
            }
          }
        }
      }
    });
    Object.keys(siteWiseData).map((item) => {
      let data = siteWiseData[item];
      let siteData = {
        id: item,
        siteName: data.siteName,
        [fy]: data.carbonEmission,
      };
      tableList.push(data);
    });
    let nonSiteEmissions = { siteName: "Non-site emissions" };
    // Object.assign(nonSiteEmissions, fyEmissionSum);
    for (const key in fyEmissionSum) {
      let key1 = fyEmissionSum?.[key]?.toFixed(0);
      let diff = key1;
      nonSiteEmissions[key] = diff || 0;
    }
    tableList.push(nonSiteEmissions);
    setTripList(tableList);
    let filteredData = {};
    filteredData = tableList.map((item) => ({
      id: item?.siteId,
      Scope: truncateAndAddCommas(item?.totalEmission),
    }));
    setMapData(filteredData);
    clientEmissionData?.map((item) => {
      if (selectedSubCategories.includes(item?.subCategoryId)) {
        if (item?.scope == scope) {
          let subCategoryName = item?.subCategoryName;
          let carbonEmission = item?.carbonEmissionSum;
          let updatedSubCategoryName = toCamelCase(subCategoryName);
          if (!categoryWiseData[updatedSubCategoryName]) {
            categoryWiseData[updatedSubCategoryName] = {
              subCategoryName: subCategoryName,
              [fy]: carbonEmission / 1000,
            };
          } else {
            if (!categoryWiseData[updatedSubCategoryName][fy]) {
              categoryWiseData[updatedSubCategoryName][fy] =
                carbonEmission / 1000;
            } else {
              categoryWiseData[updatedSubCategoryName][fy] +=
                carbonEmission / 1000;
            }
          }
        }
      }
    });
    Object.keys(categoryWiseData).map((item) => {
      let data = categoryWiseData[item];
      let categoryData = {
        subCategoryName: item.subCategoryName,
        [fy]: item.carbonEmission,
      };
      categoryList.push(data);
    });
    setCategoryList(categoryList);
    scopeData[fy]?.clientEmissionData?.map((item, index) => {
      if (selectedSubCategories.includes(item?.subCategoryId)) {
        if (
          item?.subCategoryId &&
          item?.categoryId == givenCategoryId &&
          !labels.some((el) => el.name === item?.subCategoryName)
        ) {
          labels.push({
            name: item?.subCategoryName,
            id: item?.subCategoryId,
            color: item?.subCategoryColor,
          });
        }
        const category = item?.categoryId;
        const subCategory = item?.subCategoryId;
        const data = {
          label: item?.subCategoryName,
          count: +(+item?.carbonEmissionSum / 1000)?.toFixed(2),
          color: item?.subCategoryColor,
        };

        if (!categoryData[fy]) {
          categoryData[fy] = {};
        }

        if (!categoryData[fy][category]) {
          categoryData[fy][category] = {
            name: item?.scope,
            data: {},
          };
        }

        if (!categoryData[fy][category].data[subCategory]) {
          categoryData[fy][category].data[subCategory] = data;
        } else {
          categoryData[fy][category].data[subCategory].count += data.count;
        }
      }
    });
  }
  setTableHeading(tableHeading);
  setTable2Heading(table2Heading);
  const chartData = {};
  const fyLabels = [];
  const lineChartData = {};
  const pieData = {
    data: [],
    label: "Scope 3",
    colors: [],
  };
  for (const fy of selectedFY) {
    lineChartData[fy] = 0;
  }
  for (const label of labels) {
    chartData[label?.name] = {
      data: [],
    };
    let sum = 0;
    for (const fy of Object.keys(categoryData)) {
      if (!fyLabels.includes(fy)) fyLabels.push(fy);
      let count;
      if (categoryData?.[fy]?.[givenCategoryId]?.data?.[label?.id]) {
        count = categoryData?.[fy]?.[givenCategoryId]?.data?.[label?.id]?.count;
        if (isNaN(count)) {
          count = 0;
        }
        if (lineChartData.hasOwnProperty(fy)) {
          lineChartData[fy] += count;
        } else {
          lineChartData[fy] = count;
        }
      }
      chartData[label?.name]?.data.push(count ? count : 0);
      chartData[label?.name].color = label?.color;
    }
  }
  for (const fy of Object.keys(categoryData)) {
    for (const [index, label] of labels.entries()) {
      let count;
      if (categoryData?.[fy]?.[givenCategoryId]?.data?.[label?.id]) {
        count = categoryData?.[fy]?.[givenCategoryId]?.data?.[label?.id]?.count;
      }
      if (pieData?.data?.[index] === undefined) {
        pieData.data.push(count ? count : 0);
      } else {
        pieData.data[index] += count ? count : 0;
      }
      if (!pieData?.colors?.[index]) {
        pieData.colors.push(label.color);
      }
    }
  }
  setStackedView != null &&
    setStackedView(
      <BarChart
        chartData={chartData}
        labels={fyLabels.reverse()}
        colorOutside={colorOutside}
        colorInner={colorInner}
      />
    );
  setChartView != null &&
    setChartView(
      <PieChart
        chartData={pieData}
        labels={labels}
        colorOutside={colorOutside}
        colorInner={colorInner}
      />
    );
  setLineView != null &&
    setLineView(
      <MultiAxisLineChart
        labels={fyLabels}
        chartData={lineChartData}
        // passing static vvalues for now
        revenue={1}
        unitProduction={2}
      />
    );
  return {
    categoryData,
  };
};

export const overallScopeWiseEmissionDashboardDataLoad = (
  scopeData,
  scopeCategoryData,
  setStackedView = null,
  setChartView = null,
  setLineView = null,
  selectedCategories = [],
  selectedFY = [],
  setTripList,
  setTableHeading,
  setScopeList,
  setTable2Heading,
  setMapData,
  setTotalEmission,
  setSelectedScope,
  subCategoryOptions
) => {
  let selectedScope = "";
  subCategoryOptions?.forEach((item) => {
    const label = item.label;
    let value = item?.value;
    if (selectedCategories.includes(item?.value)) {
      const scopeNumber = parseInt(label.split(" ")[1]);
      selectedScope += scopeNumber;
      selectedScope += ",";
    }
  });
  let trimmedSelectedScope = "";
  if (selectedScope.length >= 2) {
    trimmedSelectedScope = selectedScope.slice(0, -1);
    trimmedSelectedScope = sortCommaSeparatedString(trimmedSelectedScope);
  }
  setSelectedScope(trimmedSelectedScope);
  let tableHeading = [];
  let table2Heading = [];
  if (selectedFY.length > 0) {
    tableHeading.push({
      label: "Site Name",
      field: "siteName",
      sort: "asc",
    });
    table2Heading.push({
      label: "Scope Type",
      field: "scopeType",
      sort: "asc",
    });
  }
  let siteWiseData = {};
  let scopeWiseData = {};
  let fyEmissionSum = [],
    fyEmissionSum2 = [];
  let categoryData = {};
  for (const fy of selectedFY) {
    tableHeading.push({
      label: fy,
      field: fy,
      sort: "asc",
    });
    table2Heading.push({
      label: fy,
      field: fy,
      sort: "asc",
    });
    let tableList = [];
    let scopeList = [];
    categoryData[fy] = {};
    let totalCarbonEmission;
    let clientEmissionData = scopeData[fy]?.clientEmissionData;
    clientEmissionData?.map((item) => {
      const categoryIdInt = parseInt(item?.categoryId, 10);
      if (!selectedCategories.includes(categoryIdInt)) return;
      if (!fyEmissionSum[fy]) {
        fyEmissionSum[fy] = item?.carbonEmissionSum / 1000;
      } else {
        fyEmissionSum[fy] += item?.carbonEmissionSum / 1000;
      }
      // }
    });
    let data = scopeData[fy]?.siteWiseScopeAnalysis;
    data?.map((item) => {
      let siteId = item?.plantId;
      let scopeType = item?.scope;
      if (!selectedCategories.includes(item?.categoryId)) return;
      let carbonEmission = item?.carbonEmissionSum;
      if (!fyEmissionSum2[fy]) {
        fyEmissionSum2[fy] = carbonEmission / 1000;
      } else {
        fyEmissionSum2[fy] += carbonEmission / 1000;
      }
      if (!siteWiseData[siteId]) {
        siteWiseData[siteId] = {
          siteId: siteId,
          siteName: item?.plantName,
          [fy]: carbonEmission / 1000,
          totalEmission: carbonEmission / 1000,
        };
      } else {
        if (!siteWiseData[siteId][fy]) {
          siteWiseData[siteId][fy] = carbonEmission / 1000;
          siteWiseData[siteId].totalEmission += carbonEmission / 1000;
        } else {
          siteWiseData[siteId][fy] += carbonEmission / 1000;
          siteWiseData[siteId].totalEmission += carbonEmission / 1000;
        }
      }
    });
    Object.keys(siteWiseData).map((item) => {
      let data = siteWiseData[item];
      let siteData = {
        id: item,
        siteName: data.siteName,
        [fy]: data.carbonEmission,
      };
      tableList.push(data);
    });
    let nonSiteEmissions = { siteName: "Non-site emissions" };
    for (const key in fyEmissionSum) {
      let key1 = fyEmissionSum?.[key]?.toFixed(0);
      let key2 = fyEmissionSum2?.[key]?.toFixed(0);
      let diff = key1 - key2;
      if (!key2){
        diff = key1;
      }
      nonSiteEmissions[key] = diff || 0;
    }
    tableList.push(nonSiteEmissions);
    setTripList(tableList);
    let filteredData = {};
    filteredData = tableList.map((item) => ({
      id: item?.siteId,
      Scope: truncateAndAddCommas(item?.totalEmission),
    }));
    setMapData(filteredData);
    clientEmissionData?.map((item) => {
      let carbonEmission = item?.carbonEmissionSum;
      let scopeType = item?.scope;
      const categoryIdInt = parseInt(item?.categoryId, 10);
      if (!selectedCategories.includes(categoryIdInt)) return;
      if (!scopeWiseData[scopeType]) {
        scopeWiseData[scopeType] = {
          scopeType: scopeType,
          [fy]: carbonEmission / 1000,
        };
      } else {
        if (!scopeWiseData[scopeType][fy]) {
          scopeWiseData[scopeType][fy] = carbonEmission / 1000;
        } else {
          scopeWiseData[scopeType][fy] += carbonEmission / 1000;
        }
      }
    });
    Object.keys(scopeWiseData).map((item) => {
      let data = scopeWiseData[item];
      let categoryData = {
        scopeType: item.scopeType,
        [fy]: item.carbonEmission,
      };
      scopeList.push(data);
    });
    const scopeOrder = {
      "Scope 1": 0,
      "Scope 2": 1,
      "Scope 3": 2,
    };
    scopeList.sort((a, b) => {
      return scopeOrder[a.scopeType] - scopeOrder[b.scopeType];
    });
    setScopeList(scopeList);
  }
  setTableHeading(tableHeading);
  setTable2Heading(table2Heading);
  const chartData = {};
  const fyLabels = [];
  const labels = [];
  const lineChartData = {};
  const pieData = {
    data: [],
    label: "Scope Wise",
    colors: [],
  };
  for (const [index, category] of scopeCategoryData.entries()) {
    chartData[category?.name] = {
      data: [],
      color: colorInner[2 - index],
    };
    for (const fy of selectedFY) {
      lineChartData[fy] = 0;
      if (!fyLabels.includes(fy)) fyLabels.push(fy);
      let count;
      if (scopeWiseData[category?.name]?.[fy]) {
        count = scopeWiseData[category?.name]?.[fy];
      }
      chartData[category?.name].data.push(count ? count : 0);
      if (isNaN(count)) {
        count = 0;
      }
      if (!lineChartData[fy]) {
        lineChartData[fy] = count;
      } else {
        lineChartData[fy] += count;
      }
    }
  }
  for (const [index, category] of scopeCategoryData.entries()) {
    let count = 0;
    for (const fy of selectedFY) {
      count += scopeWiseData?.[category?.name]?.[fy]
        ? scopeWiseData[category?.name][fy]
        : 0;
    }
    if (pieData?.data?.[index] === undefined) {
      pieData.data.push(count ? count : 0);
    }
    if (!pieData?.colors?.[index]) {
      pieData.colors.push(category?.color);
    }
    labels.push({ name: category?.name });
  }
  const totalEmissions = pieData?.data?.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  setTotalEmission(Number(totalEmissions.toFixed(0)).toLocaleString());
  // calendarSeasons.pop();
  setStackedView != null &&
    setStackedView(
      <BarChart chartData={chartData} labels={fyLabels.reverse()} />
    );
  setChartView != null &&
    setChartView(<PieChart chartData={pieData} labels={labels} />);
  setLineView != null &&
    setLineView(
      <MultiAxisLineChart
        labels={fyLabels}
        chartData={lineChartData}
        // passing static vvalues for now
        revenue={100}
        unitProduction={60}
      />
    );

  return {
    scopeWiseData,
  };
};

export const calculateScope2Emission = (
  electricityData,
  subCategoryAttributes,
  setSpeedoMeterView,
  setStackedView,
  setLineView,
  selectedFY
) => {
  let renewableCount = 0;
  let gridCount = 0;
  let totalCarbonEmission = 0;
  const stackChartData = { Electricity: { data: [], color: "#FE7171" } };
  const fyLabels = [];
  const subCategoryAttributeMap = {};
  for (const subCategoryAttribute of subCategoryAttributes) {
    subCategoryAttributeMap[subCategoryAttribute?.attribute?.name] =
      subCategoryAttribute?.id;
  }
  let index = 0;
  const lineChartData = {};
  selectedFY.map((fy, index) => {
    fyLabels.push(fy);
    if (!stackChartData.Electricity?.[index]) {
      stackChartData.Electricity.data.push(0);
    }
    if (fy in electricityData) {
      for (const form of electricityData[fy]) {
        const data = form?.data;
        let totalVal =
          Number(
            data?.[subCategoryAttributeMap[nameConsts.CARBON_EMISSION_TCO2]]
          ) || 0;
        if (!lineChartData[fy]) {
          lineChartData[fy] = totalVal / 1000;
        } else {
          lineChartData[fy] += totalVal / 1000;
        }
        if (
          data?.[
            subCategoryAttributeMap?.[nameConsts.ELECTRICITY_TYPE]
          ]?.toLowerCase() == "renewable"
        ) {
          renewableCount += Number(
            data?.[subCategoryAttributeMap[nameConsts.ELECTRICITY_USAGE]]
          );
        } else {
          gridCount += Number(
            data?.[subCategoryAttributeMap[nameConsts.ELECTRICITY_USAGE]]
          );
          totalCarbonEmission += Number(
            data?.[subCategoryAttributeMap[nameConsts.CARBON_EMISSION_TCO2]]
          );
          stackChartData.Electricity.data[index] +=
            Number(
              data?.[subCategoryAttributeMap[nameConsts.CARBON_EMISSION_TCO2]]
            ) / 1000;
        }
      }
    }
  });
  setSpeedoMeterView != null &&
    setSpeedoMeterView(
      <SpeedoMeterChart renewableCount={renewableCount} gridCount={gridCount} />
    );
  setStackedView != null &&
    setStackedView(<BarChart chartData={stackChartData} labels={fyLabels} />);
  setLineView != null &&
    setLineView(
      <MultiAxisLineChart
        chartData={lineChartData}
        labels={fyLabels}
        revenue={1}
        unitProduction={2}
      />
    );
};

export const scope2TableData = (
  resData,
  setTripList,
  setCategoryList,
  selectedFY,
  subCategoryAttributes,
  setCategoryDataLoading,
  siteNameData,
  setMapData,
  setTotalEmission,
  setTableHeading,
  setTable2Heading
) => {
  if (!selectedFY) {
    return;
  }
  let tableHeading = [];
  let table2Heading = [];
  const subCategoryAttributeMap = {};
  for (const subCategoryAttribute of subCategoryAttributes) {
    subCategoryAttributeMap[subCategoryAttribute?.attribute?.name] =
      subCategoryAttribute?.id;
  }
  if (selectedFY.length > 0) {
    tableHeading.push({
      label: "Site Name",
      field: "siteName",
      sort: "asc",
    });
    table2Heading.push({
      label: "Category Name",
      field: "subCategoryName",
      sort: "asc",
    });
  }
  const scope2List = [];
  const scope2MapData = [];
  const data = resData[0]?.data?.data;
  let siteWiseData = {};
  let categoryWiseData = {};
  let totalScope2Emission = 0;
  Object.keys(data).map((item) => {
    if (selectedFY.includes(item)) {
      tableHeading.push({
        label: item,
        field: item,
        sort: "asc",
      });
      table2Heading.push({
        label: item,
        field: item,
        sort: "asc",
      });
    }
    if (selectedFY.includes(item)) {
      let scope2Data = data[item];
      let siteId, electricityUsage, totalCarbonEmission;
      for (const itm of scope2Data) {
        let siteData = itm?.data;
        siteId = siteData?.[subCategoryAttributeMap?.[nameConsts?.SITE_ID]];
        electricityUsage = parseInt(
          siteData?.[subCategoryAttributeMap?.[nameConsts?.ELECTRICITY_USAGE]]
        );
        totalCarbonEmission =
          parseInt(
            siteData?.[
              subCategoryAttributeMap?.[nameConsts?.CARBON_EMISSION_TCO2]
            ]
          ) / 1000;
        totalScope2Emission += totalCarbonEmission;
        if (!siteWiseData[siteId]) {
          siteWiseData[siteId] = {
            siteId: siteId,
            siteName: "",
            [item]: totalCarbonEmission,
            totalEmission: totalCarbonEmission,
          };
        } else {
          if (!siteWiseData[siteId][item]) {
            siteWiseData[siteId][item] = totalCarbonEmission;
            siteWiseData[siteId].totalEmission += totalCarbonEmission;
          } else {
            siteWiseData[siteId][item] += totalCarbonEmission;
            siteWiseData[siteId].totalEmission += totalCarbonEmission;
          }
        }
        let sourceType =
          siteData?.[subCategoryAttributeMap?.[nameConsts?.ELECTRICITY_TYPE]];
        // if sourcetype is undefined i have to add it in grid one
        if (!sourceType) {
          sourceType = "Grid";
        }
        let updatedScopeType = toCamelCase(sourceType);
        if (!categoryWiseData[updatedScopeType]) {
          categoryWiseData[updatedScopeType] = {
            subCategoryName: sourceType,
            [item]: totalCarbonEmission,
          };
        } else {
          if (!categoryWiseData[updatedScopeType][item]) {
            categoryWiseData[updatedScopeType][item] = totalCarbonEmission;
          } else {
            categoryWiseData[updatedScopeType][item] += totalCarbonEmission;
          }
        }
      }
    }
  });

  for (const siteId in siteWiseData) {
    if (siteWiseData.hasOwnProperty(siteId)) {
      const siteNameObj = siteNameData.find((obj) => obj.siteId == siteId);
      if (siteNameObj) {
        // ṇeed to check with the pagination again here
        siteWiseData[siteId].siteName = siteNameObj.name;
      }
    }
  }
  let categoryList = [];
  Object.keys(categoryWiseData).map((item) => {
    let data = categoryWiseData[item];
    categoryList.push(data);
  });

  let tableList = [];
  let mapList = [];
  Object.keys(siteWiseData).map((item) => {
    let data = siteWiseData[item];
    let mapData = {
      id: data.siteId,
      Scope: truncateAndAddCommas(data.totalEmission),
    };
    mapList.push(mapData);
    tableList.push(data);
  });
  setMapData(mapList);
  setTripList(tableList);
  setCategoryDataLoading(false);
  setTableHeading(tableHeading);
  setCategoryList(categoryList);
  setTable2Heading(table2Heading);
  let totalEmissionString = totalScope2Emission.toString();
  setTotalEmission(totalEmissionString);
};
export const emissionDashboardDataLoad = (
  res,
  categoryData,
  colorOutside,
  setChartView,
  setAvailableFinancialYear
) => {
  const category = res[1]?.data?.data?.rows?.find(
    (data) => data?.id == process.env.REACT_APP_CATEGORY_KEY
  );
  const subCategory = res[2]?.data?.data?.rows?.find(
    (data) => data?.id == process.env.REACT_APP_SUBCATEGORY_KEY
  );

  // categoryData = {};
  setAvailableFinancialYear(
    res[0]?.data?.financialYeardata
      ?.sort((a, b) => {
        const yearA = a?.value?.split("-");
        const yearB = b?.value?.split("-");
        a = +yearA[0] + +yearA[1];
        b = +yearB[0] + +yearB[1];
        return b - a;
      })
      ?.map((el) => {
        return { label: el.value, value: el.value };
      })
  );

  if (
    res[0]?.data?.data?.getCarbonEmissionFromTripByFitsol?.[0]
      ?.carbonEmissionSum
  ) {
    if (category?.id in categoryData) {
      if (subCategory?.id in categoryData[category?.id]) {
        categoryData[category?.id].data[subCategory?.id].count += +(
          +res[0]?.data?.data?.getCarbonEmissionFromTripByFitsol[0]
            ?.carbonEmissionSum / 1000
        )?.toFixed(0);
      } else {
        categoryData[category?.id].data = {
          ...categoryData[category?.id]?.data,
          [subCategory?.id]: {
            label: subCategory?.name,
            color: subCategory?.color,

            count: +(
              +res[0]?.data?.data?.getCarbonEmissionFromTripByFitsol[0]
                ?.carbonEmissionSum / 1000
            )?.toFixed(0),
          },
        };
      }
    } else {
      categoryData = {
        ...categoryData,
        [category?.id]: {
          name: category?.name,
          data: {
            [subCategory?.id]: {
              label: subCategory?.name,
              color: subCategory?.color,
              count: +(
                +res[0]?.data?.data?.getCarbonEmissionFromTripByFitsol[0]
                  ?.carbonEmissionSum / 1000
              )?.toFixed(0),
            },
          },
        },
      };
    }
  }
  res[0]?.data?.getCarbonEmissionBySubCategoryAttributes?.map((item) => {
    const category = item?.form?.category?.id;
    const subCategory = item?.subCategoryAttribute?.subCategory?.id;

    if (category in categoryData) {
      if (subCategory in categoryData[category]?.data) {
        categoryData[category].data[subCategory].count += +(
          +item?.sum / 1000
        )?.toFixed(2);
      } else {
        categoryData[category].data = {
          ...categoryData[category]?.data,
          [subCategory]: {
            label: item?.subCategoryAttribute?.subCategory?.name,
            count: +(+item?.sum / 1000)?.toFixed(2),
            color: item?.subCategoryAttribute?.subCategory?.color,
          },
        };
      }
    } else {
      categoryData = {
        ...categoryData,
        [category]: {
          name: item?.form?.category?.name,
          data: {
            [subCategory]: {
              label: item?.subCategoryAttribute?.subCategory?.name,
              count: +(+item?.sum / 1000)?.toFixed(2),
              color: item?.subCategoryAttribute?.subCategory?.color,
            },
          },
        },
      };
    }
  });
  const calendarSeasonsinner = Object.values(categoryData)?.map(
    (itm, index) => {
      return {
        value: Object.values(itm?.data)?.reduce((total, it) => {
          return it?.count + total;
        }, 0),
        label: `${itm?.name}`,
        // label: `${1}`,
      };
    }
  );
  colorOutside = [];

  const calendarSeasons = Object.values(categoryData)
    ?.map((itm, i) => {
      Object.keys(itm?.data)?.map((it, i) => {
        const colorCodess = res[2]?.data?.data?.rows?.find(
          (itm) => +itm?.id == +it
        )?.color;
        colorOutside.push(colorCodess);
      });

      return Object.values(itm?.data)?.map((it, index) => {
        return {
          value: +it?.count?.toFixed(2),
          // label: "ASD",
          label: `${it?.label}`,
        };
      });
    })
    .flat(2);

  // calendarSeasons.pop();
  setChartView(
    <DoughnutChart
      chartData={[calendarSeasonsinner, calendarSeasons]}
      colorOutside={colorOutside}
      colorInner={colorInner}
    />
  );

  return {
    categoryData,
  };
};

export const bulkUploadJobLoad = (getJobBulkData) => {
  return getJobBulkData?.users?.data?.rows?.map((jobData) => {
    return {
      ...jobData,
      no: getRecordId?.job(jobData?.id),
      uploadFile: (
        <i
          className="mdi mdi-download"
          style={{
            marginLeft: "30%",
            fontSize: "20px",
            cursor: "pointer",
          }}
          onClick={() => {
            window.open(jobData?.uploadedData, "download");
          }}
        ></i>
      ),
      category: jobData?.subCategory?.name,
      csvLink: jobData?.uploadedData,
      createdDate: moment(jobData?.createdAt)?.local()?.format("DD-MM-YYYY"),
      uploadedRecord: +jobData?.totalEntryCount || 0,
      successRecord:
        jobData?.failedEntry != null
          ? (+jobData?.totalEntryCount || 0) - (+jobData?.failedEntry || 0)
          : 0,
      failedRecord: +jobData?.failedEntry || 0,
      view: (
        <>
          {
            <Link
              className="TableLink"
              to={`${window.location.pathname}/${jobData?.id}`}
              // onClick={() => GoToClientInfo(clientData)}
              style={{
                color: "gray",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                color="primary"
                className="btn waves-effect waves-light white-color"
                id="view"
                title="View"
              >
                <i className="fa fa-eye"></i>
              </Button>
            </Link>
          }
        </>
      ),
    };
  });
};

export const bulkUploadConnectionJobLoad = (getJobBulkData) => {
  return getJobBulkData?.map((jobData) => {
    return {
      ...jobData,
      no: getRecordId?.job(jobData?.id),
      uploadFile: (
        <i
          className="mdi mdi-download"
          style={{
            marginLeft: "30%",
            fontSize: "20px",
            cursor: "pointer",
          }}
          onClick={() => {
            window.open(jobData?.uploadedData, "download");
          }}
        ></i>
      ),
      csvLink: jobData?.uploadedData,
      createdDate: moment(jobData?.createdAt)?.local()?.format("DD-MM-YYYY"),
      uploadedRecord: +jobData?.totalEntryCount || 0,
      successRecord:
        jobData?.failedEntry != null
          ? (+jobData?.totalEntryCount || 0) - (+jobData?.failedEntry || 0)
          : 0,
      failedRecord: +jobData?.failedEntry || 0,
      view: (
        <>
          {
            <Link
              className="TableLink"
              to={`${window.location.pathname}/${jobData?.id}`}
              style={{
                color: "gray",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                color="primary"
                className="btn waves-effect waves-light white-color"
                id="view"
                title="View"
              >
                <i className="fa fa-eye"></i>
              </Button>
            </Link>
          }
        </>
      ),
    };
  });
};
export const bulkUploadSingleJobLoad = (getJobBulkData) => {
  // Ensure getJobBulkData is an object and contains a connections array
  if (
    typeof getJobBulkData === "object" &&
    getJobBulkData !== null &&
    Array.isArray(getJobBulkData.connections)
  ) {
    return getJobBulkData.connections.map((connection) => {
      return {
        ...connection,
        no: getRecordId?.job(connection?.id),
        status: connection?.status,
        error: connection?.errorMessage,
        createdDate: moment(connection?.createdAt)
          ?.local()
          ?.format("DD-MM-YYYY"),
      };
    });
  } else {
    console.error(
      "getJobBulkData does not have a valid connections array:",
      getJobBulkData
    );
    return []; // Return an empty array if connections is not valid
  }
};
export const sitePackaginInfoDataLoad = (
  res,
  setPackagingData,
  setViewAction,
  initValue
) => {
  const possiblePackageDetails = [
    ...res?.packageAssignedData?.map((item) => {
      return {
        packagingTypeId: item?.packagingTypeId,
        name: item["packagingType.DropdownName"],
      };
    }),
    ...res?.tripsPackageData?.map((item) => {
      return {
        packagingTypeId: item?.packagingTypeId,
        name: item["packagingType.DropdownName"],
      };
    }),
  ];

  let packageTypeId = possiblePackageDetails.reduce(
    (prev, curr) => {
      if (!prev?.label?.includes(curr.packagingTypeId)) {
        prev?.label?.push(curr.packagingTypeId);
        prev?.Arr?.push(curr);
      }

      return prev;
    },
    { label: [], Arr: [] }
  )?.Arr;

  setPackagingData(
    packageTypeId?.map((packageId) => {
      let rowData = {};
      const rowEntry = res?.packageAssignedData?.filter(
        (item) =>
          item?.packagingTypeId == packageId?.packagingTypeId &&
          item["route.routeType"] == "Forward"
      );
      rowData = {
        ...initValue,
        ...rowData,
      };
      rowEntry?.map((item) => {
        rowData = {
          ...rowData,
          [item["route.id"]]: item?.totalCount,
        };
      });

      rowData = {
        ...rowData,
        packageName: packageId?.name,
        totalAssign: rowEntry?.reduce((total, item) => {
          total += +item?.totalCount;
          return +total;
        }, 0),
        totalAccounted: calculatePackageCount(res, packageId?.packagingTypeId),
        view: (
          <Button
            color="primary"
            className="btn waves-effect waves-light white-color"
            id="view"
            title="View"
            onClick={() =>
              setViewAction({
                packageId: packageId?.packagingTypeId,
                packageName: packageId?.name,
              })
            }
          >
            <i className="fa fa-eye"></i>
          </Button>
        ),
      };
      return rowData;
    })
  );
};

export const emissionEntryCategoryWiseDataLoad = (
  subCategoryAttributeOptionRes = null,
  Other,
  rearrangedData,
  setDynamicOption,
  arrHeader = null
) => {
  const arr = [];
  let formField = [];
  rearrangedData?.map((item) => {
    let itemDataType = item?.dataType;

    if (
      itemDataType === "SingleSelect" ||
      itemDataType === "getShowDefaultSelect"
    ) {
      if (itemDataType === "getShowDefaultSelect") {
        item.getShowDefaultSelect = true;
      }
      itemDataType = "SingleSelect";
      // if (
      //   subCategoryAttributeOptionRes?.rows?.filter(
      //     (el) => el?.attributeId == item?.attributeId
      //   ) <= 0 &&
      //   +item?.attributeId == +28
      // ) {
      //   setDynamicOption((prev) => ({
      //     ...prev,
      //     [item?.id]: res[3]?.data?.data?.rows?.map((fuelData) => {
      //       return {
      //         label: fuelData?.fuelType,
      //         value: fuelData?.fuelType,
      //         id: fuelData?.id,
      //         unit: fuelData?.unit,
      //       };
      //     }),
      //   }));
      // dynamicOption = {
      //   ...dynamicOption,
      //   [item?.id]: res[3]?.data?.data?.rows?.map((fuelData) => {
      //     return {
      //       label: fuelData?.fuelType,
      //       value: fuelData?.fuelType,
      //       id: fuelData?.id,
      //       unit: fuelData?.unit,
      //     };
      //   }),
      // };
      // } else if (
      //   subCategoryAttributeOptionRes?.rows?.filter(
      //     (el) => el?.attributeId == item?.attributeId
      //   ) <= 0 &&
      //   +item?.attributeId == +30
      // ) {
      // } else {

      setDynamicOption((prev) => ({
        ...prev,
        [item?.id]: subCategoryAttributeOptionRes?.rows
          ?.filter((el) => el?.attributeId == item?.attributeId)
          ?.map((el) => {
            return {
              label: el?.value,
              value: el?.value,
            };
          }),
      }));
      // dynamicOption = {
      //   ...dynamicOption,
      //   [item?.id]: subCategoryAttributeOptionRes?.rows
      //     ?.filter((el) => el?.attributeId == item?.attributeId)
      //     ?.map((el) => {
      //       return {
      //         label: el?.value,
      //         value: el?.value,
      //       };
      //     }),
      // };
      // }
    } else if (itemDataType.includes("-")) {
      const typeBreak = itemDataType.split("-");

      itemDataType = typeBreak[1];
      setDynamicOption((prev) => ({
        ...prev,
        [item?.id]: Other[typeBreak[0]]?.map((el) => {
          return {
            label:
              el?.unitName ||
              el?.fuelType ||
              el?.gas ||
              el?.wasteType ||
              el?.vehicleType ||
              el?.name,
            value:
              el?.fuelType ||
              el?.gas ||
              el?.wasteType ||
              el?.vehicleType ||
              el?.name ||
              // el?.purchasedcategory ||
              el?.id,
            id: el?.id,
          };
        }),
      }));
      // dynamicOption = {
      //   ...dynamicOption,
      // [item?.id]: Other[typeBreak[0]]?.map((el) => {
      //   return {
      //     label: el?.unitName,
      //     value: el?.id,
      //   };
      // }),
      // };
    } else if (itemDataType == "dynamicSelect") {
      itemDataType = "SingleSelect";
      item.isDynamicOption = true; //For Parent Field Inside Option list then use it
    }

    const keyArray = item?.equation?.split(",");
    const pr = getFormFiled(
      item?.id,
      `${item?.attribute?.name}`,
      "",
      itemDataType,
      item?.isRequired,
      false,
      (data) => {
        if (
          item?.extra?.visibiltyRule != undefined &&
          item?.extra?.visibiltyRule != null
        ) {
          return false;
        } else {
          return true;
        }
      },
      item?.placeHolder || "",
      arrHeader == null ? item?.width : 12,
      {
        attributId: item?.attributeId,
        isDynamicOption: item?.isDynamicOption, //For Parent Field Inside Option list then use it
        keyOrEquetion: keyArray, //Equation From backend
        getShowDefaultSelect: item?.extra?.visibiltyRule, //For After parent Field Select
        getShowlistAntd: item?.extra?.employeelist, //For Multiple list or
        depenededFieldId: item?.extra?.depenededFieldId, //For Dependency Parent Field
        labelChangeDependedFieldId: item?.extra?.labelChangeDependedFieldId, //For Label Change parent Dependency
        // isSelectMultiple: item?.extra?.multipleSelect,
      }
    );

    if (pr?.type == "SingleSelectWithCreate") {
      pr["create"] = false;
    }

    // if (+pr?.extraValue == +30) {
    //   pr.isShow = (data) => {
    //     if (data?.["64"]) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   };
    // }

    // if (
    //   +pr?.extraValue?.attributId == +33 ||
    //   +pr?.extraValue?.attributId == +34 ||
    //   +pr?.extraValue?.attributId == +35
    // ) {
    //   pr.isShow = (data) => {
    //     if (data?.["76"] && data?.["76"] != "Other") {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   };
    // }

    // if (pr?.extraValue?.attributId == 36) {
    //   pr.isShow = (data) => {
    //     if (data?.["76"] && data?.["76"] == "Other") {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   };
    // }

    arr.push(pr);
    arrHeader != null &&
      arrHeader.push({
        label: item?.attribute?.name,
        // (
        // <p style={{ margin: "0px", width: "90px" }}>
        // {item?.attribute?.name}
        // {/* </p> */}
        // )
        field: item?.id?.toString(),
        sort: "asc",
      });
  });

  // formField = [...CONSTANT.FORM_FIELDS.ADDSINGLEEMISSIONENTRY, ...arr];
  if (arrHeader != null) {
    return { arr, arrHeader };
  } else {
    return { arr };
  }
  // setSubCategoryAttribute(formField);
};

export const emissionForEditFormDataCallLoad = (
  subCategoryModal,
  setDynamicOption,
  setSubCategoryModal,
  formData,
  alldynamicOption,
  editData
) => {
  // if (!!formData) return;
  subCategoryModal
    ?.filter((el) => el?.extraValue?.isDynamicOption)
    ?.map((el) => {
      const valueId = formData?.data?.[el?.extraValue?.keyOrEquetion?.[0]]
        ? formData?.data?.[el?.extraValue?.keyOrEquetion?.[0]]
        : editData[el?.extraValue?.keyOrEquetion?.[0]];

      setDynamicOption((prev) => ({
        ...prev,
        [el?.id]: alldynamicOption[valueId],
      }));
    });

  setSubCategoryModal((pr) => {
    const newValue = pr?.map((it) => {
      if (
        it?.extraValue?.labelChangeDependedFieldId &&
        formData?.selectedValue
      ) {
        const labelDependendId = Object?.keys(formData?.selectedValue)?.find(
          (el) => +el == +it?.extraValue?.labelChangeDependedFieldId
        );
        const labelDepend = formData?.selectedValue[labelDependendId]?.extra;

        if (!!labelDepend) {
          it.label = (
            <span>
              Quantity {`${labelDepend ? `(in ${labelDepend})` : ""}`}
            </span>
          );
        }
      }

      if (it?.extraValue?.getShowDefaultSelect?.length) {
        let findValue = false;
        const objectNew = {};
        it?.extraValue?.getShowDefaultSelect?.map((elp) => {
          Object?.keys(elp)?.map((elpc) => {
            if (objectNew?.[elpc]) {
              objectNew?.[elpc]?.push(elp?.[elpc]);
            } else {
              objectNew[elpc] = [elp?.[elpc]];
            }
          });
        });

        it.isShow = (data, defaultData) => {
          if (
            objectNew[Object?.keys(objectNew)[0]]?.includes(
              data?.[Object?.keys(objectNew)[0]] ||
                defaultData?.[Object?.keys(objectNew)[0]]
            )
          ) {
            const required = objectNew?.["required"]?.[0];
            if (required != undefined || required != null) {
              it["required"] = required;
            } else {
              it["required"] = true;
            }
            return true;
          } else {
            return false;
          }
        };
      }
      return it;
    });
    return newValue;
  });
};

export const emissionForFormDataCallLoad = (
  subCategoryAttribute,
  setDynamicOption,
  setSubCategoryAttribute,
  setFinancialYear,
  formData,
  alldynamicOption,
  getFieldsValue
) => {
  subCategoryAttribute &&
    subCategoryAttribute
      ?.filter((el) => el?.extraValue?.isDynamicOption)
      ?.map((el) => {
        (formData?.data || formData) &&
          setDynamicOption((prev) => ({
            ...prev,
            [el?.id]:
              alldynamicOption?.[
                formData?.data?.[el?.extraValue?.keyOrEquetion?.[0]] ||
                  formData?.[el?.extraValue?.keyOrEquetion?.[0]]
              ],
          }));
      });

  setSubCategoryAttribute((pr) => {
    const newValue = pr?.map((it) => {
      if (
        it?.extraValue?.labelChangeDependedFieldId &&
        formData?.selectedValue
      ) {
        const labelDependendId = Object?.keys(formData?.selectedValue)?.find(
          (el) => +el == +it?.extraValue?.labelChangeDependedFieldId
        );
        const labelDepend = formData?.selectedValue[labelDependendId]?.extra;
        if (!!labelDepend) {
          it.label = (
            <span>
              Quantity {`${labelDepend ? `(in ${labelDepend})` : ""}`}
            </span>
          );
        }
      }
      if (it?.extraValue?.getShowDefaultSelect?.length) {
        let findValue = false;
        const objectNew = {};

        it?.extraValue?.getShowDefaultSelect?.map((elp) => {
          Object?.keys(elp)?.map((elpc) => {
            if (objectNew?.[elpc]) {
              objectNew?.[elpc]?.push(elp?.[elpc]);
            } else {
              objectNew[elpc] = [elp?.[elpc]];
            }
          });
        });

        it.isShow = (data) => {
          if (
            objectNew[Object?.keys(objectNew)[0]]?.includes(
              data?.[Object?.keys(objectNew)[0]]
            )
          ) {
            const required = objectNew?.["required"]?.[0];
            if (required != undefined || required != null) {
              it["required"] = required;
            } else {
              it["required"] = true;
            }
            return true;
          } else {
            return false;
          }
        };
      }
      if (it?.type == "multifield") {
        for (const field of it?.fields) {
          if (field?.extraValue?.getShowDefaultSelect?.length) {
            let findValue = false;
            const objectNew = {};

            field?.extraValue?.getShowDefaultSelect?.map((elp) => {
              Object?.keys(elp)?.map((elpc) => {
                if (objectNew?.[elpc]) {
                  objectNew?.[elpc]?.push(elp?.[elpc]);
                } else {
                  objectNew[elpc] = [elp?.[elpc]];
                }
              });
            });

            field.isShow = (data) => {
              if (
                objectNew[Object?.keys(objectNew)[0]]?.includes(
                  data?.[Object?.keys(objectNew)[0]]
                )
              ) {
                const required = objectNew?.["required"]?.[0];
                if (required != undefined || required != null) {
                  field["required"] = required;
                } else {
                  field["required"] = true;
                }
                return true;
              } else {
                return false;
              }
            };
          }
        }
      }
      return it;
    });
    return newValue;
  });

  (formData?.data || formData) &&
    Object?.values(formData?.data || formData) &&
    (() => {
      const data = formData?.data || formData;
      const firstValue = Object.values(data)[0];

      if (typeof firstValue === "string") {
        const yy = firstValue.split("-");

        if (yy?.[0]?.length == 4) {
          if (yy?.[2]?.length) {
            const lastFind = yy[0].substring(yy[0].length - 2);

            setFinancialYear(
              `${yy?.[0]}-${(parseInt(lastFind) + 1)?.toString()}`
            );
          } else {
            setFinancialYear(yy?.join("-"));
          }
        }
      }
    })();
};

export const globalSectionsDataMask = (res, Operation) => {
  return res?.data?.map((formData) => {
    let deadline = formData?.deadline || "2024-12-12";
    let sectionName = formData?.name;
    if (sectionName == "9 Principles of NGRBC") {
      return;
    }
    let formId = formData?.id;
    let answeredQuestion = formData?.answeredQuestions || 0;
    let totalQuestion = formData?.totalQuestions || 0;
    let answeredQuestionPercentage;
    if (totalQuestion > 0) {
      answeredQuestionPercentage = (
        (answeredQuestion / totalQuestion) *
        100
      ).toFixed(0);
    }
    // avoiding / for navigation routing
    if (sectionName == "Labor/management relations") {
      sectionName = "Labormanagement relations";
    }
    return {
      ...formData,
      no: getRecordId?.brsrFormId(formData?.id),
      action: ActionButton(Operation, formData),
      deadline: new Date(deadline).toLocaleString("en-GB").split(",")[0],
      editForm: ViewButton(
        `${window.location.pathname}/brsr-form/${sectionName}/${formId}`
      ),
      spocs: ViewButton(
        `${window.location.pathname}/brsr-form/${formId}/spoclist`
      ),
      progressBar: (
        <Progress
          type="circle"
          percent={answeredQuestionPercentage}
          size={45}
        />
      ),
      // updateBRSRForm: OnlyEditButton(Operation, formData),
      sectionName: sectionName,
      answeredQuestion,
      totalQuestion,
    };
  });
};

export const connectionSentDataMask = (res, Operation) => {
  return res?.data?.map((connectionSentData) => {
    return {
      no: getRecordId?.connectionSentId(connectionSentData?.id),
      ...connectionSentData,
      connectionTo: connectionSentData?.connectionTo?.name,
      status: connectionSentData?.status,
      gstIn: connectionSentData?.connectionTo?.gstin,
      pocName: connectionSentData?.connectionToPoc?.name,
      pocMobile: connectionSentData?.connectionToPoc?.mobile,
      pocMail: connectionSentData?.connectionToPoc?.mail,
      categoryServiceProvider:
        connectionSentData?.declarationDetail?.supplierTypeToCategory
          ?.categoryOfServiceProvider,
      action: OnlyDeleteButton(Operation, connectionSentData, false),
    };
  });
};
export const userApprovalDataMask = (res, Operation, setUpdatedList) => {
  return res?.map((userData) => {
    return {
      no: getRecordId?.userApprovalId(userData?.id),
      ...userData,
      action: (
        <ApproveAction
          Operation={Operation}
          clientData={userData}
          setUpdatedList={setUpdatedList}
        />
      ),
    };
  });
};

export const connectionReceivedDataMask = (res, Operation, setFlag) => {
  return res?.data?.map((connectionRecievedData) => {
    return {
      no: getRecordId?.connectionRecievedId(connectionRecievedData?.id),
      ...connectionRecievedData,
      connectionFrom: connectionRecievedData?.connectionFrom?.name,
      status: connectionRecievedData?.status,
      gstIn: connectionRecievedData?.connectionFrom?.gstin,
      pocName: connectionRecievedData?.connectionToPoc?.name,
      pocMobile: connectionRecievedData?.connectionToPoc?.mobile,
      pocMail: connectionRecievedData?.connectionToPoc?.mail,
      categoryServiceProvider:
        connectionRecievedData?.declarationDetail?.supplierTypeToCategory
          ?.categoryOfServiceProvider,
      updateStatus: (
      <ConnectionStatusUpdate
      Operation={Operation}
      userData = {connectionRecievedData}
      setFlag = {setFlag}
      />),
      deleteConnection: OnlyDeleteButton(
        Operation,
        connectionRecievedData,
        true
      ),
    };
  });
};

export const globalSpocsListDataMask = (res, Operation) => {
  return res?.data?.map((spocData) => {
    return {
      ...spocData,
      no: getRecordId?.brsrAllotedSpocId(spocData?.id),
      SPOCname: spocData?.name,
      SPOCemail: spocData?.email,
      SPOCphone: spocData?.phone,
      SPOCdepartment: spocData?.department,

      // no: getRecordId?.brsrFormId(formData?.id),
      // action: ActionButton(Operation, formData),
      // editForm: ViewButton(`${window.location.pathname}/brsr-form/${sectionId}`),
      // spocs: ViewButton(`${window.location.pathname}/brsr-form/${formId}/spoclist`),
    };
  });
};
