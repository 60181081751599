import React, { useEffect } from "react";
import VerticalLayout from "../VerticalLayout";
import CONSTANT, { MyData } from "../../pages/Utility/constnt";
import {
  getPackagingStart,
  getPlantStart,
  getRouteStart,
  getShipmentStart,
  getUsersDetailSuccess,
} from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import useHttp from "../Hook/Use-http";
import {
  menuGen,
  notifications,
  replaceId,
} from "../../pages/Utility/function";
import { useLocation, useNavigate } from "react-router-dom";
import useCheckInternet from "../Hook/use-checkInternet";
import HorizontalLayout from "../HorizontalLayout";
import authStorage from "../../pages/Utility/API/authStroge";
import { ROUTES } from "../../pages/Utility/Route";

const SiteLayout = () => {
  useCheckInternet("/noInternet");

  const API_CALL = useHttp();
  const dispatch = useDispatch();
  let userRole = localStorage.getItem("userRole");
  const siteId = window.location.pathname.split("/")[2];
  const myData = useSelector((state) => state.userGetMeData);
  const getSiteData = useSelector((state) => state.plantDetailData);
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();

  useEffect(() => {
    const isLogin =
      authStorage?.getAuthToken() !== undefined &&
      authStorage?.getAuthToken() !== null;
    if (!isLogin || !userRole) {
      notifications.error("Session Expired!");
      return navigate("/login");
    }
    (async () => {
      if (!window?.location?.href.includes("data-not-found")) {
        // if (userRole !== "siteViewer") return navigate(ROUTES.PAGES403);
        if (userRole === "siteViewer") {
          if (!currentPath.includes("/siteViewer"))
            return navigate(ROUTES.PAGES403);

          API_CALL.sendRequest(CONSTANT.API.ADMIN.getMe, getMeSiteDataHandler);
        }
        userRole == "admin" && siteId && startReduceData();
        // if ( userRole !== "siteViewer") {
        //   startReduceData();
        // }
        // else if(localStorage.getItem("userRole") === "tripCreator")
        // {
        //   API_CALL.sendRequest(CONSTANT.API.getMe, getMeSiteDataHandler);
        // }
      }
    })();
  }, []);

  useEffect(() => {
    if (getSiteData?.users?.data?.rows) {
      if (userRole == "admin" && siteId) {
        dispatch(
          getUsersDetailSuccess(
            getSiteData?.users?.data?.rows?.find((el) => el?.id == siteId)
          )
        );
      }
    }
  }, [getSiteData?.users?.data?.rows]);

  const startReduceData = () => {
    if (userRole == "admin" && siteId) {
      // userRole = "clientViewRole";
      //   if (Object.entries(localStorage)?.find((el) => el?.[0] == "siteViewer")) {
      userRole = "siteViewRole";
      //   }
    }

    switch (userRole) {
      case "siteViewer":
        dispatch(getShipmentStart());
        dispatch(getRouteStart());

        break;

      case "siteViewRole":
        dispatch(getPlantStart());

        dispatch(
          getShipmentStart(
            replaceId(CONSTANT.API.SHIPMENT.getPlantTrip, siteId)?.endpoint
          )
        );

        dispatch(
          getRouteStart(
            replaceId(CONSTANT.API.ROUTE.getPlantRoute, siteId)?.endpoint
          )
        );

        break;

      default:
        break;
    }

    dispatch(getPackagingStart());
  };

  const getMeSiteDataHandler = (res) => {
    MyData.data = res.data;
    dispatch(getUsersDetailSuccess(res?.data));
    // localStorage.setItem("userName", res?.data?.name);
    // dispatch(getShipmentStart());
    // dispatch(getRouteStart());

    if (currentPath == "/siteViewer") {
      navigate(currentPath + `/${res?.data?.plantId}`);
    }
    if (currentPath == "/siteViewer/") {
      navigate(currentPath + `${res?.data?.plantId}`);
    }
    if (
      !currentPath?.includes(res?.data?.plantId) &&
      currentPath != "/siteViewer" &&
      currentPath != "/siteViewer/"
    ) {
      navigate(
        currentPath?.replace(currentPath.split("/")[2], res?.data?.plantId)
      );
    }

    startReduceData();
    if (res?.data?.plant?.client === null || res?.data?.plant === null) {
      window.location.replace("data-not-found");
    }
  };

  return (
    <>
      <HorizontalLayout SideBarMenu={menuGen("siteViewer")} />

      {/* <VerticalLayout SideBarMenu={menuGen("siteViewer")} /> */}
    </>
  );
};

export default SiteLayout;
