import React from "react";

const TrackIdSvg = () => {
  return (
    <>
      <svg
        width="37"
        height="37"
        viewBox="0 0 37 37"
        fill="#003E70"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="37" height="37" rx="4" fill="#003E70" fill-opacity="0.1" />
        <path d="M22.0813 26.6666H13.042C11.5481 26.6666 10.3337 25.4522 10.3337 23.9583C10.3337 22.4644 11.5481 21.25 13.042 21.25H20.6253C22.7162 21.25 24.417 19.5491 24.417 17.4583C24.417 15.3675 22.7162 13.6666 20.6253 13.6666H15.3592C14.9614 14.4465 14.4687 15.1742 13.8924 15.8333H20.6253C21.5212 15.8333 22.2503 16.5624 22.2503 17.4583C22.2503 18.3542 21.5212 19.0833 20.6253 19.0833H13.042C10.3542 19.0833 8.16699 21.2705 8.16699 23.9583C8.16699 26.646 10.3542 28.8333 13.042 28.8333H23.4344C22.9028 28.1646 22.4489 27.4377 22.0813 26.6666ZM11.417 7.16663C9.62516 7.16663 8.16699 8.62479 8.16699 10.4166C8.16699 13.8703 11.417 15.8333 11.417 15.8333C11.417 15.8333 14.667 13.8692 14.667 10.4166C14.667 8.62479 13.2088 7.16663 11.417 7.16663ZM11.417 12.0416C11.2035 12.0416 10.9922 11.9994 10.795 11.9177C10.5978 11.8359 10.4186 11.7161 10.2677 11.5651C10.1168 11.4141 9.99715 11.2349 9.91552 11.0377C9.8339 10.8404 9.79192 10.629 9.79199 10.4155C9.79206 10.2021 9.83418 9.99071 9.91594 9.79352C9.99769 9.59632 10.1175 9.41716 10.2685 9.26627C10.4195 9.11537 10.5987 8.9957 10.796 8.91407C10.9932 8.83245 11.2046 8.79047 11.4181 8.79054C11.8492 8.79069 12.2626 8.96209 12.5673 9.26704C12.8721 9.57199 13.0432 9.98551 13.0431 10.4166C13.0429 10.8477 12.8715 11.2612 12.5666 11.5659C12.2616 11.8706 11.8481 12.0418 11.417 12.0416Z" />
        <path d="M26.583 20.1666C24.7912 20.1666 23.333 21.6248 23.333 23.4166C23.333 26.8703 26.583 28.8333 26.583 28.8333C26.583 28.8333 29.833 26.8692 29.833 23.4166C29.833 21.6248 28.3748 20.1666 26.583 20.1666ZM26.583 25.0416C26.3695 25.0416 26.1582 24.9994 25.961 24.9177C25.7638 24.8359 25.5846 24.7161 25.4337 24.5651C25.2828 24.4141 25.1632 24.2349 25.0815 24.0377C24.9999 23.8404 24.9579 23.629 24.958 23.4155C24.9581 23.2021 25.0002 22.9907 25.082 22.7935C25.1637 22.5963 25.2835 22.4172 25.4345 22.2663C25.5855 22.1154 25.7647 21.9957 25.962 21.9141C26.1592 21.8324 26.3706 21.7905 26.5841 21.7905C27.0152 21.7907 27.4286 21.9621 27.7334 22.267C28.0381 22.572 28.2092 22.9855 28.2091 23.4166C28.2089 23.8477 28.0375 24.2612 27.7326 24.5659C27.4276 24.8706 27.0141 25.0418 26.583 25.0416Z" />
      </svg>
    </>
  );
};

export default TrackIdSvg;
