import { Card } from "antd";
import React, { useEffect, useState } from "react";
import TableComponent from "../tableComponent";
import CONSTANT, {
  pcfMaterialPartDetailTableFields,
  pcfProcessDetailTableFields,
} from "../../Utility/constnt";
import useHttp from "../../../components/Hook/Use-http";
import PcfServices from "../../Utility/API/pcfService";
import {
  DeleteSweetAlert,
  optionArrayWithSort,
  replaceId,
} from "../../Utility/function";
import { map } from "leaflet";
import Loader from "../../../components/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import {
  materialAddNewProcess,
  materialContinuePayload,
  tableArrayGen,
  tableArrayPropertyGen,
} from "../../Utility/pcfSpreadFunction";

const MaterialTab = (props) => {
  const {
    tabIndex = null,
    setMainProductData,
    setTabIndex,
    mainProductData,
  } = props;
  const tableFieldList = {
    listTable: [...pcfMaterialPartDetailTableFields],
    multipleListTable: [...pcfProcessDetailTableFields],
  };
  const location = useLocation();
  const currentPath = location.pathname;
  const urlParams = new URLSearchParams(location?.search);
  const productId = urlParams.get("productid");
  // let sumOfWeight = 0;
  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [qualityData, setQualityData] = useState([]);
  const [processCategoryData, setProcessCategoryData] = useState([]);
  const [processSubCategoryData, setProcessSubCategoryData] = useState([]);
  const [transportModeData, setTransportModeData] = useState([]);
  const [energySourceData, setEnergySourceData] = useState([]);
  const [sumOfWeight, setSumOfWeight] = useState(0);
  const [deleteData, setDeleteData] = useState(null);

  const [formData, setFormData] = useState([]);
  const [newFormData, setNewFormData] = useState({});
  const API = useHttp();
  let dynamicOption = {};

  useEffect(() => {
    Object?.keys(mainProductData)?.length != 0 &&
      !mainProductData?.id &&
      API.getAll(
        [
          CONSTANT.API.PCFPRODUCTCATEGORY.getForMaterial.endpoint,
          CONSTANT.API.PCFPRODUCTQUALITY.get.endpoint,
          CONSTANT.API.PCFRPRODUCTPROCESSCATEGORY.get.endpoint,
          CONSTANT.API.PCFPRODUCTSUBCATEGORY.get.endpoint,
          CONSTANT.API.PCFRPRODUCTPROCESSSUBCATEGORY.get.endpoint,
          CONSTANT.API.PCFPRODUCTEMISSIONSOURCE.getEnergySource.endpoint,
          CONSTANT.API.PCFPRODUCTEMISSIONSOURCE.getTranportMode.endpoint,
        ],
        (res) => {
          setCategoryData(res?.[0]?.data?.data?.rows);
          setQualityData(res?.[1]?.data?.data?.rows);
          setProcessCategoryData(res?.[2]?.data?.data?.rows);
          setSubCategoryData(res?.[3]?.data?.data?.rows);
          setProcessSubCategoryData(res?.[4]?.data?.data?.rows);
          setTransportModeData(res?.[5]?.data?.data?.rows);
          setEnergySourceData(res?.[6]?.data?.data?.rows);
          setTableData([
            tableArrayGen({
              productCategoryId: {
                option: optionArrayWithSort(
                  res?.[0]?.data?.data?.rows,
                  "name",
                  "id"
                ),
              },
              transportationModeId: {
                option: optionArrayWithSort(
                  res?.[5]?.data?.data?.rows,
                  "name",
                  "id"
                ),
              },
            }),
          ]);
        },
        PcfServices
      );

    // setTableData([tableArrayGen()]);
  }, []);

  //update
  useEffect(() => {
    mainProductData?.id &&
      API.getAll(
        [
          replaceId(
            CONSTANT.API.PCFPRODUCTMATERIAL.getProductWise,
            mainProductData?.id
          ).endpoint + "&sortBy=id&sortOrder=ASC",
          CONSTANT.API.PCFPRODUCTCATEGORY.getForMaterial.endpoint,
          CONSTANT.API.PCFPRODUCTQUALITY.get.endpoint,
          CONSTANT.API.PCFRPRODUCTPROCESSCATEGORY.get.endpoint,
          CONSTANT.API.PCFPRODUCTSUBCATEGORY.get.endpoint,
          CONSTANT.API.PCFRPRODUCTPROCESSSUBCATEGORY.get.endpoint,
          CONSTANT.API.PCFPRODUCTEMISSIONSOURCE.getEnergySource.endpoint,
          CONSTANT.API.PCFPRODUCTEMISSIONSOURCE.getTranportMode.endpoint,
        ],
        (res) => {
          setCategoryData(res?.[1]?.data?.data?.rows);
          setQualityData(res?.[2]?.data?.data?.rows);
          setProcessCategoryData(res?.[3]?.data?.data?.rows);
          setSubCategoryData(res?.[4]?.data?.data?.rows);
          setProcessSubCategoryData(res?.[5]?.data?.data?.rows);
          setEnergySourceData(res?.[6]?.data?.data?.rows);
          setTransportModeData(res?.[7]?.data?.data?.rows);

          tableArrayPropertyGen(res, setTableData, setSumOfWeight);
        },
        PcfServices
      );
  }, [mainProductData]);

  const ProductSubCategoryData = (value) => {
    value &&
      API.getAll(
        [
          replaceId(
            CONSTANT.API.PCFPRODUCTSUBCATEGORY.getSubCategoryFilterByCategory,
            value
          ).endpoint,
        ],
        (res) => {
          setSubCategoryData(res?.[0]?.data?.data?.rows);
        },
        PcfServices
      );
  };

  // Part Sub-Category Option
  const getPartSubCategory = (partIndex, elementIndex, categoryId) => {
    API.getAll(
      [
        replaceId(
          CONSTANT.API.PCFPRODUCTSUBCATEGORY.getSubCategoryFilterByCategory,
          categoryId
        ).endpoint,
      ],
      (res) => {
        setTableData((prev) => {
          const prevData = [...prev];
          const currentPart = prevData?.[partIndex];
          currentPart.data[elementIndex].option = optionArrayWithSort(
            res?.[0]?.data?.data?.rows,
            "name",
            "id"
          );
          currentPart.data[elementIndex].isLoading = false;
          return prevData;
        });
      },
      PcfServices
    );
  };

  //  Part Quality Option  depend Process Filter
  const getPartQualityAndProcess = (partIndex, elementIndex, subcategoryId) => {
    API.getAll(
      [
        replaceId(
          CONSTANT.API.PCFPRODUCTQUALITY.getQualityFilterBySubCategory,
          subcategoryId
        ).endpoint,
        replaceId(
          CONSTANT.API.PCFRPRODUCTPROCESSCATEGORY
            .getProceessFilterByProductSubCategory,
          subcategoryId
        ).endpoint,
      ],
      (res) => {
        setTableData((prev) => {
          const prevData = [...prev];
          const currentPart = prevData?.[partIndex];

          currentPart.data[elementIndex].option = optionArrayWithSort(
            res?.[0]?.data?.data?.rows,
            "name",
            "id"
          );
          currentPart.data[elementIndex].isLoading = false;
          prevData?.[partIndex]?.data?.[currentPart?.data?.length - 1]?.forEach(
            (el, index) => {
              el.data[1].isLoading = true;
              el.data[1].value = null;
              el.data[2].value = null;
              el.data[3].value = null;
              el.data[2].option = null;
              el.data[1].option = optionArrayWithSort(
                res?.[1]?.data?.data?.rows,
                "name",
                "id"
              );

              el.data[1].isLoading = false;
            }
          );
          return prevData;
        });
      },
      PcfServices
    );
  };

  //  Process Sub-Category Filter Process id depend
  const getProcessSubCategory = (
    partIndex,
    elementIndex,
    processId,
    processIndex
  ) => {
    API.getAll(
      [
        replaceId(
          CONSTANT.API.PCFRPRODUCTPROCESSSUBCATEGORY
            .getProcessSubCategoryFilterByProcessCategory,
          processId
        ).endpoint,
      ],
      (res) => {
        setTableData((prev) => {
          const prevData = [...prev];
          const currentPart = prevData?.[partIndex];
          const currentProcess =
            prevData?.[partIndex]?.data?.[currentPart?.data?.length - 1];

          currentProcess[processIndex].data[elementIndex].option =
            optionArrayWithSort(res?.[0]?.data?.data?.rows, "name", "id");

          currentProcess[processIndex].data[elementIndex].isLoading = false;

          return prevData;
        });
      },
      PcfServices
    );
  };

  useEffect(() => {}, []);

  // part onchange
  const onPartValueChange = (
    partIndex,
    elementIndex,
    value,
    multipleIndex,
    latitude,
    logtitude
  ) => {
    multipleIndex == null &&
      setTableData((prev) => {
        const prevData = [...prev];
        const currentPart = prevData?.[partIndex];
        if (latitude && logtitude) {
          const keyarr = Object?.keys(currentPart.data[elementIndex].latlog);
          currentPart.data[elementIndex].latlog[keyarr[0]] = latitude;
          currentPart.data[elementIndex].latlog[keyarr[1]] = logtitude;
          currentPart.data[elementIndex].value = value || "";
        } else {
          currentPart.data[elementIndex].value = value || "";
        }
        // currentPart.data[elementIndex].value = value;
        let sumOfTotalWeight = 0;
        prevData?.forEach((el, index) => {
          const elWeight = el?.data?.find(
            (ell, dataIndex) => ell?.id == "weight"
          )?.value;

          if (elWeight && elWeight != null && elWeight != undefined) {
            sumOfTotalWeight += elWeight;
          }
        });
        setSumOfWeight(sumOfTotalWeight);
        switch (elementIndex) {
          case 1:
            currentPart.data[elementIndex + 1].isLoading = true;
            currentPart.data[elementIndex + 1].value = null;
            currentPart.data[elementIndex + 1].option = [];
            currentPart.data[elementIndex + 2].value = null;
            currentPart.data[elementIndex + 2].option = [];

            getPartSubCategory(partIndex, elementIndex + 1, value);
            break;

          case 2:
            currentPart.data[elementIndex + 1].isLoading = true;
            currentPart.data[elementIndex + 1].value = null;
            currentPart.data[elementIndex + 1].option = [];

            getPartQualityAndProcess(partIndex, elementIndex + 1, value);
            // getProcessCategory()

            break;

          default:
            return prevData;
        }
        return prevData;
        // currentMaterialProcess?.push(childTable());
        // return prevData;
      });
  };

  // proceess value onChange
  const onProcessValueChange = (
    partIndex,
    elementIndex,
    value,
    multipleIndex,
    latitude,
    logtitude
  ) => {
    multipleIndex != null &&
      setTableData((prev) => {
        const prevData = [...prev];
        const currentPart = prevData?.[partIndex];
        const currentProcess =
          currentPart?.data?.[currentPart?.data?.length - 1][multipleIndex];
        if (currentProcess.data) {
          if (latitude && logtitude) {
            const keyarr = Object?.keys(
              currentProcess.data[elementIndex]?.latlog
            );

            currentProcess.data[elementIndex].latlog[keyarr[0]] = latitude;
            currentProcess.data[elementIndex].latlog[keyarr[1]] = logtitude;
            currentProcess.data[elementIndex].value = value;
          } else {
            currentProcess.data[elementIndex].value = value;
          }
        }

        // currentProcess.data &&
        //   (currentProcess.data[elementIndex].value = value);

        switch (elementIndex) {
          case 1:
            currentProcess.data[elementIndex + 1].isLoading = true;
            currentProcess.data[elementIndex + 1].value = null;
            currentProcess.data[elementIndex + 1].option = [];
            // currentProcess.data[elementIndex + 2].value = null;

            getProcessSubCategory(
              partIndex,
              elementIndex + 1,
              value,
              multipleIndex
            );
            break;
          // case 2:
          //   currentProcess.data[elementIndex + 1].isLoading = true;
          //   currentProcess.data[elementIndex + 1].value = null;
          //   currentProcess.data[elementIndex + 1].option = [];

          //   // const energyConsume = processSubCategoryData?.find(
          //   //   (el) => +el?.id == +value
          //   // )?.emissionFactor;

          //   // if (energyConsume && energyConsume != "") {
          //   //   currentProcess.data[elementIndex + 1].value = energyConsume;
          //   // }
          //   break;

          default:
            return prevData;
        }

        return prevData;
      });
  };

  //add new Process
  const addNewProcess = (partIndex) => {
    //Alway child Process Table Add inside Last On the Object
    materialAddNewProcess(
      setTableData,
      partIndex,
      processCategoryData,
      energySourceData,
      transportModeData
    );
  };

  // delete process
  const deleteProcess = (partIndex, processIndex) => {
    // partIndex = deleteData?.partIndex;
    // processIndex = deleteData?.processIndex;
    setTableData((prev) => {
      const currentPart = prev?.[partIndex];
      let currentProcess =
        currentPart?.data?.[prev?.[partIndex]?.data?.length - 1];

      if (currentProcess?.[processIndex]?.recordId != -1) {
        //TO DO API CALL DELETE NI
        API.sendRequest(
          replaceId(
            CONSTANT.API.PCFPRODUCTPROCESS.delete,
            currentProcess?.[processIndex]?.recordId
          ),
          () => {},
          null,
          "Deleted successfully",
          null,
          null,
          PcfServices
        );
      }

      if (currentProcess?.length == 1) {
        setDeleteData(null);
        prev[partIndex].data[prev[partIndex].data?.length - 1] = [];
        return [...prev];
      }
      setDeleteData(null);
      currentProcess = prev?.[partIndex]?.data?.[
        prev?.[partIndex]?.data?.length - 1
      ]?.filter((el, index) => processIndex != index);
      prev[partIndex].data[prev[partIndex].data?.length - 1] = [
        ...currentProcess,
      ];

      return [...prev];
    });
  };

  // add part button
  const addPart = () => {
    setTableData((prev) => {
      const prevData = [...prev];
      prevData?.push(
        tableArrayGen({
          productCategoryId: {
            option: optionArrayWithSort(categoryData, "name", "id"),
            value: "",
          },
          transportationModeId: {
            option: optionArrayWithSort(transportModeData, "name", "id"),
            value: "",
          },
        })
      );
      return prevData;
    });
  };

  // delete part button
  const deletePart = (partIndex) => {
    // partIndex = deleteData?.partIndex;

    deleteData != null &&
      setTableData((prev) => {
        const currentPart = prev?.[partIndex];
        let sumOfTotalWeight = 0;
        prev
          ?.filter((el, index) => partIndex != index)
          ?.forEach((el, index) => {
            const elWeight = el?.data?.find(
              (ell, dataIndex) => ell?.id == "weight"
            )?.value;

            if (elWeight && elWeight != null && elWeight != undefined) {
              sumOfTotalWeight += elWeight;
            }
          });
        setSumOfWeight(sumOfTotalWeight);

        if (currentPart?.recordId != -1) {
          //TO DO API CALL DELETE NI
          API.sendRequest(
            replaceId(
              CONSTANT.API.PCFPRODUCTMATERIAL.delete,
              currentPart?.recordId
            ),
            () => {},
            null,
            "Deleted successfully",
            null,
            null,
            PcfServices
          );
        }
        if (prev?.length == 1) {
          setDeleteData(null);
          return [
            tableArrayGen({
              productCategoryId: {
                option: optionArrayWithSort(categoryData, "name", "id"),
              },

              transportationModeId: {
                option: optionArrayWithSort(transportModeData, "name", "id"),
              },
            }),
          ];
        }

        setDeleteData(null);
        return prev?.filter((el, index) => partIndex != index);
      });
  };

  const previewButton = () => {
    navigate(`${currentPath}?tab=product&productid=${mainProductData?.id}`);
    setTabIndex("1");
  };

  const continueButton = () => {
    const finalPayload = {
      data: {
        addMaterialWithProcess: [],
        updateMaterial: [],
        updateProcess: [],
        addProcessInMaterial: [],
      },
    };
    const payload = [];
    let isError = false;
    // const isError =
    materialContinuePayload(
      setTableData,
      finalPayload,
      mainProductData,
      API,
      navigate,
      currentPath,
      setTabIndex
    );
    finalPayload["productId"] = mainProductData?.id;
  };

  return (
    <>
      <TableComponent
        tableFieldList={tableData}
        categoryData={categoryData}
        subCategoryData={subCategoryData}
        qualityData={qualityData}
        processCategoryData={processCategoryData}
        processSubCategoryData={processSubCategoryData}
        dynamicOption={dynamicOption}
        addNewProcess={addNewProcess}
        deleteProcess={deleteProcess}
        addPart={addPart}
        deletePart={deletePart}
        onPartValueChange={onPartValueChange}
        onProcessValueChange={onProcessValueChange}
        continueButton={continueButton}
        mainProductData={mainProductData}
        sumOfWeight={sumOfWeight}
        previewButton={previewButton}
        setDeleteData={setDeleteData}
      />
      {deleteData != null ? (
        <DeleteSweetAlert
          onDelete={() => {
            if (
              deleteData?.processIndex != null &&
              deleteData?.partIndex != null
            ) {
              deleteProcess(deleteData?.partIndex, deleteData?.processIndex);
            } else if (
              !deleteData?.processIndex &&
              deleteData?.partIndex != null
            ) {
              deletePart(deleteData?.partIndex);
            }
          }}
          setDeleteData={setDeleteData}
        />
      ) : null}
    </>
  );
};

export default MaterialTab;
